import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MoreHoriz, OpenInNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import AllocationModal from './AllocationModal';
import { useSelector } from 'react-redux';
import GridItemOptionsModal from './GridItemOptionsModal';

export default function TransItemEditableGrid({ rowsData, setUpdateRows, taxPurchase, editable, sSelectItemChecked, sHideItemChecked, setGridEditing }) {
    const rdxTransHead = useSelector((state) => state.PurchaseSlice.transHead);
    const vMediaQuery = useMediaQuery('(max-width:900px)');
    const [sLocModal, setLocModal] = useState(false);
    const [sOptionsModal, setOptionsModal] = useState(false);
    const [sSelectedRow, setSelectedRow] = useState({
        SrNo: 0,
        ALTItmCd: '',
        ALTItmName: '',
        PackQty: 0,
        Qty: 0,
        OrdQty: 0,
        RequiredCTN: 0,
        ExistingPurCTN: 0,
        // IMAGE_BASE_URL: '',
        // Version: '',
        // barcode: ''

    });
    const onRowButtonClick = (params) => {
        setSelectedRow({
            SrNo: params.row.SrNo,
            ALTItmCd: params.row.ALTItmCd,
            ALTItmName: params.row.ALTItmName,
            PackQty: params.row.PackQty,
            Qty: params.row.Qty,
            OrdQty: params.row.OrdQty,
            RequiredCTN: params.row.RequiredCTN,
            ExistingPurCTN: params.row.ExistingPurCTN,
            // IMAGE_BASE_URL: params.row.IMAGE_BASE_URL,
            // Version: params.row.Version,
        });

    }


    const setPackQty = (params) => {
        let row = updateValues({ ...params.row, PackQty: params.value }, "PACK_QTY");
        const updatedRows = rowsData.map((itm) => (itm.SrNo === row.SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const setQty = (params) => {
        let row = updateValues({ ...params.row, Qty: Math.round(Number(params.value)) }, "PUR_QTY");
        const updatedRows = rowsData.map((itm) => (itm.SrNo === row.SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const setUnitRate = (params) => {
        let row = updateValues({ ...params.row, UnitRate: params.value }, "UNIT_RATE");
        const updatedRows = rowsData.map((itm) => (itm.SrNo === row.SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };



    const updateValues = (obj, xType) => {
        const calculatedObj = calculate(
            obj.OrdQty,
            obj.ExistingPur,
            obj.PackQty,
            obj.Qty,
            obj.UnitRate,
            obj.LDiscPerc,
            obj.TDiscPerc,
            obj.ExciseTaxAmt,
            obj.TaxPerc,
            obj.TaxPerc2,
            obj.Ad_PCQty,
            obj.FocAmt,
            obj.locList,
            xType
        );
        return { ...obj, ...calculatedObj };
    };

    const calculate = (
        OrdQty,
        ExistingPur,
        PackQty,
        Qty,
        UnitRate,
        LDiscPerc,
        TDiscPerc,
        ExciseTaxAmt,
        TaxPerc,
        TaxPerc2,
        Ad_PCQty,
        FocAmt,
        locList,
        xType
    ) => {

        OrdQty = isNaN(OrdQty) ? 0 : OrdQty;
        ExistingPur = isNaN(ExistingPur) ? 0 : ExistingPur;
        PackQty = isNaN(PackQty) ? 0 : PackQty;
        Qty = isNaN(Qty) ? 0 : Qty;
        UnitRate = isNaN(UnitRate) ? 0 : UnitRate;
        LDiscPerc = isNaN(LDiscPerc) ? 0 : LDiscPerc;
        TDiscPerc = isNaN(TDiscPerc) ? 0 : TDiscPerc;
        ExciseTaxAmt = isNaN(ExciseTaxAmt) ? 0 : ExciseTaxAmt;
        TaxPerc = isNaN(TaxPerc) ? 0 : TaxPerc;
        TaxPerc2 = isNaN(TaxPerc2) ? 0 : TaxPerc2;
        Ad_PCQty = isNaN(Ad_PCQty) ? 0 : Ad_PCQty;
        FocAmt = isNaN(FocAmt) ? 0 : FocAmt;

        //  var RequiredCTN = PackQty == 0 ? OrdQty - ExistingPur : (OrdQty - ExistingPur) / PackQty;
        // RequiredCTN = Math.ceil(RequiredCTN);

        //required ctn calculation
        const roundedPckQTy = PackQty == 0 ? 1 : PackQty;
        let updatedLocList = locList;
        var RequiredCTN = 0;
        RequiredCTN = updatedLocList.filter(i => i.selected).reduce((prevValue, i) => {
            return prevValue + i.ReqCTN;
        }, 0)

        if (xType == "PACK_QTY") {

            updatedLocList = locList.map(i => {
                let reqCTN = 0;
                if (i.OrderQty - i.ExistingAlloQty <= 0) {
                    reqCTN = 0;
                }
                else {
                    reqCTN = Math.round((i.OrderQty - i.ExistingAlloQty) / roundedPckQTy);
                    reqCTN = reqCTN === 0 ? (i.ExistingAlloQty > 0 ? 0 : 1) : reqCTN;
                }


                return {
                    ...i,
                    OrderQtyCTN: i.OrderQty / roundedPckQTy,
                    ExistingAlloQtyCTN: i.ExistingAlloQty / roundedPckQTy,
                    ReqCTN: reqCTN
                }
            });
            /****chnages 03.05.22 for allocation loc selection*****/
            // RequiredCTN = updatedLocList.reduce((prevValue, i) => {
            //     return prevValue + i.ReqCTN;
            // }, 0)
            RequiredCTN = updatedLocList.filter(i => i.selected).reduce((prevValue, i) => {
                return prevValue + i.ReqCTN;
            }, 0)
        }
        else if (xType == "PUR_QTY") {
            //loc allocation based on devide and fifo

            let allocRatio = Qty / RequiredCTN;
            let totalQty = Qty;

            let lastSelectedIndex = updatedLocList.findLastIndex(i => i.selected);
            updatedLocList = updatedLocList.map((i, index) => {
                let qty = 0;
                if (totalQty > 0 && i.selected) {
                    if (index === lastSelectedIndex) {
                        qty = totalQty
                    }
                    else {
                        qty = Math.round(i.ReqCTN * allocRatio);
                        qty = qty > totalQty ? totalQty : qty;
                        totalQty = totalQty - qty;
                    }
                }
                return {
                    ...i,
                    CurrAllocQtyCTN: qty
                }
            });
            console.log(lastSelectedIndex, updatedLocList, RequiredCTN);

        }

        /****chnages 03.05.22 for allocation loc selection*****/
        // var ExistingPurCTN = Math.ceil(PackQty == 0 ? ExistingPur : ExistingPur / PackQty);
        var ExistingPurCTN = updatedLocList.filter(i => i.selected).reduce((prevValue, i) => {
            return prevValue + i.ExistingAlloQtyCTN;
        }, 0);

        const BaseQty = Qty * roundedPckQTy;
        const GrossAmt = Qty * UnitRate;
        const LDiscAmt = (LDiscPerc / 100) * GrossAmt;
        const TDiscAmt = (TDiscPerc / 100) * (GrossAmt - LDiscAmt);
        const ExciseTaxNetAmt = ExciseTaxAmt * Qty;
        const Taxable = GrossAmt - LDiscAmt - TDiscAmt - FocAmt + ExciseTaxNetAmt;
        let TaxAmt = (TaxPerc / 100) * Taxable;
        let TaxAmt2 = (TaxPerc2 / 100) * Taxable;
        let Ad_PCRate = Ad_PCQty * roundedPckQTy;
        let Ad_NetCessAmt = Ad_PCRate * Qty;
        if (taxPurchase === 'N') {
            TaxAmt = 0;
            TaxAmt2 = 0;
            Ad_PCRate = 0;
            Ad_NetCessAmt = 0;
        }

        const Total = Taxable + TaxAmt + TaxAmt2 + Ad_NetCessAmt;

        return {
            PackQty: roundedPckQTy,
            VendorPackQty: roundedPckQTy,
            RequiredCTN: RequiredCTN,
            ExistingPurCTN: ExistingPurCTN,
            Qty: Qty,
            BaseQty: BaseQty,
            UnitRate: UnitRate,
            GrossAmt: GrossAmt,
            LDiscAmt: LDiscAmt,
            TDiscAmt: TDiscAmt,
            ExciseTaxNetAmt: ExciseTaxNetAmt,
            Taxable: Taxable,
            TaxAmt: TaxAmt,
            TaxAmt2: TaxAmt2,
            Ad_PCRate: Ad_PCRate,
            Ad_NetCessAmt: Ad_NetCessAmt,
            Total: Total,
            locList: updatedLocList,
        }
    };

    const preProcessEditCellProps = async (params) => {
        const errorMessage = await validateNumber(params.props.value);
        return { ...params.props, error: errorMessage };
    };

    const validateNumber = async (input) => {
        return Number(input) < 0 ? 'Negative value found' : null;
    }

    const columns = [
        {
            field: 'SrNo',
            headerName: 'Sr No.',
            // flex: 1,
            width: 30,
            align: 'center',
            headerAlign: 'center',
            hide: vMediaQuery
        },
        {
            field: 'click',
            headerName: '',
            // flex: .5,
            width: 10,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {

                return (
                    <IconButton size='small' color='primary' onClick={() => { onRowButtonClick(params); setLocModal(true); }}>
                        <MoreHoriz />
                    </IconButton>
                );
            }
        },
        {
            field: 'options',
            headerName: '',
            // flex: .5,
            width: 10,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {

                return (
                    <IconButton size='small' color='primary' onClick={() => { onRowButtonClick(params); setOptionsModal(true); }}>
                        <OpenInNew />
                    </IconButton>
                );
            }
        },
        {
            field: 'ALTItmCd',
            headerName: 'ItmCd',
            // flex: 1,
            width: 50,
            hide: true
        },
        {
            field: 'ALTItmName',
            headerName: 'Item',
            // flex: 4,
            width: !vMediaQuery ? 500 : 280,

        },

        {
            field: 'PackQty',
            headerName: 'Pack',
            // flex: 2,
            width: 70,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            type: 'number',
            //preProcessEditCellProps,
            valueSetter: setPackQty,
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },
            valueFormatter: ({ value }) => Number(value).toFixed(2),
        },
        {
            field: 'ExistingPurCTN',
            headerName: 'Ext.Pur.',
            // flex: 2,
            width: 70,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'RequiredCTN',
            headerName: 'Req.CTN.',
            // flex: 2,
            width: 70,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        }, {
            field: 'Qty',
            headerName: 'Qty',
            // flex: 2,
            width: 70,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            type: 'number',
            //preProcessEditCellProps,
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },
            valueSetter: setQty,
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'UnitRate',
            headerName: 'Rate',
            // flex: 2,
            width: 60,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            type: 'number',
            //preProcessEditCellProps,
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },
            valueSetter: setUnitRate,
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'Taxable',
            headerName: 'Amount',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
    ];

    return (
        <>
            <Box
                sx={{
                    height: '43vh',
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary'
                    },
                    '& .textPrimary': {
                        color: 'text.primary'
                    },
                    '& .Mui-error': {
                        backgroundColor: `rgb(126,10,15, 0.1})`,
                        color: '#ff4343',
                    },
                }}>
                <DataGrid
                    // rows={rowsData}
                    rows={sSelectItemChecked ?
                        (sHideItemChecked ?
                            rowsData.filter(i => i.ExistingPur < i.OrdQty && i.Qty > 0)
                            : rowsData.filter(i => i.Qty > 0))
                        : (sHideItemChecked ?
                            rowsData.filter(i => i.ExistingPur < i.OrdQty)
                            : rowsData)
                    }
                    columns={columns}
                    rowHeight={45}
                    headerHeight={45}
                    sx={{
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cellContent': {
                            textTransform: 'lowercase'
                        },
                    }}
                    componentsProps={{
                        filterPanel: { sx: { maxWidth: "93vw" } }
                      }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                    }

                    onCellEditStart={() => setGridEditing(true)}
                    onCellEditStop={() => setGridEditing(false)}
                    onCellEditCommit={() => setGridEditing(false)}


                // getCellClassName={(params) =>
                //     params.isEditable ? 'mui-editable-cell' : ''
                // }

                />
            </Box>
            <Modal
                open={sLocModal}
                onClose={() => setLocModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="model-sales-invoice-GCVanMaster-Modal">
                    <AllocationModal sSelectedRow={sSelectedRow} rowsData={rowsData} setUpdateRows={setUpdateRows} editable={editable} setLocModal={setLocModal} />
                </Box>
            </Modal>

            <Modal
                open={sOptionsModal}
                onClose={() => setOptionsModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="modal-puchase-grid-options-Modal">
                    <GridItemOptionsModal sSelectedRow={sSelectedRow} sItemList={rowsData} setItemList={setUpdateRows} setOptionsModal={setOptionsModal} />
                </Box>
            </Modal>
        </>
    );
}
