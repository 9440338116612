import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
function BatchCreationBreadcrumbs() {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 breadcrumb">
            {vPathName === '/BatchCreation' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">
                        PR Batch Creation{' '}
                    </span>
                </div>
            ) : vPathName === '/BatchCreationDetail' ? (
                <div className="d-flex">
                    <Link
                        to="/BatchCreation"
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        PR Batch Creation
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive" >
                            PR Brach Creation Detail{' '}
                        </span>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
export default BatchCreationBreadcrumbs;
