import { Box } from '@mui/material'
import React from 'react'
import FishOrder from './FishOrder'
import FshOrderBreadCrumbs from './ProcessComponents/FshOrderBreadCrumbs'

export default function CompletedFishOrder() {
    return (
        <div className="container">
            <div 
            // className="my-2"
            >
                <FshOrderBreadCrumbs />
            </div>
            <Box sx={{ flexGrow: 1, height: '75vh', overflow: 'auto', paddingRight: '10px' }}>
                <FishOrder xStatus='Post' />
            </Box>
        </div>
    )
}
