import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import '../../component/ToolBox/ToolBox.css';
import { CSVLink, CSVDownload } from 'react-csv';
import { ToastContainer } from 'react-toastify';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpCheckboxField,
  MUIDataGrid,
  CmpSelectArrField,
  CmpButtonWithIcon,
  CmpSelectField
} from '../../component/ToolBox/ToolBox';
import {
  getFPConsPostAttr,
  getFPConsPostLoadDetail,
  getFPConsAppDetail,
  getFPConsAppAttr,
  getFPConsPost
} from './actions';
import ConsolidationPostingLoad from './ProcessComponents/ConsolidationPostingLoad';
import { getGitterColumns, fAlertToast } from '../../Utility/Utilitys';
import moment from 'moment';
import ConsPostBreadCrumbs from './ProcessComponents/ConsPostBreadCrumbs';

const gridItemSpacing = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0.2
};
const borderBox = {
  height: '75vh',
  margin: 2
};
const alignContent = {
  paddingTop: '9px'
};
const alignType = {
  paddingTop: '5px'
};

const loadDetailGridSample = [
  {
    field: 'A_VocTyp',
    headerName: 'VocTyp',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'VocName',
    headerName: 'Doc.Name',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_VocNo',
    headerName: 'Doc.No',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'A_VocDt',
    headerName: 'Doc.Date',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'A_Loc',
    headerName: 'A_Loc',
    hide: true,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'LocName',
    headerName: 'Location',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_Dept',
    headerName: 'A_Dept',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Deptname',
    headerName: 'Department',
    hide: false,
    width: 170,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_DocStat',
    headerName: 'Status',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Completed',
    headerName: 'Completed',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Completed_UserCd',
    headerName: 'Completed_UserCd',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'CompletedByName',
    headerName: 'Completed_By',
    hide: false,
    width: 100,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_DC_Purchase',
    headerName: 'DC',
    hide: false,
    width: 40,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Br',
    headerName: 'Br',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: ' ',
    NumberFormat: '0'
  },
  {
    field: 'BrName',
    headerName: 'Branch',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: ' ',
    NumberFormat: '0'
  },
  {
    field: 'CoCd',
    headerName: 'CoCd',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: ' ',
    NumberFormat: '0'
  },
  {
    field: 'A_FromDt',
    headerName: 'From.Date',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'A_ToDt',
    headerName: 'To.Date',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  }
];

const purchaseTransGColmnSample = [
  {
    field: 'TRNS_ORD_ID',
    headerName: 'ORD.ID',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_ID',
    headerName: 'Trans.ID',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_LOC',
    headerName: 'TRNS_LOC',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_LOC_Name',
    headerName: 'TRNS_LOC_Name',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_DATE',
    headerName: 'Date',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'TRNS_PARTY',
    headerName: 'TRNS_PARTY',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_PARTY_NAME',
    headerName: 'Vendor',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_REFNO',
    headerName: 'TRNS_REFNO',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'Paymode',
    headerName: 'Paymode',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'Status',
    headerName: 'Status',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'GRN_DOC_TYP',
    headerName: 'GRN_DOC_TYP',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'GRN_DOC_TYP_NAME',
    headerName: 'GRN_DOC_TYP_NAME',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'GRN_DOC_NO',
    headerName: 'GRN.Doc.No',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_GRV_DOC_Type',
    headerName: 'TRNS_GRV_DOC_Type',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'GRV_DOC_TYP_NAME',
    headerName: 'GRV_DOC_TYP_NAME',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_GRV_DOC_No',
    headerName: 'PR.Doc.No',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'DOC',
    headerName: 'DOC',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '0'
  }
];

const purchaseOrderGColmnSample = [
  {
    field: 'Ord_ID',
    headerName: 'Ord.ID',
    hide: false,
    width: 120,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'Ord_Date',
    headerName: 'Ord.Date',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'Ord_Loc',
    headerName: 'Ord_Loc',
    hide: true,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'Ord_Loc_Name',
    headerName: 'Location',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Ord_Buyer',
    headerName: 'Ord_Buyer',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'Ord_Buyer_Name',
    headerName: 'Buyer',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Ord_Status',
    headerName: 'Status',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  }
];

const transferGColmnSample = [
  {
    field: 'SR_No',
    headerName: 'Sr.No',
    hide: false,
    width: 50,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TSR_VocTyp',
    headerName: 'TSR_VocTyp',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TSR_VocTypName',
    headerName: 'Doc.Type',
    hide: false,
    width: 180,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TSR_VocNo',
    headerName: 'Doc.No',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  }
];
const ConsolidationPosting = () => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const fromDtRef = useRef(null);
  const toDTRef = useRef(null);
  const locRef = useRef(null);
  const rdxuser = useSelector((state) => state.SliceDB.value.login);
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);

  const [headObj, setHeadObj] = useState({
    fromDt: moment().startOf('month'),
    toDT: new Date(),
    loc: '',
    locName: '',
    allowDCPurchase: 'Y',
    allowCentralPurchase: 'N',
    locationData: [],
    typeName: 'All',
    typeCode: 'All',
    type: []
  });
  const [showPostingModal, setShowPostingModal] = useState(false);
  const [loadDetailGridRow, setLoadDetailGridRow] = useState([]);
  const [loadDetailGridColmn, setLoadDetailGridColmn] = useState(loadDetailGridSample);
  const [purchaseTransGRow, setPurchaseTransGRow] = useState([]);
  const [purchaseOrderGRow, setPurchaseOrderGRow] = useState([]);
  const [transferGRow, setTransferGRow] = useState([]);
  const [purchaseTransGColmn, setPurchaseTransGColmn] = useState(purchaseTransGColmnSample);
  const [purchaseOrderGColmn, setPurchaseOrderGColmn] = useState(purchaseOrderGColmnSample);
  const [transferGColmn, setTransferGColmn] = useState(transferGColmnSample);
  const [selectedRow, setSelectedRow] = useState([
    {
      vocTyp: '0',
      vocName: '',
      vocNo: '0',
      docStatus: 'POST',
      completed: 'ENTER',
      vocDt: new Date(),
      branch: '',
      branchName: '',
      LocName: '',
      loc: '0',
      purchase: ''
    }
  ]);

  const onChangeFromDate = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: newValue.$d
      }));
    }
  };

  const onChangeDateTo = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        toDT: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        toDT: newValue.$d
      }));
    }
  };

  const onChangeType = (event) => {
    const obj = headObj.type.find((x) => x.Name1 == event.target.value);
    setHeadObj((xrow) => ({
      ...xrow,
      typeName: event.target.value,
      typeCode: obj?.Id
    }));
  };
  const onChangeLoc = (event) => {
    const data = headObj.locationData.find((x) => x.Name1 === event.target.value);
    setHeadObj((obj) => ({
      ...obj,
      locName: data.Name1,
      loc: data.UId
    }));
  };

  const onSelectGridItem = async (data) => {
    setShowPostingModal(true);
    setSelectedRow((obj) => ({
      ...obj,
      vocTyp: data?.row?.A_VocTyp,
      vocName: data?.row?.VocName,
      vocNo: data?.row?.A_VocNo,
      docStatus: data?.row?.A_DocStat,
      completed: data?.row?.Completed,
      vocDt: data?.row?.A_VocDt,
      branch: data?.row?.Br,
      branchName: data?.row?.BrName,
      LocName: data?.row?.LocName,
      loc: data?.row?.A_Loc,
      purchase: data?.row?.CNTRL_PUR
    }));
    fetchAppAttribute(data?.row?.A_VocTyp, data?.row?.A_VocNo);
    fetchAppDetail(data?.row?.A_VocTyp, data?.row?.A_VocNo);
    fetchGitter('PUCNSPSTPURWB');
    fetchGitter('PUCNSPSTPORWB');
    fetchGitter('PUCNSPSTFRWB');
  };

  const fetchAppAttribute = async (xtype, xno) => {
    const obj = {
      C_VocTyp: xtype,
      C_VocNo: xno
      //     "C_VocTyp":"AP001",
      // "C_VocNo":"AP22000001"
    };
    const result = await getFPConsAppAttr(obj);
    if (result?.ResultSet?.Type?.length > 0) {
      setHeadObj((xrow) => ({
        ...xrow,
        typeName: result?.ResultSet?.Type[0]?.Name1,
        typeCode: result?.ResultSet?.Type[0]?.Id,
        type: result?.ResultSet?.Type
      }));
    }
  };
  const fetchAppDetail = async (xtype, xno) => {
    const obj = {
      v_CONS_VocTyp: xtype,
      v_CONS_VocNo: xno,
      v_TRNSID: headObj?.typeCode
    };
    const result = await getFPConsAppDetail(obj);
    const order = result?.ResultSet?.Purchaseorder?.map((x, index) => {
      const data = {
        ...x,
        id: index + 1
      };
      return data;
    });
    const trans = result?.ResultSet?.PurchaseTrans?.map((x, index) => {
      const data = {
        ...x,
        id: index + 1
      };
      return data;
    });
    const transfer = result?.ResultSet?.Transfer?.map((x, index) => {
      const data = {
        ...x,
        id: index + 1
      };
      return data;
    });
    setPurchaseOrderGRow(order);
    setPurchaseTransGRow(trans);
    setTransferGRow(transfer);
  };

  const onLoadClick = async () => {
    const obj = {
      v_FromDt: moment(headObj.fromDt).format('MM/DD/YYYY'),
      v_ToDt: moment(headObj.toDT).format('MM/DD/YYYY'),
      v_Loc: headObj.loc,
      v_UserCd: rdxuser.userId,
      v_DC_Purchase: headObj.allowDCPurchase,
      CNTRL_PUR: headObj.allowDCPurchase == 'Y' ? 'N' : 'Y'
      //CNTRL_PUR: headObj.allowCentralPurchase ? 'Y' : 'N'
    };
    const result = await getFPConsPostLoadDetail(obj);
    const data = result?.ResultSet?.map((x, index) => {
      const data = {
        ...x,
        id: index + 1
      };
      return data;
    });
    setLoadDetailGridRow(data);
  };
  const fetchGitter = async (xtype) => {
    const payload = {
      trnTyp: xtype
    };
    const result = await getGitterColumns(payload);
    if (xtype == 'PUCNSPSTPURWB') {
      setPurchaseTransGColmn(result?.ResultSet?.columns);
    } else if (xtype == 'PUCNSPSTPORWB') {
      setPurchaseOrderGColmn(result?.ResultSet?.columns);
    } else if (xtype == 'PUCNSPSTFRWB') {
      setTransferGColmn(result?.ResultSet?.columns);
    }
  };
  const fetchPost = async (xdata) => {
    if (headObj.type.length === 0) {
      setShowPostingModal(false);
      fAlertToast('FAILED', 'Posting type is Null.');
      return false;
    }

    const obj = {
      A_Loc: xdata?.loc,
      A_VocDt: moment(xdata?.vocDt).format('MM/DD/YYYY'),
      Br: xdata?.branch,
      A_VocTyp: xdata?.vocTyp,
      A_VocNo: xdata?.vocNo,
      v_TRNSID: headObj?.typeCode,
      v_UserId: rdxuser?.userId
    };
    const result = await getFPConsPost(obj);

    if (result.Type === 'Success') {
      setShowPostingModal(false);
      onLoadClick();
    }
  };
  //Attributes API call
  useEffect(() => {
    const fLoadFPAttributes = async () => {
      const obj = {
        userCd: rdxuser.userId
      };
      const result = await getFPConsPostAttr(obj);
      setHeadObj((obj) => ({
        ...obj,
        loc: result?.ResultSet?.loc[0]?.UId,
        locName: result?.ResultSet?.loc[0]?.Name1,
        locationData: result?.ResultSet?.loc
      }));
    };
    const fgetColumns = async () => {
      const payload = {
        trnTyp: 'FR_CONSOLIDATION_APPROVAL'
      };
      const result = await getGitterColumns(payload);
      setLoadDetailGridColmn(result?.ResultSet?.columns);
    };
    fLoadFPAttributes();
    fgetColumns();
  }, []);

  return (
    <div>
      <div>
        <ToastContainer />
      </div>
      <div>
        <ConsPostBreadCrumbs />
      </div>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ paddingX: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <CmpTypographyField xcomponent={'span'} xText={'Location'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              sx={{
                '& .MuiMenu-list': {
                  height: '15rem !important'
                }
              }}>
              <CmpSelectArrField
                xLabelText={'Location'}
                xValue={headObj?.locName}
                xOnChange={onChangeLoc}
                xName={'Location'}
                xData={headObj?.locationData.map((x) => x.Name1)}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={''}
                xDisable={false}
                xref={locRef}
                xheight={'15rem'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8} md={8} lg={8} sx={{ padding: '5px' }}>
          <Box
            style={
              vMediaQuery
                ? {}
                : {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }
            }>
            <Grid container>
              <Grid
                item
                xs={3}
                sm={4}
                md={4}
                lg={4}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} xVariant={'subtitle'} />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8} sm={7} md={7} lg={7} sx={vMediaQuery ? {} : gridItemSpacing}>
                <CmpDatePickerField
                  xValue={headObj.fromDt}
                  xOnChange={(event) => onChangeFromDate(event)}
                  xName={'fromDt'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'1'}
                  xref={fromDtRef}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                sm={4}
                md={4}
                lg={4}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} xVariant={'subtitle'} />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8} sm={7} md={7} lg={7} sx={vMediaQuery ? {} : gridItemSpacing}>
                <CmpDatePickerField
                  xValue={headObj.toDT}
                  xOnChange={(event) => onChangeDateTo(event)}
                  xName={'toDT'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'2'}
                  xref={toDTRef}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ padding: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={vMediaQuery ? alignType : {}}>
              <CmpTypographyField xcomponent={'span'} xText={'Type'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={vMediaQuery ? alignType : {}}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              sx={{
                '& .MuiMenu-list': {
                  height: '15rem !important'
                }
              }}>
              <CmpSelectField
                xTabIndex="4"
                xLabelText="Type"
                xValue={headObj?.allowDCPurchase}
                xOnChange={(event) => {
                  setHeadObj((obj) => ({
                    ...obj,
                    allowDCPurchase: event.target.value
                  }));
                }}
                xName={'Type'}
                xData={[
                  {
                    UId: 'Y',
                    Name1: 'Market'
                  },
                  {
                    UId: 'N',
                    Name1: 'DC'
                  }
                ]}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={false}
                xDisable={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} sx={{ padding: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: vMediaQuery ? 'center' : 'end',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={1.5} lg={1.5} sx={{ px: '10px' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={onLoadClick}
                xLabel={'Load'}
                xIcon={''}
                xDisable={false}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1.5} sx={{ px: '10px' }}>
              <Button
                variant={'contained'}
                disabled={false}
                size={'medium'}
                onClick={() => {}}
                className="btn-text-transform-none inputfont mui-btn-wt-icon"
                startIcon={''}>
                <CSVLink
                  data={loadDetailGridRow}
                  filename={'cons_posting.csv'}
                  style={{ color: 'white', textDecoration: 'none' }}>
                  Export
                </CSVLink>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} sm={12} md={12} lg={12} sx={borderBox}>
          <MUIDataGrid
            xRows={loadDetailGridRow}
            xColumns={loadDetailGridColmn}
            xonRowClick={onSelectGridItem}
            enableCheckbox={false}
            borderColorChange={true}
          />
        </Grid>
      </Grid>
      <ConsolidationPostingLoad
        xOpen={showPostingModal}
        xTitle={'Purchase Consolidation Posting'}
        fCloseModal={() => setShowPostingModal(false)}
        xPostedRowInfo={selectedRow}
        xPurchaseTransRow={purchaseTransGRow}
        xPurchaseTransColumn={purchaseTransGColmn}
        xPurchaseOrderRow={purchaseOrderGRow}
        xPurchaseOrderColumn={purchaseOrderGColmn}
        xTransferRow={transferGRow}
        xTransferColumn={transferGColmn}
        xfetchPost={fetchPost}
        xonChangeType={onChangeType}
        xheadObj={headObj}
      />
    </div>
  );
};
export default ConsolidationPosting;
