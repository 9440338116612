import React from 'react';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Shop2Icon from '@mui/icons-material/Shop2';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import InfoIcon from '@mui/icons-material/Info';
import { styled, useTheme } from '@mui/material/styles';
import { LayoutRoutes } from '../router';
import './layout.css';
import HomeIcon from '@mui/icons-material/Home';
import BasicPopover from './Profile';
import Filter from './Filter';
import SideNav from './SideNav';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import Shop2OutlinedIcon from '@mui/icons-material/Shop2Outlined';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  NoMealsOutlined,
  QueueOutlined,
  SailingOutlined,
  SetMeal,
  SetMealOutlined
} from '@mui/icons-material';
import { DB_SetMenus } from '../DBSlice/SliceDB';

const otherMenus = [
  {
    id: 'Filter',
    icon: <Filter />,
    name: 'Filter'
  },
  {
    id: 'Profile',
    icon: <BasicPopover style={{ fontSize: '30px' }} />,
    name: 'Profile'
  }
];

export default function Layout(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const vMediaQuery = useMediaQuery('(max-width: 767px)');
  // const fLogOut = () => {
  //   dispatch(DB_Init());
  //   dispatch(Logout());
  // };
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [enableMenuButton, setEnableMenuButton] = useState(false);
  const [subMenuOption, setSubMenuOption] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const mainMenuArry = [
    '/',
    '/order',
    '/purchase',
    '/return',
    '/misInfo',
    '/OutletRequest',
    '/PendingFishOrder'
  ];
  // const history = useHistory()
  const fullMenuList = useSelector((state) => state.SliceDB.value.menuList);
  const [menuList, setMenuList] = useState([]);

  // const userInfo = useSelector((state) => state.SliceDB.value.userInfo);
  // const madinaGroup = useSelector((state) => state.SliceDB.value.madinaGroup);
  // const manualStockTrs = useSelector((state) => state.SliceDB.value.stockTransfer);
  // const FPConsPosting = useSelector((state) => state.SliceDB.value.consPosting);
  // const DCMargin = useSelector((state) => state.SliceDB.value.dcMargin);
  // const UNLoad = useSelector((state) => state.SliceDB.value.unload);
  const Item = styled('div')(({ theme }) => ({
    //padding: theme.spacing(1)
    //textAlign: 'center'
  }));
  const handleSideNavClose = (data) => {
    if (data === 'enable') {
      setEnableMenuButton(true);
      setSubMenuOpen(false);
    }
  };

  function handleMenuClick() {
    setSubMenuOpen(true);
    setEnableMenuButton(false);
  }

  const updateSubMenu = (obj) => {
    if (obj?.submenu?.length > 0) {
      const data = obj?.submenu.map((x) => {
        if (x.id === 'FP_FP03003') {
          return {
            ...x,
            path: '/VanTransSummary?isFishPurchase=N'
          };
        } else if (x?.id === 'FP_FP05006') {
          return {
            ...x,
            path: '/VanTransSummary?isFishPurchase=Y'
          };
        } else {
          return x;
        }
      });
      return data;
    }
  }
  

  useEffect(() => {
    if (fullMenuList?.length > 0 && menuList?.length == 0) {
      fullMenuList?.map((obj) => {
        if (obj?.id == 'FP_FP01') {
          const data = {
            ...obj,
            icon: <QueueOutlined style={{ fontSize: '30px' }} />
          };
          setMenuList((current) => [...current, data]);
        } else if (obj?.id == 'FP_FP02') {
          const data = {
            ...obj,
            icon: <InventoryOutlinedIcon style={{ fontSize: '30px' }} />
          };
          setMenuList((current) => [...current, data]);
        } else if (obj?.id == 'FP_FP03') {
          let data = {
            ...obj,
            icon: <Shop2OutlinedIcon style={{ fontSize: '30px' }} />,
            submenu: updateSubMenu(obj)
          };
          setMenuList((current) => [...current, data]);
        } else if (obj?.id == 'FP_FP04') {
          const data = {
            ...obj,
            icon: <AssignmentReturnOutlinedIcon style={{ fontSize: '30px' }} />
          };
          setMenuList((current) => [...current, data]);
        } else if (obj?.id == 'FP_FP05') {
          let data = {
            ...obj,
            icon: <SetMealOutlined style={{ fontSize: '30px' }} />,
            submenu: updateSubMenu(obj)
          };
          setMenuList((current) => [...current, data]);
        } else if (obj?.id == 'FP_FP06') {
          const data = {
            ...obj,
            icon: <InfoOutlinedIcon style={{ fontSize: '30px' }} />
          };
          setMenuList((current) => [...current, data]);
        }
      });
    }

    if(menuList?.length == 0){
      otherMenus?.map((i) => {
        setMenuList((current) => [...current, i]);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(DB_SetMenus(menuList));
  }, [menuList]);

  useEffect(() => {
    let pathMatch = subMenuOption?.some((path) =>
      path['path'].includes('/VanTransSummary')
        ? path['path'].includes(location.pathname)
        : path['path'] === location.pathname
    );

    if (vMediaQuery) {
      if (pathMatch) {
        handleSideNavClose('enable');
      }
    }

    if (mainMenuArry.includes(location.pathname) && location.pathname != selectedMenu) {
      menuList?.map((value, i) => {
        if (value?.submenu?.length > 0) {
          setSubMenuOpen(true);
          setSubMenuOption(value?.submenu);
        } else {
          setSubMenuOpen(false);
          setSubMenuOption(null);
          setEnableMenuButton(false);
        }
      });
    }
  }, [vMediaQuery, location, subMenuOption]);
  console.log(menuList, 'menuList');
  return (
    <Box flexGrow={1}>
      <Box className="main-menu-nav">
        <div className="logout gcContainer">
          <Grid
            container
            spacing={2}
            flexGrow={1}
            justifyContent="center"
            style={{ textAlign: 'center' }}
            // className="container"
          >
            <Grid item xs={3} sm={3} md={5}>
              <div
                className="d-flex "
                onClick={() => {
                  navigate('/');
                }}>
                <HomeIcon
                  style={{
                    fontSize: '40px',
                    marginLeft: vMediaQuery ? '1rem' : '2rem'
                  }}
                />
                {!vMediaQuery && (
                  <Typography variant="h7" style={{ margin: '10px' }}>
                    Fresh Purchase
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={7} sm={8} md={7}>
              <Grid container spacing={1} flexGrow={1} justifyContent="flex-end">
                {menuList?.map((obj, index) => (
                  <Grid
                    item
                    xs={4}
                    sm={1.7}
                    md={1.7}
                    lg={1.5}
                    key={index + 1}
                    style={{ paddingTop: vMediaQuery ? '15px' : '' }}
                    onClick={() => {
                      if (obj.name !== 'Filter') {
                        navigate(obj.path);
                        if (obj?.submenu?.length > 0) {
                          setSubMenuOpen(true);
                          setSubMenuOption(obj.submenu);
                          setSelectedMenu(obj.path);
                        } else {
                          setSubMenuOpen(false);
                          setSubMenuOption(null);
                          setEnableMenuButton(false);
                        }
                      }
                    }}>
                    {!vMediaQuery && (
                      <Typography variant="body2">
                        {obj?.icon}
                        <br />
                        {obj?.name}
                      </Typography>
                    )}
                    {vMediaQuery && index < 3 && <div>{obj?.icon}</div>}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={2} sm={1}>
              {vMediaQuery && menuList.length > 3 && (
                <div className="dropdown">
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                  <div className="dropdown-content" style={{ right: 0 }}>
                    <Grid container spacing={0} flexGrow={1} alignItems="center">
                      {menuList?.slice(3)?.map((value, i) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            key={i + 1}
                            sx={{ color: '#000' }}
                            className="d-flex justify-content-start m-1"
                            onClick={() => {
                              if (value.name !== 'Filter') {
                                navigate(value.path);
                                if (value?.submenu?.length > 0) {
                                  setSubMenuOpen(true);
                                  setSubMenuOption(value.submenu);
                                  setSelectedMenu(value.path);
                                } else {
                                  setSubMenuOpen(false);
                                  setSubMenuOption(null);
                                  setEnableMenuButton(false);
                                }
                              }
                            }}>
                            {/* <div style={{ flex: 1 }}>{value.icon}</div>
                            <div style={{ flex: 3 }}>{value.name}</div> */}
                            <div style={{display: 'flex'}}>
                              <span style={{ paddingRight: '5px' }}>{value.icon}</span>
                              <span style={{ fontSize: '16px',alignItems:'center' }}>{value.name}</span>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Item style={{ height: '100%' }}>
              <div style={{display:'flex'}}>
                {enableMenuButton && (
                  <MenuIcon onClick={handleMenuClick} sx={{ float: 'left', cursor: 'pointer' }} />
                )}

                {!vMediaQuery && (
                  <Grid container spacing={1} flexGrow={1} justifyContent="center">
                    {subMenuOpen && (
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <SideNav
                          sideNav={subMenuOpen}
                          subMenu={subMenuOption}
                          handleSideNavClose={handleSideNavClose}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={subMenuOpen ? 9 : 12}
                      md={subMenuOpen ? 9 : 12}
                      lg={subMenuOpen ? 9 : 12}>
                      <LayoutRoutes />
                    </Grid>
                  </Grid>
                )}
                {vMediaQuery && (
                  <Grid container spacing={1} flexGrow={1} justifyContent="center">
                    <Grid item xs={12}>
                      {subMenuOpen ? (
                        <SideNav
                          sideNav={subMenuOpen}
                          subMenu={subMenuOption}
                          handleSideNavClose={handleSideNavClose}
                        />
                      ) : (
                        <LayoutRoutes />
                      )}
                    </Grid>
                  </Grid>
                )}
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
