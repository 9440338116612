import { fExecuteAPI } from '../../Server/Apis';

export const GridData = [
  {
    B_TrnsId: 1,
    B_SrNo: 1,
    B_ItmCd: '100039763',
    B_ItemCd: 'AL MADINA ROASTED GRAM SPLIT',
    B_Qty: 10,
    B_Unit: 'PCS',
    UnitName: 'PCS',
    B_BaseQty: 10,
    B_UnitRate: 10,
    B_NetAmt: 100,
    B_Status: '',
    B_Type: '0',
    B_Acc_Qty: 0,
    B_Acc_BaseQty: 0,
    B_DC_LPCost: 0,
    B_Vendor: '',
    Vendorname: '',
    B_VendorCost: 0,
    B_VendorNetAmt: 0
  },
  {
    B_TrnsId: 1,
    B_SrNo: 2,
    B_ItmCd: '100039763',
    B_ItemCd: 'AL MADINA ROASTED GRAM SPLIT',
    B_Qty: 10,
    B_Unit: 'PCS',
    UnitName: 'PCS',
    B_BaseQty: 10,
    B_UnitRate: 10,
    B_NetAmt: 100,
    B_Status: '',
    B_Type: '0',
    B_Acc_Qty: 0,
    B_Acc_BaseQty: 0,
    B_DC_LPCost: 0,
    B_Vendor: '',
    Vendorname: '',
    B_VendorCost: 0,
    B_VendorNetAmt: 0
  },
  {
    B_TrnsId: 1,
    B_SrNo: 3,
    B_ItmCd: '100039763',
    B_ItemCd: 'AL MADINA ROASTED GRAM SPLIT',
    B_Qty: 10,
    B_Unit: 'PCS',
    UnitName: 'PCS',
    B_BaseQty: 10,
    B_UnitRate: 10,
    B_NetAmt: 100,
    B_Status: '',
    B_Type: '0',
    B_Acc_Qty: 0,
    B_Acc_BaseQty: 0,
    B_DC_LPCost: 0,
    B_Vendor: '',
    Vendorname: '',
    B_VendorCost: 0,
    B_VendorNetAmt: 0
  },
  {
    B_TrnsId: 1,
    B_SrNo: 4,
    B_ItmCd: '100039763',
    B_ItemCd: 'AL MADINA ROASTED GRAM SPLIT',
    B_Qty: 10,
    B_Unit: 'PCS',
    UnitName: 'PCS',
    B_BaseQty: 10,
    B_UnitRate: 10,
    B_NetAmt: 100,
    B_Status: '',
    B_Type: '0',
    B_Acc_Qty: 0,
    B_Acc_BaseQty: 0,
    B_DC_LPCost: 0,
    B_Vendor: '',
    Vendorname: '',
    B_VendorCost: 0,
    B_VendorNetAmt: 0
  }
];

export const searchVendor = async (xObj) => {
  try {
    const result = await fExecuteAPI('SEARCH_VENDOR', xObj);
    return result;
  } catch (error) {}
};
