import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton
} from '../../../component/ToolBox/ToolBox';
import { Checkbox } from '@mui/material';
function DispatchDetailCard({
  xItem,
  xSrNo,
  xItemName,
  xUnit,
  xPacking,
  xPickedQty,
  xUnitRate,
  xConfQty,
  xOnChangeConfQty,
  xAdjQty,
  xAdjAmt,
  xNetAmount,
  isReadQty,
  xSelected,
  xOnChangeSelect,
  xDisableSelect
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid
        container
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ alignItems: 'center' }}>

        <Grid item xs={11.5} sm={11.5} md={11.5}>
          <Grid
            container
            spacing={0.1}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xItem} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Sr.No'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xSrNo} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={2} sm={2} md={2}>
                  <CmpTypographyField xcomponent={'span'} xText={'Item Name'} />
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={0.5}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={9} sm={9} md={9} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xItemName} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Unit'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xUnit} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Packing'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xPacking} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Picked Qty'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
                  <CmpTypographyField xcomponent={'span'} xText={Number(xPickedQty).toFixed(2)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Unit Rate'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
                  <CmpTypographyField xcomponent={'span'} xText={Number(xUnitRate).toFixed(2)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Conf.Qty'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpInputField
                    xTextAlign="center"
                    xValue={xConfQty}
                    xType={'Number'} // text/number/password
                    xName={'ConfirmQty'}
                    xReadOnly={isReadQty}
                    xOnChange={xOnChangeConfQty}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Net.Amt'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
                  <CmpTypographyField xcomponent={'span'} xText={Number(xNetAmount).toFixed(2)} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Adj.Qty'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xAdjQty).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Adj.Amt'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xAdjAmt).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={.5} sm={.5} md={.5}>
          <Checkbox checked={xSelected} onChange={xOnChangeSelect} disabled={xDisableSelect} />
        </Grid>
      </Grid>
    </Box>
  );
}
export default DispatchDetailCard;
