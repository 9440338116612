import { fExecuteAPI } from '../../Server/Apis';

const getPurchaseReturnSummaryList = async (xObj) => {
  try {
    const vPurchaseReturnList = await fExecuteAPI('GET_PURCHASE_RETURN_SUMMARY_LIST', xObj);
    return vPurchaseReturnList;
  } catch (error) {}
};

const getPurchaseReturnItemList = async (xObj) => {
  try {
    const vPurchaseReturnItemList = await fExecuteAPI('GET_PURCHASE_RETURN_ITEM_LIST', xObj);
    return vPurchaseReturnItemList;
  } catch (error) {}
};

const updatePurchaseReturnItem = async (xObj) => {
  try {
    const vUpdatePurchaseReturnItem = await fExecuteAPI('UPDATE_PURCHASE_RETURN_ITEM', xObj);
    return vUpdatePurchaseReturnItem;
  } catch (error) {}
};

const getTaxDeptList = async (xObj) => {
  try {
    const vTaxDeptList = await fExecuteAPI('GET_TAX_DEPT_LIST', xObj);
    return vTaxDeptList.data;
  } catch (error) {}
};

export {
  getPurchaseReturnSummaryList,
  getPurchaseReturnItemList,
  updatePurchaseReturnItem,
  getTaxDeptList
};
