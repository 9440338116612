import { Close } from '@mui/icons-material';
import { Grid, Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { CmpButton } from '../../../component/ToolBox/ToolBox';
import AddNewItemModal from './AddNewItemModal';
import Inquiry from '../../Order/ProcessComponents/Inquiry'
import PriceEnquiry from './PriceEnquiry';
export default function GridItemOptionsModal({
    sSelectedRow,
    sItemList,
    setItemList,
    setOptionsModal,
}) {
    const [sShowAddItemModal, setShowAddItemModal] = useState(false);
    const [sShowInquiryModal, setShowInquiryModal] = useState(false);

    const onClickAltItem = () => {
        setShowAddItemModal(true);
    }
    return (
        <>
            <Box sx={{ flexGrow: 1, height: '80%' }}>
                {/* <Grid container spacing={0}>
                    <Grid item xs={12} className=" d-flex justify-content-end m-2">
                        <Close onClick={() => setOptionsModal(false)} />
                    </Grid>
                </Grid> */}
                <Grid container spacing={3} columns={{ xs: 10, sm: 10, md: 10, lg: 10 }} alignItems='center' className='m-3 d-flex'>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={onClickAltItem}
                            xLabel={'Alternative Item'}
                            xDisable={Number(sSelectedRow.PackQty) !== 0 || Number(sSelectedRow.Qty) !== 0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={() => { setShowInquiryModal(true) }}
                            xLabel={'Prie Enquiry'}
                        // xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER' || sGridEditing}
                        />
                        {/* <Inquiry data={{ ItmCd: sSelectedRow.ALTItmCd, ItmName: sSelectedRow.ALTItmName }} /> */}

                    </Grid>

                </Grid>

            </Box>
            <Modal
                open={sShowAddItemModal}
                onClose={() => setShowAddItemModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="modal-purchase-Modal">
                    <AddNewItemModal sItemList={sItemList} setItemList={setItemList} setShowAddItemModal={setShowAddItemModal} selectedSrNo={sSelectedRow.SrNo} altItem={true} />
                </Box>
            </Modal>

            <Modal
                open={sShowInquiryModal}
                onClose={() => setShowInquiryModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="modal-purchase-Modal">
                    <PriceEnquiry xItmCd={sSelectedRow.ALTItmCd} xItmName={sSelectedRow.ALTItmName} setShowInquiryModal={setShowInquiryModal} />
                </Box>
            </Modal>
        </>
    )
}
