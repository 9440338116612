import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './ReturnRequestReviewAction.css';
import { ArrowForwardIos } from '@mui/icons-material';
function ReturnRequestReviewBreadcrumbs() {
  const location = useLocation();
  const vPathName = location.pathname;
  return (
    <div className="container-fluid p-0 breadcrumb">
      {vPathName === '/ReturnRequestReview' ? (
        <div>
          <span
            className="breadcrumb-not-active "
            //  style={{ fontSize: '1rem', textDecorationLine: 'underline' }}
          >
            ReturnRequestReview{' '}
          </span>
        </div>
      ) : vPathName === '/ReturnRequestReviewDetail' ? (
        <div className="d-flex">
          <Link
            to="/ReturnRequestReview"
            state={location}
            // style={{ fontSize: '1rem', marginRight: '1rem' }}
            className={'breadcrumb-active mr-2'}>
            ReturnRequestReview
          </Link>
          <ArrowForwardIos color="info" style={{ marginRight: '1px' }} />
          <div>
            <span
              className="breadcrumb-not-active"
              //  style={{ fontSize: '0.85rem', textDecorationLine: 'underline' }}
            >
              ReturnRequestReviewDetail{' '}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default ReturnRequestReviewBreadcrumbs;
