import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
const initialState = {
  head: {
    location: '',
    dateFrom: moment().startOf('month'),
    dateTo: new Date(),
    status: 'All',
    Type: 'All',
    DC: 'N',
    Dept: ''
  },
  detail: {
    A_TransId: '',
    A_PostDt: new Date(),
    A_Loc: '',
    LocName: '',
    A_Driver: '',
    DriverName: '',
    A_Truck: '',
    TruckName: '',
    A_ReqTransId: 0,
    A_Type: '',
    A_PickStatus: '',
    A_PickDt: '',
    A_DispatchStatus: '',
    A_DispatchDt: '',
    A_RefNo: '',
    A_Narration: '',
    A_Vendor: '',
    Vendorname: '',
    ItemCount: 0,
    QTY_EDIT: 0,
    Dept: '',
    ReqID: '',
    Type: ''
  }
};

const DispatchSlice = createSlice({
  name: 'DispatchSlice',
  initialState: { value: initialState },

  reducers: {
    Dispatch_Head: (state, action) => {
      state.value.head = action.payload;
    },
    Dispatch_Detail: (state, action) => {
      state.value.detail = action.payload;
    },
    Dispatch_Detail_UpdateStatus: (state, action) => {
      state.value.detail.A_DispatchStatus = action.payload;
    }
  }
});

export const { Dispatch_Head, Dispatch_Detail, Dispatch_Detail_UpdateStatus } =
  DispatchSlice.actions;
export default DispatchSlice.reducer;
