import { fExecuteAPI } from '../../Server/Apis';
import { fAlertToast } from '../../Utility/Utilitys';

const getReturnRequestSummary = async (xObj) => {
  try {
    const getSummaryList = await fExecuteAPI('GET_RETURN_REQUEST_SUMMARY', xObj);
    return getSummaryList;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};
export const getDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};

const getKernValue = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_KERN', xObj);
    return result.data;
  } catch (error) {}
};
export { getReturnRequestSummary, getKernValue };
