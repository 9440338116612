export const Gridcolumns = [
  {
    field: 'A_TransId',
    headerName: 'TransId',
    width: 80,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'A_PostDt',
    headerName: 'Date',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'A_Loc',
    headerName: 'Location',
    width: 300,
    hide: true,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: 'LocName',
    headerName: 'Location',
    width: 150
  },
  {
    field: 'A_DCLoc',
    headerName: 'A_DCLoc',
    width: 150,
    hide: true
  },
  {
    field: 'DCLocName',
    headerName: 'DC Location',
    width: 150
  },
  {
    field: 'Type',
    headerName: 'Type',
    width: 150
  },
  {
    field: 'A_DocStatus',
    headerName: 'Status',
    width: 100,
    align: 'left',
    headerAlign: 'left'
  },
  {
    field: 'A_Type',
    headerName: 'Type',
    width: 100,
    align: 'center',
    headerAlign: 'center',
    hide: true
  },
  {
    field: 'A_DCStatus',
    headerName: 'A DCStatus',
    width: 100
    // hide: true
  },
  {
    field: 'A_Truck',
    headerName: 'Truck',
    width: 80,
    hide: true
  },
  {
    field: 'isDcReturn',
    headerName: 'DCReturn',
    width: 80
  },
  {
    field: 'DepartmentName',
    headerName: 'DepartmentName',
    width: 120
  }
];

export const GridcolumnsDetails = [
  {
    field: 'B_SrNo',
    headerName: 'SrNo',
    width: 80,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'B_ItmCd',
    headerName: 'ItemCode',
    width: 100
  },
  {
    field: 'B_ItemName',
    headerName: 'ItemName',
    width: 250
  },
  {
    field: 'B_Qty',
    headerName: 'Qty',
    width: 10,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: ({ value }) => Number(value).toFixed(2)
    // valueFormatter: ({ value }) => value.toFixed(2).replace(',', '.')
  },
  {
    field: 'B_Unit',
    headerName: 'Unit',
    width: 100
  },
  {
    field: 'B_BaseQty',
    headerName: 'BaseQty',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: ({ value }) => Number(value).toFixed(2)
  },
  {
    field: 'B_UnitRate',
    headerName: 'Unit Cost',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: ({ value }) => Number(value).toFixed(2)
  },
  {
    field: 'B_NetAmt',
    headerName: 'Net Amount',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: ({ value }) => Number(value).toFixed(2)
  },
  {
    field: 'B_ReasonName',
    headerName: 'Reason',
    width: 150
  }
];
export const GridDataColumnsItemSearch = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'ItmCd', headerName: 'ItmCd', width: 90 },
  { field: 'Name1', headerName: 'Name1', width: 250 },
  { field: 'NameFL', headerName: 'NameFL', width: 90 },
  { field: 'MainGrpName', headerName: 'MainGrpName', width: 90 },
  { field: 'SuppRefNo1', headerName: 'SuppRefNo1', width: 90 },
  { field: 'Model', headerName: 'Model', width: 90 },
  { field: 'BaseUnitName', headerName: 'BaseUnitName', width: 90 },
  { field: 'BarCode', headerName: 'BarCode', width: 90 }
];
export const statusData = ['All', 'Enter', 'Post', 'Deleted'];
export const DCStatusData = ['All', 'Accepted', 'Rejected', 'Pending', 'Partial Accepted'];
