import React from 'react';

export default function Dashboard() {
    return (
        <div style={{ height: '100%', textAlign: "center" }}>
            <h3>DASHBOARD PAGE</h3>
            <div className="dashboard">
                Content of the page
            </div>
        </div>
    );
};

