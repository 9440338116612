import { DataGrid } from '@mui/x-data-grid';
import React from 'react'

export default function PostModalGrid({
    xRows,
    xColumns,
    xRowHeight = 40,
    xHeaderHeight = 40
}) {
    return (
        <DataGrid
            rows={xRows}
            columns={xColumns}
            rowHeight={xRowHeight}
            headerHeight={xHeaderHeight}
            sx={{
                border: 0,
                borderColor: 'primary.light'
            }}
            componentsProps={{
                filterPanel: { sx: { maxWidth: "93vw" } }
              }}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            hideFooter
        />
    );
};