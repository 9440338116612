import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  reportFilter: {
    transferTyp: 'All',
    itmTyp: 'All',
    itmCd: '',
    itmName: '',
    mainGrpTyp: 'All',
    mainGrps: [],
    subGrpTyp: 'All',
    subGrps: [],
    grpTyp: 'All',
    grps: [],
    reportType: ''
  }
};

const ReturnReportSlice = createSlice({
  name: 'ReturnReportSlice',
  initialState: { value: initialState },

  reducers: {
    updateReportFilter: (state, action) => {
      state.value.reportFilter = action.payload;
    }
  }
});

export const { updateReportFilter } = ReturnReportSlice.actions;
export default ReturnReportSlice.reducer;
