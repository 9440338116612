import { Routes, Route } from 'react-router-dom';
import Notion from '../pages/Notion/Notion';

const NotionRoute = () => {
  return (
    <Routes>
      <Route path="/notion" element={<Notion />} />
    </Routes>
  );
};

export default NotionRoute;
