import React from 'react';
import { Link, useLocation } from 'react-router-dom';
export default function TransferBreadCrumbs() {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 breadcrumb">
            {vPathName === '/RequestTransferSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive">Transfer Summary{' '}</span>
                </div>
            ) : vPathName === '/RequestTransferDetail' ? (
                <div className="d-flex">
                    <Link
                        to="/RequestTransferSummary"
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        Transfer Summary
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Transfer Detail{' '}</span>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
