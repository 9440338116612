import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { getOrderSummary } from '../../Order/action';
import PendingOrderCard from './PendingOrderCard';
import { useNavigate } from 'react-router-dom';
import { updateTransHead } from '../PurchaseSlice';

export default function OrderModal({ setShowOrderModal }) {
  const [sOrderList, setOrderList] = useState([]);
  const rdxUser = useSelector((state) => state.SliceDB.value);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadDetail = useCallback(async () => {
    const reqObj = {
      buyerCd: rdxUser.userInfo.Buyer,
      fromDate: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      toDate: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      status: 'Enter',
      dcLocs: rdxFilterObj.DCList.toString(),
      usercd: rdxUser.login.userId
    };
    const result = await getOrderSummary(reqObj);
    console.log('sOrderListtttttt in trans modal' + JSON.stringify(result));
    const obj = result.ResultSet;
    setOrderList(obj);
  }, [setOrderList, rdxFilterObj, rdxUser]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  const fOnpressTransCard = (itm) => {
    dispatch(
      updateTransHead({
        TransId: '0',
        OrderId: itm.ID,
        Location: itm.Loc,
        LocationName: itm.LocName,
        Date: new Date(),
        Vendor: '',
        VendorName: '',
        ReferenceNo: '',
        ReferenceDate: new Date(),
        Status: 'ENTER',
        NetAmt: 0,
        DriverName: '',
        Parking: '',
        invoiceAmt: 0,
        Paymode: 'CREDIT',
        State: 'OPEN',
        Rounding: 0,
        MarketPurchase: itm.MarketPurchase,
        TRNS_Vendor_Os: 0,
        TRNS_Vendor_Os_Adj: 0,
        TRNS_Vendor_Reduction: 0,
        TRNS_Confirm_Inv: 'Y',
        TRNS_Direct_Deli: 'N',
        TaxPurchase: 'Y',
        TRNS_PARTY_MOBILE: '',
        CNTRL_PUR: itm.CNTRL_PUR,
        DCPurchase: itm.DCPurchase
      })
    );

    navigate('/PurchaseDetail');
  };
  return (
    <Box sx={{ flexGrow: 1 }} className="m-4">
      <Grid container spacing={0}>
        <Grid item xs={12} className="order-Modal-title d-flex justify-content-between">
          <span> Orders</span>
          <Close onClick={() => setShowOrderModal(false)} />
        </Grid>
      </Grid>
      {/* <Box sx={{ height: '70vh', overflowY: 'auto', marginTop: '20px' }}> */}
      <Grid
        container
        spacing={1}
        sx={{
          height: '70vh',
          overflowY: 'auto',
          marginTop: '1vh',
          paddingRight: '15px',
          alignContent: 'start'
        }}>
        {sOrderList.map((itm, i) => (
          <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => fOnpressTransCard(itm)}>
            <PendingOrderCard
              key={'order' + itm.ID}
              xOrdId={itm.ID}
              xPurcompletion={itm.Purcompletion}
              xPurAccuracy={itm.PurAccuracy}
              xDeviation={itm.deviation}
              xCostDeviation={itm.costdeviation}
              xPurchaseCount={itm.PurchaseCount}
              xOrdDate={itm.Ord_Date}
              xMarketPurchase={itm.MarketPurchase}
              xDCPurchase={itm.DCPurchase}
              xctrpuchase={
                itm?.CNTRL_PUR == 'Y' && cPurchaseFlg
                  ? 'Central Purchase'
                  : 'Direct Transfer to Branch'
              }
            />
          </Grid>
        ))}
      </Grid>
      {/* </Box> */}
    </Box>
  );
}
