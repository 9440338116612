import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MoreHoriz } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import BufferAllocationModal from './BufferAllocationModal';

export default function BufferItemsGrid({ rowsData, setUpdateRows, taxPurchase, editable, setGridEditing }) {
    const vMediaQuery = useMediaQuery('(max-width:900px)');

    const [sLocModal, setLocModal] = useState(false);
    const [sSelectedRow, setSelectedRow] = useState({
        SrNo: 0,
        ALTItmCd: '',
        ALTItmName: '',
        PackQty: 0,
        Qty: 0,
        ReceivedQty: 0
    });
    const onRowButtonClick = (params) => {
        setSelectedRow({
            SrNo: params.row.SrNo,
            ALTItmCd: params.row.ALTItmCd,
            ALTItmName: params.row.ALTItmName,
            PackQty: params.row.PackQty,
            Qty: params.row.Qty,
            ReceivedQty: params.row.ReceivedQty,
        });

    }

    const setQty = (params) => {
        //let row = updateValues({ ...params.row, Qty: Math.round(Number(params.value)) });
        let row = updateValues({ ...params.row, Qty: Number(Number(params.value).toFixed(2)) });
        const updatedRows = rowsData.map((itm) => (itm.SrNo === row.SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const setUnitRate = (params) => {
        let row = updateValues({ ...params.row, UnitRate: params.value });
        const updatedRows = rowsData.map((itm) => (itm.SrNo === row.SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };


    const setPRQty = (params) => {
        let prQty = isNaN(Number(params.value)) ? 0 : Number(params.value);

        // let row = { ...params.row, PRQty: Math.round(prQty) };
        let row = { ...params.row, PRQty: Number(prQty.toFixed(2)) };
        const updatedRows = rowsData.map((itm) => (itm.SrNo === row.SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const updateValues = (obj) => {
        const calculatedObj = calculate(
            obj.PackQty,
            obj.Qty,
            obj.UnitRate,
            obj.LDiscPerc,
            obj.TDiscPerc,
            obj.ExciseTaxAmt,
            obj.TaxPerc,
            obj.TaxPerc2,
            obj.Ad_PCQty,
            obj.FocAmt,
        );
        return { ...obj, ...calculatedObj };
    };

    const calculate = (
        PackQty,
        Qty,
        UnitRate,
        LDiscPerc,
        TDiscPerc,
        ExciseTaxAmt,
        TaxPerc,
        TaxPerc2,
        Ad_PCQty,
        FocAmt,
    ) => {
        PackQty = isNaN(PackQty) ? 0 : PackQty;
        Qty = isNaN(Qty) ? 0 : Qty;
        UnitRate = isNaN(UnitRate) ? 0 : UnitRate;
        LDiscPerc = isNaN(LDiscPerc) ? 0 : LDiscPerc;
        TDiscPerc = isNaN(TDiscPerc) ? 0 : TDiscPerc;
        ExciseTaxAmt = isNaN(ExciseTaxAmt) ? 0 : ExciseTaxAmt;
        TaxPerc = isNaN(TaxPerc) ? 0 : TaxPerc;
        TaxPerc2 = isNaN(TaxPerc2) ? 0 : TaxPerc2;
        Ad_PCQty = isNaN(Ad_PCQty) ? 0 : Ad_PCQty;
        FocAmt = isNaN(FocAmt) ? 0 : FocAmt;


        const roundedPckQTy = PackQty == 0 ? 1 : PackQty;

        const BaseQty = Qty * roundedPckQTy;
        const GrossAmt = Qty * UnitRate;
        const LDiscAmt = (LDiscPerc / 100) * GrossAmt;
        const TDiscAmt = (TDiscPerc / 100) * (GrossAmt - LDiscAmt);
        const ExciseTaxNetAmt = ExciseTaxAmt * Qty;
        const Taxable = GrossAmt - LDiscAmt - TDiscAmt - FocAmt + ExciseTaxNetAmt;
        let TaxAmt = (TaxPerc / 100) * Taxable;
        let TaxAmt2 = (TaxPerc2 / 100) * Taxable;
        let Ad_PCRate = Ad_PCQty * roundedPckQTy;
        let Ad_NetCessAmt = Ad_PCRate * Qty;
        if (taxPurchase === 'N') {
            TaxAmt = 0;
            TaxAmt2 = 0;
            Ad_PCRate = 0;
            Ad_NetCessAmt = 0;
        }

        const Total = Taxable + TaxAmt + TaxAmt2 + Ad_NetCessAmt;

        return {
            PackQty: roundedPckQTy,
            VendorPackQty: roundedPckQTy,
            Qty: Qty,
            BaseQty: BaseQty,
            UnitRate: UnitRate,
            GrossAmt: GrossAmt,
            LDiscAmt: LDiscAmt,
            TDiscAmt: TDiscAmt,
            ExciseTaxNetAmt: ExciseTaxNetAmt,
            Taxable: Taxable,
            TaxAmt: TaxAmt,
            TaxAmt2: TaxAmt2,
            Ad_PCRate: Ad_PCRate,
            Ad_NetCessAmt: Ad_NetCessAmt,
            Total: Total,
        }
    };

    const preProcessEditCellProps = async (params) => {
        const errorMessage = await validateNumber(params.props.value);
        return { ...params.props, error: errorMessage };
    };

    const validateNumber = async (input) => {
        return Number(input) < 0 ? 'Negative value found' : null;
    }

    const columns = [
        {
            field: 'SrNo',
            headerName: 'Sr No.',
            // flex: 1,
            width: 50,
            align: 'center',
            headerAlign: 'center',
            hide: vMediaQuery
        },
        {
            field: 'click',
            headerName: '',
            // flex: .5,
            width: 30,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {

                return (
                    <IconButton size='small' color='primary' onClick={() => { onRowButtonClick(params); setLocModal(true); }}>
                        <MoreHoriz />
                    </IconButton>
                );
            }
        },

        {
            field: 'ALTItmCd',
            headerName: 'ItmCd',
            // flex: 1,
            width: 80,
            hide: true
        },
        {
            field: 'ALTItmName',
            headerName: 'Item',
            // flex: 4,
            width: !vMediaQuery ? 500 : 300,
        },

        {
            field: 'PackQty',
            headerName: 'Pack Qty.',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            hide: vMediaQuery,
            valueFormatter: ({ value }) => Number(value).toFixed(2),
        },
        {
            field: 'Qty',
            headerName: 'Pur.CTN',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            type: 'number',
            // preProcessEditCellProps,
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },
            valueSetter: setQty,
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'ReceivedQty',
            headerName: 'Received CTN',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'PRQty',
            headerName: 'PR.CTN',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            type: 'number',
            // preProcessEditCellProps,
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },
            valueSetter: setPRQty,
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'UnitRate',
            headerName: 'Rate',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            type: 'number',
            //  preProcessEditCellProps,
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },
            valueSetter: setUnitRate,
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'Taxable',
            headerName: 'Amount',
            // flex: 2,
            width: 80,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
    ];

    return (
        <>
            <Box
                sx={{
                    height: '44vh',
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary'
                    },
                    '& .textPrimary': {
                        color: 'text.primary'
                    },
                    '& .Mui-error': {
                        backgroundColor: `rgb(126,10,15, 0.1})`,
                        color: '#ff4343',
                    },
                }}>
                <DataGrid
                    rows={rowsData}
                    getRowId={(row) => row.SrNo}
                    columns={columns}
                    rowHeight={45}
                    headerHeight={45}
                    sx={{
                        border: 2,
                        borderColor: 'primary.light',
                    }}
                    componentsProps={{
                        filterPanel: { sx: { maxWidth: "93vw" } }
                      }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                    }

                    onCellEditStart={() => setGridEditing(true)}
                    onCellEditStop={() => setGridEditing(false)}
                    onCellEditCommit={() => setGridEditing(false)}

                />
            </Box>
            <Modal
                open={sLocModal}
                onClose={() => setLocModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="model-sales-invoice-GCVanMaster-Modal">
                    <BufferAllocationModal sSelectedRow={sSelectedRow} rowsData={rowsData} setUpdateRows={setUpdateRows} editable={editable} setLocModal={setLocModal} />
                </Box>
            </Modal>
        </>
    );
}
