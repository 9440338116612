import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import DCReceipt_Breadcrumbs from './DCReceiptBreadCrumbs';
import { Box, Grid, useMediaQuery } from '@mui/material';
import {
  CmpButton,
  CmpDatePickerField,
  CmpInputField,
  CmpSelectArrField,
  CmpSelectField,
  CmpTypographyField
} from '../../component/ToolBox/ToolBox';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { GridDetailcolumns } from './DCReceiptAction';
import { fAlertToast, getGitterColumns } from '../../Utility/Utilitys';
import { GET_ReturnRequest_DCReceipt_Detail, fExecuteAPI } from '../../Server/Apis';
import { getReturnRequestItemUpdate } from './action';
import { DCReceipt_RowDetail } from './DCReceiptSlice';

const DCReceiptDetail = () => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const dispatch = useDispatch();
  const xRDRowDetail = useSelector((state) => state.DCReceiptSlice.value.detailObj);
  const [sGridrowsDetail, setGridrowsDetail] = useState([]);
  const [sGridEditing, setGridEditing] = useState(false);

  const onLoadPage = async () => {
    const result = await Return_Request_DCReceipt_Detail();
    if (result?.length > 0) {
      let GridID = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
      setGridrowsDetail(GridID);
    } else {
      setGridrowsDetail([]);
    }
  };

  const Return_Request_DCReceipt_Detail = async () => {
    let detailObj = {
      v_TRNID: xRDRowDetail.A_TransId
    };

    const vDetail = await fExecuteAPI('GET_ReturnRequest_DCReceipt_Detail', detailObj);
    if (vDetail?.status === 200) {
      return vDetail?.data?.ResultSet;
    } else {
      fAlertToast('FAILED', vDetail?.response.data.message || 'Error in loading');
      setGridrowsDetail([]);
      return [];
    }
  };

  const setConfrimQty = (params) => {
    console.log(
      Number(params.row.AcceptBaseQty),
      Number(params.value),
      Number(params.row.AcceptBaseQty) > Number(params.value)
    );
    if (Number(params.value) > Number(params.row.AcceptBaseQty)) {
      fAlertToast('FAILED', 'Confirm Qty should not be greater than Accept Qty');
      return params.row;
    } else {
      let itemList = sGridrowsDetail.map((i) => {
        console.log(i.id === params.row.id);
        return i.id === params.row.id
          ? {
              ...i,
              DCConfirmQty: Number(params.value) < 0 ? 0 : Number(params.value)
            }
          : i;
      });

      setGridrowsDetail(itemList);
      console.log('itemList', itemList);
      return { ...params.row, DCConfirmQty: params.value };
    }
  };

  const columns = GridDetailcolumns.map((x) => {
    if (x.field === 'DCConfirmQty') {
      return {
        ...x,
        valueSetter: setConfrimQty,
        editable: true,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'mui-request-grid-final-qty',
        width: vMediaQuery ? 50 : 80
      };
    } else {
      return x;
    }
  });

  const returnRequestItemUpdate = async (isSave) => {
    let QtyCheck = sGridrowsDetail.find((i) => {
      return Number(i.DCConfirmQty) > Number(i.AcceptBaseQty);
    });
    if (QtyCheck) {
      fAlertToast(
        'FAILED',
        `confirm quantity should not be greater then AcceptBaseQty(SrNo:${QtyCheck?.B_SrNo})`
      );
    } else {
      let itemList = sGridrowsDetail.map((i) => {
        return {
          v_B_SrNo: i.B_SrNo,
          v_B_Confirm_Qty: Number(i.DCConfirmQty)
        };
      });

      const vItemObj = {
        A_TransId: xRDRowDetail.A_TransId,
        lineItem: itemList,
        isSave: isSave
      };

      const vItem = await getReturnRequestItemUpdate(vItemObj);
      if (vItem?.status == 200) {
        if (!isSave) {
          dispatch(
            DCReceipt_RowDetail({
              ...xRDRowDetail,
              DC_RECEIVED_STATUS: 'POST'
            })
          );
        }
        return vItem.data.ResultSet;
      }
    }
  };

  useEffect(() => {
    onLoadPage();
  }, []);

  return (
    <div className="container">
      <div>
        <ToastContainer />
      </div>
      <DCReceipt_Breadcrumbs />
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ marginBottom: vMediaQuery ? '1rem' : '2rem' }}>
          <Grid item xs={4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => returnRequestItemUpdate(true)} // Pass true for Save
              xLabel={'Save'}
              xDisable={xRDRowDetail.DC_RECEIVED_STATUS.toUpperCase() != 'ENTER' || sGridEditing}
            />
          </Grid>
          <Grid item xs={4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => returnRequestItemUpdate(false)} // Pass false for Post
              xLabel={'Approve'}
              xDisable={xRDRowDetail.DC_RECEIVED_STATUS.toUpperCase() != 'ENTER' || sGridEditing}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
              </Grid>
              <Grid item xs={0.6} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.7} sm={8} md={8}>
                <CmpInputField
                  xValue={xRDRowDetail?.A_TransId}
                  xType={'text'} // text/number/password
                  xName={'transId'}
                  xReadOnly={true}
                  xOnChange={''}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Date'} />
              </Grid>
              <Grid item xs={0.6} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.7} sm={8} md={8}>
                <CmpDatePickerField
                  xTabIndex="3"
                  xValue={xRDRowDetail?.A_PostDt}
                  // xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={false}
                  xReadOnly={true}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={0.6} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.7} sm={8} md={8}>
                <CmpInputField
                  xValue={xRDRowDetail?.LocName}
                  xType={'text'} // text/number/password
                  xName={'location'}
                  xReadOnly={true}
                  xOnChange={''}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={0.6} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.7} sm={8} md={8}>
                <CmpInputField
                  xValue={xRDRowDetail?.DC_RECEIVED_STATUS}
                  xType={'text'} // text/number/password
                  xName={'DCStatus'}
                  xReadOnly={true}
                  xOnChange={''}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ height: '40vh', width: '100%' }} className="mt-4">
          <DataGrid
            rowHeight={45}
            headerHeight={50}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            onCellEditStart={() => setGridEditing(true)}
            onCellEditStop={() => setGridEditing(false)}
            onCellEditCommit={() => setGridEditing(false)}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            rows={sGridrowsDetail}
            columns={columns}
            hideFooter={sGridrowsDetail.length > 100}
            // rowsPerPageOptions={[10]}
            //  selectionModel={selectedRow ? [selectedRow.id] : []} // Assuming you have an 'id' field in your rows
            //  onSelectionModelChange={handleRowSelection}
            //onRowClick={(item) => fOnRowClick(item)}
          />
        </Box>
      </Box>
    </div>
  );
};

export default DCReceiptDetail;
