import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { CmpTypographyField, CmpTypographyFieldColor } from '../../component/ToolBox/ToolBox';
import { getReportColumns, getReturnSummaryReport } from './actions';
import { ToastContainer } from 'react-toastify';
import './ReturnReport.css';
import ReportGrid from './ProcessComponents/ReportGrid';
import RequestReportGrid from './ProcessComponents/RequestReportGrid';

export default function RequestReports() {
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const userInfo = useSelector((state) => state.SliceDB.value.userInfo);

  const [sFilteredDCNames, setFilteredDCNames] = useState([]);
  const [sFilteredLocNames, setFilteredLocNames] = useState([]);

  const [sDetailObj, setDetailObj] = useState([]);
  const [sGridColumns, setGridColumns] = useState([]);

  const fGetDetailList = useCallback(async () => {
    const obj = {
      DCLoc: rdxFilterObj.DCList.toString(),
      fromDt: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      toDt: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      locList: rdxFilterObj.locList.toString()
    };
    const result = await getReturnSummaryReport(obj);
    if (result.ResultSet?.length > 0) {
      setDetailObj(
        result.ResultSet.map((i) => {
          return { ...i, id: i.SrNo };
        })
      );
    } else {
      setDetailObj([]);
    }
  }, [rdxFilterObj]);

  const fOnPageLoad = useCallback(async () => {
    let filteredDC = dcLoclist.filter((i) => rdxFilterObj.DCList.includes(i.UId));
    setFilteredDCNames(filteredDC.map((i) => i.Name1));

    let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    setFilteredLocNames(filteredLoc.map((i) => i.Name1));

    await fGetDetailList();
  }, [rdxFilterObj, dcLoclist, locList, fGetDetailList]);

  const reportColumns = useCallback(() => {
    if (
      userInfo.DCRequest === 'Y' &&
      userInfo.Grp != '01' &&
      userInfo.DCAdmin !== 'Y' &&
      userInfo.DCUser !== 'Y'
    ) {
      setGridColumns(getReportColumns('REQUEST_BR'));
    } else {
      setGridColumns(getReportColumns('REQUEST'));
    }
  }, [userInfo]);

  useEffect(() => {
    fOnPageLoad();
    reportColumns();
    console.log('caleed 1.....');
  }, [fOnPageLoad, reportColumns]);

  return (
    <div className="reportContainer">
      <div
      // className="my-2"
      >
        {/* <ReviewBatchCreateBreadCrumbs /> */}
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className=" my-2 mb-4">
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={4.05} md={3.05}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.95}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={6.95} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={4} md={3}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6} sm={7} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2} md={3}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={9.5} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
                {/* <CmpSelectField
                                    xLabelText="DC Location"
                                    xValue={sHeadObj.DClocation}
                                    xOnChange={(event) => onChangeDCLocation(event)}
                                    xName={'DCLocation'}
                                    xData={dcLoclist}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                    xTabIndex={'1'}
                                    xref={scanFocusOnLoad}
                                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2} md={1}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.35}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.8} sm={9.5} md={10.6}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />

                {/* <CmpMultiSelectAll
                                    xLabelText="Location"
                                    xValue={sHeadObj.LocList}
                                    xOnChange={(event) => onChangeLocList(event)}
                                    xName={'Location'}
                                    xData={locList.map(i => { return { id: i.UId, name: i.Name1 } })}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                    xTabIndex={'4'}
                                /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          width="100%"
          className="mt-4 pt-4"
          sx={{
            height: '70vh',
            width: '100%',
            '& .actions': {
              color: 'text.secondary'
            },
            '& .textPrimary': {
              color: 'text.primary'
            },
            '& .Mui-error': {
              backgroundColor: `rgb(126,10,15, 0.1})`,
              color: '#ff4343'
            }
          }}>
          <RequestReportGrid xRows={sDetailObj} xColumns={sGridColumns} />
          {/* <DataGrid
                        rows={sDetailObj}
                        getRowId={(row) => row.SrNo}
                        columns={columns}
                        rowHeight={50}
                        headerHeight={60}
                        sx={{
                            border: 0,
                            borderColor: 'primary.light',
                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                            '& .MuiDataGrid-row:hover': {
                                color: 'primary.main',
                                cursor: 'pointer',
                                fontWeight: 500,
                                // backgroundColor: '#ebeef0',
                                //boxShadow: 'rgba(0, 0, 0, 0.35) 10px 15px 15px'
                            },

                        }}
                        components={{ Toolbar: GridToolbar }}
                        // getRowClassName={(params) =>
                        //     params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-report-grid-even-row' : 'mui-report-grid-odd-row'
                        // }
                        getRowClassName={(params) =>
                            'mui-report-grid-row'
                        }

                    /> */}
        </Box>
      </Box>
    </div>
  );
}
