import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import { CmpTypographyField, CmpTypographyFieldColor,StatusIcon } from '../../../component/ToolBox/ToolBox';


export default function TransCard({
    xVanTransId,
    xVendor,
    xBuyer,
    xTruck,
    xDriver,
    xPhone,
    xDate,
    xLocation,
    xStatus,
}) {
    return (
      <Box sx={{ flexGrow: 1 }} className="border-card-2">
        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={11} sm={11} md={11} style={{ textAlign: 'center', paddingBottom: '15px' }}>
            <CmpTypographyField xVariant="h3" xText={xVanTransId} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              {/* 

                        <Grid item xs={12} sm={12} md={12} lg={7}>
                            <Grid
                                container
                                spacing={0}
                                columns={{ xs: 12, sm: 12, md: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={3} sm={5} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                                    <CmpTypographyField xcomponent={'span'} xText={xVendor} />
                                </Grid>
                            </Grid>
                        </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Buyer'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xBuyer} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xTruck} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Driver'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xDriver} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Phone Number'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xPhone} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xDate} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={5} md={7} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xLocation} />
                  </Grid>

                  <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'end' }}>
                    <StatusIcon xStatus={xStatus} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11.8} sm={11.8} md={11.8} style={{ padding: '5px 0px' }}>
            <Typography
              variant="body2"
              component={'span'}
              fontSize="14px"
              className="wrap-ellipsis">
              {xVendor}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
}
