import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './PurchaseReturn.css';
import { fAlertToast } from '../../Utility/Utilitys';
import { CmpTypographyField, CmpButton, CmpDatePickerField } from '../../component/ToolBox/ToolBox';
import PurchaseReturnBreadCrumbs from './PurchaseReturnBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseReturnItemList, getTaxDeptList, updatePurchaseReturnItem } from './action';
import { PurchaseReturn_Detail_TransDate } from './PurchaseReturnSlice';
import { useCallback } from 'react';
import DCConfirmationDetailCard from '../DC_Confirmation/processComponent/DCConfirmationDetailCard';
import PurchaseReturnDetailCard from './processComponent/PurchaseReturnDetailCard';

function PurchaseReturnDetail() {
  const dispatch = useDispatch();
  const xItemDetail = useSelector((state) => state.PurchaseReturnSlice.value.detail);
  const rdxuserId = useSelector((state) => state.SliceDB.value.login.userId);

  const [sItemList, setItemList] = useState([]);
  const [sTaxDept, setTaxDept] = useState([]);

  const getPurchaseReturnDetailItemLists = useCallback(async () => {
    const itemListObj = {
      v_vendor: xItemDetail.A_Vendor,
      v_Loc: xItemDetail.DCLoc
    };
    const result = await getPurchaseReturnItemList(itemListObj);
    console.log('*******getPurchaseReturnItemList******** ', result);
    setItemList(result.data.ResultSet);
  }, [xItemDetail.A_Vendor, xItemDetail.DCLoc]);

  useEffect(() => {
    getPurchaseReturnDetailItemLists();
    getDeptList();
  }, [getPurchaseReturnDetailItemLists, xItemDetail.A_TransId]);

  const getDeptList = async () => {
    const deptObj = {};
    const result = await getTaxDeptList(deptObj);
    const updateList = result.ResultSet.Tax1.map((itm) => ({
      ...itm,
      value: itm.UID,
      lable: itm.Name1
    }));
    setTaxDept(updateList);
  };

  const Validation = () => {
    let iRes = true;

    const confrQty = sItemList.filter((data) => data.B_Conf_Qty === '');
    if (confrQty.length > 0) {
      fAlertToast('FAILED', 'Conf Qty cannot be empty');
      return (iRes = false);
    }

    const unitRate = sItemList.filter((data) => data.B_Conf_UnitRate === '');
    if (unitRate.length > 0) {
      fAlertToast('FAILED', 'unit Rate cannot be empty');
      return (iRes = false);
    }

    const taxDept = sItemList.filter((data) => data.B_TaxDept === '');
    if (taxDept.length > 0) {
      fAlertToast('FAILED', 'Tax Dept cannot be empty');
      return (iRes = false);
    }

    const adjQtyRange = sItemList.filter((data) => data.B_Conf_Qty > data.B_Pick_Qty);
    if (adjQtyRange.length > 0) {
      fAlertToast('FAILED', 'Confrm Qty cannot be greater than picked Qty');
      return (iRes = false);
    }

    return iRes;
  };

  const UpdatePurchaseReturn = async (xStatus) => {
    const updateList = sItemList.map((itm) => ({
      v_B_TrnsId: itm.A_TransId,
      v_B_SrNo: itm.B_SrNo,
      v_B_Conf_Qty: itm.B_Conf_Qty,
      v_B_Conf_UnitRate: itm.B_Conf_UnitRate,
      v_B_Conf_NetAmt: itm.B_Conf_NetAmt,
      v_B_Adj_Qty: itm.B_Adj_Qty,
      v_B_Adj_Amt: itm.B_Adj_Amt
      // v_B_TaxDept: itm.B_TaxDept,
      // v_B_TaxPerc: itm.B_TaxPerc,
      // v_B_TaxAmt: itm.B_TaxAmt
    }));

    if (Validation()) {
      const updateObj = {
        status: xStatus === 'save' ? 'Enter' : 'Post',
        TransDt: moment(xItemDetail.A_TransDt).format('MM/DD/YYYY'),
        userId: rdxuserId,
        ItemList: updateList
      };
      const result = await updatePurchaseReturnItem(updateObj);
      if (result.status === 200) {
        fAlertToast('SUCCESS', xStatus === 'save' ? 'Save Successfully' : 'Post Successfully');
      }
    }
  };

  const Calculation = (event, item, val) => {

    console.log('srrnnooo', item)
    // const objResult = sTaxDept.find((obj) => {
    //   if (obj.value === event.target.value) {
    //     return obj;
    //   }
    // });
    let updatedValue = sItemList.map((obj) => {
      if (obj.A_TransId === item.A_TransId && obj.B_SrNo === item.B_SrNo) {
        let objUpdate = obj;
        obj.B_Conf_Qty = val === 'ConfrmQty' ? event.target.value : obj.B_Conf_Qty;
        obj.B_Conf_UnitRate = val === 'unitRate' ? event.target.value : obj.B_Conf_UnitRate;
        // obj.B_TaxDept = val === 'taxDpt' ? event.target.value : obj.B_TaxDept;
        obj.B_Conf_NetAmt = obj.B_Conf_Qty * obj.B_Conf_UnitRate;
        obj.B_Adj_Qty = obj.B_Pick_Qty - obj.B_Conf_Qty;
        // obj.B_TaxAmt = (val === 'taxDpt' ? objResult.TaxPerc : obj.B_TaxPerc) * obj.B_Conf_NetAmt;

        return objUpdate;
      } else {
        return obj;
      }
    });
    setItemList(updatedValue);
  };

  const onChangeTransDate = (newValue) => {
    dispatch(PurchaseReturn_Detail_TransDate(newValue.$d));
  };

  // const onChangeConfrmQty = (event, item) => {
  // let updatedValue = sItemList.map((obj) => {
  //   if (obj.B_ItmCd === item.B_ItmCd) {
  //     let objUpdate = obj;
  //     obj.B_Conf_Qty = event.target.value;
  //     obj.B_Conf_NetAmt = Number(event.target.value) * obj.B_Conf_UnitRate;
  //     obj.B_Adj_Qty = obj.B_Pick_Qty - obj.B_Conf_Qty;
  //     return objUpdate;
  //   } else {
  //     return obj;
  //   }
  // });
  // setItemList(updatedValue);
  // };

  // const onChangeUnitRate = (event, item) => {
  //   let updatedValue = sItemList.map((obj) => {
  //     if (obj.B_ItmCd === item.B_ItmCd) {
  //       let objUpdate = obj;
  //       obj.B_Conf_UnitRate = event.target.value;
  //       obj.B_Conf_NetAmt = Number(event.target.value) * obj.B_Conf_Qty;
  //       obj.B_Adj_Qty = obj.B_Pick_Qty - obj.B_Conf_Qty;
  //       return objUpdate;
  //     } else {
  //       return obj;
  //     }
  //   });
  //   setItemList(updatedValue);
  // };

  // const onChnageTaxDept = (event, item) => {
  //   const objResult = sTaxDept.find((obj) => {
  //     if (obj.value === event.target.value) {
  //       return obj;
  //     }
  //   });
  //   let updatedValue = sItemList.map((obj) => {
  //     if (obj.B_ItmCd === item.B_ItmCd) {
  //       let objUpdate = obj;
  //       obj.B_TaxDept = event.target.value;
  //       obj.B_Conf_NetAmt = obj.B_Conf_UnitRate * obj.B_Conf_Qty;
  //       obj.B_TaxAmt = objResult.TaxPerc * obj.B_Conf_NetAmt;
  //       return objUpdate;
  //     } else {
  //       return obj;
  //     }
  //   });
  //   setItemList(updatedValue);
  // };

  return (
    <div className="container PurchaseReturn">
      <div>
        <ToastContainer />
      </div>
      <div 
      // className="my-2"
      >
        <PurchaseReturnBreadCrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => UpdatePurchaseReturn('save')}
              xLabel={'Save'}
              xDisable={false}
            />
          </Grid>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => UpdatePurchaseReturn('post')}
              xLabel={'Post'}
              xDisable={false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.Vendorname} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.DCLocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Dispatched Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(xItemDetail.A_DispatchDt).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Transaction Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5} sm={2.5} md={2.5} lg={1.5}>
                <CmpDatePickerField
                  xValue={xItemDetail.A_TransDt}
                  xOnChange={(newValue) => onChangeTransDate(newValue)}
                  xName={'Trans Date'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="scrol-overflow">
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            sx={{ alignItems: 'center' }}>
            {sItemList.map((itm) => {
              return (
                <Grid item xs={12} key={itm.B_ItmCd} sm={12} lg={6} md={6}>
                  <PurchaseReturnDetailCard
                    xDisptachNo={itm.A_TransId}
                    xLoc={itm.LocName}
                    xItmCode={itm.B_ItmCd}
                    xItmName={itm.ItemName}
                    xPickedQty={itm.B_Pick_Qty}
                    xDisptached={itm.B_Disp_Qty}
                    xConfrmQty_Value={itm.B_Conf_Qty}
                    xOnchangeConfrmQty={(event) => Calculation(event, itm, 'ConfrmQty')}
                    xUnitRateValue={itm.B_Conf_UnitRate}
                    xOnchangeUnitRate={(event) => Calculation(event, itm, 'unitRate')}
                    xNetAmnt={itm.B_Conf_NetAmt}
                    xAdjQty={itm.B_Adj_Qty}
                    xUnit={itm.UnitName}
                  // xTaxDeptArr={sTaxDept}
                  // xTaxAmnt={itm.B_TaxAmt}
                  // xTaxDeptValue={itm.B_TaxDept}
                  // xOnChangeTxDept={(event) => Calculation(event, itm, 'taxDpt')}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Box>
    </div>
  );
}
export default PurchaseReturnDetail;
