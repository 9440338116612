import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import { CmpButton, CmpInput, CmpTypographyField } from '../../component/ToolBox/ToolBox';
import UnloadBreadCrumbs from './ProcessComponents/UnloadBreadCrumbs';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUnloadBatchDetailSave,getUnloadBatchDetailDel,getUnloadBatchDetailGrid } from './actions';
import UnloadBatchGrid from './ProcessComponents/UnloadBatchGrid';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';

export default function UnLoadingDetailItem() {

    const batchInfo = useSelector((state)=>state.OutletRequestSlice.value.batchInfo);
    const batchItem = useSelector((state)=>state.OutletRequestSlice.value.batchItem);
    const [sHeadObj, setHeadObj] = useState({
        rowId: 0,
        qty: '',
    });

    const [sReceivingList, setReceivingList] = useState({
        rowId: 0,
        qty: 0
    });


    const [sReceivedSum, setReceivedSum] = useState(batchItem.receivedQty);

    const loadItems = useCallback(async () => {
        const obj = {
            v_Batch_ID: batchInfo?.BatchNo,
            v_SrNo: batchItem?.srNo
        };
        const data = await getUnloadBatchDetailGrid(obj);
        const responseData = data.ResultSet.map((obj, index) => {
          return { ...obj, id: index + 1 };
        });
        setReceivingList(responseData);
        
        let total = responseData.reduce((prevValue, i) => {
            return prevValue + Number(i.C_Qty);
        }, 0);

        setReceivedSum(total);
    }, [setReceivingList, batchInfo, batchItem]);

    useEffect(() => {
        loadItems();
    }, [loadItems]);

    const fOnclickNew = () => {
        setHeadObj({
            rowId: 0,
            qty: ''
        });
    };

    const fOnclickSave = async () => {
        if (Number(sHeadObj.qty) === 0) {
            fAlertToast('FAILED', 'Qunatity should not be 0');
            return;
        }
        console.log(sReceivingList,"sReceivingList")
        let gridTotal = sReceivingList.reduce((prevValue, i) => {
            return prevValue + Number(i.C_Qty);
        }, 0);

        let existingVal = 0;
        if (Number(sHeadObj.rowId) !== 0) {
            existingVal = sReceivingList.find(i => Number(i.rowId) === Number(sHeadObj.rowId))?.C_Qty;
        }


        if (Number(sHeadObj.qty) + gridTotal - Number(existingVal) > Number(batchItem.totalQty)) {
            fAlertToast('FAILED', 'Can not receive more than purchased quantity');
            return;
        }
        console.log(sHeadObj,"sHeadObj")
        let obj = {
          v_C_BatchID: batchInfo?.BatchNo,
          v_C_SrNo: batchItem?.srNo,
          v_C_RowID: sHeadObj?.rowId,
          v_C_Qty: Number(sHeadObj.qty)
        };
        await getUnloadBatchDetailSave(obj);
        loadItems();
        setHeadObj({
            rowId: 0,
            qty: ''
        });
    };

    const onDeleteClick = async (rowId) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete?',
            buttons: [
                {
                    className: 'alertBtn',
                    label: 'Yes',
                    onClick: async () => {
                        let obj = {
                          v_C_BatchID: batchInfo?.BatchNo,
                          v_C_SrNo: batchItem?.srNo,
                          v_C_RowID: rowId
                        };

                        await getUnloadBatchDetailDel(obj);
                        loadItems();
                    }
                },
                {
                    className: 'alertBtn',
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }



    return (
      <div className="container openPurchase">
        <div>
          <UnloadBreadCrumbs />
        </div>
        <div>
          <ToastContainer />
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            columns={{ xs: 12, sm: 12, md: 10 }}
            className="mt-2 d-flex justify-content-between"
            sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={10} md={12}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}
                className="mb-2">
                <Grid item xs={11} sm={10} md={11} textAlign="center">
                  <CmpTypographyField xVariant="h3" xText={batchItem.itemName} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={6}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12 }}
                className="mb-4"
                sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={4}>
                      <CmpTypographyField xcomponent={'span'} xText={'Total'} />
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={Number(batchItem.totalQty).toFixed()}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      <CmpTypographyField xcomponent={'span'} xText={batchItem.unitName} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={4}>
                      <CmpTypographyField xcomponent={'span'} xText={'Received'} />
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      {/* <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransDetail.receivedQty).toFixed()} /> */}
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={Number(sReceivedSum).toFixed()}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      <CmpTypographyField xcomponent={'span'} xText={batchItem.unitName} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={4}>
                      <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      {/* <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransDetail.balQty).toFixed()} /> */}
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={(Number(batchItem.totalQty) - Number(sReceivedSum)).toFixed()}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      <CmpTypographyField xcomponent={'span'} xText={batchItem.unitName} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={4}>
              <Grid
                container
                spacing={2}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={6} md={5}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={7} sm={4} md={5}>
                      <CmpTypographyField xcomponent={'span'} xText={'Sr No.'} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                      <CmpTypographyField xcomponent={'span'} xText={sHeadObj.rowId} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8} sm={6} md={7}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={4} md={6}>
                      <CmpTypographyField xcomponent={'span'} xText={'Quantity'} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>

                    <Grid item xs={5} sm={5} md={5} className="txt-overflow-ellipse">
                      <CmpInput
                        xType={'number'} // text/number/password
                        xName={'Qty'}
                        xReadOnly={false}
                        xValue={sHeadObj.qty}
                        xOnChange={(event) => {
                          setHeadObj({ ...sHeadObj, qty: event.target.value });
                        }}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                        xTextAlign="right"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    alignItems="center"
                    className="d-flex justify-content-center">
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={fOnclickNew}
                        xLabel={'New'}
                        xDisable={batchInfo.Status.toString().toUpperCase() !== 'ENTER'}
                      />
                    </Grid>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={fOnclickSave}
                        xLabel={'Save'}
                        xDisable={batchInfo.Status.toString().toUpperCase() !== 'ENTER'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            columns={{ xs: 12, sm: 12, md: 12 }}
            className="mt-2"
            sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={10} md={12}>
              <CmpTypographyField xVariant="h3" xText="Batch Item" />
            </Grid>
            <Grid item xs={12} sm={10} md={12}>
              <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
                <UnloadBatchGrid
                  batchInfo={batchInfo}
                  setHeadObj={setHeadObj}
                  sReceivingList={sReceivingList}
                  onDeleteClick={onDeleteClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
}
