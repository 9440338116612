import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
const initialState = {
  head: {
    location: '',
    dateFrom:  moment().startOf('month'),
    dateTo: new Date(),
    status: 'All'
  },
  detail: {
    A_BatchId: '',
    A_PostDt: '',
    A_Vendor: '',
    Vendorname: '',
    A_DCLoc: '',
    DCLocName: '',
    A_Status: '',
    A_PR_PostDt: new Date(),
    A_PR_DocNo: '',
    DispatchCount: 0,
    DispatchValue: 0,
    A_Taxable: 'Y',
    VANTransIDs: '',
    REQTransIDs: ''
  }
};

const PurchaseReturnSlice = createSlice({
  name: 'PurchaseReturnSlice',
  initialState: { value: initialState },

  reducers: {
    PurchaseReturn_Head: (state, action) => {
      state.value.head = action.payload;
    },
    PurchaseReturn_Detail: (state, action) => {
      state.value.detail = action.payload;
    },
    PurchaseReturn_Detail_TransDate: (state, action) => {
      state.value.detail.A_TransDt = action.payload;
    }
  }
});

export const { PurchaseReturn_Head, PurchaseReturn_Detail, PurchaseReturn_Detail_TransDate } =
  PurchaseReturnSlice.actions;
export default PurchaseReturnSlice.reducer;
