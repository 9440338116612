import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CmpDatePickerField, CmpMultiSelectFilter } from '../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateFilter } from './slice';

const height = 48;
const paddingTop = 8;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
  alignItems: 'center'
};

export default function Filter() {
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const [personName, setPersonName] = useState([]);
  const [value, setValue] = useState(date);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);

  const [sFilterObj, setFilterObj] = useState({
    DCList: rdxFilterObj.DCList.length > 0 ? rdxFilterObj.DCList : [],
    dateFrom: rdxFilterObj.dateFrom,
    dateTo: rdxFilterObj.dateTo,
    locList: rdxFilterObj.locList.length > 0 ? rdxFilterObj.locList : []
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onClickApply = () => {
    dispatch(
      updateFilter({
        DCList: sFilterObj.DCList,
        dateFrom: sFilterObj.dateFrom,
        dateTo: sFilterObj.dateTo,
        locList: sFilterObj.locList
      })
    );
    setOpen(false);
  };
  const vMediaQuery = useMediaQuery('(max-width: 767px)');

  const onChangeLocList = (event) => {
    setFilterObj((obj) => ({
      ...obj,
      locList: event
    }));
  };

  const onChangeDCList = (event) => {
    setFilterObj((obj) => ({
      ...obj,
      DCList: event
    }));
  };

  const onChangeDateFrom = (newValue) => {
    setFilterObj((obj) => ({
      ...obj,
      dateFrom: newValue.$d
    }));
  };

  const onChangeDateTo = (newValue) => {
    setFilterObj((obj) => ({
      ...obj,
      dateTo: newValue.$d
    }));
  };

  return (
    <>
      <span onClick={handleOpen} style={{ display: vMediaQuery ? 'flex' : '' }}>
        <FilterAltOutlinedIcon
          style={{
            fontSize: '28px',
            justifyContent: 'center',
            marginTop: vMediaQuery ? '' : ''
          }}
        />

        {/* {!vMediaQuery && <p>Filter</p>} */}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ fontSize: '10px' }}>
        <Box sx={style}>
          <Typography variant="h6" sx={{ p: 3 }}>
            Apply filter
          </Typography>

          <CmpMultiSelectFilter
            xLabelText="Location"
            xValue={sFilterObj.locList}
            xOnChange={(event) => onChangeLocList(event)}
            xName={'Location'}
            xData={locList.map((i) => {
              return { id: i.UId, name: i.Name1 };
            })}
            xError={false}
            xErrorMessage={false}
            xDisable={false}
            xTabIndex={'1'}
            xShowLable={true}
          />

          <CmpMultiSelectFilter
            xLabelText="DC"
            xValue={sFilterObj.DCList}
            xOnChange={(event) => onChangeDCList(event)}
            xName={'DC Location'}
            xData={dcLoclist.map((i) => {
              return { id: i.UId, name: i.Name1 };
            })}
            xError={false}
            xErrorMessage={false}
            xDisable={false}
            xTabIndex={'2'}
            xShowLable={true}
          />
          <Box
            sx={{
              width: '17rem',
              display: 'flex',
              margin: '1rem',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <CmpDatePickerField
              xValue={sFilterObj.dateFrom}
              xOnChange={(newValue) => onChangeDateFrom(newValue)}
              xName={'dateFrom'}
              xError={false}
              xErrorMessage={false}
              xOnKeyUp={''}
              xTabIndex={'3'}
              xLabel="From Date"
              xmaxdate={sFilterObj.dateTo}
            />
            <CmpDatePickerField
              xValue={sFilterObj.dateTo}
              xOnChange={(newValue) => onChangeDateTo(newValue)}
              xName={'dateTo'}
              xError={false}
              xErrorMessage={false}
              xOnKeyUp={''}
              xTabIndex={'4'}
              xLabel="To Date"
              xmindate={sFilterObj.dateFrom}
            />
          </Box>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                width: '17rem',
                display: 'flex',
                margin: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <DatePicker
                sx={{ fontSize: '30px' }}
                disableFuture
                label="From Date"
                // openTo="year"
                views={['year', 'month', 'day']}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                disableFuture
                label="To Date"
                // openTo="year"
                views={['year', 'month', 'day']}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider> */}
          <Button onClick={() => onClickApply()} variant="contained">
            Apply
          </Button>
        </Box>
      </Modal>
    </>
  );
}
