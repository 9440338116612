import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  summaryObj: {
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(),
    status: 'All',
    DC: '',
  },

  detailObj: {
    A_TransId: '0',
    A_PostDt: new Date(),
    A_Loc: '',
    LocName: '',
    DC_RECEIVED_STATUS:''
  },
};

const DCReceiptSlice = createSlice({
  name: 'DCReceiptSlice',
  initialState: { value: initialState },

  reducers: {
    DCReceipt_SummaryObj: (state, action) => {
      state.value.summaryObj = action.payload;
    },
    DCReceipt_RowDetail: (state, action) => {
      state.value.detailObj = action.payload;
    },
    DCReceipt_Init: (state) => {
      state.value = initialState;
    },
  }
});

export const {
  DCReceipt_SummaryObj,
    DCReceipt_RowDetail,
  DCReceipt_Init,
} = DCReceiptSlice.actions;
export default DCReceiptSlice.reducer;
