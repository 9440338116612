import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './DispatchSummary.css';
import {
  CmpTypographyField,
  CmpInputField,
  CmpButton,
  CmpStatusDiv
} from '../../component/ToolBox/ToolBox';
import DispatchDetailCard from './processComponent/DispatchDetailCard';
import DispatchSummaryBreadCrumbs from './DispatchSummaryBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { fAlertToast } from '../../Utility/Utilitys';
import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { getDispatchDetailItemList, reverseDispatchItem, updateDispatchItem } from './action';
import { Dispatch_Detail_UpdateStatus, Dispatch_Detail } from './DispatchSlice';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function DispatchDetail() {
  const dispatch = useDispatch();
  const xItemDetail = useSelector((state) => state.DispatchSlice.value.detail);

  const [sItemList, setItemList] = useState([]);

  useEffect(() => {
    getDispatchDetailItemLists();
  }, [xItemDetail.A_TransId]);

  const getDispatchDetailItemLists = async () => {
    const itemListObj = {
      v_B_TransId: xItemDetail.A_TransId
    };
    const result = await getDispatchDetailItemList(itemListObj);

    let items = [];

    items = result.ResultSet;
    //items = result.ResultSet.map(i=>{return {...i,selected : i}});
    // if (xItemDetail.A_Type == 'DC') {
    // if (xItemDetail.QTY_EDIT == 0) {
    //   items = result.ResultSet.map((i) => {
    //     return { ...i, B_Disp_Qty: i.B_Pick_Qty, B_Disp_NetAmt: i.B_Pick_NetAmt };
    //   });
    // } else {
    //   items = result.ResultSet;
    // }
    setItemList(items);
  };

  const Validation = () => {
    let iRes = true;

    // const dispConfrQty = sItemList.filter((data) => data.B_Disp_Qty == '');
    // if (dispConfrQty.length > 0) {
    //   fAlertToast('FAILED', 'Confirm Dispatch Qty cannot be empty');
    //   return (iRes = false);
    // }

    const confrmQty = sItemList.filter((data) => data.B_Disp_Qty > data.B_Pick_Qty);

    if (confrmQty.length > 0) {
      fAlertToast('FAILED', 'Confirm Qty cannot be greater then Picked Qty');
      return (iRes = false);
    }

    return iRes;
  };

  const fupdateDispatchItem = async () => {
    if (xItemDetail.A_DispatchStatus == 'Post') {
      confirmAlert({
        title: 'Reverse',
        message: 'Are you sure to Reverse.',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const reverseObj = {
                v_A_TransId: xItemDetail.A_TransId
              };
              const vReversePicking = await reverseDispatchItem(reverseObj);
              if (vReversePicking.status == 200) {
                dispatch(Dispatch_Detail_UpdateStatus('Enter'));
                fAlertToast('SUCCESS', 'Reversed Successfully');
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else {
      const ItemList = sItemList.map((itm) => {
        return {
          // ...itm,
          v_B_SrNo: itm.B_SrNo,
          v_B_Disp_Qty: itm.B_Disp_Qty,
          v_B_Disp_NetAmt: itm.B_Disp_NetAmt,
          v_B_Disp_AdjQty: itm.B_Disp_AdjQty,
          v_B_Disp_AdjAmt: itm.B_Disp_AdjAmt
        };
      });

      if (Validation()) {
        const updateObj = {
          v_A_TransId: xItemDetail.A_TransId,
          v_Status: 'Enter',
          v_A_RefNo: xItemDetail.A_RefNo,
          v_A_Narration: xItemDetail.A_Narration,
          itemList: ItemList
        };
        const result = await updateDispatchItem(updateObj);
        if (result.Type === 'Success') {
          fAlertToast('SUCCESS', 'saved successfully');
          getDispatchDetailItemLists();
        }
      }
    }
  };

  const fpostDispatchItem = async () => {
    const ItemList = sItemList.map((itm) => {
      return {
        // ...itm,
        v_B_SrNo: itm.B_SrNo,
        v_B_Disp_Qty: itm.B_Disp_Qty,
        v_B_Disp_NetAmt: itm.B_Disp_NetAmt,
        v_B_Disp_AdjQty: itm.B_Disp_AdjQty,
        v_B_Disp_AdjAmt: itm.B_Disp_AdjAmt
      };
    });

    if (Validation()) {
      const postObj = {
        v_A_TransId: xItemDetail.A_TransId,
        v_Status: 'Post',
        v_A_RefNo: xItemDetail.A_RefNo,
        v_A_Narration: xItemDetail.A_Narration,
        itemList: ItemList
      };
      const result = await updateDispatchItem(postObj);
      if (result.Type === 'Success') {
        fAlertToast('SUCCESS', 'Post successfully');
        dispatch(Dispatch_Detail_UpdateStatus('Post'));
      }
    }
  };

  const onChangeConfigQty = (event, item, index) => {
    let confQty = isNaN(event.target.value) ? 0 : Number(event.target.value);
    let updatedValue = sItemList.map((obj) => {
      if (obj.B_SrNo === item.B_SrNo) {
        let objUpdate = obj;
        obj.B_Disp_Qty = confQty;
        obj.B_Disp_NetAmt = Number(confQty) * obj.B_UnitRate;
        obj.B_Disp_AdjQty = obj.B_Pick_Qty - obj.B_Disp_Qty;
        obj.B_Disp_AdjAmt = (obj.B_Pick_Qty - obj.B_Disp_Qty) * obj.B_UnitRate;

        return objUpdate;
      } else {
        return obj;
      }
    });
    setItemList(updatedValue);
  };

  const onChangeSelect = (item) => {
    console.log('item' + item.B_SrNo);
    let updatedList = sItemList.map((i) => {
      if (i.B_SrNo === item.B_SrNo) {
        return item.B_Disp_Qty == 0 ? { ...i, B_Disp_Qty: i.B_Pick_Qty } : { ...i, B_Disp_Qty: 0 };
      } else {
        return i;
      }
    });
    console.log('updatedList' + JSON.stringify(updatedList));
    setItemList(updatedList);
  };

  return (
    <div className="container Dispatch">
      <div>
        <ToastContainer />
      </div>
      <div
      // className="my-2"
      >
        <DispatchSummaryBreadCrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={4} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fupdateDispatchItem}
              xLabel={'Save'}
              // xDisable={xItemDetail.A_DispatchStatus == 'Post' ? true : false}
            />
          </Grid>
          <Grid item xs={4} sm={4} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fpostDispatchItem}
              xLabel={'Post'}
              xDisable={xItemDetail.A_DispatchStatus == 'Post' ? true : false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="my-2 mb-3"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={5} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={6} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_TransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.TruckName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={5} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={6} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(xItemDetail.A_PostDt).format('MM/DD/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_Type} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={5} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={6} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.LocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Ref No'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={7.5}>
                <CmpInputField
                  xValue={xItemDetail.A_RefNo}
                  xType={'text'} // text/number/password
                  xName={'RefNo'}
                  xReadOnly={false}
                  xOnChange={(event) => {
                    dispatch(Dispatch_Detail({ ...xItemDetail, A_RefNo: event.target.value }));
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={5} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Req.Trans.ID'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={6} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_ReqTransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Narration'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={7.5}>
                <CmpInputField
                  xValue={xItemDetail.A_Narration}
                  xType={'text'} // text/number/password
                  xName={'Narration'}
                  xReadOnly={false}
                  xOnChange={(event) => {
                    dispatch(Dispatch_Detail({ ...xItemDetail, A_Narration: event.target.value }));
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.Vendorname} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.Dept} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'RequestID'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.ReqID} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.Type} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="scrol-overflow-dispatch">
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            sx={{ alignItems: 'center' }}>
            {sItemList.map((itm) => {
              return (
                <Grid item xs={12} sm={6} lg={6} md={6} key={itm.B_SrNo}>
                  <DispatchDetailCard
                    xItem={itm.B_ItmCd}
                    xSrNo={itm.B_SrNo}
                    xItemName={itm.ItemName}
                    xUnit={itm.B_Unit}
                    xPacking={itm.packing}
                    xPickedQty={itm.B_Pick_Qty}
                    xUnitRate={itm.B_UnitRate}
                    // isReadQty={xItemDetail.A_Type == 'DC' ? true : false}
                    //isReadQty={xItemDetail.QTY_EDIT == 0 ? true : false}
                    isReadQty={true}
                    xConfQty={itm.B_Disp_Qty}
                    xOnChangeConfQty={(event) => {
                      onChangeConfigQty(event, itm);
                    }}
                    xAdjQty={itm.B_Disp_AdjQty}
                    xAdjAmt={itm.B_Disp_AdjAmt}
                    xNetAmount={itm.B_Disp_NetAmt}
                    xSelected={itm.B_Disp_Qty > 0}
                    xOnChangeSelect={() => onChangeSelect(itm)}
                    xDisableSelect={xItemDetail.A_DispatchStatus == 'Post'}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
        {/* <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          sx={{ alignItems: 'center', justifyContent: 'start' }}
          className="my-2">
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <CmpTypographyField xcomponent={'span'} xText={'STATUS'} />
            <CmpTypographyField xcomponent={'span'} xText={':'} />
            <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_DispatchStatus} />
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Grid item xs={5} sm={5} md={1}>
            <CmpStatusDiv xStatus={xItemDetail.A_DispatchStatus} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default DispatchDetail;
