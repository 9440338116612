import { fExecuteAPI } from '../../Server/Apis';

export const getOrderSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_ORDER_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};

export const getOrderItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_ORDER_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const getOrderItemsReqLocs = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_ORDER_ITEMS_REQ_LOC', xObj);
    return result.data;
  } catch (error) {}
};

export const getVendorPriceEnquiry = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_VENDOR_PRICE_ENQUIRY', xObj);
    return result.data;
  } catch (error) {}
};

export const getOrderDetails = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_ORDER_DETAILS', xObj);
    return result.data;
  } catch (error) {}
};

export const postOrder = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_POST_ORDER', xObj);
    return result;
  } catch (error) {}
};
