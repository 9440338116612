import React, { useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
// Local
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpSelectArrField,
  CmpButton,
  CmpCheckboxField,
  CmpCheckboxIntermediate,
  CmpTypographyFieldColor,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import './ReturnRequestAction';
import { ReturnRequest_Head, ReturnRequest_RowDetail } from './returnRequestSlice';
import { Gridcolumns } from './ReturnRequestAction';
import ReturnRequest_Breadcrumbs from './ReturnRequest_BreadCrumbs';
import { getDepartmentList, getReturnRequestSummary } from './action';
import { AddCircle, Refresh } from '@mui/icons-material';

const statusData = ['All', 'Enter', 'Post', 'Deleted'];
const DCStatusData = ['All', 'Accepted', 'Rejected', 'Pending', 'Partial Accepted'];
const ReturnRequest = () => {
  const vMediaQuery = useMediaQuery('(max-width:800px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getLocations = useSelector((state) => state.SliceDB.value.getLocation);
  const getDCLocations = useSelector((state) => state.SliceDB.value.getDCLocation);
  const vRdxkern = useSelector((state) => state.SliceDB.value.Kern);
  const userInfo = useSelector((state) => state.SliceDB.value.login);
  const scanFocusOnLoad = useRef(null);

  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const [sFilteredDCNames, setFilteredDCNames] = useState([]);
  const [sFilteredLocNames, setFilteredLocNames] = useState([]);

  const [sDeptList, setDeptList] = useState([]);

  const [sGridrows, setGridrows] = useState([]);
  const [sGridcols, setGridcols] = useState(Gridcolumns);
  const [sSummaryObj, setSummaryObj] = useState({
    // location: getLocations.length > 0 ? getLocations[0].UId : '',
    // dateFrom: moment().startOf('month'),
    // dateTo: new Date(),
    status: statusData[0],
    DCStatus: DCStatusData[0],
    // DC: getDCLocations.length > 0 ? getDCLocations[0].UId : '',
    DCReturn: 'N',
    Dept: ''
  });

  const fOnPageLoad = useCallback(async () => {
    let filteredDC = dcLoclist.filter((i) => rdxFilterObj.DCList.includes(i.UId));
    setFilteredDCNames(filteredDC.map((i) => i.Name1));

    let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    setFilteredLocNames(filteredLoc.map((i) => i.Name1));

    const result = await getDepartmentList({});
    setDeptList(result?.ResultSet);
    if (sSummaryObj.Dept == '') {
      setSummaryObj((obj) => ({ ...obj, Dept: result?.ResultSet[0].Cd }));
      fOnpressLoad(result?.ResultSet[0].Cd);
    } else {
      fOnpressLoad();
    }
  }, [dispatch, rdxFilterObj]);

  useEffect(() => {
    // scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  useEffect(() => {
    if (vMediaQuery) {
      let updatedArray = Gridcolumns.map((obj) => {
        if (obj.field === 'DCLocName') {
          return { ...obj, hide: true };
        }
        return obj;
      });
      setGridcols(updatedArray);
    } else {
      let updatedArray = Gridcolumns.map((obj) => {
        if (obj.field === 'DCLocName') {
          return { ...obj, hide: false };
        }
        return obj;
      });
      setGridcols(updatedArray);
    }
  }, [vMediaQuery]);
  const Return_Request_Summary = async (Dept = sSummaryObj.Dept) => {
    let vSumaryObj = {
      v_A_Loc: rdxFilterObj.locList.toString(),
      v_A_DCLoc: rdxFilterObj.DCList.toString(),
      v_Date1: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      v_A_DocStatus: sSummaryObj.status,
      v_A_DCStatus: sSummaryObj.DCStatus,
      v_UserCd: userInfo.userId,
      v_isDcReturn: sSummaryObj.DCReturn,
      v_Dept: Dept
    };

    const vSummary = await getReturnRequestSummary(vSumaryObj);
    return vSummary.data.ResultSet;
  };

  const fOnpressLoad = async () => {
    const result = await Return_Request_Summary();
    // if (result.length > 0) {
    let GridID = result?.map((obj, i) => ({
      ...obj,
      id: i + 1,
      A_PostDt: moment(obj.A_PostDt).format('DD/MM/YYYY')
    }));
    setGridrows(GridID);
    // }
    // dispatch(ReturnRequest_Head(sSummaryObj));
    // document.getElementsByName('Location')[0].focus();
    scanFocusOnLoad.current.focus();
  };

  const fOnpressNew = () => {
    dispatch(
      ReturnRequest_RowDetail({
        A_TransId: '0',
        A_PostDt: new Date(),
        A_Loc: getLocations[0]?.UId,
        LocName: getLocations[0]?.Name1,
        A_DCLoc: getDCLocations[0]?.UId,
        DCLocName: getDCLocations[0]?.Name1,
        A_DocStatus: 'Enter',
        A_Type: '',
        A_DCStatus: DCStatusData[0],
        v_isDcReturn: 'N',
        Dept: sDeptList[0]?.Cd
        /////////////////////////////////////////
        // A_TransId: xRDRowDetail.A_TransId,
        // A_PostDt: sSummaryObj.dateTo,
        // // A_Loc: xRDRowDetail.A_Loc,
        // LocName: sSummaryObj.location,
        // // A_DCLoc: xRDRowDetail.A_DCLoc,
        // DCLocName: sSummaryObj.DC,
        // A_DocStatus: sSummaryObj.status,
        // A_Type: xRDRowDetail.A_Type,
        // A_DCStatus: sSummaryObj.DCStatus
      })
    );
    navigate('/ReturnRequestDetail');
  };

  const fOnRowClick = (item) => {
    dispatch(
      ReturnRequest_RowDetail({
        A_TransId: item.row.A_TransId,
        A_PostDt: moment(item.row.A_PostDt, 'DD/MM/YYYY')._d,
        A_Loc: item.row.A_Loc,
        LocName: item.row.LocName,
        A_DCLoc: item.row.A_DCLoc,
        DCLocName: item.row.DCLocName,
        A_DocStatus: item.row.A_DocStatus,
        A_Type: item.row.A_Type,
        A_DCStatus: item.row.A_DCStatus,
        v_isDcReturn: vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N' ? 'N' : item.row.isDcReturn,
        Dept: item.row.DepartmentCode
      })
    );
    // console.log("****** ", item.row)
    navigate('/ReturnRequestDetail');
  };

  const onChangeLocations = (event) => {
    setSummaryObj((obj) => ({ ...obj, location: event.target.value }));
  };

  const onChangeDCLocations = (event) => {
    setSummaryObj((obj) => ({ ...obj, DC: event.target.value }));
  };

  const onChangeStatus = (event) => {
    setSummaryObj((obj) => ({ ...obj, status: event.target.value }));
  };

  const onChangeDCStatus = (event) => {
    setSummaryObj((obj) => ({ ...obj, DCStatus: event.target.value }));
  };

  const onChangeDateFrom = (newValue) => {
    setSummaryObj((obj) => ({ ...obj, dateFrom: newValue.$d }));
  };

  const onChangeDateTo = (newValue) => {
    setSummaryObj((obj) => ({ ...obj, dateTo: newValue.$d }));
  };

  return (
    <div className="container">
      <div>
      <ReturnRequest_Breadcrumbs />
      </div>
      <div>
        {' '}
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-3">
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={1.35} md={0.9}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={0.4} sm={0.2} md={0.25}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.9} sm={10} md={10.85}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={2.7} md={3.65}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={4.5}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={2.2}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={2.7} md={4}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={1} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={4.5}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4.6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2.7} md={2.5}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.4} sm={0.4} md={0.5}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8.9} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2.2}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2.7} md={3.5}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={8.8} md={7.5}>
                <CmpSelectArrField
                  xTabIndex="5"
                  xLabelText="status"
                  xValue={sSummaryObj.status}
                  xOnChange={(event) => onChangeStatus(event)}
                  xName={'status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center', paddingTop: vMediaQuery ? '15px !important' : "" }}>
              <Grid item xs={2.7} sm={2.7} md={3.8}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Status'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={8.8} md={7.2}>
                <CmpSelectArrField
                  xTabIndex="6"
                  xLabelText="DCStatus"
                  xValue={sSummaryObj.DCStatus}
                  xOnChange={(event) => onChangeDCStatus(event)}
                  xName={'DCStatus'}
                  xData={DCStatusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              {/* <Grid item xs={2} sm={12} md={12}>
                <CmpCheckboxIntermediate
                  xChecked={sSummaryObj.DCReturn === 'Y'}
                  xCheckedIndeterminate={sSummaryObj.DCReturn === 'B'}
                  xOnChange={fCheckBoxChange}
               
                  xName={'DC Return'}
                  xNameField={'DC Return'}
                  xOnkeyUp={''}
                  xTabIndex=""
                  xref=""
                />
              </Grid> */}
              <Grid item xs={2.7} sm={2.5} md={2.8}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={9} md={8.2}>
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="Type"
                  xValue={sSummaryObj.DCReturn}
                  xOnChange={(event) => {
                    setSummaryObj((obj) => ({ ...obj, DCReturn: event.target.value }));
                  }}
                  xName={'Type'}
                  xData={[
                    {
                      UId: 'N',
                      Name1: 'Market'
                    },
                    {
                      UId: 'Y',
                      Name1: 'DC'
                    }
                  ]}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2.7} md={3.5}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={8.8} md={7.5}>
                <CmpSelectField
                  xTabIndex="5"
                  xLabelText="Department"
                  xValue={sSummaryObj.Dept}
                  xOnChange={(event) => {
                    setSummaryObj((obj) => ({ ...obj, Dept: event.target.value }));
                  }}
                  xName={'Department'}
                  xData={sDeptList}
                  xValueMember={''}
                  xDispalyMember={''}
                  // xError={sErrorMsg.locCd !== ''}
                  // xErrorMessage={sErrorMsg.locCd}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <Grid
              container
              spacing={4}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ justifyContent: vMediaQuery ? 'center' : 'end' }}>
              <Grid item xs={4} sm={3.5} md={5} className="">
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressNew}
                  xLabel={'New'}
                  xIcon={<AddCircle />}
                />
              </Grid>
              <Grid item xs={4} sm={3.5} md={5}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ height: '40vh', width: '100%' }} className="mt-4">
          <DataGrid
            rowHeight={45}
            headerHeight={50}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            rows={sGridrows}
            columns={sGridcols}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // hideFooter
            onRowClick={(item) => fOnRowClick(item)}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ReturnRequest;
