import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  CmpButton,
  CmpButtonOld,
  CmpTypographyField,
  StatusIcon
} from '../../../component/ToolBox/ToolBox';
import { Circle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, useMediaQuery } from '@mui/material';
import { getUnloadingBatchItemPurDetail } from '../../Request/actions';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

export default function TransItemCard({
  xItem,
  xTotal,
  xReceived,
  xBalQty,
  xUnit,
  xItemCd,
  viewModal,
  setViewModal,
  xOnClick
}) {
  const vMediaQuery = useMediaQuery('(max-width: 760px)');
  const selectedBatchInfo = useSelector((state) => state.OutletRequestSlice.value.batchInfo);
  const [sGridData, setGridData] = useState([]);

  const fCloseModal = () => {
    setViewModal(false);
    setGridData([]);
  };
  const fShowModal = () => {
    setViewModal(true);
  };

  const onViewDetail = async () => {
    const obj = {
      v_Batch_ID: selectedBatchInfo.BatchNo,
      v_ItmCd: xItemCd
    };
    const data = await getUnloadingBatchItemPurDetail(obj);
    if (data?.Type === 'Success') {
      let GridID =
        data?.Result == 0
          ? []
          : data?.ResultSet.map((obj, i) => ({
              ...obj,
              id: i + 1
              //A_PostDt: moment(obj.A_PostDt).format('DD/MM/YYYY')
            }));
      setGridData(GridID);
    }
    fShowModal();
  };

  //   useEffect(() => {
  //     if (viewModal) {
  //       fShowModal();
  //     }
  //   }, [viewModal]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="border-card-2">
        <Grid
          onClick={xOnClick}
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}
              className="mb-3">
              <Grid item xs={11} sm={11} md={11} style={{ textAlign: 'center' }}>
                <CmpTypographyField xVariant="h3" xText={xItem} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <Circle
                  style={{
                    color:
                      Number(xReceived) === 0
                        ? 'gray'
                        : Number(xBalQty) > 0
                        ? '#f1df09'
                        : '#15d218',
                    fontSize: '.9rem'
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <CmpTypographyField xcomponent={'span'} xText={'Total'} />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse text-end">
                    <CmpTypographyField xcomponent={'span'} xText={Number(xTotal).toFixed()} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xUnit} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <CmpTypographyField xcomponent={'span'} xText={'Received'} />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse text-end">
                    <CmpTypographyField xcomponent={'span'} xText={Number(xReceived).toFixed()} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xUnit} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse text-end">
                    <CmpTypographyField xcomponent={'span'} xText={Number(xBalQty).toFixed()} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xUnit} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ justifyContent: 'center' }}>
          <Grid item xs={4} sm={2} md={4} className="my-2 text-center">
            <CmpButtonOld
              xvariant={'contained'}
              xsize={'small'}
              //xonClick={fOnclickPost}
              xonClick={onViewDetail}
              xLabel={'View'}
              //xDisable={selectedBatchInfo?.Status.toString().toUpperCase() !== 'ENTER'}
            />
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={viewModal}
        onClose={fCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="model-sales-invoice-GCVanMaster-Modal">
          <Box sx={{ flexGrow: 1 }} className="gnr-item-search  GCItemSearch-Modal">
            <Grid container spacing={0} className="grnt-item-search-title">
              <Grid item xs={12} className="item-search d-flex justify-content-between">
                <span> Unloading BatchItem Detail List</span>
                <CloseIcon className="grnt-item-search-close-icon" onClick={fCloseModal} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="search-item mt-2">
              <Grid item xs={12} className=" height-table-scroll " component="div">
                <div
                  style={{ height: vMediaQuery ? '45vh' : '50vh', width: '100%' }}
                  id="table-main-grid-3"
                  className="search-grid-table ">
                  <Box
                    style={{ height: '100%', width: '100%' }}
                    className="bintransfer-out-grid px-2">
                    <DataGrid
                      rowHeight={30}
                      headerHeight={40}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                      }
                      sx={{
                        border: 1,
                        borderColor: 'primary.light'
                      }}
                      componentsProps={{
                        filterPanel: { sx: { maxWidth: '93vw' } }
                      }}
                      rows={sGridData}
                      columns={[
                        {
                          field: 'TRNS_DATE',
                          headerName: 'Trns_Date',
                          hide: false,
                          width: 100,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'S',
                          NumberFormat: '0',
                          valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
                        },
                        {
                          field: 'Vendor',
                          headerName: 'Vendor',
                          hide: false,
                          width: 400,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'S',
                          NumberFormat: '0'
                        },
                        {
                          field: 'TRNS_QTY',
                          headerName: 'Trns_Qty',
                          hide: false,
                          width: 100,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'number',
                          NumberFormat: '0'
                        },
                        {
                          field: 'TRN_PACK_QTY',
                          headerName: 'Trn_Pack_Qty',
                          hide: false,
                          width: 100,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'number',
                          NumberFormat: '0'
                        }
                      ]}
                      getRowId={(row) => row.id}
                      hideFooter
                      // onColumnHeaderEnter={}
                      // onCellKeyDown={xOnCellKeyDown}
                      onRowClick={(params) => {
                        // console.log('safdasd', sCurrentRowSelected, params.row.PackQty);
                        // setPackQty_V1(sCurrentRowSelected, params.row.PackQty);
                        // fCloseItempackQty();
                      }}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
