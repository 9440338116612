import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';
//Local
import './DispatchSummary.css';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpSelectArrField,
  CmpTypographyFieldColor,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import DispatchSumarryCard from './processComponent/DispatchSumarryCard';
import DispatchSummaryBreadCrumbs from './DispatchSummaryBreadCrumbs';
import moment from 'moment';
import { Dispatch_Detail, Dispatch_Head } from './DispatchSlice';
import { fAlertToast } from '../../Utility/Utilitys';
import { getDepartmentList, getDisptachSummaryList } from './action';
import { Refresh } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

const statusData = ['All', 'Enter', 'Post'];
const typeData = ['All', 'DC', 'PR'];

function DispatchSummary() {
  const vMediaQuery = useMediaQuery('(max-width:760px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rdxHeadobj = useSelector((state) => state.DispatchSlice.value.head);
  const getPickLoc = useSelector((state) => state.SliceDB.value.getPickLocList);
  const userInfo = useSelector((state) => state.SliceDB.value.userInfo);
  const scanFocusOnLoad = useRef(null);
  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const [sFilteredDCNames, setFilteredDCNames] = useState([]);
  const [sFilteredLocNames, setFilteredLocNames] = useState([]);

  const [sDeptList, setDeptList] = useState([]);

  const [dispatchList, setDisptachList] = useState([]);

  // const fOnPageLoad = useCallback(async () => {
  //   dispatch(
  //     Dispatch_Head({
  //       location: getPickLoc[0].LocCode,
  //       dateFrom: moment().startOf('month'),
  //       dateTo: new Date(),
  //       status: statusData[0],
  //       Type: typeData[0]
  //     })
  //   );

  //   fOnpressLoad();
  // }, [dispatch]);

  const fOnPageLoad = useCallback(async () => {
    let filteredDC = dcLoclist.filter((i) => rdxFilterObj.DCList.includes(i.UId));
    setFilteredDCNames(filteredDC.map((i) => i.Name1));

    let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    setFilteredLocNames(filteredLoc.map((i) => i.Name1));

    const result = await getDepartmentList({});
    setDeptList(result?.ResultSet);

    if (rdxHeadobj.Dept == '') {
      dispatch(
        Dispatch_Head({
          ...rdxHeadobj,
          Dept: result?.ResultSet[0].Cd
        })
      );
      fGetDisptachSummaryList(result?.ResultSet[0].Cd);
    } else {
      fGetDisptachSummaryList();
    }
  }, [rdxFilterObj]);

  useEffect(() => {
    // scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  // useEffect(() => {
  // scanFocusOnLoad.current.focus();
  //   fOnPageLoad();
  // }, []);

  const fGetDisptachSummaryList = async (Dept = rdxHeadobj.Dept) => {
    const dispatchSummaryObj = {
      v_A_Loc: rdxFilterObj.locList.toString(),
      v_Date1: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      v_Status: rdxHeadobj.status,
      v_TruckID: userInfo.TruckID,
      v_Type: rdxHeadobj.Type,
      v_DCType: rdxHeadobj.DC,
      v_Dept: Dept
    };
    const result = await getDisptachSummaryList(dispatchSummaryObj);

    if (result.ResultSet.length > 0) {
      setDisptachList(result.ResultSet);
    } else {
      setDisptachList([]);
    }
    return result.ResultSet;
  };

  const fOnpressLoad = async () => {
    // scanFocusOnLoad.current.focus();
    console.log(rdxFilterObj.dateFrom, rdxFilterObj.dateTo);
    // if (rdxFilterObj.dateFrom < rdxFilterObj.dateTo) {
    await fGetDisptachSummaryList();
    // } else {
    //   fAlertToast('FAILED', 'To Date cannot be less then from date');
    // }
  };

  const fOnpressItem = (item) => {
    dispatch(
      Dispatch_Detail({
        A_TransId: item.A_TransId,
        A_PostDt: item.A_PostDt,
        A_Loc: item.A_Loc,
        LocName: item.LocName,
        A_Driver: item.A_Driver,
        DriverName: item.DriverName,
        A_Truck: item.A_Truck,
        TruckName: item.TruckName,
        A_ReqTransId: item.A_ReqTransId,
        A_Type: item.A_Type,
        A_PickStatus: item.A_DispatchStatus,
        A_PickDt: item.A_PickDt,
        A_DispatchStatus: item.A_DispatchStatus,
        A_DispatchDt: item.A_DispatchDt,
        A_RefNo: item.A_RefNo,
        A_Narration: item.A_Narration,
        A_Vendor: item.A_Vendor,
        Vendorname: item.Vendorname,
        ItemCount: item.ItemCount,
        QTY_EDIT: item.QTY_EDIT,
        Dept: item?.DepartmentName,
        ReqID: item?.RequestID,
        Type: item?.Type
      })
    );
    if (item.A_TransId !== '') {
      navigate('/DispatchDetail');
    }
  };

  return (
    <div className="container Dispatch">
      <div
      // className="my-2"
      >
        <DispatchSummaryBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-3">
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={1.5} md={1.05}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={0.4} sm={0.5} md={0.25}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.9} sm={10} md={10.7}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={3} md={3.6}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={7.5}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={3} md={3.6}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={2} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4.5} sm={8} md={7.5}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
             <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2} md={2}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.5}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.3} sm={9} md={7}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={6} md={5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={2.9}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={0.7}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={8.4}>
                <CmpSelectArrField
                  xLabelText="Status"
                  xValue={rdxHeadobj.status}
                  xOnChange={(event) => {
                    dispatch(
                      Dispatch_Head({
                        ...rdxHeadobj,
                        status: event.target.value
                      })
                    );
                  }}
                  xName={'Status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3.6}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpSelectArrField
                  xLabelText="Type"
                  xValue={rdxHeadobj.Type}
                  xOnChange={(event) => {
                    dispatch(
                      Dispatch_Head({
                        ...rdxHeadobj,
                        Type: event.target.value
                      })
                    );
                  }}
                  xName={'Type'}
                  xData={typeData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'5'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3.6}>
                <CmpTypographyField xcomponent={'span'} xText={'DCType'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="Type"
                  xValue={rdxHeadobj.DC}
                  xOnChange={(event) => {
                    dispatch(
                      Dispatch_Head({
                        ...rdxHeadobj,
                        DC: event.target.value
                      })
                    );
                  }}
                  xName={'Type'}
                  xData={[
                    {
                      UId: 'N',
                      Name1: 'Market'
                    },
                    {
                      UId: 'Y',
                      Name1: 'DC'
                    }
                  ]}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3.6}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpSelectField
                  xTabIndex="5"
                  xLabelText="Department"
                  xValue={rdxHeadobj.Dept}
                  xOnChange={(event) => {
                    dispatch(
                      Dispatch_Head({
                        ...rdxHeadobj,
                        Dept: event.target.value
                      })
                    );
                  }}
                  xName={'Department'}
                  xData={sDeptList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: vMediaQuery ? 'center' : 'end' }}>
              <Grid item xs={3} sm={1.5} md={11} className="text-end">
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={(e) => fOnpressLoad()}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '60vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent: dispatchList.length == 0 ? 'center' : 'start'
          }}>
          {dispatchList.length == 0 ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <div>
                <p style={{ fontSize: '2rem' }}> List is Empty </p>
              </div>
            </Grid>
          ) : (
            dispatchList.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={itm.A_TransId}
                  onClick={() => fOnpressItem(itm)}>
                  <DispatchSumarryCard
                    xTransId={itm.A_TransId}
                    xTruck={itm.TruckName}
                    xDate={moment(itm.A_PostDt).format('DD/MM/YYYY')}
                    xStatus={itm.A_DispatchStatus}
                    xLoc={itm.LocName}
                    xNumberOfItems={itm.ItemCount}
                    xType={itm.A_Type}
                    xVendor={itm.Vendorname}
                    xStkTsrNo={itm.TSR_VocNo}
                    xPrNo={itm.PR_VocNo}
                    xDCReturn={itm.isDcReturn}
                    item={itm}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
export default DispatchSummary;
