import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    head: {
        location: "",
        dateFrom: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
        ),
        dateTo: new Date(),
        status: "All",
        DCStatus: "All",
        DC: ""
    },
    detail: {
        A_TransId: "0",
        A_PostDt: new Date(),
        A_Loc: "",
        LocName: "",
        A_DCLoc: "",
        DCLocName: "",
        A_DocStatus: "",
        A_Type: "",
        A_DCStatus: "Pending"
    },
};

const ReturnRequestSlice = createSlice({
    name: "ReturnRequestReviewSlice",
    initialState: { value: initialState },

    reducers: {
        ReturnRequest_Review_Head: (state, action) => {
            state.value.head = action.payload;
        },
        ReturnRequest_Review_RowDetail: (state, action) => {
            state.value.detail = action.payload;
        },
        ReturnRequest_Review_Init: (state) => {
            state.value = initialState;
        },
        ReturnRequestReview_DCStatus: (state, action) => {
            state.value.detail.A_DCStatus = action.payload.A_DCStatus;
        },
    },
});

export const { 
    ReturnRequest_Review_Head, 
    ReturnRequest_Review_RowDetail, 
    ReturnRequest_Review_Init, 
    ReturnRequestReview_DCStatus 
} = ReturnRequestSlice.actions;
export default ReturnRequestSlice.reducer;
