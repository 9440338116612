import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton
} from '../../component/ToolBox/ToolBox';
import './ReturnRequestReviewAction.css';
import { ToastContainer } from 'react-toastify';

import { fAlertToast } from '../../Utility/Utilitys';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// Local
import { fExecuteAPI } from '../../Server/Apis';
import FullFeaturedCrudGrid from './ProcessComponent/GC_GridTable_MUI';
import { ReturnRequestReview_DCStatus } from './ReturnRequestReviewSlice';

import ReturnRequestReviewBreadcrumbs from './ReturnRequestReview_BreadCrumbs';
import useMediaQuery from '@mui/material/useMediaQuery';
const typeData = ['Accepted', 'Rejected', 'Partial Accepted'];

const ReturnRequestReviewDetail = () => {
  const dispatch = useDispatch();
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  // Redux state
  const getLocations = useSelector((state) => state.SliceDB.value.getReviewLoc);
  const getDCLocations = useSelector((state) => state.SliceDB.value.getDCLocation);
  const xRDRowDetail = useSelector((state) => state.ReturnRequestReviewSlice.value.detail);
  const scanFocusOnLoad = useRef(null);

  // Local states
  const [sGridrows, setGridrows] = useState([]);
  const [sVendorList, setVendorList] = useState([]);
  const [sDetailObj, setDetailObj] = useState({
    requestNo: xRDRowDetail.A_TransId,
    location: xRDRowDetail.A_Loc,
    dateTo: xRDRowDetail.A_PostDt,
    DC: xRDRowDetail.A_DCLoc,
    Type: xRDRowDetail.A_Type === null ? typeData[0] : xRDRowDetail.A_Type,
    DCStatus: xRDRowDetail.A_DCStatus
  });

  const [sBufferObj, setBufferObj] = useState({
    B_Srno: '0',
    B_Itmcd: '',
    B_ItmName: '',
    B_Qty: '',
    B_Unit: '',
    B_BaseQty: '',
    B_UnitCost: '',
    B_NetAmt: '',
    UnitName: '',
    B_UnitRate: '',
    B_Status: '',
    B_Acc_Qty: '',
    B_Acc_BaseQty: '',
    B_DC_LPCost: '',
    B_Vendor: '',
    Vendorname: '',
    B_PR_Amount: '',
    B_VendorCost: '',
    B_VendorNetAmt: ''
  });

  useEffect(() => {
    scanFocusOnLoad.current.focus();
    onLoadPage();
    VendorList();
  }, []);

  const Validation = () => {
    let iRes = true;

    if (sDetailObj.Type === 'Accepted') {
      const check = sGridrows.find(
        (data) => data.B_Status !== 'Accept' || data.B_Acc_Qty !== data.B_Qty
      );
      if (check !== undefined) {
        fAlertToast('FAILED', 'Header Type is accepted then all items should be accept');
        iRes = false;
      }
    }

    if (sDetailObj.Type === 'Accepted') {
      const check = sGridrows.find((data) => data.B_Status === 'Accept' && data.B_Type === '--');
      if (check !== undefined) {
        fAlertToast('FAILED', 'Item status is accept then type should be DC or PR');
        iRes = false;
      }
    }

    if (sDetailObj.Type === 'Rejected') {
      const checkStatus = sGridrows.find(
        (data) => data.B_Status !== 'Reject' || data.B_Acc_Qty !== 0
      );
      if (checkStatus !== undefined) {
        fAlertToast('FAILED', 'Header Type is rejected then all items should be reject');
        iRes = false;
      }
    }

    if (sDetailObj.Type === 'Partial Accepted') {
      const checkStatus = sGridrows.filter((data) => data.B_Qty !== data.B_Acc_Qty);
      if (checkStatus.length == 0) {
        fAlertToast('FAILED', 'There is no item at partial accepted type');
        iRes = false;
      }
    }

    return iRes;
  };

  const EmptyValidation = () => {
    let iRes = true;

    const checkStatus = sGridrows.filter((itm) => itm.B_Status === '');
    if (checkStatus.length > 0) {
      fAlertToast(
        'FAILED',
        `Status is missing at Sr No ${checkStatus.map((e) => e.B_SrNo).toString()}`
      );
      return false;
    }

    const checkType = sGridrows.filter((itm) => itm.B_Type === '');
    if (checkType.length > 0) {
      fAlertToast(
        'FAILED',
        `Type is missing at Sr No ${checkType.map((e) => e.B_SrNo).toString()}`
      );
      return false;
    }

    const checkVendor = sGridrows.filter((itm) => itm.B_Vendor === '');
    if (checkVendor.length > 0) {
      fAlertToast(
        'FAILED',
        `Vendor is missing at Sr No ${checkVendor.map((e) => e.B_SrNo).toString()}`
      );
      return false;
    }

    const checkAcceptQty = sGridrows.filter((itm) => itm.B_Acc_Qty === '');
    if (checkAcceptQty.length > 0) {
      fAlertToast(
        'FAILED',
        `Accept Qty is missing at Sr No ${checkAcceptQty.map((e) => e.B_SrNo).toString()}`
      );
      return false;
    }

    return iRes;
  };

  const onLoadPage = async () => {
    const result = await Return_Request_Item_Detail();
    if (result.length > 0) {
      let GridID = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
      setGridrows(GridID);
    }
  };

  const Return_Request_Item_Detail = async () => {
    let detailObj = {
      v_B_TransId: sDetailObj.requestNo
    };

    const vDetail = await fExecuteAPI('GET_RETURN_REQUEST_REVIEW_ITEM_LIST', detailObj);
    if (vDetail?.status === 200) {
      return vDetail.data.ResultSet;
    } else if (
      vDetail.response.status === 500 ||
      vDetail.response.status === 400 ||
      vDetail.response.status === 404 ||
      vDetail.response.status === 403
    ) {
      fAlertToast('FAILED', vDetail.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'something went wrong');
      return {};
    }
  };

  const fReverseReviewRequest = async () => {
    let reverseReviewObj = {
      v_A_TransId: sDetailObj.requestNo
    };
    const vReviewReverse = await fExecuteAPI('GET_REVERSE_RETURN_REQUEST_REVIEW', reverseReviewObj);
    if (vReviewReverse?.status === 200) {
      return vReviewReverse.data;
    } else if (
      vReviewReverse.response.status === 500 ||
      vReviewReverse.response.status === 400 ||
      vReviewReverse.response.status === 404 ||
      vReviewReverse.response.status === 403
    ) {
      fAlertToast(
        'FAILED',
        vReviewReverse.response.data.message
        // ? vReviewReverse.response.data.message
        // : ' something went wrong '
      );
      return {};
    }
  };

  const onPressSave = async () => {
    if (xRDRowDetail.A_DCStatus === 'Post') {
      confirmAlert({
        title: 'Reverse',
        message: 'Are you sure to Reverse.',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const vReverseReview = await fReverseReviewRequest();
              if (vReverseReview.Type === 'Success') {
                dispatch(ReturnRequestReview_DCStatus({ A_DCStatus: 'Pending' }));
                fAlertToast('SUCCESS', 'Reversed Successfully');
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else {
      fSaveReviewRequest(true);
    }
  };

  const fSaveReviewRequest = async (isSave) => {
    if (EmptyValidation()) {
      const updateValue = sGridrows.map((itm) => ({
        v_B_SrNo: itm.B_SrNo,
        v_B_Status: itm.B_Status,
        v_B_Type: itm.B_Type,
        v_B_Acc_Qty: itm.B_Acc_Qty,
        v_B_Acc_BaseQty: itm.B_Acc_BaseQty,
        v_B_DC_LPCost: itm.B_DC_LPCost,
        v_B_DC_NetAmt: itm.B_DC_NetAmt,
        v_B_Vendor: itm.B_Vendor,
        v_B_VendorCost: itm.B_VendorCost,
        v_B_VendorNetAmt: itm.B_VendorNetAmt
      }));
      const result = await saveReviewDetail(updateValue, isSave);
      if (result.Type === 'Success' && isSave) {
        fAlertToast('SUCCESS', 'Saved Successfully');
      }
    }
  };

  const saveReviewDetail = async (updateValue, ifSave) => {
    let saveObj = {
      v_A_TransId: sDetailObj.requestNo,
      v_A_Type: sDetailObj.Type,
      v_A_DCStatus: xRDRowDetail.A_DCStatus,
      itemList: updateValue
    };

    const vSave = await fExecuteAPI('GET_RETURN_REQUEST_REVIEW_DETAIL_SAVE', saveObj);

    if (vSave?.status === 200) {
      return vSave.data;
    } else if (vSave.response.status === 500 || ifSave) {
      fAlertToast('FAILED', vSave.response.data.message);
      return {};
      // } else {
      //   fAlertToast('FAILED', 'something went wrong');
      //   return {};
    }
  };

  const onPressPost = async () => {
    if (Validation() && EmptyValidation()) {
      const resp = await fSaveReviewRequest(false);
      console.log('*********SAVE*****************', resp);
      const result = await postReviewDetail();
      if (result.Type === 'Success') {
        fAlertToast('SUCCESS', 'Post Successfully');
        dispatch(ReturnRequestReview_DCStatus({ A_DCStatus: 'Post' }));
      }
    }
  };

  const postReviewDetail = async () => {
    let postObj = {
      v_A_TransId: sDetailObj.requestNo
    };
    const vPost = await fExecuteAPI('GET_RETURN_REQUEST_REVIEW_DETAIL_POST', postObj);
    if (vPost?.status === 200) {
      return vPost.data;
    } else if (
      vPost.response.status === 500 ||
      vPost.response.status === 400 ||
      vPost.response.status === 404 ||
      vPost.response.status === 403
    ) {
      fAlertToast('FAILED', vPost.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'something went wrong');
      return {};
    }
  };

  const onChangeDateTo = (newValue) => {
    setDetailObj((obj) => ({ ...obj, dateTo: newValue.$d }));
  };

  const onChangeType = (event) => {
    setDetailObj((obj) => ({ ...obj, Type: event.target.value }));
    if (event.target.value === 'Accepted') {
      const updateRow = sGridrows.map((itm) => ({
        ...itm,
        B_Status: 'Accept',
        B_Acc_Qty: itm.B_Qty,
        // B_Type: 'DC' changed on 28th Jan
        B_Type: 'PR'
      }));
      setGridrows(updateRow);
    } else if (event.target.value === 'Rejected') {
      const updateRow = sGridrows.map((itm) => ({
        ...itm,
        B_Status: 'Reject',
        B_Acc_Qty: 0,
        B_Type: '--'
      }));
      setGridrows(updateRow);
    }
  };

  const VendorList = async () => {
    const result = await getVendorList();
    setVendorList(result);
  };

  const getVendorList = async () => {
    let vendorObj = {};
    const vVendor = await fExecuteAPI('GET_VENDOR_LIST', vendorObj);
    if (vVendor?.status === 200) {
      return vVendor.data.ResultSet;
    } else if (
      vVendor.response.status === 500 ||
      vVendor.response.status === 400 ||
      vVendor.response.status === 404 ||
      vVendor.response.status === 403
    ) {
      fAlertToast('FAILED', vVendor.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'something went wrong');
      return {};
    }
  };

  useEffect(() => {
    const prAmntArr = sGridrows.map((e) => e.B_VendorNetAmt);
    const PRAmount = prAmntArr.reduce((prev, curr) => prev + curr, 0);
    setBufferObj((obj) => ({
      ...obj,
      B_PR_Amount: PRAmount
    }));
  }, [sBufferObj.B_PR_Amount, sGridrows]);

  return (
    <div>
      <div>
        <ToastContainer />
      </div>
      <div>
        <ReturnRequestReviewBreadcrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="return-request-review-m">
          <Grid item xs={2.5} sm={1.1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressSave}
              xLabel={'Save'}
            />
          </Grid>
          <Grid item xs={2.5} sm={1.1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressPost}
              xLabel={'Post'}
              xDisable={xRDRowDetail.A_DCStatus === 'Post' ? true : false}
            />
          </Grid>

          <Grid item xs={2.5} sm={1.1} md={1}>
            <CmpButton xvariant={'contained'} xsize={'small'} xonClick={''} xLabel={'Print'} />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 0 }}
          columnSpacing={{ xs: 2, sm: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ justifyContent: 'between' }}
          className={vMediaQuery ? '' : 'mt-1'}>
          <Grid item xs={12} sm={12} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={1} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans.Id'} />
              </Grid>
              <Grid item xs={3} sm={3} md={8}>
                <CmpInputField
                  xValue={sDetailObj.requestNo}
                  xType={'text'} // text/number/password
                  xName={'requestNo'}
                  xReadOnly={true}
                  xOnChange={''}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
              <Grid item xs={2} sm={1} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Date'} />
              </Grid>
              <Grid item xs={4} sm={3} md={8}>
                <CmpDatePickerField
                  xValue={sDetailObj.dateTo}
                  xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xDisabled={true}
                />
                {/* <CmpInputField
                  xValue={sDetailObj.dateTo}
                  xName={'dateTo'}
                  xReadOnly={true}
                  xOnChange={''}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                /> */}
              </Grid>
              <Grid item xs={3} sm={1} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'DC'} />
              </Grid>
              <Grid item xs={9} sm={3} md={8}>
                <CmpSelectField
                  xLabelText="DC"
                  xValue={sDetailObj.DC}
                  // xOnChange={(event)=> onChangeDCLocation(event)}
                  xName={'DC'}
                  xData={getDCLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={1} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={9} sm={3} md={8}>
                <CmpSelectField
                  xLabelText="Location"
                  xValue={sDetailObj.location}
                  xOnChange={''}
                  xName={'Location'}
                  xData={getLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={true}
                />
              </Grid>

              <Grid item xs={3} sm={1} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={9} sm={3} md={8}>
                <CmpSelectArrField
                  xref={scanFocusOnLoad}
                  xLabelText="Type"
                  xValue={sDetailObj.Type}
                  xOnChange={(event) => onChangeType(event)}
                  xName={'Type'}
                  xData={typeData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="mt-1">
          {/* <GCGridTable /> */}
          {sGridrows.length > 0 && (
            <FullFeaturedCrudGrid
              rowsData={sGridrows}
              setUpdateRows={setGridrows}
              vendorList={sVendorList}
            />
          )}
        </Box>
        {/* <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Grid item xs={5} sm={5} md={1}>
            <CmpTypographyField xcomponent={'span'} xText={'Status'} /> :{' '}
            <CmpTypographyField xcomponent={'span'} xText={xRDRowDetail.A_DCStatus} />

          </Grid>

          <Grid item xs={5} sm={5} md={1} className="text-end">
            <CmpTypographyField xcomponent={'span'} xText={'Total'} />:
            <CmpTypographyField
              xcomponent={'span'}
              xText={
                sBufferObj.B_PR_Amount === '' || sBufferObj.B_PR_Amount === '0'
                  ? '0.00'
                  : Number(sBufferObj.B_PR_Amount).toFixed(2)
              }
            />
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Grid item xs={5} sm={5} md={1}>
            <button
              className={
                xRDRowDetail.A_DCStatus == 'All'
                  ? 'btn-status btn-primary'
                  : xRDRowDetail.A_DCStatus == 'Pending'
                  ? 'btn-status bg-warning'
                  : xRDRowDetail.A_DCStatus == 'Post'
                  ? 'btn-status bg-success'
                  : 'btn-status bg-secondary'
              }>
              <span> STATUS = </span>
              <span id="WMSDocStatID">{xRDRowDetail.A_DCStatus}</span>
            </button>
          </Grid>
          <Grid item xs={5} sm={5} md={1} className="text-end">
            <button className="btn-status btn-primary">
              <span> TOTAL = </span>
              <span>
                {sBufferObj.B_PR_Amount === '' || sBufferObj.B_PR_Amount === '0'
                  ? '0.00'
                  : Number(sBufferObj.B_PR_Amount).toFixed(2)}
              </span>
            </button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ReturnRequestReviewDetail;
