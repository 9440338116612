import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterHead: {
    vendorTyp: 'All',
    vendorCd: '',
    vendorname: ''
  },
  transHead: {
    vocTyp: '',
    VocTypName: '',
    vocNo: '',
    vocDate: new Date(),
    locCd: '',
    locName: '',
    partyCd: '',
    partyName: '',
    deptCd: '',
    deptName: '',
    status: 'ENTER'
  }
};
const PriceEnquirySlice = createSlice({
  name: 'PriceEnquirySlice',
  initialState: initialState,
  reducers: {
    updateFilterHead: (state, action) => {
      state.filterHead = action.payload;
    },
    updateTransHead: (state, action) => {
      state.transHead = action.payload;
    }
  }
});

export const { updateFilterHead, updateTransHead } = PriceEnquirySlice.actions;
export default PriceEnquirySlice.reducer;
