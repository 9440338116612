import { fExecuteAPI } from '../../Server/Apis';

const getDisptachSummaryList = async (xObj) => {
  try {
    const vDispatchList = await fExecuteAPI('GET_DISPATCH_SUMMARY_LIST', xObj);
    return vDispatchList.data;
  } catch (error) {}
};
export const getDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};
const getDispatchDetailItemList = async (xObj) => {
  try {
    const vDetail = await fExecuteAPI('GET_PICK_DETAIL_ITEM_LIST', xObj);
    return vDetail.data;
  } catch (error) {}
};

const updateDispatchItem = async (xObj) => {
  try {
    const vUpdate = await fExecuteAPI('UPDATE_DISPATCH_DETAIL_ITEM', xObj);
    return vUpdate.data;
  } catch (error) {}
};

const reverseDispatchItem = async (xObj) => {
  try {
    const vReverse = await fExecuteAPI('REVERSE_DISPATCH_DETAIL_ITEM', xObj);
    return vReverse;
  } catch (error) {}
};

export {
  getDisptachSummaryList,
  getDispatchDetailItemList,
  updateDispatchItem,
  reverseDispatchItem
};
