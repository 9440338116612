import React, { useEffect, useState, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpButton,
  MUIDataGrid,
  CmpStatusDiv
} from '../../component/ToolBox/ToolBox';
import { getReturnReviewBatchDetail, postReturnReviewBatch } from './actions';
import { useMediaQuery } from '@mui/material';
import { RetunReviewBatchItem, UpdateRetunReviewBatchDetail } from './ReturnReviewBatchSlice';
import { useNavigate } from 'react-router-dom';
import ReviewBatchBreadCrumbs from './ReviewBatchBreadCrumbs';
import { DataGrid } from '@mui/x-data-grid';

export default function ReturnReviewBatchDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rdxBatchDetail = useSelector((state) => state.ReturnReviewBatchSlice.value.detail);
  const [sItemList, setItemList] = useState([]);

  const vMediaQuery = useMediaQuery('(max-width:550px)');

  const loadItems = useCallback(async () => {
    const itemListObj = {
      v_BatchId: rdxBatchDetail.A_BatchId
    };
    const result = await getReturnReviewBatchDetail(itemListObj);
    const obj = result.data.ResultSet;
    const rowObj = obj.map((i) => {
      return { ...i, id: i.ItmCd, reviewed: i.reviewed === 'Y' };
    });
    setItemList(rowObj);
  }, [setItemList, rdxBatchDetail]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const gridColumns = [
    {
      field: 'ItmCd',
      headerName: 'Item code',
      flex: 2,
      hide: vMediaQuery
    },
    {
      field: 'ItemName',
      headerName: 'Item Name',
      flex: 3
    },
    {
      field: 'B_BaseQty',
      headerName: 'Base Qty',
      flex: 1,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'Unit',
      headerName: 'Unit',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: true
    },
    {
      field: 'UnitName',
      headerName: 'Unit',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      hide: vMediaQuery
    },
    {
      field: 'requestCount',
      headerName: 'No.of Requests',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: vMediaQuery
    },
    {
      field: 'reviewed',
      headerName: 'Reviewed',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    }
  ];

  const onRowClick = (param) => {
    dispatch(
      RetunReviewBatchItem({
        batchId: rdxBatchDetail.A_BatchId,
        itemCd: param.row.ItmCd,
        itemName: param.row.ItemName
      })
    );
    navigate('/ReviewBatchItem');
  };

  const onPressPost = async () => {
    const obj = {
      batchId: rdxBatchDetail.A_BatchId
    };
    const result = await postReturnReviewBatch(obj);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Posted Successfully');
      dispatch(
        UpdateRetunReviewBatchDetail({
          ...rdxBatchDetail,
          A_Status: 'Post'
        })
      );
    }
  };

  return (
    <div className="container ReviewBatch">
      <div>
        <ToastContainer />
      </div>
      <div
      // className="my-2"
      >
        <ReviewBatchBreadCrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={2} lg={1} md={2}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => onPressPost()}
              xLabel={'Post'}
              xDisable={rdxBatchDetail.A_Status !== 'Enter'}
            />
          </Grid>
          {/* <Grid item xs={4} sm={2} lg={1} md={2}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            //  xonClick={() => onPressPrint()}
                            xLabel={'Print'}
                            xDisable={false}
                        />
                    </Grid> */}
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Batch Id'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={rdxBatchDetail.A_BatchId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Batch Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxBatchDetail.A_PostDt).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={rdxBatchDetail.DCLocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Departments'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={rdxBatchDetail.Dept} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={rdxBatchDetail.LocNames} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'RequestID'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={rdxBatchDetail.RequestIDs} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="scrol-overflow">
          {sItemList.length > 0 && (
            <Box sx={{ height: '90%', width: '100%' }} className={'mt-3'}>
              <DataGrid
                rows={sItemList}
                columns={gridColumns}
                rowHeight={45}
                headerHeight={50}
                sx={{
                  border: 2,
                  borderColor: 'primary.light'
                }}
                componentsProps={{
                  filterPanel: { sx: { maxWidth: '93vw' } }
                }}
                getRowClassName={(params) => {
                  return params.indexRelativeToCurrentPage % 2 === 0
                    ? params.row.reviewed
                      ? 'mui-even-row '
                      : 'mui-even-row  mui-grid-font-color2'
                    : params.row.reviewed
                    ? 'mui-odd-row '
                    : 'mui-odd-row  mui-grid-font-color2';
                }}
                hideFooter
                onRowClick={(item) => onRowClick(item)}
              />
              {/* <MUIDataGrid
                                xRows={sItemList}
                                xColumns={gridColumns}
                                xonRowClick={onRowClick}
                                xRowHeight={40}
                                xHeaderHeight={50}
                                xRowClass='mui-grid-font-color1'
                            /> */}
            </Box>
          )}
        </div>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={5} sm={5} md={1}>
            {/* <button
                            className={
                                rdxBatchDetail.A_Status === 'Enter'
                                    ? 'btn-status bg-warning'
                                    : rdxBatchDetail.A_Status === 'Post'
                                        ? 'btn-status bg-success'
                                        : 'btn-status bg-secondary'
                            }>
                            <span> Status = </span>
                            <span id="WMSDocStatID">{rdxBatchDetail.A_Status}</span>
                        </button> */}
            <CmpStatusDiv xStatus={rdxBatchDetail.A_Status} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
