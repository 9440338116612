import { Box } from '@mui/material';
import React, { useEffect } from 'react'
import Order from './Order';
import OrderBreadCrumbs from './ProcessComponents/OrderBreadCrumbs';

export default function PostedOrder() {
    useEffect(() => {
        console.log("renderrrrr PostedOrder");
    }, []);
    return (
        <div className="container">
            <div 
            // className="my-2"
            >
                <OrderBreadCrumbs />
            </div>
            <Box sx={{ flexGrow: 1, height: '75vh', overflow: 'auto', paddingRight: '10px' }}>
                <Order xStatus='Post' />
            </Box>
        </div>
    )
}
