import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
// function ReviewBatchBreadCrumbs() {
//   const location = useLocation();
//   const vPathName = location.pathname;
//   return (
//     <div className="container-fluid p-0 mt-0 mb-4 breadcrumb">
//       {vPathName === '/ReviewBatchSummary' ? (
//         <div>
//           <span
//             className="breadcrumb-not-active "
//             //  style={{ fontSize: '1rem', textDecorationLine: 'underline' }}
//           >
//             Review Batch Summary{' '}
//           </span>
//         </div>
//       ) : vPathName === '/ReviewBatchDetails' ? (
//         <div className="d-flex">
//           <Link
//             to="/ReviewBatchSummary"
//             state={location}
//             // style={{ fontSize: '1rem', marginRight: '1rem' }}
//             className={'breadcrumb-active mr-2'}>
//             Review Batch Summary
//           </Link>
//           <ArrowForwardIos color="info" style={{ marginRight: '1px' }} />
//           <div>
//             <span
//               className="breadcrumb-not-active"
//               //  style={{ fontSize: '0.85rem', textDecorationLine: 'underline' }}
//             >
//               Review Batch Detail{' '}
//             </span>
//           </div>
//         </div>
//       ) : vPathName === '/ReviewBatchItem' ? (
//         <div className="d-flex">
//           <Link
//             to="/ReviewBatchSummary"
//             state={location}
//             // style={{ fontSize: '1rem', marginRight: '1rem' }}
//             className={'breadcrumb-active mr-2'}>
//             Review Batch Summary
//           </Link>
//           <ArrowForwardIos color="info" style={{ marginRight: '1px' }} />
//           <Link
//             to="/ReviewBatchDetails"
//             state={location}
//             // style={{ fontSize: '1rem', marginRight: '1rem' }}
//             className={'breadcrumb-active mr-2'}>
//             Review Batch Detail
//           </Link>
//           <ArrowForwardIos color="info" style={{ marginRight: '1px' }} />
//           <div>
//             <span
//               className="breadcrumb-not-active"
//               //  style={{ fontSize: '0.85rem', textDecorationLine: 'underline' }}
//             >
//               Review Batch Item{' '}
//             </span>
//           </div>
//         </div>
//       ) : (
//         <></>
//       )}
//     </div>
//   );
// }

function ReviewBatchBreadCrumbs() {
  const location = useLocation();
  const vPathName = location.pathname;
  return (
    <div className="container-fluid p-0 mt-0 mb-4 breadcrumb">
      {vPathName === '/ReviewBatchSummary' ? (
        <div>
          <span className="breadcrumb-arrow-inactive ">Review Batch Summary </span>
        </div>
      ) : vPathName === '/ReviewBatchDetails' ? (
        <div className="d-flex">
          <Link
            to="/ReviewBatchSummary"
            state={location}
            className={'breadcrumb-arrow-active mr-2'}>
            Review Batch Summary
          </Link>
          <div>
            <span className="breadcrumb-arrow-inactive">Review Batch Detail </span>
          </div>
        </div>
      ) : vPathName === '/ReviewBatchItem' ? (
        <div className="d-flex">
          <Link
            to="/ReviewBatchSummary"
            state={location}
            className={'breadcrumb-arrow-active  mr-2'}>
            Review Batch Summary
          </Link>
          <Link
            to="/ReviewBatchDetails"
            state={location}
            className={'breadcrumb-arrow-active  mr-2'}>
            Review Batch Detail
          </Link>
          <div>
            <span className="breadcrumb-arrow-inactive">Review Batch Item </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default ReviewBatchBreadCrumbs;
