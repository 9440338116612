import React from 'react';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import { CmpDatePickerField } from '../../../component/ToolBox/ToolBox';

const ConsolidateSearch = ({
  xStatusArr = [],
  xLocationArr = [],
  xSearchModalObj = {},
  xGetSearchValue = () => {},
  fonChangeStatusModal = () => {},
  fonChangeVocNoModal = () => {},
  fOnChangeDateModel = () => {},
  fonChangeLocModal = () => {},
  fCloseSearchModal = () => {},
  fClearSearchModal = () => {},
  GridSearchHeadRowData = [],
  GridDataColumnsSearch,
  xOnRowClickSearch = () => {},
  vMediaQuery = false,
  xEnterObj = {
    key: 'Enter'
  },
  xOpen = false,
  xTitle
}) => {
//   const fCheckKey = (event) => {
  
//   };
//   useEffect(() => {
//     setTimeout(() => {
//       document.onkeydown = fCheckKey;
//     }, 1000);
//   }, []);
//   console.log(xSearchModalObj,"xSearchModalObj");

  return (
    <div className="container-fluid">
      <Modal
        open={xOpen}
        onClose={fCloseSearchModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{ flexGrow: 1, overflow: 'auto' }} className="model-GCSearchBtn">
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            className="GCSearchBtn-Tittle"
            sx={{ alignItems: 'center' }}>
            <Grid item xs={11} sm={11} md={11} className="d-flex justify-content-center  ">
              <div>{xTitle}</div>
            </Grid>
            <Grid item xs={1} sm={1} md={1} className="d-flex justify-content-end">
              <CloseIcon
                className="grnt-item-search-close-icon"
                onClick={fCloseSearchModal}
                style={{ zIndex: 999 }}
              />
            </Grid>
          </Grid>
          <div className="p-3">
            <Grid container spacing={2} columns={{ xs: 8, sm: 8, md: 12 }}>
              <Grid item xs={9} className="gnr-item-search-label">
                <Grid container spacing={2} columns={{ xs: 6, sm: 6, md: 12 }}>
                  <Grid item xs={6} className="gnr-item-search-label">
                    <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
                      <Grid item xs={3} className="gnr-item-search-label">
                        Doc No
                      </Grid>
                      <Grid item xs={9} className="gnr-item-search-label">
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          name="VocNo"
                          value={xSearchModalObj?.vocNo}
                          onKeyUp={(e) => {
                            xGetSearchValue(e);
                          }}
                          onChange={(e) => {
                            fonChangeVocNoModal(e);
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} className="gnr-item-search-label">
                        Doc Date
                      </Grid>
                      <Grid item xs={9} className="gnr-item-search-label">
                        {/* <input
                          className="form-control form-control-sm"
                          type="date"
                          name="PostDt"
                          value={moment(xSearchModalObj?.vocDate).format('YYYY-MM-DD')}
                          onKeyUp={(e) => {
                            xGetSearchValue(e);
                          }}
                          onChange={(e) => {
                            fOnChangeDateModel(e);
                          }}
                        /> */}
                        <CmpDatePickerField
                          xtabIndex={'2'}
                          xValue={xSearchModalObj?.vocDate}
                          xOnChange={
                            !!fOnChangeDateModel
                              ? (e) => {
                                  fOnChangeDateModel(e);
                                }
                              : ()=>{}
                          }
                          xName={'docDt'}
                          xError={false}
                          xErrorMessage={''}
                          xOnKeyUp={
                            !!xGetSearchValue
                              ? (e) => {
                                  xGetSearchValue(e);
                                }
                              : ()=>{}
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className="gnr-item-search-label">
                    <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
                      <Grid item xs={3} className="gnr-item-search-label">
                        Status
                      </Grid>
                      <Grid item xs={9} className="gnr-item-search-label">
                        <select
                          className="form-select form-select-sm"
                          name="Status"
                          value={xSearchModalObj?.docStat}
                          onKeyUp={(e) => {
                            xGetSearchValue(e);
                          }}
                          onChange={(e) => {
                            fonChangeStatusModal(e, xStatusArr);
                          }}>
                          {xStatusArr.length > 0 ? (
                            <>
                              {' '}
                              {xStatusArr?.map((value, index) => (
                                <option className="py-2" value={value} key={'WmsStatus' + index}>
                                  {value}
                                </option>
                              ))}{' '}
                            </>
                          ) : (
                            <option value=""></option>
                          )}
                        </select>
                      </Grid>
                      <Grid item xs={3} className="gnr-item-search-label">
                        Location
                      </Grid>
                      <Grid item xs={9} className="gnr-item-search-label">
                        <select
                          className="form-select form-select-sm"
                          name="Loc"
                          value={xSearchModalObj?.loc}
                          onKeyUp={(e) => {
                            xGetSearchValue(e);
                          }}
                          onChange={(e) => {
                            fonChangeLocModal(e);
                          }}>
                          {xLocationArr?.length > 0 ? (
                            <>
                              {' '}
                              {xLocationArr?.map((option, index) => (
                                <option
                                  className="py-2"
                                  value={option.UId}
                                  key={'Location' + index}>
                                  {option.Name1}
                                </option>
                              ))}{' '}
                            </>
                          ) : (
                            <option value=""></option>
                          )}
                        </select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={0.5} lg={0.5} />
              <Grid item xs={8} sm={4} md={2} className="gnr-item-search-label align-self-center">
                <Grid container spacing={1} columns={{ xs: 36, sm: 36, md: 12 }}>
                  {vMediaQuery ? (
                    <Grid item xs={12} className="gnr-item-search-label mt-2">
                      <button
                        className="btn btn-primary  search-btn"
                        onClick={(e) => {
                          xGetSearchValue(xEnterObj);
                        }}>
                        Enter
                      </button>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} className="gnr-item-search-label ">
                    <button className="btn btn-primary  search-btn" onClick={fClearSearchModal}>
                      Clear
                    </button>
                  </Grid>
                  <Grid item xs={12} className="gnr-item-search-label ">
                    <button
                      className="btn btn-primary  search-btn"
                      onClick={(e) => {
                        fCloseSearchModal(e);
                        fClearSearchModal(e);
                      }}>
                      Cancel
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={1} columns={{ xs: 4, sm: 4, md: 12 }}>
              <Grid item xs={12} className="gnr-item-search-label mt-2">
                <div
                  style={{ height: vMediaQuery ? 200 : 300, width: '100%' }}
                  id="ConsolidateSearch">
                  <DataGrid
                    rows={GridSearchHeadRowData}
                    columns={GridDataColumnsSearch}
                    onCellKeyDown={(newSelectionModel, e) => {
                      if (e.key === 'Enter') {
                        xOnRowClickSearch(newSelectionModel);
                      }
                    }}
                    componentsProps={{
                      filterPanel: { sx: { maxWidth: "93vw" } }
                    }}
                    onRowClick={(item)=>xOnRowClickSearch(item)}
                    // pageSize={5}
                    // rowsPerPageOptions={[5]}
                    hideFooter
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConsolidateSearch;
