import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './PickingSummary.css';
import { CmpTypographyField, CmpInputField, CmpButton } from '../../component/ToolBox/ToolBox';
import PickingDetailCard from './processComponent/PickingDetailCard';
import PickingSummaryBreadCrumbs from './PickingSummaryBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getPickingDetailItemList, reversePickingItem, updatePickingItem } from './action';
import {
  Picking_Detail_Narration,
  Picking_Detail_RefNo,
  Picking_Detail_UpdateStatus
} from './PickingSlice';
import { fAlertToast } from '../../Utility/Utilitys';
import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function PickingDetail() {
  const dispatch = useDispatch();
  const xItemDetail = useSelector((state) => state.PickingSlice.value.detail);
  const scanFocusOnLoad = useRef(null);

  const [sItemList, setItemList] = useState([]);

  useEffect(() => {
    getPickingDetailItemLists();
  }, [xItemDetail.A_TransId]);

  const getPickingDetailItemLists = async () => {
    scanFocusOnLoad.current.focus();
    const itemListObj = {
      v_B_TransId: xItemDetail.A_TransId
    };
    const result = await getPickingDetailItemList(itemListObj);
    setItemList(result.ResultSet);
  };

  const Validation = () => {
    let iRes = true;

    if (xItemDetail.A_RefNo == '') {
      fAlertToast('FAILED', 'Ref No is missing');
      return (iRes = false);
    }

    if (xItemDetail.A_Narration == '') {
      fAlertToast('FAILED', 'Narration is  missing');
      return (iRes = false);
    }
    const confrQty = sItemList.filter((data) => data.B_Pick_Qty == '');

    if (confrQty.length > 0) {
      fAlertToast('FAILED', 'Conf Qty cannot be zero or empty');
      return (iRes = false);
    }

    const checkQty = sItemList.filter((data) => data.B_Pick_Qty > data.B_Qty);

    if (checkQty.length > 0) {
      fAlertToast('FAILED', 'Conf Qty cannot be greater then Qty');
      return (iRes = false);
    }

    return iRes;
  };

  const fupdatePickingItem = async () => {
    if (xItemDetail.A_PickStatus == 'Post') {
      confirmAlert({
        title: 'Reverse',
        message: 'Are you sure to Reverse.',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const reverseObj = {
                v_A_TransId: xItemDetail.A_TransId
              };
              const vReversePicking = await reversePickingItem(reverseObj);
              if (vReversePicking.status == 200) {
                dispatch(Picking_Detail_UpdateStatus('Enter'));
                fAlertToast('SUCCESS', 'Reversed Successfully');
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else {
      const ItemList = sItemList.map((itm) => {
        return {
          // ...itm,
          v_B_SrNo: itm.B_SrNo,
          v_B_Pick_Qty: itm.B_Pick_Qty,
          v_B_Pick_NetAmt: itm.B_Pick_NetAmt
        };
      });

      if (Validation()) {
        const updateObj = {
          v_A_TransId: xItemDetail.A_TransId,
          v_A_RefNo: xItemDetail.A_RefNo,
          v_A_Narration: xItemDetail.A_Narration,
          itemList: ItemList,
          v_Status: 'Enter'
        };

        const result = await updatePickingItem(updateObj);
        if (result.Type === 'Success') {
          fAlertToast('SUCCESS', 'saved successfully');
          getPickingDetailItemLists();
        }
      }
    }
  };

  const fpostPickingItem = async () => {
    const ItemList = sItemList.map((itm) => {
      return {
        // ...itm,
        v_B_SrNo: itm.B_SrNo,
        v_B_Pick_Qty: itm.B_Pick_Qty,
        v_B_Pick_NetAmt: itm.B_Pick_NetAmt
      };
    });
    if (Validation()) {
      const postObj = {
        v_A_TransId: xItemDetail.A_TransId,
        v_A_RefNo: xItemDetail.A_RefNo,
        v_A_Narration: xItemDetail.A_Narration,
        itemList: ItemList,
        v_Status: 'Post'
      };

      const result = await updatePickingItem(postObj);
      if (result.Type === 'Success') {
        fAlertToast('SUCCESS', 'post successfully');
        dispatch(Picking_Detail_UpdateStatus('Post'));
      }
    }
  };

  const onChangePickQty = (event, item, index) => {
    // setConf_Qty(event.target.value);
    let updatedValue = sItemList.map((obj) => {
      if (obj.B_SrNo === item.B_SrNo) {
        let objUpdate = obj;
        obj.B_Pick_Qty = Number(event.target.value);
        obj.B_Pick_NetAmt = Number(event.target.value) * obj.B_UnitRate;
        return objUpdate;
      } else {
        return obj;
      }
    });
    setItemList(updatedValue);
  };

  return (
    <div className="container Picking">
      <div>
        <ToastContainer />
      </div>
      <div 
      // className="my-2"
      >
        <PickingSummaryBreadCrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={4} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fupdatePickingItem}
              xLabel={'Save'}
              // xDisable={xItemDetail.A_PickStatus === 'Post' ? true : false}
            />
          </Grid>
          <Grid item xs={4} sm={4} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fpostPickingItem}
              xLabel={'Post'}
              xDisable={xItemDetail.A_PickStatus === 'Post' ? true : false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6} sm={6} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_TransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.TruckName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6} sm={6} lg={9} md={9}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(xItemDetail.A_PostDt).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_Type} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6} sm={6} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.LocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Ref No'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={3} md={3}>
                <CmpInputField
                  xValue={xItemDetail.A_RefNo}
                  xType={'text'} // text/number/password
                  xName={'RefNo'}
                  xReadOnly={xItemDetail.A_PickStatus === 'Post' ? true : false}
                  xOnChange={(event) => {
                    dispatch(Picking_Detail_RefNo(event.target.value));
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xref={scanFocusOnLoad}
                  xTabIndex="1"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Req.Trans.ID'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6} sm={6} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_ReqTransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Narration'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={3} md={3}>
                <CmpInputField
                  xValue={xItemDetail.A_Narration}
                  xType={'text'} // text/number/password
                  xName={'Narration'}
                  xReadOnly={xItemDetail.A_PickStatus === 'Post' ? true : false}
                  xOnChange={(event) => {
                    dispatch(Picking_Detail_Narration(event.target.value));
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTabIndex="2"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.Vendorname} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="scrol-overflow">
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            sx={{ alignItems: 'center' }}>
            {sItemList.map((itm, index) => {
              // itm.B_Conf_NetAmt = Number(itm.B_Conf_Qty * itm.B_UnitRate);
              return (
                <Grid item xs={12} sm={6} lg={6} md={6} key={itm.B_SrNo}>
                  <PickingDetailCard
                    xSrNo={itm.B_SrNo}
                    xQty={itm.B_Qty}
                    xItem={itm.B_ItmCd}
                    xNetAmount={itm.B_Pick_NetAmt}
                    xConfQty={Number(itm.B_Pick_Qty)}
                    xOnChangeConfQty={(event) => {
                      onChangePickQty(event, itm, index);
                    }}
                    xUnitRate={itm.B_UnitRate}
                    xUnit={itm.UnitName}
                    xReadQty={xItemDetail.A_PickStatus === 'Post' ? true : false}
                    xPacking={itm.packing == '' ? '--' : itm.packing}
                    xItemName={itm.ItemName}
                    xTabIndex={'3'}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
        {/* <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          sx={{ alignItems: 'center', justifyContent: 'start' }}
          className="my-2">
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <CmpTypographyField xcomponent={'span'} xText={'STATUS'} />
            <CmpTypographyField xcomponent={'span'} xText={':'} />
            <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_PickStatus} />
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Grid item xs={5} sm={5} md={1}>
            <button
              className={
                xItemDetail.A_PickStatus == 'All'
                  ? 'btn-status btn-primary'
                  : xItemDetail.A_PickStatus == 'Pending'
                  ? 'btn-status bg-warning'
                  : xItemDetail.A_PickStatus == 'Post'
                  ? 'btn-status bg-success'
                  : 'btn-status bg-secondary'
              }>
              <span> STATUS = </span>
              <span id="WMSDocStatID">{xItemDetail.A_PickStatus}</span>
            </button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default PickingDetail;
