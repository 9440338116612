import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import { CmpTypographyField } from '../../component/ToolBox/ToolBox';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import RadialSeparators from '../../component/ReuseableComponent/RadialSeparators';
import {
    // CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { Typography } from '@mui/material';
import { updateOrderHead } from './FishPurchaseSlice';
import { getOrderSummary } from './actions';

const CircularDiv = styled.div`
max-width: 4rem;
margin: 2rem auto;
`;

const StyledDiv = styled.div`
overflow-y: auto;
display: block;
text-align:center;

`;

const BoxDiv = styled.div`
border: 1px solid #0d73bb;
border-radius: 5px;
// background-color: #0d73bb;
// color: #fff;
// margin: 5px;
text-align: center;
cursor:pointer;
box-shadow: 1px 1px 2px 0px #0d73be;
// height:10rem;
&:hover {
    box-shadow: #0d73be 2px 2px 1px 1px;
      `;

export default function FishOrder({ xStatus }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const rdxUser = useSelector((state) => state.SliceDB.value);
    const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);

    const [sOrderList, setOrderList] = useState([]);

    const loadDetail = useCallback(async () => {
        const reqObj = {
            buyerCd: rdxUser.userInfo.Buyer,
            fromDate: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
            toDate: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
            status: xStatus,
            dcLocs: rdxFilterObj.DCList.toString(),
            usercd: rdxUser.login.userId,
            Dept: 'FF'
        };
        const result = await getOrderSummary(reqObj);
        console.log("sOrderListtttttt" + JSON.stringify(result));
        const obj = result.ResultSet;
        setOrderList(obj);
    }, [setOrderList, rdxFilterObj, rdxUser, xStatus]);

    useEffect(() => {
        loadDetail();
    }, [loadDetail]);


    const onClickCard = (item) => {
        console.log("card clickeddddd");
        dispatch(updateOrderHead({
            orderId: item.ID,
            status: item.Status,
            orderDate: item.Date,
            purCompletion: item.Purcompletion,
            purAccuracy: item.PurAccuracy,
            deviation: item.deviation,
            costDeviation: item.costdeviation,
            marketPurchase: item.MarketPurchase,
            purchaseCount: item.PurchaseCount
        }));
        navigate('/FishOrderDetail');
    }
    const cardData = (data) => {
        return (
            <React.Fragment key={data.ID}>
                <CardContent>

                    <CmpTypographyField xVariant='h3' xText={data.ID} />
                    <br />
                    <Grid container spacing={2} >
                        <Grid item xs={9} align='left'>
                            <CmpTypographyField xcomponent={'span'} xText={data.Purcompletion + "% of purchase Completion"} />
                            <CmpTypographyField xcomponent={'span'} xText={data.PurAccuracy + "% of Accuracy purchase"} />
                            <CmpTypographyField xcomponent={'span'} xText={data.deviation + " of deviation"} />
                            <CmpTypographyField xcomponent={'span'} xText={data.costdeviation + " of cost deviation"} />
                            <CmpTypographyField xcomponent={'span'} xText={"No of purchase invoice :" + data.PurchaseCount} />
                            <CmpTypographyField xcomponent={'span'} xText={moment(data.Date).format("DD/MM/YYYY")} />
                            <CmpTypographyField xcomponent={'span'} xText={data.MarketPurchase === 'Y' ? "Market Purchase" : "Outlet Purchase"} />
                        </Grid>
                        <Grid item xs={3} align='right'>
                            <CircularDiv>
                                <CircularProgressbarWithChildren
                                    value={data.Purcompletion}
                                    text={`${data.Purcompletion}%`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        strokeLinecap: "butt"
                                    })}
                                >
                                    <RadialSeparators
                                        count={12}
                                        style={{
                                            background: "#fff",
                                            width: "1px",
                                            // This needs to be equal to props.strokeWidth
                                            height: `${10}%`
                                        }}
                                    />
                                </CircularProgressbarWithChildren>
                            </CircularDiv>

                        </Grid>
                    </Grid>
                </CardContent>
            </React.Fragment>
        )
    }

    return (
        <StyledDiv>
            <Grid container spacing={1}>
                {sOrderList.map((value, i) =>
                    <Grid item xs={12} sm={4} md={3} lg={3} key={"order-card-grid" + value.ID} >
                        {/* <Box sx={{
                            // width: "20rem",
                            // "&:hover": {
                            "box-shadow": "0 3px 10px #0d73bb"
                            // },
                        }}> */}
                        <BoxDiv>
                            <Card key={"order" + value.ID} variant="outlined" onClick={() => onClickCard(value)}>{cardData(value)}</Card>
                        </BoxDiv>
                        {/* </Box > */}
                    </Grid>
                )}
            </Grid>
        </StyledDiv>
    );
}