import { fExecuteAPI } from '../../Server/Apis';
import { fAlertToast } from '../../Utility/Utilitys';

const getLogin = async (xObj) => {
  try {
    const vLogin = await fExecuteAPI('GET_API_LOGIN', xObj);
    return vLogin;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};
const getKern = async (xObj) => {
  try {
    const iResp = await fExecuteAPI('GET_API_KERN', xObj);
    return iResp;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

const getTruckLists = async (xObj) => {
  try {
    const vTruck = await fExecuteAPI('GET_TRUCK_LIST', xObj);
    return vTruck;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

const getBuyerAccess = async (xObj) => {
  try {
    const vBuyer = await fExecuteAPI('GET_BUYER_ACCESS', xObj);
    return vBuyer;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

const getReturnRequestReviewLocation = async (xObj) => {
  try {
    const vRetReqRevLoc = await fExecuteAPI('GET_RETURN_REQUEST_REVIEW_LOCATIONS', xObj);
    return vRetReqRevLoc;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

const getPickLocation = async (xObj) => {
  try {
    const vPickLoc = await fExecuteAPI('GET_PICK_LOCATION', xObj);

    return vPickLoc;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

const getKernValue = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_KERN', xObj);
    return result.data;
  } catch (error) {}
};

const getFpUserRights = async (xObj) => {
  try {
    const vBuyer = await fExecuteAPI('FP_USER_RIGHTS', xObj);
    return vBuyer;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};
const getMenuList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_MENUS', xObj);
    return result.data;
  } catch (error) {}
};

export {
  getLogin,
  getKern,
  getTruckLists,
  getBuyerAccess,
  getReturnRequestReviewLocation,
  getPickLocation,
  getKernValue,
  getFpUserRights,
  getMenuList
};
