import React, { useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
// Local
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpSelectArrField,
  CmpButton,
  CmpCheckboxField,
  CmpCheckboxIntermediate,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import DCReceipt_Breadcrumbs from './DCReceiptBreadCrumbs';
import { getReturnRequestDCSummary } from './action';
import { DCReceipt_RowDetail, DCReceipt_SummaryObj } from './DCReceiptSlice';
//import './ReturnRequestAction';
//import { ReturnRequest_Head, ReturnRequest_RowDetail } from './returnRequestSlice';
import { Gridcolumns } from './DCReceiptAction';
import { AddCircle, Refresh } from '@mui/icons-material';
//import ReturnRequest_Breadcrumbs from './ReturnRequest_BreadCrumbs';
//import { getReturnRequestSummary } from './action';

const statusData = ['All', 'Enter', 'Post'];

const DCReceiptSummary = () => {
  const vMediaQuery = useMediaQuery('(max-width:780px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDCLocations = useSelector((state) => state.SliceDB.value.getDCLocation);
  const xRDSummaryobj = useSelector((state) => state.DCReceiptSlice.value.summaryObj);
  const vRdxkern = useSelector((state) => state.SliceDB.value.Kern);
  const userInfo = useSelector((state) => state.SliceDB.value.login);
  const scanFocusOnLoad = useRef(null);
  const [sGridrows, setGridrows] = useState([]);

  const fSummary_OnChange = (e) => {
    dispatch(
      DCReceipt_SummaryObj({
        ...xRDSummaryobj,
        [e.target.name]: e.target.value
      })
    );
  };

  const ReturnRequestSummary_DC_REceipt = async () => {
    let vSumaryObj = {
      v_A_DCLoc:
        xRDSummaryobj?.DC == '' || getDCLocations.length > 0
          ? getDCLocations[0]?.UId
          : xRDSummaryobj?.DC,
      v_Date1: moment(xRDSummaryobj?.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(xRDSummaryobj?.dateTo).format('MM/DD/YYYY'),
      v_A_DCStatus: xRDSummaryobj?.status,
      v_UserCd: userInfo.userId
    };

    const vSummary = await getReturnRequestDCSummary(vSumaryObj);
    return vSummary.data.ResultSet;
  };

  const fOnpressLoad = async () => {
    const result = await ReturnRequestSummary_DC_REceipt();
    // if (result.length > 0) {
    let GridID = result?.map((obj, i) => ({
      ...obj,
      id: i + 1
      //A_PostDt: moment(obj.A_PostDt).format('DD/MM/YYYY')
    }));
    setGridrows(GridID);
    // }
    // document.getElementsByName('Location')[0].focus();
    scanFocusOnLoad.current.focus();
  };

  const fOnRowClick = (item) => {
    dispatch(
      DCReceipt_RowDetail({
        A_TransId: item.row.A_TransId,
        A_PostDt: item.row.A_PostDt,
        A_Loc: item.row.A_Loc,
        LocName: item.row.LocName,
        DC_RECEIVED_STATUS: item.row.DC_RECEIVED_STATUS
      })
    );
    navigate('/DCReceiptDetail');
  };

  useEffect(() => {
    dispatch(
      DCReceipt_SummaryObj({
        dateFrom: moment(xRDSummaryobj?.dateFrom),
        dateTo: moment(xRDSummaryobj?.dateTo),
        status: xRDSummaryobj?.status,
        DC:
          xRDSummaryobj?.DC == '' || getDCLocations.length > 0
            ? getDCLocations[0]?.UId
            : xRDSummaryobj?.DC
      })
    ); // in this it will dispatch but you cannot read
    fOnpressLoad();
  }, []);

  return (
    <div className="container">
      <div>
        {' '}
        <ToastContainer />
      </div>
      <DCReceipt_Breadcrumbs />
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7.5} sm={7} md={8}>
                <CmpDatePickerField
                  xTabIndex="2"
                  xValue={xRDSummaryobj?.dateFrom}
                  xOnChange={(newValue) =>
                    dispatch(
                      DCReceipt_SummaryObj({
                        ...xRDSummaryobj,
                        dateFrom: newValue.$d
                      })
                    )
                  }
                  xName={'dateFrom'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7.5} sm={7} md={8}>
                <CmpDatePickerField
                  xTabIndex="3"
                  xValue={xRDSummaryobj?.dateTo}
                  xOnChange={(newValue) =>
                    dispatch(
                      DCReceipt_SummaryObj({
                        ...xRDSummaryobj,
                        dateTo: newValue.$d
                      })
                    )
                  }
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'DC'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7.5} sm={7} md={8}>
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="DC"
                  xValue={xRDSummaryobj?.DC}
                  xOnChange={(event) => fSummary_OnChange(event)}
                  xName={'DC'}
                  xData={getDCLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7.5} sm={7} md={8}>
                <CmpSelectArrField
                  xTabIndex="5"
                  xLabelText="status"
                  xValue={xRDSummaryobj?.status}
                  xOnChange={(event) => fSummary_OnChange(event)}
                  xName={'status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Grid
              container
              spacing={4}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ justifyContent: vMediaQuery ? 'center' : 'end', paddingTop: 1 }}>
              <Grid item xs={3} sm={2} md={1.5} className="">
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  //xonClick={fOnpressNew}
                  xLabel={'New'}
                  xIcon={<AddCircle />}
                />
              </Grid>
              <Grid item xs={3} sm={2} md={1.5}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ height: '40vh', width: '100%' }} className="mt-4">
          <DataGrid
            rowHeight={45}
            headerHeight={50}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            rows={sGridrows}
            columns={Gridcolumns}
            // pageSize={10}
            // rowsPerPageOptions={[10]}
            hideFooter={sGridrows.length > 100}
            onRowClick={(item) => fOnRowClick(item)}
          />
        </Box>
      </Box>
    </div>
  );
};

export default DCReceiptSummary;
