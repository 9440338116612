import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton
} from '../../../component/ToolBox/ToolBox';
function PickingSumarryCard({
  xTransId,
  xTruck,
  xDate,
  xStatus,
  xLoc,
  xNumberOfItems,
  xVendor,
  xType
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid
        container
        spacing={0}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ alignItems: 'center' }}>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xTransId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xTruck} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Date'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xDate} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Status'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xStatus} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Location'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xLoc} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'No.of.Item'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xNumberOfItems} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {xType == 'PR' && (
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} md={7} className="txt-overflow-ellipse">
                <CmpTypographyField xcomponent={'span'} xText={xVendor} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
export default PickingSumarryCard;
