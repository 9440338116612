import { CancelRounded, CheckCircle, Circle, Close, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, CardContent, Collapse, Grid, IconButton, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { CmpButton, CmpTypographyField, StatusIcon } from '../../../component/ToolBox/ToolBox';
import { getOrderDetails, postOrder } from '../action';
import PostModalGrid from './PostModalGrid';
import '../Order.css'
import { fAlertToast } from '../../../Utility/Utilitys';
import { updateOrderHeadSatus } from '../OrderSlice';
import { useDispatch } from 'react-redux';


export default function PostModal({
    orderNo,
    onClose,
    itemList
}) {
    const dispatch = useDispatch();
    const [sPurchaseOpen, setPurchaseOpen] = useState(false);
    const [sReceivingOpen, setReceivingOpen] = useState(false);
    const [sMismatchOpen, setMismatchOpen] = useState(false);

    const [sPurchaseList, setPurchaseList] = useState([]);
    const [sReceivingList, setReceivingList] = useState([]);

    const mismatchItems = itemList.filter((i) => i.BasseQty - i.PurchasedQty !== 0);
    const mismatchRows = mismatchItems.map(i => {
        return {
            id: i.ItmCd,
            itmCd: i.ItmCd,
            name: i.ItmName,
            orderQty: i.BasseQty,
            purchaseQty: i.PurchasedQty,
            balance: Number(i.BasseQty - i.PurchasedQty).toFixed(2),
        }
    });
    const togglePurchase = () => {
        setPurchaseOpen(!sPurchaseOpen);
        setReceivingOpen(false);
        setMismatchOpen(false);
    };
    const toggleReceiving = () => {
        setReceivingOpen(!sReceivingOpen);
        setPurchaseOpen(false);
        setMismatchOpen(false);
    };
    const toggleMismatch = () => {
        setMismatchOpen(!sMismatchOpen);
        setPurchaseOpen(false);
        setReceivingOpen(false);
    };

    const onClickPost = async () => {
        confirmAlert({
            title: 'Post',
            message: 'Are you sure to Post?',

            buttons: [
                {
                    className: 'alertBtn',
                    label: 'Yes',
                    onClick: async () => {
                        const result = await postOrder({ orderId: orderNo });
                        if (result.status === '200') {
                            dispatch(updateOrderHeadSatus({ status: 'POST' }));
                            fAlertToast('SUCCESS', 'posted Successfully');
                        }

                        onClose(false);
                    }
                },
                {
                    className: 'alertBtn',
                    label: 'No',
                    onClick: () => { }
                }
            ],
            // overlayClassName: "overlay-custom-class-name"
        });

    }
    const loadDetail = useCallback(async () => {
        const obj = {
            orderId: orderNo
        };

        const result = await getOrderDetails(obj);
        setPurchaseList(result.ResultSet.Purchase.map(i => { return { ...i, id: i.TransId } }));
        setReceivingList(result.ResultSet.VanTrans.map(i => { return { ...i, id: i.VanTransId } }));

    }, []);

    useEffect(() => {
        loadDetail();
    }, [loadDetail]);


    const purchaseColumns = [
        {
            field: 'TransId',
            headerName: 'Trans Id',
            flex: 3,
        },
        {
            field: 'VendorName',
            headerName: 'Vendor',
            flex: 3,
        },
        {
            field: 'Amount',
            headerName: 'Amount',
            flex: 2,
            align: 'right',
            headerAlign: 'right'
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 2,
        },
    ];

    const receivingColumns = [
        {
            field: 'VanTransId',
            headerName: 'Trans Id',
            flex: 2,
        },
        {
            field: 'PurchaseTransId',
            headerName: 'Purchase Id',
            flex: 2,
        },
        {
            field: 'TruckName',
            headerName: 'Truck',
            flex: 2,

        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
        },
    ];


    const mismatchColumns = [
        {
            field: 'itmCd',
            headerName: 'Item Code',
            flex: 2,
        },
        {
            field: 'name',
            headerName: 'Item Name',
            flex: 4,
        },
        {
            field: 'orderQty',
            headerName: 'Order Qty',
            flex: 1,
            align: 'right',
            headerAlign: 'right'
        },
        {
            field: 'purchaseQty',
            headerName: 'Purchased',
            flex: 1,
            align: 'right',
            headerAlign: 'right'
        },
        {
            field: 'balance',
            headerName: 'Balance',
            flex: 1,
            align: 'right',
            headerAlign: 'right'
        },
    ];
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
                <Grid item xs={12} className="post-Modal-title d-flex justify-content-between">
                    <span> Order : {orderNo}</span>
                    <Close onClick={() => onClose(false)} />
                </Grid>
            </Grid>
            <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mt-3'>
                <Grid item xs={12}>
                    <div className='post-Modal-menu'>
                        <Grid container alignItems='center'>
                            <Grid item xs={5} sm={6}>
                                <CmpTypographyField xcomponent={'span'} xText={'Purchase'} />
                            </Grid>
                            <Grid item xs={3} sm={2.5}>
                                <StatusIcon xStatus='Post' />
                                <span className='p-2'>{sPurchaseList.filter(i => i.Status === 'POST').length}</span>
                            </Grid>
                            <Grid item xs={3} sm={2.5}>
                                <StatusIcon xStatus='Enter' />
                                <span className='p-2'>{sPurchaseList.filter(i => i.Status !== 'POST').length}</span>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <div >
                                    <IconButton onClick={() => togglePurchase()}>
                                        {sPurchaseOpen ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </div>
                            </Grid>

                        </Grid>
                        <Collapse in={sPurchaseOpen} timeout="auto" unmountOnExit>
                            <Box sx={{ typography: 'h5' }}>
                                <div className='post-Modal-collapse'>
                                    <PostModalGrid
                                        xRows={sPurchaseList}
                                        xColumns={purchaseColumns}

                                    />
                                </div>
                            </Box>
                        </Collapse>

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='post-Modal-menu'>
                        <Grid container alignItems='center'>
                            <Grid item xs={5} sm={6}>
                                <CmpTypographyField xcomponent={'span'} xText={'Receiving'} />
                            </Grid>
                            <Grid item xs={3} sm={2.5}>
                                <StatusIcon xStatus='Post' />
                                <span className='p-2'>{sReceivingList.filter(i => i.Status === 'POST').length}</span>
                            </Grid>
                            <Grid item xs={3} sm={2.5}>
                                <StatusIcon xStatus='Enter' />
                                <span className='p-2'>{sReceivingList.filter(i => i.Status !== 'POST').length}</span>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <div >
                                    <IconButton onClick={() => toggleReceiving()}>
                                        {sReceivingOpen ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </div>
                            </Grid>

                        </Grid>
                        <Collapse in={sReceivingOpen} timeout="auto" unmountOnExit>
                            <div className='post-Modal-collapse'>
                                <PostModalGrid
                                    xRows={sReceivingList}
                                    xColumns={receivingColumns}
                                />
                            </div>
                        </Collapse>

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='post-Modal-menu'>
                        <Grid container alignItems='center'>
                            <Grid item xs={5} sm={6}>
                                <CmpTypographyField xcomponent={'span'} xText={'Mismatch Items'} />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                                {/* <StatusIcon xStatus='Post' /> */}
                                <CancelRounded style={{ color: 'red' }} fontSize="small" />
                                <span className='p-2'>{mismatchRows.length}</span>
                            </Grid>

                            <Grid item xs={1} sm={1}>
                                <div >
                                    <IconButton onClick={() => toggleMismatch()}>
                                        {sMismatchOpen ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </div>
                            </Grid>

                        </Grid>
                        <Collapse in={sMismatchOpen} timeout="auto" unmountOnExit>
                            <div className='post-Modal-collapse'>
                                <PostModalGrid
                                    xRows={mismatchRows}
                                    xColumns={mismatchColumns}
                                />
                            </div>
                        </Collapse>

                    </div>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12 }}
                className='mt-1'
            >
                <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} className=" d-flex justify-content-end">
                    <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={() => onClickPost()}
                        xLabel={'post'}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}
