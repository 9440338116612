import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Checkbox } from '@mui/material';

import { CmpTypographyField } from '../../../component/ToolBox/ToolBox';

export default function BatchCreationCard({
  xTransId,
  xLoc,
  xPostDt,
  xSelected,
  xDCReturn,
  xDepartmentCode,
  xOnChangeSelect
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{ alignItems: 'center' }}>
        <Grid item xs={11} sm={11} md={11}>
          <Grid
            container
            spacing={0.1}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'left' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Trans Id'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xTransId} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xLoc} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xPostDt} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Type'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField
                    xcomponent={'span'}
                    xText={xDCReturn == 'Y' ? 'DC' : 'Market'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Department'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
                  <CmpTypographyField xcomponent={'span'} xText={xDepartmentCode} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Checkbox checked={xSelected} onChange={xOnChangeSelect} />
        </Grid>
      </Grid>
    </Box>
  );
}
