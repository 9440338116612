import React, { useRef, useState } from 'react'
import { Input, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { searchVendor } from './GCAction';


export default function GC_VendorSearch({
    xValue,
    xOnChange,
    xReadOnly = false,
    xType,
    xName,
    xTextAlign = 'left',
    xTabIndex = '',
    xref = '',
    xSearchText,
    xSetSearchText,
    xOnBackSpaceKeyPress,
    xDisable
}) {
    const [sShowResult, setShowResult] = useState(false);
    const [sSearchResult, setSearchResult] = useState([]);
    const onChangeInput = async (e) => {
        xSetSearchText(e.target.value);

        if (e.target.value.length < 3) {
            setShowResult(false);
        }
        else {
            await fLoadVendors(e.target.value);
            setShowResult(true);
        }
    };

    const fLoadVendors = async (searchText) => {
        const obj = {
            searchText: searchText
        }

        const result = await searchVendor(obj);
        setSearchResult(result.data.ResultSet);
    }

    const onKeyUpInput = async (e) => {
        // if ((e.key == 'Enter' || e._reactName === 'onClick') && xSearchText.length > 2) {
        //     await fLoadVendors();
        //     setShowResult(true);
        // }
        if (e.key == 'Backspace') {
            xOnBackSpaceKeyPress();
        }
    }

    const onClickItem = (e) => {
        console.log(e);

    }
    const inputReference = useRef(null);
    const columns = [
        {
            field: 'AcCd',
            headerName: 'Party Code',
            flex: 1
        },
        {
            field: 'Name2',
            headerName: 'Party Name',
            flex: 3
        },
        {
            field: 'Mobile',
            headerName: 'Mobile',
            flex: 2
        },
    ]

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '30%' }}>
                    <TextField
                        className={'mui-text-field'}
                        value={xValue}
                        name={xName}
                        readOnly={xReadOnly}
                        fullWidth
                        inputProps={{ min: 0, style: { textAlign: xTextAlign } }}
                        disabled={true}
                    />
                </div>
                <div style={{ width: '70%' }}>
                    <TextField
                        inputRef={xref ? xref : inputReference}
                        className={'mui-text-field'}
                        value={xSearchText}
                        type={xType} // text/number/password
                        name={xName}
                        readOnly={xReadOnly}
                        disabled={xDisable}
                        onChange={(e) => {
                            onChangeInput(e);
                        }}
                        onKeyUp={(e) => {
                            onKeyUpInput(e)
                        }

                        }
                        fullWidth
                        inputProps={{ min: 0, style: { textAlign: xTextAlign }, tabIndex: xTabIndex }}
                    />
                </div>
            </div>
            {sShowResult && <div style={{ width: '100%', height: '40vh', position: 'absolute', zIndex: '999', backgroundColor: '#fff' }}>
                <DataGrid
                    rows={sSearchResult}
                    columns={columns}
                    getRowId={(row) => row.AcCd}
                    rowHeight={40}
                    headerHeight={30}
                    hideFooter={true}
                    sx={{
                        border: 2,
                        borderColor: 'primary.light',
                    }}
                    componentsProps={{
                        filterPanel: { sx: { maxWidth: "93vw" } }
                      }}
                    onRowClick={(params) => { xOnChange(params); setShowResult(false); }}
                />


            </div>
            }
        </div>
    )
}
