import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, useCallback } from 'react';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputFieldGroup,
  CmpInputField,
  CmpButton,
  CmpStatusDiv,
  CmpTotalMenu,
  CmpCheckboxField
} from '../../component/ToolBox/ToolBox';
import './ReturnRequestAction';
import { GridcolumnsDetails, GridDataColumnsItemSearch } from './ReturnRequestAction';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
//Local
import { fAlertToast } from '../../Utility/Utilitys';
import 'react-toastify/dist/ReactToastify.css';
import { fExecuteAPI } from '../../Server/Apis';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import GCItemSearch from '../../component/ReuseableComponent/GCItemSearch';
import './returnRequest.css';
import {
  ReturnRequest_RowDetail_DOC_Status,
  ReturnRequest_RowDetail_TransID,
  ReturnRequest_RowDetail_TruckID,
  ReturnRequest_RowDetail
} from './returnRequestSlice';
import ReturnRequest_Breadcrumbs from './ReturnRequest_BreadCrumbs';
import { ConstructionOutlined } from '@mui/icons-material';
import { getDepartmentList, getKernValue } from './action';
import GCItemSearchReturnReq from '../../component/ReuseableComponent/GCItemSearchReturnReq';

// const unitData = ["PCS", "KG"]

const ReturnRequestDetail = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.SliceDB.value);
  const getLocations = useSelector((state) => state.SliceDB.value.getLocation);
  const getDCLocations = useSelector((state) => state.SliceDB.value.getDCLocation);
  const xRDRowDetail = useSelector((state) => state.ReturnRequestSlice.value.detail);
  const vRdxkern = useSelector((state) => state.SliceDB.value.Kern);
  const reportBaseUrl = useSelector((state) => state.SliceDB.value.reportBaseUrl);
  // Truck List
  const getTruckLists = useSelector((state) => state.SliceDB.value.getTruckList);

  const filterTruck = getTruckLists.filter((data) => data.Loc === xRDRowDetail.A_Loc);

  // const filterTruck = getTruckLists.filter((data) => {
  //   if (data.Loc === xRDRowDetail.A_Loc) {
  //     return data;
  //   }
  // });

  const updateTruckArr = filterTruck.map(({ Loc, LocName, ...rest }) => ({ ...rest }));

  const [sTruckList, setTruckList] = useState(updateTruckArr);
  const [sDeptList, setDeptList] = useState();

  const vMediaQuery = useMediaQuery('(max-width:800px)');
  const [sEnterKeyObj, setEnterKeyObj] = useState({
    key: 'Enter'
  });
  const [unitData, setUnitData] = useState([]);
  const [sReasonData, setsReasonData] = useState([]);
  const [sGridrows, setGridrows] = useState([]);
  const [sDetailObj, setDetailObj] = useState({
    requestNo: xRDRowDetail.A_TransId,
    location: xRDRowDetail.A_Loc,
    dateTo: moment(xRDRowDetail.A_PostDt).format('MM/DD/YYYY'),
    DC: xRDRowDetail.A_DCLoc,
    Truck: filterTruck.length > 0 ? filterTruck[0].Truck : '',
    DCReturn: xRDRowDetail.v_isDcReturn,
    Dept: xRDRowDetail.Dept
  });

  const [sBufferObj, setBufferObj] = useState({
    B_Srno: '0',
    B_Itmcd: '',
    B_ItmName: '',
    B_Qty: 0,
    B_Unit: '',
    B_BaseQty: '',
    B_UnitCost: '',
    B_NetAmt: '',
    B_Reason: '',
    B_Reason_Id: '',
    B_PackQty: '',
    totalNetAmount: ''
  });

  let vErrorMsg = {
    location: '',
    dateTo: '',
    DC: '',
    Truck: '',
    B_Srno: '',
    B_Itmcd: '',
    B_ItmName: '',
    B_Qty: '',
    B_Unit: '',
    B_BaseQty: '',
    B_UnitCost: '',
    B_NetAmt: '',
    B_Reason: '',
    DCReturn: ''
  };

  const [sErrorMsg, setErrorMsg] = useState({
    location: '',
    dateTo: '',
    DC: '',
    Truck: '',
    B_Srno: '',
    B_Itmcd: '',
    B_ItmName: '',
    B_Qty: '',
    B_Unit: '',
    B_BaseQty: '',
    B_UnitCost: '',
    B_NetAmt: '',
    B_Reason: '',
    DCReturn: ''
  });

  const scanFocusOnLoad = useRef(null);

  useEffect(() => {
    if (sDetailObj.requestNo !== 0) {
      onLoadPage(sDetailObj.requestNo);
    }
    scanFocusOnLoad.current.focus();
    fgetReasonArray();
  }, []);

  const onload = async () => {
    const result = await getDepartmentList({});
    setDeptList(result?.ResultSet);
  };

  useEffect(() => {
    onload();
  }, []);

  const fgetReasonArray = async () => {
    const reasonArr = await fgetReason();
    if (reasonArr.length > 0) {
      setsReasonData(reasonArr);
      setBufferObj((obj) => ({
        ...obj,
        B_Reason: reasonArr[0].Name1,
        B_Reason_Id: reasonArr[0].Uid
      }));
    }
  };

  const fOnRowClick = async (item) => {
    setBufferObj((obj) => ({
      ...obj,
      B_Srno: item.row.B_SrNo,
      B_Itmcd: item.row.B_ItmCd,
      B_ItmName: item.row.B_ItemName,
      B_Qty: item.row.B_Qty,
      B_Unit: item.row.B_Unit,
      B_BaseQty: item.row.B_BaseQty,
      B_UnitCost: item.row.B_UnitRate,
      B_NetAmt: item.row.B_NetAmt,
      B_Reason: item.row.B_Reason,
      B_Reason_Id: item.row.B_ReasonName
    }));

    const unitResult = await fgetItemUnit(item.row.B_ItmCd);
    if (unitResult.length > 0) {
      setUnitData(unitResult);
      const getUnitValue = unitResult.find((data) => {
        if (item.row.B_Unit == data.Unit) {
          return data;
        }
      });
      setBufferObj((obj) => ({
        ...obj,
        B_Unit: getUnitValue.Unit,
        B_PackQty: getUnitValue.PackQty
      }));
    }
    const reasonResult = await fgetReason();
    if (reasonResult.length > 0) {
      setsReasonData(reasonResult);
      const getReasonObj = reasonResult.find((data) => {
        if (item.row.B_Reason == data.Uid) {
          return data;
        }
      });
      setBufferObj((obj) => ({
        ...obj,
        B_Reason: getReasonObj.Name1,
        B_Reason_Id: getReasonObj.Uid
      }));
    }
  };

  const onLoadPage = async (transId) => {
    const result = await Return_Request_Detail(transId);
    if (result.length > 0) {
      let GridID = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
      setGridrows(GridID);
    } else {
      setGridrows([]);
    }
  };

  const Return_Request_Detail = async (transId) => {
    let detailObj = {
      v_B_TransId: transId
    };

    const vDetail = await fExecuteAPI('GET_RETURN_REQUEST_ITEM_LIST', detailObj);
    if (vDetail?.status === 200) {
      return vDetail.data.ResultSet;
    } else if (
      vDetail.response.status === 500 ||
      vDetail.response.status === 400 ||
      vDetail.response.status === 404 ||
      vDetail.response.status === 403
    ) {
      fAlertToast('FAILED', vDetail.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading login');
      return {};
    }
  };

  const Validation = () => {
    let iRes = true;

    if (sDetailObj.location == '') {
      setErrorMsg((obj) => ({ ...obj, location: 'location is Empty' }));
      iRes = false;
    }
    if (sDetailObj.dateTo == '') {
      setErrorMsg((obj) => ({ ...obj, dateTo: 'date is Empty' }));
      iRes = false;
    }
    if (sDetailObj.DC == '') {
      setErrorMsg((obj) => ({ ...obj, DC: 'DC is Empty' }));
      iRes = false;
    }
    if (sDetailObj.Truck == '') {
      setErrorMsg((obj) => ({ ...obj, Truck: 'Truck is not selected' }));
      iRes = false;
    }
    if (sBufferObj.B_BaseQty == '') {
      setErrorMsg((obj) => ({ ...obj, B_BaseQty: 'BaseQty is Empty' }));
      iRes = false;
    }
    if (sBufferObj.B_ItmName == '') {
      setErrorMsg((obj) => ({ ...obj, B_ItmName: 'Item Name is Empty' }));
      iRes = false;
    }
    if (sBufferObj.B_Itmcd == '') {
      setErrorMsg((obj) => ({ ...obj, B_Itmcd: 'Item Code is Empty' }));
      iRes = false;
    }
    // if (sBufferObj.B_NetAmt == '') {
    //   setErrorMsg((obj) => ({ ...obj, B_NetAmt: 'Net Amount is Empty' }));
    //   iRes = false;
    // }
    if (sBufferObj.B_Qty == '') {
      setErrorMsg((obj) => ({ ...obj, B_Qty: 'Qty is Empty' }));
      iRes = false;
    }
    if (sBufferObj.B_Srno == '') {
      setErrorMsg((obj) => ({ ...obj, B_Srno: 'Sr No is Empty' }));
      iRes = false;
    }
    if (sBufferObj.B_Reason == '') {
      setErrorMsg((obj) => ({ ...obj, B_Reason: 'Reason is Empty' }));
      iRes = false;
    }
    if (sBufferObj.B_Unit == '') {
      setErrorMsg((obj) => ({ ...obj, B_Unit: 'Unit is Empty' }));
      iRes = false;
    }
    // if (sBufferObj.B_UnitCost == '') {
    //   setErrorMsg((obj) => ({ ...obj, B_UnitCost: 'Unit Cosdt is Empty' }));
    //   iRes = false;
    // }
    if (sDetailObj.DCReturn == '') {
      setErrorMsg((obj) => ({ ...obj, DCReturn: 'DCReturn is Empty' }));
      iRes = false;
    }

    return iRes;
  };

  const fReverseRequest = async () => {
    let reverseObj = {
      v_A_TransId: sDetailObj.requestNo
    };
    const vReverse = await fExecuteAPI('GET_REVERSE_RETURN_REQUEST', reverseObj);
    if (vReverse?.status === 200) {
      return vReverse.data;
    } else if (
      vReverse.response.status === 500 ||
      vReverse.response.status === 400 ||
      vReverse.response.status === 404 ||
      vReverse.response.status === 403
    ) {
      fAlertToast(
        'FAILED',
        vReverse.response.data.message ? vReverse.response.data.message : ' something went wrong '
      );
      return {};
    }
  };

  const onPressSave = async () => {
    if (xRDRowDetail.A_DocStatus === 'Post') {
      confirmAlert({
        title: 'Reverse',
        message: 'Are you sure to Reverse.',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const vReverse = await fReverseRequest();
              if (vReverse.Type === 'Success') {
                dispatch(ReturnRequest_RowDetail_DOC_Status({ A_DocStatus: 'Enter' }));
                fAlertToast('SUCCESS', 'Reversed Successfully');
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else {
      setErrorMsg(vErrorMsg);
      if (Validation()) {
        confirmAlert({
          title: 'Save',
          message: 'Are you sure to Save.',
          buttons: [
            {
              className: 'alertBtn',
              label: 'Yes',
              onClick: async () => {
                const result = await UpdateReturnRequest();
                if (result.Type === 'Success') {
                  dispatch(
                    ReturnRequest_RowDetail_TransID({ A_TransId: result.ResultSet[0].A_TransId })
                  );

                  dispatch(
                    ReturnRequest_RowDetail({
                      ...xRDRowDetail,
                      A_TransId: result.ResultSet[0].A_TransId,
                      A_PostDt: sDetailObj.dateTo,
                      A_Loc: sDetailObj.location,
                      LocName: '',
                      A_DCLoc: sDetailObj.DC,
                      DCLocName: '',
                      A_DocStatus: 'Enter',
                      A_Type: '',
                      A_DCStatus: '',
                      Truck: sDetailObj.Truck,
                      v_isDcReturn: sDetailObj.DCReturn
                    })
                  );
                  // dispatch(ReturnRequest_RowDetail_TruckID({ Truck: sDetailObj.Truck }));
                  setDetailObj((obj) => ({ ...obj, requestNo: result.ResultSet[0].A_TransId }));
                  const itemResult = await UpdateReturnRequestItem(result.ResultSet[0].A_TransId);
                  console.log(itemResult);
                }

                setBufferObj((obj) => ({
                  ...obj,
                  B_Srno: '0',
                  B_Itmcd: '',
                  B_ItmName: '',
                  B_Qty: '',
                  B_Unit: '',
                  B_BaseQty: '',
                  B_UnitCost: '',
                  B_NetAmt: '',
                  B_Reason: '',
                  B_Reason_Id: '',
                  B_PackQty: ''
                }));
                onLoadPage(result.ResultSet[0].A_TransId);
              }
            },
            {
              className: 'alertBtn',
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  const onPressDelete = async () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to Delete.',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            if (sBufferObj.B_Srno == 0) {
              const result = await deleteRequest();
              if (result.Type === 'Success') {
                fAlertToast('SUCCESS', 'Request has been deleted');
                dispatch(ReturnRequest_RowDetail_DOC_Status({ A_DocStatus: 'Delete' }));
              }
            } else {
              const result = await deleteItem();
              if (result?.status == 200) {
                fAlertToast('SUCCESS', 'Item has been deleted');
                onLoadPage(sDetailObj.requestNo);
                setBufferObj((obj) => ({
                  ...obj,
                  B_Srno: '0',
                  B_Itmcd: '',
                  B_ItmName: '',
                  B_Qty: '',
                  B_Unit: '',
                  B_BaseQty: '',
                  B_UnitCost: '',
                  B_NetAmt: '',
                  B_Reason: '',
                  B_Reason_Id: '',
                  B_PackQty: ''
                }));
              }
            }
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const deleteRequest = async () => {
    let deleteObj = {
      v_A_TransId: sDetailObj.requestNo
    };

    const vDelete = await fExecuteAPI('GET_RETURN_REQUEST_DELETE', deleteObj);
    if (vDelete?.status == 200) {
      return vDelete.data;
    } else if (
      vDelete.response.status == 500 ||
      vDelete.response.status == 400 ||
      vDelete.response.status == 404 ||
      vDelete.response.status == 403
    ) {
      fAlertToast('FAILED', vDelete.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'something went wrong');
      return {};
    }
  };

  const deleteItem = async () => {
    let deleteItemObj = {
      v_B_TransId: sDetailObj.requestNo,
      v_B_SrNo: sBufferObj.B_Srno
    };

    const vDelete = await fExecuteAPI('GET_RETURN_REQUEST_DELETE_ITEM', deleteItemObj);
    if (vDelete?.status == 200) {
      return vDelete;
    } else if (
      vDelete.response.status == 500 ||
      vDelete.response.status == 400 ||
      vDelete.response.status == 404 ||
      vDelete.response.status == 403
    ) {
      fAlertToast('FAILED', vDelete.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading login');
      return {};
    }
  };

  const fgetReason = async () => {
    let reasonObj = {};

    const vReason = await fExecuteAPI('GET_RETURN_REQUEST_REASON', reasonObj);
    if (vReason?.status == 200) {
      return vReason.data.ResultSet;
    } else if (
      vReason.response.status == 500 ||
      vReason.response.status == 400 ||
      vReason.response.status == 404 ||
      vReason.response.status == 403
    ) {
      fAlertToast('FAILED', vReason.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading login');
      return {};
    }
  };

  const onPressPost = async () => {
    if (sGridrows.length > 0) {
      confirmAlert({
        title: 'Post',
        message: 'Are you sure to Post.',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const headerSaveResponse = await UpdateReturnRequest();
              if (headerSaveResponse.Type === 'Success') {
                const result = await postItem();
                if (result.Type === 'Success') {
                  fAlertToast('SUCCESS', 'Posted Successfully');
                  dispatch(ReturnRequest_RowDetail_DOC_Status({ A_DocStatus: 'Post' }));
                }
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else {
      fAlertToast('FAILED', 'Empty Detail Item cannot be post');
    }
  };

  const postItem = async () => {
    let postObj = {
      v_A_TransId: sDetailObj.requestNo,
      v_User: userInfo.login.userId
    };

    const vPost = await fExecuteAPI('GET_RETURN_REQUEST_POST_ITEM', postObj);

    if (vPost?.status == 200) {
      return vPost.data;
    } else if (
      vPost.response.status == 500 ||
      vPost.response.status == 400 ||
      vPost.response.status == 404 ||
      vPost.response.status == 403
    ) {
      fAlertToast('FAILED', vPost.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const UpdateReturnRequest = async () => {
    // let detailObj = {
    //   v_A_TransId: sDetailObj.requestNo,
    //   v_A_PostDt: moment(xRDRowDetail.A_PostDt).format('MM/DD/YYYY'),
    //   v_A_Loc: xRDRowDetail.A_Loc,
    //   v_A_DCLoc: xRDRowDetail.A_DCLoc,
    //   v_A_DocStatus: xRDRowDetail.A_DocStatus,
    //   v_User: userInfo.login.userId,
    //   v_A_Truck: sDetailObj.Truck
    // };

    let detailObj = {
      v_A_TransId: sDetailObj.requestNo,
      v_A_PostDt: moment(sDetailObj.dateTo).format('MM/DD/YYYY'),
      v_A_Loc: sDetailObj.location,
      v_A_DCLoc: sDetailObj.DC,
      v_A_DocStatus: xRDRowDetail.A_DocStatus,
      v_User: userInfo.login.userId,
      v_A_Truck: sDetailObj.Truck,
      v_isDcReturn: sDetailObj.DCReturn,
      v_Dept: sDetailObj.Dept
    };

    const vDetail = await fExecuteAPI('GET_RETURN_REQUEST_SAVE', detailObj);
    if (vDetail?.status === 200) {
      return vDetail.data;
    } else if (
      vDetail.response.status === 500 ||
      vDetail.response.status === 400 ||
      vDetail.response.status === 404 ||
      vDetail.response.status === 403
    ) {
      fAlertToast('FAILED', vDetail.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const UpdateReturnRequestItem = async (transId) => {
    let itemObj = {
      v_B_TrnsId: transId,
      v_B_SrNo: sBufferObj.B_Srno,
      v_B_ItmCd: sBufferObj.B_Itmcd.trim(),
      v_B_Qty: Number(sBufferObj.B_Qty).toFixed(2),
      v_B_Unit: sBufferObj.B_Unit,
      // v_B_BaseQty: sBufferObj.B_BaseQty,
      // v_B_UnitRate: sBufferObj.B_UnitCost,
      // v_B_NetAmt: sBufferObj.B_NetAmt,
      v_B_Reason: sBufferObj.B_Reason_Id,
      v_isDcReturn: sDetailObj.DCReturn,
      v_A_Loc: sDetailObj.location
    };
    // console.log('************itemObj************* ', itemObj);
    const vDetail = await fExecuteAPI('GET_RETURN_REQUEST_SAVE_ITEM', itemObj);
    if (vDetail?.status === 200) {
      return vDetail.data.ResultSet;
    } else if (
      vDetail.response.status === 500 ||
      vDetail.response.status === 400 ||
      vDetail.response.status === 404 ||
      vDetail.response.status === 403
    ) {
      fAlertToast('FAILED', vDetail.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const onPressNew = () => {
    setBufferObj((obj) => ({
      ...obj,
      B_Srno: '0',
      B_Itmcd: '',
      B_ItmName: '',
      B_Qty: '',
      B_Unit: '',
      B_BaseQty: '',
      B_UnitCost: '',
      B_NetAmt: '',
      B_Reason: '',
      B_Reason_Id: '',
      B_PackQty: ''
    }));
    document.getElementsByName('B_ItmName')[0].focus();
  };

  const fB_Srno = (event) => {
    setBufferObj((obj) => ({ ...obj, B_Srno: event.target.value }));
  };

  const fB_Itmcd = (event) => {
    setBufferObj((obj) => ({ ...obj, B_Itmcd: event.target.value }));
  };

  const fB_ItmName = (event) => {
    setBufferObj((obj) => ({ ...obj, B_ItmName: event.target.value }));
  };

  const fB_Qty = (event) => {
    setBufferObj((obj) => ({ ...obj, B_Qty: Number(event.target.value) }));
  };

  const fB_Unit = (event) => {
    const getUnit = unitData.find((data) => {
      if (event.target.value == data.Unit) {
        return data;
      }
    });
    setBufferObj((obj) => ({ ...obj, B_Unit: getUnit.Unit, B_PackQty: getUnit.PackQty }));
  };

  const onChangeDateTo = (newValue) => {
    setDetailObj((obj) => ({ ...obj, dateTo: newValue.$d }));
  };

  useEffect(() => {
    setBufferObj((obj) => ({
      ...obj,
      B_BaseQty: sBufferObj.B_PackQty * sBufferObj.B_Qty
    }));
  }, [sBufferObj.B_PackQty, sBufferObj.B_Qty]);

  useEffect(() => {
    setBufferObj((obj) => ({
      ...obj,
      B_NetAmt: sBufferObj.B_UnitCost * sBufferObj.B_Qty
    }));
  }, [sBufferObj.B_UnitCost, sBufferObj.B_Qty]);

  useEffect(() => {
    const netAmntArr = sGridrows.map((e) => e.B_NetAmt);
    const totalNetAmount = netAmntArr.reduce((prev, curr) => prev + curr, 0);
    setBufferObj((obj) => ({
      ...obj,
      totalNetAmount: totalNetAmount
    }));
  }, [sBufferObj.totalNetAmount, sGridrows]);

  const fB_BaseQty = (event) => {
    setBufferObj((obj) => ({ ...obj, B_BaseQty: event.target.value }));
  };

  const fB_UnitCost = (event) => {
    setBufferObj((obj) => ({ ...obj, B_UnitCost: event.target.value }));
  };

  const fB_NetAmt = (event) => {
    setBufferObj((obj) => ({ ...obj, B_NetAmt: event.target.value }));
  };

  const fB_Reason = (event) => {
    const getReason = sReasonData.find((data) => {
      if (event.target.value == data.Uid) {
        return data;
      }
    });
    setBufferObj((obj) => ({ ...obj, B_Reason: getReason.Name1, B_Reason_Id: getReason.Uid }));
  };

  //// GCMODAL
  const [sGridSelectRowData, setGridSelectRowData] = useState([]);
  const [sLoadingItemSearch, setLoadingItemSearch] = useState(false);
  const [sItemSearchModalObj, setItemSearchModalObj] = useState({
    vAnydata: '',
    vItemNameCode: '',
    vItemNameName: '',
    vItemNameBarcode: '',
    vItemNameGroupCode: 'ALL',
    vItemNameRefNo: '',
    vItemNameNameFL: '',
    vItemNameCostPrice: '',
    vItemNameRSP: ''
  });
  const [sItemSearchModal, setItemSearchModal] = useState(false);
  const fCloseItemSearchModal = () => {
    setItemSearchModal(false);
    fClearItemSearch();
  };
  const fOpenInvoiceSalesModal = async (e) => {
    if (e.key == 'Enter' || e._reactName === 'onClick') {
      const result = await fSearchExactItem();
      if (result.length > 0) {
        setBufferObj((obj) => ({ ...obj, B_ItmName: result[0].Name1, B_Itmcd: result[0].ItmCd }));

        // Getting Unit Array
        const unitResult = await fgetItemUnit(result[0].ItmCd);
        if (unitResult.length > 0) {
          setUnitData(unitResult);
          setBufferObj((obj) => ({
            ...obj,
            B_Unit: unitResult[0].Unit,
            B_PackQty: unitResult[0].PackQty
          }));
        }

        // Getting reason Array
        const reasonArr = await fgetReason();
        if (reasonArr.length > 0) {
          setsReasonData(reasonArr);
          setBufferObj((obj) => ({
            ...obj,
            B_Reason: reasonArr[0].Name1,
            B_Reason_Id: reasonArr[0].Uid
          }));
        }
      } else {
        setItemSearchModal(true);
        fClearItemSearch();
        setItemSearchModalObj((obj) => ({
          ...obj,
          vAnydata: sBufferObj.B_ItmName,
          vItemNameGroupCode: sDetailObj.Dept
        }));
      }
    }
    if (e.key == 'Backspace') {
      setBufferObj((obj) => ({
        ...obj,
        B_Itmcd: ''
      }));
    }
  };

  const fSearchExactItem = async () => {
    let searchObj = {
      v_AnyData: sBufferObj.B_ItmName,
      v_TransType: 'F',
      v_Report: 'Y',
      v_Loc: sDetailObj.location
    };
    const vSearch = await fExecuteAPI('GET_RETURN_REQUEST_EXACT_SEARCH_ITEM', searchObj);
    if (vSearch?.status == 200) {
      return vSearch.data.ResultSet;
    } else if (
      vSearch.response.status == 500 ||
      vSearch.response.status == 400 ||
      vSearch.response.status == 404 ||
      vSearch.response.status == 403
    ) {
      fAlertToast('FAILED', vSearch.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const fSearchItem = async () => {
    let searchObj = {
      v_AnyData: sItemSearchModalObj.vAnydata,
      v_Cd: sItemSearchModalObj.vItemNameCode,
      v_Name1: sItemSearchModalObj.vItemNameName,
      v_Barcode: sItemSearchModalObj.vItemNameBarcode,
      v_Model: sItemSearchModalObj.vItemNameGroupCode, //ss
      v_RefNo1: sItemSearchModalObj.vItemNameRefNo,
      v_NameFL: sItemSearchModalObj.vItemNameNameFL,
      v_Loc: sDetailObj.location
    };
    const vSearch = await fExecuteAPI('GET_RETURN_REQUEST_SEARCH_ITEM', searchObj);
    if (vSearch?.status == 200) {
      return vSearch.data.ResultSet;
    } else if (
      vSearch.response.status == 500 ||
      vSearch.response.status == 400 ||
      vSearch.response.status == 404 ||
      vSearch.response.status == 403
    ) {
      fAlertToast('FAILED', vSearch.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const fonChangeInputModal = (event) => {
    setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
  };
  const fonChangeInputModalSelect = (event) => {
    setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
    // GetItemSearchValue("", "GROUP", event.target.value);
  };
  const fClearItemSearch = useCallback(
    (event) => {
      setItemSearchModalObj({
        vAnydata: '',
        vItemNameCode: '',
        vItemNameName: '',
        vItemNameBarcode: '',
        vItemNameGroupCode: 'ALL',
        vItemNameRefNo: '',
        vItemNameNameFL: '',
        vItemNameCostPrice: '',
        vItemNameRSP: ''
      });
      setGridSelectRowData([]);
      setLoadingItemSearch(false);
    },
    [sItemSearchModalObj]
  );

  //GCItemSearch
  const fOnCellKeyDownGCItemSearchn = (newSelectionModel, e) => {};
  //GCItemSearch
  const fOnRowClickGCItemSearch = async (item) => {
    setBufferObj((obj) => ({ ...obj, B_ItmName: item.row.Name1, B_Itmcd: item.row.ItmCd }));
    const unitResult = await fgetItemUnit(item.row.ItmCd);

    // Get Unit Array
    if (unitResult.length > 0) {
      setUnitData(unitResult);
      setBufferObj((obj) => ({
        ...obj,
        B_Unit: unitResult[0].Unit,
        B_PackQty: unitResult[0].PackQty
      }));
    }

    // Get Reason Array
    const reasonArr = await fgetReason();
    if (reasonArr.length > 0) {
      setsReasonData(reasonArr);
      setBufferObj((obj) => ({
        ...obj,
        B_Reason: reasonArr[0].Name1,
        B_Reason_Id: reasonArr[0].Uid
      }));
    }

    setItemSearchModal(false);
  };

  //API
  const GetItemSearchValue = async (e, xOnchangeAPI, xValue) => {
    if (e.key == 'ArrowDown' || e.keyCode == 40) {
      setTimeout(() => {
        var selectedLength = document.getElementsByClassName('Mui-selected').length;
        if (selectedLength === 0) {
          let tabel = document.getElementById('table-main-grid-3');
          // vIsDownKeyPressed = true;
          // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.click();
          // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.dblclick();
          tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.focus();
        }
      }, 500);
    } else if (e.key == 'Enter' || e._reactName === 'onClick') {
      const result = await fSearchItem();
      if (result.length > 0) {
        let GridSearchItem = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
        setGridSelectRowData(GridSearchItem);
      }
    }
  };

  const fgetItemUnit = async (xItmCd) => {
    let unitObj = {
      v_ItemCd: xItmCd
    };
    const vUnit = await fExecuteAPI('GET_RETURN_REQUEST_ITEM_UNIT', unitObj);
    if (vUnit?.status == 200) {
      return vUnit.data.ResultSet;
    } else if (
      vUnit.response.status == 500 ||
      vUnit.response.status == 400 ||
      vUnit.response.status == 404 ||
      vUnit.response.status == 403
    ) {
      fAlertToast('FAILED', vUnit.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const fCheckBoxChange = () => {
    let newValue = sDetailObj.DCReturn === 'N' ? 'Y' : 'N';

    setDetailObj((obj) => ({
      ...obj,
      DCReturn: newValue
    }));
  };

  const fOnChangeTruck = (event) => {
    console.log('change truck presed', event.target.value);
    setDetailObj((obj) => ({ ...obj, Truck: event.target.value }));
  };

  const fOnChangeLocation = (loc) => {
    setDetailObj((obj) => ({ ...obj, location: loc }));
    let filterTruck = getTruckLists
      .filter((data) => data.Loc === loc)
      .map((i) => ({ Truck: i.Truck, TruckName: i.TruckName }));
    setTruckList(filterTruck);
    setDetailObj((obj) => ({ ...obj, Truck: filterTruck.length > 0 ? filterTruck[0].Truck : '' }));
  };

  const onPressPrint = () => {
    // let baseUrl = '' + process.env.REACT_APP_REPORT_BASE_URL;

    let url =
      reportBaseUrl + 'FreshPurchase/ReturnRequest?trntyp=req&transId=' + sDetailObj.requestNo;
    window.open(url);
  };

  return (
    <div className="container">
      <Box sx={{ flexGrow: 1 }}>
        <div>
          <ToastContainer />
        </div>
        <ReturnRequest_Breadcrumbs />
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ marginBottom: vMediaQuery ? '1rem' : '2rem' }}>
          <Grid item xs={2.4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressNew}
              xLabel={'New'}
              xDisable={
                xRDRowDetail.A_DocStatus === 'Post' || xRDRowDetail.A_DocStatus === 'Delete'
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={2.4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressSave}
              xLabel={'Save'}
              xDisable={xRDRowDetail.A_DocStatus === 'Delete' ? true : false}
            />
          </Grid>
          <Grid item xs={2.4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressDelete}
              xLabel={'Delete'}
              xDisable={
                xRDRowDetail.A_DocStatus === 'Post' ||
                xRDRowDetail.A_DocStatus === 'Delete' ||
                xRDRowDetail.A_TransId == '0'
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={2.4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressPost}
              xLabel={'Post'}
              xDisable={
                xRDRowDetail.A_DocStatus === 'Post' || xRDRowDetail.A_DocStatus === 'Delete'
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={2.4} sm={1.2} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onPressPrint}
              xLabel={'Print'}
            />
          </Grid>
        </Grid>

        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 0 }}
          columnSpacing={{ xs: 2, sm: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ justifyContent: 'between' }}
          className="mb-return-request">
          <Grid item xs={12} sm={12} md={3} className="me-return-request">
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans.Id'} />
              </Grid>
              <Grid item xs={4} sm={4} md={8}>
                <CmpInputField
                  xValue={sDetailObj.requestNo}
                  xType={'text'} // text/number/password
                  xName={'requestNo'}
                  xReadOnly={true}
                  xOnChange={''}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Date'} />
              </Grid>
              <Grid item xs={4} sm={4} md={8}>
                <CmpDatePickerField
                  xTabIndex="2"
                  xValue={sDetailObj.dateTo}
                  xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={sErrorMsg.dateTo === '' ? false : true}
                  xErrorMessage={sErrorMsg.dateTo}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={4} sm={4} md={8}>
                <CmpSelectField
                  xLabelText="Location"
                  xValue={sDetailObj.location}
                  // xOnChange={(event) =>
                  //   dispatch(ReturnRequest_RowDetail({ ...sDetailObj, A_Loc: event.target.value }))
                  // }
                  xOnChange={(event) => fOnChangeLocation(event.target.value)}
                  xName={'Location'}
                  // xData={sArr}
                  xData={getLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={sErrorMsg.location === '' ? false : true}
                  xErrorMessage={sErrorMsg.location}
                  xDisable={sGridrows.length > 0 ? true : false}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'DC'} />
              </Grid>
              <Grid item xs={4} sm={4} md={8}>
                <CmpSelectField
                  xTabIndex="3"
                  xLabelText="DC"
                  xValue={sDetailObj.DC}
                  // xOnChange={(event) =>
                  //   dispatch(
                  //     ReturnRequest_RowDetail({ ...sDetailObj, A_DCLoc: event.target.value })
                  //   )
                  // }
                  xOnChange={(event) => setDetailObj((obj) => ({ ...obj, DC: event.target.value }))}
                  xName={'DC'}
                  // xData={sArr}
                  xData={getDCLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={sErrorMsg.DC === '' ? false : true}
                  xErrorMessage={sErrorMsg.DC}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
              </Grid>
              <Grid item xs={10} sm={4} md={8}>
                <CmpSelectField
                  xTabIndex="1"
                  xLabelText="Truck"
                  xValue={sDetailObj.Truck}
                  xOnChange={(event) => fOnChangeTruck(event)}
                  xName={'Truck'}
                  // xData={sArr}
                  // xData={updateTruckArr}
                  xData={sTruckList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={sErrorMsg.Truck === '' ? false : true}
                  xErrorMessage={sErrorMsg.Truck}
                  xDisable={false}
                  xref={scanFocusOnLoad}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={4} sm={4} md={8}>
                <CmpSelectField
                  xTabIndex="3"
                  xLabelText="Dept"
                  xValue={sDetailObj.Dept}
                  // xOnChange={(event) =>
                  //   dispatch(
                  //     ReturnRequest_RowDetail({ ...sDetailObj, A_DCLoc: event.target.value })
                  //   )
                  // }
                  xOnChange={(event) =>
                    setDetailObj((obj) => ({ ...obj, Dept: event.target.value }))
                  }
                  xName={'Dept'}
                  // xData={sArr}
                  xData={sDeptList}
                  xValueMember={''}
                  xDispalyMember={sGridrows?.length > 0 || vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'}
                  xError={false}
                  xErrorMessage={''}
                  xDisable={false}
                />
              </Grid>
              {/* <Grid item xs={2} sm={12} md={12}>
                <CmpCheckboxField
                  xChecked={sDetailObj.DCReturn === 'Y'}
                  xOnChange={fCheckBoxChange}
                  xDisabled={sGridrows?.length > 0 || vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'}
                  xName={'DC Return'}
                  xNameField={'DC Return'}
                  xOnkeyUp={''}
                  xTabIndex=""
                  xref=""
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2} sm={2} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={10} sm={4} md={8}>
                <CmpSelectField
                  xLabelText="Type"
                  xValue={sDetailObj.DCReturn}
                  xOnChange={(event) => {
                    setDetailObj((obj) => ({ ...obj, DCReturn: event.target.value }));
                  }}
                  xName={'Type'}
                  // xData={sArr}
                  // xData={updateTruckArr}
                  xData={[
                    {
                      UId: 'N',
                      Name1: 'Market'
                    },
                    {
                      UId: 'Y',
                      Name1: 'DC'
                    }
                  ]}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>

              {/* <Grid item xs={2} sm={12} md={12}>
                <CmpCheckboxField
                  xChecked={sDetailObj.DCReturn === 'Y'}
                  xOnChange={fCheckBoxChange}
                  xDisabled={sGridrows?.length > 0 || vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'}
                  xName={'DC Return'}
                  xNameField={'DC Return'}
                  xOnkeyUp={''}
                  xTabIndex=""
                  xref=""
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 1 }}
          columnSpacing={{ xs: 0, sm: 0, md: 0.2 }}
          columns={{ xs: 12, sm: 12, md: 16 }}
          className={vMediaQuery ? 'mb-2' : 'mt-3'}>
          <Grid item xs={1} sm={1} md={1}>
            <div className="d-flex justify-content-between showMoreCheckbox">
              <CmpTypographyField xcomponent={'div'} xText={'Sr.No'} />
            </div>
            <CmpInputField
              xValue={sBufferObj.B_Srno}
              xType={'text'} // text/number/password
              xName={'B_Srno'}
              xReadOnly={true}
              xOnChange={(event) => fB_Srno(event)}
              xError={sErrorMsg.B_Srno === '' ? false : true}
              xErrorMessage={sErrorMsg.B_Srno}
              xOnKeyUp={''}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <CmpTypographyField xcomponent={'div'} xText={'Item Code'} />
            <CmpInputField
              xValue={sBufferObj.B_Itmcd}
              xType={'text'} // text/number/password
              xName={'B_Itmcd'}
              xReadOnly={true}
              xOnChange={(event) => fB_Itmcd(event)}
              xError={sErrorMsg.B_Itmcd === '' ? false : true}
              xErrorMessage={sErrorMsg.B_Itmcd}
              xOnKeyUp={''}
            />
          </Grid>
          <Grid item xs={7} sm={6} md={5}>
            <CmpTypographyField xcomponent={'div'} xText={'Item Name'} />
            <CmpInputFieldGroup
              xTabIndex="1001"
              xValue={sBufferObj?.B_ItmName}
              xType={'text'} // text/number/password
              xName={'B_ItmName'}
              xReadOnly={false}
              xOnChange={(event) => fB_ItmName(event)}
              xOnKeyUp={fOpenInvoiceSalesModal}
              xError={sErrorMsg.B_ItmName === '' ? false : true}
              xErrorMessage={sErrorMsg.B_ItmName}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={1}>
            <CmpTypographyField xcomponent={'div'} xText={'Qty'} />
            <CmpInputField
              xTabIndex="1002"
              xValue={Number(sBufferObj.B_Qty)}
              xType={'number'} // text/number/password
              xName={'B_Qty'}
              xReadOnly={false}
              xOnChange={(event) => fB_Qty(event)}
              xError={sErrorMsg.B_Qty === '' ? false : true}
              xErrorMessage={sErrorMsg.B_Qty}
              xOnKeyUp={''}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <CmpTypographyField xcomponent={'div'} xText={'Unit'} />
            <CmpSelectField
              xTabIndex="1003"
              xLabelText="Unit"
              xValue={sBufferObj.B_Unit}
              xOnChange={(event) => fB_Unit(event)}
              xName={'B_Unit'}
              // xData={sArr}
              xData={unitData}
              xValueMember={''}
              xDispalyMember={''}
              xError={sErrorMsg.B_Unit === '' ? false : true}
              xErrorMessage={sErrorMsg.B_Unit}
              xDisable={false}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <CmpTypographyField xcomponent={'div'} xText={'Base_Qty'} />
            <CmpInputField
              xValue={Number(sBufferObj.B_BaseQty).toFixed(2)}
              xType={'text'} // text/number/password
              xName={'B_BaseQty'}
              xReadOnly={true}
              xOnChange={(event) => fB_BaseQty(event)}
              xError={sErrorMsg.B_BaseQty === '' ? false : true}
              xErrorMessage={sErrorMsg.B_BaseQty}
              xOnKeyUp={''}
            />
          </Grid>
          {/* <Grid item xs={4} sm={4} md={1}>
            <CmpTypographyField xcomponent={'div'} xText={'UnitCost'} />
            <CmpInputField
              xValue={sBufferObj.B_UnitCost}
              xType={'text'} // text/number/password
              xName={'B_UnitCost'}
              xReadOnly={false}
              xOnChange={(event) => fB_UnitCost(event)}
              xError={sErrorMsg.B_UnitCost === '' ? false : true}
              xErrorMessage={sErrorMsg.B_UnitCost}
              xOnKeyUp={''}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={1}>
            <CmpTypographyField xcomponent={'div'} xText={'NetAmt'} />
            <CmpInputField
              xValue={sBufferObj.B_NetAmt}
              xType={'text'} // text/number/password
              xName={'B_NetAmt'}
              xReadOnly={true}
              xOnChange={(event) => fB_NetAmt(event)}
              xError={sErrorMsg.B_NetAmt === '' ? false : true}
              xErrorMessage={sErrorMsg.B_NetAmt}
              xOnKeyUp={''}
            />
          </Grid> */}
          <Grid item xs={4} sm={4} md={2}>
            <CmpTypographyField xcomponent={'div'} xText={'Reason'} />
            <CmpSelectField
              xTabIndex="1004"
              xLabelText="Reason"
              xValue={sBufferObj.B_Reason_Id}
              xOnChange={(event) => fB_Reason(event)}
              xName={'B_Reason'}
              // xData={sArr}
              xData={sReasonData}
              xValueMember={''}
              xDispalyMember={''}
              xError={sErrorMsg.B_Reason === '' ? false : true}
              xErrorMessage={sErrorMsg.B_Reason}
              xDisable={false}
            />
          </Grid>
        </Grid>
        <Box
          sx={{ height: vMediaQuery ? '30VH' : '40vh', width: '100%' }}
          className={vMediaQuery ? '' : 'mt-5'}>
          <DataGrid
            rows={sGridrows}
            columns={GridcolumnsDetails}
            rowHeight={45}
            headerHeight={50}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            hideFooter
            onRowClick={(item) => fOnRowClick(item)}
          />
        </Box>
        {/* <Grid
          container
          spacing={3}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: vMediaQuery ? '' : '10px'
          }}>
          <Grid item xs={6} sm={6} md={1}>
            <CmpTypographyField xcomponent={'span'} xText={'Status'} /> :{' '}
            <CmpTypographyField xcomponent={'span'} xText={xRDRowDetail.A_DocStatus} />
          </Grid>

          <Grid item xs={6} sm={6} md={1}>
            <CmpTypographyField xcomponent={'span'} xText={'Total'} />:
            <CmpTypographyField xcomponent={'span'} xText={sBufferObj.totalNetAmount} />
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Grid item xs={5} sm={5} md={1}>
            <CmpStatusDiv xStatus={xRDRowDetail.A_DocStatus} />
            {/* <button
              className={
                xRDRowDetail.A_DocStatus == 'All'
                  ? 'btn-status btn-primary'
                  : xRDRowDetail.A_DocStatus == 'Pending'
                  ? 'btn-status bg-warning'
                  : xRDRowDetail.A_DocStatus == 'Post'
                  ? 'btn-status bg-success'
                  : 'btn-status bg-secondary'
              }>
              <span> STATUS = </span>
              <span id="WMSDocStatID">{xRDRowDetail.A_DocStatus}</span>
            </button> */}
          </Grid>
          <Grid item xs={5} sm={4} md={0.8} className="text-end">
            {/* <button className="btn-status btn-primary">
              <span> TOTAL = </span>
              <span>
                {sBufferObj.totalNetAmount === '' || sBufferObj.totalNetAmount === '0'
                  ? '0.00'
                  : Number(sBufferObj.totalNetAmount).toFixed(2)}
              </span>
            </button> */}

            <CmpTotalMenu xTotal={sBufferObj.totalNetAmount} />
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={sItemSearchModal}
        onClose={fCloseItemSearchModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="model-sales-invoice-GCVanMaster-Modal">
          <GCItemSearchReturnReq
            fCloseItemSearch={fCloseItemSearchModal}
            title={'Item'}
            xOnCellKeyDown={fOnCellKeyDownGCItemSearchn}
            xOnRowClick={(item) => fOnRowClickGCItemSearch(item)}
            xItemRows={sGridSelectRowData}
            xItemColumn={GridDataColumnsItemSearch}
            xonChangeInputModal={fonChangeInputModal}
            fonChangeInputModalSelect={fonChangeInputModalSelect}
            xItemSearchModalObj={sItemSearchModalObj}
            xfClear={fClearItemSearch}
            xGetItemSearchValue={GetItemSearchValue}
            sLoadingItemSearch={sLoadingItemSearch}
            vMediaQuery={vMediaQuery}
            xEnterObj={sEnterKeyObj}
            vRdxDBLocationGroup={[]}
            sDeptList={sDeptList}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ReturnRequestDetail;
