import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux'
import '../../../component/ReuseableComponent/ReuseableComponent.css';
import Modal from '@mui/material/Modal';
import '../../../component/ToolBox/ToolBox.css';
import {
  CmpSelectArrField,
  CmpTypographyField,
  CmpInputField,
  MUIDataGrid,
  CmpButtonWithIcon
} from '../../../component/ToolBox/ToolBox';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const fDummyFunction = () => {};
const gridBox = {
  border: '1px solid #DADBD6',
  padding: 0.5
};
const borderBox = {
  height: '37vh',
  padding: 0.5
};
const gridItemSpacing = {
  display: 'flex',
  alignItems: 'center',
  padding: 0.2,
  justifyContent: 'flex-start'
};

const ConsolidationPostingLoad = ({
  xOpen = false,
  xTitle = '',
  fCloseModal,
  xPostedRowInfo = [],
  xPurchaseTransRow,
  xPurchaseTransColumn,
  xPurchaseOrderRow,
  xPurchaseOrderColumn,
  xTransferRow,
  xTransferColumn,
  xfetchPost,
        xonChangeType,
        xheadObj
}) => {
  const typeRef = useRef(null);
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);

  // console.log(xheadObj,"xheadObj")
  return (
    <Modal
      open={xOpen}
      onClose={fCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ flexGrow: 1, overflow: 'auto' }} className="gnr-item-search model-gc-search">
        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="GCSearchBtn-Tittle"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={11} sm={11} md={11} className="d-flex justify-content-center  ">
            <div>{xTitle}</div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} className="d-flex justify-content-end">
            <CloseIcon
              className="grnt-item-search-close-icon"
              onClick={!!fCloseModal ? fCloseModal : fDummyFunction}
              style={{ zIndex: 999 }}
            />
          </Grid>
        </Grid>
        <Box sx={gridBox}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5} lg={5} sx={{ p: '5px' }}>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'p'} xText={'Doc Type'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.vocNo}
                        xType={'text'} // text/number/password
                        xName={'Doc Type'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.vocName}
                        xType={'text'} // text/number/password
                        xName={'Doc Type'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'span'} xText={'Doc No'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <CmpInputField
                    xValue={xPostedRowInfo.vocNo}
                    xType={'text'} // text/number/password
                    xName={'vocNo'}
                    xReadOnly={true}
                    xOnChange={''}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField
                    xcomponent={'span'}
                    xText={'Doc Status'}
                    xVariant={'subtitle'}
                  />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <CmpInputField
                    xValue={xPostedRowInfo.docStatus}
                    xType={'text'} // text/number/password
                    xName={'docstat'}
                    xReadOnly={true}
                    xOnChange={''}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField
                    xcomponent={'span'}
                    xText={'Completed'}
                    xVariant={'subtitle'}
                  />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <CmpInputField
                    xValue={xPostedRowInfo.completed}
                    xType={'text'} // text/number/password
                    xName={'completed'}
                    xReadOnly={true}
                    xOnChange={''}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'span'} xText={'Date'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <CmpInputField
                    xValue={moment(xPostedRowInfo.vocDt).format('DD/MM/YYYY')}
                    xType={'text'} // text/number/password
                    xName={'date'}
                    xReadOnly={true}
                    xOnChange={''}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'span'} xText={'Branch'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10}>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.branch}
                        xType={'text'} // text/number/password
                        xName={'branch'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>

                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.branchName}
                        xType={'text'} // text/number/password
                        xName={'branchName'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'p'} xText={'Type'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <CmpSelectArrField
                    xLabelText={'Type'}
                    xValue={xheadObj?.typeName}
                    xOnChange={xonChangeType}
                    xName={'Type'}
                    xData={xheadObj?.type?.map((x)=>x.Name1)}
                    xValueMember={''}
                    xDispalyMember={''}
                    xError={false}
                    xErrorMessage={''}
                    xDisable={false}
                    xref={typeRef}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2.5} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'span'} xText={'Purchase'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9.5} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                  <CmpInputField
                    xValue={xPostedRowInfo.purchase == 'Y' && cPurchaseFlg ? 'Central Purchase' :'Direct Transfer to Branch'}
                    xType={'text'} // text/number/password
                    xName={'Purchase'}
                    xReadOnly={true}
                    xOnChange={''}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
              <div style={{ width: '50%', padding: '5px', margin: '0 auto' }}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'medium'}
                  xonClick={() => {
                    xfetchPost(xPostedRowInfo);
                    // setShowPostingModal(true);
                  }}
                  xLabel={'Post'}
                  xIcon={''}
                  xDisable={false}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} sx={{ p: '5px', height: '15rem' }}>
              <MUIDataGrid
                xRows={xPurchaseOrderRow}
                xColumns={xPurchaseOrderColumn}
                xonRowClick={() => {}}
                enableCheckbox={false}
                borderColorChange={true}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container sx={{ height: vMediaQuery ? '100%' : '' }}>
          <Grid item xs={12} md={7} sm={7} lg={7}>
            <Box sx={borderBox}>
              <MUIDataGrid xRows={xPurchaseTransRow} xColumns={xPurchaseTransColumn} xonRowClick={() => {}} enableCheckbox={false} />
            </Box>
          </Grid>
          <Grid item xs={12} md={5} sm={5} lg={5}>
            <Box sx={borderBox}>
              <MUIDataGrid xRows={ xTransferRow} xColumns={ xTransferColumn } xonRowClick={() => {}} enableCheckbox={false} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ConsolidationPostingLoad;
