import { FormHelperText, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useRef, useState } from 'react'
import useMediaQuery from "@mui/material/useMediaQuery";

export default function GCDropdownSearch({
    xValue,
    xOnChange,
    xType,
    xName,
    xTextAlign = 'left',
    xTabIndex = '',
    xref = '',
    xSearchText,
    xSetSearchText,
    xOnBackSpaceKeyPress,
    xDisable,
    xReadOnly = false,
    xResultColumns,
    xLoadDetail,
    xError,
    xErrorMessage
}) {
    const vMediaQuery = useMediaQuery('(max-width: 900px)');
    const [sShowResult, setShowResult] = useState(false);
    const [sSearchResult, setSearchResult] = useState([]);
    const onChangeInput = async (e) => {
        xSetSearchText(e.target.value);

        if (e.target.value.length < 3) {
            setShowResult(false);
        }
        else {
            const result = await xLoadDetail(e.target.value);
            setSearchResult(result);
            setShowResult(true);
        }
    };


    const onKeyUpInput = async (e) => {
        // if ((e.key == 'Enter' || e._reactName === 'onClick') && xSearchText.length > 2) {
        //     await fLoadVendors();
        //     setShowResult(true);
        // }
        if (e.key == 'Backspace') {
            xOnBackSpaceKeyPress();
        }
    }

    const inputReference = useRef(null);


    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '30%' }}>
            <TextField
              className={'mui-text-field'}
              value={xValue}
              name={xName}
              readOnly={xReadOnly}
              fullWidth
              inputProps={{ min: 0, style: { textAlign: xTextAlign } }}
              disabled={true}
            />
          </div>
          <div style={{ width: '70%' }}>
            <TextField
              inputRef={xref ? xref : inputReference}
              className={'mui-text-field'}
              value={xSearchText}
              type={xType} // text/number/password
              name={xName}
              readOnly={xReadOnly}
              disabled={xDisable}
              onChange={(e) => {
                onChangeInput(e);
              }}
              onKeyUp={(e) => {
                onKeyUpInput(e);
              }}
              fullWidth
              inputProps={{ min: 0, style: { textAlign: xTextAlign }, tabIndex: xTabIndex }}
            />
          </div>
        </div>
        {xError ? (
          <FormHelperText className="error-message text-danger" error={xError}>
            {xErrorMessage}
          </FormHelperText>
        ) : (
          ''
        )}
        {sShowResult && (
          <div
            style={{
              width: vMediaQuery ? '113%':  '100%',
              height: '40vh',
              position: 'absolute',
              zIndex: '999',
              backgroundColor: '#fff',
              margin:0
            }}>
            <DataGrid
              rows={sSearchResult}
              columns={xResultColumns}
              // getRowId={(row) => row.AcCd}
              rowHeight={40}
              headerHeight={30}
              hideFooter={true}
              sx={{
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cellContent': {
                  textTransform: 'lowercase'
              },
              }}
              componentsProps={{
                filterPanel: { sx: { maxWidth: '93vw' } }
              }}
              onRowClick={(params) => {
                xOnChange(params);
                setShowResult(false);
              }}
            />
          </div>
        )}
      </div>
    );
}
