import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Modal, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { CmpButton, CmpTypographyField } from '../../component/ToolBox/ToolBox';
import TransItemCard from '../VanReceiving/ProcessComponents/TransItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { getUnloadBatchDetailSum } from './actions';
import { getUnloadBatchDetailAppr } from './actions';
import { updateBatchItem, updateBatchInfo } from './OutletRequestSlice';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';
import UnloadBreadCrumbs from './ProcessComponents/UnloadBreadCrumbs';

const UnLoadingDetail = () => {
  const vMediaQuery = useMediaQuery('(max-width: 760px)');
  const [sItemList, setItemList] = useState([]);
  const selectedBatchInfo = useSelector((state) => state.OutletRequestSlice.value.batchInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewModal, setViewModal] = useState(false);

  const loadItems = useCallback(async () => {
    const obj = {
      v_Batch_ID: selectedBatchInfo?.BatchNo
    };
    const data = await getUnloadBatchDetailSum(obj);
    const responseObj = data.ResultSet.map((itm) => {
      return { ...itm, BalQty: Number(itm.Qty - itm.ReceivedQty) };
    });
    setItemList(responseObj);
  }, [setItemList, selectedBatchInfo]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const fOnpressTransItem = (itm) => {
    dispatch(
      updateBatchItem({
        srNo: itm.SrNo,
        itmCd: itm.ItemCode,
        itemName: itm.ItemName,
        totalQty: Number(itm.Qty),
        unit: itm.Unit,
        unitName: itm.UnitName,
        receivedQty: Number(itm.ReceivedQty),
        balQty: Number(itm.BalQty),
        PLU: itm.PLU
      })
    );
    navigate('/UnLoadingDetailItem');
  };

  const fOnclickPost = async () => {
    const itemsVariance = sItemList.filter((i) => Number(i.BalQty) !== 0);
    confirmAlert({
      title: 'Post',
      message:
        itemsVariance.length > 0
          ? 'Variance in quantity found! \n Are you sure to post?'
          : 'Are you sure to post?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            let obj = {
              v_C_BatchID: selectedBatchInfo?.BatchNo
            };
            const result = await getUnloadBatchDetailAppr(obj);
            if (result.data.Type === 'Success') {
              fAlertToast('SUCCESS', 'Posted Successfully');
              dispatch(
                updateBatchInfo({
                  ...selectedBatchInfo,
                  Status: 'POST'
                })
              );
              navigate('/UnLoading');
            }
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const fOnClickReverse = async () => {
    // confirmAlert({
    //     title: 'Reverse',
    //     message: 'Are you sure to reverse?',
    //     buttons: [
    //         {
    //             className: 'alertBtn',
    //             label: 'Yes',
    //             onClick: async () => {
    //                 // let obj = {
    //                 //     vanTransId: rdxTransHead.transId
    //                 // };
    //                 // const result = await reverseVanReceivingTrans(obj);
    //                 // if (result.data.Type === 'Success') {
    //                 //     fAlertToast('SUCCESS', 'Reversed Successfully');
    //                 //     dispatch(updateTransHead({
    //                 //         ...rdxTransHead,
    //                 //         status: 'ENTER'
    //                 //     }));
    //                 // }
    //             }
    //         },
    //         {
    //             className: 'alertBtn',
    //             label: 'No',
    //             onClick: () => { }
    //         }
    //     ]
    // });
  };
  return (
    <div className="container">
      <div>
        <UnloadBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={4} md={2}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Batch No'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={6}>
                <CmpTypographyField xcomponent={'span'} xText={selectedBatchInfo.BatchNo} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={6}>
                <CmpTypographyField xcomponent={'span'} xText={selectedBatchInfo.Truck} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} md={6}>
                <CmpTypographyField xcomponent={'span'} xText={'Truck Name'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={selectedBatchInfo.TruckName} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mb-2">
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fOnclickPost}
              xLabel={'Post'}
              xDisable={selectedBatchInfo?.Status.toString().toUpperCase() !== 'ENTER'}
            />
          </Grid>
          {/* <Grid item xs={4} sm={2.4} lg={1.2} md={1.3}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fOnClickReverse}
              xLabel={'Reverse'}
              xDisable={selectedBatchInfo?.Status.toString().toUpperCase() !== 'POST'}
            />
          </Grid> */}
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '60vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent: sItemList.length === 0 ? 'center' : 'start'
          }}>
          {sItemList.length === 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                // height: '80vh'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : (
            sItemList.map((itm) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} key={itm.SrNo}>
                  <TransItemCard
                    xOnClick={() => fOnpressTransItem(itm)}
                    xItemCd={itm.ItemCode}
                    xItem={itm.ItemName}
                    xTotal={itm.Qty}
                    xReceived={itm.ReceivedQty}
                    xBalQty={itm.BalQty}
                    xUnit={itm.Unit}
                    viewModal={viewModal}
                    setViewModal={setViewModal}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default UnLoadingDetail;
