import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function ConsReqBreadCrumbs() {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 mt-0 mb-3 breadcrumb">
            {vPathName === '/ConsolidationRequest' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Consolidation Request Summary</span>
                </div>
            ) : (
                    <></>

                )}
        </div>
    );
}

