import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './PickingSummary.css';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpButton,
  CmpSelectArrField
} from '../../component/ToolBox/ToolBox';
import PickingSumarryCard from './processComponent/PickingSumarryCard';
import { useNavigate } from 'react-router-dom';
import PickingSummaryBreadCrumbs from './PickingSummaryBreadCrumbs';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getPickingSummaryList } from './action';
import { Picking_Detail, Picking_Head } from './PickingSlice';
import { fAlertToast } from '../../Utility/Utilitys';
import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import useMediaQuery from '@mui/material/useMediaQuery';

const statusData = ['All', 'Enter', 'Post'];

function PickingSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vMediaQuery = useMediaQuery('(max-width:600px)');
  const getPickLoc = useSelector((state) => state.SliceDB.value.getPickLocList);
  const userInfo = useSelector((state) => state.SliceDB.value.userInfo);
  const scanFocusOnLoad = useRef(null);

  const [sPickDetailObj, setPickDetailObj] = useState({
    location: getPickLoc[0].LocCode,
    dateFrom: moment().startOf('month'),
    dateTo: new Date(),
    status: statusData[0]
  });
  const [sPickingList, setPickingList] = useState([
    {
      A_TransId: '',
      A_PostDt: new Date(),
      A_Loc: '',
      LocName: '',
      A_Driver: '',
      DriverName: '',
      A_Truck: '',
      TruckName: '',
      A_ReqTransId: 4,
      A_Type: '',
      A_PickStatus: '',
      A_PickDt: '',
      A_DispatchStatus: '',
      A_DispatchDt: '',
      A_RefNo: '',
      A_Narration: '',
      ItemCount: 1
    }
  ]);

  const fOnPageLoad = useCallback(async () => {
    dispatch(
      Picking_Head({
        location: getPickLoc[0].LocCode,
        dateFrom: moment().startOf('month'),
        dateTo: new Date(),
        status: statusData[0]
      })
    );

    fOnpressLoad();
  }, [dispatch]);

  useEffect(() => {
    scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, []);

  const fGetPickSummaryList = async () => {
    const pickSummaryObj = {
      v_A_Loc: sPickDetailObj.location,
      v_Date1: moment(sPickDetailObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(sPickDetailObj.dateTo).format('MM/DD/YYYY'),
      v_Status: sPickDetailObj.status,
      v_TruckID: userInfo.TruckID
    };
    const result = await getPickingSummaryList(pickSummaryObj);
    return result.ResultSet;
  };

  const fOnpressLoad = async () => {
    scanFocusOnLoad.current.focus();
    if (sPickDetailObj.dateFrom < sPickDetailObj.dateTo) {
      const result = await fGetPickSummaryList();
      if (result.length > 0) {
        setPickingList(result);
      } else {
        setPickingList([]);
      }

      dispatch(Picking_Head(sPickDetailObj));
    } else {
      fAlertToast('To Date cannot be less then from date');
      alert('To Date cannot be less then from date');
    }
  };

  const fOnpressItem = (item) => {
    dispatch(
      Picking_Detail({
        A_TransId: item.A_TransId,
        A_PostDt: item.A_PostDt,
        A_Loc: item.A_Loc,
        LocName: item.LocName,
        A_Driver: item.A_Driver,
        DriverName: item.DriverName,
        A_Truck: item.A_Truck,
        TruckName: item.TruckName,
        A_ReqTransId: item.A_ReqTransId,
        A_Type: item.A_Type,
        A_PickStatus: item.A_PickStatus,
        A_PickDt: item.A_PickDt,
        A_DispatchStatus: item.A_DispatchStatus,
        A_DispatchDt: item.A_DispatchDt,
        A_RefNo: item.A_RefNo,
        A_Narration: item.A_Narration,
        A_Vendor: item.A_Vendor,
        Vendorname: item.Vendorname,
        ItemCount: item.ItemCount
      })
    );
    if (item.A_TransId !== '') {
      navigate('/PickingDetail');
    }
  };

  const onChangeLocation = (event) => {
    setPickDetailObj((obj) => ({
      ...obj,
      location: event.target.value
    }));
  };

  const onChangeDateFrom = (newValue) => {
    setPickDetailObj((obj) => ({
      ...obj,
      dateFrom: newValue.$d
    }));
  };

  const onChangeDateTo = (newValue) => {
    setPickDetailObj((obj) => ({
      ...obj,
      dateTo: newValue.$d
    }));
  };

  const onChangeStatus = (event) => {
    setPickDetailObj((obj) => ({
      ...obj,
      status: event.target.value
    }));
  };

  return (
    <div className="container Picking">
      <div 
      // className="my-2"
      >
        <PickingSummaryBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} md={2.5}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={7} sm={7} md={6}>
                <CmpSelectField
                  xTabIndex="1"
                  xLabelText="Location"
                  xValue={sPickDetailObj.location}
                  xOnChange={(event) => onChangeLocation(event)}
                  xName={'Location'}
                  xData={getPickLoc}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xref={scanFocusOnLoad}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={7} sm={7} md={6}>
                <CmpSelectArrField
                  xTabIndex="2"
                  xLabelText="Status"
                  xValue={sPickDetailObj.status}
                  xOnChange={(event) => onChangeStatus(event)}
                  xName={'Status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={7} sm={7} md={6}>
                <CmpDatePickerField
                  xTabIndex="3"
                  xValue={sPickDetailObj.dateFrom}
                  xOnChange={(newValue) => onChangeDateFrom(newValue)}
                  xName={'dateFrom'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={5} md={2.5}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={7} sm={7} md={6}>
                <CmpDatePickerField
                  xTabIndex="4"
                  xValue={sPickDetailObj.dateTo}
                  xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center', justifyContent: 'start' }}
          className="my-2">
          <Grid item xs={12} sm={12} md={11} className="text-end">
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fOnpressLoad}
              xLabel={'Load'}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          {sPickingList.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : (
            sPickingList.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  key={itm.A_TransId}
                  onClick={() => fOnpressItem(itm)}>
                  <PickingSumarryCard
                    xTransId={itm.A_TransId}
                    xTruck={itm.TruckName}
                    xDate={moment(itm.A_PostDt).format('DD/MM/YYYY')}
                    xStatus={itm.A_PickStatus}
                    xLoc={itm.LocName}
                    xNumberOfItems={itm.ItemCount}
                    xVendor={itm.Vendorname}
                    xType={itm.A_Type}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
export default PickingSummary;
