import { Delete, DeleteOutline, DeleteOutlined } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Modal,
  Select,
  TextField,
  useMediaQuery
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GCItemSearch from '../../../component/ReuseableComponent/GCItemSearch';
import {
  CmpInputField,
  CmpInputFieldGroup,
  CmpTypographyField
} from '../../../component/ToolBox/ToolBox';
import { fAlertToast } from '../../../Utility/Utilitys';
import { getExactSearchItem, GridDataColumnsItemSearch, searchItem } from '../actions';
import { updateRequestItemsDetail } from '../OutletRequestSlice';
import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  }
}));
const UnitSelector = ({ params, setUnit }) => {
  return (
    <div style={{ width: '100%', height: '100%', padding: 0 }}>
      <Select
        value={params.row.unitCd}
        label={'unit'}
        onChange={(event) => {
          setUnit(params, event.target.value);
          console.log(event);
        }}
        name={'unit'}
        fullWidth
        style={{ padding: 0, height: '100%', textAlign: 'center' }}
        input={<BootstrapInput />}>
        {params.row.unitList.length > 0 ? (
          params.row.unitList.map((e, i) => {
            const keys = Object.keys(e);
            return (
              <MenuItem key={'Key' + i} value={e.Unit}>
                {e.Name1 + ' ( ' + Number(e.PackQty).toFixed(1) + ' )'}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={''}>none</MenuItem>
        )}
      </Select>
    </div>
  );
};

export default function RequestItemGrid({
  xGenerateDetail,
  xVocNo,
  xMobileScreen,
  xMediaQuery,
  xDocStat,
  xOnClickDeleteItem,
  sItemSearchModal,
  setItemSearchModal,
  setSelectedSrNo,
  sSelectedSrNo,
  loc
}) {
  const rdxItemList = useSelector((state) => state.OutletRequestSlice.value.requestItemDetail);
  const rdxColumns = useSelector((state) => state.OutletRequestSlice.value.requestGridColumns);

  const dispatch = useDispatch();
  // const [sSelectedSrNo, setSelectedSrNo] = useState(0);
  // const [sItemSearchModal, setItemSearchModal] = useState(false);
  const [sGridSelectRowData, setGridSelectRowData] = useState([]);
  const [sLoadingItemSearch, setLoadingItemSearch] = useState(false);
  const [sEnterKeyObj, setEnterKeyObj] = useState({
    key: 'Enter'
  });
  const [sItemSearchModalObj, setItemSearchModalObj] = useState({
    vAnydata: '',
    vItemNameCode: '',
    vItemNameName: '',
    vItemNameBarcode: '',
    vItemNameGroupCode: 'ALL',
    vItemNameRefNo: '',
    vItemNameNameFL: '',
    vItemNameCostPrice: '',
    vItemNameRSP: ''
  });

  const setFinalQty = (params) => {
    let itemList = rdxItemList.map((i) => {
      return i.SrNo === params.row.SrNo ? { ...i, FinalQty: params.value } : i;
    });
    dispatch(updateRequestItemsDetail(itemList));
    return { ...params.row, FinalQty: params.value };
  };

  const setUnit = (params, unit) => {
    let unitObj = params.row.unitList.find((u) => u.Unit === unit);
    let packQty = Number(unitObj?.PackQty);

    let itemList = rdxItemList.map((i) => {
      return i.SrNo === params.row.SrNo
        ? { ...i, unitCd: unit, FinalQty: packQty * Number(i.Qty) }
        : i;
    });
    dispatch(updateRequestItemsDetail(itemList));
    return { ...params.row, unitCd: unit, FinalQty: packQty * Number(params.row.Qty) };
  };

  const setQty = (params) => {
    let unitObj = params.row.unitList.find((u) => u.Unit === params.row.unitCd);
    let packQty = Number(unitObj?.PackQty);

    let itemList = rdxItemList.map((i) => {
      return i.SrNo === params.row.SrNo
        ? { ...i, Qty: Number(params.value), FinalQty: packQty * Number(params.value) }
        : i;
    });
    dispatch(updateRequestItemsDetail(itemList));
    return { ...params.row, Qty: Number(params.value), FinalQty: packQty * Number(params.value) };
  };

  const columns = [
    ...rdxColumns,
    {
      field: 'Qty',
      headerName: 'Qty',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      hide: false,
      valueSetter: setQty,
      editable: true,
      type: 'number',
      cellClassName: 'mui-request-grid-final-qty'
    },
    {
      field: 'unitCd',
      headerName: 'Unit',
      flex: 3,
      cellClassName: 'mui-request-grid-unit',
      renderCell: (params) => {
        return <UnitSelector params={params} setUnit={setUnit} />;
      }
    },

    {
      field: 'FinalQty',
      headerName: 'Base Qty',
      hide: false,
      flex: 2,
      align: 'center',
      hide: false
      //valueSetter: setFinalQty,
      //editable: true,
      //cellClassName: 'mui-request-grid-final-qty'
    },
    {
      field: 'delete',
      headerName: '',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: false,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onClickDelete(params);
            }}
            disabled={xDocStat.toString().toUpperCase() !== 'ENTER'}>
            <DeleteOutline />
          </IconButton>
        );
      }
    }
  ];

  const dispatchItem = async (itemCd, itemName) => {
    //let itemList = rdxItemList.map(i => { return i.SrNo === sSelectedSrNo ? { ...i, ItmCd: itemCd, ItemName: itemName } : i });
    //dispatch(updateRequestItemsDetail(itemList));

    if (itemCd != '') {
      let result = await xGenerateDetail(xVocNo, itemCd, sSelectedSrNo);
      if (!result) {
        fAlertToast(
          'FAILED',
          'The item cannot be added because it might be inactive, OTP enabled, frozen, discontinued, or not enabled for DC items, or it might not be connected to the current department'
        );
      }
    }
  };

  const fOnRowClickGCItemSearch = async (item) => {
    dispatchItem(item.row.ItmCd, item.row.Name1);
    setItemSearchModal(false);
  };

  const fClearItemSearch = useCallback(
    (event) => {
      setItemSearchModalObj({
        vAnydata: '',
        vItemNameCode: '',
        vItemNameName: '',
        vItemNameBarcode: '',
        vItemNameGroupCode: 'ALL',
        vItemNameRefNo: '',
        vItemNameNameFL: '',
        vItemNameCostPrice: '',
        vItemNameRSP: ''
      });
      setGridSelectRowData([]);
      setLoadingItemSearch(false);
    },
    [sItemSearchModalObj]
  );
  const fCloseItemSearchModal = () => {
    setItemSearchModal(false);
    fClearItemSearch();
  };
  const fonChangeInputModal = (event) => {
    setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
  };
  const fonChangeInputModalSelect = (event) => {
    setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
    // GetItemSearchValue("", "GROUP", event.target.value);
  };
  useEffect(() => {
    if (sItemSearchModal) {
      fShowItemSearchModal();
    }
  }, [sItemSearchModal]);

  const fShowItemSearchModal = () => {
    setItemSearchModal(true);
  };

  const fSearchItem = async () => {
    let searchObj = {
      v_AnyData: sItemSearchModalObj.vAnydata,
      v_Cd: sItemSearchModalObj.vItemNameCode,
      v_Name1: sItemSearchModalObj.vItemNameName,
      v_Barcode: sItemSearchModalObj.vItemNameBarcode,
      v_Model: sItemSearchModalObj.vItemNameGroupCode,
      v_RefNo1: sItemSearchModalObj.vItemNameRefNo,
      v_NameFL: sItemSearchModalObj.vItemNameNameFL,
      v_Loc: loc
    };

    const res = await searchItem(searchObj);
    return res.ResultSet;
  };

  const GetItemSearchValue = async (e, xOnchangeAPI, xValue) => {
    if (e.key == 'ArrowDown' || e.keyCode == 40) {
      setTimeout(() => {
        var selectedLength = document.getElementsByClassName('Mui-selected').length;
        if (selectedLength === 0) {
          let tabel = document.getElementById('table-main-grid-3');
          // vIsDownKeyPressed = true;
          // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.click();
          // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.dblclick();
          tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.focus();
        }
      }, 500);
    } else if (e.key == 'Enter' || e._reactName === 'onClick') {
      const result = await fSearchItem();
      if (result.length > 0) {
        let GridSearchItem = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
        setGridSelectRowData(GridSearchItem);
      }
    }
  };

  // const columns = [
  //     {
  //         field: 'SrNo',
  //         headerName: 'Sr No.',
  //         flex: 1,
  //         align: 'center',
  //         headerAlign: 'center',
  //         hide: true
  //     },
  //     {
  //         field: 'ItmCd',
  //         headerName: 'Item Cd',
  //         flex: 3,
  //         hide: true
  //     },
  //     {
  //         field: 'ItemName',
  //         headerName: 'Item Name',
  //         flex: 5
  //     },
  //     {
  //         field: 'BarCode',
  //         headerName: 'Barcode',
  //         flex: 3
  //     },
  //     {
  //         field: 'PLU',
  //         headerName: 'PLU',
  //         flex: 3,
  //         hide: true
  //     },
  //     {
  //         field: 'unit',
  //         headerName: 'Unit',
  //         flex: 3,
  //         hide: true
  //     },
  //     {
  //         field: 'BuyerName',
  //         headerName: 'BuyerName',
  //         flex: 3,
  //         hide: true
  //     },
  //     {
  //         field: 'Cost',
  //         headerName: 'Cost',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toLocaleString('en')
  //     },
  //     {
  //         field: 'RSP',
  //         headerName: 'RSP',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toLocaleString('en'),
  //         hide: true
  //     },
  //     {
  //         field: 'GP',
  //         headerName: 'GP',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toLocaleString('en'),
  //         hide: true
  //     },
  //     {
  //         field: 'StkQty',
  //         headerName: 'Stock',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0)
  //     },
  //     {
  //         field: 'OrderQty',
  //         headerName: 'Order Qty',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0),
  //         hide: true
  //     },
  //     {
  //         field: 'SalQty',
  //         headerName: 'Sale Qty',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0),
  //         hide: true
  //     },
  //     {
  //         field: 'AvgSalQty',
  //         headerName: 'AvgSal Qty',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0),
  //         hide: true
  //     },
  //     {
  //         field: 'MaxShelfQty',
  //         headerName: 'MaxShelf',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0),
  //         hide: true
  //     },
  //     {
  //         field: 'MinShelfQty',
  //         headerName: 'MinShelf',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0),
  //         hide: true
  //     },
  //     {
  //         field: 'ReqQty',
  //         headerName: 'Required',
  //         flex: 2,
  //         align: 'right',
  //         headerAlign: 'right',
  //         valueFormatter: ({ value }) => Number(value).toFixed(0)
  //     },
  //     {
  //         field: 'FinalQty',
  //         headerName: 'FinalQty',
  //         flex: 3,
  //         align: 'center',
  //         headerAlign: 'center',
  //         hide: false,
  //         valueSetter: setFinalQty,
  //         editable: true,
  //         cellClassName: 'mui-request-grid-final-qty'
  //     }
  // ];

  const columnsMob = [
    {
      field: 'ItemName',
      headerName: 'Item Name',
      flex: 8
    },

    {
      field: 'StkQty',
      headerName: 'Stock',
      flex: xMobileScreen ? 1 : 2,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0),
      hide: xMobileScreen
    },
    {
      field: 'ReqQty',
      headerName: 'Required',
      flex: xMobileScreen ? 1 : 2,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'Qty',
      headerName: 'Qty',
      flex: xMobileScreen ? 1 : 2,
      align: 'center',
      headerAlign: 'center',
      valueSetter: setQty,
      editable: true,
      type: 'number',
      cellClassName: 'mui-request-grid-final-qty'
    },
    {
      field: 'unitCd',
      headerName: 'Unit',
      flex: 4,
      cellClassName: 'mui-request-grid-unit',
      renderCell: (params) => {
        return <UnitSelector params={params} setUnit={setUnit} />;
      }
    },

    {
      field: 'FinalQty',
      headerName: 'Base Qty',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      hide: xMobileScreen
      // valueSetter: setFinalQty,
      // editable: true,
      // cellClassName: 'mui-request-grid-final-qty'
    },
    {
      field: 'delete',
      headerName: '',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: false,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onClickDelete(params);
            }}
            disabled={xDocStat.toString().toUpperCase() !== 'ENTER'}>
            <DeleteOutline />
          </IconButton>
        );
      }
    }
  ];
  const fOnCellClick = (params) => {
    if (params.field === 'ItemName') {
      setSelectedSrNo(params.row.SrNo);
      fShowItemSearchModal();
    }
  };
  const fOnRowDoubleClick = (params) => {
    console.log('double click');
    setSelectedSrNo(params.row.SrNo);
    fShowItemSearchModal();
  };

  const onClickDelete = async (params) => {
    await xOnClickDeleteItem(params.row.SrNo);
  };

  return (
    <>
      <Box
        sx={{
          height: xMediaQuery ? (xMobileScreen ? '75vh' : '43vh') : '50vh',
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}>
        {xMediaQuery ? (
          <DataGrid
            getRowId={(row) => row.SrNo}
            rowHeight={50}
            headerHeight={40}
            disableColumnSelector
            sx={{
              border: 2,
              borderColor: 'primary.light',
              color: '#000000'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            // getRowClassName={(params) =>
            //     params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            // }
            rows={rdxItemList}
            columns={columnsMob}
            onCellClick={(params) => {
              fOnCellClick(params);
            }}
          />
        ) : (
          <DataGrid
            getRowId={(row) => row.SrNo}
            rowHeight={50}
            headerHeight={40}
            disableColumnSelector
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            // getRowClassName={(params) =>
            //     params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            // }
            rows={rdxItemList}
            columns={columns}
            onCellClick={(params) => {
              fOnCellClick(params);
            }}
          />
        )}
      </Box>
      <Modal
        open={sItemSearchModal}
        onClose={fCloseItemSearchModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="model-sales-invoice-GCVanMaster-Modal">
          <GCItemSearch
            fCloseItemSearch={fCloseItemSearchModal}
            title={'Item'}
            xOnRowClick={(item) => fOnRowClickGCItemSearch(item)}
            xItemRows={sGridSelectRowData}
            xItemColumn={GridDataColumnsItemSearch}
            xonChangeInputModal={fonChangeInputModal}
            fonChangeInputModalSelect={fonChangeInputModalSelect}
            xItemSearchModalObj={sItemSearchModalObj}
            xfClear={fClearItemSearch}
            xGetItemSearchValue={GetItemSearchValue}
            sLoadingItemSearch={sLoadingItemSearch}
            vMediaQuery={xMediaQuery}
            xEnterObj={sEnterKeyObj}
            vRdxDBLocationGroup={[]}
          />
        </Box>
      </Modal>
    </>
  );
}
