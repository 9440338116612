import { Close } from '@mui/icons-material'
import { Avatar, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { CmpButton, CmpCheckboxField, CmpTypographyField } from '../../../component/ToolBox/ToolBox'

import grapes from '../../../Images/grapes.jpg';
import { fAlertToast } from '../../../Utility/Utilitys'

export default function AllocationModal({ sSelectedRow, rowsData, setUpdateRows, editable, setLocModal }) {

    const locRows = rowsData.filter(i => i.SrNo === sSelectedRow.SrNo).map(j => { return j.locList });
    const [sLocRows, setLocRows] = useState(rowsData.find(i => i.SrNo === sSelectedRow.SrNo).locList);
    const [sManualAlloc, setManualAlloc] = useState(false);
    const [sTotalAllocQty, setTotalAllocQty] = useState(0);
    const [sGridEditing, setGridEditing] = useState(false);
    const [sTotalExistPurCTN, setTotalExistPurCTN] = useState(Number(sSelectedRow.ExistingPurCTN));
    const [sTotalRequiredCTN, setTotalRequiredCTN] = useState(Number(sSelectedRow.RequiredCTN));
    const [sSelectAllLoc, setSelectAllLoc] = useState(true);

    console.log("locRows>>>>>" + JSON.stringify(sLocRows));

    useEffect(() => {
        let totalAllocQty = sLocRows.reduce((prevValue, i) => {
            return Number(prevValue) + Number(i.CurrAllocQtyCTN);
        }, 0);
        setTotalAllocQty(Number(totalAllocQty));
    }, [sLocRows])

    /*************moved to detail *******************
    useEffect(() => {
        console.log("called loc modal");
        let arr = rowsData.find(i => i.SrNo === sSelectedRow.SrNo).locList;
        let packQty = isNaN(sSelectedRow.PackQty) ? 1 : (sSelectedRow.PackQty === 0 ? 1 : sSelectedRow.PackQty);
        arr = arr.map(i => {
            let reqCTN = 0;
            if (i.OrderQty - i.ExistingAlloQty <= 0) {
                reqCTN = 0;
            }
            else {
                reqCTN = Math.round((i.OrderQty - i.ExistingAlloQty) / packQty);
                reqCTN = reqCTN === 0 ? (i.ExistingAlloQty > 0 ? 0 : 1) : reqCTN;
            }


            return {
                ...i,
                OrderQtyCTN: i.OrderQty / packQty,
                ExistingAlloQtyCTN: i.ExistingAlloQty / packQty,
                ReqCTN: reqCTN
            }
        })
    }, []);

    ******************/


    // const obj = {

    //     "locList": [
    //         {
    //             "ItmCd": "9980103",
    //             "ItmName": "ONION INDIA",
    //             "Loc": "05",
    //             "LocName": "ALQ-HYPER",
    //             "OrderQty": 20,
    //             "OrderQtyCTN": 20,
    //             "ExistingAlloQty": 17.5,
    //             "ExistingAlloQtyCTN": 17.5,
    //             "ReqCTN": 2.5,
    //             "VANID": "017",
    //             "CurrAllocQtyCTN": 7,
    //             "Flag": "Y"
    //         },

    //     ]
    // };

    const setCurrAllocQty = (params) => {
        let row = { ...params.row, CurrAllocQtyCTN: params.value };

        const locs = sLocRows.map((itm) => (itm.Loc === row.Loc ? row : itm));
        setLocRows(locs);
        return row;
    };

    const onClickSave = () => {

        if (Number(sSelectedRow.PackQty) === 0 && Number(sSelectedRow.Qty) === 0) {
            const updatedRowsData = rowsData.map(i => (i.SrNo === sSelectedRow.SrNo ? { ...i, locList: sLocRows, ExistingPurCTN: sTotalExistPurCTN, RequiredCTN: sTotalRequiredCTN } : i));
            setUpdateRows(updatedRowsData);
        }
        else if (sManualAlloc) {
            const totalPurQty = Number(sSelectedRow.Qty);
            // let totalAllocQty = sLocRows.reduce((prevValue, i) => {
            //     return Number(prevValue) + Number(i.CurrAllocQtyCTN);
            // }, 0);

            let totalAllocQty = Number(sTotalAllocQty);

            console.log(totalPurQty, totalAllocQty)

            if (totalPurQty !== totalAllocQty) {
                fAlertToast('FAILED', 'Allocation quantity is not matches with Purchased Qty.');
            }
            else {

                const updatedRowsData = rowsData.map(i => (i.SrNo === sSelectedRow.SrNo ? { ...i, locList: sLocRows } : i));
                setUpdateRows(updatedRowsData);
                //rowsData.filter(i => i.SrNo === sSelectedRow.SrNo).map(j => { return j.locList })
            }
        }
        setLocModal(false);
    }
    const changeChecked = (params) => {
        const checked = !params.row.selected;

        let row = { ...params.row, selected: checked };
        const locs = sLocRows.map((itm) => (itm.Loc === row.Loc ? row : itm));
        setLocRows(locs);

        let totalExistPurCTN = locs.filter(i => i.selected).reduce((prevValue, i) => {
            return Number(prevValue) + Number(i.ExistingAlloQtyCTN);
        }, 0);


        let totalReqCTN = locs.filter(i => i.selected).reduce((prevValue, i) => {
            return Number(prevValue) + Number(i.ReqCTN);
        }, 0);

        setTotalExistPurCTN(totalExistPurCTN);
        setTotalRequiredCTN(totalReqCTN);

        return row;
    }

    const columns = [
        {
            field: 'checked',
            headerName: '',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {
                return (
                    <CmpCheckboxField xChecked={params.row.selected}
                        xDisabled={Number(sSelectedRow.PackQty) !== 0 || Number(sSelectedRow.Qty) !== 0}
                        xOnChange={(event) => { console.log(params); changeChecked(params); }}
                    />
                );
            }
        },
        {
            field: 'Loc',
            headerName: 'Loc',
            flex: 1,
            hide: true
        },
        {
            field: 'LocName',
            headerName: 'Location',
            flex: 10,
            // width:150
        },

        {
            field: 'OrderQtyCTN',
            headerName: 'Order Qty.',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            field: 'ExistingAlloQtyCTN',
            headerName: 'Exist.Purchased',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2),
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },

        },
        {
            field: 'ReqCTN',
            headerName: 'Req. Qty.',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2),
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },

        }, {
            field: 'CurrAllocQtyCTN',
            headerName: 'Curr.Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            editable: editable && sManualAlloc,
            valueSetter: setCurrAllocQty,
            valueFormatter: ({ value }) => Number(value).toFixed(2),
            valueParser: (newValue) => {
                if (newValue < 0) {
                    return 0;
                }
                return newValue;
            },

        },
    ];

    const onChangeSelectAllLoc = (checked) => {
        setSelectAllLoc(checked);
        const locs = sLocRows.map((itm) => { return { ...itm, selected: checked } });
        setLocRows(locs);

        if (checked) {
            let totalExistPurCTN = locs.reduce((prevValue, i) => {
                return Number(prevValue) + Number(i.ExistingAlloQtyCTN);
            }, 0);


            let totalReqCTN = locs.reduce((prevValue, i) => {
                return Number(prevValue) + Number(i.ReqCTN);
            }, 0);

            setTotalExistPurCTN(totalExistPurCTN);
            setTotalRequiredCTN(totalReqCTN);
        }
        else {
            setTotalExistPurCTN(0);
            setTotalRequiredCTN(0);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }} className="m-4">

            <Grid container spacing={0}>
                <Grid item xs={12} className="d-flex justify-content-end">
                    <Close onClick={() => setLocModal(false)} />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                className="my-2 p-2"
                sx={{ alignItems: 'center' }}>
                <Grid item xs={9} sm={9} lg={9} md={9}>
                    <Grid
                        container
                        spacing={2}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={sSelectedRow.ALTItmCd} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Item Name'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={sSelectedRow.ALTItmName} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Pack Qty'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={Number(sSelectedRow.PackQty).toFixed(2)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Purchased Qty'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={Number(sSelectedRow.Qty).toFixed(2)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} sm={3} lg={3} md={3}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        //sx={{ alignItems: 'center' }}
                        className='mb-4'
                    >
                        <Grid item xs={7} sm={7} lg={10} md={10}>
                            <CmpTypographyField xcomponent={'span'} xText={'Manual allocation'} />
                        </Grid>
                        <Grid item xs={1} sm={1} lg={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={2} sm={2} lg={1} md={1}>
                            <CmpCheckboxField
                                xChecked={sManualAlloc}
                                xDisabled={Number(sSelectedRow.PackQty) === 0 && Number(sSelectedRow.Qty) === 0}
                                xOnChange={(event) => { setManualAlloc(event.target.checked) }}
                            />
                        </Grid>
                    </Grid>
                    {/* <Avatar
                        alt="Remy Sharp"
                        src={grapes}
                        sx={{ width: 80, height: 80, border: '2px solid #0d73bb' }}
                    /> */}

                </Grid>
                <Grid item className='mt-2'>
                    <CmpCheckboxField
                        xChecked={sSelectAllLoc}
                        xDisabled={Number(sSelectedRow.PackQty) !== 0 || Number(sSelectedRow.Qty) !== 0}
                        xOnChange={(event) => { onChangeSelectAllLoc(event.target.checked) }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mt-0'>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Box
                        sx={{
                            height: '35vh',
                            width: '100%',
                            '& .actions': {
                                color: 'text.secondary'
                            },
                            '& .textPrimary': {
                                color: 'text.primary'
                            }
                        }}>
                        <DataGrid
                            columns={columns}
                            rows={sLocRows}
                            getRowId={(row) => row.Loc}
                            rowHeight={45}
                            headerHeight={50}
                            hideFooter={true}
                            sx={{
                                border: 2,
                                borderColor: 'primary.light',
                            }}
                            componentsProps={{
                                filterPanel: { sx: { maxWidth: "93vw" } }
                              }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                            }

                            onCellEditStart={() => setGridEditing(true)}
                            onCellEditStop={() => setGridEditing(false)}
                            onCellEditCommit={() => setGridEditing(false)}
                        />
                        <div className='p-3' style={{ height: '3vh', display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                            <div style={{ flex: 3, textAlign: 'left' }}><CmpTypographyField xText='Total' /></div>
                            <div style={{ flex: 2 }}> <CmpTypographyField xText={(Number(sSelectedRow.OrdQty) / (Number(sSelectedRow.PackQty) === 0 ? 1 : Number(sSelectedRow.PackQty))).toFixed(2)} /></div>
                            <div style={{ flex: 2 }}><CmpTypographyField xText={Number(sTotalExistPurCTN).toFixed(2)} /></div>
                            <div style={{ flex: 2 }}><CmpTypographyField xText={Number(sTotalRequiredCTN).toFixed(2)} /></div>
                            <div style={{ flex: 2 }}><CmpTypographyField xText={Number(sTotalAllocQty).toFixed(2)} /></div>
                        </div>
                    </Box >
                </Grid >
            </Grid >
            <Grid container spacing={0} className='mt-5'>
                <Grid item xs={12} className="d-flex justify-content-center">
                    <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={onClickSave}
                        xLabel={'Save'}
                        xDisable={!(editable) || sGridEditing}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}
