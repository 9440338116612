import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import useMediaQuery from '@mui/material/useMediaQuery';
import '../../../component/ReuseableComponent/ReuseableComponent.css';
import Modal from '@mui/material/Modal';
import '../../../component/ToolBox/ToolBox.css';
import {
  CmpSelectArrField,
  CmpTypographyField,
  MUIDataGrid,
  CmpButtonWithIcon
} from '../../../component/ToolBox/ToolBox';
import { getUnloadBatchAttr, getUnloadBatchDetail } from '../actions';
import { getGitterColumns } from '../../../Utility/Utilitys';
import CloseIcon from '@mui/icons-material/Close';

const fDummyFunction = () => {};
const gridBox = {
  border: '1px solid #DADBD6',
  padding: 0.5
};
const borderBox = {
  height: '60vh',
  padding: 0.5
};

const newBatchGridColSample = [
  {
    field: 'VAN_TRNID',
    headerName: 'TRN.ID',
    hide: false,
    width: 120,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'VAN_REFID',
    headerName: 'Reference.ID',
    hide: false,
    width: 190,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'VAN_TRUCK_ID',
    headerName: 'TRUCK.ID',
    hide: false,
    width: 120,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_LOC',
    headerName: 'Location',
    hide: false,
    width: 120,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'id',
    headerName: 'id',
    hide: false,
    width: 40,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  }
];

const UnLoadNewBatch = ({ xOpen = false, xTitle = '', fCloseModal, fCreateNewBatch, headObj }) => {
  // const typeRef = useRef(null);
  // const vMediaQuery = useMediaQuery('(max-width:650px)');
  const [van, setVan] = useState([]);
  const [selectedVan, setSelectedVan] = useState({
    vanName: '',
    vanCode: ''
  });
  const [newBatchGridRow, setNewBatchGridRow] = useState([]);
  const [newBatchGridCol, setNewBatchGridCol] = useState(newBatchGridColSample);
  const [newBatch, setNewBatch] = useState([]);

  const onChangeModalVan = (event) => {
    const data = van.find((x) => x.Name1 === event.target.value);
    setSelectedVan((obj) => ({
      ...obj,
      vanName: data.Name1,
      vanCode: data.Uid
    }));
  };

  const onLoadClick = async () => {
    const payload = {
      trnTyp: 'UNLODIGDETILWB'
    };
    const col = await getGitterColumns(payload);
    setNewBatchGridCol(col?.ResultSet?.columns);

    const obj = {
      v_Loc: headObj?.locCd,
      v_Truck: selectedVan?.vanCode
    };
    const row = await getUnloadBatchDetail(obj);
    setNewBatchGridRow(row?.ResultSet);
  };

  const onSelectGridItem = (item) => {
    console.log(item, 'item');
    const data = item.filter((x) => x.Flg == 'Y');
    setNewBatch(data);
  };

  useEffect(() => {
    const fonPageload = async () => {
      const result = await getUnloadBatchAttr();
      setVan(result?.ResultSet?.Truck);
      setSelectedVan((obj) => ({
        ...obj,
        vanName: result?.ResultSet?.Truck[0]?.Name1,
        vanCode: result?.ResultSet?.Truck[0]?.Uid
      }));
    };
    fonPageload();
  }, []);
  return (
    <Modal
      open={xOpen}
      onClose={fCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ flexGrow: 1, overflow: 'auto' }} className="gnr-item-search model-gc-search">
        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="GCSearchBtn-Tittle"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={11} sm={11} md={11} className="d-flex justify-content-center  ">
            <div>{xTitle}</div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} className="d-flex justify-content-end">
            <CloseIcon
              className="grnt-item-search-close-icon"
              onClick={!!fCloseModal ? fCloseModal : fDummyFunction}
              style={{ zIndex: 999 }}
            />
          </Grid>
        </Grid>
        <Box sx={gridBox}>
          <Grid container>
            <Grid item xs={8} sm={8} md={3} lg={3} sx={{ p: '5px' }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}>
                <Grid item xs={2} sm={2} md={3} lg={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'Van'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={10} sm={10} md={9} lg={9}>
                  <CmpSelectArrField
                    xLabelText={'van'}
                    xValue={selectedVan.vanName}
                    xOnChange={onChangeModalVan}
                    xName={'van'}
                    xData={van.map((x) => x.Name1)}
                    xValueMember={''}
                    xDispalyMember={''}
                    xError={false}
                    xErrorMessage={''}
                    xDisable={false}
                    xheight={'15rem'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={1} md={1} lg={1}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={onLoadClick}
                xLabel={'Load'}
                xIcon={''}
                xDisable={false}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={borderBox}>
              <MUIDataGrid
                xRows={newBatchGridRow}
                xColumns={newBatchGridCol}
                xonRowClick={() => {}}
                enableCheckbox={newBatchGridRow?.length > 0 ? true : false}
                borderColorChange={true}
                xonSelectionClick={onSelectGridItem}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ width: '20%', margin: '0 auto' }}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'medium'}
                  xonClick={() => {
                    fCreateNewBatch(newBatch);
                  }}
                  xLabel={'Create NewBatch'}
                  xIcon={''}
                  xDisable={false}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnLoadNewBatch;
