import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Checkbox, Drawer, IconButton, Modal, Typography, useMediaQuery } from '@mui/material';

import {
  CmpTypographyField,
  CmpTypographyFieldColor,
  StatusIcon
} from '../../../component/ToolBox/ToolBox';
import { Delete, MoreVert, PictureAsPdf } from '@mui/icons-material';
import { deletePurchaseTransaction } from '../actions';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../../Utility/Utilitys';
import { getKernValue } from '../../Login/action';
import CloseIcon from '@mui/icons-material/Close';
import WarehouseIcon from '@mui/icons-material/Warehouse';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'background.paper',
  border: '2px solid #0d73bb',
  boxShadow: 24,
  // p: 4,
  textAlign: 'center',
  // alignItems: "center",
  // borderRadius: 3,
  height: '80%',
  margin: '0 auto',
  color: '#0d73bb'
};

export default function TransSummarycard({
  xTransId,
  xInvoiceNumber,
  xVendor,
  xAmount,
  xDate,
  xState,
  xStatus,
  xOnClick,
  sTransList,
  setTransList,
  xctrpuchase = '',
  xDCPurchase = 'N'
}) {
  const [sOpenModal, setOpenModal] = useState(false);
  const vMediaQuery = useMediaQuery('(max-width:900px)');
  const [printWebURL, setPrintWebURL] = useState(null);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  const onClickDelete = async () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to Delete?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            const obj = {
              transId: xTransId
            };
            const result = await deletePurchaseTransaction(obj);
            if (result.status == 200) {
              fAlertToast('SUCCESS', 'Purchase has been deleted');
              let newArray = sTransList.filter((i) => i.TransId !== xTransId);
              setTransList(newArray);
            }
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const onClickViewInvoice = async () => {
    setOpenPrintModal(true);
    try {
      const obj = {
        key: 'WEB_REPORT_BASE_URL_OTS'
      };

      const result = await getKernValue(obj);
      let baseUrl = '';
      if (result.ResultSet?.length > 0) {
        baseUrl = result.ResultSet[0].Val;
      }
      console.log('baseUrl' + baseUrl);

      // let url = `${baseUrl}FreshPurchase/GenerateInvoice?TRNS_ID=${xTransId}&Download=1`
      // window.open(url);
      let url = `${baseUrl}FreshPurchase/FreshPurchasePrint?trntyp=GI&vTrnsId=${xTransId}`;
      console.log(url, 'url');
      setPrintWebURL(url);
    } catch (error) {
      fAlertToast('FAILED', error.meassge);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="border-card-2">
        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={11} sm={11} md={11} style={{ textAlign: 'center', paddingBottom: '15px' }}>
            <CmpTypographyField xVariant="h3" xText={xTransId} />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <IconButton size="small" color="primary" onClick={showModal}>
              <MoreVert />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} onClick={xOnClick}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Invoice No.'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xInvoiceNumber} />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={7}>
                                <Grid
                                    container
                                    spacing={0}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={3} sm={5} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                                        <CmpTypographyField xcomponent={'span'} xText={xVendor} />
                                    </Grid>
                                </Grid>
                            </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Amount'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={Number(xAmount).toFixed(2)} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={5} md={7} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xDate} />
                  </Grid>
                  {xDCPurchase === 'Y' && (
                    <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'end' }}>
                      <WarehouseIcon
                        style={{
                          color: 'green'
                        }}
                        fontSize="small"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'State'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={5} md={7} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xState} />
                  </Grid>

                  {(xState === 'OPEN' || xState === 'POST') && (
                    <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'end' }}>
                      <StatusIcon xStatus={xStatus} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Grid
                  container
                  spacing={0}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={3} sm={5} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Purchase'} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={5} md={7} className="txt-overflow-ellipse">
                    <CmpTypographyField xcomponent={'span'} xText={xctrpuchase} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11.8} sm={11.8} md={11.8} sx={{ padding: '5px 0px' }}>
            <Typography
              variant="body2"
              component={'span'}
              fontSize="14px"
              className="wrap-ellipsis">
              {xVendor}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* <Modal
                open={sOpenModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{ height: '20vh', width: '20%', backgroundColor: '#fff', alignSelf: 'center', position: 'absolute', top: '40%', left: '40%' }}>
                    <IconButton size='large' color='primary' onClick={() => { console.log("clickedd") }}>
                        <PictureAsPdf />
                        <Typography className='p-4'>Attachement</Typography>
                    </IconButton>
                </Box>
            </Modal> */}

      <Drawer
        anchor={vMediaQuery ? 'bottom' : 'right'}
        open={sOpenModal}
        onClose={() => setOpenModal(false)}>
        {/* <Box
                    sx={{ height: '25vh', padding: '50px' }}
                    role="presentation"
                > */}
        <div style={{ height: '25vh', padding: '20px', borderRadius: '40px 40px 0px 0px' }}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12}>
              <IconButton size="large" color="primary" onClick={() => onClickViewInvoice()}>
                <PictureAsPdf sx={{ fontSize: '3rem', paddingRight: '15px' }} />
                <Typography className="pl-2" variant="h3">
                  Preview Invoice
                </Typography>
              </IconButton>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <IconButton
                size="large"
                color="primary"
                onClick={() => onClickDelete()}
                disabled={xStatus.toString().toUpperCase().trim() !== 'ENTER'}>
                <Delete sx={{ fontSize: '3rem', paddingRight: '15px' }} />
                <Typography variant="h3">Delete</Typography>
              </IconButton>
            </Grid>
          </Grid>
        </div>
        {/* </Box> */}
      </Drawer>

      <Modal
        open={openPrintModal}
        onClose={() => setOpenPrintModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box className="GCSearchBtn-Tittle">
            <span>Invoice</span>
            <CloseIcon
              sx={{ zIndex: 999, float: 'right' }}
              onClick={() => setOpenPrintModal(false)}
            />
          </Box>
          <iframe src={printWebURL} width="100%" height={500}></iframe>
        </Box>
      </Modal>
    </>
  );
}
