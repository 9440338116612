import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Grid, IconButton, Modal, useMediaQuery } from '@mui/material';
import { CmpButton, CmpInputField, CmpInputFieldGroup, CmpMultiSelectAll, CmpSelectArrField, CmpSelectField, CmpTypographyField, CmpTypographyFieldColor } from '../../component/ToolBox/ToolBox';
import { getCategoryList, getDepartmentList, getExactSearchItem, getMISReport, getReportColumns, getSubDepartmentList, GridDataColumnsItemSearch, searchItem } from './actions';
import { updateReportFilter } from './ReturnReportSlice';
import { fAlertToast } from '../../Utility/Utilitys';
import { ToastContainer } from 'react-toastify';
import './ReturnReport.css';
import ReportGrid from './ProcessComponents/ReportGrid';
import { Close, Minimize, OpenInFull } from '@mui/icons-material';
import GCItemSearch from '../../component/ReuseableComponent/GCItemSearch';

const typeList = ["All", "Is"];
const transferTypList = ["All", "Transfer", "Transfer + Margin", "Transfer Return"];
const reportTypes = [
    { id: "ITMWISE", name: "Item wise" },
    { id: "DEPTWISE", name: "Department wise" },
    { id: "SUBDEPTWISE", name: "Sub Department wise" },
    { id: "CATWISE", name: "Cateogory wise" },
    { id: "DOCWISE", name: "Document wise" },
    { id: "DATEWISE", name: "Date wise" },
    { id: "LOCWISE", name: "Location wise" },
];

export default function ReturnReports() {

    const dispatch = useDispatch();
    const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
    const locList = useSelector((state) => state.SliceDB.value.getLocation);
    const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
    const rdxHeadobj = useSelector((state) => state.ReturnReportSlice.value.reportFilter);
    const scanFocusOnLoad = useRef(null);

    const [sFilteredDCNames, setFilteredDCNames] = useState([]);
    const [sFilteredLocNames, setFilteredLocNames] = useState([]);
    const [sDeptList, setDeptList] = useState([]);
    const [sSubDeptList, setSubDeptList] = useState([]);
    const [sCatList, setCatList] = useState([]);
    const [sGridColumns, setGridColumns] = useState([]);
    const [sHideFilter, setHideFilter] = useState(false);
    const [sHeadObj, setHeadObj] = useState({
        DClocation: [],
        dateFrom: new Date(),
        dateTo: new Date(),
        LocList: [],
        transferTyp: '',
        itmTyp: 'All',
        itmCd: '',
        itmName: '',
        mainGrpTyp: 'All',
        mainGrps: [],
        subGrpTyp: 'All',
        subGrps: [],
        grpTyp: 'All',
        grps: [],
        reportType: reportTypes[1].id
    });

    const [sDetailObj, setDetailObj] = useState([]);
    const [sItemSearchModal, setItemSearchModal] = useState(false);
    const [sGridSelectRowData, setGridSelectRowData] = useState([]);
    const [sLoadingItemSearch, setLoadingItemSearch] = useState(false);
    const [sEnterKeyObj, setEnterKeyObj] = useState({
        key: 'Enter'
    });
    console.log(reportTypes,"reportTypes");
    const [sItemSearchModalObj, setItemSearchModalObj] = useState({
        vAnydata: '',
        vItemNameCode: '',
        vItemNameName: '',
        vItemNameBarcode: '',
        vItemNameGroupCode: 'ALL',
        vItemNameRefNo: '',
        vItemNameNameFL: '',
        vItemNameCostPrice: '',
        vItemNameRSP: ''
    });

    const vMediaQuery = useMediaQuery('(max-width:800px)');
    const fOnPageLoad = useCallback(async () => {

        let filteredDC = dcLoclist.filter(i => rdxFilterObj.DCList.includes(i.UId));
        setFilteredDCNames(filteredDC.map(i => i.Name1));

        let filteredLoc = locList.filter(i => rdxFilterObj.locList.includes(i.UId));
        setFilteredLocNames(filteredLoc.map(i => i.Name1));

        setGridColumns(getReportColumns(rdxHeadobj.reportType));
        let currObj = {
            DClocation: rdxFilterObj.DCList,
            dateFrom: rdxFilterObj.dateFrom,
            dateTo: rdxFilterObj.dateTo,
            LocList: rdxFilterObj.locList,
            transferTyp: rdxHeadobj.transferTyp,
            itmTyp: rdxHeadobj.itmTyp,
            itmName: rdxHeadobj.itmName,
            itmCd: rdxHeadobj.itmCd,
            mainGrpTyp: rdxHeadobj.mainGrpTyp,
            mainGrps: rdxHeadobj.mainGrps,
            subGrpTyp: rdxHeadobj.subGrpTyp,
            subGrps: rdxHeadobj.subGrps,
            grpTyp: rdxHeadobj.grpTyp,
            grps: rdxHeadobj.grps,
            reportType: rdxHeadobj.reportType === '' ? reportTypes[1].id : rdxHeadobj.reportType
        }
        setHeadObj(currObj);
        await fGetDetailList(currObj);
    }, [rdxHeadobj, dcLoclist, locList, rdxFilterObj]);

    const fGetDeptList = useCallback(async () => {
        const result = await getDepartmentList();
        if (result.ResultSet?.length > 0) {
            setDeptList(result.ResultSet);
        } else {
            setDeptList([]);
        }
    }, []);

    const fGetSubDeptList = async (mainGrps) => {
        const obj = {
            deptList: mainGrps.toString(),
        };
        const result = await getSubDepartmentList(obj);
        if (result.ResultSet?.length > 0) {
            setSubDeptList(result.ResultSet);
        } else {
            setSubDeptList([]);
        }
    };

    const fGetCatList = async (subGrps) => {
        const obj = {
            subDeptList: subGrps.toString(),
        };
        const result = await getCategoryList(obj);
        if (result.ResultSet?.length > 0) {
            setCatList(result.ResultSet);
        } else {
            setCatList([]);
        }
    };
    useEffect(() => {
        scanFocusOnLoad.current.focus();
        fOnPageLoad();
        fGetDeptList();
        console.log("calleddd");
    }, [fOnPageLoad, fGetDeptList]);


    const fGetDetailList = async (xHeadObj) => {
        const obj = {
            locList: xHeadObj.LocList.toString(),
            DCLocList: xHeadObj.DClocation.toString(),
            fromDt: moment(xHeadObj.dateFrom).format('MM/DD/YYYY'),
            toDt: moment(xHeadObj.dateTo).format('MM/DD/YYYY'),
            transferTyp: xHeadObj.transferTyp,
            itmCd: xHeadObj.itmTyp === 'All' ? '' : xHeadObj.itmCd,
            mainGrps: xHeadObj.mainGrpTyp === 'All' ? 'All' : xHeadObj.mainGrps.toString(),
            subGrps: xHeadObj.subGrpTyp === 'All' ? 'All' : xHeadObj.subGrps.toString(),
            grps: xHeadObj.grpTyp === 'All' ? 'All' : xHeadObj.grps.toString(),
        };
        const result = await getMISReport(obj, xHeadObj.reportType);
        console.log("reult.......>>>>>", JSON.stringify(result));
        if (result.ResultSet?.length > 0) {
            setDetailObj(result.ResultSet);
        } else {
            setDetailObj([]);
        }
    };

    const onChangeDeptList = async (event) => {
        setHeadObj((obj) => ({
            ...obj,
            mainGrps: event,
            subGrpTyp: 'All',
            subGrps: [],
            grpTyp: 'All',
            grps: [],
        }));
        setSubDeptList([]);
        setCatList([]);
        await fGetSubDeptList(event);
    };
    const onChangeSubDeptList = async (event) => {
        setHeadObj((obj) => ({
            ...obj,
            subGrps: event,
            grpTyp: 'All',
            grps: [],
        }));
        setCatList([]);
        await fGetCatList(event);

    };
    const onChangeCatList = (event) => {
        setHeadObj((obj) => ({
            ...obj,
            grps: event,
        }));

    };

    const onChangeReportType = (type) => {
        setHeadObj((obj) => ({ ...obj, reportType: type }));
    }

    const fOnpressLoad = async () => {
        scanFocusOnLoad.current.focus();

        setDetailObj([]);
        setGridColumns(getReportColumns(sHeadObj.reportType));

        //await fGetDetailList(sHeadObj);
        dispatch(updateReportFilter({
            transferTyp: sHeadObj.transferTyp,
            itmTyp: sHeadObj.itmTyp,
            itmCd: sHeadObj.itmCd,
            mainGrpTyp: sHeadObj.mainGrpTyp,
            mainGrps: sHeadObj.mainGrps,
            subGrpTyp: sHeadObj.subGrpTyp,
            subGrps: sHeadObj.subGrps,
            grpTyp: sHeadObj.grpTyp,
            grps: sHeadObj.grps,
            reportType: sHeadObj.reportType
        }));

    };


    const fClearItemSearch = useCallback(
        (event) => {
            setItemSearchModalObj({
                vAnydata: '',
                vItemNameCode: '',
                vItemNameName: '',
                vItemNameBarcode: '',
                vItemNameGroupCode: 'ALL',
                vItemNameRefNo: '',
                vItemNameNameFL: '',
                vItemNameCostPrice: '',
                vItemNameRSP: ''
            });
            setGridSelectRowData([]);
            setLoadingItemSearch(false);
        },
        [sItemSearchModalObj]
    );
    const fCloseItemSearchModal = () => {
        setItemSearchModal(false);
        fClearItemSearch();
    };

    const fonChangeInputModal = (event) => {
        setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
    };
    const fonChangeInputModalSelect = (event) => {
        setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
        // GetItemSearchValue("", "GROUP", event.target.value);
    };

    const fOnRowClickGCItemSearch = async (item) => {
        setHeadObj((obj) => ({ ...obj, itmName: item.row.Name1, itmCd: item.row.ItmCd }));
        setItemSearchModal(false);
    };

    const fSearchExactItem = async () => {
        let searchObj = {
            v_AnyData: sHeadObj.itmName,
            v_TransType: 'F',
            v_Report: 'Y',
            v_Loc: ''
        };
        const res = await getExactSearchItem(searchObj);
        return res.ResultSet;
    };

    const fSearchItem = async () => {
        let searchObj = {
            v_AnyData: sItemSearchModalObj.vAnydata,
            v_Cd: sItemSearchModalObj.vItemNameCode,
            v_Name1: sItemSearchModalObj.vItemNameName,
            v_Barcode: sItemSearchModalObj.vItemNameBarcode,
            v_Model: sItemSearchModalObj.vItemNameGroupCode,
            v_RefNo1: sItemSearchModalObj.vItemNameRefNo,
            v_NameFL: sItemSearchModalObj.vItemNameNameFL,
            v_Loc: ''
        };

        const res = await searchItem(searchObj);
        return res.ResultSet;

    };

    const GetItemSearchValue = async (e, xOnchangeAPI, xValue) => {
        if (e.key == 'ArrowDown' || e.keyCode == 40) {
            setTimeout(() => {
                var selectedLength = document.getElementsByClassName('Mui-selected').length;
                if (selectedLength === 0) {
                    let tabel = document.getElementById('table-main-grid-3');
                    // vIsDownKeyPressed = true;
                    // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.click();
                    // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.dblclick();
                    tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.focus();
                }
            }, 500);
        } else if (e.key == 'Enter' || e._reactName === 'onClick') {
            const result = await fSearchItem();
            if (result.length > 0) {
                let GridSearchItem = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
                setGridSelectRowData(GridSearchItem);
            }
        }
    };

    const fOpenInvoiceSalesModal = async (e) => {
        if (e.key == 'Enter' || e._reactName === 'onClick') {
            const result = await fSearchExactItem();
            if (result.length > 0) {
                setHeadObj((obj) => ({ ...obj, itmName: result[0].Name1, itmCd: result[0].ItmCd }));

            } else {
                fClearItemSearch();
                setItemSearchModalObj((obj) => ({ ...obj, vAnydata: sHeadObj.itmName }));
                setItemSearchModal(true);
            }
        }
        if (e.key == 'Backspace') {
            setHeadObj((obj) => ({
                ...obj,
                itmCd: ''
            }));
        }
    };
    return (
        <div className='reportContainer'>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ p: 1, border: '1px ridge #ededed' }}>

                    <Grid
                        container
                        spacing={1}
                        columnSpacing={2}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                        sx={{ alignItems: 'start' }}
                    >
                        <Grid item xs={11.5} sm={11.5} md={11.5}>
                            {!sHideFilter && (
                                <>
                                    <Grid
                                        container
                                        spacing={1}
                                        columnSpacing={2}
                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                        sx={{ alignItems: 'center' }}
                                        className='mb-3'>
                                        <Grid item xs={6} sm={6} md={3}>
                                            <Grid
                                                container
                                                spacing={1}
                                                columns={{ xs: 12, sm: 12, md: 12 }}
                                                sx={{ alignItems: 'center' }}>
                                                <Grid item xs={5.5} sm={4} md={4}>
                                                    <CmpTypographyFieldColor xcomponent={'span'} xText={'From Date'} />
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={1}>
                                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                </Grid>
                                                <Grid item xs={4.5} sm={4} md={4.5}>
                                                    <CmpTypographyField xcomponent={'span'} xText={moment(rdxFilterObj.dateFrom).format("DD/MM/YYYY")} />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={3}>
                                            <Grid
                                                container
                                                spacing={1}
                                                columns={{ xs: 12, sm: 12, md: 12 }}
                                                sx={{ alignItems: 'center' }}>
                                                <Grid item xs={5} sm={4} md={4}>
                                                    <CmpTypographyFieldColor xcomponent={'span'} xText={'To Date'} />
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={1}>
                                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                </Grid>
                                                <Grid item xs={4.5} sm={4} md={4.5}>
                                                    <CmpTypographyField xcomponent={'span'} xText={moment(rdxFilterObj.dateTo).format("DD/MM/YYYY")} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid
                                                container
                                                spacing={1}
                                                columns={{ xs: 12, sm: 12, md: 12 }}
                                                sx={{ alignItems: 'center' }}>
                                                <Grid item xs={2.7} sm={2} md={2}>
                                                    <CmpTypographyFieldColor xcomponent={'span'} xText={'DC Location'} />
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={.5}>
                                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                </Grid>
                                                <Grid item xs={8.3} sm={9} md={7}>
                                                    <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
                                                    {/* <CmpSelectField
                                    xLabelText="DC Location"
                                    xValue={sHeadObj.DClocation}
                                    xOnChange={(event) => onChangeDCLocation(event)}
                                    xName={'DCLocation'}
                                    xData={dcLoclist}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                    xTabIndex={'1'}
                                    xref={scanFocusOnLoad}
                                /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid
                                                container
                                                spacing={1}
                                                columns={{ xs: 12, sm: 12, md: 12 }}
                                                sx={{ alignItems: 'center' }}>
                                                <Grid item xs={2.7} sm={2} md={1}>
                                                    <CmpTypographyFieldColor xcomponent={'span'} xText={'Location'} />
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={.25}>
                                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                </Grid>

                                                <Grid item xs={8.3} sm={8} md={9}>
                                                    <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />

                                                    {/* <CmpMultiSelectAll
                                    xLabelText="Location"
                                    xValue={sHeadObj.LocList}
                                    xOnChange={(event) => onChangeLocList(event)}
                                    xName={'Location'}
                                    xData={locList.map(i => { return { id: i.UId, name: i.Name1 } })}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                    xTabIndex={'4'}
                                /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        columnSpacing={4}
                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                        sx={{ alignItems: 'center', }}
                                        className='mb-2'>
                                        <Grid item xs={12} sm={5.5} md={5.5}>
                                            <Grid
                                                container
                                                spacing={1}
                                                columnSpacing={2}
                                                columns={{ xs: 12, sm: 12, md: 12 }}
                                                sx={{ alignItems: 'center', }}
                                            >
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                                        sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2.7} sm={2} md={2.5}>
                                                            <CmpTypographyFieldColor xcomponent={'span'} xText={'Transfer Type'} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                        </Grid>
                                                        <Grid item xs={8.3} sm={9} md={8.5}>
                                                            <CmpSelectArrField
                                                                xTabIndex="1"
                                                                xLabelText="Transfer"
                                                                xValue={sHeadObj.transferTyp}
                                                                xOnChange={(event) => {
                                                                    setHeadObj((obj) => ({ ...obj, transferTyp: event.target.value }))
                                                                }}
                                                                xName={'transferTyp'}
                                                                xData={transferTypList}
                                                                xValueMember={''}
                                                                xDispalyMember={''}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={false}
                                                                xref={scanFocusOnLoad}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                                        sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2.7} sm={2} md={2.5}>
                                                            <CmpTypographyFieldColor xcomponent={'span'} xText={'Item'} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                        </Grid>
                                                        <Grid item xs={2.3} sm={2} md={2}>
                                                            <CmpSelectArrField
                                                                xTabIndex="2"
                                                                xLabelText="ItemType"
                                                                xValue={sHeadObj.itmTyp}
                                                                xOnChange={(event) => setHeadObj((obj) => ({ ...obj, itmTyp: event.target.value }))}
                                                                xName={'itmTyp'}
                                                                xData={typeList}
                                                                xValueMember={''}
                                                                xDispalyMember={''}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={false}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={3} sm={3} md={2.5}>
                                                            <CmpInputField
                                                                xTabIndex="3"
                                                                xValue={sHeadObj.itmCd}
                                                                xType={'text'}
                                                                xName={'ItmCd'}
                                                                xReadOnly={true}
                                                                xOnChange={(event) => { setHeadObj({ ...sHeadObj, itmCd: event.target.value }) }}
                                                                xError={false}
                                                                xErrorMessage={''}
                                                                xOnKeyUp={''}
                                                                xDisable={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} sm={4} md={4}>
                                                            <CmpInputFieldGroup
                                                                xTabIndex="4"
                                                                xValue={sHeadObj.itmName}
                                                                xType={'text'}
                                                                xName={'B_ItmName'}
                                                                xReadOnly={sHeadObj.itmTyp === 'All'}
                                                                xOnChange={(event) => { setHeadObj({ ...sHeadObj, itmName: event.target.value }) }}
                                                                xOnKeyUp={fOpenInvoiceSalesModal}
                                                                xError={false}
                                                                xErrorMessage={''}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                                        sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2.7} sm={2} md={2.5}>
                                                            <CmpTypographyFieldColor xcomponent={'span'} xText={'Reports'} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                        </Grid>
                                                        <Grid item xs={8.3} sm={9} md={8.5}>
                                                            <CmpSelectField
                                                                xTabIndex="4"
                                                                xLabelText="Report"
                                                                xValue={sHeadObj.reportType}
                                                                xOnChange={(event) => {
                                                                    onChangeReportType(event.target.value);
                                                                }}
                                                                xName={'Report Types'}
                                                                xData={reportTypes}
                                                                xValueMember={''}
                                                                xDispalyMember={''}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={false}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={5.5} md={5.5}>
                                            <Grid
                                                container
                                                spacing={1}
                                                columnSpacing={2}
                                                columns={{ xs: 12, sm: 12, md: 12 }}
                                                sx={{ alignItems: 'center', }}
                                            >
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                                        sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2.7} sm={2} md={2.5}>
                                                            <CmpTypographyFieldColor xcomponent={'span'} xText={'Department'} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                        </Grid>
                                                        <Grid item xs={2.3} sm={2.5} md={2.5}>
                                                            <CmpSelectArrField
                                                                xTabIndex="4"
                                                                xLabelText="mainGrpTyp"
                                                                xValue={sHeadObj.mainGrpTyp}
                                                                xOnChange={(event) => setHeadObj((obj) => ({ ...obj, mainGrpTyp: event.target.value }))}
                                                                xName={'mainGrpTyp'}
                                                                xData={typeList}
                                                                xValueMember={''}
                                                                xDispalyMember={''}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={false}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={6} sm={6.5} md={6}>
                                                            <CmpMultiSelectAll
                                                                xLabelText="Department"
                                                                xValue={sHeadObj.mainGrps}
                                                                xOnChange={(event) => onChangeDeptList(event)}
                                                                xName={'Department'}
                                                                xData={sDeptList.map(i => { return { id: i.UId, name: i.Name1 } })}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={sHeadObj.mainGrpTyp === 'All'}
                                                                xTabIndex={'5'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                                        sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2.7} sm={2} md={2.5}>
                                                            <CmpTypographyFieldColor xcomponent={'span'} xText={'Sub Department'} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                        </Grid>
                                                        <Grid item xs={2.3} sm={2.5} md={2.5}>
                                                            <CmpSelectArrField
                                                                xTabIndex="6"
                                                                xLabelText="subGrpTyp"
                                                                xValue={sHeadObj.subGrpTyp}
                                                                xOnChange={(event) => setHeadObj((obj) => ({ ...obj, subGrpTyp: event.target.value }))}
                                                                xName={'subGrpTyp'}
                                                                xData={typeList}
                                                                xValueMember={''}
                                                                xDispalyMember={''}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={false}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={6} sm={6.5} md={6}>
                                                            <CmpMultiSelectAll
                                                                xLabelText="Sub Department"
                                                                xValue={sHeadObj.subGrps}
                                                                xOnChange={(event) => onChangeSubDeptList(event)}
                                                                xName={'sub Department'}
                                                                xData={sSubDeptList}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={sHeadObj.subGrpTyp === 'All'}
                                                                xTabIndex={'7'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                                        sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2.7} sm={2} md={2.5}>
                                                            <CmpTypographyFieldColor xcomponent={'span'} xText={'Category'} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                                        </Grid>
                                                        <Grid item xs={2.3} sm={2.5} md={2.5}>
                                                            <CmpSelectArrField
                                                                xTabIndex="8"
                                                                xLabelText="Grp Type"
                                                                xValue={sHeadObj.grpTyp}
                                                                xOnChange={(event) => setHeadObj((obj) => ({ ...obj, grpTyp: event.target.value }))}
                                                                xName={'Grp Typ'}
                                                                xData={typeList}
                                                                xValueMember={''}
                                                                xDispalyMember={''}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={false}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={6} sm={6.5} md={6}>
                                                            <CmpMultiSelectAll
                                                                xLabelText="Category"
                                                                xValue={sHeadObj.grps}
                                                                xOnChange={(event) => onChangeCatList(event)}
                                                                xName={'Category'}
                                                                xData={sCatList}
                                                                xError={false}
                                                                xErrorMessage={false}
                                                                xDisable={sHeadObj.grpTyp === 'All'}
                                                                xTabIndex={'9'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} 
                                        // className='d-flex justify-content-center'
                                        >
                                            <CmpButton
                                                xvariant={'contained'}
                                                xsize={'small'}
                                                xonClick={fOnpressLoad}
                                                xLabel={'Load'}
                                            />
                                        </Grid>
                                    </Grid>

                                </>
                            )}
                        </Grid>
                        <Grid item xs={0.5} sm={0.5} md={0.5}>
                            <IconButton onClick={() => setHideFilter(!sHideFilter)} >
                                {sHideFilter ? <OpenInFull /> : <Minimize />}
                            </IconButton>
                        </Grid>

                    </Grid>

                </Box>
                <Box width='100%'
                    className="mt-2"
                    sx={{
                        height: sHideFilter ? '78vh' : '62vh',
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary'
                        },
                        '& .textPrimary': {
                            color: 'text.primary'
                        },
                        '& .Mui-error': {
                            backgroundColor: `rgb(126,10,15, 0.1})`,
                            color: '#ff4343',
                        },
                    }}>
                    <ReportGrid xRows={sDetailObj} xColumns={sGridColumns} xIsMIS={true} />
                </Box>

            </Box>
            <Modal
                open={sItemSearchModal}
                onClose={fCloseItemSearchModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="model-sales-invoice-GCVanMaster-Modal">
                    <GCItemSearch
                        fCloseItemSearch={fCloseItemSearchModal}
                        title={'Item'}
                        xOnCellKeyDown={() => { }}
                        xOnRowClick={(item) => fOnRowClickGCItemSearch(item)}
                        xItemRows={sGridSelectRowData}
                        xItemColumn={GridDataColumnsItemSearch}
                        xonChangeInputModal={fonChangeInputModal}
                        fonChangeInputModalSelect={fonChangeInputModalSelect}
                        xItemSearchModalObj={sItemSearchModalObj}
                        xfClear={fClearItemSearch}
                        xGetItemSearchValue={GetItemSearchValue}
                        sLoadingItemSearch={sLoadingItemSearch}
                        vMediaQuery={vMediaQuery}
                        xEnterObj={sEnterKeyObj}
                        vRdxDBLocationGroup={[]}
                    />
                </Box>
            </Modal>
        </div >
    )
}
