import { Close } from '@mui/icons-material'
import { Avatar, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { CmpTypographyField } from '../../../component/ToolBox/ToolBox'

import grapes from '../../../Images/grapes.jpg';

export default function BufferAllocationModal({ sSelectedRow, rowsData, setUpdateRows, setLocModal }) {

    const [sLocRows, setLocRows] = useState(rowsData.find(i => i.SrNo === sSelectedRow.SrNo).locList);
    const [sTotalAllocQty, setTotalAllocQty] = useState(0);

    console.log("locRows>>>>>" + JSON.stringify(sLocRows));

    useEffect(() => {
        let totalAllocQty = sLocRows.reduce((prevValue, i) => {
            return Number(prevValue) + Number(i.CurrAllocQtyCTN);
        }, 0);
        setTotalAllocQty(Number(totalAllocQty));
    }, [sLocRows])

    const columns = [
        {
            field: 'Loc',
            headerName: 'Loc',
            flex: 1,
            hide: true
        },
        {
            field: 'LocName',
            headerName: 'Location',
            flex: 3,
        },
        // {
        //     field: 'OrderQtyCTN',
        //     headerName: 'Order Qty.',
        //     flex: 2,
        //     align: 'right',
        //     headerAlign: 'right',
        //     valueFormatter: ({ value }) => Number(value).toFixed(2)
        // },
        {
            field: 'Qty',
            headerName: 'Pur Qty.',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            field: 'ReceivedQty',
            headerName: 'Received Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
    ];

    return (
        <Box sx={{ flexGrow: 1 }} className="m-4">

            <Grid container spacing={0}>
                <Grid item xs={12} className="d-flex justify-content-end">
                    <Close onClick={() => setLocModal(false)} />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                className="my-2 p-4"
                sx={{ alignItems: 'center' }}>
                <Grid item xs={9} sm={9} lg={9} md={9}>
                    <Grid
                        container
                        spacing={2}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={sSelectedRow.ALTItmCd} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Item Name'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={sSelectedRow.ALTItmName} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Pack Qty'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={Number(sSelectedRow.PackQty).toFixed(2)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                sx={{ alignItems: 'center' }}>
                                <Grid item xs={5} sm={5} lg={3} md={3}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Purchased Qty'} />
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={6} sm={6} lg={8} md={8}>
                                    <CmpTypographyField xcomponent={'span'} xText={Number(sSelectedRow.Qty).toFixed(2)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={3} sm={3} lg={3} md={3}>
                    <Avatar
                        alt="Remy Sharp"
                        src={grapes}
                        sx={{ width: 80, height: 80, border: '2px solid #0d73bb' }}
                    />

                </Grid> */}

            </Grid>

            <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mt-3'>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Box
                        sx={{
                            height: '35vh',
                            width: '100%',
                            '& .actions': {
                                color: 'text.secondary'
                            },
                            '& .textPrimary': {
                                color: 'text.primary'
                            }
                        }}>
                        <DataGrid
                            columns={columns}
                            rows={sLocRows}
                            getRowId={(row) => row.Loc}
                            rowHeight={45}
                            headerHeight={50}
                            hideFooter={true}
                            sx={{
                                border: 2,
                                borderColor: 'primary.light',
                            }}
                            componentsProps={{
                                filterPanel: { sx: { maxWidth: "93vw" } }
                              }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                            }

                        />
                        <div className='p-3' style={{ height: '3vh', display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                            <div style={{ flex: 3, textAlign: 'left' }}><CmpTypographyField xText='Total' /></div>
                            <div style={{ flex: 2 }}><CmpTypographyField xText={Number(sSelectedRow.ReceivedQty).toFixed(2)} /></div>
                        </div>
                    </Box >
                </Grid >
            </Grid >

        </Box >
    )
}
