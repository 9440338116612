import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
//import './returnRequest.css';
import { ArrowForwardIos } from '@mui/icons-material';
function DCReceipt_Breadcrumbs() {
  const location = useLocation();
  const vPathName = location.pathname;
  return (
    <div className="container-fluid p-0 breadcrumb">
      {vPathName === '/DCReceipt' ? (
        <div>
          <span className="breadcrumb-arrow-inactive ">
          DC Receipt{' '}
          </span>
        </div>
      ) : vPathName === '/DCReceiptDetail'? (
        <div className="d-flex">
          <Link
            to="/DCReceipt"
            state={location}
            className={'breadcrumb-arrow-active mr-2'}>
              DC Receipt
          </Link>
          <div>
            <span className="breadcrumb-arrow-inactive">
            DC Receipt Detail{' '}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default DCReceipt_Breadcrumbs;
