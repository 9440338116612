import { fExecuteAPI } from '../../Server/Apis';

const getDCConfirmationSummaryList = async (xObj) => {
  try {
    const vDCCOnfirmationList = await fExecuteAPI('GET_DC_CONFIRMATION_SUMMARY_LIST', xObj);
    return vDCCOnfirmationList;
  } catch (error) {}
};

const getDCConfirmationItemList = async (xObj) => {
  try {
    const vDCConfirmationItemList = await fExecuteAPI('GET_DC_CONFIRMATION_ITEM_LIST', xObj);
    return vDCConfirmationItemList;
  } catch (error) {}
};

const updateDCConfirmationItem = async (xObj) => {
  try {
    const vUpdateDCCOnfirmationItem = await fExecuteAPI('UPDATE_DC_CONFIRMATION_ITEM_LIST', xObj);
    return vUpdateDCCOnfirmationItem;
  } catch (error) {}
};

const getTaxDeptList = async (xObj) => {
  try {
    const vTaxDeptList = await fExecuteAPI('GET_TAX_DEPT_LIST', xObj);
    return vTaxDeptList.data;
  } catch (error) {}
};

export {
  getDCConfirmationSummaryList,
  getDCConfirmationItemList,
  updateDCConfirmationItem,
  getTaxDeptList
};
