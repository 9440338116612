import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transHead: {
    TransId: '',
    OrderId: '',
    Location: '',
    LocationName: '',
    Date: new Date(),
    Vendor: '',
    VendorName: '',
    ReferenceNo: '',
    ReferenceDate: new Date(),
    Status: 'ENTER',
    NetAmt: 0,
    DriverName: '',
    Parking: '',
    invoiceAmt: 0,
    Paymode: 'CREDIT',
    State: 'OPEN',
    Rounding: 0,
    MarketPurchase: 'Y',
    TRNS_Vendor_Os: 0,
    TRNS_Vendor_Os_Adj: 'N',
    TRNS_Vendor_Reduction: 0,
    TRNS_Confirm_Inv: 'N',
    TRNS_Direct_Deli: 'N',
    TaxPurchase: 'N',
    TRNS_PARTY_MOBILE: ''
  },
  orderHead: {
    orderId: 0,
    status: 'ENTER',
    orderDate: new Date(),
    purCompletion: 0,
    purAccuracy: 0,
    deviation: 0,
    costDeviation: 0,
    marketPurchase: 'Y',
    purchaseCount: 0
  }
};
const FishPurchaseSlice = createSlice({
  name: 'FishPurchaseSlice',
  initialState: initialState,
  reducers: {
    updateTransHead: (state, action) => {
      state.transHead = action.payload;
    },
    updateOrderHead: (state, action) => {
      state.orderHead = action.payload;
    },
    updateOrderHeadSatus: (state, action) => {
      state.orderHead.status = action.payload;
    }
  }
});

export const { updateTransHead, updateOrderHead, updateOrderHeadSatus } = FishPurchaseSlice.actions;
export default FishPurchaseSlice.reducer;
