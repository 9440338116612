import { Box, Grid } from '@mui/material';
import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import RadialSeparators from '../../../component/ReuseableComponent/RadialSeparators';
import { CmpTypographyField } from '../../../component/ToolBox/ToolBox';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export default function PendingOrderCard({
  xOrdId,
  xPurcompletion,
  xPurAccuracy,
  xDeviation,
  xCostDeviation,
  xPurchaseCount,
  xOrdDate,
  xMarketPurchase,
  xctrpuchase = '',
  xDCPurchase = 'N'
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card-2">
      <Grid container spacing={2}>
        <Grid item xs={12} align="center">
          <CmpTypographyField xVariant="h3" xText={xOrdId} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={9} align="left">
          <CmpTypographyField
            xcomponent={'span'}
            xText={xPurcompletion + '% of purchase Completion'}
          />
          <CmpTypographyField xcomponent={'span'} xText={xPurAccuracy + '% of Accuracy purchase'} />
          <CmpTypographyField xcomponent={'span'} xText={xDeviation + ' of deviation'} />
          <CmpTypographyField xcomponent={'span'} xText={xCostDeviation + ' of cost deviation'} />
          <CmpTypographyField
            xcomponent={'span'}
            xText={'No of purchase invoice :' + xPurchaseCount}
          />
          <CmpTypographyField xcomponent={'span'} xText={xOrdDate} />
          <CmpTypographyField
            xcomponent={'span'}
            xText={xMarketPurchase === 'Y' ? 'Market Purchase' : 'Outlet Purchase'}
          />
          <CmpTypographyField xcomponent={'span'} xText={xctrpuchase} />
        </Grid>
        <Grid item xs={3} align="center">
          <div style={{ maxWidth: '6rem', margin: '1.5rem auto' }}>
            <CircularProgressbarWithChildren
              value={xPurcompletion}
              text={`${xPurcompletion}%`}
              strokeWidth={10}
              styles={buildStyles({
                strokeLinecap: 'butt'
              })}>
              <RadialSeparators
                count={12}
                style={{
                  background: '#fff',
                  width: '1px',
                  height: `${10}%`
                }}
              />
            </CircularProgressbarWithChildren>
          </div>
          {xDCPurchase === 'Y' && (
            <WarehouseIcon
              style={{
                color: 'green'
              }}
              fontSize="small"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
