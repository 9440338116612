import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import '../../component/ToolBox/ToolBox.css';
import { CSVLink, CSVDownload } from 'react-csv';
import { ToastContainer } from 'react-toastify';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpCheckboxField,
  MUIDataGrid,
  CmpSelectArrField,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import { getUnloadBatchCreate, getUnloadBatchSummary, getUnloadBatchSummaryAttr } from './actions';
import UnLoadNewBatch from './ProcessComponents/UnLoadNewBatch';
import { getGitterColumns, fAlertToast } from '../../Utility/Utilitys';
import moment from 'moment';
import UnloadBreadCrumbs from './ProcessComponents/UnloadBreadCrumbs';
import { updateBatchInfo, updateBatchList } from './OutletRequestSlice';

const gridItemSpacing = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0.2
};
const borderBox = {
  height: '75vh',
  margin: 2
};
const alignContent = {
  paddingTop: '9px'
};
const loadGridColSample = [
  {
    field: 'BatchNo',
    headerName: 'Batch.No',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Truck',
    headerName: 'Truck',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TruckName',
    headerName: 'Truck.Name',
    hide: false,
    width: 180,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Date',
    headerName: 'Date',
    hide: false,
    width: 100,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Loc',
    headerName: 'Loc',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'LocName',
    headerName: 'Location',
    hide: false,
    width: 180,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Status',
    headerName: 'Status',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'id',
    headerName: 'id',
    hide: false,
    width: 40,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  }
];

const UnLoading = () => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const xMediaQuery = useMediaQuery('(max-width:850px)');
  const rdxuser = useSelector((state) => state.SliceDB.value.login);
  const batchList = useSelector((state) => state.OutletRequestSlice.value.batchList);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [headObj, setHeadObj] = useState({
    fromDt: moment().startOf('month'),
    toDT: new Date(),
    locCd: '',
    locNam: '',
    location: [],
    truckNam: '',
    truckCd: '',
    statusNam: '',
    statusCd: '',
    status: [],
    truck: [],
    type: []
  });
  const [openNewBatch, setOpenNewbatch] = useState(false);
  const [loadGridRow, setLoadGridRow] = useState(batchList);
  const [loadGridCol, setLoadGridCol] = useState(loadGridColSample);

  const onChangeFromDate = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: newValue.$d
      }));
    }
  };

  const onChangeDateTo = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        toDT: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        toDT: newValue.$d
      }));
    }
  };

  const onChangeLoc = (event) => {
    const data = headObj.location.find((x) => x.Name1 === event.target.value);
    setHeadObj((obj) => ({
      ...obj,
      locNam: data.Name1,
      locCd: data.UId
    }));
  };
  const onChangeStatus = (event) => {
    const data = headObj.status.find((x) => x.Name1 === event.target.value);
    setHeadObj((obj) => ({
      ...obj,
      statusNam: data.Name1,
      statusCd: data.UId
    }));
  };
  const onChangeVan = (event) => {
    const data = headObj.truck.find((x) => x.Name1 === event.target.value);
    setHeadObj((obj) => ({
      ...obj,
      truckNam: data.Name1,
      truckCd: data.Uid
    }));
  };

  const onLoadClick = async (xloc, xfrmD, xtoD, xstat, xtruck) => {
    const obj = {
      v_Loc: xloc,
      v_Date1: moment(xfrmD).format('MM/DD/YYYY'),
      v_Date2: moment(xtoD).format('MM/DD/YYYY'),
      v_Status: xstat,
      v_Truck: xtruck
    };
    const result = await getUnloadBatchSummary(obj);
    setLoadGridRow(result?.ResultSet);
  };
  const createNewBatch = async (xdata) => {
    console.log(xdata);
    const items = xdata.map((x) => {
      return {
        VAN_TRNID: x?.VAN_TRNID,
        VAN_REFID: x?.VAN_REFID,
        VAN_TRUCK_ID: x?.VAN_TRUCK_ID,
        TRNS_LOC: x?.TRNS_LOC,
        A_VocTyp: x?.A_VocTyp,
        A_VocNo: x?.A_VocNo
      };
    });
    const obj = {
      usercd: rdxuser.userId,
      LineItem: items
    };
    const result = await getUnloadBatchCreate(obj);
    if (result?.Type === 'Success') {
      setOpenNewbatch(false);
      // setHeadObj((obj) => ({
      //   ...obj,
      //   fromDt: moment().startOf('month'),
      //   toDT: new Date(),
      //   truckCd: headObj?.truck[0]?.Uid,
      //   truckNam: headObj?.truck[0]?.Name1,
      //   statusCd: headObj?.status[0]?.UId,
      //   statusNam: headObj?.status[0]?.Name1
      // }));
      onLoadClick(
        headObj?.locCd,
        headObj?.fromDt,
        headObj?.toDT,
        headObj?.statusCd,
        headObj?.truckCd
      );
    }
  };

  const onRowClick = (item) => {
    console.log('sss', updateBatchInfo(item?.row));
    dispatch(updateBatchInfo(item?.row));
    dispatch(updateBatchList(loadGridRow));
    navigate('/UnLoadingDetail');
  };
  console.log(loadGridRow, 'loadGridRow');

  useEffect(() => {
    const fLoadSummaryAttr = async () => {
      const obj = {
        userCd: rdxuser.userId
      };
      const result = await getUnloadBatchSummaryAttr(obj);
      setHeadObj((obj) => ({
        ...obj,
        locCd: result?.ResultSet?.loc[0]?.UId,
        locNam: result?.ResultSet?.loc[0]?.Name1,
        location: result?.ResultSet?.loc,
        truckCd: result?.ResultSet?.Truck[0]?.Uid,
        truckNam: result?.ResultSet?.Truck[0]?.Name1,
        truck: result?.ResultSet?.Truck,
        statusCd: result?.ResultSet?.Status[0]?.UId,
        statusNam: result?.ResultSet?.Status[0]?.Name1,
        status: result?.ResultSet?.Status
      }));
      onLoadClick(
        result?.ResultSet?.loc[0]?.UId,
        headObj.fromDt,
        headObj.toDT,
        result?.ResultSet?.Status[0]?.UId,
        result?.ResultSet?.Truck[0]?.Uid
      );
    };
    const fgetColumns = async () => {
      const payload = {
        trnTyp: 'UNLODBTCHSUMRY'
      };
      const result = await getGitterColumns(payload);
      setLoadGridCol(result?.ResultSet?.columns);
    };
    fLoadSummaryAttr();
    fgetColumns();
  }, []);

  return (
    <div className="container">
      <div>
        <UnloadBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ paddingX: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <CmpTypographyField xcomponent={'span'} xText={'Location'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              sx={{
                '& .MuiMenu-list': {
                  height: '15rem !important'
                }
              }}>
              <CmpSelectArrField
                xLabelText={'Location'}
                xValue={headObj?.locNam}
                xOnChange={onChangeLoc}
                xName={'Location'}
                xData={headObj?.location?.map((x) => x?.Name1)}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={''}
                xDisable={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} sx={{ padding: '5px' }}>
          <Box
            style={
              vMediaQuery
                ? {}
                : {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }
            }>
            <Grid container>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} xVariant={'subtitle'} />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8} sm={7.8} md={7.8} lg={7.8} sx={vMediaQuery ? {} : gridItemSpacing}>
                <CmpDatePickerField
                  xValue={headObj?.fromDt}
                  xOnChange={(event) => onChangeFromDate(event)}
                  xName={'fromDt'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'1'}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} xVariant={'subtitle'} />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8} sm={7.8} md={7.8} lg={7.8} sx={vMediaQuery ? {} : gridItemSpacing}>
                <CmpDatePickerField
                  xValue={headObj?.toDT}
                  xOnChange={(event) => onChangeDateTo(event)}
                  xName={'toDT'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ padding: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <CmpTypographyField xcomponent={'span'} xText={'Status'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <CmpSelectArrField
                xLabelText={'status'}
                xValue={headObj?.statusNam}
                xOnChange={onChangeStatus}
                xName={'status'}
                xData={headObj?.status?.map((x) => x?.Name1)}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={''}
                xDisable={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ padding: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={3} lg={3} paddingLeft={xMediaQuery ? '' : '18px'}>
              <CmpTypographyField xcomponent={'span'} xText={'Van'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={vMediaQuery ? {} : gridItemSpacing}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} sx={vMediaQuery ? {} : gridItemSpacing}>
              <CmpSelectArrField
                xLabelText={'van'}
                xValue={headObj?.truckNam}
                xOnChange={onChangeVan}
                xName={'van'}
                xData={headObj?.truck?.map((x) => x?.Name1)}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={''}
                xDisable={false}
                xheight={'15rem'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ padding: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: vMediaQuery ? 'center' : 'end',
              alignItems: 'center'
            }}>
            <Grid xs={5} sm={6} md={4} lg={4} sx={{ px: '10px' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'small'}
                xonClick={() => {
                  onLoadClick(
                    headObj?.locCd,
                    headObj?.fromDt,
                    headObj?.toDT,
                    headObj?.statusCd,
                    headObj?.truckCd
                  );
                }}
                xLabel={'Load'}
                xIcon={''}
                xDisable={false}
              />
            </Grid>
            <Grid xs={5} sm={6} md={4} lg={4} sx={{ px: '10px' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'small'}
                xonClick={() => {
                  setOpenNewbatch(true);
                }}
                xLabel={'New Batch'}
                xIcon={''}
                xDisable={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} sm={12} md={12} lg={12} sx={borderBox}>
          <MUIDataGrid
            xRows={loadGridRow}
            xColumns={loadGridCol}
            xonRowClick={onRowClick}
            enableCheckbox={false}
            borderColorChange={true}
          />
        </Grid>
      </Grid>
      {openNewBatch && (
        <UnLoadNewBatch
          xOpen={openNewBatch}
          xTitle={'UnLoading'}
          fCloseModal={() => setOpenNewbatch(false)}
          fCreateNewBatch={createNewBatch}
          headObj={headObj}
        />
      )}
    </div>
  );
};
export default UnLoading;
