import React from 'react';
import { DataGrid, GridFooter, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';


export default function ReportGrid({ xRows, xColumns }) {

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={xRows}
                columns={xColumns}
                rowHeight={30}
                headerHeight={50}
                sx={{
                    border: 0,
                    borderColor: 'primary.light',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    '& .MuiDataGrid-row:hover': {
                        color: 'primary.main',
                        cursor: 'pointer',
                        fontWeight: 500,
                    },

                }}
                componentsProps={{
                    filterPanel: { sx: { maxWidth: "93vw" } }
                  }}
                components={{
                    Toolbar: GridToolbar,
                    Footer: GridFooter

                }}

                getRowClassName={(params) =>
                    'mui-report-grid-row'
                }

            />
        </Box>
    )
}
