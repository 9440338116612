export const GridcolumnsReview = [
  {
    field: 'A_TransId',
    headerName: 'TransId',
    width: 80,
    hide: false
  },
  {
    field: 'A_PostDt',
    headerName: 'Date',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  // {
  //   field: 'A_Loc',
  //   headerName: 'Location',
  //   width: 300,
  //   hide: true
  // },
  {
    field: 'LocName',
    headerName: 'Location',
    width: 100
  },
  // {
  //   field: 'A_DCLoc',
  //   headerName: 'A_DCLoc',
  //   width: 150,
  //   align: 'center',
  //   headerAlign: 'center',
  //   hide: true
  // },
  {
    field: 'DCLocName',
    headerName: 'DC LocName',
    width: 150
  },
  {
    field: 'A_Type',
    headerName: 'Type',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'A_DCStatus',
    headerName: 'Status',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  }
];

export const GridcolumnsReviewDetails = [
  {
    field: 'Srno',
    headerName: 'SrNo',
    width: 150
  },
  {
    field: 'itmcd',
    headerName: 'ItemCode',
    width: 350
  },
  {
    field: 'ItmName',
    headerName: 'ItemName',
    width: 400
  },
  {
    field: 'Qty',
    headerName: 'Qty',
    width: 100
  },
  {
    field: 'Unit',
    headerName: 'Unit',
    width: 100
  },
  {
    field: 'BaseQty',
    headerName: 'BaseQty',
    width: 100
  },
  {
    field: 'UnitCost',
    headerName: 'Unit Cost',
    width: 100
  },
  {
    field: 'netAmnt',
    headerName: 'Net Amount',
    width: 100
  }
];
