import { fExecuteAPI } from '../../Server/Apis';
import moment from 'moment';

export const getReturnSummaryReport = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RETURN_SUMMARY_REPORT', xObj);
    return result.data;
  } catch (error) {}
};

export const getDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getSubDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_SUBDEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getCategoryList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_CATOGORY_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getExactSearchItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RETURN_REQUEST_EXACT_SEARCH_ITEM', xObj);
    return result.data;
  } catch (error) {}
};
export const searchItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RETURN_REQUEST_SEARCH_ITEM', xObj);
    return result.data;
  } catch (error) {}
};

export const getMISReport = async (xObj, xType) => {
  try {
    let trnTyp = '';
    switch (xType) {
      case 'ITMWISE': {
        trnTyp = 'GET_RETURN_REPORT_ITMWISE';
        break;
      }
      case 'DATEWISE': {
        trnTyp = 'GET_RETURN_REPORT_DATEWISE';
        break;
      }
      case 'DOCWISE': {
        trnTyp = 'GET_RETURN_REPORT_DOCWISE';
        break;
      }
      case 'DEPTWISE': {
        trnTyp = 'GET_RETURN_REPORT_DEPTWISE';
        break;
      }
      case 'SUBDEPTWISE': {
        trnTyp = 'GET_RETURN_REPORT_SUBDEPTWISE';
        break;
      }
      case 'CATWISE': {
        trnTyp = 'GET_RETURN_REPORT_CATWISE';
        break;
      }
      case 'LOCWISE': {
        trnTyp = 'GET_RETURN_REPORT_LOCWISE';
        break;
      }

      default:
        break;
    }
    const result = await fExecuteAPI(trnTyp, xObj);
    return result.data;
  } catch (error) {}
};

export const getReportColumns = (type) => {
  let columns = [];
  switch (type) {
    case 'REQUEST': {
      columns = [
        {
          field: 'A_TransId',
          headerName: 'Trans Id',
          flex: 1.5,
          align: 'center'
        },
        {
          field: 'A_PostDt',
          headerName: 'Date',
          flex: 1.5,
          valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
        },
        {
          field: 'LocName',
          headerName: 'Location',
          flex: 2
        },
        {
          field: 'B_ItmCd',
          headerName: 'Item',
          flex: 1,
          hide: true
        },
        {
          field: 'ItemName',
          headerName: 'Item Name',
          flex: 3
        },

        {
          field: 'Vendorname',
          headerName: 'Vendor',
          flex: 2,
          hide: true
        },
        {
          field: 'B_BaseQty',
          headerName: 'Req. Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_UnitRate',
          headerName: 'Req.Unit Rate',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_NetAmt',
          headerName: 'Req. NetAmt',
          flex: 1,
          align: 'right',
          hide: true,
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_Acc_BaseQty',
          headerName: 'Accept Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'A_DCStatus',
          headerName: 'DC Status',
          flex: 1,
          hide: true
        },
        {
          field: 'B_Pick_Qty',
          headerName: 'Pick Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en'),
          hide: true
        },
        {
          field: 'A_PickStatus',
          headerName: 'Pick Status',
          flex: 1,
          hide: true
        },
        {
          field: 'B_Disp_Qty',
          headerName: 'Disp Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'A_DispatchStatus',
          headerName: 'Disp Status',
          flex: 1
        },
        {
          field: 'B_Conf_Qty',
          headerName: 'Conf. Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_Conf_UnitRate',
          headerName: 'Unit Rate',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_Conf_NetAmt',
          headerName: 'Net Amt',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'A_ConfirmStatus',
          headerName: 'Conf. Status',
          flex: 1
        }
      ];
      break;
    }
    case 'REQUEST_BR': {
      columns = [
        {
          field: 'A_TransId',
          headerName: 'Trans Id',
          flex: 1.5,
          align: 'center'
        },
        {
          field: 'A_PostDt',
          headerName: 'Date',
          flex: 1.5,
          valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
        },
        {
          field: 'LocName',
          headerName: 'Location',
          flex: 2
        },
        {
          field: 'B_ItmCd',
          headerName: 'Item',
          flex: 1,
          hide: true
        },
        {
          field: 'ItemName',
          headerName: 'Item Name',
          flex: 3
        },

        {
          field: 'Vendorname',
          headerName: 'Vendor',
          flex: 2,
          hide: true
        },
        {
          field: 'B_BaseQty',
          headerName: 'Req. Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_UnitRate',
          headerName: 'Req.Unit Rate',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_NetAmt',
          headerName: 'Req. NetAmt',
          flex: 1,
          align: 'right',
          hide: true,
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_Acc_BaseQty',
          headerName: 'Accept Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'A_DCStatus',
          headerName: 'DC Status',
          flex: 1,
          hide: true
        },
        {
          field: 'B_Pick_Qty',
          headerName: 'Pick Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en'),
          hide: true
        },
        {
          field: 'A_PickStatus',
          headerName: 'Pick Status',
          flex: 1,
          hide: true
        },
        {
          field: 'B_Disp_Qty',
          headerName: 'Disp Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'A_DispatchStatus',
          headerName: 'Disp Status',
          flex: 1
        },
        {
          field: 'B_Conf_Qty',
          headerName: 'Conf. Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'A_ConfirmStatus',
          headerName: 'Conf. Status',
          flex: 1
        }
      ];
      break;
    }
    case 'ITMWISE': {
      columns = [
        {
          field: 'B_Itmcd',
          headerName: 'Item',
          flex: 1
        },
        {
          field: 'ItemName',
          headerName: 'Item Name',
          flex: 2
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    case 'DEPTWISE': {
      columns = [
        {
          field: 'id',
          headerName: 'Dept. code',
          flex: 1,
          hide: true
        },
        {
          field: 'DeptName',
          headerName: 'Department',
          flex: 3
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    case 'SUBDEPTWISE': {
      columns = [
        {
          field: 'id',
          headerName: 'Sub Dept. Code',
          flex: 1,
          hide: true
        },
        {
          field: 'SubDeptName',
          headerName: 'Sub Department',
          flex: 3
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    case 'CATWISE': {
      columns = [
        {
          field: 'id',
          headerName: 'Cat. Code',
          flex: 1,
          hide: true
        },
        {
          field: 'CategoryName',
          headerName: 'Category',
          flex: 3
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    case 'DOCWISE': {
      columns = [
        // {
        //   field: 'A_VocTyp',
        //   headerName: 'Doc Type',
        //   flex: 1,
        //   hide: true
        // },
        {
          field: 'VocName',
          headerName: 'Document',
          flex: 2
        },
        {
          field: 'VocNo',
          headerName: 'Document no.',
          flex: 1
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    case 'DATEWISE': {
      columns = [
        {
          field: 'A_PostDt',
          headerName: 'Post Date',
          flex: 3,
          align: 'center',
          valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    case 'LOCWISE': {
      columns = [
        {
          field: 'LocName',
          headerName: 'Location',
          flex: 3
        },
        {
          field: 'Qty',
          headerName: 'Qty',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Sales',
          headerName: 'Sales',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'B_TaxAmt',
          headerName: 'Tax',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'COGS',
          headerName: 'COGS',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GP',
          headerName: 'GP',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'GPPerc',
          headerName: 'GP %',
          flex: 1,
          align: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    default:
      break;
  }
  let result = columns.map((i) => {
    return { ...i, headerAlign: 'center', headerClassName: 'mui-report-grid-header' };
  });
  return result;
};

export const GridDataColumnsItemSearch = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'ItmCd', headerName: 'ItmCd', width: 90 },
  { field: 'Name1', headerName: 'Name1', width: 250 },
  { field: 'NameFL', headerName: 'NameFL', width: 90 },
  { field: 'MainGrpName', headerName: 'MainGrpName', width: 90 },
  { field: 'SuppRefNo1', headerName: 'SuppRefNo1', width: 90 },
  { field: 'Model', headerName: 'Model', width: 90 },
  { field: 'BaseUnitName', headerName: 'BaseUnitName', width: 90 },
  { field: 'BarCode', headerName: 'BarCode', width: 90 }
];
