import { fExecuteAPI } from '../../Server/Apis';
import moment from 'moment';
import { httpRequest } from '../../Server/Axios';

export const getDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};
export const getDepartmentListOutlet = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST_OUTLET', xObj);
    return result.data;
  } catch (error) {}
};

export const getSubDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_SUBDEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getCategoryList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_CATOGORY_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getExactSearchItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RETURN_REQUEST_EXACT_SEARCH_ITEM', xObj);
    return result.data;
  } catch (error) {}
};
export const searchItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RETURN_REQUEST_SEARCH_ITEM', xObj);
    return result.data;
  } catch (error) {}
};

export const getReport = async (xObj, xType) => {
  try {
    let trnTyp = '';
    switch (xType) {
      case 'ITMWISE': {
        trnTyp = 'GET_OUTLET_RQT_REPORT_ITMWISE';
        break;
      }
      case 'LOCWISE': {
        trnTyp = 'GET_OUTLET_RQT_REPORT_LOCWISE';
        break;
      }

      default:
        break;
    }
    const result = await fExecuteAPI(trnTyp, xObj);
    return result.data;
  } catch (error) {}
};

export const getReportColumns = (type) => {
  let columns = [];
  switch (type) {
    case 'ITMWISE': {
      columns = [
        {
          field: 'B_ItmCd',
          headerName: 'Item Code',
          flex: 1
        },
        {
          field: 'ItemName',
          headerName: 'Item Name',
          flex: 3
        },
        {
          field: 'Requested',
          headerName: 'Requested',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Delivered',
          headerName: 'Delivered',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'balance',
          headerName: 'Balance',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Percentage',
          headerName: '%',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }

    case 'LOCWISE': {
      columns = [
        {
          field: 'B_ItmCd',
          headerName: 'Item Code',
          flex: 1
        },
        {
          field: 'ItemName',
          headerName: 'Item Name',
          flex: 3
        },
        {
          field: 'LocName',
          headerName: 'Location',
          flex: 2
        },
        {
          field: 'Requested',
          headerName: 'Requested',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Delivered',
          headerName: 'Delivered',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'balance',
          headerName: 'Balance',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
          field: 'Percentage',
          headerName: '%',
          flex: 1,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        }
      ];
      break;
    }
    default:
      break;
  }
  let result = columns.map((i) => {
    return { ...i, headerClassName: 'mui-outlet-report-grid-header' };
  });
  return result;
};

export const GridDataColumnsItemSearch = [
  // { field: 'id', headerName: 'ID', width: 70 },
  { field: 'ItmCd', headerName: 'ItmCd', width: 90 },
  { field: 'Name1', headerName: 'Name1', width: 250 },
  { field: 'NameFL', headerName: 'NameFL', width: 90 },
  { field: 'MainGrpName', headerName: 'MainGrpName', width: 90 },
  { field: 'SuppRefNo1', headerName: 'SuppRefNo1', width: 90 },
  { field: 'Model', headerName: 'Model', width: 90 },
  { field: 'BaseUnitName', headerName: 'BaseUnitName', width: 90 },
  { field: 'BarCode', headerName: 'BarCode', width: 90 }
];

export const getRequestSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_RQST_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};

export const getVoucherList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_RQST_VOUCHERS', xObj);
    return result.data;
  } catch (error) {}
};

export const getFPDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getDaysList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_RQST_DAYS_LIST', xObj);
    return result.data;
  } catch (error) {}
};

export const getFPRequestItemList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_RQST_ITM_LIST', xObj);
    return result.data;
  } catch (error) {}
};
export const saveFPRequestHead = async (xObj) => {
  try {
    const result = await fExecuteAPI('UPDATE_FP_RQT_HEAD', xObj);
    return result;
  } catch (error) {}
};
export const generateFPRequestItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('GENERT_FP_RQT_ITM', xObj);
    return result.data;
  } catch (error) {}
};
export const updateFPRequestItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('UPDATE_FP_RQT_ITM', xObj);
    return result.data;
  } catch (error) {}
};

export const releaseFPRequestItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('RELEASE_FP_RQT_ITM', xObj);
    return result;
  } catch (error) {}
};

export const validateFPRequestHead = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_RQT_VALIDT_HEAD', xObj);
    return result;
  } catch (error) {}
};

export const postFPRequestHead = async (xObj) => {
  try {
    const result = await fExecuteAPI('POST_FP_RQT_ITM', xObj);
    return result;
  } catch (error) {}
};
export const deleteFPRequestHead = async (xObj) => {
  try {
    const result = await fExecuteAPI('DELETE_FP_RQT_HEAD', xObj);
    return result;
  } catch (error) {}
};
export const deleteFPRequestItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('DELETE_FP_RQT_ITM', xObj);
    return result;
  } catch (error) {}
};

export const getFPRequestDocRights = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_RQT_DOC_RIGHTS', xObj);
    return result;
  } catch (error) {}
};
export const reverseFPRequest = async (xObj) => {
  try {
    const result = await fExecuteAPI('REVERSE_FP_RQT', xObj);
    return result;
  } catch (error) {}
};
export const getAuditFields = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_RQT_AUDIT_FLD', xObj);
    return result.data;
  } catch (error) {}
};
export const getAuditValues = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_RQT_AUDIT_VALUES', xObj);
    return result.data;
  } catch (error) {}
};

export const getFPConsAttributes = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_ATTRIBUTES', xObj);
    return result.data;
  } catch (error) {}
};
export const getRequestTransferSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RQST_TSR_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};

export const getFPConsSaveHead = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_SAVE_HEAD', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsRequestDocs = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_REQUEST_DOCS', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsLoadDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_LOAD_DETAIL', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsDisplay = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_DISPLAY', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsRelease = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_RELEASE', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsDelete = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_DELETE', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsApprove = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_APPROVE', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsReverse = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_REVERSE', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsSearch = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONS_SEARCH', xObj);
    return result.data;
  } catch (error) {}
};
export const getRequestTransferItemList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RQST_TSR_ITMLIST', xObj);
    return result.data;
  } catch (error) {}
};

export const saveRequestTransfer = async (xObj) => {
  try {
    const result = await fExecuteAPI('SAVE_RQST_TSR_ITMS', xObj);
    return result;
  } catch (error) {}
};
export const postRequestTransfer = async (xObj) => {
  try {
    const result = await fExecuteAPI('POST_RQST_TSR', xObj);
    return result;
  } catch (error) {}
};

export const getItemsUnitDetails = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RQST_TSR_UNIT_DETAILS', xObj);
    return result.data;
  } catch (error) {}
};

export const getFPConsPostAttr = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FPCONS_POST_ATTR', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsPostLoadDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FPCONS_POST_LOAD_DETAIL', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsAppAttr = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FPCONS_APP_ATTR', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsAppDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FPCONS_APP_DETAIL', xObj);
    return result.data;
  } catch (error) {}
};
export const getFPConsPost = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FPCONS_POST', xObj);
    return result.data;
  } catch (error) {}
};

export const getDCMarginAttr = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_DCMAR_ATTR', xObj);
    return result.data;
  } catch (error) {}
};
export const getDCMarginLoadDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_DCMAR_LOAD_DETAIL', xObj);
    return result.data;
  } catch (error) {}
};

export const getDCMarginRSPPost = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FP_DCMAR_RSP_POST', xObj);
    return result.data;
  } catch (error) {}
};
export const getDCMarginRSPAttr = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DCMAR_RSP_ATTR', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchAttr = async () => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_ATTR');
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_DETAIL', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchCreate = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_BATCH_CREATE', xObj);
    return result.data;
  } catch (error) {}
};

export const getUnloadBatchSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchSummaryAttr = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_SUMMARY_ATTR', xObj);
    return result.data;
  } catch (error) {}
};
//
export const getUnloadBatchDetailSum = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_DETAIL_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchDetailAppr = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_DETAIL_APPR', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchDetailSave = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_DETAIL_SAVE', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadBatchDetailDel = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_DETAIL_DEL', xObj);
    return result.data;
  } catch (error) {}
};

export const getUnloadBatchDetailGrid = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_DETAIL_GRID', xObj);
    return result.data;
  } catch (error) {}
};

export const getMissingBranch = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_MISSING_BRANCH', xObj);
    return result.data;
  } catch (error) {}
};

export const getStkTransItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_STK_TRANS_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const getStkTransItemSearch = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_STK_TRANS_ITEM_SEARCH', xObj);
    return result.data;
  } catch (error) {}
};

export const deleteStkTransItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('DELETE_STK_TRANS_ITEM', xObj);
    return result.data;
  } catch (error) {}
};
export const tsrPackQtyApi = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_TSR_PACKQTY_GETROW', xObj);
    return result.data;
  } catch (error) {}
};

export const getConsolidatedLocItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_CONSOLIDATE_LOCITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const saveConsolidatedLocItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('SAVE_CONSOLIDATE_LOCITEMS', xObj);
    return result.data;
  } catch (error) {}
};
export const getUnloadingBatchItemPurDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_UNLOAD_BATCH_ITEM_PURDETAIL', xObj);
    return result.data;
  } catch (error) {}
};
