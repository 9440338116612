import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SaveIcon from '@mui/icons-material/Save';
import VerifiedIcon from '@mui/icons-material/Verified';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import '../../component/ToolBox/ToolBox.css';
import Divider from '@mui/material/Divider';
import GCAudit from '../../component/ReuseableComponent/GCAudit';
// import GCSearch from '../../component/ReuseableComponent/GCSearch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import {
  CmpSelectArrField,
  CmpTypographyField,
  CmpDatePickerField,
  CmpInputField,
  CmpCheckboxField,
  // CmpButton,
  MUIDataGrid,
  // CmpSelectArrFieldwithGrid,
  MuiDataGridWithSearch,
  CmpButtonWithIcon,
  CmpStatusDiv,
  CmpSelectField
} from '../../component/ToolBox/ToolBox';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { fCheck_Doc_Rights_001, getGeoLocation, getGitterColumns } from '../../Utility/Utilitys';
import {
  getFPConsAttributes,
  getFPConsSaveHead,
  getFPConsRequestDocs,
  getFPConsLoadDetail,
  getFPConsRelease,
  getFPConsDisplay,
  getFPConsApprove,
  getFPConsDelete,
  getFPConsReverse,
  getAuditValues,
  getFPConsSearch,
  getMissingBranch,
  getConsolidatedLocItems
} from './actions';
import { fAlertToast } from '../../Utility/Utilitys';
import ConsolidateSearch from './ProcessComponents/ConsolidateSearch';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import MissingBranch from './ProcessComponents/Missingbranch';
import ConsReqBreadCrumbs from './ProcessComponents/ConsReqBreadCrumbs';
import ConsolidatedItemsModal from './ProcessComponents/ConsolidatedItemsModal';

const gridBox = {
  border: '1px solid #DADBD6',
  padding: 0.5
};
const borderBox = {
  height: '37vh',
  padding: 0.2
};
const gridItemSpacing = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'flex-start',
  padding: 0.2
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'background.paper',
  border: '2px solid #0d73bb',
  boxShadow: 24,
  // p: 4,
  textAlign: 'center',
  // alignItems: "center",
  // borderRadius: 3,
  height: '80%',
  margin: '0 auto',
  color: '#0d73bb'
};

export const locGridCol = [
  {
    field: 'UId',
    headerName: 'UID',
    width: 150
  },
  {
    field: 'Name1',
    headerName: 'Name1',
    width: 150
  }
];
export const departGridCol = [
  {
    field: 'Cd',
    headerName: 'UID',
    width: 150
  },
  {
    field: 'Name1',
    headerName: 'Name1',
    width: 150
  }
];
export const VocDataGridColumn = [
  {
    field: 'VocName',
    headerName: 'Voc.Type',
    width: 100
  },
  {
    field: 'A_VocNo',
    headerName: 'Voc.No',
    width: 150
  },
  {
    field: 'A_VocDt',
    headerName: 'voc.Date',
    width: 150,
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
    // hide: true,
  },
  {
    field: 'LocName',
    headerName: 'Location',
    width: 200
    // hide: true,
  }
];

export const ConsolidatedGridColumns = [
  {
    field: 'SrNo',
    headerName: 'Sr.No',
    width: 50
  },
  {
    field: 'ItmCd',
    headerName: 'Item.Code',
    width: 150
  },
  {
    field: 'ItemName',
    headerName: 'Item.Name',
    width: 300
  },
  {
    field: 'GrpName',
    headerName: 'Group',
    width: 150
    // hide: true,
  },
  {
    field: 'ReqQty',
    headerName: 'Required',
    width: 150
    // hide: true,
  },
  {
    field: 'FinalQty',
    headerName: 'Final.Qty',
    width: 150
    // hide: true,
  },
  {
    field: 'DCStock',
    headerName: 'DC Stock',
    width: 150
    // hide: true,
  },
  {
    field: 'AlloQty',
    headerName: 'Alloc.Qty',
    width: 150
    // hide: true,
  },
  {
    field: 'BalQty',
    headerName: 'Bal.Qty',
    width: 150
    // hide: true,
  },
  {
    field: 'B_PackQty',
    headerName: 'Pack.Qty',
    width: 150
    // hide: true,
  }
  // {
  //   field: 'BalQty',
  //   headerName: 'Bal Qty',
  //   width: 150
  //   // hide: true,
  // }
];
const auditGridColumn = [
  {
    field: 'User',
    headerName: 'User',
    flex: 2
  },

  {
    field: 'Date',
    headerName: 'Date',
    flex: 1
  },
  {
    field: 'Time',
    headerName: 'Time',
    flex: 2
  },
  {
    field: 'Field',
    headerName: 'Field',
    flex: 1
  },
  {
    field: 'Old Value',
    headerName: 'Old Value',
    flex: 1
  },
  {
    field: 'New Value',
    headerName: 'New Value',
    flex: 1
  },
  {
    field: 'ItmName',
    headerName: 'Item Name',
    flex: 2
  }
];

const SearchGridColumn = [
  {
    field: 'A_VocTyp',
    headerName: 'Voc.Type',
    flex: 2
  },

  {
    field: 'A_VocNo',
    headerName: 'voc.No',
    flex: 1
  },
  {
    field: 'A_VocDt',
    headerName: 'Voc.Date',
    flex: 2,
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
  },
  {
    field: 'A_Loc',
    headerName: 'Location',
    flex: 1
  },
  {
    field: 'A_DocStat',
    headerName: 'Doc.Status',
    flex: 1
  },
  {
    field: 'LocName',
    headerName: 'LocName',
    flex: 1
  },
  {
    field: 'VocName',
    headerName: 'VocName',
    flex: 2
  }
  // {
  //   field: 'editBy',
  //   headerName: 'Edit By',
  //   flex: 2
  // },
  // {
  //   field: 'editDate',
  //   headerName: 'Edit Date',
  //   flex: 2
  // }
];
const xArrData = ['Any', 'UID', 'Name'];
const printSelectArr = [
  { name: 'Transaction', type: 'VOUCHAP' },
  { name: 'Request Document wise', type: 'VOUCHAP_Doc' },
  { name: 'Summary', type: 'VOUCHAS' },
  { name: 'Locationwise Summary', type: 'VOUCHAL' }
];

const buttonIconDiv = { textTransform: 'capitalize', fontSize: '13px' };
const iconDivStyle = { color: '#0D73BB', margin: '5px', cursor: 'pointer' };

const ConsolidationRequest = () => {
  const typeRef = useRef(null);
  const docDTRef = useRef(null);
  const fromDtRef = useRef(null);
  const toDTRef = useRef(null);
  const locRef = useRef(null);
  const deptRef = useRef(null);
  const rdxuser = useSelector((state) => state.SliceDB.value.login);
  const reportBaseUrl = useSelector((state) => state.SliceDB.value.reportBaseUrl);
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);
  const vMediaQuery = useMediaQuery('(max-width: 600px)');
  const vRdxkern = useSelector((state) => state.SliceDB.value.Kern);
  const [headObj, setHeadObj] = useState({
    vocName: '',
    vocTyp: '0',
    vocNo: '0',
    vocDt: new Date(),
    fromDt: moment().startOf('month'),
    toDT: new Date(),
    narr: '',
    loc: '',
    dept: '',
    locName: '',
    deptName: '',
    allowDCPurchase: 'N',
    allowDCStock: false,
    docStatus: 'ENTER'
  });
  const [openLocList, setOpenLocList] = useState(false);
  const [openDepartList, setOpenDepartList] = useState(false);
  const [locSearch, setLocSearch] = useState('');
  const [departSearch, setDepartSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('Any');
  const [filteredLoc, setFilteredLoc] = useState(null);
  const [filteredDept, setFilteredDept] = useState(null);
  const [requestGrid, setRequestGrid] = useState([]);
  const [summaryGrid, setSummaryGrid] = useState([]);
  const [locAttribute, setLocAttribute] = useState(null);
  const [deptAttribute, setDeptAttribute] = useState(null);
  const [docTypeAttribute, setDocTypeAttribute] = useState(null);
  const [rightsAttribute, setRightsAttribute] = useState(null);
  const [userDoc, setUserDoc] = useState([]);
  const [userDocLabel, setUserDocLabel] = useState(1);
  const [objRights, setObjRights] = useState(null);
  const [sShowAuditModal, setShowAuditModal] = useState(false);
  const [sSelectedAuditFld, setSelectedAuditFld] = useState(null);

  const [sAuditFields, setAuditFields] = useState([]);
  const [sAuditRows, setAuditRows] = useState([]);
  const [sShowSearchModal, setShowSearchModal] = useState(false);
  const [sSearchModalObj, setSearchModalObj] = useState({
    vocNo: '',
    vocDate: '',
    docStat: 'ENTER',
    loc: '',
    locName: ''
  });
  const [isPrintOpen, setIsPrintOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [printWebURL, setPrintWebURL] = useState(null);
  const [searchGridRow, setSearchGridRow] = useState([]);
  const [missingBranchRow, setMissingBranchRow] = useState([]);
  const [missingBranchCol, setMissingBranchCol] = useState([]);
  //consolidate modal
  const [consolidatedLocItemsArrConst, setConsolidatedLocItemsArrConst] = useState([]);
  const [consolidatedLocItems, setConsolidatedLocItems] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [consolidatedItemsModal, setConsolidatedItemsModal] = useState(false);
  const [sSelectedItemFld, setSelectedItemFld] = useState('--');
  const [sSelectedPackQty, setSelectedPackQty] = useState(1);
  const [sSelectedOrgPackQty, setSelectedOrgPackQty] = useState(1);

  const [ConsolidatedGridItemColumns, setConsolidatedGridItemColumns] = useState([
    {
      field: 'ReqLocName',
      headerName: 'Location',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'IL_AvgSales',
      headerName: 'Avg.Sales',
      width: 100,
      hide: true
    },
    {
      field: 'IL_FinalQty',
      headerName: 'Final/Req.Qty',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(2)
      // hide: true
    },
    {
      field: 'IL_ReqQtyCTN',
      // field: 'PackQty',
      headerName: 'Req.Qty',
      width: 100,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'IL_AlloQty',
      headerName: 'Alloc.Qty',
      width: 100,
      align: 'center',
      headerAlign: 'center'
      // hide: true,
      // flex: 2,
      // align: 'center',
      // headerAlign: 'center',
      // hide: false,
      // valueSetter: (params) => {
      //   setAllocQty(params, consolidatedLocItems);
      // },
      // // editable: sSelectedItemFld == 'MANUAL',
      // editable: true,
      // type: 'number',
      // cellClassName: 'mui-request-grid-final-qty'
      // // width: vMediaQuery ? 50 : 80
    },
    {
      field: 'IL_BalQty',
      headerName: 'Bal.Qty',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(2)
      // hide: true,
    },
    {
      field: 'Stock',
      headerName: 'Stock',
      width: 100,
      hide: true
    }
  ]);
  const fType = (typ = '--', gridData, sSelectedOrgPack = sSelectedOrgPackQty) => {
    let gridRow = gridData;

    if (typ == '--') {
      setSelectedPackQty(sSelectedOrgPack);
      for (let i of gridRow) {
        i.IL_AlloQty = 0;
        i.IL_BalQty = i.IL_FinalQty;
        // i.IL_BalQty = i.PackQty;
      }
    }
    // else if (typ == 'FIFO') {
    //   let dcStock = 0;
    //   let finalQty = 0;
    //   let currAlloc = 0;
    //   let allocQty = 0;
    //   let balQty = 0;
    //   dcStock = selectedRowData?.DCStock;
    //   let totalDCStock = dcStock;

    //   for (let i of gridRow) {
    //     if (totalDCStock > 0) {
    //       finalQty = i.IL_FinalQty;
    //     }
    //     if (finalQty <= totalDCStock) {
    //       allocQty = finalQty;
    //       balQty = 0;
    //       totalDCStock = totalDCStock - allocQty;
    //     } else {
    //       allocQty = totalDCStock;
    //       totalDCStock = 0;
    //       balQty = finalQty - allocQty;
    //     }
    //     i.IL_AlloQty = allocQty;
    //     i.IL_BalQty = balQty;
    //   }
    // }

    setConsolidatedLocItems(gridRow);
  };
  const fLoadConLocItems = async (ItmCd, Typ, B_PackQty) => {
    const obj = {
      vocNo: headObj.vocNo,
      vocTyp: headObj.vocTyp,
      itemCode: ItmCd,
      A_PackQty: B_PackQty
    };

    const result = await getConsolidatedLocItems(obj);

    if (!!result && result?.ResultSet.length > 0) {
      if (result?.ResultSet.length > 0) {
        setSelectedItemFld(Typ);
        setSelectedPackQty(Number(B_PackQty));
        setSelectedOrgPackQty(Number(B_PackQty)); //if typ = -- then need to div by default pkqty

        setConsolidatedLocItems(result?.ResultSet);
        setConsolidatedLocItemsArrConst(result?.ResultSet);
        setConsolidatedItemsModal(true);
        fType(Typ, result?.ResultSet, B_PackQty);
      }
    }
  };
  const [openMB, setOpenMB] = useState(false);

  const showPrintMenu = Boolean(isPrintOpen);

  const handlePrintClick = (event) => {
    setIsPrintOpen(event.currentTarget);
  };
  const handlePrintClose = () => {
    setIsPrintOpen(null);
  };

  const flocUpdate = (option) => {
    setHeadObj((obj) => ({
      ...obj,
      // selectedlocation: option?.row,
      loc: option?.row?.UId,
      locName: option?.row?.Name1
    }));
    // setSelectedlocation(option?.row);
    setOpenLocList(false);
    setLocSearch('');
  };
  const fDepartUpdate = (option) => {
    // setSelectedDepartment(option?.row);
    setHeadObj((obj) => ({
      ...obj,
      // selectedDepartment: option?.row
      dept: option?.row?.UId,
      deptName: option?.row?.Name1
    }));
    setOpenDepartList(false);
    setDepartSearch('');
  };
  const onChangeDocTyp = (event) => {
    const Id = docTypeAttribute
      .filter((x) => x.VocName === event.target.value)
      .map((y) => y.VocTyp);
    setHeadObj((obj) => ({
      ...obj,
      vocName: event.target.value,
      vocTyp: Id[0]
    }));
  };

  const onChangeFromDate = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: newValue.$d
      }));
    }
  };

  const onChangeDateTo = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        toDT: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        toDT: newValue.$d
      }));
    }
  };

  const onChangeDocDate = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        vocDt: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        vocDt: newValue.$d
      }));
    }
  };

  const onChangeNarration = (event) => {
    setHeadObj((obj) => ({ ...obj, narr: event.target.value }));
  };

  const onSelectGridItem = (item) => {
    console.log(item);
    setRequestGrid(item);
  };

  const handleRowClick = async (row) => {
    setSelectedRowData(row);
    fLoadConLocItems(row?.ItmCd, row?.Typ, row?.B_PackQty);
    // Open the modal
  };

  const getlocBySearch = useCallback(() => {
    // const xLData = attributeResponse?.loc;
    if (searchFilter === 'UID') {
      const filtered = locAttribute.filter((option) =>
        option?.UId.toLowerCase()?.includes(locSearch?.toLowerCase())
      );
      setFilteredLoc(filtered);
    } else if (searchFilter === 'Name') {
      const filtered = locAttribute.filter((option) =>
        option?.Name1.toLowerCase()?.includes(locSearch?.toLowerCase())
      );
      setFilteredLoc(filtered);
    } else if (searchFilter === 'Any') {
      const filtered = locAttribute.filter((option) => {
        const name = option?.Name1.toLowerCase();
        const search = locSearch?.toLowerCase();
        const id = option?.UId.toLowerCase();
        if (name.includes(search) || id.includes(search)) {
          return option;
        }
      });
      setFilteredLoc(filtered);
    }
  }, [locSearch, searchFilter]);

  const getDeptBySearch = useCallback(() => {
    // const xDData = attributeResponse.Dept;/
    if (searchFilter === 'UID') {
      const filtered = deptAttribute.filter((option) =>
        option?.Cd.toLowerCase()?.includes(departSearch?.toLowerCase())
      );
      setFilteredDept(filtered);
    } else if (searchFilter === 'Name') {
      const filtered = deptAttribute.filter((option) =>
        option?.Name1.toLowerCase()?.includes(departSearch?.toLowerCase())
      );
      setFilteredDept(filtered);
    } else if (searchFilter === 'Any') {
      const filtered = deptAttribute.filter((option) => {
        const name = option?.Name1.toLowerCase();
        const search = departSearch?.toLowerCase();
        const id = option?.Cd.toLowerCase();
        if (name.includes(search) || id.includes(search)) {
          return option;
        }
      });
      setFilteredDept(filtered);
    }
  }, [departSearch, searchFilter]);

  const getlocExactSearch = useCallback(() => {
    // const xLData = attributeResponse?.loc;
    const option = locAttribute?.filter((option) => {
      const search = locSearch?.toLowerCase();
      const id = option?.UId.toLowerCase();
      const name = option?.Name1.toLowerCase();

      if (id === search || name === search) {
        return option;
      }
    });
    if (option?.length > 0) {
      setHeadObj((obj) => ({
        ...obj,
        // selectedlocation: option[0]
        loc: option?.[0]?.UId,
        locName: option?.[0]?.Name1
      }));
      // setSelectedlocation(option[0]);
      setOpenLocList(false);
    } else {
      setOpenLocList(true);
    }
  }, [locSearch]);

  const getDeptExactSearch = useCallback(() => {
    // const xDData = attributeResponse.Dept;/
    const option = deptAttribute?.filter((option) => {
      const name = option?.Name1.toLowerCase();
      const search = departSearch?.toLowerCase();
      const id = option?.Cd.toLowerCase();

      if (id === search || name === search) {
        return option;
      }
    });
    if (option?.length > 0) {
      // setSelectedDepartment(option[0]);
      setHeadObj((obj) => ({
        ...obj,
        // selectedDepartment: option[0]
        dept: option?.[0]?.UId,
        deptName: option?.[0]?.Name1
      }));
      setOpenDepartList(false);
    } else {
      setOpenDepartList(true);
    }
  }, [departSearch]);

  //Attributes API call
  useEffect(() => {
    const fLoadFPAttributes = async () => {
      const obj = {
        userCd: rdxuser.userId,
        userGroupCd: rdxuser.userGroup
      };
      const result = await getFPConsAttributes(obj);
      // setAttributeResponse(result?.ResultSet);
      setHeadObj((obj) => ({
        ...obj,
        vocName: result?.ResultSet?.DocType?.[0]?.VocName,
        vocTyp: result?.ResultSet?.DocType?.[0]?.VocTyp,
        allowDCPurchase: 'Y'
      }));
      setLocAttribute(result?.ResultSet?.loc);
      setDeptAttribute(result?.ResultSet?.Dept);
      setRightsAttribute(result?.ResultSet?.Rights);
      setDocTypeAttribute(result?.ResultSet?.DocType);
      setFilteredDept(result?.ResultSet?.Dept);
      setFilteredLoc(result?.ResultSet?.loc);
      setUserDoc(result?.ResultSet?.UserDoc);
      setSearchModalObj((obj) => ({
        ...obj,
        loc: result?.ResultSet?.loc[0].UId,
        locName: result?.ResultSet?.loc[0].Name1
      }));
      setAuditFields(result?.ResultSet?.AuditFields);
      setSelectedAuditFld(result?.ResultSet?.AuditFields[0]?.id);
    };
    fLoadFPAttributes();
  }, []);

  //docRights checking
  useEffect(() => {
    // const sObjectRights = attributeResponse?.Rights;
    var resultObj = fCheck_Doc_Rights_001(
      headObj?.docStatus,
      headObj?.vocTyp,
      !!rightsAttribute && rightsAttribute?.length > 0 ? rightsAttribute : [],
      rdxuser
    );
    setObjRights(resultObj);
  }, [headObj.docStatus, headObj.vocTyp]);

  //call search API

  const getSearch = async (xvocno, xvocDate, xloc, xdocStat) => {
    const obj = {
      v_VocNo: xvocno,
      v_VocDt: !!xvocDate ? moment(xvocDate).format('DD/MM/YYYY') : '01/01/1900',
      v_Loc: xloc,
      v_Status: xdocStat,
      v_UserCd: rdxuser.userId
    };
    let result = await getFPConsSearch(obj);
    result = result.ResultSet.map((obj, index) => {
      return { ...obj, id: index + 1 };
    });
    setSearchGridRow(result);
  };

  useEffect(() => {
    if (sShowSearchModal) {
      getSearch(
        sSearchModalObj.vocNo,
        sSearchModalObj.vocDate,
        sSearchModalObj.loc,
        sSearchModalObj.docStat
      );
    }
  }, [sShowSearchModal]);

  const fDisplay = useCallback(async (vocNo, vocType) => {
    const obj = {
      vocNo: vocNo,
      vocType: vocType
    };

    const result = await getFPConsDisplay(obj);
    const resuldata = result?.ResultSet?.[0];
    if (result?.ResultSet?.length > 0) {
      setHeadObj((obj) => ({
        ...obj,
        vocName: resuldata?.VocName,
        vocTyp: resuldata?.A_VocTyp,
        vocNo: resuldata?.A_VocNo,
        vocDt: new Date(resuldata?.A_VocDt),
        fromDt: new Date(resuldata?.A_FromDt),
        toDT: new Date(resuldata?.A_ToDt),
        narr: resuldata?.A_Narr,
        loc: resuldata?.A_Loc,
        locName: resuldata?.LocName,
        dept: resuldata?.A_Dept,
        deptName: resuldata?.DeptName,
        docStatus: resuldata?.A_DocStat,
        allowDCPurchase: resuldata?.A_DC_Purchase,
        allowDCStock: resuldata?.AllowDCSTock == 'Y' ? true : false
      }));
      setRequestGrid(resuldata?.RefDoc);
      fLoadDetail(resuldata?.A_VocNo, resuldata?.A_VocTyp);
    }
  }, []);

  const fMove_First = useCallback(() => {
    try {
      const value = userDoc.find((x) => x.SrNo == 1);
      if (value != undefined) {
        setUserDocLabel(1);
        fDisplay(value.VocNo, value.VocTyp);
      }
    } catch (error) {
      // oGen.gErrorDisplay(ex);
    }
  }, [userDoc]);
  const fMove_Last = useCallback(() => {
    try {
      if (userDoc?.length > 0) {
        const value = userDoc?.find((x) => x.SrNo == userDoc?.length - 1);
        if (value != undefined) {
          setUserDocLabel(userDoc?.length);
          fDisplay(value.VocNo, value.VocTyp);
        }
      }
    } catch (error) {
      // oGen.gErrorDisplay(ex);
    }
  }, [userDoc]);
  const fMove_Previous = useCallback(() => {
    try {
      if (userDocLabel > 1) {
        const value = userDoc.find((x) => x.SrNo == userDocLabel - 1);
        if (value != undefined) {
          setUserDocLabel(userDocLabel - 1);
          fDisplay(value.VocNo, value.VocTyp);
        }
      }
    } catch (error) {
      // oGen.gErrorDisplay(ex);
    }
  }, [userDocLabel, userDoc]);

  const fMove_Next = useCallback(() => {
    try {
      if (userDocLabel < userDoc?.length) {
        const value = userDoc?.find((x) => x.SrNo == userDocLabel + 1);
        if (value != undefined) {
          setUserDocLabel(userDocLabel + 1);
          fDisplay(value.VocNo, value.VocTyp);
        }
      }
    } catch (error) {
      // oGen.gErrorDisplay(ex);
    }
  }, [userDocLabel, userDoc]);

  const fSaveHead = async (
    isLoadData,
    isRefDocExisting = false,
    isApproved = false,
    status = ''
  ) => {
    const filteredRequestDoc = requestGrid.filter((x) => x.Flg === 'Y');
    const obj = {
      vocTyp: headObj.vocTyp,
      vocNo: headObj.vocNo,
      vocDt: moment(headObj.vocDt).format('MM/DD/YYYY'),
      loc: headObj.loc,
      fromDt: moment(headObj.fromDt).format('MM/DD/YYYY'),
      toDT: moment(headObj.toDT).format('MM/DD/YYYY'),
      deptList: headObj.dept,
      narr: headObj.narr,
      allowDCStock: headObj.allowDCStock ? 'Y' : 'N',
      dcPurchase: headObj.allowDCPurchase,
      //CNTRL_PUR: headObj.CNTRL_PUR ? 'Y' : 'N',
      CNTRL_PUR: headObj.allowDCPurchase == 'Y' ? 'N' : 'Y',
      userCd: rdxuser.userId,
      refDocs: !isLoadData ? filteredRequestDoc : []
    };
    const result = await getFPConsSaveHead(obj);
    //update new Document to userDoc from the resultset if vocNo was 0
    if (result?.ResultSet?.[0].VocNo != headObj.vocNo) {
      const data = {
        VocTyp: headObj.vocTyp,
        VocNo: result?.ResultSet?.[0].VocNo,
        SrNo: userDoc?.length + 1
      };
      setUserDoc((x) => [...x, data]);
    }
    setHeadObj((x) => ({
      ...x,
      vocNo: result?.ResultSet?.[0].VocNo
    }));
    if (isLoadData) {
      fRequestDocs(result?.ResultSet?.[0].VocNo, headObj?.vocTyp, true);
      fLoadDetail(result?.ResultSet?.[0].VocNo, headObj?.vocTyp);
    }

    if (isRefDocExisting) {
      fRequestDocs(result?.ResultSet?.[0].VocNo, headObj?.vocTyp, false);
      fLoadDetail(result?.ResultSet?.[0].VocNo, headObj?.vocTyp);
    }
  };

  const fRequestDocs = async (vocNo, vocTyp, isLoad) => {
    const obj = {
      vocType: vocTyp,
      vocNo: vocNo,
      mode: isLoad ? 'G' : 'E'
    };
    const result = await getFPConsRequestDocs(obj);
    setRequestGrid(result.ResultSet);
  };

  const fLoadDetail = async (vocNo, vocTyp) => {
    const obj = {
      vocType: vocTyp,
      vocNo: vocNo
    };

    const result = await getFPConsLoadDetail(obj);
    setSummaryGrid(result.ResultSet);
  };
  const fRelease = async () => {
    const obj = {
      vocType: headObj?.vocTyp,
      vocNo: headObj?.vocNo
    };
    const result = await getFPConsRelease(obj);
    fNewDetail();
  };

  const handleLoadData = (event) => {
    if (!fValidateHead()) return;
    fSaveHead(true);

    // confirmAlert({
    //   title: 'Load',
    //   message: 'Are you sure?',
    //   buttons: [
    //     {
    //       className: 'alertBtn',
    //       label: 'Yes',
    //       onClick: async () => {
    //         fSaveHead(true);
    //       }
    //     },
    //     {
    //       className: 'alertBtn',
    //       label: 'No',
    //       onClick: () => {}
    //     }
    //   ]
    // });
  };
  const fNew = useCallback(() => {
    setHeadObj((obj) => ({
      ...obj,
      vocName: docTypeAttribute?.[0]?.VocName,
      vocTyp: docTypeAttribute?.[0]?.VocTyp,
      vocNo: '0',
      vocDt: new Date(),
      fromDt: moment().startOf('month'),
      toDT: new Date(),
      narr: '',
      allowDCPurchase: 'Y',
      allowDCStock: false,
      locName: '',
      loc: '',
      deptName: '',
      dept: '',
      docStatus: 'ENTER'
    }));
    setDepartSearch('');
    setLocSearch('');
    setRequestGrid([]);
    setSummaryGrid([]);
  }, [docTypeAttribute, filteredLoc]);

  const fValidateHead = (check) => {
    try {
      if (headObj.vocTyp === '' && headObj.vocTyp === undefined) {
        fAlertToast('FAILED', 'Select Doc/Voucher Type');
        return false;
      }
      if (headObj.vocNo === '') {
        fAlertToast('FAILED', 'VocNo should be empty');
        return false;
      }
      // if (headObj.narr === '') {
      //   fAlertToast('FAILED', 'vocNo should be empty');
      //   return false;
      // }
      if (!moment(headObj.vocDt).isValid()) {
        fAlertToast('FAILED', 'Select valid Doc date');
        docDTRef.current.focus();
        return false;
      }

      if (!moment(headObj.fromDt).isValid()) {
        fAlertToast('FAILED', 'Select valid  From date');
        fromDtRef.current.focus();
        return false;
      }
      if (!moment(headObj.toDT).isValid()) {
        fAlertToast('FAILED', 'Select valid  To date');
        toDTRef.current.focus();
        return false;
      }
      if (moment(headObj.toDT).isBefore(moment(headObj.fromDt))) {
        fAlertToast('FAILED', 'To Date should be greater than or equal to From Date');
        toDTRef.current.focus();
        return false;
      }
      if (headObj.loc === '' && headObj.locName === '') {
        fAlertToast('FAILED', 'Select Location');
        locRef.current.focus();
        return false;
      }
      if (headObj.dept === '' && headObj.deptName === '') {
        fAlertToast('FAILED', 'Select Department');
        deptRef.current.focus();
        return false;
      }
      //validation for genrate only
      if (check) {
        const data = requestGrid.filter((x) => x.Flg === 'Y');
        if (data?.length === 0) {
          fAlertToast('FAILED', 'Select atleast one row ');
          return false;
        }
      }
      return true;
    } catch (error) {
      // console.log(error, 'error');
      fAlertToast('FAILED', error);
    }
  };
  const fNewDetail = useCallback(() => {
    try {
      fRequestDocs(headObj?.vocNo, headObj?.vocTyp, false);
      fLoadDetail(headObj?.vocNo, headObj?.vocTyp, false);
    } catch (error) {
      console.log(error, 'error');
    }
  }, [headObj]);

  const fSave = () => {
    const ip = getGeoLocation();
    // console.log(ip, 'ipppp');
    if (headObj.docStatus === 'ENTER' && summaryGrid.length > 0) {
      fAlertToast('FAILED', 'Data already generated, save denied');
      return;
    } else if (
      headObj.docStatus === 'POST' ||
      headObj.docStatus === 'DRAFT' ||
      headObj.docStatus === 'CANCEL'
    ) {
      //call reverse API
      // status 200 means
      confirmAlert({
        title: 'Reverse',
        message: (
          <p>
            Current status : {headObj.docStatus} <br /> Are you sure to reverse?
          </p>
        ),
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const obj = {
                vocNo: headObj.vocNo,
                vocType: headObj.vocTyp,
                userCd: rdxuser.userId,
                ipaddress: ''
              };

              const result = await getFPConsReverse(obj);
              if (result) {
                setHeadObj((obj) => ({ ...obj, docStatus: 'ENTER' }));
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else if (headObj.docStatus === 'DELETE') {
      fAlertToast('FAILED', 'Current status is deleted, Reverse denied');
      return;
    } else {
      confirmAlert({
        title: 'Save',
        message: 'Are you sure to save?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              if (!fValidateHead()) return;
              fSaveHead(false, true);
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const fAppr = (status) => {
    const ip = getGeoLocation();

    if (
      headObj.docStatus === 'POST' ||
      headObj.docStatus === 'DRAFT' ||
      headObj.docStatus === 'CANCEL'
    ) {
      //call reverse API
      // status 200 means
      confirmAlert({
        title: 'Reverse',
        message: `Current status : ${headObj.docStatus}\nAre you sure to reverse?`,
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const obj = {
                vocNo: headObj.vocNo,
                vocType: headObj.vocTyp,
                userCd: rdxuser.userId,
                ipaddress: ip
              };

              const result = await getFPConsReverse(obj);
              // const resuldata = result?.ResultSet;
              if (result.Type === 'Success') {
                setHeadObj((obj) => ({ ...obj, docStatus: 'ENTER' }));
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    } else if (headObj.docStatus === 'DELETE') {
      fAlertToast('FAILED', 'Current status is deleted, Approve denied');
    } else {
      confirmAlert({
        title: 'Approve',
        message: 'Are you sure to Approve?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              if (!fValidateHead()) return;
              if (fValidateHead()) {
                const ip = getGeoLocation();
                const obj = {
                  vocNo: headObj.vocNo,
                  vocType: headObj.vocTyp,
                  userCd: rdxuser.userId,
                  ipaddress: ip,
                  docStatus: status
                };
                const result = await getFPConsApprove(obj);
                if (result.Type === 'Success') {
                  setHeadObj((obj) => ({ ...obj, docStatus: status == 'POST' ? 'POST' : '' }));
                }
              }

              // fSaveHead(false, true, true, status);
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };
  const fDelete = async () => {
    if (headObj.docStatus != 'ENTER') {
      fAlertToast('FAILED', 'Transaction not in enter state, Delete denied');
    } else if (headObj.docStatus === 'ENTER') {
      const obj = {
        vocNo: headObj.vocNo,
        vocType: headObj.vocTyp,
        userCd: rdxuser?.userId,
        ip: ''
      };

      const result = await getFPConsDelete(obj);
      if (result.Type === 'Success') {
        setHeadObj((obj) => ({ ...obj, docStatus: 'DELETE' }));
      }
    }
  };
  const fGenerate = () => {
    if (!fValidateHead(true)) return;
    fSaveHead(false, true);
    // confirmAlert({
    //   title: 'Generate',
    //   message: 'Are you sure you want save?',
    //   buttons: [
    //     {
    //       className: 'alertBtn',
    //       label: 'Yes',
    //       onClick: async () => {
    //         fSaveHead(false, true);
    //       }
    //     },
    //     {
    //       className: 'alertBtn',
    //       label: 'No',
    //       onClick: () => {}
    //     }
    //   ]
    // });
  };
  const handleRelease = () => {
    if (!fValidateHead()) return;
    confirmAlert({
      title: 'Release',
      message: 'Are you sure you want release?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            fRelease();
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
  const fLoadAuditValues = async (xSelectedAuditFld) => {
    const obj = {
      vocTyp: headObj.vocTyp,
      vocNo: headObj.vocNo,
      field: xSelectedAuditFld
    };
    const result = await getAuditValues(obj);
    let transAuditRes = result?.ResultSet.map((obj, i) => ({
      ...obj,
      id: i + 1
    }));
    setAuditRows(transAuditRes);
  };
  const onChangeAuditSelect = (e) => {
    setSelectedAuditFld(e.target.value);
    fLoadAuditValues(e.target.value);
  };

  // const fonChangeVocTypeModal = (e, xDocType) => {
  //   const xdata = xDocType.find((x) => x.VocName === e.target.value);

  //   setSearchModalObj((obj) => ({
  //     ...obj,
  //     vocName: xdata?.VocName
  //   }));
  // };
  const fonChangeStatusModal = (e) => {
    setSearchModalObj((obj) => ({
      ...obj,
      docStat: e.target.value
    }));
    getSearch(sSearchModalObj.vocNo, sSearchModalObj.vocDate, sSearchModalObj.loc, e.target.value);
  };

  const fonChangeVocNoModal = (e) => {
    setSearchModalObj((obj) => ({
      ...obj,
      vocNo: e.target.value
    }));
  };
  const fOnChangeDateModel = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setSearchModalObj((obj) => ({
        ...obj,
        vocDate: ''
      }));
    } else {
      setSearchModalObj((obj) => ({
        ...obj,
        vocDate: newValue?.$d
      }));
    }

    let changedDate = newValue?.$d;
    if (new Date(newValue?.$d) >= new Date('01-01-1990')) {
      getSearch(sSearchModalObj.vocNo, changedDate, sSearchModalObj.loc, sSearchModalObj.docStat);
    }
  };
  const fonChangeLocModal = (e) => {
    // const xdata = xLocationArr.find((x) => x.Name1 === e.target.value);

    setSearchModalObj((obj) => ({
      ...obj,
      loc: e.target.value
    }));
    getSearch(
      sSearchModalObj.vocNo,
      sSearchModalObj.vocDate,
      e.target.value,
      sSearchModalObj.docStat
    );
  };
  // const fonChangeRefNoModal = (e) => {
  //   setSearchModalObj((obj) => ({
  //     ...obj,
  //     refNo: e.target.value
  //   }));
  // };
  const fClearSearchModal = () => {
    setSearchModalObj(() => ({
      vocNo: '',
      vocDate: new Date(),
      docStat: 'ENTER',
      loc: locAttribute[0].UId
    }));
  };

  const onRowClickSearch = (xdata) => {
    setShowSearchModal(false);
    fDisplay(xdata?.row?.A_VocNo, xdata?.row?.A_VocTyp);
    //call fdisplay passing vocNo and VocType
  };
  const getSearchValue = async (e) => {
    if (e.key === 'Enter') {
      // call search API and payload
      getSearch(
        sSearchModalObj.vocNo,
        sSearchModalObj.vocDate,
        sSearchModalObj.loc,
        sSearchModalObj.docStat
      );
    }
    if (e.keyCode === 40) {
      let selectedLength = document.getElementsByClassName('Mui-selected').length;
      if (selectedLength === 0) {
        let tabel = document.getElementById('ConsolidateSearch');
        // tabel?.getElementsByClassName('MuiDataGrid-row')[0].firstChild.click();
        tabel?.getElementsByClassName('MuiDataGrid-row')[0].firstChild.focus();
      }
    }
  };

  const getPrintData = (type) => {
    handlePrintClose();
    setOpenModal(true);
    let url = `${reportBaseUrl}FreshPurchase/FreshPurchaseConsolidated?trntyp=${type}&VocTyp=${headObj.vocTyp}&VocNo=${headObj.vocNo}`;
    // window.open(url);
    setPrintWebURL(url);
  };

  // console.log(vMediaQuery,"vMediaQuery")

  const missingBranchLoad = async () => {
    setOpenMB(true);
    const payload = {
      trnTyp: 'PUCNSMISNGIMG'
    };
    const data = await getGitterColumns(payload);
    setMissingBranchCol(data?.ResultSet?.columns);
    const obj = {
      Date: headObj?.vocDt
    };
    const result = await getMissingBranch(obj);
    const resultWithUniqueId = result?.ResultSet?.map((x, index) => {
      return { ...x, id: index + 1 };
    });
    setMissingBranchRow(resultWithUniqueId);
  };

  return (
    <div>
      <div>
        <ToastContainer />
      </div>
      <div>
        <ConsReqBreadCrumbs />
      </div>
      <Stack
        flexWrap="wrap"
        divider={<Divider orientation="vertical" flexItem />}
        direction="row"
        spacing={1}>
        <Button variant="text" startIcon={<AddBoxIcon />} sx={buttonIconDiv} onClick={fNew}>
          New
        </Button>
        <Button
          variant="text"
          startIcon={<SaveIcon />}
          sx={buttonIconDiv}
          onClick={fSave}
          disabled={objRights?.RSave == 'N' ? true : false}>
          Save
        </Button>
        {objRights?.RApprove == 'N' ? (
          <Button
            variant="text"
            startIcon={<VerifiedIcon />}
            sx={buttonIconDiv}
            onClick={() => {
              fAppr('DRAFT');
            }}
            disabled={headObj.docStatus != 'ENTER' ? true : false}>
            Draft
          </Button>
        ) : (
          <Button
            variant="text"
            startIcon={<VerifiedIcon />}
            sx={buttonIconDiv}
            onClick={() => {
              fAppr('POST');
            }}
            disabled={headObj.docStatus == 'ENTER' || headObj.docStatus == 'DRAFT' ? false : true}>
            Approve
          </Button>
        )}

        <Button
          variant="text"
          startIcon={<SearchIcon />}
          sx={buttonIconDiv}
          onClick={() => {
            setShowSearchModal(true);
          }}>
          Search
        </Button>
        <Button
          variant="text"
          startIcon={<DeleteIcon />}
          sx={buttonIconDiv}
          disabled={objRights?.RDelete == 'N' ? true : false}
          onClick={() => {
            fDelete();
            setHeadObj((obj) => ({ ...obj, docStatus: 'DELETE' }));
          }}>
          Delete
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& .MuiInputBase-input': {
              border: 'none !important'
            },
            '& .MuiSelect-icon': {
              color: '#0D73BB'
            }
          }}>
          <Button
            variant="text"
            startIcon={<PrintIcon />}
            sx={buttonIconDiv}
            disabled={objRights?.RPrint == 'N' ? true : false}
            aria-controls={showPrintMenu ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={showPrintMenu ? 'true' : undefined}
            onClick={handlePrintClick}>
            Print
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button'
            }}
            anchorEl={isPrintOpen}
            open={showPrintMenu}
            onClose={handlePrintClose}
            TransitionComponent={Fade}>
            {printSelectArr.map((x, index) => (
              <MenuItem
                sx={{ fontSize: '13px' }}
                key={`printKey-${index + 1}`}
                onClick={() => {
                  getPrintData(x?.type);
                }}>
                {x?.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Button
          variant="text"
          startIcon={<ContactSupportIcon />}
          sx={buttonIconDiv}
          // doubt
          disabled={objRights?.RDelete == 'N' ? true : false}
          onClick={() => {
            setShowAuditModal(true);
            fLoadAuditValues(sSelectedAuditFld);
          }}>
          Audit
        </Button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: '5px'
          }}>
          <Box>
            <SkipPreviousIcon sx={iconDivStyle} onClick={fMove_First} />
          </Box>
          <Box>
            <ArrowLeftIcon sx={iconDivStyle} onClick={fMove_Previous} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '8px'
            }}>
            <CmpTypographyField xcomponent={'span'} xText={userDocLabel} /> &nbsp; &nbsp; &nbsp;
            <CmpTypographyField xcomponent={'span'} xText={`of ${userDoc?.length}`} />
          </Box>
          <Box>
            <ArrowRightIcon sx={iconDivStyle} onClick={fMove_Next} />
          </Box>
          <Box>
            <SkipNextIcon sx={iconDivStyle} onClick={fMove_Last} />
          </Box>
        </div>
      </Stack>
      <Box sx={gridBox}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={5} sx={{ p: '5px' }}>
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                <CmpTypographyField xcomponent={'p'} xText={'Doc Type'} xVariant={'subtitle'} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                <CmpSelectArrField
                  xLabelText={'Doc Type'}
                  xValue={headObj.vocName}
                  xOnChange={onChangeDocTyp}
                  xName={'Doc Type'}
                  xData={docTypeAttribute?.map((x) => x.VocName)}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={''}
                  // xDisable={!headObj.isEnablevocType}
                  xDisable={headObj.vocNo === '0' ? false : true}
                  xref={typeRef}
                />
              </Grid>
            </Grid>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'span'} xText={'Doc No'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} sx={gridItemSpacing}>
                  <CmpInputField
                    xValue={headObj.vocNo}
                    xType={'text'} // text/number/password
                    xName={'vocNo'}
                    xReadOnly={true}
                    xOnChange={''}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={3} md={3} lg={3} sx={gridItemSpacing}>
                  <CmpTypographyField
                    xcomponent={'span'}
                    xText={'Doc Date'}
                    xVariant={'subtitle'}
                  />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} sx={gridItemSpacing}>
                  <CmpDatePickerField
                    xValue={headObj.vocDt}
                    xOnChange={(event) => onChangeDocDate(event)}
                    xName={'vocDt'}
                    xError={false}
                    xErrorMessage={false}
                    xOnKeyUp={''}
                    xTabIndex={'2'}
                    xref={docDTRef}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4} sx={gridItemSpacing}>
                  <CmpTypographyField
                    xcomponent={'span'}
                    xText={'From Date'}
                    xVariant={'subtitle'}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} sx={gridItemSpacing}>
                  <CmpDatePickerField
                    xValue={headObj.fromDt}
                    xOnChange={(event) => onChangeFromDate(event)}
                    xName={'fromDt'}
                    xError={false}
                    xErrorMessage={false}
                    xOnKeyUp={''}
                    xTabIndex={'1'}
                    xref={fromDtRef}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={3} md={3} lg={3} sx={gridItemSpacing}>
                  <CmpTypographyField xcomponent={'span'} xText={'To Date'} xVariant={'subtitle'} />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} sx={gridItemSpacing}>
                  <CmpDatePickerField
                    xValue={headObj.toDT}
                    xOnChange={(event) => onChangeDateTo(event)}
                    xName={'toDT'}
                    xError={false}
                    xErrorMessage={false}
                    xOnKeyUp={''}
                    xTabIndex={'2'}
                    xref={toDTRef}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} xVariant={'subtitle'} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <MuiDataGridWithSearch
                  setOpen={setOpenLocList}
                  open={openLocList}
                  code={headObj.loc}
                  name={headObj.locName}
                  setHeadObj={setHeadObj}
                  handleClick={flocUpdate}
                  rowData={filteredLoc}
                  setSearchValue={setLocSearch}
                  searchValue={locSearch}
                  xColumns={locGridCol}
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  onHandleExactSearch={getlocExactSearch}
                  getDataByValueSearch={getlocBySearch}
                  xArrData={xArrData}
                  xref={locRef}
                  xloc={true}
                  xdept={false}
                  xvMediaQuery={vMediaQuery}
                  // setFocusedRow={setFocusedRow}
                  // focusedRow={focusedRow}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={'Department'}
                  xVariant={'subtitle'}
                />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <MuiDataGridWithSearch
                  setOpen={setOpenDepartList}
                  open={openDepartList}
                  code={headObj.dept}
                  name={headObj.deptName}
                  // selectedOption={headObj.selectedDepartment}
                  setHeadObj={setHeadObj}
                  handleClick={fDepartUpdate}
                  rowData={filteredDept}
                  setSearchValue={setDepartSearch}
                  searchValue={departSearch}
                  xColumns={departGridCol}
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  onHandleExactSearch={getDeptExactSearch}
                  getDataByValueSearch={getDeptBySearch}
                  xArrData={xArrData}
                  xref={deptRef}
                  xloc={false}
                  xdept={true}
                  xvMediaQuery={vMediaQuery}
                  // setFocusedRow={setFocusedRow}
                  // focusedRow={focusedRow}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'Narration'} xVariant={'subtitle'} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                <TextField
                  id="outlined-multiline-static"
                  // label="Multiline"
                  multiline
                  rows={1}
                  onChange={onChangeNarration}
                  value={headObj?.narr}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={10} md={6} lg={5} sx={{ p: '5px', height: '15rem' }}>
            <MUIDataGrid
              xRows={requestGrid}
              xColumns={VocDataGridColumn}
              xonRowClick={() => {}}
              enableCheckbox={summaryGrid?.length === 0 ? true : false}
              borderColorChange={true}
              xonSelectionClick={onSelectGridItem}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} sx={{ px: '10px' }}>
            {/* <CmpCheckboxField
              xChecked={headObj.allowDCPurchase}
              xOnChange={() => {
                setHeadObj((obj) => ({
                  ...obj,
                  allowDCPurchase: !headObj.allowDCPurchase,
                  allowDCStock: !headObj.allowDCPurchase ? false : headObj.allowDCStock
                }));
              }} 
              xDisabled={
                summaryGrid?.length > 0 ||
                requestGrid.length > 0 ||
                vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'
                  ? true
                  : false
              }
              xName={'DC Purchase'}
              xNameField={'DC Purchase'}
              xOnkeyUp={''}
              xTabIndex=""
              xref=""
            /> */}
            <Grid container alignItems={'center'}>
              <Grid item xs={2.5} sm={2.5} md={2.5}>
                <CmpTypographyField xcomponent={'span'} xText={'Type '} xVariant={'subtitle'} />
              </Grid>
              <Grid item xs={9.5} sm={9.5} md={9.5}>
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="Type"
                  xValue={headObj.allowDCPurchase}
                  xOnChange={(event) => {
                    setHeadObj((obj) => ({
                      ...obj,
                      allowDCPurchase: event.target.value,
                      allowDCStock: event.target.value == 'Y' ? false : headObj.allowDCStock
                    }));
                  }}
                  xName={'Type'}
                  xData={[
                    {
                      UId: 'Y',
                      Name1: 'Market'
                    },
                    {
                      UId: 'N',
                      Name1: 'DC'
                    }
                  ]}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={
                    summaryGrid?.length > 0 ||
                    requestGrid.length > 0 ||
                    vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>

            <CmpCheckboxField
              xChecked={headObj.allowDCStock}
              xOnChange={() => {
                setHeadObj((obj) => ({
                  ...obj,
                  allowDCStock: !headObj.allowDCStock
                }));
              }}
              xDisabled={
                summaryGrid?.length > 0 ||
                vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N' ||
                headObj.allowDCPurchase == 'Y'
                  ? true
                  : false
              }
              xName={'Allow DC Stock'}
              xNameField={'Allow DC Stock'}
              xOnkeyUp={''}
              xTabIndex=""
              xref=""
            />

            <Box sx={{ padding: 0.5, textAlign: 'center' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={missingBranchLoad}
                xLabel={'Missing Branch'}
                xIcon={''}
                xDisable={false}
              />
            </Box>
            <Box sx={{ padding: 0.5, textAlign: 'center' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={handleLoadData}
                xLabel={'Load'}
                xIcon={''}
                // xDisable={!headObj.isEnableLoad}
                xDisable={summaryGrid?.length > 0 ? true : false}
              />
            </Box>
            <Box sx={{ padding: 0.5, textAlign: 'center' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={fGenerate}
                xLabel={'Generate'}
                xIcon={''}
                // xDisable={!headObj.isEnableGenerate}
                xDisable={summaryGrid?.length > 0 ? true : false}
              />
            </Box>
            <Box sx={{ padding: 0.5, textAlign: 'center' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={handleRelease}
                xLabel={'Release'}
                xIcon={''}
                // xDisable={!headObj.isEnableRealease}
                xDisable={summaryGrid?.length > 0 && headObj.docStatus === 'ENTER' ? false : true}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={borderBox}>
        <MUIDataGrid
          xRows={summaryGrid}
          xColumns={ConsolidatedGridColumns}
          xonRowClick={(params) => handleRowClick(params.row)} // Pass the selected row to handleRowClick function
          enableCheckbox={false}
        />
      </Box>
      <Box
        sx={{
          padding: '2px'
        }}>
        <CmpStatusDiv xStatus={headObj.docStatus} />
      </Box>

      <ConsolidatedItemsModal
        setConsolidatedLocItemsArrConst={setConsolidatedLocItemsArrConst}
        fLoadConLocItems={fLoadConLocItems}
        dcType={headObj.allowDCPurchase == 'Y'}
        xOpen={consolidatedItemsModal}
        fType={fType}
        consolidatedLocItems={consolidatedLocItems}
        consolidatedLocItemsArrConst={consolidatedLocItemsArrConst}
        sSelectedItemFld={sSelectedItemFld}
        setSelectedItemFld={setSelectedItemFld}
        sSelectedPackQty={sSelectedPackQty}
        setSelectedPackQty={setSelectedPackQty}
        setConsolidatedLocItems={setConsolidatedLocItems}
        ConsolidatedGridItemColumns={ConsolidatedGridItemColumns}
        fCloseItemModal={() => {
          fLoadDetail(headObj?.vocNo, headObj?.vocTyp);
          setConsolidatedItemsModal(false);
          setConsolidatedLocItemsArrConst([]);
          setSelectedPackQty(1);
        }}
        selectedRowData={selectedRowData} // Pass the selected row data to the modal
        vocNo={headObj.vocNo}
        vocTyp={headObj.vocTyp}
        headObj={headObj}
        //  itemCode={selectedRowData?.ItmCd}
      />
      <GCAudit
        xOpen={sShowAuditModal}
        xTitle={'AUDIT'}
        fCloseAuditModal={() => setShowAuditModal(false)}
        sAuditSelectArr={sAuditFields}
        GridDataAuditColumns={auditGridColumn}
        GridDataAuditRows={sAuditRows}
        xAuditId={sSelectedAuditFld}
        fonChangeAuditSelect={onChangeAuditSelect}
        xLabel={headObj.vocTyp + ' - ' + headObj.vocNo}
      />
      <ConsolidateSearch
        // xDocType={docTypeAttribute}
        xStatusArr={['Enter', 'Post', 'Draft', 'Cancel', 'Delete']}
        xLocationArr={locAttribute}
        xSearchModalObj={sSearchModalObj}
        xGetSearchValue={getSearchValue}
        // fonChangeVocTypeModal={fonChangeVocTypeModal}
        fonChangeStatusModal={fonChangeStatusModal}
        fonChangeVocNoModal={fonChangeVocNoModal}
        fOnChangeDateModel={fOnChangeDateModel}
        fonChangeLocModal={fonChangeLocModal}
        // fonChangeRefNoModal={fonChangeRefNoModal}
        fCloseSearchModal={() => {
          setShowSearchModal(false);
        }}
        fClearSearchModal={fClearSearchModal}
        GridSearchHeadRowData={searchGridRow}
        GridDataColumnsSearch={SearchGridColumn}
        xOnRowClickSearch={onRowClickSearch}
        xOpen={sShowSearchModal}
        xTitle={'Search'}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box className="GCSearchBtn-Tittle">
            <span>Print</span>
            <CloseIcon sx={{ zIndex: 999, float: 'right' }} onClick={() => setOpenModal(false)} />
          </Box>
          <iframe src={printWebURL} width="100%" height={500}></iframe>
        </Box>
      </Modal>

      {openMB && (
        <MissingBranch
          xOpen={openMB}
          xTitle={'Missing Branch'}
          fCloseModal={() => setOpenMB(false)}
          xPostedRowInfo={headObj}
          xLoadDetailRow={missingBranchRow}
          xLoadDetailCol={missingBranchCol}
        />
      )}
    </div>
  );
};
export default ConsolidationRequest;
