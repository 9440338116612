import { fExecuteAPI } from '../../Server/Apis';

export const getTransSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_TRANS_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};

export const getTransItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_TRANS_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const getVendorList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_VENDOR_LIST', xObj);
    return result;
  } catch (error) {}
};

export const getVendorOSAmt = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_VENDOR_OS_AMT', xObj);
    return result;
  } catch (error) {}
};

export const getVendorOSDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_VENDOR_OS_DETAIL', xObj);
    return result;
  } catch (error) {}
};

export const savePurchaseTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('SAVE_PURCHASE_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const deletePurchaseTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('DELETE_PURCHASE_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const postPurchaseTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('POST_PURCHASE_TRANS', xObj);
    return result;
  } catch (error) {}
};
export const reversePurchaseTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('REVERSE_PURCHASE_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const searchAdditionalItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('SRCH_ADDITIONAL_ITEM', xObj);
    return result;
  } catch (error) {}
};

export const getVendorPriceEnquiry = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_VENDOR_PRICE_ENQUIRY', xObj);
    return result.data;
  } catch (error) {}
};

export const getBufferItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_BUFFER_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const updateBufferTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_UPDATE_BUFFER_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const confirmBufferTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_CONFIRM_BUFFER_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const searchVendor = async (xObj) => {
  try {
    const result = await fExecuteAPI('SEARCH_VENDOR', xObj);
    return result;
  } catch (error) {}
};

export const EditConfirmTransaction = async (xObj) => {
  try {
    const result = await fExecuteAPI('EDIT_CONFORM_TRANSACTION', xObj);
    return result;
  } catch (error) {}
};
