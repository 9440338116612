import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BatchItemEditableGrid({ rowsData, setUpdateRows, vendorList, typeData, editable, setGridEditing }) {
    const vMediaQuery = useMediaQuery('(max-width:550px)');
    const vTabScreen = useMediaQuery('(max-width:900px)');

    const vendorListUpdate = vendorList.map((itm) => ({ value: itm.AcCd, label: itm.Name2 }));
    vendorListUpdate.push({ value: '', label: '' });

    const setType = (params) => {
        let row = {
            ...params.row,
            B_Type: params.row.B_Acc_BaseQty == 0 ? '--' : params.value
        };
        const updatedRows = rowsData.map((itm) => (itm.A_TransId === row.A_TransId && itm.B_SrNo === row.B_SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const setVendorList = (params) => {

        let row = { ...params.row, B_Vendor: params.value };

        const updatedRows = rowsData.map((itm) => (itm.A_TransId === row.A_TransId && itm.B_SrNo === row.B_SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const setAccptQty = (params) => {
        let row = {
            ...params.row,
            B_Acc_BaseQty: params.value,
            B_Type: params.value == 0 ? '--' : params.row.B_Type
        };
        const updatedRows = rowsData.map((itm) => (itm.A_TransId === row.A_TransId && itm.B_SrNo === row.B_SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return row;
    };

    const setVendorCost = (params) => {
        let row = { ...params.row, B_VendorCost: params.value };
        const updatedRows = rowsData.map((itm) => (itm.A_TransId === row.A_TransId && itm.B_SrNo === row.B_SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return { ...params.row, B_VendorCost: params.value };
    };


    const columns = [
        {
            field: 'A_TransId',
            headerName: 'Trans. Id',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            hide: vMediaQuery
        },
        {
            field: 'B_SrNo',
            headerName: 'Sr.No',
            flex: 1,
            hide: true
        },
        {
            field: 'LocName',
            headerName: 'Location',
            flex: 2,
        },

        {
            field: 'B_BaseQty',
            headerName: 'Qty.',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            field: 'B_Acc_BaseQty',
            headerName: 'Acc. Qty.',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            valueSetter: setAccptQty,
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },

        {
            field: 'B_Type',
            headerName: 'Type',
            flex: 1,
            editable: editable,
            type: 'singleSelect',
            valueOptions: typeData,
            valueSetter: setType
        },

        {
            field: 'B_Vendor',
            headerName: 'Vendor',
            flex: 4,
            editable: editable,
            type: 'singleSelect',
            valueOptions: vendorListUpdate,
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const option = vendorListUpdate.find((itm) => itm.value === value);
                return option ? option.label : '';
            },
            valueSetter: setVendorList
        },

        {
            field: 'B_VendorCost',
            headerName: 'Vendor Cost',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            editable: editable,
            valueSetter: setVendorCost,
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },


    ];

    return (
        <Box
            sx={{
                height: vMediaQuery ? '55vh' : vTabScreen ? '60vh' : '55vh',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary'
                },
                '& .textPrimary': {
                    color: 'text.primary'
                }
            }}>
            <DataGrid
                rows={rowsData}
                columns={columns}
                rowHeight={45}
                headerHeight={50}
                sx={{
                    border: 2,
                    borderColor: 'primary.light',
                }}
                componentsProps={{
                    filterPanel: { sx: { maxWidth: "93vw" } }
                  }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                }
                onCellEditStart={() => setGridEditing(true)}
                onCellEditStop={() => setGridEditing(false)}
                onCellEditCommit={() => setGridEditing(false)}
            />
        </Box>
    );
}



