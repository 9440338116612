import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  reportFilter: {
    itmTyp: 'All',
    itmCd: '',
    itmName: '',
    mainGrpTyp: 'All',
    mainGrps: [],
    subGrpTyp: 'All',
    subGrps: [],
    grpTyp: 'All',
    grps: [],
    reportType: ''
  },
  summaryFilterStatus: 'All',
  summaryObj: {
    status: 'All',
    centerPurchase: 'Y',
    dept: ''
  },
  requestHead: {
    vocTyp: '',
    vocNo: '',
    vocDt: new Date(),
    loc: '',
    dept: '',
    CNSType: 1,
    CNSDt1: new Date(),
    CNSDt2: new Date(),
    CNSDays: 0,
    CNSRcndDays: 0,
    CNSRcndDay: '',
    docStat: 'ENTER',
    leadDays: 1,
    DC_Purchase: 'Y',
    CNTRL_PUR: 'N'
  },
  requestItemDetail: [],
  docRights: {
    rSave: 'Y',
    rEdit: 'Y',
    rDel: 'Y',
    rView: 'Y',
    rAppr: 'N',
    rReverse: 'N',
    rCancel: 'N',
    rPrint: 'Y',
    rClone: 'N'
  },
  requestGridColumns: [
    {
      field: 'SrNo',
      headerName: 'Sr No.',
      hide: true,
      align: 'center',
      flex: 1
    },
    {
      field: 'ItmCd',
      headerName: 'Item Code',
      hide: true,
      flex: 3
    },
    {
      field: 'ItemName',
      headerName: 'Item Name',
      hide: false,
      flex: 5
    },
    {
      field: 'BarCode',
      headerName: 'Barcode',
      hide: false,
      flex: 3
    },
    {
      field: 'PLU',
      headerName: 'PLU',
      hide: true,
      flex: 3
    },
    // {
    //   field: 'unit',
    //   headerName: 'Unit',
    //   hide: true,
    //   flex: 3
    // },
    {
      field: 'BuyerName',
      headerName: 'Buyer',
      hide: true,
      flex: 3
    },
    {
      field: 'Cost',
      headerName: 'Cost',
      hide: false,
      flex: 2,
      headerAlign: 'right',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString('en')
    },
    {
      field: 'RSP',
      headerName: 'RSP',
      hide: true,
      flex: 2,
      headerAlign: 'right',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString('en')
    },
    {
      field: 'GP',
      headerName: 'GP',
      hide: true,
      flex: 2,
      headerAlign: 'right',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString('en')
    },
    {
      field: 'StkQty',
      headerName: 'Stock',
      hide: false,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'OrderQty',
      headerName: 'Order Qty',
      hide: true,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'SalQty',
      headerName: 'Sale Qty',
      hide: true,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'AvgSalQty',
      headerName: 'Avg. Sales Qty',
      hide: true,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'MaxShelfQty',
      headerName: 'MaxShelf',
      hide: true,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'MinShelfQty',
      headerName: 'MinShelf',
      hide: true,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'ReqQty',
      headerName: 'Required',
      hide: false,
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    }
  ],

  transferFilterStatus: 'All',
  transferHead: {
    vocTyp: '',
    vocName: '',
    vocNo: '',
    vocDt: new Date(),
    loc: '',
    locName: '',
    dcLoc: '',
    dcLocName: '',
    status: 'ENTER',
    tsrVocTyp: '',
    tsrVocNo: '',
    postDt: new Date()
  },
  batchInfo: [],
  batchList: []
};

const OutletRequestSlice = createSlice({
  name: 'OutletRequestSlice',
  initialState: { value: initialState },

  reducers: {
    updateReportFilter: (state, action) => {
      state.value.reportFilter = action.payload;
    },
    updateSummaryFilterStatus: (state, action) => {
      state.value.summaryFilterStatus = action.payload;
    },
    updateSummaryObj: (state, action) => {
      state.value.summaryObj = action.payload;
    },
    updateRequestHead: (state, action) => {
      state.value.requestHead = action.payload;
    },
    updateRequestItemsDetail: (state, action) => {
      state.value.requestItemDetail = action.payload;
    },
    resetRequestHead: (state, action) => {
      state.value.requestHead = initialState.requestHead;
    },
    updateRequestGridColumns: (state, action) => {
      state.value.requestGridColumns = action.payload;
    },
    updateDocRights: (state, action) => {
      state.value.docRights = action.payload;
    },
    resetOutletRequest: (state, action) => {
      state.value = initialState;
    },
    updateTransferFilterStatus: (state, action) => {
      state.value.transferFilterStatus = action.payload;
    },
    updateTransferHead: (state, action) => {
      state.value.transferHead = action.payload;
    },
    updateBatchInfo: (state, action) => {
      state.value.batchInfo = action.payload;
    },
    updateBatchItem: (state, action) => {
      state.value.batchItem = action.payload;
    },
    updateBatchList: (state, action) => {
      state.value.batchList = action.payload;
    }
  }
});

export const {
  updateReportFilter,
  updateSummaryFilterStatus,
  updateRequestHead,
  updateSummaryObj,
  updateRequestItemsDetail,
  resetRequestHead,
  updateRequestGridColumns,
  resetOutletRequest,
  updateDocRights,
  updateTransferFilterStatus,
  updateTransferHead,
  updateBatchInfo,
  updateBatchItem,
  updateBatchList
} = OutletRequestSlice.actions;
export default OutletRequestSlice.reducer;
