import { fExecuteAPI } from '../../Server/Apis';
import { fAlertToast } from '../../Utility/Utilitys';

const getReturnRequestDCSummary = async (xObj) => {
  try {
    const getSummaryList = await fExecuteAPI('GET_ReturnRequest_DCReceipt_SUMMARY', xObj);
    return getSummaryList;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

const getReturnRequestItemUpdate = async (xObj) => {
  try {
    const getSummaryList = await fExecuteAPI('Return_Request_ItemUpdate', xObj);
    return getSummaryList;
  } catch (error) {
    fAlertToast('FAILED', error.response.data.message);
  }
};

export { getReturnRequestDCSummary, getReturnRequestItemUpdate };