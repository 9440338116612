import { fExecuteAPI } from '../../Server/Apis';

export const flyerItemSearchAPI = async (xObj) => {
  try {
    const result = await fExecuteAPI('ITEM_SEARCH', xObj);
    return result.data;
  } catch (error) {}
};

export const updateFlyerItemsAPI = async (xObj) => {
  try {
    const result = await fExecuteAPI('UPDATE_FLYER_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const getUnitAPI = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_FLYER_UNIT', xObj);
    return result.data;
  } catch (error) {}
};

export const deleteLineItemsAPI = async (xObj) => {
  try {
    const result = await fExecuteAPI('DELETE_LINEITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const getNotionFlyerItemsAPI = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_NOTION_FLYER_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const notionFlyerApproveAPI = async (xObj) => {
  try {
    const result = await fExecuteAPI('NOTION_FLYER_APPROVE', xObj);
    return result.data;
  } catch (error) {}
};
