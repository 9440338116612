import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { confirmAlert } from 'react-confirm-alert';

import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpButton,
  CmpInputField,
  CmpMultiSelect,
  CmpMultiSelectAll,
  CmpSelectArrField,
  CmpCheckboxField,
  CmpTypographyFieldColor,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import { createReviewBatch, getDepartmentList, getPendingReturnRequest } from './actions';
import { ReviewBatchCreationHead } from './ReturnReviewBatchCreationSlice';
import BatchCreationCard from './ProcessComponent/BatchCreationCard';
import ReviewBatchCreateBreadCrumbs from './ReviewBatchCreateBreadCrumbs';
import { AddCircle, Refresh } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

export default function ReturnReviewBatchCreate() {
  const vMediaQuery = useMediaQuery('(max-width:800px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const [sFilteredDCNames, setFilteredDCNames] = useState([]);
  const [sFilteredLocNames, setFilteredLocNames] = useState([]);

  const [sDeptList, setDeptList] = useState([]);

  const rdxHeadobj = useSelector((state) => state.ReturnReviewBatchCreationSlice.value.head);
  const rdxuserId = useSelector((state) => state.SliceDB.value.login.userId);
  const scanFocusOnLoad = useRef(null);

  const [sDetailObj, setDetailObj] = useState([]);

  const fCheckBoxChange = (e) => {
    dispatch(
      ReviewBatchCreationHead({
        ...rdxHeadobj,
        DCStatus: e.target.value
      })
    );
  };

  const fOnPageLoad = useCallback(async () => {
    let filteredDC = dcLoclist.filter((i) => rdxFilterObj.DCList.includes(i.UId));
    setFilteredDCNames(filteredDC.map((i) => i.Name1));

    let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    setFilteredLocNames(filteredLoc.map((i) => i.Name1));
    const result = await getDepartmentList({});
    setDeptList(result?.ResultSet);
    // await fGetDetailList();
    // rdxHeadobj.DCStatus
    dispatch(
      ReviewBatchCreationHead({
        ...rdxHeadobj,
        Dept: rdxHeadobj.Dept == '' ? result?.ResultSet[0].Cd : rdxHeadobj.Dept,
        DClocation: rdxHeadobj.DClocation == '' ? dcLoclist[0]?.UId : rdxHeadobj?.DClocation
      })
    );
    await fGetDetailList(rdxHeadobj.Dept == '' ? result?.ResultSet[0].Cd : rdxHeadobj.Dept);
  }, [dispatch, rdxFilterObj]);

  useEffect(() => {
    // scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  const fGetDetailList = async (Dept = rdxHeadobj.Dept) => {
    const obj = {
      v_A_DCLoc: rdxHeadobj.DClocation == '' ? dcLoclist[0]?.UId : rdxHeadobj?.DClocation,
      v_Date1: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      v_Loclist: rdxFilterObj.locList.toString(),
      v_DCStatus: rdxHeadobj.DCStatus,
      v_Dept: Dept
    };
    const result = await getPendingReturnRequest(obj);
    if (result.ResultSet?.length > 0) {
      setDetailObj(
        result.ResultSet.map((i) => {
          return { ...i, selected: true };
        })
      );
    } else {
      setDetailObj([]);
    }
  };

  const fOnpressLoad = async () => {
    // scanFocusOnLoad.current.focus();
    console.log(rdxFilterObj.dateFrom, rdxFilterObj.dateTo);
    // if (rdxFilterObj.dateFrom < rdxFilterObj.dateTo) {
    await fGetDetailList();
    // dispatch(ReviewBatchCreationHead(rdxHeadobj));
    // } else {
    //   fAlertToast('FAILED', 'To Date cannot be less then From date');
    // }
  };

  const onPressCreate = async () => {
    const selectedList = sDetailObj.filter((i) => i.selected);
    if (selectedList.length == 0) {
      fAlertToast('FAILED', 'Select at least one Transaction');
    } else {
      confirmAlert({
        title: 'Create',
        message: 'Are you sure to create?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              const updateObj = {
                v_A_DCLoc: rdxHeadobj.DClocation,
                v_User: rdxuserId,
                TransList: selectedList.map((i) => {
                  return { A_TransId: i.A_TransId };
                })
              };
              const result = await createReviewBatch(updateObj);
              if (result.status === 200) {
                fAlertToast('SUCCESS', 'Saved Successfully');
                fGetDetailList();
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onChangeSelect = (transId, checked) => {
    const obj = sDetailObj.map((i) => {
      return i.A_TransId === transId ? { ...i, selected: !checked } : i;
    });
    setDetailObj(obj);
  };

  const onChangeDCLocation = (event) => {
    dispatch(
      ReviewBatchCreationHead({
        ...rdxHeadobj,
        DClocation: event.target.value
      })
    );
  };

  // const onChangeLocList = (event) => {
  //     setHeadObj((obj) => ({
  //         ...obj,
  //         LocList: typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
  //     }));
  // };

  // const onChangeLocList = (event) => {
  //     console.log("ssssssssssssssssss", event);
  //     const selected = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
  //     console.log(selected);
  //     const selectedList = selected.find(i => i === 'All') ? cmpLocList.map(i => i.id) : selected;
  //     setHeadObj((obj) => ({
  //         ...obj,
  //         LocList: selectedList,
  //     }));
  // };

  return (
    <div className="container ReviewBatchCreate">
      <div
      // className="my-2"
      >
        <ReviewBatchCreateBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-2">
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={1.6} md={1.2}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={0.4} sm={0.5} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.9} sm={9.9} md={10.5}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6.5} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={3} md={3.65}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={7}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={5.5} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={3} md={3.6}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={7}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6.5} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpSelectField
                  xLabelText="DC Location"
                  xValue={rdxHeadobj.DClocation}
                  xOnChange={(event) => onChangeDCLocation(event)}
                  xName={'DCLocation'}
                  xData={dcLoclist}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'1'}
                  xref={scanFocusOnLoad}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.5} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3.6}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                {/* <CmpCheckboxField
                  xChecked={rdxHeadobj.DCStatus === 'Y'}
                  xOnChange={fCheckBoxChange}
                  xName={'DC Status'}
                  xNameField={'DC Status'}
                  xOnkeyUp={''}
                  xTabIndex=""
                  xref=""
                /> */}
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="DCStatus"
                  xValue={rdxHeadobj.DCStatus}
                  xOnChange={fCheckBoxChange}
                  xName={'DCStatus'}
                  xData={[
                    {
                      UId: 'N',
                      Name1: 'Market'
                    },
                    {
                      UId: 'Y',
                      Name1: 'DC'
                    }
                  ]}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6.5} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3.2} md={3.6}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={0.9}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={7.8} md={7}>
                <CmpSelectField
                  xTabIndex="5"
                  xLabelText="Department"
                  xValue={rdxHeadobj.Dept}
                  xOnChange={(event) => {
                    dispatch(
                      ReviewBatchCreationHead({
                        ...rdxHeadobj,
                        Dept: event.target.value
                      })
                    );
                  }}
                  xName={'Department'}
                  xData={sDeptList}
                  xValueMember={''}
                  xDispalyMember={''}
                  // xError={sErrorMsg.locCd !== ''}
                  // xErrorMessage={sErrorMsg.locCd}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.5} md={4}>
            <Grid
              container
              spacing={4}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: vMediaQuery ? 'center' : 'end' }}>
              <Grid item xs={4} sm={4} md={4}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onPressCreate}
                  xLabel={'Create'}
                  xIcon={<AddCircle />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '65vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent: sDetailObj.length == 0 ? 'center' : 'start'
          }}>
          {sDetailObj.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : (
            sDetailObj.map((itm) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={itm.A_TransId}>
                  <BatchCreationCard
                    xTransId={itm.A_TransId}
                    xLoc={itm.LocName}
                    xPostDt={moment(itm.A_PostDt).format('DD/MM/YYYY')}
                    xSelected={itm.selected}
                    xDCReturn={itm.DCReturn}
                    xDepartmentCode={itm?.DepartmentName}
                    xOnChangeSelect={() => onChangeSelect(itm.A_TransId, itm.selected)}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
