import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import {
  CmpButton,
  CmpCheckboxField,
  CmpInputField,
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectArrField
} from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';
import moment from 'moment';

import { updateTransHead } from './PurchaseSlice';
import {
  EditConfirmTransaction,
  confirmBufferTransaction,
  getBufferItems,
  updateBufferTransaction
} from './actions';
import OpenPurchaseBreadcrumbs from './ProcessComponents/OpenPurchaseBreadcrumbs';
import './Purchase.css';
import BufferItemsGrid from './ProcessComponents/BufferItemsGrid';
import ConfirmItemGrid from './ProcessComponents/ConfirmItemGrid';
export default function PurchaseConfirmDetail() {
  const dispatch = useDispatch();
  const rdxTransHead = useSelector((state) => state.PurchaseSlice.transHead);

  const [sHeadObj, setHeadObj] = useState(rdxTransHead);
  const [sItemList, setItemList] = useState([]);
  const [sTotalObj, setTotalObj] = useState({
    Taxable: 0,
    Tax: 0,
    Total: 0,
    NetAmt: 0
  });
  const [sGridEditing, setGridEditing] = useState(false);
  const paymodeList = ['CREDIT', 'CASH'];
  const loadItems = useCallback(async () => {
    const obj = {
      transId: rdxTransHead.TransId
    };
    const data = await getBufferItems(obj);
    console.log('api resulttttt' + JSON.stringify(data));

    setItemList(data.ResultSet);
  }, [setItemList, rdxTransHead]);

  // const getVendors = useCallback(async () => {
  //     const vVendor = await getVendorList({});
  //     setVendorList(vVendor.data.ResultSet);
  // }, [setVendorList]);

  // const getVendorOSAmount = async (vendor) => {
  //     const result = await getVendorOSAmt({
  //         AcCd: vendor,
  //         Loc: rdxFilterObj.DCList.toString()
  //     });
  //     const vendorName = sVendorList.find((i) => i.AcCd === vendor)?.Name2;
  //     setHeadObj({ ...sHeadObj, Vendor: vendor, VendorName: vendorName, TRNS_Vendor_Os: Number(result.data?.ResultSet[0].OSAmt) });

  // }

  useEffect(() => {
    loadItems();
    //getVendors();
  }, [loadItems]);

  useEffect(() => {
    let arr = sItemList;
    let taxable = arr.reduce((prevValue, i) => {
      return prevValue + Number(i.Taxable);
    }, 0);
    let tax = arr.reduce((prevValue, i) => {
      return prevValue + Number(i.TaxAmt) + Number(i.TaxAmt2) + Number(i.Ad_NetCessAmt);
    }, 0);

    setTotalObj({
      Taxable: taxable,
      Tax: tax,
      Total: Number(taxable) + Number(tax),
      NetAmt: Number(taxable) + Number(tax) + Number(sHeadObj.Rounding)
    });
  }, [sItemList, sHeadObj]);

  // const fFilterSelectedItems = () => {
  //     setSelectItemChecked(!sSelectItemChecked);

  //     let arr = sHideItemChecked ? sItemList.filter(i => i.ExistingPur < i.OrdQty) : sItemList;
  //     setFilterdItemList(arr.filter(i => i.Qty > 0))
  // }
  // const fFilterPurchasedItems = () => {
  //     setHideItemChecked(!sHideItemChecked);
  //     let arr = sSelectItemChecked ? sItemList.filter(i => i.Qty > 0) : sItemList;
  //     setFilterdItemList(arr.filter(i => i.ExistingPur < i.OrdQty))
  // }

  // const xOnChangeVendor = (event) => {
  //     getVendorOSAmount(event.target.value);
  // }

  const Validation = () => {
    const qtyList = sItemList.filter((i) => Number(i.Qty) < Number(i.ReceivedQty));
    if (qtyList.length > 0) {
      fAlertToast('FAILED', 'Purchase quantity can not be less than received');
      return false;
    }

    const prMisList = sItemList.filter(
      (i) => Number(i.Qty) - Number(i.ReceivedQty) !== Number(i.PRQty)
    );
    if (prMisList.length > 0) {
      fAlertToast('FAILED', 'Purchase Return quantity not matches the difference');
      return false;
    }
    if (Number(sTotalObj.NetAmt).toFixed(2) < Number(rdxTransHead.TRNS_Vendor_Reduction)) {
      fAlertToast('FAILED', 'Net amount should not be less than adjustment amount');
      return false;
    }
    if (Number(sTotalObj.NetAmt).toFixed(2) !== Number(sHeadObj.invoiceAmt).toFixed(2)) {
      fAlertToast('FAILED', 'Invoice amount not matches with Net Amount');
      return false;
    }
    if (sHeadObj.ReferenceNo === '') {
      fAlertToast('FAILED', 'Enter Invoice Number');
      return false;
    }

    const unitRList = sItemList.filter((i) => Number(i.Qty) > 0 && Number(i.UnitRate) <= 0);
    if (unitRList.length > 0) {
      fAlertToast('FAILED', 'UnitRate cannot be zero');
      return false;
    }
    return true;
  };

  const fSave = async () => {
    let itemList = sItemList.map((i) => {
      return {
        TRNS_SRNO: i.SrNo,
        TRNS_ACT_ITMCD: i.ALTItmCd,
        TRNS_QTY: i.Qty,
        TRN_PACK_QTY: i.PackQty,
        TRNS_BASEQTY: i.BaseQty,
        TRNS_UnitRate: i.UnitRate,
        TRNS_GrossAmt: i.GrossAmt,
        TRNS_LDiscAmt: i.LDiscAmt,
        TRNS_TDiscAmt: i.TDiscAmt,
        TRNS_FocAmt: i.FocAmt,
        TRNS_NetAmt: i.Taxable,
        TRNS_TaxAmt: i.TaxAmt,
        TRNS_TaxAmt2: i.TaxAmt2,
        TRNS_ExciseTaxAmt: i.ExciseTaxAmt,
        TRNS_ExciseTaxNetAmt: i.ExciseTaxNetAmt,
        TRNS_Ad_CessAmt: i.Ad_CessAmt,
        TRNS_Ad_PCQty: i.Ad_PCQty,
        TRNS_Ad_PCRate: i.Ad_PCRate,
        TRNS_Ad_NetCessAmt: i.Ad_NetCessAmt,
        TRNS_LandVal: i.Total,
        VendorPackQty: i.VendorPackQty,
        TRN_PR_QTY: i.PRQty,
        ReceivedQty: i.ReceivedQty
      };
    });
    let obj = {
      TransId: sHeadObj.TransId,
      RefNo: sHeadObj.ReferenceNo,
      RefDate: moment(sHeadObj.ReferenceDate).format('MM/DD/YYYY'),
      InvoiceAmt: sHeadObj.invoiceAmt,
      Rounding: sHeadObj.Rounding,
      PartyMobile: sHeadObj.TRNS_PARTY_MOBILE,
      itmlist: itemList,
      Party: rdxTransHead.Vendor,
      paymode: sHeadObj.Paymode,
      dept: ''
    };

    const result = await updateBufferTransaction(obj);
    console.log('result>>>>' + JSON.stringify(result));

    if (result.status === 200) {
      return true;
    }
    return false;
  };

  const fPost = async () => {
    let obj = {
      TransId: sHeadObj.TransId
    };

    const result = await confirmBufferTransaction(obj);
    console.log('result>>>>' + JSON.stringify(result));

    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Posted Successfully');
      setHeadObj({ ...sHeadObj, State: 'CONFIRM' });
    }
  };

  const fSavePurcchaseTrans = async () => {
    if (Validation()) {
      confirmAlert({
        title: 'Save',
        message: 'Are you sure to Save?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              let res = await fSave();
              if (res) {
                fAlertToast('SUCCESS', 'Saved Successfully');
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const fPostPurcchaseTrans = async () => {
    if (Validation()) {
      confirmAlert({
        title: 'Post',
        message: 'Are you sure to Post?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              let saveRes = await fSave();
              if (saveRes) {
                await fPost();
              }
              // setShowPostModal(true);
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const fUpdate = async () => {
    let itemList = sItemList.map((i) => {
      return {
        TRNS_SRNO: i.SrNo,
        TRNS_ACT_ITMCD: i.ALTItmCd,
        TRNS_QTY: i.Qty,
        TRN_PACK_QTY: i.PackQty,
        TRNS_BASEQTY: i.BaseQty,
        TRNS_UnitRate: i.UnitRate,
        TRNS_GrossAmt: i.GrossAmt,
        TRNS_LDiscAmt: i.LDiscAmt,
        TRNS_TDiscAmt: i.TDiscAmt,
        TRNS_FocAmt: i.FocAmt,
        TRNS_NetAmt: i.Taxable,
        TRNS_TaxAmt: i.TaxAmt,
        TRNS_TaxAmt2: i.TaxAmt2,
        TRNS_ExciseTaxAmt: i.ExciseTaxAmt,
        TRNS_ExciseTaxNetAmt: i.ExciseTaxNetAmt,
        TRNS_Ad_CessAmt: i.Ad_CessAmt,
        TRNS_Ad_PCQty: i.Ad_PCQty,
        TRNS_Ad_PCRate: i.Ad_PCRate,
        TRNS_Ad_NetCessAmt: i.Ad_NetCessAmt,
        TRNS_LandVal: i.Total,
        VendorPackQty: i.VendorPackQty,
        TRN_PR_QTY: i.PRQty,
        ReceivedQty: i.ReceivedQty
      };
    });
    let obj = {
      TransId: sHeadObj.TransId,
      InvoiceAmt: sHeadObj.invoiceAmt,
      Rounding: sHeadObj.Rounding,
      itmlist: itemList,
      paymode: sHeadObj.Paymode
    };
    const result = await EditConfirmTransaction(obj);
    console.log('result>>>>' + JSON.stringify(result));

    if (result.status === 200) {
      return true;
    }
    return false;
  };

  const fUpdatePurcchaseTrans = async () => {
    if (Validation()) {
      confirmAlert({
        title: 'Update',
        message: 'Are you sure to Update?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              let res = await fUpdate();
              if (res) {
                fAlertToast('SUCCESS', 'Updated Successfully');
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };
  const onChangePaymode = (event) => {
    setHeadObj({ ...sHeadObj, Paymode: event.target.value });
  };
  return (
    <div className="container">
      <div>
        <ToastContainer />
      </div>
      <div>
        <OpenPurchaseBreadcrumbs xState={sHeadObj.State} />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mb-2">
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fSavePurcchaseTrans}
              xLabel={'Save'}
              xDisable={sHeadObj.State.toString().toUpperCase() !== 'RECEIVED' || sGridEditing}
            />
          </Grid>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fPostPurcchaseTrans}
              xLabel={'Post'}
              xDisable={sHeadObj.State.toString().toUpperCase() !== 'RECEIVED' || sGridEditing}
            />
          </Grid>
          {sHeadObj.State.toString().toUpperCase() === 'CONFIRM' && (
            <Grid item xs={4} sm={2} lg={1} md={1}>
              <CmpButton
                xvariant={'contained'}
                xsize={'small'}
                xonClick={fUpdatePurcchaseTrans}
                xLabel={'Update'}
                xDisable={sHeadObj.UpdateFlag != 0}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={7.5} sm={7.5} lg={7} md={7}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.TransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4.5} sm={4.5} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Direct Invoice'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                <CmpCheckboxField
                  xChecked={sHeadObj.TRNS_Confirm_Inv === 'Y'}
                  xDisabled={true}
                  // xOnChange={(event) => { setHeadObj({ ...sHeadObj, TRNS_Confirm_Inv: event.target.checked ? 'Y' : 'N' }) }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7.5} sm={7.5} lg={7} md={7}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Order No.'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.OrderId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4.5} sm={4.5} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Direct Delivery'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                <CmpCheckboxField
                  xChecked={sHeadObj.TRNS_Direct_Deli === 'Y'}
                  xDisabled={true}
                  // xOnChange={(event) => { setHeadObj({ ...sHeadObj, TRNS_Direct_Deli: event.target.checked ? 'Y' : 'N' }) }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7.5} sm={7.5} lg={7} md={7}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={6} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={sHeadObj.VendorName} />

                {/* <CmpSelectField
                                    xTabIndex="5"
                                    xLabelText="Vendor"
                                    xValue={sHeadObj.Vendor}
                                    xOnChange={(event) => { xOnChangeVendor(event) }}
                                    xName={'Vendor'}
                                    xData={sVendorList}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={true}
                                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4.5} sm={4.5} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Tax Purchase'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                <CmpCheckboxField
                  xChecked={sHeadObj.TaxPurchase === 'Y'}
                  //xOnChange={(event) => { setHeadObj({ ...sHeadObj, TaxPurchase: event.target.checked ? 'Y' : 'N' }) }}
                  xDisabled={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7.5} sm={7.5} lg={7} md={7}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Mobile No.'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={6} lg={5} md={5}>
                <CmpInputField
                  xValue={sHeadObj.TRNS_PARTY_MOBILE}
                  xType={'text'} // text/number/password
                  xName={'mobile'}
                  xReadOnly={false}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, TRNS_PARTY_MOBILE: event.target.value });
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4.5} sm={4.5} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Purchase'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                <CmpCheckboxField
                  xChecked={sHeadObj.DCPurchase === 'Y'}
                  //xOnChange={(event) => { setHeadObj({ ...sHeadObj, TaxPurchase: event.target.checked ? 'Y' : 'N' }) }}
                  xDisabled={true}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={4} sm={4} lg={4} md={4}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={7} sm={7} lg={5} md={5}>
                                <CmpTypographyField xcomponent={'span'} xText={'Selected Items'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={2} sm={2} lg={1} md={1}>
                                <CmpCheckboxField xChecked={sSelectItemChecked} xOnChange={() => fFilterSelectedItems()} />
                            </Grid>
                        </Grid>
                    </Grid> */}
          <Grid item xs={12} sm={12} lg={7} md={7}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}
              alignItems="center">
              <Grid item xs={2.5} sm={2.5} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Invoice Date/No.'} />
              </Grid>
              <Grid item xs={0.65} sm={0.65} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4} sm={2.35} md={2.5}>
                <CmpDatePickerField
                  xTabIndex="2"
                  xValue={sHeadObj.ReferenceDate}
                  xOnChange={(newValue) => {
                    setHeadObj({ ...sHeadObj, ReferenceDate: newValue.$d });
                  }}
                  xName={'ReferenceDate'}
                  xError={''}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
              <Grid item xs={4.8} sm={6.15} md={2.5}>
                <CmpInputField
                  xType={'text'} // text/number/password
                  xName={'RefNo'}
                  xReadOnly={false}
                  xValue={sHeadObj.ReferenceNo}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, ReferenceNo: event.target.value });
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7.5} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.5} sm={4} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Paymode'} />
              </Grid>
              <Grid item xs={0.6} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={6} lg={5} md={5}>
                {/* <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.Paymode} /> */}
                <CmpSelectArrField
                  xLabelText="Paymode"
                  xValue={sHeadObj.Paymode}
                  xOnChange={(event) => {
                    onChangePaymode(event);
                  }}
                  xName={'Paymode'}
                  xData={paymodeList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={sHeadObj.TRNS_Vendor_Reduction != 0}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4.5} lg={7} md={7}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Invoice Amount'} />
              </Grid>
              <Grid item xs={0.6} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={6} lg={5} md={5}>
                <CmpInputField
                  xType={'number'} // text/number/password
                  xName={'Rounding'}
                  xReadOnly={false}
                  xValue={sHeadObj.invoiceAmt}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, invoiceAmt: event.target.value });
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mt-2">
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <div>
              {sItemList.length > 0 && sHeadObj.State === 'RECEIVED' && (
                <BufferItemsGrid
                  rowsData={sItemList}
                  setUpdateRows={setItemList}
                  taxPurchase={sHeadObj.TaxPurchase}
                  editable={sHeadObj.State === 'RECEIVED'}
                  setGridEditing={setGridEditing}
                />
              )}
              {sItemList.length > 0 && sHeadObj.State === 'CONFIRM' && (
                <ConfirmItemGrid
                  rowsData={sItemList}
                  setUpdateRows={setItemList}
                  taxPurchase={sHeadObj.TaxPurchase}
                  editable={sHeadObj.TRNS_Vendor_Reduction === 0}
                  setGridEditing={setGridEditing}
                />
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2 justify-content-between"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={4} sm={4} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              rowSpacing={0}
              sx={{ alignItems: 'end' }}>
              {/* <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={7} sm={7} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Hide Purchase Items'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={1} md={1}>
                                        <CmpCheckboxField xChecked={sHideItemChecked} xOnChange={() => fFilterPurchasedItems()} />
                                    </Grid>
                                </Grid>
                            </Grid> */}
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={7} sm={7} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Vendor O/S'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={4} sm={4} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sHeadObj.TRNS_Vendor_Os).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={6} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              className=" text-end"
              sx={{ alignItems: 'end' }}>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Taxable'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sTotalObj.Taxable).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Tax'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sTotalObj.Tax).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Rounding'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpInputField
                      xType={'text'} // text/number/password
                      xName={'Rounding'}
                      xReadOnly={false}
                      xValue={sHeadObj.Rounding}
                      xOnChange={(event) => {
                        setHeadObj({ ...sHeadObj, Rounding: event.target.value });
                      }}
                      xError={false}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTextAlign="right"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Net Amount'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sTotalObj.NetAmt).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {Number(rdxTransHead.TRNS_Vendor_Reduction) > 0 && (
                <>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                      container
                      spacing={1}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      sx={{ alignItems: 'center' }}>
                      <Grid item xs={5} sm={5} lg={5} md={5}>
                        <CmpTypographyField xcomponent={'span'} xText={'Adjustment'} />
                      </Grid>
                      <Grid item xs={1} sm={1} lg={1} md={1}>
                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <CmpTypographyField
                          xcomponent={'span'}
                          xText={Number(rdxTransHead.TRNS_Vendor_Reduction).toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                      container
                      spacing={1}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      sx={{ alignItems: 'center' }}>
                      <Grid item xs={5} sm={5} lg={5} md={5}>
                        <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                      </Grid>
                      <Grid item xs={1} sm={1} lg={1} md={1}>
                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <CmpTypographyField
                          xcomponent={'span'}
                          xText={(
                            Number(sTotalObj.NetAmt).toFixed(2) -
                            Number(rdxTransHead.TRNS_Vendor_Reduction).toFixed(2)
                          ).toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
