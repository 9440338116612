import { fExecuteAPI } from '../../Server/Apis';
import { fAlertToast } from '../../Utility/Utilitys';

const getPickingSummaryList = async (xObj) => {
  try {
    const vPickList = await fExecuteAPI('GET_PICK_SUMMARY_LIST', xObj);
    return vPickList.data;
  } catch (error) {
    // fAlertToast('FAILED', error.response.data.message);
  }
};

const getPickingDetailItemList = async (xObj) => {
  try {
    const vItemList = await fExecuteAPI('GET_PICK_DETAIL_ITEM_LIST', xObj);
    return vItemList.data;
  } catch (error) {
    // fAlertToast('FAILED', error.response.data.message);
  }
};

const updatePickingItem = async (xObj) => {
  try {
    const vUpdate = await fExecuteAPI('UPDATE_PICK_DETAIL_ITEM', xObj);
    return vUpdate.data;
  } catch (error) {
    // fAlertToast('FAILED', error.response.data.message);
  }
};

const postPickingItem = async (xObj) => {
  try {
    const vPost = await fExecuteAPI('POST_PICK_DETAIL_ITEM', xObj);
    return vPost.data;
  } catch (error) {
    // fAlertToast('FAILED', error.response.data.message);
  }
};

const reversePickingItem = async (xObj) => {
  try {
    const vReverse = await fExecuteAPI('REVERSE_PICK_DETAIL_ITEM', xObj);
    return vReverse;
  } catch (error) {
    // fAlertToast('FAILED', error.response.data.message);
  }
};

export {
  getPickingSummaryList,
  getPickingDetailItemList,
  updatePickingItem,
  postPickingItem,
  reversePickingItem
};
