import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import grapes from '../../../Images/grapes.jpg';
import InquiryTable from './InquiryTable';
import styled from "styled-components";
import { getVendorPriceEnquiry } from '../action';
import { CmpTypographyField } from '../../../component/ToolBox/ToolBox';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #0d73bb',
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    // alignItems: "center",
    borderRadius: 3,
    height: 'fit-content',
    margin: '0 auto',
    color: '#0d73bb'
};
const StyledParagraph = styled.p`
        font-size:13px;
        padding:2px;
        margin:0 auto;
        color:#000;
`;

export default function Inuiry(props) {
    const [open, setOpen] = React.useState(false);
    const [sItemList, setItemList] = useState([]);

    const currentDate = new Date();
    const handleOpen = async (itmCd) => {
        const reqObj = {
            ItmCd: itmCd,
            Date: moment(currentDate).format("MM/DD/YYYY"),
            PackQty: 1
        }

        const res = await getVendorPriceEnquiry(reqObj);
        setItemList(res.ResultSet);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    return (
        <div>
            <>
                <Button
                    onClick={() => handleOpen(props.data.ItmCd)}
                    sx={{
                        width: '5rem',
                        fontSize: '12px',
                        textTransform: 'Capitalize',
                        margin: '1rem'
                    }}
                    variant="contained"
                    size="small"
                >Inquiry</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <b>{props.data.ItmName.toUpperCase()}</b>
                        <CloseIcon sx={{
                            float: 'right'
                        }} onClick={() => setOpen(false)} />
                        <Grid container spacing={2} justifyContent='center'>
                            <Grid item xs={3}>
                                <Avatar sx={{
                                    width: 80, height: 80,
                                    border: '3px solid #3498db',
                                    backgroundColor: 'white'
                                }}
                                    alt=" "
                                    src={`${props.data.IMAGE_BASE_URL}product/${props.data.Barcode}.jpg?imgVer=${props.data.Version}`}
                                >

                                </Avatar>
                            </Grid>
                            <Grid item xs={8} align="left">
                                <Grid container spacing={2} >
                                    <Grid item xs={6} >
                                        {/* <StyledParagraph>Item code</StyledParagraph> */}
                                        <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
                                        {/* <Typography variant='h3'><b>Item code</b></Typography> */}
                                    </Grid>
                                    <Grid item xs={6} >
                                        {/* <StyledParagraph>{props.data.ItmCd}</StyledParagraph> */}
                                        <CmpTypographyField xcomponent={'span'} xText={props.data.ItmCd} />
                                        {/* <Typography variant='h3'><b>2345678</b></Typography> */}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        {/* <StyledParagraph>Date</StyledParagraph> */}
                                        <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                                        {/* <Typography variant='h3'><b>Date</b></Typography> */}
                                    </Grid>
                                    <Grid item xs={6} >
                                        {/* <StyledParagraph>{moment(currentDate).format("DD/MM/YYYY")}</StyledParagraph> */}
                                        <CmpTypographyField xcomponent={'span'} xText={moment(currentDate).format("DD/MM/YYYY")} />

                                        {/* <Typography variant='h3'><b>2/24/2023</b></Typography> */}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        {/* <StyledParagraph>Pack Qty</StyledParagraph> */}
                                        <CmpTypographyField xcomponent={'span'} xText={'Pack Qty'} />
                                        {/* <Typography variant='h3'><b>Pack Qty</b></Typography> */}
                                    </Grid>
                                    <Grid item xs={6} >
                                        {/* <StyledParagraph>1</StyledParagraph> */}
                                        <CmpTypographyField xcomponent={'span'} xText={'1'} />
                                        {/* <Typography variant='h3'><b>1</b></Typography> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <InquiryTable row={sItemList} />
                    </Box>
                </Modal>
            </>

        </div >
    );
}
