import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  head: {
    location: '',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(),
    status: 'All',
    DCStatus: 'All',
    DC: '',
    DCReturn: 'N'
  },
  detail: {
    A_TransId: '0',
    A_PostDt: new Date(),
    A_Loc: '',
    LocName: '',
    A_DCLoc: '',
    DCLocName: '',
    A_DocStatus: 'Enter',
    A_Type: '',
    A_DCStatus: '',
    Truck: '',
    v_isDcReturn: 'N',
    Dept: ''
  }
};

const ReturnRequestSlice = createSlice({
  name: 'ReturnRequestSlice',
  initialState: { value: initialState },

  reducers: {
    ReturnRequest_Head: (state, action) => {
      state.value.head = action.payload;
    },
    ReturnRequest_RowDetail: (state, action) => {
      state.value.detail = action.payload;
    },
    ReturnRequest_RowDetail_TransID: (state, action) => {
      state.value.detail.A_TransId = action.payload.A_TransId;
    },
    ReturnRequest_RowDetail_DOC_Status: (state, action) => {
      state.value.detail.A_DocStatus = action.payload.A_DocStatus;
    },
    ReturnRequest_Init: (state) => {
      state.value = initialState;
    },
    ReturnRequest_RowDetail_TruckID: (state, action) => {
      state.value.detail.Truck = action.payload.Truck;
    }
  }
});

export const {
  ReturnRequest_Head,
  ReturnRequest_RowDetail,
  ReturnRequest_Init,
  ReturnRequest_RowDetail_TransID,
  ReturnRequest_RowDetail_DOC_Status,
  ReturnRequest_RowDetail_TruckID
} = ReturnRequestSlice.actions;
export default ReturnRequestSlice.reducer;
