import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  head: {
    location: '',
    status: 'All',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date()
  },
  detail: {
    A_TransId: '',
    A_PostDt: new Date(),
    A_Loc: '',
    LocName: '',
    A_Driver: '',
    DriverName: '',
    A_Truck: '',
    TruckName: '',
    A_ReqTransId: 0,
    A_Type: '',
    A_PickStatus: '',
    A_PickDt: '',
    A_DispatchStatus: '',
    A_DispatchDt: '',
    A_RefNo: '',
    A_Narration: '',
    A_Vendor: '',
    Vendorname: '',
    ItemCount: 0
  }
};

const PickingtSlice = createSlice({
  name: 'PickingSlice',
  initialState: { value: initialState },

  reducers: {
    Picking_Head: (state, action) => {
      state.value.head = action.payload;
    },
    Picking_Detail: (state, action) => {
      state.value.detail = action.payload;
    },
    Picking_Detail_RefNo: (state, action) => {
      state.value.detail.A_RefNo = action.payload;
    },
    Picking_Detail_Narration: (state, action) => {
      state.value.detail.A_Narration = action.payload;
    },
    Picking_Detail_UpdateStatus: (state, action) => {
      state.value.detail.A_PickStatus = action.payload;
    }
  }
});

export const {
  Picking_Head,
  Picking_Detail,
  Picking_Detail_RefNo,
  Picking_Detail_Narration,
  Picking_Detail_UpdateStatus
} = PickingtSlice.actions;
export default PickingtSlice.reducer;
