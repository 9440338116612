import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
function PurchaseReturnBreadCrumbs() {
  const location = useLocation();
  const vPathName = location.pathname;
  return (
    <div className="container-fluid p-0 mt-0 mb-4 breadcrumb">
      {vPathName === '/PurchaseReturn' ? (
        <div>
          <span className="breadcrumb-arrow-inactive ">
            PR Batch Summary{' '}
          </span>
        </div>
      ) : vPathName === '/BatchDetail' ? (
        <div className="d-flex">
          <Link
            to="/PurchaseReturn"
            state={location}
            className={'breadcrumb-arrow-active mr-2'}>
            PR Batch Summary
          </Link>
          {/* <ArrowForwardIos color="info" style={{ marginRight: '1px' }} /> */}
          <div>
            <span className="breadcrumb-arrow-inactive">
              PR Batch Detail{' '}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default PurchaseReturnBreadCrumbs;
