import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderHead: {
    orderId: 0,
    status: 'ENTER',
    orderDate: new Date(),
    purCompletion: 0,
    purAccuracy: 0,
    deviation: 0,
    costDeviation: 0,
    marketPurchase: 'Y',
    purchaseCount: 0,
    dcPurchase: 'N',
  }
};
const OrderSlice = createSlice({
  name: 'OrderSlice',
  initialState: initialState,
  reducers: {
    updateOrderHead: (state, action) => {
      state.orderHead = action.payload;
    },

    updateOrderHeadSatus: (state, action) => {
      state.orderHead.status = action.payload;
    }
  }
});

export const { updateOrderHead, updateOrderHeadSatus } = OrderSlice.actions;
export default OrderSlice.reducer;
