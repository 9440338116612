import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton,
  StatusIcon,
  WarehouseIconForDC
} from '../../../component/ToolBox/ToolBox';
import WarehouseIcon from '@mui/icons-material/Warehouse';

function DispatchSumarryCard({
  xTransId,
  xTruck,
  xDate,
  xStatus,
  xLoc,
  xNumberOfItems,
  xType,
  xVendor,
  xStkTsrNo,
  xPrNo,
  xDCReturn,
  item
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xTransId} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xTruck} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xDate} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Type'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <CmpTypographyField xcomponent={'span'} xText={xType} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <WarehouseIconForDC xDCReturn={xDCReturn} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xLoc} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'No.of.Item'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xNumberOfItems} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Stock Trans.'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xStkTsrNo} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'PR DocNo'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <CmpTypographyField xcomponent={'span'} xText={xPrNo} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <StatusIcon xStatus={xStatus} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'RequestID'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={item.RequestID} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} md={4}>
                  <CmpTypographyField xcomponent={'span'} xText={'Type'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={7} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={item.Type} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={2} sm={2} md={2}>
                  <CmpTypographyField xcomponent={'span'} xText={'Department'} />
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={0.5}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={9.5} sm={9.5} md={9.5}>
                  <CmpTypographyField xcomponent={'span'} xText={item.DepartmentName} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* {xType == 'PR' && ( */}
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center', visibility: xType === 'PR' ? 'show' : 'hidden' }}>
                <Grid item xs={2} sm={2} md={2}>
                  <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={0.5}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={9.5} sm={9.5} md={9.5} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={xVendor} />
                </Grid>
              </Grid>
              {/* )}      */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default DispatchSumarryCard;
