import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function FshOrderBreadCrumbs({ xStatus }) {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 mt-0 mb-4 breadcrumb">
            {vPathName === '/PendingFishOrder' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Pending Orders</span>
                </div>
            ) : vPathName === '/CompletedFishOrder' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Completed Orders</span>
                </div>
            ) : vPathName === '/FishOrderDetail' ? (
                <div className="d-flex">
                    <Link
                        to={xStatus == 'ENTER' ? "/PendingFishOrder" : "/CompletedFishOrder"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        {xStatus == 'ENTER' ? "Pending Orders" : "Completed Orders"}
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Order Detail </span>
                    </div>
                </div>
            ) : (
                <></>

            )}
        </div>
    );
}
