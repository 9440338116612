import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import {
  CmpButtonWithIcon,
  CmpSelectArrField,
  CmpTypographyField
} from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { updateTransferFilterStatus, updateTransferHead } from './OutletRequestSlice';
import { Refresh } from '@mui/icons-material';
import RequestBreadCrumbs from './ProcessComponents/RequestBreadCrumbs';
import { getRequestTransferSummary } from './actions';
import TransferBreadCrumbs from './ProcessComponents/TransferBreadCrumbs';

export default function RequestTransferSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusData = ['All', 'ENTER', 'POST'];
  const [sStatus, setStatus] = useState('All');
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const rdxFilterStatus = useSelector(
    (state) => state.OutletRequestSlice.value.transferFilterStatus
  );
  const vMediaQuery = useMediaQuery('(max-width: 900px)');
  const xMediaQuery = useMediaQuery('(max-width: 900px)');

  const [sFilteredLocNames, setFilteredLocNames] = useState([]);
  const [sFilteredDCNames, setFilteredDCNames] = useState([]);
  const [sDetail, setDetail] = useState([]);

  const fGetDetailList = async (xStatus) => {
    const obj = {
      locList: rdxFilterObj.locList.toString(),
      DCLocList: rdxFilterObj.DCList.toString(),
      status: xStatus,
      fromDate: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      toDate: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY')
    };
    const result = await getRequestTransferSummary(obj);

    if (result?.ResultSet?.length > 0) {
      setDetail(result.ResultSet);
    } else {
      setDetail([]);
    }
    return result.ResultSet;
  };

  const fOnPageLoad = useCallback(async () => {
    let filteredDC = dcLoclist.filter((i) => rdxFilterObj.DCList.includes(i.UId));
    setFilteredDCNames(filteredDC.map((i) => i.Name1));

    let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    setFilteredLocNames(filteredLoc.map((i) => i.Name1));

    let status = rdxFilterStatus;
    setStatus(status);
    await fGetDetailList(status);
  }, [rdxFilterObj, locList, dcLoclist]);

  const fOnpressLoad = async () => {
    await fGetDetailList(sStatus);
    dispatch(updateTransferFilterStatus(sStatus));
  };
  useEffect(() => {
    fOnPageLoad();
  }, [fOnPageLoad]);

  const fOnRowClick = (row) => {
    dispatch(
      updateTransferHead({
        vocTyp: row.T_VocTyp,
        vocName: row.vocName,
        vocNo: row.T_VocNo,
        vocDt: row.T_VocDt,
        loc: row.T_Loc,
        locName: row.LocName,
        dcLoc: row.DCLoc,
        dcLocName: row.DCLocName,
        status: row.T_DocStat,
        tsrVocTyp: row.TSRVocTyp,
        tsrVocNo: row.TSRVocNo,
        postDt: row.PostDt
      })
    );
    navigate('/RequestTransferDetail');
  };

  const gridColumns = [
    {
      field: 'T_VocTyp',
      headerName: 'Voc. Type',
      // flex: 1,
      width: vMediaQuery ? 150 : 200,
      hide: true
    },
    {
      field: 'vocName',
      headerName: 'Voucher',
      // flex: 3,
      width: vMediaQuery ? 150 : 200
    },
    {
      field: 'T_VocNo',
      headerName: 'Voucher No.',
      // flex: 2
      width: vMediaQuery ? 150 : 150
    },
    {
      field: 'T_VocDt',
      headerName: 'Date',
      // flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY'),
      width: vMediaQuery ? 120 : 150
    },
    {
      field: 'LocName',
      headerName: 'Branch',
      // flex: 2
      width: vMediaQuery ? 120 : 150
    },
    {
      field: 'DCLocName',
      headerName: 'Distributing Location',
      // flex: 2
      width: vMediaQuery ? 120 : 150
    },
    {
      field: 'T_DocStat',
      headerName: 'Status',
      // flex: 1
      width: vMediaQuery ? 100 : 150
    },
    {
      field: 'PostDt',
      headerName: 'Post Date',
      // flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY'),
      width: vMediaQuery ? 120 : 150
    }
  ];
  return (
    <div className="container">
      <div
      // className="my-2"
      >
        <TransferBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-2">
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2.5} md={1.5}>
                <CmpTypographyField xcomponent={'span'} xText={'Branch'} />
              </Grid>
              <Grid item xs={1} sm={0.5} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.3} sm={9} md={10.2}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2.5} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Distributing Location'} />
              </Grid>
              <Grid item xs={1} sm={0.5} md={0.6}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.3} sm={8} md={8.4}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={5.05} md={4.6}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4.5} sm={5.95} md={6.4}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4.5} sm={7} md={7}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={10} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={1} sm={0.6} md={0.6}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.3} sm={7.5} md={8.4}>
                <CmpSelectArrField
                  xLabelText="Status"
                  xValue={sStatus}
                  xOnChange={(event) => setStatus(event.target.value)}
                  xName={'Status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2} md={6}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{
                display: 'flex',
                justifyContent: vMediaQuery ? 'center' : 'end',
                alignItems: 'center'
              }}>
              <Grid item xs={3} sm={12} md={2.5}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={() => fOnpressLoad()}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          width="100%"
          sx={{
            height: vMediaQuery ? '65vh' : '65vh',
            width: '100%',
            '& .actions': {
              color: 'text.secondary'
            },
            '& .textPrimary': {
              color: 'text.primary'
            },
            '& .Mui-error': {
              backgroundColor: `rgb(126,10,15, 0.1})`,
              color: '#ff4343'
            }
          }}>
          <DataGrid
            rows={sDetail}
            getRowId={(row) => row.T_VocTyp + row.T_VocNo + row.T_Loc}
            columns={gridColumns}
            rowHeight={50}
            headerHeight={60}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            onRowClick={(params) => fOnRowClick(params.row)}
          />
        </Box>
      </Box>
    </div>
  );
}
