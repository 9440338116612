import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import {
  CmpButton,
  CmpButtonWithIcon,
  CmpSelectArrField,
  CmpTypographyField,
  CmpCheckboxField,
  CmpSelectField
} from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList, getRequestSummary } from './actions';
import { DataGrid } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  resetRequestHead,
  updateRequestHead,
  updateSummaryFilterStatus,
  updateSummaryObj
} from './OutletRequestSlice';
import { AddCircle, Refresh } from '@mui/icons-material';
import RequestBreadCrumbs from './ProcessComponents/RequestBreadCrumbs';

export default function PuchaseRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusData = ['All', 'ENTER', 'POST', 'DELETE'];

  const vMediaQuery = useMediaQuery('(max-width:800px)');
  // const [sStatus, setStatus] = useState('All');
  const [centerPurchase, setCenterPurchase] = useState('N');
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const rdxSummaryObj = useSelector((state) => state.OutletRequestSlice.value.summaryObj);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxFilterStatus = useSelector(
    (state) => state.OutletRequestSlice.value.summaryFilterStatus
  );
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);
  const [sDeptList, setDeptList] = useState([]);
  const [sFilteredLocNames, setFilteredLocNames] = useState([]);
  const [sDetail, setDetail] = useState([]);

  const fSummary_OnChange = (e) => {
    dispatch(
      updateSummaryObj({
        ...rdxSummaryObj,
        [e.target.name]: e.target.value
      })
    );
  };

  const fGetDetailList = async (dept = rdxSummaryObj?.dept) => {
    const obj = {
      locList: rdxFilterObj.locList.toString(),
      status: rdxSummaryObj?.status === '' ? statusData[0] : rdxSummaryObj?.status,
      fromDate: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      toDate: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      CNTRL_PUR: rdxSummaryObj?.centerPurchase,
      v_Dept: dept
    };
    const result = await getRequestSummary(obj);

    if (result?.ResultSet?.length > 0) {
      setDetail(result.ResultSet);
    } else {
      setDetail([]);
    }
    return result.ResultSet;
  };

  const fOnPageLoad = useCallback(async () => {
    const result = await getDepartmentList();
    setDeptList(result?.ResultSet);
    if (rdxSummaryObj.dept == '') {
      dispatch(updateSummaryObj({ ...rdxSummaryObj, dept: result?.ResultSet[0].Cd }));
      await fGetDetailList(result?.ResultSet[0].Cd);
    } else {
      await fGetDetailList();
    }
    let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    setFilteredLocNames(filteredLoc.map((i) => i.Name1));
    // setStatus(rdxFilterStatus);
  }, [rdxFilterObj, locList]);

  const fOnpressLoad = async () => {
    await fGetDetailList();
    // dispatch(updateSummaryFilterStatus(sStatus));
    // dispatch(updateSummaryFilterStatus(centerPurchase));
  };
  useEffect(() => {
    fOnPageLoad();
    console.log('caleed 1.....');
  }, [fOnPageLoad]);

  const fOnRowClick = (row) => {
    dispatch(
      updateRequestHead({
        vocTyp: row.A_VocTyp,
        vocNo: row.A_VocNo,
        vocDt: row.A_VocDt,
        loc: row.A_Loc,
        dept: row.A_Dept,
        CNSType: row.A_CNSType,
        CNSDt1: row.A_CNSDt1,
        CNSDt2: row.A_CNSDt2,
        CNSDays: row.A_CNSDays,
        CNSRcndDays: row.A_CNSRcndDays,
        CNSRcndDay: row.A_CNSRcndDay,
        docStat: row.A_DocStat,
        leadDays: row.A_LeadDays,
        DC_Purchase: row.A_DC_Purchase,
        CNTRL_PUR: row.CNTRL_PUR
      })
    );
    navigate('/PurchaseRequestDetail001');
  };

  const fNew = () => {
    dispatch(resetRequestHead());
    navigate('/PurchaseRequestDetail001');
  };

  const gridColumns = [
    {
      field: 'A_VocTyp',
      headerName: 'Voc. Type',
      flex: 1,
      hide: true
    },
    {
      field: 'vocName',
      headerName: 'Voucher',
      flex: 2,
      hide: true
    },
    {
      field: 'A_VocNo',
      headerName: 'Voucher No.',
      flex: 1
    },
    {
      field: 'A_VocDt',
      headerName: 'Date',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
    },
    {
      field: 'Type',
      headerName: 'Type',
      flex: 1,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'LocName',
      headerName: 'Location',
      flex: 1
    },
    {
      field: 'DeptName',
      headerName: 'Department',
      flex: 2
    },
    {
      field: 'A_DocStat',
      headerName: 'Status',
      flex: 1
    }
  ];
  return (
    <div className="container">
      <div
      // className="my-2"
      >
        <RequestBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-4">
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.8} sm={8.5} md={10.7}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />

                {/* <CmpMultiSelectAll
                                    xLabelText="Location"
                                    xValue={sHeadObj.LocList}
                                    xOnChange={(event) => onChangeLocList(event)}
                                    xName={'Location'}
                                    xData={locList.map(i => { return { id: i.UId, name: i.Name1 } })}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                    xTabIndex={'4'}
                                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.5} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5.5} sm={7} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={0.5} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={7} md={8}>
                <CmpSelectArrField
                  xLabelText="status"
                  xValue={rdxSummaryObj?.status}
                  xOnChange={(event) => fSummary_OnChange(event)}
                  xName={'status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <Grid item xs={2.7} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={0.5} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={7} md={8}>
                {/* <CmpCheckboxField
                    xChecked={centerPurchase === 'Y'}
                    xOnChange={() => {
                      setCenterPurchase(centerPurchase === 'Y' ? 'N' : 'Y');
                    }}
                  /> */}
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="centerPurchase"
                  xValue={rdxSummaryObj?.centerPurchase}
                  xOnChange={(event) => fSummary_OnChange(event)}
                  xName={'centerPurchase'}
                  xData={[
                    {
                      UId: 'Y',
                      Name1: 'Market'
                    },
                    {
                      UId: 'N',
                      Name1: 'DC'
                    }
                  ]}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={0.5} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.8} sm={7} md={8}>
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="dept"
                  xValue={rdxSummaryObj?.dept}
                  xOnChange={(event) => fSummary_OnChange(event)}
                  xName={'dept'}
                  xData={sDeptList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* {cPurchaseFlg && ( */}

          {/* )} */}

          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={4}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: vMediaQuery ? 'center' : 'end' }}>
              <Grid item xs={4} sm={4} md={3}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={() => fOnpressLoad()}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fNew}
                  xLabel={'New'}
                  xIcon={<AddCircle />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          width="100%"
          sx={{
            height: '70vh',
            width: '100%',
            '& .actions': {
              color: 'text.secondary'
            },
            '& .textPrimary': {
              color: 'text.primary'
            },
            '& .Mui-error': {
              backgroundColor: `rgb(126,10,15, 0.1})`,
              color: '#ff4343'
            }
          }}>
          <DataGrid
            rows={sDetail}
            getRowId={(row) => row.A_VocTyp + row.A_VocNo}
            columns={gridColumns}
            rowHeight={50}
            headerHeight={60}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: '93vw' } }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            onRowClick={(params) => fOnRowClick(params.row)}
          />
        </Box>
      </Box>
    </div>
  );
}
