import { Delete, DeleteOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

export default function UnloadBatchGrid({
  batchInfo,
  setHeadObj,
  sReceivingList,
  onDeleteClick
}) {
  const onDeleteCellClick = async (params) => {
    console.log(params);
    console.log('on delete Click called');
    onDeleteClick(params.row.C_RowID);
  };

  const fOnCellClick = (params) => {
    if (params.field !== 'delete') {
      console.log(params);

      setHeadObj({
        rowId: Number(params.row.C_RowID),
        qty: Number(params.row.C_Qty)
      });
    }
  };

  const columns = [
    {
      field: 'C_RowID',
      headerName: 'Row.ID',
      hide: false,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      Typ: 'S',
      NumberFormat: '0'
    },
    {
      field: 'C_Qty',
      headerName: 'Qty',
      hide: false,
      width: 200,
      align: 'right',
      headerAlign: 'right',
      Typ: 'N',
      NumberFormat: '0',
      valueFormatter: ({ value }) => Number(value).toFixed(2)
    },
    {
      field: 'B_Unit',
      headerName: 'Unit',
      hide: true,
      width: 200,
      align: 'left',
      headerAlign: 'left',
      Typ: 'S',
      NumberFormat: '0'
    },
    {
      field: 'UnitName',
      headerName: 'Unit.Name',
      hide: false,
      width: 250,
      align: 'left',
      headerAlign: 'left',
      Typ: 'S',
      NumberFormat: '0'
    },
    {
      field: 'id',
      headerName: 'id',
      hide: false,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      Typ: 'S',
      NumberFormat: '0'
    },

    {
      field: 'delete',
      headerName: '',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: false,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onDeleteCellClick(params);
            }}
            disabled={batchInfo.Status.toString().toUpperCase() !== 'ENTER'}>
            <Delete />
          </IconButton>
        );
      }
    }
  ];

  return (
    <Box
      sx={{
        height: '40vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary'
        },
        '& .textPrimary': {
          color: 'text.primary'
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, 0.1})`,
          color: '#ff4343'
        }
      }}>
      <DataGrid
        rows={sReceivingList}
        // getRowId={(row) => row.SrNo}
        columns={columns}
        rowHeight={45}
        headerHeight={45}
        sx={{
          border: 2,
          borderColor: 'primary.light'
        }}
        componentsProps={{
          filterPanel: { sx: { maxWidth: "93vw" } }
        }}
        onCellClick={(params) => {
          fOnCellClick(params);
        }}
        hideFooter={true}
      />
    </Box>
  );
}
