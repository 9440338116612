import { toast } from 'react-toastify';
import axios from 'axios';
import { fExecuteAPI } from '../Server/Apis';
import moment from 'moment';
const handleError = (errMsg, needToUpdateInDb) => {
  if (needToUpdateInDb) {
    // TODO: update server about error
  }
};

//ALERT
const fAlertToast = (xIsSucccess, xMessage) => {
  let vToasterStyle = {
    position: 'top-right',
    // autoClose: xIsSucccess === 'SUCCESS' ? 800 : false,
    // hideProgressBar: false,
    // closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
    delay: 0
  };

  if (xIsSucccess === 'SUCCESS') {
    return toast.success(xMessage, vToasterStyle);
  } else if (xIsSucccess === 'FAILED') {
    return toast.error(xMessage, vToasterStyle);
  } else if (xIsSucccess === 'WARNING') {
    return toast.warning(xMessage, vToasterStyle);
  } else if (xIsSucccess === 'INFO') {
    return toast.info(xMessage, vToasterStyle);
  }
};
const fCheck_Doc_Rights_001 = (xDocStatus, xVocTyp, xDocRightsArr, rdxuser) => {
  var obj = {
    RNew: 'Y',
    RSave: 'Y',
    RDelete: xDocStatus === 'ENTER' ? 'Y' : 'N',
    RApprove: xDocStatus === 'ENTER' || xDocStatus === 'DRAFT' ? 'Y' : 'N',
    RPrint: 'Y',
    RReverse: 'Y',
    RClone: 'Y'
  };

  const vRdxUserInfoObj = rdxuser;
  if ((vRdxUserInfoObj?.userGroup).toString() === '01') {
    return obj;
  } else {
    let vDocRights = xDocRightsArr?.filter(
      (rights) => rights?.Lvl === 1 && rights?.DocTyp === xVocTyp
    );

    if (vDocRights?.length === 0) {
      vDocRights = xDocRightsArr?.filter(
        (rights) => rights?.Lvl === 2 && rights?.DocTyp === xVocTyp
      );
    }

    vDocRights = vDocRights?.length > 0 ? vDocRights : [];

    if (vDocRights?.length === 0) {
      return obj;
    } else {
      obj.RNew = vDocRights[0]?.Save;
      obj.RSave = xDocStatus === 'ENTER' ? vDocRights[0]?.Edit : vDocRights[0]?.Reverse;
      obj.RDelete = xDocStatus === 'ENTER' ? vDocRights[0]?.Del : 'N';
      obj.RApprove = xDocStatus === 'ENTER' ? vDocRights[0]?.Appr : 'N';
      obj.RPrint = vDocRights[0]?.Print;
      obj.RReverse = vDocRights[0]?.Reverse;
      obj.RClone = vDocRights[0]?.Clone;
      return obj;
    }
  }
};

const getGeoLocation = async () => {
  const res = await axios.get('https://geolocation-db.com/json/');
  return res?.data?.IPv4 || '';
};

const getGitterColumns = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_GITTER_COLUMNS', xObj);
    return result.data;
  } catch (error) {}
};
export const GetGitterColumnsV2 = async (
  setSummaryGridColumn,
  // xtrnTyp = "",
  xObj = {},
  xSummary_Grid_ColumnsDummy
) => {
  try {
    const vGitterColumns = await fExecuteAPI('GET_GITTER_COLUMNS', xObj);
    if (vGitterColumns?.status === 200) {
      if (vGitterColumns?.data?.ResultSet?.columns?.length > 0) {
        let { columns } = vGitterColumns?.data?.ResultSet;
        // columns = await columns.json();
        // return data;
        columns.forEach((obj) => {
          obj.headerClassName = 'grid-header-row';
          if (obj.Typ === 'D') {
            obj.valueFormatter = ({ value }) => moment(value).format(obj.NumberFormat);
          } else if (obj.Typ === 'N') {
            let iNumFormat =
              obj.Typ === 'N' ? (!!obj?.NumberFormat ? Number(obj?.NumberFormat) : 3) : 3;
            obj.valueFormatter = ({ value }) => {
              return Number(value)?.toFixed(iNumFormat) || Number(value)?.toFixed(3);
            };
          }
        });

        // setSummaryGridColumn(columns);
        console.log('columns', columns);
        setSummaryGridColumn(columns.filter((x) => x.hide === false));
      } else {
        setSummaryGridColumn(xSummary_Grid_ColumnsDummy);
        // fAlertToast("FAILED", "GitterColumns (" + xtrnTyp + ") is Empty");
      }
    } else {
      setSummaryGridColumn(xSummary_Grid_ColumnsDummy);
    }
  } catch (error) {}
};

export { handleError, fAlertToast, fCheck_Doc_Rights_001, getGeoLocation, getGitterColumns };
