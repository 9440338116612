import { React, useCallback, useEffect } from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { CmpButton, CmpTypographyField } from '../../component/ToolBox/ToolBox';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
import { styled as styleMui } from '@mui/material/styles';
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import Modal from '@mui/material/Modal';
import OrderCardContent from './ProcessComponents/OrderCardContent';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { getOrderItems } from './actions';
import FshOrderBreadCrumbs from './ProcessComponents/FshOrderBreadCrumbs';
import { Circle } from '@mui/icons-material';
import OrderPostModal from './ProcessComponents/OrderPostModal';

const StyledDiv = styled.div`
overflow: auto;
display: block;
// height: 35rem ;
text-align:center;
//margin:auto 3rem;
`;

const BoxDiv = styled.div`
border: 1px solid #0d73be;
border-radius: 5px;
// background-color: #0d73bb;
// color: #fff;
margin:5px;
// text-align: center;
// cursor:pointer;
box-shadow: 0px 0px 3px 0px #0d73be;
//max-width:20rem;
// padding:5px;
// height:10rem;
`;

const PurchaseStatusIcon = ({ purchasedQty, balQty }) => {
    return (
        <Circle style={{ color: purchasedQty == 0 ? 'gray' : balQty > 0 ? '#f1df09' : '#15d218', fontSize: '.9rem' }} />
    )
}
export default function FishOrderDetail() {


    const [sPostModal, setPostModal] = useState(false);
    const [sItemList, setItemList] = useState([]);

    const rdxOrderHead = useSelector((state) => state.FishPurchaseSlice.orderHead);
    const fShowPostModal = (show) => {
        setPostModal(show);
    };

    const loadDetails = useCallback(async () => {
        let reqObj = {
            "orderId": rdxOrderHead.orderId
        }
        const res = await getOrderItems(reqObj);

        setItemList(res.ResultSet);

    }, [rdxOrderHead, getOrderItems, setItemList]);


    useEffect(() => {
        loadDetails();
    }, [loadDetails]);


    const Item = styleMui('div')(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    return (
        <div className="container">
            <StyledDiv>
                <div 
                // className="my-2"
                >
                    <FshOrderBreadCrumbs xStatus={rdxOrderHead.status} />
                </div>
                <div>
                    <ToastContainer />
                </div>
                <Grid
                    container
                    spacing={0}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    className="mb-4"
                    sx={{ justifyContent: 'start' }}

                >
                    <Grid item xs={5} sm={1} md={1.5} lg={1} align="left">
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={() => fShowPostModal(true)}
                            xLabel={'Post'}
                            xDisable={rdxOrderHead.status?.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    className="mb-2 text-start"
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Order ID'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxOrderHead.orderId} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={moment(rdxOrderHead.orderDate).format("DD/MM/YYYY")} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'No. of Invoices'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxOrderHead.purchaseCount} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Purchase Completion'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxOrderHead.purCompletion + '%'} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Purchase Accuracy'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxOrderHead.purAccuracy + '%'} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Deviation'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxOrderHead.deviation} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Cost Deviation'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={5} sm={6} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxOrderHead.costDeviation} />
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                <Modal
                    open={sPostModal}
                    onClose={() => setPostModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box className="model-sales-invoice-GCVanMaster-Modal">
                        <OrderPostModal orderNo={rdxOrderHead.orderId} onClose={setPostModal} itemList={sItemList} />
                    </Box>
                </Modal>
                <Grid container spacing={.5} sx={{ height: '62vh', overflowY: 'auto', paddingRight: '10px' }}>
                    {sItemList.map((value, i) =>
                        <Grid item xs={12} sm={6} md={3} lg={3} key={value.ItmCd}>
                            {/* <Box> */}
                            <BoxDiv>
                                {/* <CmpTypographyField xcomponent={'span'} xText={value.item.toUpperCase()} /> */}
                                {/* <Typography variant='body2'>{}</Typography> */}
                                <Card variant="outlined" onClick={() => {
                                    // navigate('/OrderDetail');
                                }}>
                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                                    <Grid container spacing={1} className='pt-2' >
                                        <Grid item xs={11} >
                                            <CmpTypographyField xVariant='h3' xcomponent={'span'} xText={value.ItmName} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <PurchaseStatusIcon purchasedQty={value.PurchasedQty} balQty={value.BasseQty - value.PurchasedQty} />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    {/* <h6 style={{ color: '#0d73bb' }}><b>{value.item.toUpperCase()}</b></h6><br /> */}
                                    <OrderCardContent key={value.ItmCd} data={value} />
                                </Card>
                            </BoxDiv>
                            {/* </Box > */}
                        </Grid>
                    )}
                </Grid>


            </StyledDiv>
        </div >
    );

}