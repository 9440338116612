import { Close } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { CmpInputField, CmpTypographyField } from '../../../component/ToolBox/ToolBox';
import { fAlertToast } from '../../../Utility/Utilitys';
import { getTransItems, searchAdditionalItem } from '../actions';

export default function AddNewItemModal({
    sItemList,
    setItemList,
    setShowAddItemModal,
    selectedSrNo = 0,
    altItem = false,
    setHideItemChecked
}) {

    const rdxTransHead = useSelector((state) => state.PurchaseSlice.transHead);
    const dispatch = useDispatch();
    const [sSearchText, setSearchText] = useState('');
    const [sSearchItemList, setSearchItemList] = useState([]);

    const columns = [
        {
            field: 'ItmCd',
            headerName: 'ItmCd',
            hide: true
        },
        {
            field: 'Name2',
            headerName: 'Items',
            flex: 8,
            // width: 430,
        },
        {
            field: 'BarCode',
            headerName: 'Barcode',
            flex: 1,
            // width: 160
        },
        {
            field: 'Plu',
            headerName: 'PLU',
            flex: 1,
            // width: 80
        },
    ]
    const onChangeSearchText = async (text) => {
        setSearchText(text)
        if (text.length > 2) {
            let obj = {
                orderId: rdxTransHead.OrderId,
                searchText: text,
                ItmCdOld: altItem ? '' : ''
            }
            const result = await searchAdditionalItem(obj);
            if (result.data?.ResultSet) {
                setSearchItemList(result.data.ResultSet);

            }

        } else {
            setSearchItemList([]);
        }
    };

    const onClickItem = async (params) => {
        let itmCd = params.row.ItmCd;
        console.log(params);

        let count = sItemList.filter((i) => i.ALTItmCd === itmCd).length;
        if (count > 0) {
            fAlertToast('FAILED', 'Selected item is already existing!');
        }
        else {
            if (altItem) {
                confirmAlert({
                    title: 'Replace item',
                    message: 'Are you sure to replace this item?',
                    buttons: [
                        {
                            className: 'alertBtn',
                            label: 'Yes',
                            onClick: async () => {

                                let row = sItemList.find(i => Number(i.SrNo) === Number(selectedSrNo));
                                console.log('row b4' + JSON.stringify(row));
                                row = {
                                    ...row,
                                    ALTItmCd: itmCd,
                                    ALTItmName: params.row.Name2,
                                    TaxDept: params.row.TaxDept,
                                    TaxPerc: params.row.TaxPerc,
                                    TaxDept2: params.row.TaxDept2,
                                    TaxPerc2: params.row.TaxPerc2,
                                    Ad_PCRate: params.row.PCRate,
                                    Ad_CessAmt: params.row.AddCess,
                                    Unit: params.row.UnitID,
                                    UnitName: params.row.UnitName,
                                }
                                console.log('row after' + JSON.stringify(row));

                                let modifiedList = sItemList.map(i => Number(i.SrNo) === Number(selectedSrNo) ? row : i);
                                setItemList(modifiedList);
                                setHideItemChecked(false);
                                setShowAddItemModal(false);

                            }
                        },
                        {
                            className: 'alertBtn',
                            label: 'No',
                            onClick: () => { }
                        }
                    ]
                });
            }
            else {
                confirmAlert({
                    title: 'Add Item',
                    message: 'Are you sure to add this item?',
                    buttons: [
                        {
                            className: 'alertBtn',
                            label: 'Yes',
                            onClick: async () => {
                                const obj = {
                                    transId: rdxTransHead.TransId,
                                    orderId: rdxTransHead.OrderId,
                                    itmCd: itmCd
                                }
                                const data = await getTransItems(obj);
                                console.log("api resulttttt" + JSON.stringify(data));
                                let maxSrNo = Number(sItemList.reduce((max, item) => Number(max.SrNo) > Number(item.SrNo) ? max : item).SrNo) + 1;
                                setItemList([...sItemList, ...data.ResultSet.map(i => ({ ...i, SrNo: maxSrNo.toString(), id: maxSrNo.toString(), click: '...', }))]);
                                setHideItemChecked(false);
                                setShowAddItemModal(false)
                            }

                        },
                        {
                            className: 'alertBtn',
                            label: 'No',
                            onClick: () => { }
                        }
                    ]
                });
            }
        }

    }
    return (
        <Box sx={{ flexGrow: 1 }} className="mx-2 mt-2">

            <Grid container spacing={0}>
                <Grid item xs={11.5} 
                // className="d-flex p-2 justify-content-between"
                sx={{textAlign:'center'}}>
                    <CmpTypographyField xVariant='h3' xText={altItem ? 'Alternative Item' : 'Add New Item'} />
                </Grid>
                <Grid item xs={0.5}>
                    <Close onClick={() => setShowAddItemModal(false)} />
                </Grid>
            </Grid>
            <Grid container
            //  spacing={2}
              className='mt-3 mx-2'
              >
                <Grid item xs={12} sm={12} md={8}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Search Item'} />
                        </Grid>

                        <Grid item xs={7} sm={7} md={8}>
                            <CmpInputField
                                xTextAlign="left"
                                xValue={sSearchText}
                                xType={'text'}
                                xName={'ConfirmQty'}
                                xReadOnly={false}
                                xOnChange={async (event) => { await onChangeSearchText(event.target.value) }}
                                xError={false}
                                xErrorMessage={''}
                                xOnKeyUp={''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container 
            // spacing={2}
                sx={{ 
                height: {xs:'65vh',sm:'69vh',md:'66vh',lg:'66vh',}, 
                marginTop: '2vh', 
                // padding: '1vh', 
                // alignContent: 'start',
                 }}>
                <DataGrid
                    rows={sSearchItemList}
                    columns={columns}
                    getRowId={(row) => row.ItmCd}
                    rowHeight={50}
                    headerHeight={50}
                    hideFooter={true}
                    sx={{
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cellContent': {
                            textTransform: 'lowercase'
                        },
                    }}
                    componentsProps={{
                        filterPanel: { sx: { maxWidth: "93vw" } }
                      }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                    }

                    onRowClick={(params) => { onClickItem(params) }}
                />

            </Grid>
        </Box >
    )
}
