import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { fAlertToast } from '../../Utility/Utilitys';
import 'react-toastify/dist/ReactToastify.css';
import { fExecuteAPI } from '../../Server/Apis';
import { CmpTextField } from '../../component/ToolBox/ToolBox';
import './login.css';
import {
  DB_DCLoc_Set,
  DB_Loc_Set,
  DB_Logint_Set,
  DB_PickLOC_List_Set,
  DB_ReviewLoc_Set,
  DB_TruckList_Set,
  DB_UserInfo,
  DB_SetReportURL,
  // DB_SetMadinaGrp,
  // DB_SetFpManualStockTrs,
  // DB_SetFpConsPosting,
  // DB_SetDcMargin,
  // DB_SetCPurchase,
  // DB_SetUnLoad,
  // DB_SetDCAutoTSR,
  DB_SetMenus,
  DB_SetReportURLForPurchase,
  DB_SLice_Kern
} from '../../DBSlice/SliceDB';
import {
  getBuyerAccess,
  getFpUserRights,
  getKernValue,
  getMenus,
  getLogin,
  getPickLocation,
  getReturnRequestReviewLocation,
  getTruckLists,
  getMenuList,
  getKern
} from './action';

import { updateFilter } from '../../layout/slice';

const Login = () => {
  const dispatch = useDispatch();
  const [sLoginObj, setLoginObj] = useState({
    userName: '',
    Password: ''
  });

  let vErrorMsg = {
    userName: '',
    Password: ''
  };

  const [sErrorMsg, setErrorMsg] = useState({
    userName: '',
    Password: ''
  });
  const fValidation = () => {
    let iRes = true;
    if (sLoginObj.userName === '') {
      setErrorMsg((obj) => ({ ...obj, userName: 'userName is Empty' }));
      iRes = false;
    }
    if (sLoginObj.Password === '') {
      setErrorMsg((obj) => ({ ...obj, Password: 'Password is Empty' }));
      iRes = false;
    }
    return iRes;
  };

  const fLogin = async (event) => {
    setErrorMsg(vErrorMsg);
    if (fValidation()) {
      let result = await Getlogin();
      // let buyerInfo = await GetBuyerAccess(result.userId);
      let buyerInfo = await FpUserRights(result?.userId);
      let menuList = await getMenus(result?.userGroup);
      let reviewLoc = await fgetReturnRequestReviewLocations();
      let pickLoc = await getPickLocations(result?.userId);
      let Kern = await GetKern();
      const vCheck = pickLoc.ResultSet.map((itm) => {
        if (itm.LocCode === '') {
          return { ...itm, LocCode: 'All' };
        } else {
          return itm;
        }
      });

      if (result.userId) {
        const truckList = await getTruckList();
        dispatch(DB_TruckList_Set(truckList.ResultSet));
      }

      let reportBaseUrl = await getReportBaseUrl();
      let reportBaseUrlForPurchase = await getReportBaseUrlForPurchase();
      // let madinaGroup = await getMadinaGroupFlag();
      // let stockTransferFlg = await getFpManualStockTrsFlg();
      // let fpconsPostingFlg =  await getFpConsPostingFlg();
      // let dcMarginFlg =  await getDcMarginFlg();
      // let cPurchaseFlg =  await getCpurchaseFlg();
      // let unLoadFlg =  await getUnLoadFlg();
      // let dcAutoTsrFlg = await getDCAutoTransfer();
      let config = {};
      for (const item of Kern?.ResultSet) {
        config[item.UId] = {
          Key: item.UId,
          Value: item.Val
        };
      }
      dispatch(DB_Logint_Set(result));
      dispatch(DB_UserInfo(buyerInfo.ResultSet));
      dispatch(DB_Loc_Set(buyerInfo.ResultSet.Locations));
      dispatch(DB_DCLoc_Set(buyerInfo.ResultSet.DCLocations));
      dispatch(DB_ReviewLoc_Set(reviewLoc.ResultSet));
      dispatch(DB_PickLOC_List_Set(vCheck));
      dispatch(DB_SetReportURL(reportBaseUrl));
      dispatch(DB_SetReportURLForPurchase(reportBaseUrlForPurchase));
      // dispatch(DB_SetMadinaGrp(madinaGroup));
      // dispatch(DB_SetFpManualStockTrs(stockTransferFlg));
      // dispatch(DB_SetFpConsPosting(fpconsPostingFlg));
      // dispatch(DB_SetDcMargin(dcMarginFlg));
      // dispatch(DB_SetCPurchase(cPurchaseFlg));
      // dispatch(DB_SetUnLoad(unLoadFlg));
      // dispatch(DB_SetDCAutoTSR(dcAutoTsrFlg));
      dispatch(DB_SLice_Kern(config));
      dispatch(DB_SetMenus(menuList));

      dispatch(
        updateFilter({
          DCList: buyerInfo.ResultSet.DCLocations.map((i) => i.UId),
          dateFrom: new Date(new Date().setDate(new Date().getDate() - 1)),
          // dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay() - 30),
          dateTo: new Date(),
          locList: buyerInfo.ResultSet.Locations.map((i) => i.UId)
        })
      );
    }
  };

  //API GET_API_LOGIN
  const Getlogin = async () => {
    let loginObj = {
      userId: sLoginObj.userName,
      password: sLoginObj.Password,
      fpLogin: true
    };

    const vlogin = await getLogin(loginObj);
    return vlogin.data;

    // const vlogin = await fExecuteAPI('GET_API_LOGIN', loginObj);
    // if (vlogin?.status === 200) {
    //   return vlogin.data;
    // } else if (
    //   vlogin.response.status === 500 ||
    //   vlogin.response.status === 400 ||
    //   vlogin.response.status === 404 ||
    //   vlogin.response.status === 403
    // ) {
    //   fAlertToast('FAILED', vlogin.response.data.message);
    //   return {};
    // } else {
    //   fAlertToast('FAILED', 'Error in loading login');
    //   return {};
    // }
  };
  const GetKern = async () => {
    let obj = {};

    const iResp = await getKern(obj);
    return iResp.data;
  };

  const getTruckList = async () => {
    let truckObj = {};

    const vTruck = await getTruckLists(truckObj);
    return vTruck.data;

    // const vTruck = await fExecuteAPI('GET_TRUCK_LIST', truckObj);
    // if (vTruck?.status === 200) {
    //   return vTruck.data;
    // } else if (vTruck.response.status === 500) {
    //   fAlertToast(
    //     'FAILED',
    //     vTruck.response.data.message ? vTruck.response.message : 'something went wrong'
    //   );
    //   return {};
    // }
  };

  const FpUserRights = async (xId) => {
    let accessObj = {
      userId: xId
    };

    const res = await getFpUserRights(accessObj);
    return res.data;
  };

  const GetBuyerAccess = async (xId) => {
    let accessObj = {
      userId: xId
    };

    const vBuyerAccess = await getBuyerAccess(accessObj);
    return vBuyerAccess.data;

    // const vBuyerAccess = await fExecuteAPI('GET_BUYER_ACCESS', accessObj);
    // if (vBuyerAccess?.status === 200) {
    //   return vBuyerAccess.data;
    // } else if (
    //   vBuyerAccess.response.status === 500 ||
    //   vBuyerAccess.response.status === 400 ||
    //   vBuyerAccess.response.status === 404 ||
    //   vBuyerAccess.response.status === 403
    // ) {
    //   fAlertToast('FAILED', vBuyerAccess.response.data.message);
    //   return {};
    // } else {
    //   fAlertToast('FAILED', 'Error in loading login');
    //   return {};
    // }
  };

  const fgetReturnRequestReviewLocations = async () => {
    let locObj = {};

    const vReviewLoc = await getReturnRequestReviewLocation(locObj);
    return vReviewLoc.data;

    // const vReviewLoc = await fExecuteAPI('GET_RETURN_REQUEST_REVIEW_LOCATIONS', locObj);
    // if (vReviewLoc?.status === 200) {
    //   return vReviewLoc.data;
    // } else if (
    //   vReviewLoc.response.status === 500 ||
    //   vReviewLoc.response.status === 400 ||
    //   vReviewLoc.response.status === 404 ||
    //   vReviewLoc.response.status === 403
    // ) {
    //   fAlertToast('FAILED', vReviewLoc.response.data.message);
    //   return {};
    // } else {
    //   fAlertToast('FAILED', 'Error in loading login');
    //   return {};
    // }
  };

  const getPickLocations = async (xId) => {
    let locObj = {
      v_TruckID: '',
      v_UserCd: xId
    };

    const vPickLoc = await getPickLocation(locObj);
    return vPickLoc.data;
  };

  const getReportBaseUrlForPurchase = async () => {
    const obj = {
      key: 'WEB_REPORT_BASE_URL_OTS'
    };

    const result = await getKernValue(obj);
    let baseUrl = '';
    if (result.ResultSet?.length > 0) {
      baseUrl = result.ResultSet[0].Val;
    }
    console.log('baseUrl' + baseUrl);
    return baseUrl;
  };

  const getReportBaseUrl = async () => {
    const obj = {
      key: 'WEB_REPORT_BASE_URL'
    };

    const result = await getKernValue(obj);
    let baseUrl = '';
    if (result.ResultSet?.length > 0) {
      baseUrl = result.ResultSet[0].Val;
    }
    console.log('baseUrl' + baseUrl);
    return baseUrl;
  };

  // const getMadinaGroupFlag = async () => {

  //   const obj = {
  //     key: 'MADINA_GROUP'
  //   };

  //   const result = await getKernValue(obj);
  //   let madinaGrp = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     madinaGrp = result.ResultSet[0].Val;
  //   }
  //   return madinaGrp === 'Y';
  // };
  // const getFpManualStockTrsFlg = async () => {

  //   const obj = {
  //     key: 'FP_MANUAL_STOCK_TRS'
  //   };

  //   const result = await getKernValue(obj);
  //   let stockTransfer = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     stockTransfer = result.ResultSet[0].Val;
  //   }
  //   return stockTransfer === 'Y';
  // };

  // const getFpConsPostingFlg = async () => {

  //   const obj = {
  //     key: 'FP_CONS_POSTING'
  //   };

  //   const result = await getKernValue(obj);
  //   let consPosting = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     consPosting = result.ResultSet[0].Val;
  //   }
  //   return consPosting === 'Y';
  // };
  // const getDcMarginFlg = async () => {

  //   const obj = {
  //     key: 'FP_DC_MARGIN'
  //   };

  //   const result = await getKernValue(obj);
  //   let dcMargin = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     dcMargin = result.ResultSet[0].Val;
  //   }
  //   return dcMargin === 'Y';
  // };
  // const getCpurchaseFlg = async () => {

  //   const obj = {
  //     key: 'FP_CENTRAL_PURCHASE'
  //   };

  //   const result = await getKernValue(obj);
  //   let cpurchase = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     cpurchase = result.ResultSet[0].Val;
  //   }
  //   return cpurchase === 'Y';
  // };
  // const getUnLoadFlg = async () => {

  //   const obj = {
  //     key: 'FP_UNLOADING_PROCESS'
  //   };

  //   const result = await getKernValue(obj);
  //   let unloadFlg = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     unloadFlg = result.ResultSet[0].Val;
  //   }
  //   return unloadFlg === 'Y';
  // };
  // const getDCAutoTransfer = async () => {

  //   const obj = {
  //     key: 'FP_DC_AUTO_TSR'
  //   };

  //   const result = await getKernValue(obj);
  //   let dcAutoFlg = 'N';
  //   if (result.ResultSet?.length > 0) {
  //     dcAutoFlg = result.ResultSet[0].Val;
  //   }
  //   return dcAutoFlg === 'Y';
  // };
  const getMenus = async (userGroup) => {
    const obj = {
      userGrpCd: userGroup
    };

    const result = await getMenuList(obj);
    if (result.ResultSet?.length > 0) {
      return result.ResultSet;
    }
  };

  const fKeyTabLogin = (event, name) => {
    if (event.key === 'Enter') {
      if (name == 'Password') {
        document.getElementsByName(name)[0].focus();
      } else if (name == 'login') {
        fLogin();
      }
    }
  };
  return (
    <div className="login-form">
      <div>
        <ToastContainer />
      </div>
      <form className="login-form-bg p-5" autocomplete="off">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CmpTextField
                xLabelText={'UserName'}
                xVariant={'outlined'}
                xValue={sLoginObj.userName}
                xOnChange={(event) =>
                  setLoginObj((obj) => ({ ...obj, userName: event.target.value }))
                }
                xOnKeyUp={(e) => {
                  fKeyTabLogin(e, 'Password');
                }}
                xReadOnly={false}
                xType={'text'}
                xName={'UserName'}
                xError={sErrorMsg.userName === '' ? false : true}
                xErrorMessage={sErrorMsg.userName}
              />
            </Grid>
            <Grid item xs={12}>
              <CmpTextField
                xLabelText={'Password'}
                xVariant={'outlined'}
                xValue={sLoginObj.Password}
                xOnChange={(event) =>
                  setLoginObj((obj) => ({ ...obj, Password: event.target.value }))
                }
                xReadOnly={false}
                xOnKeyUp={(e) => {
                  fKeyTabLogin(e, 'login');
                }}
                xType={'password'}
                xName={'Password'}
                xError={sErrorMsg.Password === '' ? false : true}
                xErrorMessage={sErrorMsg.Password}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12} className="text-center">
              <Button
                onClick={(e) => fLogin(e)}
                variant="contained"
                className="btn-text-transform-none">
                Login
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

export default Login;
