import { Delete, DeleteOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { deleteVanReceivedItems } from '../actions';

export default function ReceivingItemGrid({
    rdxTransHead,
    sHeadObj,
    setHeadObj,
    sReceivingList,
    onDeleteClick
}) {
    const onDeleteCellClick = async (params) => {
        console.log(params);
        console.log("on delete Click called");
        onDeleteClick(params.row.SrNo);
    }

    const fOnCellClick = (params) => {
        if (params.field !== 'delete') {
            console.log(params);

            setHeadObj({
                srNo: Number(params.row.SrNo),
                qty: Number(params.row.Qty),
            })
        }
    }
    const columns = [
        {
            field: 'SrNo',
            headerName: 'Sr No.',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'Qty',
            headerName: 'Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'UnitID',
            headerName: 'Unit',
            flex: 2,
            align: 'center',
            headerAlign: 'center',

        },
        {
            field: 'delete',
            headerName: '',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {

                return (
                    <IconButton size='small' color='primary' onClick={() => { onDeleteCellClick(params); }} disabled={rdxTransHead.status.toString().toUpperCase() !== 'ENTER'}>
                        <Delete />
                    </IconButton>
                );
            }
        },

    ];

    return (
        <Box
            sx={{
                height: '40vh',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary'
                },
                '& .textPrimary': {
                    color: 'text.primary'
                },
                '& .Mui-error': {
                    backgroundColor: `rgb(126,10,15, 0.1})`,
                    color: '#ff4343',
                },
            }}>
            <DataGrid
                rows={sReceivingList}
                getRowId={(row) => row.SrNo}
                columns={columns}
                rowHeight={45}
                headerHeight={45}
                sx={{
                    border: 2,
                    borderColor: 'primary.light',
                }}
                componentsProps={{
                    filterPanel: { sx: { maxWidth: "93vw" } }
                  }}
                onCellClick={(params) => { fOnCellClick(params); }}
                hideFooter={true}
            />
        </Box>

    );
}
