import React,{useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../../component/ReuseableComponent/ReuseableComponent.css';
import Modal from '@mui/material/Modal';
import '../../../component/ToolBox/ToolBox.css';
import {
  CmpTypographyField,
  CmpInputField,
  CmpButtonWithIcon
} from '../../../component/ToolBox/ToolBox';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

const fDummyFunction = () => {};
const gridBox = {
  border: '1px solid #DADBD6',
  padding: 0.5
};
const borderBox = {
  height: '60vh',
  padding: 0.5
};
const gridItemSpacing = {
  display: 'flex',
  alignItems: 'center',
  padding: 0.2,
  justifyContent: 'flex-start'
};

const DCMarginPosting = ({
  xOpen = 'false',
  xTitle = '',
  fCloseModal,
  xPostedRowInfo = [],
  xLoadDetailRow = [],
  xLoadDetailCol = [],
  xfetchPost = () => {},
  xRowHeight = 30,
  xHeaderHeight = 40,
  borderColorChange = false,
  setLoadDetailRow
}) => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const addMargin = (params) => {

    let itemList = xLoadDetailRow.map((i) => {
      return i.id === params.row.id
        ? { ...i, TRNS_Add_Margin_CTN: Number(params.value), TRNS_Add_Margin_Net: params?.row?.TRNS_QTY * Number(params.value) , TRNS_TSR_RSP: params?.row?.TRNS_UnitRate + Number(params.value) }
        : i;
    });
    setLoadDetailRow(itemList);
    return { ...params.row, TRNS_Add_Margin_CTN: Number(params.value), TRNS_Add_Margin_Net: params?.row?.TRNS_QTY * Number(params.value), TRNS_TSR_RSP: params?.row?.TRNS_UnitRate + Number(params.value) };
  };

 const columns =  xLoadDetailCol.map((x)=>{
    if (x.field === 'TRNS_Add_Margin_CTN') {
      return {
        ...x,
        valueSetter: addMargin,
        editable: xPostedRowInfo.docStatus == 'POST' ? false : true,
        cellClassName: xPostedRowInfo.docStatus == 'POST' ? '' : 'mui-request-grid-final-qty'
      };
    } else {
      return x;
    }
  });

  return (
    <Modal
      open={xOpen}
      onClose={fCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ flexGrow: 1, overflow: 'auto' }} className="gnr-item-search model-gc-search">
        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="GCSearchBtn-Tittle"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={11} sm={11} md={11} className="d-flex justify-content-center  ">
            <div>{xTitle}</div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} className="d-flex justify-content-end">
            <CloseIcon
              className="grnt-item-search-close-icon"
              onClick={!!fCloseModal ? fCloseModal : fDummyFunction}
              style={{ zIndex: 999 }}
            />
          </Grid>
        </Grid>
        <Box sx={gridBox}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: '5px' }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpTypographyField
                        xcomponent={'p'}
                        xText={'Voucher Type'}
                        xVariant={'subtitle'}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <Grid
                        container
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                          <CmpInputField
                            xValue={xPostedRowInfo.vocTyp}
                            xType={'text'} // text/number/password
                            xName={'docNo'}
                            xReadOnly={true}
                            xOnChange={''}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                          />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                          <CmpInputField
                            xValue={xPostedRowInfo.vocName}
                            xType={'text'} // text/number/password
                            xName={'docName'}
                            xReadOnly={true}
                            xOnChange={''}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={'Vouchar No'}
                        xVariant={'subtitle'}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.vocNo}
                        xType={'text'} // text/number/password
                        xName={'vocNo'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={'Date'}
                        xVariant={'subtitle'}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={moment(xPostedRowInfo.vocDt).format('DD/MM/YYYY')}
                        xType={'text'} // text/number/password
                        xName={'date'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: '5px' }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpTypographyField
                        xcomponent={'p'}
                        xText={'Location'}
                        xVariant={'subtitle'}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <Grid
                        container
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                          <CmpInputField
                            xValue={xPostedRowInfo.locCode}
                            xType={'text'} // text/number/password
                            xName={'locCode'}
                            xReadOnly={true}
                            xOnChange={''}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                          />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                          <CmpInputField
                            xValue={xPostedRowInfo.locName}
                            xType={'text'} // text/number/password
                            xName={'locName'}
                            xReadOnly={true}
                            xOnChange={''}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={'Status'}
                        xVariant={'subtitle'}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.docStatus}
                        xType={'text'} // text/number/password
                        xName={'docStatus'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} sx={gridItemSpacing}>
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={'Type'}
                        xVariant={'subtitle'}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} sx={gridItemSpacing}>
                      <CmpInputField
                        xValue={xPostedRowInfo.type}
                        xType={'text'} // text/number/password
                        xName={'type'}
                        xReadOnly={true}
                        xOnChange={''}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container sx={{ height: vMediaQuery ? '100%' : '' }}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Box sx={borderBox}>
              <DataGrid
                rows={xLoadDetailRow}
                columns={columns}
                rowHeight={xRowHeight}
                headerHeight={xHeaderHeight}
                sx={{
                  border: 2,
                  borderColor: borderColorChange ? '#DADBD6' : 'primary.light'
                }}
                componentsProps={{
                  filterPanel: { sx: { maxWidth: "93vw" } }
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                }
                hideFooter
                checkboxSelection={false}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: '10px' }}>
            <div style={{ width: '20%', margin: '0 auto' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={() => {xfetchPost(xPostedRowInfo)}}
                xLabel={'Post'}
                xIcon={''}
                xDisable={false}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DCMarginPosting;
