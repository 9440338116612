import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import TransCard from './ProcessComponents/TransCard';
import { CmpButton, CmpSelectArrField, CmpTypographyField } from '../../component/ToolBox/ToolBox';
import { getVanTransSummary } from './actions';
import { updateFilterStatus, updateTransHead } from './VanReceivingSlice';
import { useNavigate } from 'react-router-dom';
import VanBreadcrumbs from './ProcessComponents/VanBreadcrumbs';

const statusData = ['ALL', 'ENTER', 'POST'];

export default function VanTransSummary() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isFishPurchaseFlg = params.get('isFishPurchase');

    const [sStatus, setStatus] = useState('ENTER');
    const [sTransList, setTransList] = useState([]);
    const rdxFilterStatus = useSelector((state) => state.VanReceivingSlice.filterStatus);
    const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
    const rdxUser = useSelector((state) => state.SliceDB.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sshowLoading, setshowLoading] = useState(false);

    const getReceivingList = useCallback(async (xStatus) => {
        setshowLoading(true);
        const obj = {
            vanId: rdxUser.userInfo.TruckID,
            status: xStatus,
            fromDate: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
            toDate: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
            isFishPurchase:isFishPurchaseFlg,
            locCsv:rdxFilterObj.locList.toString()
            //isFishPurchaseFlg newly added to differentiate vanReceiving from fish pruchase or veggies
        };
        const result = await getVanTransSummary(obj);

        if (result.ResultSet.length > 0) {
            setTransList(result.ResultSet);
        } else {
            setTransList([]);
        }
        setshowLoading(false);
        return result.ResultSet;
    }, [rdxFilterObj, rdxUser, setshowLoading]);



    const fOnPageLoad = useCallback(async () => {
        let status = rdxFilterStatus;
        setStatus(status);
        getReceivingList(status);
    }, [rdxFilterStatus, getReceivingList]);

    useEffect(() => {
        fOnPageLoad();
    }, [fOnPageLoad]);

    const fOnpressLoad = async () => {
        dispatch(updateFilterStatus(sStatus));
    }

    const fOnpressTransCard = (itm) => {
        dispatch(updateTransHead({
            transId: itm.Van_TransId,
            status: itm.Status
        }));
        navigate('/VanTransDetail');
    }

    return (
        <div className="container openPurchase">
            <div 
            // className="my-2"
            >
                <VanBreadcrumbs />
            </div>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    spacing={1}
                    rowSpacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}
                    className="mb-2">

                    <Grid item xs={8} sm={6} md={4}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={5} sm={5} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={8}>
                                <CmpSelectArrField
                                    xLabelText="Status"
                                    xValue={sStatus}
                                    xOnChange={(event) => setStatus(event.target.value)}
                                    xName={'Status'}
                                    xData={statusData}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                    xTabIndex={'2'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4} sm={6} md={1}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ justifyContent: 'start' }}
                        >
                            <Grid item xs={12} sm={12} md={11} >
                                <CmpButton
                                    xvariant={'contained'}
                                    xsize={'small'}
                                    xonClick={() => fOnpressLoad()}
                                    xLabel={'Load'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={11} sm={11} md={6} className='text-end'>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <CmpTypographyField xcomponent={'span'} xText={'Records From ' + moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY') + ' - ' + moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')} />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{
                        height: '73vh',
                        overflowY: 'auto',
                        marginTop: '1vh',
                        paddingRight: '16px',
                        alignContent: sTransList.length === 0 ? 'center' : 'start',
                        visibility: sshowLoading ? 'hidden' : 'show'
                    }}>

                    {sTransList.length === 0 ? (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // height: '80vh'
                            }}>
                            <p style={{ fontSize: '2rem' }}> List is Empty </p>
                        </div>
                    ) :
                        (
                            sTransList.map((itm) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        key={itm.Van_TransId}
                                        onClick={() => fOnpressTransCard(itm)}>
                                        <TransCard
                                            xVanTransId={itm.Van_TransId}
                                            xVendor={itm.TRNS_PARTY_NAME.toLowerCase()}
                                            xBuyer={itm.BuyerName}
                                            xTruck={itm.TruckName}
                                            xDriver={itm.DriverName}
                                            xPhone={itm.PhoneNo}
                                            xDate={moment(itm.PurchaseTransDate).format('DD/MM/YYYY')}
                                            xLocation={itm.VAN_LOC_NAME.toLowerCase()}
                                            xStatus={itm.Status}
                                        />
                                    </Grid>
                                );
                            })
                        )}
                </Grid>
            </Box>

            <Box className="GCSpinner" visibility={sshowLoading ? 'show' : 'hidden'}>
                <CircularProgress />
            </Box>
        </div>
    )
}
