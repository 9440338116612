import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton
} from '../../../component/ToolBox/ToolBox';
import { Checkbox } from '@mui/material';

function BatchCreationDetailCard({ xDisptachNo, xLoc, xPostDt, xSelected, xOnChangeSelect, item }) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid
        container
        spacing={0.1}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={12} md={12} sx={{ alignItems: 'right' }}>
          <Checkbox checked={xSelected} onChange={xOnChangeSelect} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'left' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Dispatch No'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xDisptachNo} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Branch'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xLoc} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Date'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xPostDt} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'ReqTransId'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={item.A_ReqTransId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Department'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={item.Department} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default BatchCreationDetailCard;
