import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  head: {
    DClocation: '',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(),
    LocList: [],
    DCStatus: 'N',
    Dept: ''
  }
};

const ReviewBatchCreationSlice = createSlice({
  name: 'ReviewBatchCreationSlice',
  initialState: { value: initialState },

  reducers: {
    ReviewBatchCreationHead: (state, action) => {
      state.value.head = action.payload;
    }
  }
});

export const { ReviewBatchCreationHead } = ReviewBatchCreationSlice.actions;
export default ReviewBatchCreationSlice.reducer;
