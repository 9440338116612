import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton,
  StatusIcon
} from '../../../component/ToolBox/ToolBox';
function PurchaseReturnSumarryCard({
  xTransId,
  xTruck,
  xLocation,
  xDispatched_Date,
  xStatus,
  xNoItems,
  xTransDate
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={3.5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Trans Id'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={7} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={xTransId} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Dispatch Date'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <CmpTypographyField xcomponent={'span'} xText={xDispatched_Date} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={3.5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xLocation} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Trans. Date'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <CmpTypographyField xcomponent={'span'} xText={xTransDate} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={3.5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xTruck} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={3.5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Status'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6} sm={6} md={7}>
                  <CmpTypographyField xcomponent={'span'} xText={xStatus} />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={6} sm={6} md={6}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={5} sm={5} md={5}>
                  <CmpTypographyField xcomponent={'span'} xText={'No of Items'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <CmpTypographyField xcomponent={'span'} xText={xNoItems} />
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'end' }}>
                  <StatusIcon xStatus={xStatus} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default PurchaseReturnSumarryCard;
