import React from 'react'
import PurchaseSummary from './PurchaseSummary'

export default function PostedPurchaseSummary() {
    return (
        <div>
            <PurchaseSummary xState={'POST'} />
        </div>
    )
}
