import React from 'react'
import Box from "@mui/material/Box";
import { GridPagination } from '@mui/x-data-grid';
import { Grid } from '@mui/material';

export default function CustomFooterTotal(props) {

  const totalQty = props.rows.reduce((prevValue, i) => {
    return prevValue + Number(i.Qty);
  }, 0);

  const totalSales = props.rows.reduce((prevValue, i) => {
    return prevValue + Number(i.Sales);
  }, 0);

  const totalTax = props.rows.reduce((prevValue, i) => {
    return prevValue + Number(i.B_TaxAmt);
  }, 0);

  const totalCOGS = props.rows.reduce((prevValue, i) => {
    return prevValue + Number(i.COGS);
  }, 0);

  const totalGP = props.rows.reduce((prevValue, i) => {
    return prevValue + Number(i.GP);
  }, 0);

  const gpPerc = (totalGP / totalSales) * 100;

  return (
    <>
      <Grid
        container
        columns={{ xs: 9 }}
        spacing={1}
        sx={{ alignItems: 'center', width: '100%', borderTop: '1px lightgrey solid' }}
      >
        <Grid item xs={3} className='d-flex justify-content-start'>
          <GridPagination sx={{ dispaly: 'flex', alignItems: 'center' }} />
        </Grid>
        <Grid item xs={1} className='text-end'>
          {Number(totalQty).toLocaleString('en')}
        </Grid>
        <Grid item xs={1} className='text-end'>
          {Number(totalSales).toLocaleString('en')}
        </Grid>
        <Grid item xs={1} className='text-end'>
          {Number(totalTax).toLocaleString('en')}
        </Grid>
        <Grid item xs={1} className='text-end'>
          {Number(totalCOGS).toLocaleString('en')}
        </Grid>
        <Grid item xs={1} className='text-end'>
          {Number(totalGP).toLocaleString('en')}
        </Grid>
        <Grid item xs={1} className='text-end'>
          {Number(gpPerc).toLocaleString('en')}

        </Grid>
      </Grid>
      {/* <div className='d-flex justify-content-between align-items-center' >
        <Box sx={{ display: "flex", flex: 4 }}><GridPagination sx={{ alignItems: 'center' }} /></Box>
        <Box sx={{ flex: 1 }}>{Number(totalQty).toFixed(2)}</Box>
        <Box sx={{ flex: 1 }}>{Number(totalSales).toFixed(2)}</Box>
        <Box sx={{ flex: 1 }}>{Number(totalTax).toFixed(2)}</Box>
        <Box sx={{ flex: 1 }}>{Number(totalCOGS).toFixed(2)}</Box>
        <Box sx={{ flex: 1 }}>{Number(totalGP).toFixed(2)}</Box>
      </div> */}
    </>
  )
}
