import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton,
  StatusIcon
} from '../../../component/ToolBox/ToolBox';
function PurchaseReturnSumarryCard({
  xBatchId,
  xBatchDt,
  xVendor,
  xStatus,
  xNo_Dispatch,
  xValue_Dispatched,
  xPRDocNo,
  xPrPostDt,
  xEnDt,
  item
}) {
  return (
    // <Box sx={{ flexGrow: 1 }} className={xStatus === 'Enter' ? "border-card-yellow" : (xStatus === 'Post' ? "border-card-green" : "border-card")}>
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={11} sm={11} md={11}>
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={'Batch ID & Time'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={xBatchId + '- ' + xEnDt} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={5} lg={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={5}>
              <CmpTypographyField xcomponent={'span'} xText={'Batch Date'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} className="txt-overflow-ellipse">
              <CmpTypographyField xcomponent={'span'} xText={xBatchDt} />
            </Grid>
          </Grid>
        </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={xVendor} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={5}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Status'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7} className="txt-overflow-ellipse">
              <CmpTypographyField xcomponent={'span'} xText={xStatus} />
            </Grid>
          </Grid>
        </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} lg={7}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Dispatch Count'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xNo_Dispatch} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Dispatch Value'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xValue_Dispatched} />
            </Grid>
          </Grid> 
      </Grid>*/}
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'PR No.& Date'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8}>
                  <CmpTypographyField
                    xcomponent={'span'}
                    xText={xPRDocNo === '' ? '' : xPRDocNo + ' - ' + xPrPostDt}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'VANTransID'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={item?.VANTransIDs} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'ReqTransID'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={item?.REQTransIDs} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={5}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'PR Date'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xPrPostDt} />
            </Grid>
          </Grid>
        </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'end' }}>
          <StatusIcon xStatus={xStatus} />
        </Grid>
      </Grid>
    </Box>
  );
}
export default PurchaseReturnSumarryCard;
