import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { styled as styleMui } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import grapes from '../../../Images/grapes.jpg';
import LocationTable from './LocationTable';
import {
    CmpButton, CmpTypographyField
} from '../../../component/ToolBox/ToolBox';
import styled from "styled-components";
import { getOrderItemsReqLocs } from '../action';
import { ContactSupportOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
// import grapes from '../../../Images/grapes.jpg';
// import { CmpButton, CmpTypographyField } from '../../../component/ToolBox/ToolBox';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '1px solid #0d73bb',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    // alignItems: "center",
    height: 'fit-content',
    margin: '0 auto',
    color: '#0d73bb'
    // fontSize: '12px'
};
const StyledParagraph = styled.p`
        font-size:13px;
        padding:2px;
        margin:0 auto;
        color:#0d73bb;
`;

const rows = [
    {
        locationName: 'ithra-dubai',
        Quantity: 10,
    },
    {
        locationName: 'mango-mkt-3-impz',
        Quantity: 20,
    },
    {
        locationName: 'hyper-05',
        Quantity: 30,
    }
];


export default function Location(props) {
    const [open, setOpen] = React.useState(false);
    const [sItemList, setItemList] = useState([]);
    const rdxOrderHead = useSelector((state) => state.OrderSlice.orderHead);

    const handleOpen = async (itemCd) => {

        console.log(itemCd);
        const reqObj = {
            orderId: rdxOrderHead.orderId,
            itmCd: itemCd
        }

        console.log("reqObj" + JSON.stringify(reqObj));
        const res = await getOrderItemsReqLocs(reqObj);

        setItemList(res.ResultSet);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    return (
        <div>
            <>

                <Button
                    onClick={() => handleOpen(props.data.ItmCd)}
                    sx={{
                        width: '5rem',
                        fontSize: '12px',
                        textTransform: 'Capitalize',
                        margin: '1rem'
                    }}
                    variant="contained"
                    size="small"
                >Location</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div>
                            <b>{props.data.ItmName.toUpperCase()}</b>
                            <CloseIcon sx={{
                                float: 'right',
                                fontSize: 20
                            }} onClick={() => setOpen(false)} />
                        </div>
                        <br />
                        <Grid container spacing={1} align='left' fontSize={2} >
                            <Grid item xs={4}>
                                <Avatar sx={{
                                    width: 80, height: 80,
                                    border: '3px solid #3498db',
                                    backgroundColor: 'white'

                                }}
                                    alt=" "
                                    src={`${props.data.IMAGE_BASE_URL}product/${props.data.Barcode}.jpg?imgVer=${props.data.Version}`}
                                >
                                </Avatar>
                            </Grid>
                            <Grid item xs={8} >
                                <Grid container spacing={1} justifyContent='left'>
                                    <Grid item xs={6}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Consolidation'} />
                                        {/* <StyledParagraph>Consolidation</StyledParagraph> */}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Qty'} />

                                        {/* <StyledParagraph>Qty</StyledParagraph> */}
                                    </Grid>
                                    <Grid item xs={2} className='text-end'>
                                        <CmpTypographyField xcomponent={'span'} xText={Number(props.data.BasseQty).toFixed(2)} />
                                        {/* <StyledParagraph>{Number(props.data.BasseQty).toFixed(2)}</StyledParagraph> */}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CmpTypographyField xcomponent={'span'} xText={props.data.UnitName} />
                                        {/* <StyledParagraph>{props.data.UnitName}</StyledParagraph> */}
                                    </Grid>
                                </Grid><br />
                                <Grid item xs={12}>
                                    <Grid container spacing={1} justifyContent='center'>
                                        <Grid item xs={6}>
                                            <CmpTypographyField xcomponent={'span'} xText={'Purchased'} />
                                            {/* <StyledParagraph>Purchased</StyledParagraph> */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                                            {/* <StyledParagraph>Qty</StyledParagraph> */}
                                        </Grid>
                                        <Grid item xs={2} className='text-end'>
                                            <CmpTypographyField xcomponent={'span'} xText={Number(props.data.PurchasedQty).toFixed(2)} />
                                            {/* <StyledParagraph>{Number(props.data.PurchasedQty).toFixed(2)}</StyledParagraph> */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {/* <StyledParagraph>{props.data.UnitName}</StyledParagraph> */}
                                            <CmpTypographyField xcomponent={'span'} xText={props.data.UnitName} />
                                        </Grid>
                                    </Grid>
                                </Grid><br />
                                <Grid item xs={12}>
                                    <Grid container spacing={1} justifyContent='center'>
                                        <Grid item xs={6}>
                                            <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                                            {/* <StyledParagraph>Balance</StyledParagraph> */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                                            {/* <StyledParagraph>Qty</StyledParagraph> */}
                                        </Grid>
                                        <Grid item xs={2} className='text-end'>
                                            <CmpTypographyField xcomponent={'span'} xText={Number(props.data.BasseQty - props.data.PurchasedQty).toFixed(2)} />
                                            {/* <StyledParagraph>{Number(props.data.BasseQty - props.data.PurchasedQty).toFixed(2)}</StyledParagraph> */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CmpTypographyField xcomponent={'span'} xText={props.data.UnitName} />
                                            {/* <StyledParagraph>{props.data.UnitName}</StyledParagraph> */}
                                        </Grid>
                                    </Grid>
                                </Grid><br />

                            </Grid>
                        </Grid>
                        <br />
                        <LocationTable row={sItemList} />

                    </Box>
                </Modal>
            </>

        </div >
    );
}
