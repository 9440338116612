import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterStatus: 'ENTER',
  transHead: {
    transId: '',
    status: 'ENTER'
  },
  transItem: {
    srNo: 0,
    itmCd: '',
    itemName: '',
    totalQty: 0,
    unit: '',
    unitName: '',
    receivedQty: 0,
    balQty: 0,
    barcode: ''
  }
};
const VanReceivingSlice = createSlice({
  name: 'ReceivingSlice',
  initialState: initialState,
  reducers: {
    updateFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    updateTransHead: (state, action) => {
      state.transHead = action.payload;
    },
    updateTransItem: (state, action) => {
      state.transItem = action.payload;
    }
  }
});

export const { updateFilterStatus, updateTransHead, updateTransItem } = VanReceivingSlice.actions;
export default VanReceivingSlice.reducer;
