import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BatchDetailGrid({ rowsData, setUpdateRows, editable, setGridEditing }) {
    const vMediaQuery = useMediaQuery('(max-width:800px)');

    const setConfQty = (params) => {

        const newRow = { ...params.row, B_Conf_Qty: isNaN(params.value) ? 0 : params.value };
        let row = updateValues(newRow);

        const updatedRows = rowsData.map((itm) => (itm.A_TransId === row.A_TransId && itm.B_SrNo === row.B_SrNo ? row : itm));
        setUpdateRows(updatedRows);

        return newRow;
    };

    const setUnitRate = (params) => {

        console.log("set unitrateeeee" + params.value);
        const newRow = { ...params.row, B_Conf_UnitRate: isNaN(params.value) ? 0 : params.value };
        let row = updateValues(newRow);

        const updatedRows = rowsData.map((itm) => (itm.A_TransId === row.A_TransId && itm.B_SrNo === row.B_SrNo ? row : itm));

        console.log("updaterowssss" + JSON.stringify(updatedRows));
        setUpdateRows(updatedRows);

        return newRow;
    };


    const updateValues = (obj) => {
        const calculatedObj = calculate(
            obj.B_Disp_Qty,
            obj.B_Conf_Qty,
            obj.B_Conf_UnitRate,
        );
        return { ...obj, ...calculatedObj };
    };

    const calculate = (
        dispQty = 0,
        ConfQty = 0,
        ConfUnitRate = 0,
    ) => {
        const confNetAmt = ConfQty * ConfUnitRate;
        const adjQty = dispQty - ConfQty;
        const adjAmt = adjQty * ConfUnitRate;

        return { B_Conf_NetAmt: confNetAmt, B_Adj_Qty: adjQty, B_Adj_Amt: adjAmt };
    };


    const columns = [
        {
            field: 'A_TransId',
            headerName: 'Dispatch Id',
            //  width: 60,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            hide: true,
        },
        {
            field: 'LocName',
            headerName: 'Location',
            // width: 100,
            flex: 2,
        },

        {
            field: 'DriverName',
            headerName: 'Driver',
            //width: 100,
            flex: 2,
            hide: vMediaQuery
        },
        {
            field: 'B_SrNo',
            headerName: 'Sr.No',
            //width: 50,
            align: 'center',
            headerAlign: 'center',
            hide: true,
            flex: 1,
        },
        {
            field: 'B_ItmCd',
            headerName: 'Item code',
            // width: 80,
            flex: 2,
            hide: true
        },
        {
            field: 'ItemName',
            headerName: 'Item Name',
            // width: 130
            flex: 2,
        },

        {
            field: 'B_Disp_Qty',
            headerName: 'Qty.',
            //width: 70,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)

        },
        {
            field: 'B_Unit',
            headerName: 'Unit',
            //width: 50,
            flex: 1,
            hide: true
        },
        {
            field: 'UnitName',
            headerName: 'Unit',
            //width: 80,
            flex: 1,
        },
        {
            field: 'B_Conf_Qty',
            headerName: 'Conf. Qty.',
            //width: 70,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            valueSetter: setConfQty,
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            field: 'B_Conf_UnitRate',
            headerName: 'Unit Rate',
            //width: 70,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            editable: editable,
            valueSetter: setUnitRate,
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            field: 'B_Conf_NetAmt',
            headerName: 'Net Amount',
            align: 'right',
            headerAlign: 'right',
            //width: 70,
            flex: 1,
            valueFormatter: ({ value }) => Number(value).toFixed(2),
            hide: vMediaQuery

        },

        {
            field: 'B_Adj_Qty',
            HeaderName: 'Adjust. Qty',
            //width: 80,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            //type: 'number',
            valueFormatter: ({ value }) => Number(value).toFixed(2),
            hide: true
        },
        {
            field: 'B_Adj_Amt',
            HeaderName: 'Adjust. Amt',
            //width: 80,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            //type: 'number',
            valueFormatter: ({ value }) => Number(value).toFixed(2),
            hide: true
        },
    ];

    return (
        <Box
            sx={{
                height: vMediaQuery ? '50vh' : '50vh',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary'
                },
                '& .textPrimary': {
                    color: 'text.primary'
                }
            }}>
            <DataGrid
                rows={rowsData}
                columns={columns}
                rowHeight={45}
                headerHeight={50}
                sx={{
                    border: 2,
                    borderColor: 'primary.light',
                }}
                componentsProps={{
                    filterPanel: { sx: { maxWidth: "93vw" } }
                  }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                }
                onCellEditStart={() => setGridEditing(true)}
                onCellEditStop={() => setGridEditing(false)}
            />
        </Box>
    );
}



