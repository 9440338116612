import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import grapes from '../../../Images/grapes.jpg';
import InquiryTable from '../../Order/ProcessComponents/InquiryTable';
import { CmpTypographyField } from '../../../component/ToolBox/ToolBox';
import { getVendorPriceEnquiry } from '../actions';
import { DataGrid } from '@mui/x-data-grid';

export default function PriceEnquiry({
    xItmCd,
    xItmName,
    setShowInquiryModal
}) {
    const [sItemList, setItemList] = useState([]);

    //const currentDate = new Date();

    const fLoadGrid = useCallback(async () => {
        const reqObj = {
            ItmCd: xItmCd,
            Date: moment(new Date()).format("MM/DD/YYYY"),
            PackQty: 1
        }

        const res = await getVendorPriceEnquiry(reqObj);
        setItemList(res.ResultSet);
    }, []);
    useEffect(() => {
        fLoadGrid()
    }, [fLoadGrid])

    const columns = [
        {
            field: 'VendorCode',
            headerName: 'VendorCode',
            hide: true
        },
        {
            field: 'VendorName',
            headerName: 'Vendor',
            flex: 3
        },
        {
            field: 'CostPr',
            headerName: 'Rates',
            flex: 1
        },

    ]
    return (
        <Box sx={{ flexGrow: 1 }} className="m-4">
            <b>{xItmName.toUpperCase()}</b>
            <CloseIcon sx={{
                float: 'right'
            }} onClick={() => setShowInquiryModal(false)} />
            <Grid container spacing={2} >
                {/* <Grid item xs={3}>
                    <Avatar sx={{
                        width: 80, height: 80,
                        border: '3px solid #3498db'
                    }}
                        alt="Remy Sharp"
                        src={grapes}
                    >

                    </Avatar>
                </Grid> */}
                <Grid item xs={8} align="left">
                    <Grid container spacing={2} >
                        <Grid item xs={6} >
                            <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
                        </Grid>
                        <Grid item xs={6} >
                            <CmpTypographyField xcomponent={'span'} xText={xItmCd} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                        </Grid>
                        <Grid item xs={6} >
                            <CmpTypographyField xcomponent={'span'} xText={moment(new Date()).format("DD/MM/YYYY")} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <CmpTypographyField xcomponent={'span'} xText={'Pack Qty'} />
                        </Grid>
                        <Grid item xs={6} >
                            <CmpTypographyField xcomponent={'span'} xText={'1'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            {/* <InquiryTable row={sItemList} /> */}
            <Grid container spacing={2}
                sx={{ height: '58vh', marginTop: '2vh', padding: '1vh', alignContent: 'start' }}>
                <DataGrid
                    rows={sItemList}
                    columns={columns}
                    getRowId={(row) => row.VendorCode}
                    rowHeight={40}
                    headerHeight={50}
                    hideFooter={true}
                    sx={{
                        border: 2,
                        borderColor: 'primary.light',
                    }}
                    componentsProps={{
                        filterPanel: { sx: { maxWidth: "93vw" } }
                      }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                    }
                />
            </Grid>

        </Box>
    );
}
