import { datePickerToolbarClasses } from '@mui/x-date-pickers';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transHead: {
    TransId: '',
    OrderId: '',
    Location: '',
    LocationName: '',
    Date: new Date(),
    Vendor: '',
    VendorName: '',
    ReferenceNo: '',
    ReferenceDate: new Date(),
    Status: 'ENTER',
    NetAmt: 0,
    DriverName: '',
    Parking: '',
    invoiceAmt: 0,
    Paymode: 'CREDIT',
    State: 'OPEN',
    Rounding: 0,
    MarketPurchase: 'Y',
    TRNS_Vendor_Os: 0,
    TRNS_Vendor_Os_Adj: 'N',
    TRNS_Vendor_Reduction: 0,
    TRNS_Confirm_Inv: 'N',
    TRNS_Direct_Deli: 'N',
    TaxPurchase: 'N',
    TRNS_PARTY_MOBILE: '',
    UpdateFlag: 0,
    DCPurchase: 'N'
  }
};
const PurchaseSlice = createSlice({
  name: 'PurchaseSlice',
  initialState: initialState,
  reducers: {
    updateTransHead: (state, action) => {
      state.transHead = action.payload;
    }
  }
});

export const { updateTransHead } = PurchaseSlice.actions;
export default PurchaseSlice.reducer;
