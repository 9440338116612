import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpButton,
  CmpSelectArrField,
  CmpTypographyFieldColor,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import { fAlertToast } from '../../Utility/Utilitys';

import { UpdateRetunReviewBatchDetail, RetunReviewBatchHead } from './ReturnReviewBatchSlice';
import { getDepartmentList, getReturnReviewBatchSummary } from './actions';
import './ReturnReviewBatch.css';
import BatchSummaryCard from './ProcessComponent/BatchSummaryCard';
import ReviewBatchBreadCrumbs from './ReviewBatchBreadCrumbs';
import { Refresh } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

export default function ReturnReviewBatchSummary() {
  const vMediaQuery = useMediaQuery('(max-width:760px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rdxHeadobj = useSelector((state) => state.ReturnReviewBatchSlice.value.head);

  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const [sFilteredDCNames, setFilteredDCNames] = useState([]);
  const [sFilteredLocNames, setFilteredLocNames] = useState([]);

  const [sDeptList, setDeptList] = useState([]);

  const statusList = ['All', 'Enter', 'Post'];
  const scanFocusOnLoad = useRef(null);

  const [sDetailObj, setDetailObj] = useState([]);

  const fGetDetails = async (Dept = rdxHeadobj.Dept) => {
    const reqObj = {
      v_Date1: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      v_Status: rdxHeadobj.status,
      v_A_Loc: rdxFilterObj.DCList.toString()
    };

    const result = await getReturnReviewBatchSummary(reqObj);
    if (result.ResultSet.length > 0) {
      setDetailObj(result.ResultSet);
    } else {
      setDetailObj([]);
    }
  };

  const fOnPageLoad = useCallback(async () => {
    let filteredDC = dcLoclist.filter((i) => rdxFilterObj.DCList.includes(i.UId));
    setFilteredDCNames(filteredDC.map((i) => i.Name1));

    // let filteredLoc = locList.filter((i) => rdxFilterObj.locList.includes(i.UId));
    // setFilteredLocNames(filteredLoc.map((i) => i.Name1));

    // const result = await getDepartmentList({});
    // setDeptList(result?.ResultSet);
    // if (rdxHeadobj.Dept == '') {
    //   dispatch(
    //     RetunReviewBatchHead({
    //       ...rdxHeadobj,
    //       Dept: result?.ResultSet[0].Cd
    //     })
    //   );
    //   fGetDetails(result?.ResultSet[0].Cd);
    // } else {

    // }
    fGetDetails();
  }, [dispatch, rdxFilterObj]);

  useEffect(() => {
    // scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  const fOnpressLoad = async () => {
    // scanFocusOnLoad.current.focus();
    // if (rdxFilterObj.dateFrom < rdxFilterObj.dateTo) {
    await fGetDetails();

    //   dispatch(RetunReviewBatchHead(sHeadObj));
    // } else {
    //   fAlertToast('To Date cannot be less then from date');
    // }
  };

  const fOnpressItem = (item) => {
    dispatch(
      UpdateRetunReviewBatchDetail({
        A_BatchId: item.A_BatchId,
        A_PostDt: item.A_PostDt,
        A_DCLoc: item.A_DCLoc,
        DCLocName: item.DCLocName,
        A_Status: item.A_Status,
        A_Endt: item.A_Endt,
        Dept: item?.Departments,
        LocNames: item?.LocNames,
        RequestIDs: item?.RequestIDs
      })
    );

    navigate('/ReviewBatchDetails');
  };

  return (
    <div className="container ReviewBatch">
      <div
      // className="my-2"
      >
        <ReviewBatchBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-3">
          <Grid item xs={6} sm={7} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5.7} sm={2.6} md={3}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={1} sm={0.4} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4.5} sm={8} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateFrom).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={5} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={5} sm={3} md={3}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4.5} sm={8} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(rdxFilterObj.dateTo).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} md={4.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.75} sm={2.6} md={3}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.5} sm={0.4} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.5} sm={9} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={2} md={0.98}>
                <CmpTypographyFieldColor xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={1} sm={1} md={0.25}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>

              <Grid item xs={8.3} sm={8} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={sFilteredLocNames.toString()} />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={5} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.75} sm={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={0.45} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.4} sm={8} md={7}>
                <CmpSelectArrField
                  xLabelText="Status"
                  xValue={rdxHeadobj.status}
                  xOnChange={(event) => {
                    dispatch(
                      RetunReviewBatchHead({
                        ...rdxHeadobj,
                        status: event.target.value
                      })
                    );
                  }}
                  xName={'Status'}
                  xData={statusList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'4'}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: vMediaQuery ? 'center' : 'end' }}>
              <Grid item xs={3} sm={1.5} md={1.5} className="text-end">
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="mb-4"
          sx={{ alignItems: 'center' }}>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={5} md={4.5}>
                <CmpTypographyField xcomponent={'span'} xText={'Department'} />
              </Grid>
              <Grid item xs={7.5} sm={7} md={7.5}>
                <CmpSelectField
                  xTabIndex="5"
                  xLabelText="Department"
                  xValue={rdxHeadobj.Dept}
                  xOnChange={(event) => {
                    dispatch(
                      RetunReviewBatchHead({
                        ...rdxHeadobj,
                        Dept: event.target.value
                      })
                    );
                  }}
                  xName={'Department'}
                  xData={sDeptList}
                  xValueMember={''}
                  xDispalyMember={''}
                  // xError={sErrorMsg.locCd !== ''}
                  // xErrorMessage={sErrorMsg.locCd}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        {/* <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}
                    className="my-2">
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid
                            container
                            spacing={0}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ justifyContent: 'end' }}>

                            <Grid item xs={3} sm={6} md={1} className="text-end">
                                <CmpButton
                                    xvariant={'contained'}
                                    xsize={'small'}
                                    xonClick={fOnpressLoad}
                                    xLabel={'Load'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          {sDetailObj.length === 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : (
            sDetailObj.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  key={itm.A_BatchId}
                  onClick={() => fOnpressItem(itm)}>
                  <BatchSummaryCard
                    xBatchId={itm.A_BatchId}
                    xEnDt={moment(itm.A_Endt).utcOffset('+0000').format('DD/MM/YYYY  h:mm:ss a')}
                    xStatus={itm.A_Status}
                    Departments={itm.Departments}
                    LocNames={itm.LocNames}
                    DCLocName={itm.DCLocName}
                    RequestIDs={itm.RequestIDs}
                    item={itm}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
