import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Modal, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import {
  deleteFPRequestHead,
  deleteFPRequestItem,
  generateFPRequestItems,
  getAuditFields,
  getAuditValues,
  getDaysList,
  getDepartmentList,
  getFPRequestDocRights,
  getFPRequestItemList,
  getVoucherList,
  postFPRequestHead,
  releaseFPRequestItems,
  reverseFPRequest,
  saveFPRequestHead,
  updateFPRequestItems,
  validateFPRequestHead
} from './actions';
import {
  CmpButton,
  CmpButtonWithIcon,
  CmpCheckboxField,
  CmpDatePickerField,
  CmpInput,
  CmpNumberPicker,
  CmpRadioField,
  CmpSelectField,
  CmpStatusDiv,
  CmpTypographyField
} from '../../component/ToolBox/ToolBox';
import { updateDocRights, updateRequestItemsDetail } from './OutletRequestSlice';
import {
  Add,
  AddCircle,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
  CheckBox,
  CheckBoxOutlined,
  Delete,
  ListOutlined,
  RemoveCircle,
  Save,
  Settings,
  SettingsBackupRestoreOutlined,
  Verified
} from '@mui/icons-material';
import { fAlertToast } from '../../Utility/Utilitys';
import { confirmAlert } from 'react-confirm-alert';
import RequestItemGrid from './ProcessComponents/RequestItemGrid';
import RequestItmColumnPicker from './ProcessComponents/RequestItmColumnPicker';
import RequestBreadCrumbs from './ProcessComponents/RequestBreadCrumbs';
import { Print } from '@mui/icons-material';
import GCAudit from '../../component/ReuseableComponent/GCAudit';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'background.paper',
  border: '2px solid #0d73bb',
  boxShadow: 24,
  // p: 4,
  textAlign: 'center',
  // alignItems: "center",
  // borderRadius: 3,
  height: '80%',
  margin: '0 auto',
  color: '#0d73bb'
};
export default function PurchaseRequestDetail001() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locList = useSelector((state) => state.SliceDB.value.getLocation);
  const rdxHeadObj = useSelector((state) => state.OutletRequestSlice.value.requestHead);
  const rdxuser = useSelector((state) => state.SliceDB.value.login);
  const rdxItemList = useSelector((state) => state.OutletRequestSlice.value.requestItemDetail);
  const rdxDocRights = useSelector((state) => state.OutletRequestSlice.value.docRights);
  const cPurchaseEnable = useSelector((state) => state.SliceDB.value.centralpurchase);
  const dcAutoTSREnable = useSelector((state) => state.SliceDB.value.dcAutoTSR);
  const vRdxkern = useSelector((state) => state.SliceDB.value.Kern);

  // const [sItemList, setItemList] = useState([]);
  const [sVoucherList, setVoucherList] = useState([]);
  const [sDeptList, setDeptList] = useState([]);
  const [sDaysList, setDaysList] = useState([]);
  const [sMainPage, setMainPage] = useState(true);
  const [sHeadObj, setHeadObj] = useState({
    vocTyp: '',
    vocNo: '',
    vocDt: new Date(),
    loc: '',
    dept: '',
    CNSType: 1,
    CNSDt1: new Date(),
    CNSDt2: new Date(),
    CNSDays: 0,
    CNSRcndDays: 0,
    CNSRcndDay: '',
    docStat: 'ENTER',
    leadDays: 1,
    DC_Purchase: 'Y'
    // CNTRL_PUR: 'N'
  });
  const [sItemList, setItemList] = useState([]);
  const [sSelectedSrNo, setSelectedSrNo] = useState(0);
  const [sItemSearchModal, setItemSearchModal] = useState(false);
  const [sShowColumnPicker, setShowColumnPicker] = useState(false);
  const vMediaQuery = useMediaQuery('(max-width: 900px)');
  const vMobileScreen = useMediaQuery('(max-width: 600px)');
  const reportBaseUrl = useSelector((state) => state.SliceDB.value.reportBaseUrl);
  const [sShowAuditModal, setShowAuditModal] = useState(false);
  const [sSelectedAuditFld, setSelectedAuditFld] = useState('');
  const [sAuditFields, setAuditFields] = useState([]);
  const [sAuditRows, setAuditRows] = useState([]);
  const [printWebURL, setPrintWebURL] = useState(null);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const fLoadItems = async (xHeadObj) => {
    if (xHeadObj.vocNo !== '') {
      const obj = {
        vocType: xHeadObj.vocTyp,
        vocNo: xHeadObj.vocNo
      };
      const result = await getFPRequestItemList(obj);
      result
        ? dispatch(updateRequestItemsDetail(result.ResultSet))
        : dispatch(updateRequestItemsDetail([]));

      return result ? result.ResultSet : [];
    } else {
      dispatch(updateRequestItemsDetail([]));
    }
    return [];
  };

  const onPageInitialLoad = useCallback(async (vouchers) => {
    setHeadObj({
      ...rdxHeadObj,
      vocTyp:
        rdxHeadObj.vocTyp == ''
          ? vouchers.length > 0
            ? vouchers[0].VocTyp
            : ''
          : rdxHeadObj.vocTyp
    });

    await fLoadItems(rdxHeadObj);
    await getUserRights(rdxHeadObj.vocTyp);
  }, []);

  const fLoadVoucher = useCallback(async () => {
    const obj = {
      userGrp: rdxuser.userGroup,
      userCd: rdxuser.userId
    };
    const result = await getVoucherList(obj);
    setVoucherList(result?.ResultSet);

    return result?.ResultSet;
  }, [rdxuser]);

  const fLoadDepts = useCallback(async () => {
    const result = await getDepartmentList();
    setDeptList(result?.ResultSet);
  }, []);

  const fLoadDaysList = useCallback(async () => {
    const result = await getDaysList();
    setDaysList(result?.ResultSet);
  }, []);

  const fLoadAuditFields = useCallback(async () => {
    const result = await getAuditFields();
    setAuditFields(result?.ResultSet);
    setSelectedAuditFld(result?.ResultSet[0]?.id);
  }, []);

  const fonPageLoad = useCallback(async () => {
    let vouchers = await fLoadVoucher();
    await fLoadDepts();
    await fLoadDaysList();
    await fLoadAuditFields();
    await onPageInitialLoad(vouchers ? vouchers : []);
  }, [fLoadVoucher, fLoadDepts, fLoadDaysList, onPageInitialLoad, fLoadAuditFields]);

  useEffect(() => {
    fonPageLoad();
  }, []);

  const fValidateHead = () => {
    try {
      if (sHeadObj.vocTyp === '') {
        fAlertToast('FAILED', 'Select Voucher Type');
        return false;
      }
      if (sHeadObj.loc === '') {
        fAlertToast('FAILED', 'Select Location');
        return false;
      }
      if (sHeadObj.dept === '') {
        fAlertToast('FAILED', 'Select Department');
        return false;
      }
      try {
        if (!moment(sHeadObj.vocDt).isValid()) {
          fAlertToast('FAILED', 'Select valid doc date');
          return false;
        }
      } catch {
        fAlertToast('FAILED', 'Select valid doc date');
        return false;
      }

      if (sHeadObj.CNSType == 1) {
        if (!moment(sHeadObj.CNSDt1).isValid()) {
          fAlertToast('FAILED', 'Select valid  From date');
          return false;
        }
        if (!moment(sHeadObj.CNSDt2).isValid()) {
          fAlertToast('FAILED', 'Select valid  To date');
          return false;
        }
        if (moment(sHeadObj.CNSDt2).isBefore(moment(sHeadObj.CNSDt1))) {
          fAlertToast('FAILED', 'To Date should be greater than or equal to From Date');
          return false;
        }
      }

      if (sHeadObj.CNSType == 2) {
        if (sHeadObj.CNSDays === '' || sHeadObj.CNSDays === 0) {
          fAlertToast('FAILED', 'Select consumption days');
          return false;
        }
      }
      if (sHeadObj.CNSType == 3) {
        if (sHeadObj.CNSRcndDays === '' || sHeadObj.CNSRcndDays === 0) {
          fAlertToast('FAILED', 'Enter Recent Days');
          return false;
        }
        if (sHeadObj.CNSRcndDay === '') {
          fAlertToast('FAILED', 'Enter Recent Day');
          return false;
        }
      }
      if (Number(sHeadObj.leadDays <= 0)) {
        fAlertToast('FAILED', 'Enter Valid Lead days');
        return false;
      }
      return true;
    } catch (error) {
      fAlertToast('FAILED', error);
    }
  };

  const fValidateDetail = () => {
    try {
    } catch (error) {
      fAlertToast('FAILED', error);
    }
  };

  const fValidateHeadAPI = async (onApprove) => {
    let obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: sHeadObj.vocNo == '' ? '0' : sHeadObj.vocNo,
      vocDt: moment(sHeadObj.vocDt).format('MM/DD/YYYY'),
      loc: sHeadObj.loc,
      approve: onApprove,
      userCd: rdxuser.userId
    };
    const result = await validateFPRequestHead(obj);
    if (result.status === 200) {
      return true;
    }
    return false;
  };

  const fSavehead = async () => {
    let obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: sHeadObj.vocNo == '' ? '0' : sHeadObj.vocNo,
      vocDt: moment(sHeadObj.vocDt).format('MM/DD/YYYY'),
      loc: sHeadObj.loc,
      dept: sHeadObj.dept,
      cnsType: sHeadObj.CNSType,
      cnsDt1: moment(sHeadObj.CNSDt1).format('MM/DD/YYYY'),
      cnsDt2: moment(sHeadObj.CNSDt2).format('MM/DD/YYYY'),
      cnsDays: isNaN(sHeadObj.CNSDays) ? 0 : Number(sHeadObj.CNSDays),
      cnsRcndDays: isNaN(sHeadObj.CNSRcndDays) ? 0 : Number(sHeadObj.CNSRcndDays),
      cnsRcndDay: sHeadObj.CNSRcndDay,
      leadDays: isNaN(sHeadObj.leadDays) ? 0 : Number(sHeadObj.leadDays),
      userCd: rdxuser.userId,
      iP: '',
      dcPurchase: sHeadObj.DC_Purchase,
      CNTRL_PUR: sHeadObj.DC_Purchase == 'Y' ? 'N' : 'Y'
    };
    const result = await saveFPRequestHead(obj);
    if (result.status === 200) {
      const vocNo = result.data.ResultSet[0]?.VocNo;

      if (vocNo == undefined || vocNo === '') {
        fAlertToast('FAILED', 'DOC_NUMBER_REACHED_MAXIMUM, Please change prefix');
      } else {
        fAlertToast('SUCCESS', 'Saved Successfully');
        setHeadObj({ ...sHeadObj, vocNo: vocNo });
        return vocNo;
      }
    }
    return '';
  };

  const fSaveDetail = async (xVocNo) => {
    let items = rdxItemList.filter((i) => Number(i.FinalQty) > 0 && i.ItmCd !== '');
    let itemList = items.map((i) => {
      return {
        itmCd: i.ItmCd,
        cost: Number(i.Cost),
        RSP: Number(i.RSP),
        GP: Number(i.GP),
        stock: Number(i.StkQty),
        orderQty: Number(i.OrderQty),
        salQty: Number(i.SalQty),
        avgSalQty: Number(i.AvgSalQty),
        maxShelfQty: Number(i.MaxShelfQty),
        minShelfQty: Number(i.MinShelfQty),
        reqQty: Number(i.ReqQty),
        finalQty: Number(i.FinalQty),
        buyer: i.Buyer,
        Qty: Number(i.Qty),
        unitCd: i.unitCd
      };
    });
    let obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: xVocNo,
      itemList: itemList
    };
    const result = await updateFPRequestItems(obj);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Saved Successfully');
    }
  };

  const fGenerateDetail = async (vocNo, itemCd = '', srNo = 0) => {
    let obj = {
      vocType: sHeadObj.vocTyp,
      vocNo: vocNo,
      itmCd: itemCd
    };
    const result = await generateFPRequestItems(obj);
    let totalConsDays = 1;
    if (Number(sHeadObj.CNSType) === 1) {
      let startDate = moment(sHeadObj.CNSDt1);
      let endDate = moment(sHeadObj.CNSDt2);

      // console.log(startDate, endDate, endDate.diff(startDate, 'days'));
      totalConsDays = endDate.diff(startDate, 'days') + 1;
    } else if (Number(sHeadObj.CNSType) === 2) {
      totalConsDays = Number(sHeadObj.CNSDays);
    } else {
      totalConsDays = Number(sHeadObj.CNSRcndDays);
    }

    let CNSLeadDays = Number(sHeadObj.leadDays);

    // console.log('totalConsDays' + totalConsDays);
    // console.log('CNSLeadDays' + CNSLeadDays);
    // console.log('sHeadObj.CNSType' + sHeadObj.CNSType);

    if (itemCd === '') {
      let itmList = result?.ResultSet.map((i) => {
        let GP = Number(i.RSP) === 0 ? 0 : (Number(i.RSP) - Number(i.Cost)) / Number(i.RSP);
        let avgSalQty = Number(i.SalQty) / totalConsDays;
        let stkqty = i.StkQty < 0 ? 0 : i.StkQty;
        let orderqty = i.OrderQty < 0 ? 0 : i.OrderQty;

        let reqQty = Math.ceil(avgSalQty * CNSLeadDays - orderqty - stkqty);
        if (reqQty <= 0) reqQty = 0;

        return {
          SrNo: i.SrNo,
          ItmCd: i.ItmCd,
          ItemName: i.ItmName,
          PLU: i.PLU,
          unit: i.unit,
          BarCode: i.BarCode,
          // BaseItmCd: i.BaseItmCd,
          // BaseItmName: i.BaseItmName,
          // MainGrp: i.MainGrp,
          // MainGrpName: i.MainGrpName,
          // SubGrp: i.SubGrp,
          // SubGrpName: i.SubGrpName,
          // Grp: i.Grp,
          // GrpName: i.GrpName,
          //Brand: i.Brand,
          //BrandName: i.BrandName,
          // MainSupplier: i.MainSupplier,
          // VendorName: i.VendorName,
          Buyer: i.Buyer,
          BuyerName: i.BuyerName,
          Cost: i.Cost,
          RSP: i.RSP,
          GP: GP,
          StkQty: i.StkQty,
          OrderQty: i.OrderQty,
          SalQty: i.SalQty,
          AvgSalQty: avgSalQty,
          MaxShelfQty: i.MaxShelfQty,
          MinShelfQty: i.MinShelfQty,
          ReqQty: reqQty,
          FinalQty: reqQty,
          Qty: reqQty,
          unitCd: i.unitCd,
          unitList: i.unitList
        };
      });
      // dispatch(updateRequestItemsDetail([]));
      itmList
        ? dispatch(updateRequestItemsDetail(itmList))
        : dispatch(updateRequestItemsDetail([]));
      setItemList(itmList.slice(0, 10));

      return true;
    } else {
      let newItem = result?.ResultSet?.length > 0 ? result?.ResultSet[0] : undefined;
      if (!newItem) return false;

      let GP =
        Number(newItem.RSP) === 0
          ? 0
          : (Number(newItem.RSP) - Number(newItem.Cost)) / Number(newItem.RSP);
      let avgSalQty = Number(newItem.SalQty) / totalConsDays;

      let stkqty = newItem.StkQty < 0 ? 0 : newItem.StkQty;
      let orderqty = newItem.OrderQty < 0 ? 0 : newItem.OrderQty;

      let reqQty = Math.ceil(avgSalQty * CNSLeadDays - orderqty - stkqty);
      if (reqQty <= 0) reqQty = 0;

      let itemList = rdxItemList.map((i) => {
        return i.SrNo === srNo
          ? {
              SrNo: srNo,
              ItmCd: newItem.ItmCd,
              ItemName: newItem.ItmName,
              PLU: newItem.PLU,
              unit: newItem.unit,
              BarCode: newItem.BarCode,
              // BaseItmCd: newItem.BaseItmCd,
              // BaseItmName: newItem.BaseItmName,
              // MainGrp: newItem.MainGrp,
              // MainGrpName: newItem.MainGrpName,
              // SubGrp: newItem.SubGrp,
              // SubGrpName: newItem.SubGrpName,
              // Grp: newItem.Grp,
              // GrpName: newItem.GrpName,
              //Brand: newItem.Brand,
              //BrandName: newItem.BrandName,
              // MainSupplier: newItem.MainSupplier,
              // VendorName: newItem.VendorName,
              Buyer: newItem.Buyer,
              BuyerName: newItem.BuyerName,
              Cost: newItem.Cost,
              RSP: newItem.RSP,
              GP: GP,
              StkQty: newItem.StkQty,
              OrderQty: newItem.OrderQty,
              SalQty: newItem.SalQty,
              AvgSalQty: avgSalQty,
              MaxShelfQty: newItem.MaxShelfQty,
              MinShelfQty: newItem.MinShelfQty,
              ReqQty: reqQty,
              FinalQty: reqQty,
              Qty: reqQty,
              unitCd: newItem.unitCd,
              unitList: newItem.unitList
            }
          : i;
      });
      dispatch(updateRequestItemsDetail(itemList));
      return true;
    }
  };

  const onClickGenerate = async () => {
    if (!fValidateHead()) return;
    let apiValidHead = await fValidateHeadAPI(false);
    if (!apiValidHead) return;

    const vocNo = await fSavehead();
    await fGenerateDetail(vocNo);
  };

  const onClickRelease = async () => {
    confirmAlert({
      title: 'Release',
      message: 'Are you sure to Release?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            let obj = {
              vocTyp: sHeadObj.vocTyp,
              vocNo: sHeadObj.vocNo
            };
            const result = await releaseFPRequestItems(obj);
            if (result.status === 200) {
              fAlertToast('SUCCESS', 'Released Successfully');
              dispatch(updateRequestItemsDetail([]));
            }
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const onClickPrint = () => {
    setOpenPrintModal(true);
    let url =
      reportBaseUrl +
      `FreshPurchase/FreshPurchaseRequest?trntyp=VOUCHAO&VocTyp=${sHeadObj.vocTyp}&VocNo=${sHeadObj.vocNo}`;
    // window.open(url);
    console.log(url, 'url');
    setPrintWebURL(url);
  };

  const onClickNewItem = async () => {
    if (rdxItemList?.length == 0) {
      if (!fValidateHead()) return;
      let apiValidHead = await fValidateHeadAPI(false);
      if (!apiValidHead) return;
      await fSavehead();
    }
    console.log(rdxItemList, 'rdxItemList');
    const data = rdxItemList?.filter((x) => x.ItmCd == '');
    if (data?.length == 0) {
      let obj = {
        SrNo: rdxItemList.length + 1,
        ItmCd: '',
        ItemName: '',
        PLU: '',
        unit: '',
        BarCode: '',
        Buyer: '',
        BuyerName: '',
        Cost: 0,
        RSP: 0,
        GP: 0,
        StkQty: 0,
        OrderQty: 0,
        SalQty: 0,
        AvgSalQty: 0,
        MaxShelfQty: 0,
        MinShelfQty: 0,
        ReqQty: 0,
        FinalQty: 0,
        Qty: 0,
        unitCd: '',
        unitList: []
      };
      let newList = [obj, ...rdxItemList];
      dispatch(updateRequestItemsDetail(newList));
      setSelectedSrNo(rdxItemList.length + 1);
    }
    setItemSearchModal(true);
  };

  const onClickSave = async () => {
    if (!fValidateHead()) return;

    // if (!fValidateDetail()) return;//nothing to validate
    let apiValidHead = await fValidateHeadAPI(false);
    if (!apiValidHead) return;

    const vocNo = await fSavehead();
    await fSaveDetail(vocNo);
    const obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: vocNo
    };

    await fLoadItems(obj);
  };

  const onClickPost = async () => {
    const obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: sHeadObj.vocNo
    };

    let itmList = await fLoadItems(obj);

    if (!fValidateHead()) return;

    let apiValidHead = await fValidateHeadAPI(true);
    if (!apiValidHead) return;

    if (!itmList) {
      fAlertToast('FAILED', 'Atleast one item should be saved!');
      return;
    }

    for (let i of itmList) {
      if (i.Buyer == '') {
        fAlertToast('FAILED', 'Buyer not found for Item (' + i.ItemName + ')');
        return;
      }
    }

    await fSavehead();

    const postObj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: sHeadObj.vocNo,
      userCd: rdxuser.userId
    };
    const result = await postFPRequestHead(postObj);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Posted Successfully');
      setHeadObj({ ...sHeadObj, docStat: 'POST' });
    }
  };
  const onClickReverse = async () => {
    const obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: sHeadObj.vocNo,
      userCd: rdxuser.userId
    };
    const result = await reverseFPRequest(obj);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Reversed Successfully');
      setHeadObj({ ...sHeadObj, docStat: 'ENTER' });
    }
  };

  const onClickDelete = async () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to Delete?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            const obj = {
              vocTyp: sHeadObj.vocTyp,
              vocNo: sHeadObj.vocNo,
              userCd: rdxuser.userId
            };
            const result = await deleteFPRequestHead(obj);
            if (result.status === 200) {
              fAlertToast('SUCCESS', 'Deleted Successfully');
              setHeadObj({ ...sHeadObj, docStat: 'DELETE' });
            }
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
  const onClickDeleteItem = async (srNo) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to Delete?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            const obj = {
              vocTyp: sHeadObj.vocTyp,
              vocNo: sHeadObj.vocNo,
              srNo: srNo
            };
            const result = await deleteFPRequestItem(obj);
            if (result.status === 200) {
              fAlertToast('SUCCESS', 'Deleted Successfully');
            }
            await fLoadItems({
              vocTyp: sHeadObj.vocTyp,
              vocNo: sHeadObj.vocNo
            });
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const getUserRights = async (vocType) => {
    const obj = { vocType: vocType, userGrp: rdxuser.userGroup };
    const result = await getFPRequestDocRights(obj);
    if (result.status === 200) {
      if (result?.data?.ResultSet?.length > 0) {
        const rigths = result.data.ResultSet[0];
        dispatch(
          updateDocRights({
            rSave: rigths.Save,
            rEdit: rigths.Edit,
            rDel: rigths.Del,
            rView: rigths.View,
            rAppr: rigths.Appr,
            rReverse: rigths.Reverse,
            rCancel: rigths.Cancel,
            rPrint: rigths.Print,
            rClone: rigths.Clone
          })
        );
      }
    }
  };
  const onChangeVoucher = async (vocType) => {
    setHeadObj({ ...sHeadObj, vocTyp: vocType });
    await getUserRights(vocType);
  };

  const fLoadAuditValues = async (xSelectedAuditFld) => {
    const obj = {
      vocTyp: sHeadObj.vocTyp,
      vocNo: sHeadObj.vocNo,
      field: xSelectedAuditFld
    };
    const result = await getAuditValues(obj);
    let transAuditRes = result?.ResultSet.map((obj, i) => ({
      ...obj,
      id: i + 1
    }));
    setAuditRows(transAuditRes);
  };

  const onChangeAuditSelect = (e) => {
    setSelectedAuditFld(e.target.value);
    fLoadAuditValues(e.target.value);
  };

  const auditGridColumn = [
    {
      field: 'User',
      headerName: 'User',
      flex: 2
    },

    {
      field: 'Date',
      headerName: 'Date',
      flex: 1
    },
    {
      field: 'Time',
      headerName: 'Time',
      flex: 2
    },
    {
      field: 'Field',
      headerName: 'Field',
      flex: 1
    },
    {
      field: 'Old Value',
      headerName: 'Old Value',
      flex: 1
    },
    {
      field: 'New Value',
      headerName: 'New Value',
      flex: 1
    },
    {
      field: 'ItmName',
      headerName: 'Item Name',
      flex: 2
    }
  ];

  const onClickAudit = () => {
    fLoadAuditValues(sSelectedAuditFld);
    setShowAuditModal(true);
  };
  console.log(sHeadObj, 'sHeadObj-check');

  return (
    <div className="container">
      <div>
        <RequestBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mb-3">
          {/* <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            // xonClick={fNewDetail}
                            xLabel={'New'}
                        //xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid> */}
          <Grid item xs={4} sm={2} md={1}>
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onClickSave}
              xLabel={'Save'}
              xIcon={<Save />}
              xDisable={
                sHeadObj.docStat.toString().toUpperCase() !== 'ENTER' || rdxDocRights.rEdit !== 'Y'
              }
            />
          </Grid>
          {(!vMobileScreen || sMainPage) && (
            <>
              <Grid item xs={4} sm={2} md={1}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickPost}
                  xLabel={'Post'}
                  xIcon={<Verified />}
                  xDisable={
                    sHeadObj.docStat.toString().toUpperCase() !== 'ENTER' ||
                    rdxDocRights.rAppr !== 'Y'
                  }
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1.2}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickReverse}
                  xLabel={'Reverse'}
                  xIcon={<SettingsBackupRestoreOutlined />}
                  xDisable={
                    sHeadObj.docStat.toString().toUpperCase() !== 'POST' ||
                    rdxDocRights.rAppr !== 'Y'
                  }
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1.2}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickDelete}
                  xLabel={'Delete'}
                  xIcon={<Delete />}
                  xDisable={
                    sHeadObj.docStat.toString().toUpperCase() !== 'ENTER' ||
                    rdxDocRights.rDel !== 'Y'
                  }
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1.2}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickGenerate}
                  xLabel={'Generate'}
                  xIcon={<Settings />}
                  xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                />
              </Grid>

              <Grid item xs={4} sm={2} md={1.2}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickRelease}
                  xLabel={'Release '}
                  xIcon={<RemoveCircle />}
                  xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickPrint}
                  xLabel={'Print'}
                  xIcon={<Print />}
                  xDisable={rdxDocRights.rPrint !== 'Y'}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={onClickAudit}
                  xLabel={'Audit'}
                  xIcon={<ListOutlined />}
                  xDisable={rdxDocRights.rPrint !== 'Y'}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4} sm={3} md={1.6}>
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onClickNewItem}
              xLabel={'Add Item'}
              xIcon={<AddCircle />}
              xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
            />
          </Grid>
          {!vMediaQuery && (
            <Grid item md={1.6}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'small'}
                xonClick={() => {
                  setShowColumnPicker(true);
                }}
                xLabel={'Columns'}
                xIcon={<CheckBox />}
              />
            </Grid>
          )}
          {vMobileScreen && sMainPage && (
            <Grid item xs={4}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'small'}
                xonClick={() => {
                  setMainPage(false);
                }}
                xLabel={'Next'}
                xIcon={<ArrowRight />}
              />
            </Grid>
          )}
          {vMobileScreen && !sMainPage && (
            <Grid item xs={4}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'small'}
                xonClick={() => {
                  setMainPage(true);
                }}
                xLabel={'Back'}
                xIcon={<ArrowLeft />}
              />
              {/* <IconButton size='large' color='primary' onClick={() => { setMainPage(true) }}>
                                    <ArrowCircleLeft sx={{ fontSize: '3rem' }} />
                                </IconButton> */}
            </Grid>
          )}
        </Grid>
        {sMainPage && (
          <Grid
            container
            spacing={1}
            columnSpacing={2}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}
            className="mb-3">
            <Grid item xs={12} sm={12} md={7.5}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Doc.Type'} />
                    </Grid>
                    <Grid item xs={1} sm={0.5} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={8.5} md={8}>
                      <CmpSelectField
                        xTabIndex="1"
                        xLabelText="Doctype"
                        xValue={sHeadObj.vocTyp}
                        xOnChange={(event) => {
                          onChangeVoucher(event.target.value);
                        }}
                        xName={'Doctype'}
                        xData={sVoucherList}
                        xValueMember={''}
                        xDispalyMember={''}
                        // xError={sErrorMsg.locCd !== ''}
                        // xErrorMessage={sErrorMsg.locCd}
                        xDisable={sHeadObj.vocNo !== ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Doc.No.'} />
                    </Grid>
                    <Grid item xs={1} sm={0.5} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={8.5} md={8}>
                      <CmpInput
                        xType={'text'} // text/number/password
                        xName={'Doc no'}
                        xReadOnly={true}
                        xTabIndex={111}
                        xValue={sHeadObj.vocNo}
                        xOnChange={(event) => {
                          setHeadObj({ ...sHeadObj, vocNo: event.target.value });
                        }}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                        xTextAlign="left"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                    </Grid>
                    <Grid item xs={1} sm={0.5} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={8.5} md={8}>
                      <CmpDatePickerField
                        xTabIndex="2"
                        xValue={sHeadObj.vocDt}
                        xOnChange={(newValue) => setHeadObj({ ...sHeadObj, vocDt: newValue?.$d })}
                        xName={'Date'}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={cPurchaseEnable ? 2 : 3} md={cPurchaseEnable ? 2 : 3}>
                  <Grid
                    container
                    // spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={cPurchaseEnable ? 8 : 6.2} md={cPurchaseEnable ? 8 : 6}>
                      <CmpTypographyField xcomponent={'span'} xText={'No. of Days'} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={cPurchaseEnable ? 1 : 2}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={cPurchaseEnable ? 3 : 4} md={cPurchaseEnable ? 3 : 4}>
                      <CmpNumberPicker
                        xType={'number'} // text/number/password
                        xName={'days'}
                        xReadOnly={false}
                        xTabIndex={3}
                        xValue={sHeadObj.leadDays}
                        xOnChange={(event) => {
                          setHeadObj({ ...sHeadObj, leadDays: event.target.value });
                        }}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                        xTextAlign="center"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={cPurchaseEnable ? 2 : 3} md={cPurchaseEnable ? 2 : 3}>
                  <Grid
                    container
                    // spacing={1}
                    alignItems="center"
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Type'} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    {/* <Grid item xs={1} sm={cPurchaseEnable ? 1 : 2} md={cPurchaseEnable ? 2 : 2}>
                      <FormGroup className="CmpCheckboxField inputfont">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sHeadObj.DC_Purchase == 'Y'}
                              onChange={() => {
                                if (cPurchaseEnable) {
                                  setHeadObj({
                                    ...sHeadObj,
                                    DC_Purchase: sHeadObj.DC_Purchase == 'Y' ? 'N' : 'Y',
                                    CNTRL_PUR: sHeadObj.CNTRL_PUR === 'Y' ? 'N' : 'Y'
                                  });
                                } else {
                                  setHeadObj({
                                    ...sHeadObj,
                                    DC_Purchase: sHeadObj.DC_Purchase == 'Y' ? 'N' : 'Y'
                                  });
                                }
                              }}
                              inputProps={{ className: 'inputfont' }}
                            />
                          }
                          name={''}
                          label={''}
                          // disabled={!dcAutoTSREnable}
                          disabled={vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'}
                        />
                      </FormGroup>
                      
                    </Grid> */}
                    <Grid item xs={7} sm={8} md={8}>
                      <CmpSelectField
                        xLabelText="Type"
                        xValue={sHeadObj.DC_Purchase}
                        xOnChange={(event) => {
                          setHeadObj({
                            ...sHeadObj,
                            DC_Purchase: event.target.value
                          });
                        }}
                        xName={'DC'}
                        xData={[
                          {
                            UId: 'Y',
                            Name1: 'Market'
                          },
                          {
                            UId: 'N',
                            Name1: 'DC'
                          }
                        ]}
                        xValueMember={''}
                        xDispalyMember={''}
                        xError={false}
                        xErrorMessage={false}
                        xDisable={vRdxkern?.FP_DC_AUTO_TSR?.Value == 'N'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* {cPurchaseEnable && ( */}

                {/* <Grid item xs={12} sm={2} md={2}>
                    <Grid
                      container 
                      alignItems="center"
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                      <Grid item xs={4} sm={8} md={10.5}>
                        <CmpTypographyField xcomponent={'span'} xText={'CentralPurchase'} />
                      </Grid>
                      <Grid item xs={1} sm={2} md={0.5}>
                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                      </Grid>
                      <Grid item xs={1} sm={2} md={0.1}>
                        <FormGroup className="CmpCheckboxField inputfont">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sHeadObj.CNTRL_PUR === 'Y'}
                                onChange={() => {
                                  if (dcAutoTSREnable) {
                                    setHeadObj({
                                      ...sHeadObj,
                                      CNTRL_PUR: sHeadObj.CNTRL_PUR === 'Y' ? 'N' : 'Y',
                                      DC_Purchase: sHeadObj.DC_Purchase === 'Y' ? 'N' : 'Y'
                                    });
                                  } else {
                                    setHeadObj({
                                      ...sHeadObj,
                                      CNTRL_PUR: sHeadObj.CNTRL_PUR === 'Y' ? 'N' : 'Y'
                                    });
                                  }
                                }}
                                inputProps={{ className: 'inputfont' }}
                              />
                            }
                            name={''}
                            label={''}
                          />
                        </FormGroup>
                       
                      </Grid>
                    </Grid>
                  </Grid> */}
                {/* )} */}
                <Grid item xs={12} sm={6} md={6}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                    </Grid>
                    <Grid item xs={1} sm={0.5} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={8.5} md={8}>
                      <CmpSelectField
                        xTabIndex="4"
                        xLabelText="Location"
                        xValue={sHeadObj.loc}
                        xOnChange={(event) => {
                          setHeadObj({ ...sHeadObj, loc: event.target.value });
                        }}
                        xName={'Location'}
                        xData={locList}
                        xValueMember={''}
                        xDispalyMember={''}
                        // xError={sErrorMsg.locCd !== ''}
                        // xErrorMessage={sErrorMsg.locCd}
                        xDisable={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Department'} />
                    </Grid>
                    <Grid item xs={1} sm={0.5} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={8.5} md={8}>
                      <CmpSelectField
                        xTabIndex="5"
                        xLabelText="Department"
                        xValue={sHeadObj.dept}
                        xOnChange={(event) => {
                          setHeadObj({ ...sHeadObj, dept: event.target.value });
                        }}
                        xName={'Department'}
                        xData={sDeptList}
                        xValueMember={''}
                        xDispalyMember={''}
                        // xError={sErrorMsg.locCd !== ''}
                        // xErrorMessage={sErrorMsg.locCd}
                        xDisable={false}
                      />
                      {/* <CmpMultiSelectAll
                                            xLabelText="Department"
                                            xValue={sHeadObj.dept}
                                            xOnChange={(event) => onChangeDeptList(event)}
                                            xName={'Department'}
                                            xData={[]}
                                            xError={false}
                                            xErrorMessage={false}
                                            xDisable={false}
                                            xTabIndex={'5'}
                                        /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4.5}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12 }}
                // className={vMediaQuery ? 'mt-3' : ''}
                sx={{ alignItems: 'center' }}>
                {/* <Grid item xs={12} className='mb-2'>
                                    <CmpTypographyField xcomponent={'span'} xText={'Consumption Details'} />
                                </Grid> */}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={1.5}>
                      <CmpRadioField
                        xName={'CNSType1'}
                        xChecked={Number(sHeadObj.CNSType) === 1}
                        xOnChange={() => setHeadObj({ ...sHeadObj, CNSType: 1 })}
                        xNameField={''}
                        xTabIndex={6}
                        xValue={'CNSType1'}
                      />
                    </Grid>

                    <Grid item xs={10.5}>
                      <Grid
                        container
                        columnSpacing={2}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={2.1} md={2}>
                          <CmpTypographyField xcomponent={'span'} xText={'From'} />
                        </Grid>
                        <Grid item xs={4.3} md={4}>
                          <CmpDatePickerField
                            xTabIndex="7"
                            xValue={sHeadObj.CNSDt1}
                            xOnChange={(newValue) =>
                              setHeadObj({ ...sHeadObj, CNSDt1: newValue?.$d })
                            }
                            xName={'Date'}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                            xDisabled={Number(sHeadObj.CNSType) !== 1}
                          />
                        </Grid>
                        <Grid item xs={1.4} md={2}>
                          <CmpTypographyField xcomponent={'span'} xText={'To'} />
                        </Grid>
                        <Grid item xs={4.2} md={4}>
                          <CmpDatePickerField
                            xTabIndex="8"
                            xValue={sHeadObj.CNSDt2}
                            xOnChange={(newValue) =>
                              setHeadObj((obj) => ({ ...sHeadObj, CNSDt2: newValue?.$d }))
                            }
                            xName={'To Date'}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                            xDisabled={Number(sHeadObj.CNSType) !== 1}
                          />
                        </Grid>
                        <Grid />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={1.5}>
                      <CmpRadioField
                        xName={'CNSType2'}
                        xChecked={Number(sHeadObj.CNSType) === 2}
                        xOnChange={() => setHeadObj({ ...sHeadObj, CNSType: 2 })}
                        xNameField={''}
                        xTabIndex={9}
                        xValue={'CNSType2'}
                      />
                    </Grid>

                    <Grid item xs={10.5}>
                      <Grid
                        container
                        columnSpacing={2}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <CmpInput
                            xType={'number'} // text/number/password
                            xName={'Days'}
                            xReadOnly={Number(sHeadObj.CNSType) !== 2}
                            xTabIndex={10}
                            xValue={sHeadObj.CNSDays}
                            xOnChange={(event) => {
                              setHeadObj({ ...sHeadObj, CNSDays: event.target.value });
                            }}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                            xTextAlign="center"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CmpTypographyField xcomponent={'span'} xText={'Days'} />
                        </Grid>

                        <Grid />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={1.5}>
                      <CmpRadioField
                        xName={'CNSType3'}
                        xChecked={Number(sHeadObj.CNSType) === 3}
                        xOnChange={() => setHeadObj({ ...sHeadObj, CNSType: 3 })}
                        xNameField={''}
                        xTabIndex={11}
                        xValue={'CNSType3'}
                      />
                    </Grid>

                    <Grid item xs={10.5}>
                      <Grid
                        container
                        columnSpacing={2}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={2.5}>
                          <CmpTypographyField xcomponent={'span'} xText={'Recent'} />
                        </Grid>
                        <Grid item xs={3}>
                          <CmpInput
                            xType={'number'} // text/number/password
                            xName={'RecentDays'}
                            xReadOnly={Number(sHeadObj.CNSType) !== 3}
                            xTabIndex={12}
                            xValue={sHeadObj.CNSRcndDays}
                            xOnChange={(event) => {
                              setHeadObj({ ...sHeadObj, CNSRcndDays: event.target.value });
                            }}
                            xError={false}
                            xErrorMessage={''}
                            xOnKeyUp={''}
                            xTextAlign="center"
                          />
                        </Grid>
                        <Grid item xs={6.5}>
                          <CmpSelectField
                            xTabIndex="14"
                            xLabelText="RecentDay"
                            xValue={sHeadObj.CNSRcndDay}
                            xOnChange={(event) => {
                              setHeadObj({ ...sHeadObj, CNSRcndDay: event.target.value });
                            }}
                            xName={'day'}
                            xData={sDaysList}
                            xValueMember={''}
                            xDispalyMember={''}
                            // xError={sErrorMsg.locCd !== ''}
                            // xErrorMessage={sErrorMsg.locCd}
                            xDisable={Number(sHeadObj.CNSType) !== 3}
                          />
                        </Grid>

                        <Grid />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* {(vMobileScreen || !sMainPage) && ( */}
        <>
          <RequestItemGrid
            xGenerateDetail={fGenerateDetail}
            xVocNo={sHeadObj?.vocNo}
            xMobileScreen={vMobileScreen}
            xMediaQuery={vMediaQuery}
            xDocStat={sHeadObj?.docStat}
            xOnClickDeleteItem={onClickDeleteItem}
            sItemSearchModal={sItemSearchModal}
            setItemSearchModal={setItemSearchModal}
            setSelectedSrNo={setSelectedSrNo}
            sSelectedSrNo={sSelectedSrNo}
            loc={sHeadObj.loc}
          />
        </>
        {/* )} */}
        {(!vMobileScreen || sMainPage) && (
          <Grid
            container
            spacing={1}
            columns={{ xs: 12, sm: 12, md: 5 }}
            className="mt-2"
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={1}>
              <CmpStatusDiv xStatus={sHeadObj.docStat} />
            </Grid>
          </Grid>
        )}
      </Box>
      <Modal
        open={sShowColumnPicker}
        onClose={() => setShowColumnPicker(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-req_itm-col-modal">
          <RequestItmColumnPicker setShowColumnPicker={setShowColumnPicker} />
        </Box>
      </Modal>

      <GCAudit
        xOpen={sShowAuditModal}
        xTitle={'AUDIT'}
        fCloseAuditModal={() => setShowAuditModal(false)}
        sAuditSelectArr={sAuditFields}
        GridDataAuditColumns={auditGridColumn}
        GridDataAuditRows={sAuditRows}
        xAuditId={sSelectedAuditFld}
        fonChangeAuditSelect={onChangeAuditSelect}
        xLabel={sHeadObj.vocTyp + ' - ' + sHeadObj.vocNo}
      />
      <Modal
        open={openPrintModal}
        onClose={() => setOpenPrintModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box className="GCSearchBtn-Tittle">
            <span>Print</span>
            <CloseIcon
              sx={{ zIndex: 999, float: 'right' }}
              onClick={() => setOpenPrintModal(false)}
            />
          </Box>
          <iframe src={printWebURL} width="100%" height={500}></iframe>
        </Box>
      </Modal>
    </div>
  );
}
