import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './DCConfirmation.css';
import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpButton,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import { Refresh } from '@mui/icons-material';
import DCConfirmationSumarryCard from './processComponent/DCConfirmationSumarryCard';
import DCConfirmationBreadCrumbs from './DCConfirmationBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { DCConfirmation_Detail, DCConfirmation_Head } from './DCConfirmationSlice';
import { getDCConfirmationSummaryList } from './action';

function DCConfirmationSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDCLoc = useSelector((state) => state.SliceDB.value.getDCLocation);
  const scanFocusOnLoad = useRef(null);

  const [sDCconfirmDetailObj, setDCconfirmDetailObj] = useState({
    location: getDCLoc[0].UId,
    dateFrom: moment().startOf('month'),
    dateTo: new Date()
  });

  // const [sConfirmationList, setConfirmationList] = useState([
  //   {
  //     A_TransId: '',
  //     A_PostDt: new Date(),
  //     A_Loc: '',
  //     LocName: '',
  //     A_Driver: '',
  //     DriverName: '',
  //     A_Truck: '',
  //     TruckName: '',
  //     A_ReqTransId: 4,
  //     A_Type: '',
  //     A_PickStatus: '',
  //     A_PickDt: '',
  //     A_DispatchStatus: '',
  //     A_DispatchDt: '',
  //     A_RefNo: '',
  //     A_Narration: '',
  //     ItemCount: 1
  //   }
  // ]);

  const [sConfirmationList, setConfirmationList] = useState([]);

  const fOnPageLoad = useCallback(async () => {
    dispatch(
      DCConfirmation_Head({
        location: getDCLoc[0].UId,
        dateFrom: moment().startOf('month'),
        dateTo: new Date()
      })
    );

    fOnpressLoad();
  }, [dispatch]);

  useEffect(() => {
    scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  const fGetDCConfirmationSummaryList = async () => {
    const cdConfirmationSummaryObj = {
      v_Loc: sDCconfirmDetailObj.location,
      v_Date1: moment(sDCconfirmDetailObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(sDCconfirmDetailObj.dateTo).format('MM/DD/YYYY')
    };
    const result = await getDCConfirmationSummaryList(cdConfirmationSummaryObj);
    return result.data.ResultSet;
  };

  const fOnpressLoad = async () => {
    scanFocusOnLoad.current.focus();
    if (sDCconfirmDetailObj.dateFrom < sDCconfirmDetailObj.dateTo) {
      const result = await fGetDCConfirmationSummaryList();
      if (result.length > 0) {
        setConfirmationList(result);
      } else {
        setConfirmationList([]);
      }

      dispatch(DCConfirmation_Head(sDCconfirmDetailObj));
    } else {
      fAlertToast('To Date cannot be less then from date');
    }
  };

  const fOnpressItem = (item) => {
    dispatch(
      DCConfirmation_Detail({
        A_TransId: item.A_TransId,
        TruckName: item.TruckName,
        LocName: item.LocName,
        A_DispatchDt: item.A_DispatchDt,
        Status: item.Status,
        ItemCount: item.ItemCount,
        A_TransDt: item.A_TransDt,
        A_Truck: item.A_Truck,
        Loc: item.Loc
      })
    );
    navigate('/DCConfirmationDetail');
  };

  const onChangeLocation = (event) => {
    setDCconfirmDetailObj((obj) => ({
      ...obj,
      location: event.target.value
    }));
  };

  const onChangeDateFrom = (newValue) => {
    setDCconfirmDetailObj((obj) => ({
      ...obj,
      dateFrom: newValue.$d
    }));
  };

  const onChangeDateTo = (newValue) => {
    setDCconfirmDetailObj((obj) => ({
      ...obj,
      dateTo: newValue.$d
    }));
  };

  return (
    <div className="container PurchaseReturn">
      <div
      // className="my-2"
      >
        <DCConfirmationBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="mb-3"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.6} sm={7} md={8}>
                <CmpSelectField
                  xLabelText="Location"
                  xValue={sDCconfirmDetailObj.location}
                  xOnChange={(event) => onChangeLocation(event)}
                  xName={'Location'}
                  xData={getDCLoc}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'1'}
                  xref={scanFocusOnLoad}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.6} sm={7} md={8}>
                <CmpDatePickerField
                  xValue={sDCconfirmDetailObj.dateFrom}
                  xOnChange={(newValue) => onChangeDateFrom(newValue)}
                  xName={'dateFrom'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={3.5}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.6} sm={7} md={8}>
                <CmpDatePickerField
                  xValue={sDCconfirmDetailObj.dateTo}
                  xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={0.6} className="text-end">
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fOnpressLoad}
              xLabel={'Load'}
              xIcon={<Refresh />}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '65vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent: sConfirmationList.length == 0 ? 'center' : 'start'
          }}>
          {sConfirmationList.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : (
            sConfirmationList.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={itm.A_Vendor}
                  onClick={() => fOnpressItem(itm)}>
                  <DCConfirmationSumarryCard
                    xTransId={itm.A_TransId}
                    xTruck={itm.TruckName}
                    xLocation={itm.LocName}
                    xDispatched_Date={moment(itm.A_DispatchDt).format('DD/MM/YYYY')}
                    xStatus={itm.Status}
                    xNoItems={itm.ItemCount}
                    xTransDate={moment(itm.A_TransDt).format('DD/MM/YYYY')}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
export default DCConfirmationSummary;
