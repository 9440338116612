import { fExecuteAPI } from '../../Server/Apis';

export const getVanTransSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_VAN_TRANS_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};

export const getVanTrasactionItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_VAN_TRANS_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const getVanReceivedItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_VAN_RECEIVED_ITEMS', xObj);
    return result.data;
  } catch (error) {}
};

export const saveVanReceivedItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_SAVE_RECEIVED_ITEMS', xObj);
    return result;
  } catch (error) {}
};

export const deleteVanReceivedItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_DELETE_RECEIVED_ITEMS', xObj);
    return result;
  } catch (error) {}
};

export const postVanReceivingTrans = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_POST_VAN_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const reverseVanReceivingTrans = async (xObj) => {
  try {
    const result = await fExecuteAPI('FP_REVERSE_VAN_TRANS', xObj);
    return result;
  } catch (error) {}
};
