import React, { useEffect, useState, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpButton,
  MUIDataGrid,
  CmpSelectArrField,
  CmpSelectField,
  CmpInputField,
  CmpStatusDiv
} from '../../component/ToolBox/ToolBox';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getReturnReviewBatchItem, getVendorList, updateReturnReviewBatchItems } from './actions';
import BatchItemEditableGrid from './ProcessComponent/BatchItemEditableGrid';
import ReviewBatchBreadCrumbs from './ReviewBatchBreadCrumbs';
import ReturnReviewBatchBreadCrumbTest from './ProcessComponent/ReturnReviewBatchBreadCrumb';
import { confirmAlert } from 'react-confirm-alert';
//import ReturnReviewBatchBreadCrumbTest from './ProcessComponent/ReturnReviewBatchBreadCrumb';

export default function ReturnReviewBatchItem() {
  const rdxItemHead = useSelector((state) => state.ReturnReviewBatchSlice.value.itemHead);
  const rdxBatchDetail = useSelector((state) => state.ReturnReviewBatchSlice.value.detail);
  const [sItemList, setItemList] = useState([]);
  const [sVendorList, setVendorList] = useState([]);
  const typeData = [
    'PR',
    //'DC',
    '--'
  ];

  const [sHeadType, setHeadType] = useState('--');
  const [sHeadVendor, setHeadVendor] = useState('');
  const [sHeadCost, setHeadCost] = useState(0);
  const [sGridEditing, setGridEditing] = useState(false);

  const loadItems = useCallback(async () => {
    const itemListObj = {
      v_BatchId: rdxItemHead.batchId,
      v_ItmCd: rdxItemHead.itemCd
    };
    const result = await getReturnReviewBatchItem(itemListObj);
    const obj = result.data.ResultSet;
    const rowObj = obj.map((i) => {
      return { ...i, id: i.A_TransId + '-' + i.B_SrNo };
    });
    setItemList(rowObj);
  }, [rdxItemHead]);

  const getVendors = useCallback(async () => {
    const vVendor = await getVendorList({});
    console.log(JSON.stringify(vVendor));
    setVendorList(vVendor.data.ResultSet);
  }, [setVendorList]);

  useEffect(() => {
    loadItems();
    getVendors();
  }, [loadItems, getVendors]);

  const onChangeVendor = (event) => {
    setHeadVendor(event.target.value);
    const obj = sItemList.map((i) => {
      return { ...i, B_Vendor: event.target.value };
    });
    setItemList(obj);
  };

  const onChangeTyp = (event) => {
    setHeadType(event.target.value);
    const obj = sItemList.map((i) => {
      return { ...i, B_Type: event.target.value };
    });
    setItemList(obj);
  };

  const onChangeCost = (event) => {
    const cost = isNaN(event.target.value) ? 0 : event.target.value;
    setHeadCost(cost);
    const obj = sItemList.map((i) => {
      return { ...i, B_VendorCost: cost };
    });
    setItemList(obj);
  };

  const Validation = () => {
    const emptyVendor = sItemList.filter((data) => data.B_Vendor === '');
    if (emptyVendor.length > 0) {
      fAlertToast('FAILED', 'Vendor cannot be empty');
      return false;
    }

    const emptyType = sItemList.filter((data) => data.B_Acc_BaseQty !== 0 && data.B_Type === '--');
    if (emptyType.length > 0) {
      fAlertToast('FAILED', 'Please select the type PR/DC for accepted items');
      return false;
    }

    const accptedMore = sItemList.filter((data) => data.B_Acc_BaseQty > data.B_BaseQty);
    if (accptedMore.length > 0) {
      fAlertToast('FAILED', 'Accepted Quantity is more than requested');
      return false;
    }

    return true;
  };

  const onPressSave = async () => {
    const updateList = sItemList.map((i) => ({
      v_B_TrnsId: i.A_TransId,
      v_B_SrNo: i.B_SrNo,
      v_B_Type: i.B_Type,
      v_B_Acc_BaseQty: i.B_Acc_BaseQty,
      v_B_DC_LPCost: i.B_DC_LPCost,
      v_B_Vendor: i.B_Vendor,
      v_B_VendorCost: i.B_VendorCost
    }));

    if (Validation()) {
      const updateObj = {
        TransList: updateList
      };

      const accptedzero = sItemList.filter((data) => data.B_Acc_BaseQty === 0);
      console.log('accptedzero' + accptedzero.length);
      if (accptedzero.length > 0) {
        confirmAlert({
          title: 'Save',
          message: 'Accepted quantity is 0 for some items, Are you sure to save?',
          buttons: [
            {
              className: 'alertBtn',
              label: 'Yes',
              onClick: async () => {
                let result = await updateReturnReviewBatchItems(updateObj);
                if (result.status === 200) {
                  fAlertToast('SUCCESS', 'Saved Successfully');
                }
              }
            },
            {
              className: 'alertBtn',
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      } else {
        const result = await updateReturnReviewBatchItems(updateObj);
        if (result.status === 200) {
          fAlertToast('SUCCESS', 'Saved Successfully');
        }
      }
    }
  };

  return (
    <div className="container ReviewBatch">
      <div>
        <ToastContainer />
      </div>
      <div>
        <ReviewBatchBreadCrumbs />
        {/* <ReturnReviewBatchBreadCrumbTest /> */}
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={2} lg={1} md={2}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => onPressSave()}
              xLabel={'Save'}
              xDisable={rdxBatchDetail.A_Status !== 'Enter' || sGridEditing}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Batch Id'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={rdxItemHead.batchId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Item'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={rdxItemHead.itemName} />
              </Grid>
            </Grid>
          </Grid>
          {rdxBatchDetail.A_Status === 'Enter' && (
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{ alignItems: 'center', paddingTop: 2 }}>
                <Grid item xs={2} sm={2} lg={3} md={3}>
                  <Grid
                    container
                    //spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Type'} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                      <CmpSelectArrField
                        xLabelText="Type"
                        xValue={sHeadType}
                        xOnChange={(event) => onChangeTyp(event)}
                        xName={'Type'}
                        xData={typeData}
                        xValueMember={''}
                        xDispalyMember={''}
                        xError={false}
                        xErrorMessage={false}
                        xDisable={false}
                        xTabIndex={'4'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={8} lg={6} md={6}>
                  <Grid
                    container
                    //spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} sm={12} lg={2} md={2}>
                      <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={8} md={8}>
                      <CmpSelectField
                        xTabIndex="5"
                        xLabelText="Vendor"
                        xValue={sHeadVendor}
                        xOnChange={(event) => onChangeVendor(event)}
                        xName={'Vendor'}
                        xData={sVendorList}
                        xValueMember={''}
                        xDispalyMember={''}
                        xError={false}
                        xErrorMessage={false}
                        xDisable={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2} lg={3} md={3}>
                  <Grid
                    container
                    //spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Cost'} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={8} md={8}>
                      <CmpInputField
                        xValue={sHeadCost}
                        xType={'text'} // text/number/password
                        xName={'Cost'}
                        xReadOnly={false}
                        xOnChange={(event) => onChangeCost(event)}
                        xError={false}
                        xErrorMessage={''}
                        xOnKeyUp={''}
                        xTextAlign={'right'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mt-3">
          <Grid item xs={12} sm={12} lg={12} md={12}>
            {/* <div className="scrol-overflow1"> */}
            {sItemList.length > 0 && (
              <BatchItemEditableGrid
                rowsData={sItemList}
                setUpdateRows={setItemList}
                editable={rdxBatchDetail.A_Status === 'Enter'}
                vendorList={sVendorList}
                typeData={typeData}
                setGridEditing={setGridEditing}
              />
            )}
            {/* </div> */}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          className="mt-2"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={5} sm={5} md={1}>
            <CmpStatusDiv xStatus={rdxBatchDetail.A_Status} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
