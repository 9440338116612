import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { CmpButton, CmpCheckboxField, CmpDatePickerField, CmpInput, CmpInputField, CmpInputFieldGroup, CmpMultiSelectAll, CmpNumberPicker, CmpRadioField, CmpSelectField, CmpTypographyField } from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDaysList, getDepartmentList, getFPRequestItemList, getVoucherList } from './actions';
import { DataGrid } from '@mui/x-data-grid';

export default function PurchaseRequestDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locList = useSelector((state) => state.SliceDB.value.getLocation);
    const rdxHeadObj = useSelector((state) => state.OutletRequestSlice.value.requestHead);
    const rdxuser = useSelector((state) => state.SliceDB.value.login);

    const [sItemList, setItemList] = useState([]);
    const [sVoucherList, setVoucherList] = useState([]);
    const [sDeptList, setDeptList] = useState([]);
    const [sDaysList, setDaysList] = useState([]);

    const [sHeadObj, setHeadObj] = useState({
        vocTyp: '',
        vocNo: '',
        vocDt: new Date(),
        loc: '',
        dept: '',
        CNSType: '',
        CNSDt1: new Date(),
        CNSDt2: new Date(),
        CNSDays: 0,
        CNSRcndDays: 0,
        CNSRcndDay: '',
        docStat: 'ENTER',
        leadDays: 1,
        DC_Purchase: 'Y'
    });
    const [sDeatilObj, setDetailObj] = useState({
        srNo: 0,
        itmCd: '',
        itemName: '',
        cost: 0,
        RSP: 0,
        GP: 0,
        stkQty: 0,
        orderQty: 0,
        salQty: 0,
        avgSalQty: 0,
        maxShelfQty: 0,
        minShelfQty: 0,
        reqQty: 0,
        finalQty: ''
    });


    // const fLoadItems = useCallback(async (xHeadObj) => {
    //     if (xHeadObj.vocNo !== '') {
    //         const obj = {
    //             vocType: xHeadObj.vocTyp,
    //             vocNo: xHeadObj.vocNo
    //         };
    //         const result = await getFPRequestItemList(obj);
    //         console.log(result);
    //         result ? setItemList(result.ResultSet) : setItemList([]);
    //     }
    //     else {
    //         setItemList([]);
    //     }
    // }, []);

    const fLoadItems = async (xHeadObj) => {
        if (xHeadObj.vocNo !== '') {
            const obj = {
                vocType: xHeadObj.vocTyp,
                vocNo: xHeadObj.vocNo
            };
            const result = await getFPRequestItemList(obj);
            console.log(result);
            result ? setItemList(result.ResultSet) : setItemList([]);
        }
        else {
            setItemList([]);
        }
    };

    const onPageLoad = useCallback(async () => {
        setHeadObj(rdxHeadObj);
        await fLoadItems(rdxHeadObj);
    }, [rdxHeadObj]);

    const fLoadVoucher = useCallback(async () => {
        const obj = {
            userGrp: rdxuser.userGroup,
            userCd: rdxuser.userId,
            mgrp: '05'
        }
        const result = await getVoucherList(obj);
        setVoucherList(result?.ResultSet);

    }, [rdxuser]);

    const fLoadDepts = useCallback(async () => {

        const result = await getDepartmentList();
        setDeptList(result?.ResultSet);

    }, []);

    const fLoadDaysList = useCallback(async () => {

        const result = await getDaysList();
        setDaysList(result?.ResultSet);

    }, []);


    useEffect(() => {
        fLoadVoucher();
        fLoadDepts();
        fLoadDaysList();
        onPageLoad();
    }, [fLoadVoucher, fLoadDepts, fLoadDaysList, onPageLoad]);

    const gridColumns = [
        {
            field: 'SrNo',
            headerName: 'Sr No.',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'ItmCd',
            headerName: 'Item Cd',
            flex: 2,
        },
        {
            field: 'ItemName',
            headerName: 'Item Name',
            flex: 3,
        },
        {
            field: 'PLU',
            headerName: 'PLU',
            flex: 2,
        },
        {
            field: 'MainGrpName',
            headerName: 'Main Group',
            flex: 3,
            hide: true
        },
        {
            field: 'Cost',
            headerName: 'Cost',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'RSP',
            headerName: 'RSP',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'GP',
            headerName: 'GP',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'OrderQty',
            headerName: 'Order',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'SalQty',
            headerName: 'Sales',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'AvgSalQty',
            headerName: 'Avg.Sales',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'MaxShelfQty',
            headerName: 'Max.Shelf Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en'),
            hide: true
        },
        {
            field: 'MinShelfQty',
            headerName: 'Min. Shelf Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en'),
            hide: true
        },
        {
            field: 'ReqQty',
            headerName: 'Required',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'FinalQty',
            headerName: 'Final Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },

    ];


    return (
        <div className='container'>
            <div >
                {/* <PEBreadcrumbs /> */}
            </div>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mb-3'>
                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            // xonClick={fNewDetail}
                            xLabel={'New'}
                        //xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            // xonClick={fSave}
                            xLabel={'Save'}
                        //xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid>

                    {/* <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            //   xonClick={fPostPurcchaseTrans}
                            xLabel={'Post'}
                        // xDisable={}
                        />
                    </Grid> */}

                </Grid>
                <Grid
                    container
                    spacing={1}
                    columnSpacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}
                    className='mb-3'>

                    <Grid item xs={12} sm={12} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>

                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Doc.Type'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <CmpSelectField
                                            xTabIndex="1"
                                            xLabelText="Doctype"
                                            xValue={sHeadObj.vocTyp}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, vocTyp: event.target.value }) }}
                                            xName={'Doctype'}
                                            xData={sVoucherList}
                                            xValueMember={''}
                                            xDispalyMember={''}
                                            // xError={sErrorMsg.locCd !== ''}
                                            // xErrorMessage={sErrorMsg.locCd}
                                            xDisable={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={4}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Doc.No.'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={6}>
                                        <CmpInput
                                            xType={'text'} // text/number/password
                                            xName={'Doc no'}
                                            xReadOnly={true}
                                            xTabIndex={111}
                                            xValue={sHeadObj.vocNo}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, vocNo: event.target.value }) }}
                                            xError={false}
                                            xErrorMessage={''}
                                            xOnKeyUp={''}
                                            xTextAlign='left'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <CmpDatePickerField
                                            xTabIndex="2"
                                            xValue={sHeadObj.vocDt}
                                            xOnChange={(newValue) => setHeadObj({ ...sHeadObj, vocDt: newValue.$d })}
                                            xName={'Date'}
                                            xError={false}
                                            xErrorMessage={''}
                                            xOnKeyUp={''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={4}>
                                        <CmpTypographyField xcomponent={'span'} xText={'No. of Days'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={6}>
                                        <CmpNumberPicker
                                            xType={'number'} // text/number/password
                                            xName={'days'}
                                            xReadOnly={false}
                                            xTabIndex={3}
                                            xValue={sHeadObj.leadDays}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, leadDays: event.target.value }) }}
                                            xError={false}
                                            xErrorMessage={''}
                                            xOnKeyUp={''}
                                            xTextAlign='center'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <CmpSelectField
                                            xTabIndex="4"
                                            xLabelText="Location"
                                            xValue={sHeadObj.loc}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, loc: event.target.value }) }}
                                            xName={'Location'}
                                            xData={locList}
                                            xValueMember={''}
                                            xDispalyMember={''}
                                            // xError={sErrorMsg.locCd !== ''}
                                            // xErrorMessage={sErrorMsg.locCd}
                                            xDisable={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                >
                                    <Grid item xs={4} sm={4} md={4}>
                                        <CmpTypographyField xcomponent={'span'} xText={'DC Purchase'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpCheckboxField xChecked={sHeadObj.DC_Purchase === 'Y'} xOnChange={() => setHeadObj({ ...sHeadObj, DC_Purchase: sHeadObj.DC_Purchase === 'Y' ? 'N' : 'Y' })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={2} md={1.5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Department'} />
                                    </Grid>
                                    <Grid item xs={1} sm={.5} md={.5}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <CmpSelectField
                                            xTabIndex="5"
                                            xLabelText="Department"
                                            xValue={sHeadObj.dept}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, dept: event.target.value }) }}
                                            xName={'Department'}
                                            xData={sDeptList}
                                            xValueMember={''}
                                            xDispalyMember={''}
                                            // xError={sErrorMsg.locCd !== ''}
                                            // xErrorMessage={sErrorMsg.locCd}
                                            xDisable={false}
                                        />
                                        {/* <CmpMultiSelectAll
                                            xLabelText="Department"
                                            xValue={sHeadObj.dept}
                                            xOnChange={(event) => onChangeDeptList(event)}
                                            xName={'Department'}
                                            xData={[]}
                                            xError={false}
                                            xErrorMessage={false}
                                            xDisable={false}
                                            xTabIndex={'5'}
                                        /> */}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={10} md={5} className='mt-2'>
                        <Grid
                            container
                            spacing={0}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            className='p-1'
                            sx={{ alignItems: 'center', border: '1px solid lightGray' }}>
                            <Grid item xs={12} className='mb-2'>
                                <CmpTypographyField xcomponent={'span'} xText={'Consumption Details'} />
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={1.5} >
                                        <CmpRadioField
                                            xName={'CNSType1'}
                                            xChecked={Number(sHeadObj.CNSType) === 1}
                                            xOnChange={() => setHeadObj({ ...sHeadObj, CNSType: 1 })}
                                            xNameField={""}
                                            xTabIndex={6}
                                            xValue={'CNSType1'}
                                        />
                                    </Grid>

                                    <Grid item xs={10}>
                                        <Grid
                                            container
                                            columnSpacing={2}
                                            columns={{ xs: 12, sm: 12, md: 12 }}
                                            sx={{ alignItems: 'center' }}>
                                            <Grid item xs={2}><CmpTypographyField xcomponent={'span'} xText={'From'} /></Grid>
                                            <Grid item xs={4.5}>
                                                <CmpDatePickerField
                                                    xTabIndex="7"
                                                    xValue={sHeadObj.CNSDt1}
                                                    xOnChange={(newValue) => setHeadObj({ ...sHeadObj, CNSDt1: newValue.$d })}
                                                    xName={'Date'}
                                                    xError={false}
                                                    xErrorMessage={''}
                                                    xOnKeyUp={''}
                                                    xDisabled={Number(sHeadObj.CNSType) !== 1}
                                                />
                                            </Grid>
                                            <Grid item xs={1}><CmpTypographyField xcomponent={'span'} xText={'To'} /></Grid>
                                            <Grid item xs={4.5}>
                                                <CmpDatePickerField
                                                    xTabIndex="8"
                                                    xValue={sHeadObj.CNSDt2}
                                                    xOnChange={(newValue) => setHeadObj((obj) => ({ ...sHeadObj, CNSDt2: newValue.$d }))}
                                                    xName={'To Date'}
                                                    xError={false}
                                                    xErrorMessage={''}
                                                    xOnKeyUp={''}
                                                    xDisabled={Number(sHeadObj.CNSType) !== 1}
                                                />
                                            </Grid>
                                            <Grid />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={1.5} >
                                        <CmpRadioField
                                            xName={'CNSType2'}
                                            xChecked={Number(sHeadObj.CNSType) === 2}
                                            xOnChange={() => setHeadObj({ ...sHeadObj, CNSType: 2 })}
                                            xNameField={""}
                                            xTabIndex={9}
                                            xValue={'CNSType2'}
                                        />
                                    </Grid>

                                    <Grid item xs={10}>
                                        <Grid
                                            container
                                            columnSpacing={2}
                                            columns={{ xs: 12, sm: 12, md: 12 }}
                                            sx={{ alignItems: 'center' }}>
                                            <Grid item xs={4}>
                                                <CmpInput
                                                    xType={'number'} // text/number/password
                                                    xName={'Days'}
                                                    xReadOnly={Number(sHeadObj.CNSType) !== 2}
                                                    xTabIndex={10}
                                                    xValue={sHeadObj.CNSDays}
                                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, CNSDays: event.target.value }) }}
                                                    xError={false}
                                                    xErrorMessage={''}
                                                    xOnKeyUp={''}
                                                    xTextAlign='center'

                                                />
                                            </Grid>
                                            <Grid item xs={3}><CmpTypographyField xcomponent={'span'} xText={'Days'} /></Grid>

                                            <Grid />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={1.5} >
                                        <CmpRadioField
                                            xName={'CNSType3'}
                                            xChecked={Number(sHeadObj.CNSType) === 3}
                                            xOnChange={() => setHeadObj({ ...sHeadObj, CNSType: 3 })}
                                            xNameField={""}
                                            xTabIndex={11}
                                            xValue={'CNSType3'}
                                        />
                                    </Grid>

                                    <Grid item xs={10}>
                                        <Grid
                                            container
                                            columnSpacing={2}
                                            columns={{ xs: 12, sm: 12, md: 12 }}
                                            sx={{ alignItems: 'center' }}>
                                            <Grid item xs={2.5}><CmpTypographyField xcomponent={'span'} xText={'Recent'} /></Grid>
                                            <Grid item xs={3}>
                                                <CmpInput
                                                    xType={'number'} // text/number/password
                                                    xName={'RecentDays'}
                                                    xReadOnly={Number(sHeadObj.CNSType) !== 3}
                                                    xTabIndex={12}
                                                    xValue={sHeadObj.CNSRcndDays}
                                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, CNSRcndDays: event.target.value }) }}
                                                    xError={false}
                                                    xErrorMessage={''}
                                                    xOnKeyUp={''}
                                                    xTextAlign='center'
                                                />
                                            </Grid>
                                            <Grid item xs={6.5}>
                                                <CmpSelectField
                                                    xTabIndex="14"
                                                    xLabelText="RecentDay"
                                                    xValue={sHeadObj.CNSRcndDay}
                                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, CNSRcndDay: event.target.value }) }}
                                                    xName={'day'}
                                                    xData={sDaysList}
                                                    xValueMember={''}
                                                    xDispalyMember={''}
                                                    // xError={sErrorMsg.locCd !== ''}
                                                    // xErrorMessage={sErrorMsg.locCd}
                                                    xDisable={Number(sHeadObj.CNSType) !== 3}
                                                />
                                            </Grid>

                                            <Grid />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}>
                        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} alignItems='center'>
                            <Grid item xs={3} sm={12} md={12}>
                                <CmpButton
                                    xvariant={'contained'}
                                    xsize={'small'}
                                    // xonClick={fNewDetail}
                                    xLabel={'Generate'}
                                //xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                                />
                            </Grid>
                            <Grid item xs={3} sm={12} md={12}>
                                <CmpButton
                                    xvariant={'contained'}
                                    xsize={'small'}
                                    // xonClick={fSave}
                                    xLabel={'Release '}
                                //xDisable={sHeadObj.docStat.toString().toUpperCase() !== 'ENTER'}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 0, sm: 0, md: 0.2 }}
                    columns={{ xs: 12, sm: 12, md: 16 }}
                    className={'mt-3'}>
                    <Grid item xs={1} sm={1} md={1}>
                        <div className="d-flex justify-content-between">
                            <CmpTypographyField xcomponent={'div'} xText={'Sr.No'} />
                        </div>
                        <CmpInputField
                            xValue={sDeatilObj.srNo}
                            xType={'text'} // text/number/password
                            xName={'srNo'}
                            xReadOnly={true}
                            xOnChange={() => { }}
                            //xError={sErrorMsg.B_Srno === '' ? false : true}
                            // xErrorMessage={sErrorMsg.B_Srno}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                        <CmpTypographyField xcomponent={'div'} xText={'Item Code'} />
                        <CmpInputField
                            xValue={sDeatilObj.itmCd}
                            xType={'text'} // text/number/password
                            xName={'B_Itmcd'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            // xError={sErrorMsg.B_Itmcd === '' ? false : true}
                            // xErrorMessage={sErrorMsg.B_Itmcd}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={7} sm={6} md={5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Item Name'} />
                        <CmpInputFieldGroup
                            xTabIndex="1001"
                            xValue={sDeatilObj?.itemName}
                            xType={'text'} // text/number/password
                            xName={'itemName'}
                            xReadOnly={false}
                            xOnChange={(event) => setDetailObj({ ...sDeatilObj, itemName: event.target.value })}
                        //    xOnKeyUp={fOpenInvoiceSalesModal}
                        // xError={sErrorMsg.B_ItmName === '' ? false : true}
                        // xErrorMessage={sErrorMsg.B_ItmName}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Cost'} />
                        <CmpInputField
                            xTabIndex="1002"
                            xValue={Number(sDeatilObj.cost)}
                            xType={'number'} // text/number/password
                            xName={'cost'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'RSP'} />
                        <CmpInputField
                            xTabIndex="1003"
                            xValue={Number(sDeatilObj.RSP)}
                            xType={'number'} // text/number/password
                            xName={'RSP'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'GP'} />
                        <CmpInputField
                            xTabIndex="1004"
                            xValue={Number(sDeatilObj.GP)}
                            xType={'number'} // text/number/password
                            xName={'GP'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Curr. Stk'} />
                        <CmpInputField
                            xTabIndex="1005"
                            xValue={Number(sDeatilObj.stkQty)}
                            xType={'number'} // text/number/password
                            xName={'stkQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'orderQty'} />
                        <CmpInputField
                            xTabIndex="1006"
                            xValue={Number(sDeatilObj.orderQty)}
                            xType={'number'} // text/number/password
                            xName={'orderQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Sales Qty'} />
                        <CmpInputField
                            xTabIndex="1007"
                            xValue={Number(sDeatilObj.salQty)}
                            xType={'number'} // text/number/password
                            xName={'salQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Avg. Sales Qty'} />
                        <CmpInputField
                            xTabIndex="1008"
                            xValue={Number(sDeatilObj.avgSalQty)}
                            xType={'number'} // text/number/password
                            xName={'avgSalQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Max Shelf Qty'} />
                        <CmpInputField
                            xTabIndex="1009"
                            xValue={Number(sDeatilObj.maxShelfQty)}
                            xType={'number'} // text/number/password
                            xName={'maxShelfQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Min Shelf Qty'} />
                        <CmpInputField
                            xTabIndex="1010"
                            xValue={Number(sDeatilObj.minShelfQty)}
                            xType={'number'} // text/number/password
                            xName={'minShelfQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Required Qty'} />
                        <CmpInputField
                            xTabIndex="1008"
                            xValue={Number(sDeatilObj.reqQty)}
                            xType={'number'} // text/number/password
                            xName={'reqQty'}
                            xReadOnly={true}
                            xOnChange={(event) => { }}
                            xOnKeyUp={''}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                        <CmpTypographyField xcomponent={'div'} xText={'Final Qty'} />
                        <CmpInputField
                            xTabIndex="1008"
                            xValue={Number(sDeatilObj.finalQty)}
                            xType={'number'} // text/number/password
                            xName={'reqQty'}
                            xReadOnly={false}
                            xOnChange={(event) => { setDetailObj({ ...sDeatilObj, finalQty: Number(event.target.value) }) }}
                            // xError={sErrorMsg.B_Qty === '' ? false : true}
                            // xErrorMessage={sErrorMsg.B_Qty}
                            xOnKeyUp={''}
                        />
                    </Grid>

                </Grid>

                <Box sx={{ height: '45vh', width: '100%' }} className="mt-4">
                    <DataGrid
                        rowHeight={50}
                        headerHeight={50}
                        getRowId={(row) => row.SrNo}
                        sx={{
                            border: 2,
                            borderColor: 'primary.light'
                        }}
                        componentsProps={{
                            filterPanel: { sx: { maxWidth: "93vw" } }
                          }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                        }
                        rows={sItemList}
                        columns={gridColumns}
                    //onRowClick={(params) => fOnRowClick(params)}

                    />
                </Box>
            </Box>
        </div>
    )
}
