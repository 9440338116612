import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Checkbox } from '@mui/material';

import { CmpTypographyField, StatusIcon } from '../../../component/ToolBox/ToolBox';

export default function BatchSummaryCard({
  xBatchId,
  xEnDt,
  xStatus,
  Departments,
  LocNames,
  DCLocName,
  RequestIDs,
  item
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={11} sm={11} md={11}>
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={'Batch ID'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={xBatchId} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'Created at'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={xEnDt} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'Departments'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={Departments} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={LocNames} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'DCLocation'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={DCLocName} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'RequestID'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={RequestIDs} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Grid
                container
                spacing={0}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={3} sm={5} md={3}>
                  <CmpTypographyField xcomponent={'span'} xText={'Type'} />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={6} md={8} className="txt-overflow-ellipse">
                  <CmpTypographyField xcomponent={'span'} xText={item?.Type} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'end' }}>
          <StatusIcon xStatus={xStatus} />
        </Grid>
      </Grid>
    </Box>
  );
}
