import React from 'react';
import {  useLocation } from 'react-router-dom';

function ReviewBatchCreateBreadCrumbs() {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 mt-0 mb-4 breadcrumb">
            {vPathName === '/ReviewBatchCreation' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Review Batch Creation </span>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
export default ReviewBatchCreateBreadCrumbs;
