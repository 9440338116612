import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './PurchaseReturn.css';
import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpButton,
  CmpInputField,
  CmpSelectArrField,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import { Refresh } from '@mui/icons-material';
import PurchaseReturnSumarryCard from './processComponent/PurchaseReturnSumarryCard';
import PurchaseReturnBreadCrumbs from './PurchaseReturnBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { PurchaseReturn_Detail, PurchaseReturn_Head } from './PurchaseReturnSlice';
import { getPurchaseReturnSummaryList } from './action';

function PurchaseReturnSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDCLoc = useSelector((state) => state.SliceDB.value.getDCLocation);
  const rdxHeadobj = useSelector((state) => state.PurchaseReturnSlice.value.head);

  const statusList = ['All', 'Enter', 'Post'];
  const scanFocusOnLoad = useRef(null);

  const [sSearch, setSearch] = useState('');
  const [sSearchList, setSearchList] = useState('');

  // const [sPurchaseList, setPurchaseList] = useState([
  //   {
  //     A_BatchId: '',
  //     A_PostDt: '',
  //     A_Vendor: '',
  //     Vendorname: '',
  //     A_DCLoc: '',
  //     DCLocName: '',
  //     A_Status: '',
  //     A_PR_PostDt: new Date(),
  //     A_PR_DocNo: '',
  //     DispatchCount: 0,
  //     DispatchValue: 0,
  //     A_Endt: new Date(),
  //   }
  // ]);

  const [sPurchaseList, setPurchaseList] = useState([]);

  const fOnPageLoad = useCallback(async () => {
    let currObj = {
      location: rdxHeadobj.location == '' ? getDCLoc[0]?.UId : rdxHeadobj?.location,
      dateFrom: rdxHeadobj.dateFrom,
      dateTo: rdxHeadobj.dateTo,
      status: rdxHeadobj.status
    };
    dispatch(PurchaseReturn_Head(currObj));
    await fGetPurchaseReturnSummaryList(currObj);
  }, [getDCLoc]);

  useEffect(() => {
    scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  const fGetPurchaseReturnSummaryList = async () => {
    const purchaseReturnSummaryObj = {
      v_Loc: rdxHeadobj.location,
      v_Date1: moment(rdxHeadobj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(rdxHeadobj.dateTo).format('MM/DD/YYYY'),
      v_Status: rdxHeadobj.status
    };

    const result = await getPurchaseReturnSummaryList(purchaseReturnSummaryObj);
    console.log(JSON.stringify(result));

    if (result.data.ResultSet.length > 0) {
      setPurchaseList(result.data.ResultSet);
    } else {
      setPurchaseList([]);
    }
  };

  const fOnpressLoad = async () => {
    scanFocusOnLoad.current.focus();
    if (rdxHeadobj.dateFrom < rdxHeadobj.dateTo) {
      await fGetPurchaseReturnSummaryList(rdxHeadobj);

      dispatch(PurchaseReturn_Head(rdxHeadobj));
    } else {
      fAlertToast('FAILED', 'To Date cannot be less then from date');
    }
  };

  const fOnpressItem = (item) => {
    console.log('kkkkkkkkkkkkk' + JSON.stringify(item));
    dispatch(
      PurchaseReturn_Detail({
        A_BatchId: item.A_BatchId,
        A_PostDt: item.A_PostDt,
        A_Vendor: item.A_Vendor,
        Vendorname: item.Vendorname,
        A_DCLoc: item.DCLoc,
        DCLocName: item.DCLocName,
        A_Status: item.A_Status,
        A_PR_PostDt: item.A_PR_PostDt,
        A_PR_DocNo: item.A_PR_DocNo,
        DispatchCount: item.DispatchCount,
        DispatchValue: item.DispatchValue,
        A_Taxable: item.A_Taxable,
        VANTransIDs: item.VANTransIDs,
        REQTransIDs: item.REQTransIDs
      })
    );

    navigate('/BatchDetail');
  };

  const onChangeLocation = (event) => {
    dispatch(
      PurchaseReturn_Head({
        ...rdxHeadobj,
        location: event.target.value
      })
    );
  };

  const onChangeDateFrom = (newValue) => {
    dispatch(
      PurchaseReturn_Head({
        ...rdxHeadobj,
        dateFrom: newValue.$d
      })
    );
  };

  const onChangeDateTo = (newValue) => {
    dispatch(
      PurchaseReturn_Head({
        ...rdxHeadobj,
        dateTo: newValue.$d
      })
    );
  };

  const onchangeStatus = (event) => {
    dispatch(
      PurchaseReturn_Head({
        ...rdxHeadobj,
        status: event.target.value
      })
    );
  };

  const onSearchKey = () => {
    if (sSearch.length > 1) {
      const searchArr = sPurchaseList.filter((data) =>
        data.Vendorname.toString().toLowerCase().includes(sSearch.toLowerCase())
      );
      console.log(searchArr);
      setSearchList(searchArr);
    } else {
      setSearchList(sPurchaseList);
    }
  };

  return (
    <div className="container PurchaseReturn">
      <div
      // className="my-2"
      >
        <PurchaseReturnBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpSelectField
                  xLabelText="Location"
                  xValue={rdxHeadobj.location}
                  xOnChange={(event) => onChangeLocation(event)}
                  xName={'Location'}
                  xData={getDCLoc}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'1'}
                  xref={scanFocusOnLoad}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpDatePickerField
                  xValue={rdxHeadobj.dateFrom}
                  xOnChange={(newValue) => onChangeDateFrom(newValue)}
                  xName={'dateFrom'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpDatePickerField
                  xValue={rdxHeadobj.dateTo}
                  xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.7} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={0.4} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.9} sm={8} md={7}>
                <CmpSelectArrField
                  xLabelText="Status"
                  xValue={rdxHeadobj.status}
                  xOnChange={(event) => onchangeStatus(event)}
                  xName={'Status'}
                  xData={statusList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'4'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={10} md={6}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              className="d-flex justify-content-between">
              <Grid item xs={12} sm={12} md={12}>
                <CmpInputField
                  xTextAlign="left"
                  xValue={sSearch}
                  xType={'text'} // text/number/password
                  xName={'ConfirmQty'}
                  xReadOnly={false}
                  xOnChange={(event) => {
                    setSearch(event.target.value);
                    onSearchKey();
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTabIndex={'4'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: 'end' }}>
              <Grid item xs={10} sm={8} md={6}>
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '55vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent:
              sPurchaseList.length == 0
                ? 'center'
                : sSearch.length == 0
                ? 'start'
                : sSearchList.length == 0
                ? 'center'
                : 'start'
          }}>
          {sPurchaseList.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : // (
          //   sPurchaseList.map((itm) => {
          //     return (
          //       <Grid
          //         item
          //         xs={12}
          //         sm={6}
          //         md={6}
          //         lg={6}
          //         key={itm.A_Vendor}
          //         onClick={() => fOnpressItem(itm)}>
          //         <PurchaseReturnSumarryCard
          //           xVendor={itm.Vendorname}
          //           xNo_Dispatch={itm.DispatchCount}
          //           xValue_Dispatched={itm.DispatchValue}
          //           xDCLocation={itm.DCLocName}
          //           xDispatched_Date={moment(itm.A_DispatchDt).format('DD/MM/YYYY')}
          //         />
          //       </Grid>
          //     );
          //   })
          // )}
          sSearch.length == 0 ? (
            sPurchaseList.map((itm, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={'PurchaseReturnSummary' + i}
                  onClick={() => fOnpressItem(itm)}>
                  <PurchaseReturnSumarryCard
                    xBatchId={itm.A_BatchId}
                    xBatchDt={moment(itm.A_PostDt).format('DD/MM/YYYY')}
                    xVendor={itm.Vendorname}
                    xStatus={itm.A_Status}
                    xNo_Dispatch={itm.DispatchCount}
                    xValue_Dispatched={itm.DispatchValue}
                    xPRDocNo={itm.A_PR_DocNo}
                    xPrPostDt={moment(itm.A_PR_PostDt).format('DD/MM/YYYY')}
                    xEnDt={moment(itm.A_Endt).utcOffset('+0000').format('DD/MM/YYYY  h:mm:ss a')}
                    item={itm}
                  />
                </Grid>
              );
            })
          ) : sSearchList.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <p style={{ fontSize: '2rem' }}> Nothing found </p>
            </div>
          ) : (
            sSearchList.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={itm.A_BatchId}
                  onClick={() => fOnpressItem(itm)}>
                  <PurchaseReturnSumarryCard
                    xBatchId={itm.A_BatchId}
                    xBatchDt={moment(itm.A_PostDt).format('DD/MM/YYYY')}
                    xVendor={itm.Vendorname}
                    xStatus={itm.A_Status}
                    xNo_Dispatch={itm.DispatchCount}
                    xValue_Dispatched={itm.DispatchValue}
                    xPRDocNo={itm.A_PR_DocNo}
                    xPrPostDt={moment(itm.A_PR_PostDt).format('DD/MM/YYYY')}
                    xEnDt={moment(itm.A_Endt).utcOffset('+0000').format('DD/MM/YYYY  h:mm:ss a')}
                    item={itm}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
export default PurchaseReturnSummary;
