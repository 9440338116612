import React, { useCallback, useEffect, useState } from 'react'

import { Box, Grid, Modal, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import {
    CmpButton, CmpCheckboxField, CmpInputField, CmpSelectField, CmpStatusDiv, CmpTypographyField, CmpDatePickerField, CmpSelectArrField,
} from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';
import moment from 'moment';

import { updateTransHead } from './FishPurchaseSlice';
import { confirmBufferTransaction, getBufferItems, getVendorOSAmt, reverseConfirmedTransaction, reversePurchaseTransaction, savePurchaseTransaction, searchVendor, updateBufferTransaction } from './actions';
import Breadcrumbs from './ProcessComponents/Breadcrumbs';
import PurchasePostModal from './ProcessComponents/PurchasePostModal';
import './FishPurchase.css';
import AddNewItemModal from './ProcessComponents/AddNewItemModal';
import GCDropdownSearch from '../../component/ReuseableComponent/GCDropdownSearch';
import BufferItemsGrid from './ProcessComponents/BufferItemsGrid';

export default function ConfirmFishPurDetail() {

    const dispatch = useDispatch();
    const rdxTransHead = useSelector((state) => state.FishPurchaseSlice.transHead);
    const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);

    const [sHeadObj, setHeadObj] = useState(rdxTransHead);
    const [sItemList, setItemList] = useState([]);
    const [sVendorList, setVendorList] = useState([]);

    const [sTotalObj, setTotalObj] = useState({
        Taxable: 0,
        Tax: 0,
        Total: 0,
        NetAmt: 0
    });
    const [sGridEditing, setGridEditing] = useState(false);
    const [sShowPostModal, setShowPostModal] = useState(false);
    const [sShowAddItemModal, setShowAddItemModal] = useState(false);
    const paymodeList = ['CREDIT', 'CASH'];
    const vMediaQuery = useMediaQuery('(max-width:600px)');

    const loadItems = useCallback(async () => {
        const obj = {
            transId: rdxTransHead.TransId,
        }
        const data = await getBufferItems(obj);
        console.log("api resulttttt" + JSON.stringify(data));

        setItemList(data.ResultSet);

    }, [setItemList, rdxTransHead]);

    const getVendorOSAmount = async (vendor, name, taxable) => {
        const result = await getVendorOSAmt({
            AcCd: vendor,
            Loc: rdxFilterObj.DCList.toString()
        });
        setHeadObj({ ...sHeadObj, Vendor: vendor, VendorName: name, TRNS_Vendor_Os: Number(result.data?.ResultSet[0].OSAmt), TaxPurchase: taxable });
    }

    useEffect(() => {
        loadItems();
    }, [loadItems]);

    useEffect(() => {
        let arr = sItemList;
        let taxable = arr.reduce((prevValue, i) => {
            return prevValue + Number(i.Taxable);
        }, 0);
        let tax = arr.reduce((prevValue, i) => {
            return prevValue + Number(i.TaxAmt) + Number(i.TaxAmt2) + Number(i.Ad_NetCessAmt);
        }, 0);

        setTotalObj({
            Taxable: taxable,
            Tax: tax,
            Total: Number(taxable) + Number(tax),
            // NetAmt: Number(taxable) + Number(tax) + Number(rdxTransHead.Rounding),
            NetAmt: Number(taxable) + Number(tax) + Number(sHeadObj.Rounding),
        })

    }, [sItemList, sHeadObj])


    const xOnChangeVendor = (row) => {

        const taxable = sItemList.filter(i => Number(i.Qty) > 0).length === 0 ? row.Taxable : sHeadObj.TaxPurchase;
        console.log(row, taxable);

        getVendorOSAmount(row.AcCd, row.Name2, taxable);
        // dispatch(updateTransHead({ ...rdxTransHead, Vendor: event.target.value }));
        //setHeadObj({ ...sHeadObj, Vendor: event.target.value });
    }

    const Validation = () => {

        if (sHeadObj.Vendor === '') {
            fAlertToast('FAILED', 'Select Vendor');
            return false;
        }
        if (sHeadObj.Paymode !== 'CASH' && sHeadObj.Paymode !== 'CREDIT') {
            fAlertToast('FAILED', 'Select Paymode');
            return false;
        }
        if (sTotalObj.Total < 0) {
            fAlertToast('FAILED', 'Total amount should not be less than 0');
            return false;
        }
        const negativeQtyList = sItemList.filter(i => i.Qty <= 0);
        if (negativeQtyList.length > 0) {
            fAlertToast('FAILED', 'Purchase quantity can not be 0 or negative ');
            return false;
        }

        const negativeUrateList = sItemList.filter(i => i.UnitRate <= 0);
        if (negativeUrateList.length > 0) {
            fAlertToast('FAILED', 'Unit rate should be greater than 0');
            return false;
        }
        return true;
    };

    const PostValidation = () => {
        console.log(sHeadObj.invoiceAmt, Number(sTotalObj.Total) + Number(sHeadObj.Rounding));
        if (Number(sHeadObj.invoiceAmt).toFixed(2) !== (Number(sTotalObj.Total) + Number(sHeadObj.Rounding)).toFixed(2)) {
            fAlertToast('FAILED', 'Invoice amount not matches with grand total');
            return false;
        }
        return true;
    };


    const fSave = async () => {

        let itemList = sItemList.map(i => {
            return {
                TRNS_SRNO: i.SrNo,
                TRNS_ACT_ITMCD: i.ALTItmCd,
                TRNS_QTY: i.Qty,
                TRN_PACK_QTY: i.PackQty,
                TRNS_BASEQTY: i.BaseQty,
                TRNS_UnitRate: i.UnitRate,
                TRNS_GrossAmt: i.GrossAmt,
                TRNS_LDiscAmt: i.LDiscAmt,
                TRNS_TDiscAmt: i.TDiscAmt,
                TRNS_FocAmt: i.FocAmt,
                TRNS_NetAmt: i.Taxable,
                TRNS_TaxAmt: i.TaxAmt,
                TRNS_TaxAmt2: i.TaxAmt2,
                TRNS_ExciseTaxAmt: i.ExciseTaxAmt,
                TRNS_ExciseTaxNetAmt: i.ExciseTaxNetAmt,
                TRNS_Ad_CessAmt: i.Ad_CessAmt,
                TRNS_Ad_PCQty: i.Ad_PCQty,
                TRNS_Ad_PCRate: i.Ad_PCRate,
                TRNS_Ad_NetCessAmt: i.Ad_NetCessAmt,
                TRNS_LandVal: i.Total,
                VendorPackQty: i.VendorPackQty,
                TRN_PR_QTY: 0,
                ReceivedQty: i.ReceivedQty
            }
        })
        let obj = {
            TransId: sHeadObj.TransId,
            RefNo: sHeadObj.ReferenceNo,
            RefDate: moment(sHeadObj.ReferenceDate).format("MM/DD/YYYY"),
            InvoiceAmt: sHeadObj.invoiceAmt,
            Rounding: sHeadObj.Rounding,
            PartyMobile: sHeadObj.TRNS_PARTY_MOBILE,
            itmlist: itemList,
            Party: sHeadObj.Vendor,
            paymode: sHeadObj.Paymode,
            dept: 'FF',
        }


        const result = await updateBufferTransaction(obj);
        console.log('result>>>>' + JSON.stringify(result));

        if (result.status === 200) {
            return true;
        }
        return false;
    }

    const fPost = async () => {
        let obj = {
            TransId: sHeadObj.TransId
        }

        const result = await confirmBufferTransaction(obj);
        console.log('result>>>>' + JSON.stringify(result));

        if (result.status === 200) {
            fAlertToast('SUCCESS', 'Posted Successfully');
            setHeadObj({ ...sHeadObj, State: 'CONFIRM' })
        }

    }



    const fSavePurcchaseTrans = async () => {
        if (Validation()) {

            confirmAlert({
                title: 'Save',
                message: 'Are you sure to Save?',
                buttons: [
                    {
                        className: 'alertBtn',
                        label: 'Yes',
                        onClick: async () => {
                            let res = await fSave();
                            if (res) {
                                fAlertToast('SUCCESS', 'Saved Successfully');
                            }
                        }

                    },
                    {
                        className: 'alertBtn',
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });

        }
    }


    const fPostPurcchaseTrans = async () => {
        if (Validation()) {
            if (PostValidation()) {
                confirmAlert({
                    title: 'Post',
                    message: 'Are you sure to Post?',
                    buttons: [
                        {
                            className: 'alertBtn',
                            label: 'Yes',
                            onClick: async () => {
                                let saveRes = await fSave();
                                if (saveRes) {
                                    await fPost();
                                }
                                // setShowPostModal(true);
                            }
                        },
                        {
                            className: 'alertBtn',
                            label: 'No',
                            onClick: () => { }
                        }
                    ]
                });
            }
        }
    }
    const fReversePurchaseTrans = async () => {

        confirmAlert({
            title: 'Reverse',
            message: 'Are you sure to Reverse?',
            buttons: [
                {
                    className: 'alertBtn',
                    label: 'Yes',
                    onClick: async () => {
                        if (rdxTransHead.State == "RECEIVED") {
                            let obj = {
                                transId: sHeadObj.TransId,
                                dept: 'FF'
                            }
                            const result = await reversePurchaseTransaction(obj);
                            if (result.data.Type === 'Success') {
                                fAlertToast('SUCCESS', 'Reversed Successfully');
                                setHeadObj({ ...sHeadObj, State: 'POST' })
                            }
                        }
                        else {
                            let obj = {
                                TransId: sHeadObj.TransId,
                            }
                            const result = await reverseConfirmedTransaction(obj);
                            if (result.data.Type === 'Success') {
                                fAlertToast('SUCCESS', 'Reversed Successfully');
                                setHeadObj({ ...sHeadObj, State: 'RECEIVED' })
                            }
                        }
                    }
                },
                {
                    className: 'alertBtn',
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const fSearchVendor = async (searchText) => {
        const obj = {
            searchText: searchText
        }

        const result = await searchVendor(obj);
        const resultSet = result.data ? result.data.ResultSet : [];
        return resultSet ? resultSet.map(i => { return { ...i, id: i.AcCd } }) : [];
    }
    const xVendorColumns = [
        {
            field: 'AcCd',
            headerName: 'Party Code',
            flex: 1
        },
        {
            field: 'Name2',
            headerName: 'Party Name',
            flex: 3
        },

    ];
    const onChangePaymode = (event) => {

        if (event.target.value === 'CREDIT') {
            setHeadObj({ ...sHeadObj, Paymode: event.target.value, TRNS_Vendor_Reduction: 0, TRNS_Vendor_Os_Adj: 'N' });

        }
        else {
            setHeadObj({ ...sHeadObj, Paymode: event.target.value });
        }
    }
    return (
        <div className="container">
            <div>
                <ToastContainer />
            </div>
            <div >
                <Breadcrumbs xState={rdxTransHead.State} />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mb-1'>
                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fSavePurcchaseTrans}
                            xLabel={'Save'}
                            xDisable={sHeadObj.State.toString().toUpperCase() !== 'RECEIVED' || sGridEditing}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fPostPurcchaseTrans}
                            xLabel={'Post'}
                            xDisable={sHeadObj.State.toString().toUpperCase() !== 'RECEIVED' || sGridEditing}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2.4} lg={1.2} md={1.3}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fReversePurchaseTrans}
                            xLabel={'Reverse'}
                            xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'POST' || sGridEditing}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={.5}
                    // rowSpacing={0}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    className="my-2"
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} lg={7} md={7}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={5} sm={5} lg={2} md={2}>
                                <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={9} md={9}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.TransId} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={6} lg={5} md={5}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={4} sm={4} lg={3} md={3}>
                                <CmpTypographyField xcomponent={'span'} xText={'Order No.'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={7} sm={7} lg={8} md={8}>
                                <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.OrderId} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={7} md={7}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={2.5} sm={2.5} lg={2} md={2}>
                                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                            </Grid>
                            <Grid item xs={.5} sm={.5} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={9} sm={8.5} lg={7} md={7}>
                                <GCDropdownSearch
                                    xValue={sHeadObj.Vendor}
                                    xOnChange={(params) => { xOnChangeVendor(params.row) }}
                                    xType='text'
                                    xName='vendor'
                                    xTextAlign='left'
                                    xTabIndex='1'
                                    xSearchText={sHeadObj.VendorName}
                                    xSetSearchText={(value) => { setHeadObj({ ...sHeadObj, VendorName: value }) }}
                                    xOnBackSpaceKeyPress={() => setHeadObj({ ...sHeadObj, Vendor: '', vendorName: '' })}
                                    xResultColumns={xVendorColumns}
                                    xLoadDetail={async (searchText) => fSearchVendor(searchText)}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={sHeadObj.State.toString().toUpperCase() !== 'RECEIVED' || rdxTransHead.TRNS_Vendor_Os_Adj === 'Y'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={5} md={5}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={2.5} sm={2.5} lg={3} md={3}>
                                <CmpTypographyField xcomponent={'span'} xText={'Mobile'} />
                            </Grid>
                            <Grid item xs={.5} sm={.5} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={5} md={5}>

                                <CmpInputField
                                    xValue={sHeadObj.TRNS_PARTY_MOBILE}
                                    xType={'text'} // text/number/password
                                    xName={'RefNo'}
                                    xReadOnly={false}
                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, TRNS_PARTY_MOBILE: event.target.value }); }}
                                    xError={false}
                                    xErrorMessage={''}
                                    xOnKeyUp={''}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={7} md={7}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={5} sm={5} lg={2} md={2}>
                                <CmpTypographyField xcomponent={'span'} xText={'Paymode'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>

                            <Grid item xs={6} sm={6} lg={7} md={7}>
                                <CmpSelectArrField
                                    xLabelText="Paymode"
                                    xValue={sHeadObj.Paymode}
                                    xOnChange={(event) => { onChangePaymode(event); }}
                                    xName={'Paymode'}
                                    xData={paymodeList}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={rdxTransHead.TRNS_Vendor_Os_Adj === 'Y'}
                                    xTabIndex={'2'}
                                />
                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={5} md={5}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>

                            <Grid item xs={5} sm={5} lg={3} md={3}>
                                <CmpTypographyField xcomponent={'span'} xText={'Tax Purchase'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={2} sm={2} lg={1} md={1}>
                                <CmpCheckboxField
                                    xChecked={sHeadObj.TaxPurchase === "Y"}
                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, TaxPurchase: event.target.checked ? 'Y' : 'N' }) }}
                                    xDisabled={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={12} lg={7} md={7} >
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}
                            alignItems='center'>
                            <Grid item xs={2.5} sm={2.5} md={2}>
                                <CmpTypographyField xcomponent={'span'} xText={'Invoice Date/No.'} />
                            </Grid>
                            <Grid item xs={.5} sm={.5} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={3.5} sm={2.35} md={3.5}>
                                <CmpDatePickerField
                                    xTabIndex="2"
                                    //xValue={rdxTransHead.ReferenceDate}
                                    // xOnChange={(newValue) => { dispatch(updateTransHead({ ...rdxTransHead, ReferenceDate: newValue.$d })) }}
                                    xValue={sHeadObj.ReferenceDate}
                                    xOnChange={(newValue) => { setHeadObj({ ...sHeadObj, ReferenceDate: newValue.$d }) }}
                                    xName={'ReferenceDate'}
                                    xError={''}
                                    xErrorMessage={''}
                                    xOnKeyUp={''}
                                />
                            </Grid>
                            <Grid item xs={5.5} sm={5.5} md={3.5}>
                                <CmpInputField
                                    xType={'text'} // text/number/password
                                    xName={'RefNo'}
                                    xReadOnly={false}
                                    // xValue={rdxTransHead.ReferenceNo}
                                    // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, ReferenceNo: event.target.value })) }}
                                    xValue={sHeadObj.ReferenceNo}
                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, ReferenceNo: event.target.value }) }}
                                    xError={false}
                                    xErrorMessage={''}
                                    xOnKeyUp={''}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={5} md={5}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={5} sm={5} lg={3} md={3}>
                                <CmpTypographyField xcomponent={'span'} xText={'Invoice Amount'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={5} md={5}>
                                <CmpInputField
                                    xType={'number'} // text/number/password
                                    xName={'Rounding'}
                                    xReadOnly={false}
                                    // xValue={rdxTransHead.invoiceAmt}
                                    // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, invoiceAmt: event.target.value })) }}
                                    xValue={sHeadObj.invoiceAmt}
                                    xOnChange={(event) => { setHeadObj({ ...sHeadObj, invoiceAmt: event.target.value }) }}
                                    xError={false}
                                    xErrorMessage={''}
                                    xOnKeyUp={''}
                                    xTextAlign='center'
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>

                <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mt-2'>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <div >
                            {sItemList.length > 0 && (
                                // <TransItemEditableGrid
                                //     rowsData={sSelectItemChecked ? sFilterdItemList : sItemList}
                                //     setUpdateRows={sSelectItemChecked ? setFilterdItemList : setItemList}
                                //     editable={rdxTransHead.Status === 'ENTER'}
                                // />

                                <BufferItemsGrid
                                    rowsData={sItemList}
                                    setUpdateRows={setItemList}
                                    taxPurchase={sHeadObj.TaxPurchase}
                                    editable={sHeadObj.State === 'RECEIVED'}
                                    setGridEditing={setGridEditing}
                                />

                            )}
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={0}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    className="my-2 justify-content-between"
                    sx={{ alignItems: 'center' }}>

                    <Grid item xs={4} sm={4} lg={4} md={4}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            rowSpacing={2}
                            sx={{ alignItems: 'end' }}>


                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={7} sm={7} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Vendor O/S'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={4} sm={4} lg={6} md={6}>
                                        {/* <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransHead.TRNS_Vendor_Os).toFixed(2)} /> */}
                                        <CmpTypographyField xcomponent={'span'} xText={Number(sHeadObj.TRNS_Vendor_Os).toFixed(2)} />

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 5 }}
                                    sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                    <Grid item xs={5} sm={5} md={1}>
                                        <CmpStatusDiv xStatus={rdxTransHead.Status} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} sm={6} lg={4} md={4}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            className=" text-end"
                            sx={{ alignItems: 'end' }}>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={5} sm={5} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Taxable'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} md={6}>
                                        <CmpTypographyField xcomponent={'span'} xText={Number(sTotalObj.Taxable).toFixed(2)} />

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={5} sm={5} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Tax'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} md={6}>
                                        <CmpTypographyField xcomponent={'span'} xText={Number(sTotalObj.Tax).toFixed(2)} />

                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={5} sm={5} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Total'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} md={6}>
                                        <CmpTypographyField xcomponent={'span'} xText={Number(sTotalObj.Total).toFixed(2)} />

                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={5} sm={5} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Rounding'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} md={6}>
                                        {/* <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransHead.Rounding).toFixed(2)} /> */}
                                        <CmpInputField
                                            xType={'text'} // text/number/password
                                            xName={'Rounding'}
                                            xReadOnly={false}
                                            // xValue={rdxTransHead.Rounding}
                                            // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, Rounding: event.target.value })) }}
                                            xValue={sHeadObj.Rounding}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, Rounding: event.target.value }) }}
                                            xError={false}
                                            xErrorMessage={''}
                                            xOnKeyUp={''}
                                            xTextAlign='right'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={5} sm={5} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Net Amount'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} md={6}>
                                        <CmpTypographyField xcomponent={'span'} xText={Number(sTotalObj.NetAmt).toFixed(2)} />

                                    </Grid>
                                </Grid>
                            </Grid>

                            {Number(rdxTransHead.TRNS_Vendor_Reduction) > 0 && <>
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Grid
                                        container
                                        spacing={1}
                                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                        sx={{ alignItems: 'center' }}>
                                        <Grid item xs={5} sm={5} lg={5} md={5}>
                                            <CmpTypographyField xcomponent={'span'} xText={'Adjustment'} />
                                        </Grid>
                                        <Grid item xs={1} sm={1} lg={1} md={1}>
                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6} md={6}>
                                            <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransHead.TRNS_Vendor_Reduction).toFixed(2)} />

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Grid
                                        container
                                        spacing={1}
                                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                        sx={{ alignItems: 'center' }}>
                                        <Grid item xs={5} sm={5} lg={5} md={5}>
                                            <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                                        </Grid>
                                        <Grid item xs={1} sm={1} lg={1} md={1}>
                                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6} md={6}>
                                            <CmpTypographyField xcomponent={'span'} xText={(Number(sTotalObj.NetAmt).toFixed(2) - Number(rdxTransHead.TRNS_Vendor_Reduction).toFixed(2)).toFixed(2)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                            }

                        </Grid>

                    </Grid>
                </Grid>

                {/* <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 5 }}
                    sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                    <Grid item xs={5} sm={5} md={1}>
                        <CmpStatusDiv xStatus={rdxTransHead.Status} />
                    </Grid>
                </Grid> */}
            </Box>
            <Modal
                open={sShowPostModal}
                onClose={() => setShowPostModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="modal-purchase-Modal">
                    <PurchasePostModal sHeadObj={sHeadObj} setHeadObj={setHeadObj} setShowPostModal={setShowPostModal} xTotal={Number(sTotalObj.Total).toFixed(2)} />
                </Box>
            </Modal>
            <Modal
                open={sShowAddItemModal}
                onClose={() => setShowAddItemModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="modal-purchase-Modal">
                    <AddNewItemModal sItemList={sItemList} setItemList={setItemList} setShowAddItemModal={setShowAddItemModal} selectedSrNo={0} />
                </Box>
            </Modal>
        </div >
    )
}
