import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { styled as styleMui } from '@mui/material/styles';

const StyledTableCell = styleMui(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3498db',
    color: '#fff',
    textTransform: 'uppercase',
    border: '1px solid #3498db',
    padding: '4px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12
  }
}));

const StyledTableRow = styleMui(TableRow)(({ theme }) => ({
  textTransform: 'uppercase',
  border: '1px solid #3498db',
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function OrderTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Vendor</StyledTableCell>
            <StyledTableCell align="right">Rates</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.row.map((row, i) => (
            <StyledTableRow key={'row' + i + 1}>
              <StyledTableCell component="th" scope="row">
                {row.VendorName}
              </StyledTableCell>
              <StyledTableCell align="right">{row.CostPr}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
