
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Breadcrumbs({ xState = 'OPEN' }) {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 mt-0 mb-3 breadcrumb">
            {vPathName === '/OpenFishPurSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Open Purchase Summary</span>
                </div>

            ) : vPathName === '/PostedFishPurSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Posted Purchase Summary</span>
                </div>
            ) : vPathName === '/PendingFishPurSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Pending Purchase Summary</span>
                </div>
            ) : vPathName === '/CompletedFishPurSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Completed Purchase Summary</span>
                </div>
            ) : vPathName === '/FishPurchaseDetail' ? (
                <div className="d-flex">
                    <Link
                        to={xState === 'OPEN' ? "/OpenFishPurSummary" : "/PostedFishPurSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        {xState === 'OPEN' ? 'Open Purchase Summary' : 'Posted Purchase Summary'}
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Purchase Detail </span>
                    </div>
                </div>

            ) : vPathName === '/ConfirmFishPurDetail' ? (
                <div className="d-flex">
                    <Link
                        to={xState === 'RECEIVED' ? "/PendingFishPurSummary" : "/CompletedFishPurSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        {xState === 'RECEIVED' ? 'Pending Purchase Summary' : 'Completed Purchase Summary'}
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">{xState === 'RECEIVED' ? 'Pending Purchase Detail' : 'Complted Purchase Detail'} </span>
                    </div>
                </div>
            ) : (
                <></>

            )}
        </div>
    );
}
