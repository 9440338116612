import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './returnRequest.css';
import { ArrowForwardIos } from '@mui/icons-material';
function ReturnRequestBreadcrumbs() {
  const location = useLocation();
  const vPathName = location.pathname;
  return (
    <div className="container-fluid p-0 breadcrumb">
      {vPathName === '/ReturnRequest' ? (
        <div>
          <span className="breadcrumb-arrow-inactive ">
            Return Request{' '}
          </span>
        </div>
      ) : vPathName === '/ReturnRequestDetail' ? (
        <div className="d-flex">
          <Link
            to="/ReturnRequest"
            state={location}
            className={'breadcrumb-arrow-active mr-2'}>
            Return Request
          </Link>
          <div>
            <span className="breadcrumb-arrow-inactive">
              Return Request Detail{' '}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default ReturnRequestBreadcrumbs;
