import moment from 'moment';

export const Gridcolumns = [
  {
    field: 'A_TransId',
    headerName: 'TransId',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'A_PostDt',
    headerName: 'Date',
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY'),
    width: 100
  },
  {
    field: 'A_Loc',
    headerName: 'Location',
    width: 250,
    hide: true
  },
  {
    field: 'LocName',
    headerName: 'Location',
    width: 250
  },
  {
    field: 'DC_RECEIVED_STATUS',
    headerName: 'DC Status',
    width: 100
  },
  {
    field: 'A_Truck',
    headerName: 'Truck',
    width: 90,
    align: 'center',
    headerAlign: 'center'
    // valueFormatter: ({ value }) => Number(value).toFixed(2)
  },
  {
    field: 'isDcReturn',
    headerName: 'DC Return',
    width: 150
  }
];

export const GridDetailcolumns = [
  {
    field: 'B_SrNo',
    headerName: 'SrNo',
    width: 80,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ItmCd',
    headerName: 'ItemCode',
    width: 100
  },
  {
    field: 'ItemName',
    headerName: 'ItemName',
    width: 250,
    hide: false
  },
  {
    field: 'Vendor',
    headerName: 'Vendor',
    width: 280
  },
  {
    field: 'ReqBaseQty',
    headerName: 'ReqQty',
    width: 90
    // align: 'right',
    // headerAlign: 'right',
    // valueFormatter: ({ value }) => Number(value).toFixed(2)
    // valueFormatter: ({ value }) => value.toFixed(2).replace(',', '.')
  },
  {
    field: 'Unit',
    headerName: 'Unit',
    width: 100
  },
  {
    field: 'UnitName',
    headerName: 'UnitName',
    width: 120,
    hide: true
  },
  {
    field: 'AcceptBaseQty',
    headerName: 'AccQty',
    width: 90
    // align: 'right',
    // headerAlign: 'right',
    //valueFormatter: ({ value }) => Number(value).toFixed(2)
    // valueFormatter: ({ value }) => value.toFixed(2).replace(',', '.')
  },
  {
    field: 'DCConfirmQty',
    headerName: 'ConQty',
    width: 100
  }
];
