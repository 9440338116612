import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  login: {
    userId: '',
    userName: '',
    userGroup: ''
  },
  userInfo: {
    Grp: '',
    isBuyer: 'N',
    isTruck: 'N',
    Buyer: '',
    BuyerName: '',
    TruckID: '',
    TruckName: '',
    BranchPurchase: 'N',
    PriceEnquiry: 'N',
    MarketPurchase: 'N',
    DCUser: 'N',
    DCAdmin: 'N',
    DCRequest: 'N',
    Locations: [],
    DCLocations: []
  },
  getLocation: [],
  getDCLocation: [],
  getReviewLoc: [],
  getTruckList: [],
  getPickLocList: [],
  reportBaseUrl: '',
  reportBaseUrlForPurchase: '',
  // madinaGroup: false,
  // stockTransfer:false,
  // consPosting:false,
  // dcMargin:false,
  // centralpurchase:false,
  // unload:false,
  // dcAutoTSR:false,
  menuList: [],
  Kern: {}
};

const SliceDB = createSlice({
  name: 'SliceDB',
  initialState: { value: initialState },

  reducers: {
    DB_Logint_Set: (state, action) => {
      state.value.login = action.payload;
    },
    DB_Init: (state) => {
      state.value = initialState;
    },
    DB_UserInfo: (state, action) => {
      state.value.userInfo = action.payload;
    },
    DB_Loc_Set: (state, action) => {
      state.value.getLocation = action.payload;
    },
    DB_DCLoc_Set: (state, action) => {
      state.value.getDCLocation = action.payload;
    },
    DB_ReviewLoc_Set: (state, action) => {
      state.value.getReviewLoc = action.payload;
    },
    DB_TruckList_Set: (state, action) => {
      state.value.getTruckList = action.payload;
    },

    DB_PickLOC_List_Set: (state, action) => {
      state.value.getPickLocList = action.payload;
    },

    DB_SetReportURL: (state, action) => {
      state.value.reportBaseUrl = action.payload;
    },
    DB_SetReportURLForPurchase: (state, action) => {
      state.value.reportBaseUrlForPurchase = action.payload;
    },
    // DB_SetMadinaGrp: (state, action) => {
    //   state.value.madinaGroup = action.payload;
    // },
    // DB_SetFpManualStockTrs: (state, action) => {
    //   state.value.stockTransfer = action.payload;
    // },
    // DB_SetFpConsPosting: (state, action) => {
    //   state.value.consPosting = action.payload;
    // },
    // DB_SetDcMargin: (state, action) => {
    //   state.value.dcMargin = action.payload;
    // },
    // DB_SetCPurchase: (state, action) => {
    //   state.value.centralpurchase = action.payload;
    // },
    // DB_SetUnLoad: (state, action) => {
    //   state.value.unload = action.payload;
    // },
    // DB_SetDCAutoTSR: (state, action) => {
    //   state.value.dcAutoTSR = action.payload;
    // },
    DB_SLice_Kern: (state, action) => {
      state.value.Kern = action.payload;
    },
    DB_SetMenus: (state, action) => {
      state.value.menuList = action.payload;
    }
  }
});

export const {
  DB_Logint_Set,
  DB_Init,
  DB_UserInfo,
  DB_Loc_Set,
  DB_DCLoc_Set,
  DB_ReviewLoc_Set,
  DB_TruckList_Set,
  DB_PickLOC_List_Set,
  DB_SetReportURL,
  DB_SetReportURLForPurchase,
  DB_SLice_Kern,
  // DB_SetMadinaGrp,
  // DB_SetFpManualStockTrs,
  // DB_SetFpConsPosting,
  // DB_SetDcMargin,
  // DB_SetCPurchase,
  // DB_SetUnLoad,
  // DB_SetDCAutoTSR,
  DB_SetMenus
} = SliceDB.actions;
export default SliceDB.reducer;
