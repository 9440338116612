import React from 'react'
import PurchaseConfirmSummary from './PurchaseConfirmSummary'

export default function ConfirmedPurchaseSummary() {
    return (
        < div>
            <PurchaseConfirmSummary xState={'CONFIRM'} />
        </div>
    )
}

