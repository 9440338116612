import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CircularProgress } from '@mui/material';
// import { fDummyFunction, GridDataColumn } from "./Action";
//import "./ReuseableComponent.css";
import Modal from '@mui/material/Modal';
import {
  CmpButtonOld,
  CmpButtonWithIcon,
  CmpInputField,
  CmpTypographyField,
  MUIDataGrid
} from '../../../component/ToolBox/ToolBox';
import { getConsolidatedLocItems, saveConsolidatedLocItems } from '../actions';
import { fAlertToast } from '../../../Utility/Utilitys';

const fDummyFunction = () => {};

const ConsolidatedItemsModal = ({
  xOpen = false,
  fCloseItemModal,
  vocTyp,
  vocNo,
  selectedRowData = [],
  consolidatedLocItems,
  consolidatedLocItemsArrConst,
  setConsolidatedLocItems,
  ConsolidatedGridItemColumns,
  sSelectedItemFld,
  setSelectedItemFld,
  sSelectedPackQty,
  setSelectedPackQty,
  fType,
  dcType,
  headObj,
  fLoadConLocItems,
  setConsolidatedLocItemsArrConst
}) => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');

  const [sGridEditing, setGridEditing] = useState(false);

  const setAllocQty = (params) => {
    if (params.value < 0) {
      fAlertToast('FAILED', 'AlloQty should be greater then 0');
      return params.row;
    } else {
      let itemList = consolidatedLocItems.map((i) => {
        console.log(i.id === params.row.id);
        return i.id === params.row.id
          ? {
              ...i,
              IL_AlloQty: Number(params.value),
              IL_BalQty: Number(i.IL_ReqQtyCTN) - Number(params.value) //* sSelectedPackQty
            }
          : i;
      });
      setConsolidatedLocItems(itemList);
      return { ...params.row, IL_AlloQty: params.value };
    }
  };

  const gridItemSpacing = {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-start',
    padding: 0.2
  };

  const consolidatedItemFields = [
    // {
    //   id: 'FIFO',
    //   name: 'FIFO'
    // },
    {
      id: 'MANUAL',
      name: 'MANUAL'
    },
    {
      id: '--',
      name: '--'
    }
  ];
  const onChangeItemSelect = (e) => {
    setSelectedItemFld(e.target.value);
    fType(
      e.target.value,
      e.target.value == 'MANUAL' ? consolidatedLocItemsArrConst : consolidatedLocItems
      // e.target.value == 'MANUAL' ? sSelectedPackQty :
    );
  };

  // useEffect(() => {

  //   if (xOpen) {
  //     fLoadConLocItems(setSelectedRowData.ItmCd, setSelectedRowData.Typ, setSelectedRowData.B_PackQty);
  //   }
  // }, [xOpen, vocNo, vocTyp, selectedRowData?.ItmCd]);

  const columns = ConsolidatedGridItemColumns.map((x) => {
    if (x.field === 'IL_AlloQty') {
      return {
        ...x,
        valueSetter: setAllocQty,
        editable: sSelectedItemFld == 'MANUAL',
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        cellClassName: sSelectedItemFld == 'MANUAL' ? 'mui-request-grid-final-qty' : '',
        width: vMediaQuery ? 50 : 80
      };
    } else {
      return x;
    }
  });

  const fsave_Consolidate = async () => {
    if (sSelectedPackQty < 0 || sSelectedPackQty == '') {
      fAlertToast('FAILED', 'Pack Qty should not be empty or zero');
      return;
    } else {
      const obj = {
        v_IL_VocTyp: vocTyp,
        v_IL_VocNo: vocNo,
        v_IL_ItmCd: selectedRowData?.ItmCd,
        DCStock: selectedRowData?.DCStock,
        v_Typ: sSelectedItemFld,
        locItemArr: consolidatedLocItems,
        B_PackQty: sSelectedPackQty
      };
      const result = await saveConsolidatedLocItems(obj);
      if (!!result) {
        // fAlertToast('SUCCESS', 'Saved successfuly');
        fLoadConLocItems(selectedRowData?.ItmCd, sSelectedItemFld, sSelectedPackQty);
      }
    }
  };

  return (
    <Modal
      open={xOpen}
      onClose={fCloseItemModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ flexGrow: 1, overflow: 'auto' }} className="gnr-item-search model-gc-item2">
        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="GCSearchBtn-Tittle"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={11} sm={11} md={11} className="d-flex justify-content-center  ">
            <div>Consolidated Items</div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} className="d-flex justify-content-end">
            <CloseIcon
              className="grnt-item-search-close-icon"
              onClick={!!fCloseItemModal ? fCloseItemModal : fDummyFunction}
              style={{ zIndex: 999 }}
            />
          </Grid>
        </Grid>

        <div className="p-2">
          <Grid
            container
            rowSpacing={2}
            columnSpacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12 }}
            className="mt-2   pt-0"
            alignItems={'center'}>
            {/* <Grid item xs={4} className="gnr-item-search-label ">
                            {xLabel}
                        </Grid> */}

            <Grid item xs={2} sm={2} md={0.5}>
              <CmpTypographyField xcomponent={'span'} xText={'Item'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={4} sm={4} md={1}>
              <CmpInputField
                xValue={selectedRowData?.ItmCd}
                xType={'text'} // text/number/password
                xName={'vocNo'}
                xReadOnly={true}
                xOnChange={''}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <CmpInputField
                xValue={selectedRowData?.ItemName}
                xType={'text'} // text/number/password
                xName={'vocNo'}
                xReadOnly={true}
                xOnChange={''}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
              />
            </Grid>

            <Grid item xs={2} sm={2} md={1.5}>
              <CmpTypographyField
                xcomponent={'span'}
                xText={'DC Stock Qty'}
                xVariant={'subtitle'}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={1}>
              <CmpInputField
                xValue={selectedRowData?.DCStock}
                xType={'text'} // text/number/password
                xName={'vocNo'}
                xReadOnly={true}
                xOnChange={''}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
                xTextAlign="center"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={1.5}>
              <CmpInputField
                xValue={
                  Number(sSelectedPackQty) == 0
                    ? 0
                    : (Number(selectedRowData?.DCStock) / Number(sSelectedPackQty)).toFixed(2)
                }
                xType={'text'} // text/number/password
                xName={' '}
                xReadOnly={true}
                xOnChange={''}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
                xTextAlign="center"
              />
            </Grid>
            <Grid item xs={2} sm={2} md={1.5}>
              <CmpTypographyField xcomponent={'span'} xText={'Pack Qty'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={4} sm={4} md={1}>
              <CmpInputField
                xValue={sSelectedPackQty}
                xType={'number'} // text/number/password
                xName={'PackQty'}
                xReadOnly={sSelectedItemFld == '--'}
                xOnChange={(event) => {
                  let value = Number(event.target.value);
                  setSelectedPackQty(value);
                  // let itemList = consolidatedLocItems.map((i) => {
                  //   return {
                  //     ...i,
                  //     // IL_ReqQtyCTN: Number(event.target.value) ,
                  //     PackQty:
                  //       Number(value) == 0
                  //         ? 0
                  //         : Number(i.IL_FinalQty) / Number(value) > 0 &&
                  //           Number(i.IL_FinalQty) / Number(value) < 0.99
                  //         ? 1
                  //         : Math.round(Number(i.IL_FinalQty) / Number(value)),
                  //     //balance qty=(reqqty-allocated qty)*pacqty
                  //     IL_BalQty:
                  //       (Number(
                  //         Number(value) == 0
                  //           ? 0
                  //           : Number(i.IL_FinalQty) / Number(value) > 0 &&
                  //             Number(i.IL_FinalQty) / Number(value) < 0.99
                  //           ? 1
                  //           : Math.round(Number(i.IL_FinalQty) / Number(value))
                  //       ) -
                  //         Number(i.IL_AlloQty)) *
                  //       value
                  //   };
                  // });
                  // setConsolidatedLocItems(itemList);

                  setGridEditing(false);
                  // }
                }}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
                xTextAlign="center"
              />
            </Grid>

            <Grid item xs={6} sm={6} md={2}>
              <select
                className="form-select form-select-sm"
                value={sSelectedItemFld}
                onChange={onChangeItemSelect}
                disabled={dcType}>
                {consolidatedItemFields.length > 0 ? (
                  <>
                    {consolidatedItemFields?.map((option, index) => (
                      <option className="py-2" value={option.id} key={'auditfield' + index}>
                        {option.name}{' '}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">none</option>
                )}
              </select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} textAlign="end">
              <CmpButtonOld
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={() => {
                  fsave_Consolidate();
                }}
                xLabel={'Calculate'}
                xIcon={''}
                xDisable={sGridEditing || headObj.docStatus === 'POST'}
              />
            </Grid>
          </Grid>

          {false ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={1} className="search-item ">
              <Grid item xs={12} className=" height-table-scroll" component="div">
                <div
                  style={{
                    height: vMediaQuery ? '60vh' : '65vh',
                    width: '100%'
                  }}
                  id="table-main-grid-3"
                  className="search-grid-table mt-2">
                  <Box style={{ height: '100%', width: '100%' }}>
                    {/* <MUIDataGrid
                      xRows={consolidatedLocItems}
                      xColumns={ConsolidatedGridItemColumns}
                      xonRowClick={() => {}}
                      enableCheckbox={false}
                    /> */}
                    <DataGrid
                      getRowId={(row) => row.id}
                      rowHeight={40}
                      headerHeight={40}
                      sx={{
                        border: 2,
                        borderColor: 'primary.light'
                        // color: '#000000'
                      }}
                      onCellEditStart={() => setGridEditing(true)}
                      onCellEditStop={() => setGridEditing(false)}
                      onCellEditCommit={() => setGridEditing(false)}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                      }
                      rows={consolidatedLocItems}
                      columns={columns}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
          )}
          <Box sx={{ padding: 0.5, display: 'flex', justifyContent: 'flex-end' }}>
            <CmpButtonOld
              xvariant={'contained'}
              xsize={'medium'}
              xonClick={() => {
                fsave_Consolidate();
              }}
              xLabel={'Save'}
              xIcon={''}
              xDisable={sGridEditing || headObj.docStatus === 'POST'}
            />
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default ConsolidatedItemsModal;
