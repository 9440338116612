import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material';
import { Check, CheckBox, Close } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { CmpButton, CmpCheckboxField, CmpTypographyField } from '../../../component/ToolBox/ToolBox';
import { getVendorOSDetail } from '../actions';
import { fAlertToast } from '../../../Utility/Utilitys';

export default function PurchaseAdjustmentModal({
    sHeadObj,
    setHeadObj,
    xTotal,
    setShowAdjModal
}) {

    const [sDetail, setDetail] = useState([]);
    const [sAdjAmt, setAdjAmt] = useState(0);
    const [sEditable, setEditable] = useState(true);

    useEffect(() => {
        let total = sDetail.reduce((prevValue, i) => {
            return prevValue + Number(i.AdjAmt);
        }, 0);
        setAdjAmt(total);
    }, [sDetail, setAdjAmt])
    //const rdxTransHead = useSelector((state) => state.PurchaseSlice.transHead);
    const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);

    const loadVendorOSDetails = useCallback(async () => {
        const result = await getVendorOSDetail({
            AcCd: sHeadObj.Vendor.toString(),
            Loc: rdxFilterObj.DCList.toString()
        });
        console.log('v detail' + JSON.stringify(result.data.ResultSet));

        setDetail(result.data.ResultSet.map((i) => { return { ...i, checked: false } }));
    }, [setDetail, rdxFilterObj]);


    const setAdjAmount = (params) => {
        let row = { ...params.row, AdjAmt: params.value, checked: true };

        const rec1 = sDetail.map((itm) => (itm.VocNo === row.VocNo ? row : itm));
        setDetail(rec1);
        return row;
    };

    const changeChecked = (params) => {
        const checked = !params.row.checked;

        let row = { ...params.row, checked: checked, AdjAmt: checked ? params.row.OsAmtLc : params.row.AdjAmt };

        const rec1 = sDetail.map((itm) => (itm.VocNo === row.VocNo ? row : itm));
        setDetail(rec1);
        return row;
    }

    useEffect(() => {
        loadVendorOSDetails();
    }, [loadVendorOSDetails]);


    const onClickApply = () => {

        if (Number(xTotal) + Number(sHeadObj.Rounding) < Number(sAdjAmt)) {
            fAlertToast('FAILED', 'Adjustment amount can not be greater than purchase amount');
            return;
        }
        const misRows = sDetail.filter((i) => Number(i.AdjAmt) > Number(i.OsAmtLc));
        console.log('misrows' + misRows + misRows.length);
        if (misRows.length > 0) {
            fAlertToast('FAILED', 'Adjustment amount can not be greater than o/s amount');
            return;
        }



    }


    const columns = [
        {
            field: 'checked',
            headerName: 'Allocation',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {
                // console.log('ccccc' + JSON.stringify(params));
                return (
                    <CmpCheckboxField xChecked={params.row.checked}
                        xOnChange={(event) => { console.log(params); changeChecked(params); }}
                    />
                    // <IconButton size='small' color='primary' onClick={() => { console.log(params); }}>
                    //     <CheckBox />
                    // </IconButton>
                );
            }
        },
        {
            field: 'VocNo',
            headerName: 'Voc No',
            flex: 1,
        },
        {
            field: 'OsAmtLc',
            headerName: 'O/s Amount Lc',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },

        {
            field: 'AdjAmt',
            headerName: 'Adjustment.amt.',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            editable: true,
            valueSetter: setAdjAmount,
            valueFormatter: ({ value }) => Number(value).toFixed(2),

        },
        {
            field: 'RefDt',
            headerName: 'Reference Date',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => moment(value).format('MM/DD/YYYY'),

        },
        {
            field: 'DueDt',
            headerName: 'Due date',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => moment(value).format('MM/DD/YYYY'),
        },
        // {
        //     field: 'CurrAllocQtyCTN',
        //     headerName: 'Curr.Qty',
        //     flex: 2,
        //     align: 'right',
        //     headerAlign: 'right',
        //     editable: editable && sManualAlloc,
        //     valueSetter: setCurrAllocQty,
        //     valueFormatter: ({ value }) => Number(value).toFixed(2)

        // },
    ];
    return (
        <Box sx={{ flexGrow: 1 }} className="m-4">

            <Grid container spacing={0}>
                <Grid item xs={12} className=" d-flex justify-content-end">
                    <Close onClick={() => setShowAdjModal(false)} />
                </Grid>
            </Grid>
            <Grid container spacing={2}
                sx={{ overflowY: 'auto', marginTop: '1vh', paddingRight: '15px', alignContent: 'start' }}>

                <Grid item xs={12} sm={12} md={8}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={6} sm={6} md={6}>
                            <CmpTypographyField xcomponent={'span'} xText={'Purchased Amount'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={3} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={(Number(xTotal) + Number(sHeadObj.Rounding)).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={6} sm={6} md={6}>
                            <CmpTypographyField xcomponent={'span'} xText={'Allocated Amount'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={3} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={Number(sAdjAmt).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={6} sm={6} md={6}>
                            <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={3} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={(Number(xTotal) + Number(sHeadObj.Rounding) - Number(sAdjAmt)).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mt-4'>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Box
                        sx={{
                            height: '35vh',
                            width: '100%',
                            '& .actions': {
                                color: 'text.secondary'
                            },
                            '& .textPrimary': {
                                color: 'text.primary'
                            }
                        }}>
                        <DataGrid
                            columns={columns}
                            rows={sDetail}
                            getRowId={(row) => row.VocNo}
                            rowHeight={45}
                            headerHeight={50}
                            hideFooter={true}
                            //checkboxSelection
                            //onSelectionModelChange={(params, details) => { console.log('checked changedd' + params[0] + params.length + typeof (params) + JSON.stringify(details)) }}
                            sx={{
                                border: 2,
                                borderColor: 'primary.light',
                            }}
                            componentsProps={{
                                filterPanel: { sx: { maxWidth: "93vw" } }
                              }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                            }

                            onCellEditStart={() => setEditable(false)}
                            onCellEditStop={() => setEditable(true)}
                            onCellEditCommit={() => setEditable(true)}
                        />

                    </Box >
                </Grid >
            </Grid >
            <Grid container spacing={0} className='mt-5'>
                <Grid item xs={12} className="d-flex justify-content-center">
                    <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={onClickApply}
                        xLabel={'Apply'}
                        xDisable={!sEditable}
                    />
                </Grid>
            </Grid>
        </Box >

    )
}
