import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { fAlertToast } from '../../Utility/Utilitys';

import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { CmpTypographyField, CmpButton, CmpDatePickerField } from '../../component/ToolBox/ToolBox';
import { setBatchCreationDetail } from './BatchCreationSlice';
import BatchCreationDetailCard from './ProcessComponents/BatchCreationDetailCard';
import { createBatch, getBatchCreationPendingTrans } from './actions';
import { ConstructionOutlined } from '@mui/icons-material';
import BatchCreationBreadcrumbs from './BatchCreationBreadcrumbs';
import './BatchCreation.css';

export default function BatchCreationDetail() {
  const dispatch = useDispatch();
  const xBatchDetail = useSelector((state) => state.BatchCreationSlice.value.detail);
  const rdxuserId = useSelector((state) => state.SliceDB.value.login.userId);
  const reportBaseUrl = useSelector((state) => state.SliceDB.value.reportBaseUrl);

  const [sTransList, setTransList] = useState([]);
  const [sBatch, setBatch] = useState('');

  const getPendingTransList = useCallback(async () => {
    const obj = {
      v_vendor: xBatchDetail.A_Vendor,
      v_Loc: xBatchDetail.DCLoc
    };

    const result = await getBatchCreationPendingTrans(obj);
    setTransList(
      result.ResultSet.map((i) => {
        return { ...i, selected: true };
      })
    );
  }, [xBatchDetail.A_Vendor, xBatchDetail.DCLoc]);

  useEffect(() => {
    getPendingTransList();
  }, []);

  const onChangeTransDate = (newValue) => {
    dispatch(setBatchCreationDetail({ ...xBatchDetail, A_TransDt: newValue.$d }));
  };

  const onChangeSelect = (transId, checked) => {
    const obj = sTransList.map((i) => {
      return i.A_TransId === transId ? { ...i, selected: !checked } : i;
    });
    setTransList(obj);
  };

  const onPressCreate = async () => {
    const selectedList = sTransList.filter((i) => i.selected);
    if (selectedList.length == 0) {
      fAlertToast('FAILED', 'Select at least one Transaction');
    } else {
      const updateObj = {
        v_A_PostDt: moment(xBatchDetail.A_TransDt).format('MM/DD/YYYY'),
        v_A_DCLoc: xBatchDetail.DCLoc,
        v_A_Vendor: xBatchDetail.A_Vendor,
        v_User: rdxuserId,
        TransList: selectedList.map((i) => {
          return i.A_TransId;
        })
      };
      const result = await createBatch(updateObj);
      if (result.status === 200) {
        fAlertToast('SUCCESS', 'Saved Successfully');
        setBatch(result.data.ResultSet.batchId);
        //getPendingTransList();
      }
    }
  };

  const onPressPrint = () => {
    // let baseUrl = '' + process.env.REACT_APP_REPORT_BASE_URL;
    let url = reportBaseUrl + 'FreshPurchase/PurchaseReturn?trntyp=PR&batchId=' + sBatch;
    window.open(url);
  };

  return (
    <div className="container BatchCreation">
      <div>
        <ToastContainer />
      </div>
      <div
      //  className="my-2"
      >
        <BatchCreationBreadcrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => onPressCreate()}
              xLabel={'Create'}
              xDisable={sBatch !== ''}
            />
          </Grid>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => onPressPrint()}
              xLabel={'Print'}
              xDisable={sBatch == ''}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          {sBatch != '' && (
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4.5} sm={4} lg={2} md={2}>
                  <CmpTypographyField xcomponent={'span'} xText={'Batch No'} />
                </Grid>
                <Grid item xs={1} sm={1} lg={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={6.5} sm={7} lg={9} md={9}>
                  <CmpTypographyField xcomponent={'span'} xText={sBatch} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xBatchDetail.Vendorname} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={xBatchDetail.DCLocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Dispatched Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={9} md={9}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(xBatchDetail.A_DispatchDt).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Transaction Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5} sm={2.5} md={2.5} lg={1.5}>
                <CmpDatePickerField
                  xValue={xBatchDetail.A_TransDt}
                  xOnChange={(newValue) => onChangeTransDate(newValue)}
                  xName={'Trans Date'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                  xDisabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="scrol-overflow">
          <Grid
            container
            spacing={1}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            sx={{
              height: '90%',
              overflowY: 'auto',
              marginTop: '1vh',
              paddingRight: '16px',
              alignContent: 'start'
            }}>
            {sTransList.map((i) => {
              return (
                <Grid key={i.A_TransId} item xs={12} sm={12} lg={4} md={6}>
                  <BatchCreationDetailCard
                    xDisptachNo={i.A_TransId}
                    xLoc={i.LocName}
                    xPostDt={moment(i.A_PostDt).format('DD/MM/YYYY')}
                    xSelected={i.selected}
                    xOnChangeSelect={() => onChangeSelect(i.A_TransId, i.selected)}
                    item={i}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Box>
    </div>
  );
}
