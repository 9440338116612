import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { CmpButton } from '../../component/ToolBox/ToolBox';
import TransItemCard from './ProcessComponents/TransItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { getVanTrasactionItems, postVanReceivingTrans, reverseVanReceivingTrans } from './actions';
import VanBreadcrumbs from './ProcessComponents/VanBreadcrumbs';
import { updateTransHead, updateTransItem } from './VanReceivingSlice';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';

export default function VanTransDetail() {
    const [sItemList, setItemList] = useState([]);
    const rdxTransHead = useSelector((state) => state.VanReceivingSlice.transHead);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loadItems = useCallback(async () => {

        const obj = {
            vanTransId: rdxTransHead.transId,
        }
        const data = await getVanTrasactionItems(obj);
        setItemList(data.ResultSet);
    }, [setItemList, rdxTransHead]);

    useEffect(() => {
        loadItems();

    }, [loadItems]);

    const fOnpressTransItem = (itm) => {
        dispatch(updateTransItem({
            srNo: itm.SrNo,
            itmCd: itm.ItmCd,
            itemName: itm.ItemName,
            totalQty: Number(itm.AssignedQty),
            unit: itm.Unit,
            unitName: itm.UnitID,
            receivedQty: Number(itm.ReceivedQty),
            balQty: Number(itm.BalQty),
            barcode: itm.Barcode,
        }));
        navigate('/VanItemReceiving');
    };

    const fOnclickPost = async () => {

        const itemsVariance = sItemList.filter(i => Number(i.BalQty) !== 0);
        confirmAlert({
            title: 'Post',
            message: itemsVariance.length > 0 ? 'Variance in quantity found! \n Are you sure to post?' : 'Are you sure to post?',
            buttons: [
                {
                    className: 'alertBtn',
                    label: 'Yes',
                    onClick: async () => {
                        let obj = {
                            vanTransId: rdxTransHead.transId
                        };
                        const result = await postVanReceivingTrans(obj);
                        if (result.data.Type === 'Success') {
                            fAlertToast('SUCCESS', 'Posted Successfully');
                            dispatch(updateTransHead({
                                ...rdxTransHead,
                                status: 'POST'
                            }));

                        }
                    }
                },
                {
                    className: 'alertBtn',
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    };

    const fOnClickReverse = async () => {
        confirmAlert({
            title: 'Reverse',
            message: 'Are you sure to reverse?',
            buttons: [
                {
                    className: 'alertBtn',
                    label: 'Yes',
                    onClick: async () => {
                        let obj = {
                            vanTransId: rdxTransHead.transId
                        };
                        const result = await reverseVanReceivingTrans(obj);
                        if (result.data.Type === 'Success') {
                            fAlertToast('SUCCESS', 'Reversed Successfully');
                            dispatch(updateTransHead({
                                ...rdxTransHead,
                                status: 'ENTER'
                            }));

                        }
                    }
                },
                {
                    className: 'alertBtn',
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    };
    return (
        <div className="container openPurchase">
            <div >
                <VanBreadcrumbs />
            </div>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mb-2'>

                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fOnclickPost}
                            xLabel={'Post'}
                            xDisable={rdxTransHead.status.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2.4} lg={1.2} md={1.3}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fOnClickReverse}
                            xLabel={'Reverse'}
                            xDisable={rdxTransHead.status.toString().toUpperCase() !== 'POST'}
                        />
                    </Grid>

                </Grid>
                <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ height: '60vh', overflowY: 'auto', marginTop: '1vh', paddingRight: '16px', alignContent: sItemList.length === 0 ? 'center' : 'start' }}>

                    {sItemList.length === 0 ? (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // height: '80vh'
                            }}>
                            <p style={{ fontSize: '2rem' }}> List is Empty </p>
                        </div>
                    ) :
                        (
                            sItemList.map((itm) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        key={itm.VAN_SRNO}
                                        onClick={() => fOnpressTransItem(itm)}
                                    >
                                        <TransItemCard
                                            xItem={itm.ItemName}
                                            xTotal={itm.AssignedQty}
                                            xReceived={itm.ReceivedQty}
                                            xBalQty={itm.BalQty}
                                            xUnit={itm.UnitID}
                                        />
                                    </Grid>
                                );
                            })
                        )}
                </Grid>
            </Box>

        </div>
    )
}
