import { fExecuteAPI } from '../../Server/Apis';

const getReturnReviewBatchSummary = async (xObj) => {
  try {
    const pendingList = await fExecuteAPI('RETURN_REVIEW_BATCH_SUMMARY', xObj);
    return pendingList.data;
  } catch (error) {}
};

export const getDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};
const getReturnReviewBatchDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('RETURN_REVIEW_BATCH_DETAIL', xObj);
    return result;
  } catch (error) {}
};

const getReturnReviewBatchItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('RETURN_REVIEW_BATCH_ITEM', xObj);
    return result;
  } catch (error) {}
};

const getVendorList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_VENDOR_LIST', xObj);
    return result;
  } catch (error) {}
};

const updateReturnReviewBatchItems = async (xObj) => {
  try {
    const result = await fExecuteAPI('UPDATE_RETURN_REVIEW_BATCH_ITEM', xObj);
    return result;
  } catch (error) {}
};

const postReturnReviewBatch = async (xObj) => {
  try {
    const result = await fExecuteAPI('POST_RETURN_REVIEW_BATCH', xObj);
    return result;
  } catch (error) {}
};

export {
  getReturnReviewBatchSummary,
  getReturnReviewBatchDetail,
  getReturnReviewBatchItem,
  getVendorList,
  updateReturnReviewBatchItems,
  postReturnReviewBatch
};
