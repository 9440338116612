import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './DCConfirmation.css';
import { fAlertToast } from '../../Utility/Utilitys';
import { CmpTypographyField, CmpButton, CmpDatePickerField, CmpStatusDiv } from '../../component/ToolBox/ToolBox';
import DCConfirmationDetailCard from './processComponent/DCConfirmationDetailCard';
import DCConfirmationBreadCrumbs from './DCConfirmationBreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDCConfirmationItemList,
  getPurchaseReturnItemList,
  getTaxDeptList,
  updateDCConfirmationItem,
  updatePurchaseReturnItem
} from './action';
import { DCConfirmation_Detail_TransDate } from './DCConfirmationSlice';

function DCConfirmationDetail() {
  const dispatch = useDispatch();
  const xItemDetail = useSelector((state) => state.DCConfirmationSlice.value.detail);
  const rdxuserId = useSelector((state) => state.SliceDB.value.login.userId);


  const [sItemList, setItemList] = useState([]);
  const [sTaxDept, setTaxDept] = useState([]);

  useEffect(() => {
    getDCConfirmationItemLists();
    getDeptList();
  }, [xItemDetail.A_TransId]);

  const getDeptList = async () => {
    const deptObj = {};
    const result = await getTaxDeptList(deptObj);
    const updateList = result.ResultSet.Tax1.map((itm) => ({
      ...itm,
      value: itm.UID,
      lable: itm.Name1
    }));
    setTaxDept(updateList);
  };

  const getDCConfirmationItemLists = async () => {
    const itemListObj = {
      v_A_TransId: xItemDetail.A_TransId
    };
    const result = await getDCConfirmationItemList(itemListObj);
    console.log('*******getDCConfirmationItemList ****** ', result);
    setItemList(result.data.ResultSet);
  };

  const Validation = () => {
    let iRes = true;

    const confrQty = sItemList.filter((data) => data.B_Conf_Qty === '');
    if (confrQty.length > 0) {
      fAlertToast('FAILED', 'Conf Qty cannot be empty');
      return (iRes = false);
    }

    const unitRate = sItemList.filter((data) => data.B_Conf_UnitRate === '');
    if (unitRate.length > 0) {
      fAlertToast('FAILED', 'unit Rate cannot be empty');
      return (iRes = false);
    }

    const taxDept = sItemList.filter((data) => data.B_TaxDept === '');
    if (taxDept.length > 0) {
      fAlertToast('FAILED', 'Tax Dept cannot be empty');
      return (iRes = false);
    }

    const adjQtyRange = sItemList.filter((data) => data.B_Conf_Qty > data.B_Pick_Qty);
    if (adjQtyRange.length > 0) {
      fAlertToast('FAILED', 'Confrm Qty cannot be greater then picked Qty');
      return (iRes = false);
    }

    return iRes;
  };

  const UpdateDCConfirmation = async (xStatus) => {
    const updateList = sItemList.map((itm) => ({
      v_B_SrNo: itm.B_SrNo,
      v_B_Conf_Qty: itm.B_Conf_Qty,
      v_B_Conf_UnitRate: itm.B_Conf_UnitRate,
      v_B_Conf_NetAmt: itm.B_Conf_NetAmt,
      v_B_Adj_Qty: itm.B_Adj_Qty,
      v_B_Adj_Amt: itm.B_Adj_Amt
      // v_B_TaxDept: itm.B_TaxDept,
      // v_B_TaxPerc: itm.B_TaxPerc,
      // v_B_TaxAmt: itm.B_TaxAmt
    }));

    // {

    //   TransDt: "01/27/2023"
    //   status: "Enter",
    //   ItemList: [
    //     {
    //       v_B_Adj_Amt:0
    //       v_B_Adj_Qty:38
    //       v_B_Conf_NetAmt:1080
    //       v_B_Conf_Qty: "12"
    //       v_B_Conf_UnitRate: "90"
    //       v_B_SrNo:1
    //     }
    //   ]
    // }

    if (Validation()) {
      const updateObj = {
        v_A_TrnsId: xItemDetail.A_TransId,
        status: xStatus === 'save' ? 'Enter' : 'Post',
        userId: rdxuserId,
        v_A_TransDt: moment(xItemDetail.A_TransDt).format('MM/DD/YYYY'),
        ItemList: updateList
      };
      const result = await updateDCConfirmationItem(updateObj);
      console.log('********RESULT******** ', result);
      console.log('********PAYLOAD ******** ', updateObj);
      if (result.status === 200) {
        fAlertToast('SUCCESS', xStatus === 'save' ? 'Save Successfully' : 'Post Successfully');
      }
    }
  };

  const Calculation = (event, item, val) => {
    const objResult = sTaxDept.find((obj) => {
      if (obj.value === event.target.value) {
        return obj;
      }
    });
    let updatedValue = sItemList.map((obj) => {
      if (obj.B_ItmCd === item.B_ItmCd) {
        let objUpdate = obj;
        obj.B_Conf_Qty = val === 'ConfrmQty' ? event.target.value : obj.B_Conf_Qty;
        obj.B_Conf_UnitRate = val === 'unitRate' ? event.target.value : obj.B_Conf_UnitRate;
        // obj.B_TaxDept = val === 'taxDpt' ? event.target.value : obj.B_TaxPerc;
        obj.B_Conf_NetAmt = obj.B_Conf_Qty * obj.B_Conf_UnitRate;
        obj.B_Adj_Qty = obj.B_Pick_Qty - obj.B_Conf_Qty;
        // obj.B_TaxAmt = (val === 'taxDpt' ? objResult.TaxPerc : obj.B_TaxPerc) * obj.B_Conf_NetAmt;

        return objUpdate;
      } else {
        return obj;
      }
    });
    setItemList(updatedValue);
  };

  const onChangeTransDate = (newValue) => {
    dispatch(DCConfirmation_Detail_TransDate(newValue.$d));
  };

  return (
    <div className="container PurchaseReturn">
      <div>
        <ToastContainer />
      </div>
      <div 
      // className="my-2"
      >
        <DCConfirmationBreadCrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => UpdateDCConfirmation('save')}
              xLabel={'Save'}
              xDisable={false}
            />
          </Grid>
          <Grid item xs={4} sm={2} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => UpdateDCConfirmation('post')}
              xLabel={'Post'}
              xDisable={false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans Id'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={7} md={7}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_TransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Dispatched Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={7} md={7}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(xItemDetail.A_DispatchDt).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={7} md={7}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.LocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Truck'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={7} md={7}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.TruckName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              className='mb-3'
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Transaction Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4} sm={7} md={4}>
                <CmpDatePickerField
                  xValue={xItemDetail.A_TransDt}
                  xOnChange={(newValue) => onChangeTransDate(newValue)}
                  xName={'Trans Date'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={4} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'No of Items'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={7} md={7}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.ItemCount} />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <div className="scrol-overflow">
          <Grid
            container
            spacing={0.5}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            sx={{ height: '50vh', overflowY: 'auto', marginTop: '1vh', paddingRight: '16px', alignContent: 'start' }}>

            {sItemList.map((itm) => {
              return (
                <Grid item xs={12} key={itm.B_ItmCd} sm={12} lg={6} md={6}>
                  <DCConfirmationDetailCard
                    xItmCode={itm.B_ItmCd}
                    xItmName={itm.ItemName}
                    xPickedQty={itm.B_Pick_Qty}
                    xDisptached={itm.B_Disp_Qty}
                    xConfrmQty_Value={itm.B_Conf_Qty}
                    xOnchangeConfrmQty={(event) => Calculation(event, itm, 'ConfrmQty')}
                    xUnitRateValue={itm.B_Conf_UnitRate}
                    xOnchangeUnitRate={(event) => Calculation(event, itm, 'unitRate')}
                    xNetAmnt={itm.B_Conf_NetAmt}
                    xAdjQty={itm.B_Adj_Qty}
                  // xTaxDeptArr={sTaxDept}
                  // xTaxAmnt={itm.B_TaxAmt}
                  // xTaxDeptValue={itm.B_TaxDept}
                  // xOnChangeTxDept={(event) => Calculation(event, itm, 'taxDpt')}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>

        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          sx={{ alignItems: 'center' }}>
          <CmpStatusDiv xStatus={xItemDetail.Status} />
        </Grid>
      </Box>
    </div>
  );
}
export default DCConfirmationDetail;
