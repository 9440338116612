import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function VanBreadcrumbs() {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 mt-0 mb-3 breadcrumb">
            {vPathName === '/VanTransSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Receiving Summary</span>
                </div>
            ) : vPathName === '/VanTransDetail' ? (
                <div className="d-flex">
                    <Link
                        to={"/VanTransSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        Receiving Summary
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Receiving Detail </span>
                    </div>
                </div>
            ) : vPathName === '/VanItemReceiving' ? (
                <div className="d-flex">
                    <Link
                        to={"/VanTransSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        Receiving Summary
                    </Link>
                    <Link
                        to={"/VanTransDetail"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        Receiving Detail
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Item Receiving </span>
                    </div>
                </div>
            )
                : (
                    <></>

                )}
        </div>
    );
}

