import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import '../../component/ToolBox/ToolBox.css';
import { CSVLink, CSVDownload } from 'react-csv';
import { ToastContainer } from 'react-toastify';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpCheckboxField,
  MUIDataGrid,
  CmpSelectArrField,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import {
  getDCMarginRSPAttr,
  getDCMarginLoadDetail,
  getFPConsAppDetail,
  getDCMarginRSPPost,
  getDCMarginAttr
} from './actions';
import DCMarginPosting from './ProcessComponents/DCMarginPosting';
import { getGitterColumns, fAlertToast } from '../../Utility/Utilitys';
import moment from 'moment';
import DCMarginBreadCrumbs from './ProcessComponents/DCMarginBreadCrumbs';

const gridItemSpacing = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0.2
};
const borderBox = {
  height: '75vh',
  margin: 2
};
const alignContent = {
  paddingTop: '9px'
};

const loadSummaryColSample = [
  {
    field: 'A_VocTyp',
    headerName: 'A_VocTyp',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'VocName',
    headerName: 'Doc.Name',
    hide: false,
    width: 140,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_VocNo',
    headerName: 'Doc.No',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_VocDt',
    headerName: 'Doc.Date',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'A_Loc',
    headerName: 'A_Loc',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'LocName',
    headerName: 'Location',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_Dept',
    headerName: 'A_Dept',
    hide: true,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Deptname',
    headerName: 'Deptartment',
    hide: false,
    width: 170,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'Completed',
    headerName: 'Status',
    hide: false,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'DocStatus',
    headerName: 'DC.Margin',
    hide: false,
    width: 80,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'PostedBy',
    headerName: 'DC.Posted.By',
    hide: false,
    width: 100,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'PostedDt',
    headerName: 'PostedDt',
    hide: true,
    width: 40,
    align: 'right',
    headerAlign: 'right',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'Type',
    headerName: 'Type',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'id',
    headerName: 'id',
    hide: true,
    width: 40,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '0'
  }
];

const loadDetailColSample = [
  {
    field: 'A_VocTyp',
    headerName: 'A_VocTyp',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_VocTypName',
    headerName: 'A_VocTypName',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'A_VocNo',
    headerName: 'A_VocNo',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_ORD_ID',
    headerName: 'Order.Id',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_ID',
    headerName: 'Trans.Id',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'GRN_DOC_TYP',
    headerName: 'GRN_DOC_TYP',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'GRN_DOC_TYPName',
    headerName: 'GRN_DOC_TYPName',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'GRN_DOC_NO',
    headerName: 'GRN_DOC_NO',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_LOC',
    headerName: 'TRNS_LOC',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_LOCName',
    headerName: 'TRNS_LOCName',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_DATE',
    headerName: 'TRNS_DATE',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'D',
    NumberFormat: 'dd/MM/yyyy'
  },
  {
    field: 'TRNS_PARTY',
    headerName: 'TRNS_PARTY',
    hide: true,
    width: 200,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_PARTYName',
    headerName: 'Vendor',
    hide: false,
    width: 150,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_SRNO',
    headerName: 'Sr.No',
    hide: true,
    width: 40,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'ItmCode',
    headerName: 'Item.Code',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'ItmName',
    headerName: 'Item.Name',
    hide: false,
    width: 140,
    align: 'left',
    headerAlign: 'left',
    Typ: 'S',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_QTY',
    headerName: 'Qty',
    hide: false,
    width: 60,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_UNIT',
    headerName: 'TRNS_UNIT',
    hide: true,
    width: 100,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRNS_UNITName',
    headerName: 'Packing',
    hide: false,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '0'
  },
  {
    field: 'TRN_PACK_QTY',
    headerName: 'TRN_PACK_QTY',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_BASEQTY',
    headerName: 'TRNS_BASEQTY',
    hide: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_UnitRate',
    headerName: 'Unit.Rate',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_NetAmt',
    headerName: 'Net.Amt',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRN_Rounding',
    headerName: 'TRN_Rounding',
    hide: true,
    width: 60,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'LandedCost',
    headerName: 'LandedCost',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_Add_Margin_Base',
    headerName: 'TRNS_Add_Margin_Base',
    hide: true,
    width: 60,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_Add_Margin_CTN',
    headerName: 'Add.Margin',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_Add_Margin_Net',
    headerName: 'Net.Margin',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'TRNS_TSR_RSP',
    headerName: 'TSR.Cost',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  },
  {
    field: 'id',
    headerName: 'id',
    hide: false,
    width: 150,
    align: 'right',
    headerAlign: 'right',
    Typ: 'N',
    NumberFormat: '2'
  }
];

const DCMarginUpdate = () => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const fromDtRef = useRef(null);
  const toDTRef = useRef(null);
  const rdxuser = useSelector((state) => state.SliceDB.value.login);
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);

  const [headObj, setHeadObj] = useState({
    fromDt: moment().startOf('month'),
    toDT: new Date(),
    locCode: '',
    locName: '',
    // allowDCPurchase: false,
    allowCentralPurchase: false,
    location: []
  });
  const [showMarginModal, setShowMarginModal] = useState(false);
  const [loadSummaryRow, setLoadSummaryRow] = useState([]);
  const [loadSummaryCol, setLoadSummaryCol] = useState(loadSummaryColSample);
  const [loadDetailRow, setLoadDetailRow] = useState([]);
  const [loadDetailCol, setLoadDetailCol] = useState(loadDetailColSample);
  const [selectedRow, setSelectedRow] = useState([
    {
      vocTyp: '0',
      vocName: '',
      vocNo: '0',
      vocDt: new Date(),
      locName: '',
      locCode: '0',
      docStatus: '',
      type: ''
    }
  ]);

  const onChangeFromDate = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        fromDt: newValue.$d
      }));
    }
  };

  const onChangeDateTo = (newValue) => {
    if (
      newValue?.$d == 'Invalid Date' ||
      newValue?.$d == null ||
      newValue?.$d === undefined ||
      newValue?.$d === ''
    ) {
      setHeadObj((obj) => ({
        ...obj,
        toDT: ''
      }));
    } else {
      setHeadObj((obj) => ({
        ...obj,
        toDT: newValue.$d
      }));
    }
  };

  const onChangeLoc = (event) => {
    const data = headObj.location.find((x) => x.Name1 === event.target.value);
    setHeadObj((obj) => ({
      ...obj,
      locName: data.Name1,
      locCode: data.UId
    }));
  };

  const onSelectGridItem = async (data) => {
    setShowMarginModal(true);
    setSelectedRow((obj) => ({
      ...obj,
      vocTyp: data?.row?.A_VocTyp,
      vocName: data?.row?.VocName,
      vocNo: data?.row?.A_VocNo,
      vocDt: data?.row?.A_VocDt,
      locName: data?.row?.LocName,
      locCode: data?.row?.A_Loc,
      docStatus: data?.row?.DocStatus,
      type: data?.row?.Type
    }));
    fetchDCMarginRSPAttr(
      data?.row?.A_VocTyp,
      data?.row?.A_VocNo,
      data?.row?.Type,
      data?.row?.DocStatus
    );
    const payload = {
      trnTyp: 'PUCNSLISTDCMRGNRSPWB'
    };
    const result = await getGitterColumns(payload);
    setLoadDetailCol(result?.ResultSet?.columns);
  };

  const fetchDCMarginRSPAttr = async (xvoctype, xvocno, xtype, status) => {
    const obj = {
      v_VocTyp: xvoctype,
      v_VocNo: xvocno,
      v_Type: xtype
    };
    const result = await getDCMarginRSPAttr(obj);
    let response = result?.ResultSet;
    if (status === 'ENTER') {
      response = response?.map((obj) => {
        if (obj?.TRNS_Add_Margin_Base === 0) {
          let additionalMargin = (obj.X_Add_Margin / 100) * obj.TRNS_UnitRate;
          return {
            ...obj,
            TRNS_Add_Margin_Base: Number((additionalMargin / obj.TRN_PACK_QTY).toFixed(3)),
            TRNS_Add_Margin_CTN: Number(additionalMargin.toFixed(3)),
            TRNS_Add_Margin_Net: Number((additionalMargin * obj.TRNS_QTY).toFixed(3)),
            TRNS_TSR_RSP: Number((obj.TRNS_UnitRate + additionalMargin).toFixed(3))
          };
        } else {
          return obj;
        }
      });
    }
    setLoadDetailRow(response);
  };
  console.log(loadDetailRow);
  const onLoadClick = async () => {
    const obj = {
      v_FromDt: moment(headObj.fromDt).format('MM/DD/YYYY'),
      v_ToDt: moment(headObj.toDT).format('MM/DD/YYYY'),
      v_Loc: headObj.locCode,
      v_UserCd: rdxuser.userId,
      // v_DC_Purchase: headObj.allowDCPurchase ? 'Y' : 'N',
      CNTRL_PUR: headObj.allowCentralPurchase ? 'Y' : 'N'
    };

    const result = await getDCMarginLoadDetail(obj);
    setLoadSummaryRow(result?.ResultSet);
  };

  const fetchPost = async (xdata) => {
    const xTransDetail = loadDetailRow.map((x) => {
      const data = {
        v_TRNS_ID: x?.TRNS_ID,
        v_TRNS_SRNO: x?.TRNS_SRNO,
        v_TRNS_Add_Margin_Base: x?.TRNS_Add_Margin_Base,
        v_TRNS_Add_Margin_CTN: x?.TRNS_Add_Margin_CTN,
        v_TRNS_Add_Margin_Net: x?.TRNS_Add_Margin_Net,
        v_TRNS_TSR_RSP: x?.TRNS_TSR_RSP
        // TRNS_ITEM_NAME: x?.ItmName
      };
      return data;
    });
    const obj = {
      C_VocTyp: xdata?.vocTyp,
      C_VocNo: xdata?.vocNo,
      C_TRNSID: 'All',
      v_UserCd: rdxuser?.userId,
      v_Typ: xdata?.type,
      TransDetail: xTransDetail
    };
    const result = await getDCMarginRSPPost(obj);

    if (result?.Type === 'Success') {
      setShowMarginModal(false);
      onLoadClick();
    }
  };
  //Attributes API call
  useEffect(() => {
    const fDCMarginAttr = async () => {
      const obj = {
        userCd: rdxuser.userId
      };
      const result = await getDCMarginAttr(obj);
      setHeadObj((obj) => ({
        ...obj,
        locCode: result?.ResultSet?.loc[0]?.UId,
        locName: result?.ResultSet?.loc[0]?.Name1,
        location: result?.ResultSet?.loc
      }));
    };
    const fDCMarginSummaryGitter = async () => {
      const payload = {
        trnTyp: 'PUCNSLISTDCMRGNWB'
      };
      const result = await getGitterColumns(payload);
      setLoadSummaryCol(result?.ResultSet?.columns);
    };
    fDCMarginAttr();
    fDCMarginSummaryGitter();
  }, []);

  return (
    <div>
      <div>
        <ToastContainer />
      </div>
      <div>
        <DCMarginBreadCrumbs />
      </div>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Grid item xs={12} sm={5} md={3} lg={3} sx={{ paddingX: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <CmpTypographyField xcomponent={'span'} xText={'Location'} xVariant={'subtitle'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              sx={{
                '& .MuiMenu-list': {
                  height: '15rem !important'
                }
              }}>
              <CmpSelectArrField
                xLabelText={'Location'}
                xValue={headObj.locName}
                xOnChange={onChangeLoc}
                xName={'Location'}
                xData={headObj?.location?.map((x) => x.Name1)}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={''}
                xDisable={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} md={5} lg={5} sx={{ padding: '5px' }}>
          <Box
            style={
              vMediaQuery
                ? {}
                : {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }
            }>
            <Grid container>
              <Grid
                item
                xs={3}
                sm={4}
                md={4}
                lg={4}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} xVariant={'subtitle'} />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8} sm={7} md={7} lg={7} sx={vMediaQuery ? {} : gridItemSpacing}>
                <CmpDatePickerField
                  xValue={headObj.fromDt}
                  xOnChange={(event) => onChangeFromDate(event)}
                  xName={'fromDt'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'1'}
                  xref={fromDtRef}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                sm={4}
                md={4}
                lg={4}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} xVariant={'subtitle'} />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={vMediaQuery ? alignContent : gridItemSpacing}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8} sm={7} md={7} lg={7} sx={vMediaQuery ? {} : gridItemSpacing}>
                <CmpDatePickerField
                  xValue={headObj.toDT}
                  xOnChange={(event) => onChangeDateTo(event)}
                  xName={'toDT'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'2'}
                  xref={toDTRef}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ padding: '5px' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: vMediaQuery ? 'center' : 'end',
              alignItems: 'center'
            }}>
            {/* <Grid item xs={3} sm={3} md={3} lg={3} sx={{ px: '10px' }}>
              <CmpCheckboxField
                xChecked={headObj.allowDCPurchase}
                xOnChange={() => {
                  setHeadObj((obj) => ({
                    ...obj,
                    allowDCPurchase: !headObj.allowDCPurchase
                  }));
                }}
                // xDisabled={!headObj.isDcPurchaseEnabled}
                xDisabled={false}
                xName={'DC Purchase'}
                xNameField={'DC Purchase'}
                xOnkeyUp={''}
                xTabIndex=""
                xref=""
              />
            </Grid> */}
            {cPurchaseFlg && (
              <Grid item xs={4} sm={4} md={4} lg={4} sx={{ px: '10px' }}>
                <CmpCheckboxField
                  xChecked={headObj.allowCentralPurchase}
                  xOnChange={() => {
                    setHeadObj((obj) => ({
                      ...obj,
                      allowCentralPurchase: !headObj.allowCentralPurchase
                    }));
                  }}
                  // xDisabled={!headObj.isDcPurchaseEnabled}
                  xDisabled={false}
                  xName={'Central Purchase'}
                  xNameField={'Central Purchase'}
                  xOnkeyUp={''}
                  xTabIndex=""
                  xref=""
                />
              </Grid>
            )}

            <Grid item xs={4} sm={2} md={3} lg={3} sx={{ px: '10px' }}>
              <CmpButtonWithIcon
                xvariant={'contained'}
                xsize={'medium'}
                xonClick={onLoadClick}
                xLabel={'Load'}
                xIcon={''}
                xDisable={false}
              />
            </Grid>
            <Grid item xs={4} sm={2} md={3} lg={3} sx={{ px: '10px' }}>
              <Button
                variant={'contained'}
                disabled={false}
                size={'medium'}
                onClick={() => {}}
                className="btn-text-transform-none inputfont mui-btn-wt-icon"
                startIcon={''}>
                <CSVLink
                  data={loadSummaryRow}
                  filename={'Dc_Margin.csv'}
                  style={{ color: 'white', textDecoration: 'none' }}>
                  Export
                </CSVLink>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} sm={12} md={12} lg={12} sx={borderBox}>
          <MUIDataGrid
            xRows={loadSummaryRow}
            xColumns={loadSummaryCol}
            xonRowClick={onSelectGridItem}
            enableCheckbox={false}
            borderColorChange={true}
          />
        </Grid>
      </Grid>
      <DCMarginPosting
        xOpen={showMarginModal}
        xTitle={'DC Margin Update - Posting'}
        fCloseModal={() => setShowMarginModal(false)}
        xPostedRowInfo={selectedRow}
        xLoadDetailRow={loadDetailRow}
        xLoadDetailCol={loadDetailCol}
        xfetchPost={fetchPost}
        setLoadDetailRow={setLoadDetailRow}
      />
    </div>
  );
};
export default DCMarginUpdate;
