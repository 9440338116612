import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  head: {
    location: '',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date()
  },
  detail: {
    A_Vendor: '',
    Vendorname: '',
    DCLoc: '',
    DCLocName: '',
    A_DispatchDt: '',
    DispatchCount: '',
    DispatchValue: '',
    A_TransDt: new Date()
  }
};

const BatchCreationSlice = createSlice({
  name: 'BatchCreationSlice',
  initialState: { value: initialState },

  reducers: {
    BatchCreationHead: (state, action) => {
      state.value.head = action.payload;
    },
    setBatchCreationDetail: (state, action) => {
      state.value.detail = action.payload;
    }
  }
});

export const { BatchCreationHead, setBatchCreationDetail } = BatchCreationSlice.actions;
export default BatchCreationSlice.reducer;
