import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CmpTypographyField } from '../../../component/ToolBox/ToolBox';

function BatchCreationSummaryCard({
  xVendor,
  xNo_Dispatch,
  xValue_Dispatched,
  xDCLocation,
  xDispatched_Date
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid
        container
        spacing={0.5}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={3.5}>
              <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7} className="txt-overflow-ellipse">
              <CmpTypographyField xcomponent={'span'} xText={xVendor} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={3.5}>
              <CmpTypographyField xcomponent={'span'} xText={'No of Dispatch'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xNo_Dispatch} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={3.5}>
              <CmpTypographyField xcomponent={'span'} xText={'Value of Dispatch'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xValue_Dispatched} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={3.5}>
              <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xDCLocation} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={5} md={3.5}>
              <CmpTypographyField xcomponent={'span'} xText={'Dispatch Date'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={6} md={7}>
              <CmpTypographyField xcomponent={'span'} xText={xDispatched_Date} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default BatchCreationSummaryCard;
