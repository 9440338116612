import React from 'react';
import { Link, useLocation } from 'react-router-dom';
export default function RequestBreadCrumbs() {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 breadcrumb">
            {vPathName === '/PurchaseRequestSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive">Request Summary{' '}</span>
                </div>
            ) : vPathName === '/PurchaseRequestDetail001' ? (
                <div className="d-flex">
                    <Link
                        to="/PurchaseRequestSummary"
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        Request Summary
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Request Detail{' '}</span>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
