import { httpRequest } from './Axios';

export const GET_API_LOGIN = (obj) => {
  return {
    method: 'POST',
    url: `users/login`,
    data: obj
  };
};
export const GET_API_KERN = (obj) => {
  return {
    method: 'POST',
    url: `users/kern`,
    data: obj
  };
};

export const GET_BUYER_ACCESS = (obj) => {
  return {
    method: 'POST',
    url: `users/buyerAccess`,
    data: obj
  };
};
export const FP_USER_RIGHTS = (obj) => {
  return {
    method: 'POST',
    url: `users/FpUserRights`,
    data: obj
  };
};

// Return Request Summary
export const GET_RETURN_REQUEST_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnRequestSummary`,
    data: obj
  };
};

// Return Request Detail
export const GET_RETURN_REQUEST_ITEM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnRequestItemList`,
    data: obj
  };
};

// Return Request Detail Save
export const GET_RETURN_REQUEST_SAVE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/UpdateReturnRequest`,
    data: obj
  };
};

// Return Request Detail Save item
export const GET_RETURN_REQUEST_SAVE_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/UpdateReturnRequestItems`,
    data: obj
  };
};

// Return Request Detail Delete request
export const GET_RETURN_REQUEST_DELETE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/DeleteReturnRequest`,
    data: obj
  };
};

// Return Request Detail Delete item
export const GET_RETURN_REQUEST_DELETE_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/DeleteReturnItems`,
    data: obj
  };
};

// Return Request Reason
export const GET_RETURN_REQUEST_REASON = (obj) => {
  return {
    method: 'GET',
    url: `FRReturn/ReturnRequestReasons`,
    data: obj
  };
};

// Return Request Detail Post item
export const GET_RETURN_REQUEST_POST_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PostReturnRequest`,
    data: obj
  };
};

// Return Request Search Exact item
export const GET_RETURN_REQUEST_EXACT_SEARCH_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/SearchExactItem`,
    data: obj
  };
};

// Return Request Search item
export const GET_RETURN_REQUEST_SEARCH_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/SearchItem`,
    data: obj
  };
};

// Return Request item Unit
export const GET_RETURN_REQUEST_ITEM_UNIT = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ItemUnits`,
    data: obj
  };
};

// Return Request Review Locations
export const GET_RETURN_REQUEST_REVIEW_LOCATIONS = (obj) => {
  return {
    method: 'GET',
    url: `FRReturn/ReturnRequestLocations`,
    data: obj
  };
};

// Return Request Review Summary
export const GET_RETURN_REQUEST_REVIEW_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnReviewSummary`,
    data: obj
  };
};

// Return Request Review Item List
export const GET_RETURN_REQUEST_REVIEW_ITEM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnReviewItemList`,
    data: obj
  };
};

// Return Request Review Detail Save
export const GET_RETURN_REQUEST_REVIEW_DETAIL_SAVE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/UpdateReturnReview`,
    data: obj
  };
};

// Return Request Review Detail Post
export const GET_RETURN_REQUEST_REVIEW_DETAIL_POST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PostReturnReview`,
    data: obj
  };
};

// Return Request Review Vendor List
export const GET_VENDOR_LIST = (obj) => {
  return {
    method: 'GET',
    url: `FRReturn/VendorList`,
    data: obj
  };
};

// Return Request Review Vendor List
export const GET_TRUCK_LIST = (obj) => {
  return {
    method: 'GET',
    url: `freshpurchase/getTruckList`,
    data: obj
  };
};

// Return Request Reverse
export const GET_REVERSE_RETURN_REQUEST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReverseReturnRequest`,
    data: obj
  };
};

// Return Request Review Reverse
export const GET_REVERSE_RETURN_REQUEST_REVIEW = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReverseReturnReview`,
    data: obj
  };
};

// Picking Location
export const GET_PICK_LOCATION = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PickingSummaryLocList`,
    data: obj
  };
};

// Picking Summary List
export const GET_PICK_SUMMARY_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PickingSummary`,
    data: obj
  };
};

// Picking Detail Item List
export const GET_PICK_DETAIL_ITEM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PickingItemList`,
    data: obj
  };
};

// Picking Update Item
export const UPDATE_PICK_DETAIL_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PickingUpdate`,
    data: obj
  };
};

// Picking Post Item
export const POST_PICK_DETAIL_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PickingUpdate`,
    data: obj
  };
};

// Picking Reverese Item
export const REVERSE_PICK_DETAIL_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReversePicking`,
    data: obj
  };
};

// Dispatch Summary List
export const GET_DISPATCH_SUMMARY_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/DispatchSummary`,
    data: obj
  };
};

// Picking Update Item
export const UPDATE_DISPATCH_DETAIL_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/DispatchUpdate`,
    data: obj
  };
};

// Picking Reverese Item
export const REVERSE_DISPATCH_DETAIL_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReverseDispatch`,
    data: obj
  };
};

// DispatchPurchase Return List
export const GET_PURCHASE_RETURN_SUMMARY_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PurchaseReturnSummary`,
    data: obj
  };
};

// DispatchPurchase Return Item List
export const GET_PURCHASE_RETURN_ITEM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PurchaseReturnItems`,
    data: obj
  };
};

// DispatchPurchase Return Item List
export const UPDATE_PURCHASE_RETURN_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/UpdatePurchaseReturn`,
    data: obj
  };
};

// get Tax Dept List
export const GET_TAX_DEPT_LIST = (obj) => {
  return {
    method: 'GET',
    url: `freshpurchase/getTaxDepartment`,
    data: obj
  };
};

// DC Confirmation Summary List
export const GET_DC_CONFIRMATION_SUMMARY_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/DCConfirmationSummary`,
    data: obj
  };
};

// DC Confirmation Item List
export const GET_DC_CONFIRMATION_ITEM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/DCConfirmationItems`,
    data: obj
  };
};

// DC Confirmation Item Update
export const UPDATE_DC_CONFIRMATION_ITEM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/UpdateDCConfirmation`,
    data: obj
  };
};

// Pending vendor list for batch Creation
export const GET_PENDING_FOR_BATCH_CREATION = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PendingForBatchCreation`,
    data: obj
  };
};

export const GET_PENDING_TRANS_FOR_BATCH_CREATION = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PendingTransForBatchCreation`,
    data: obj
  };
};

export const CREATE_BATCH = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/CreateBatch`,
    data: obj
  };
};

export const PENDING_RETURN_REQUEST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/PendingReturnRequest`,
    data: obj
  };
};

export const CREATE_REVIEW_BATCH = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/CreateReviewBatch`,
    data: obj
  };
};

export const RETURN_REVIEW_BATCH_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnReviewBatchSummary`,
    data: obj
  };
};

export const RETURN_REVIEW_BATCH_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnReviewBatchDetail`,
    data: obj
  };
};

export const RETURN_REVIEW_BATCH_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnReviewBatchItemDetail`,
    data: obj
  };
};

export const UPDATE_RETURN_REVIEW_BATCH_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/UpdateReturnReviewBatchItems`,
    data: obj
  };
};

export const POST_RETURN_REVIEW_BATCH = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/postReturnReviewBatch`,
    data: obj
  };
};

export const GET_RETURN_SUMMARY_REPORT = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getReturnRequestSummaryReport`,
    data: obj
  };
};

export const FP_ORDER_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `freshpurchase/getOrderSummary001`,
    data: obj
  };
};

export const FP_ORDER_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `freshpurchase/getOrderItems`,
    data: obj
  };
};

export const FP_ORDER_ITEMS_REQ_LOC = (obj) => {
  return {
    method: 'POST',
    url: `freshpurchase/getItemRequestLocation`,
    data: obj
  };
};

export const FP_VENDOR_PRICE_ENQUIRY = (obj) => {
  return {
    method: 'POST',
    url: `freshpurchase/VendorPriceEnquiry`,
    data: obj
  };
};

export const FP_ORDER_DETAILS = (obj) => {
  return {
    method: 'POST',
    url: `freshpurchase/getOrderDetails`,
    data: obj
  };
};
export const FP_POST_ORDER = (obj) => {
  return {
    method: 'POST',
    url: `freshpurchase/postPurchaseOrder`,
    data: obj
  };
};

export const GET_KERN = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getKernValue`,
    data: obj
  };
};

export const FP_TRANS_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getPurchaseTransSummary`,
    data: obj
  };
};
export const FP_TRANS_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getPurchaseTransItems`,
    data: obj
  };
};
export const GET_VENDOR_OS_AMT = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getVendorOSAmt`,
    data: obj
  };
};

export const GET_VENDOR_OS_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getVendorOSDetail`,
    data: obj
  };
};

export const SAVE_PURCHASE_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/savePurchaseTransaction`,
    data: obj
  };
};

export const DELETE_PURCHASE_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/deletePurchaseTransaction`,
    data: obj
  };
};

export const POST_PURCHASE_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/postPurchaseTransaction`,
    data: obj
  };
};

export const REVERSE_PURCHASE_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/reversePurchaseTransaction`,
    data: obj
  };
};

export const SRCH_ADDITIONAL_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/searchAdditionalItems`,
    data: obj
  };
};
export const FP_VAN_TRANS_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getVanTrasactionSummary`,
    data: obj
  };
};
export const FP_VAN_TRANS_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getVanTrasactionItems`,
    data: obj
  };
};
export const FP_VAN_RECEIVED_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getVanReceivedItems`,
    data: obj
  };
};

export const FP_SAVE_RECEIVED_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/saveVanReceivedItems`,
    data: obj
  };
};

export const FP_DELETE_RECEIVED_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/deleteVanReceivedItems`,
    data: obj
  };
};

export const FP_POST_VAN_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/postVanReceivingTrans`,
    data: obj
  };
};

export const FP_REVERSE_VAN_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/reverseVanReceivingTrans`,
    data: obj
  };
};
export const FP_BUFFER_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getPurchaseTransBufferItems`,
    data: obj
  };
};
export const FP_UPDATE_BUFFER_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/updateBufferTransaction`,
    data: obj
  };
};
export const FP_CONFIRM_BUFFER_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/confirmBufferTransaction`,
    data: obj
  };
};

export const FP_TSR_PACKQTY_GETROW = (obj) => {
  return {
    method: 'POST',
    url: 'FreshPurchaseWeb/tsrPackQty',
    data: obj
  };
};
//FRReturn/getDepartmentList changed to freshpurchase/getDepartmentList

export const GET_DEPT_LIST = (obj) => {
  return {
    method: 'GET',
    url: `freshpurchase/getDepartmentList`,
    data: obj
  };
};
export const GET_DEPT_LIST_OUTLET = (obj) => {
  return {
    method: 'GET',
    url: `freshpurchase/getDepartmentListOutlet`,
    data: obj
  };
};
export const GET_SUBDEPT_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getSubDepartmentList`,
    data: obj
  };
};
export const GET_CATOGORY_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getCategoryList`,
    data: obj
  };
};
export const GET_RETURN_REPORT_ITMWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getItemwiseMISReport`,
    data: obj
  };
};

export const GET_RETURN_REPORT_DATEWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getDatewiseMISReport`,
    data: obj
  };
};
export const GET_RETURN_REPORT_DOCWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getDocumentwiseMISReport`,
    data: obj
  };
};

export const GET_RETURN_REPORT_DEPTWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getDepartmentwiseMISReport`,
    data: obj
  };
};
export const GET_RETURN_REPORT_SUBDEPTWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getSubDeptwiseMISReport`,
    data: obj
  };
};
export const GET_RETURN_REPORT_CATWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getCategorywiseMISReport`,
    data: obj
  };
};

export const GET_RETURN_REPORT_LOCWISE = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/getLocationwiseMISReport`,
    data: obj
  };
};

export const GET_OUTLET_RQT_REPORT_ITMWISE = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getItemwiseOutletRequestReport`,
    data: obj
  };
};

export const GET_OUTLET_RQT_REPORT_LOCWISE = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/getLocwiseOutletRequestReport`,
    data: obj
  };
};
export const SEARCH_VENDOR = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/searchVendor`,
    data: obj
  };
};
export const PRICE_ENQUIRY_TRANS_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `PriceEnquiry/getPriceEnquiryTransSummary`,
    data: obj
  };
};
export const PRICE_ENQUIRY_TRANS_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: `PriceEnquiry/getPriceEnquiryTransDetail`,
    data: obj
  };
};
export const SAVE_ENQUIRY_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `PriceEnquiry/savePriceEnquiryTrans`,
    data: obj
  };
};
export const DELETE_ENQUIRY_TRANS_ITEM = (obj) => {
  return {
    method: 'POST',
    url: `PriceEnquiry/deletePriceEnquiryItem`,
    data: obj
  };
};

export const PRICE_ENQUIRY_DEFAULT_VALUES = (obj) => {
  return {
    method: 'GET',
    url: `PriceEnquiry/getDefaultVoucherType`
  };
};

export const GET_FP_RQST_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/getRequestSummary`,
    data: obj
  };
};

export const GET_FP_RQST_VOUCHERS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/getVoucherList`,
    data: obj
  };
};

export const GET_FP_DEPT_LIST = (obj) => {
  return {
    method: 'GET',
    url: `FreshPurchaseRequest/getFPDepartmentList`
  };
};

export const GET_FP_RQST_DAYS_LIST = (obj) => {
  return {
    method: 'GET',
    url: `FreshPurchaseRequest/getDaysList`
  };
};
export const GET_FP_RQST_ITM_LIST = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/getItemList`,
    data: obj
  };
};

export const UPDATE_FP_RQT_HEAD = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/updatePurchaseRequest`,
    data: obj
  };
};
export const GENERT_FP_RQT_ITM = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/GenerateConsumptionDetails`,
    data: obj
  };
};
export const UPDATE_FP_RQT_ITM = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/updatePurchaseRequestItems`,
    data: obj
  };
};
export const RELEASE_FP_RQT_ITM = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/releasePurchaseRequestItems`,
    data: obj
  };
};
export const FP_RQT_VALIDT_HEAD = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/validateHead`,
    data: obj
  };
};
export const POST_FP_RQT_ITM = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/postPurchaseRequest`,
    data: obj
  };
};
export const DELETE_FP_RQT_HEAD = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/deleteHead`,
    data: obj
  };
};
export const DELETE_FP_RQT_ITM = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/deleteItem`,
    data: obj
  };
};
export const GET_FP_RQT_DOC_RIGHTS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/getDocumentRights`,
    data: obj
  };
};
export const REVERSE_FP_RQT = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/reverseFPRequest`,
    data: obj
  };
};
export const REVERSE_CONFIRMED_TRANS = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseWeb/reverseConfirmedTransaction`,
    data: obj
  };
};
export const FP_RQT_AUDIT_FLD = (obj) => {
  return {
    method: 'GET',
    url: `FreshPurchaseRequest/getAuditFields`
  };
};
export const FP_RQT_AUDIT_VALUES = (obj) => {
  return {
    method: 'POST',
    url: `FreshPurchaseRequest/getAuditValues`,
    data: obj
  };
};
export const FP_CONS_ATTRIBUTES = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsAttributes',
    data: obj
  };
};
export const FP_CONS_SAVE_HEAD = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsSaveHead',
    data: obj
  };
};
export const FP_CONS_REQUEST_DOCS = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsRequestDocs',
    data: obj
  };
};
export const FP_CONS_LOAD_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsLoadDetail',
    data: obj
  };
};
export const FP_CONS_RELEASE = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPConsRelease`,
    data: obj
  };
};
export const FP_CONS_DISPLAY = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPConsDisplay`,
    data: obj
  };
};
export const FP_CONS_REVERSE = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPconsReverse`,
    data: obj
  };
};

export const FP_CONS_DELETE = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPconsDelete`,
    data: obj
  };
};

export const FP_CONS_APPROVE = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPconsApprove`,
    data: obj
  };
};
export const FP_CONS_SEARCH = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPConsSearch`,
    data: obj
  };
};

export const GET_RQST_TSR_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `RequestTransfer/getTransferSummary`,
    data: obj
  };
};
export const GET_RQST_TSR_ITMLIST = (obj) => {
  return {
    method: 'POST',
    url: `RequestTransfer/getTransferItems`,
    data: obj
  };
};
export const SAVE_RQST_TSR_ITMS = (obj) => {
  return {
    method: 'POST',
    url: `RequestTransfer/saveTransferItems`,
    data: obj
  };
};
export const POST_RQST_TSR = (obj) => {
  return {
    method: 'POST',
    url: `RequestTransfer/postTransfer`,
    data: obj
  };
};
export const GET_RQST_TSR_UNIT_DETAILS = (obj) => {
  return {
    method: 'POST',
    url: `RequestTransfer/getItemsUnitDetails`,
    data: obj
  };
};
export const GET_FPCONS_POST_ATTR = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPConsSumAtributs`,
    data: obj
  };
};

export const GET_FPCONS_POST_LOAD_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: `FPCons/FPConsSumLoadDetail`,
    data: obj
  };
};
export const GET_GITTER_COLUMNS = (obj) => {
  return {
    method: 'POST',
    url: `general/getGitterColumns`,
    data: obj
  };
};
export const GET_FPCONS_APP_ATTR = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsAppAtributs',
    data: obj
  };
};

export const GET_FPCONS_APP_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsAppDetail',
    data: obj
  };
};

export const GET_FPCONS_POST = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPConsPost',
    data: obj
  };
};
export const GET_FP_DCMAR_ATTR = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPDCMarginAttribute',
    data: obj
  };
};
export const GET_FP_DCMAR_LOAD_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPDCMarginLoadDetail',
    data: obj
  };
};
export const GET_FP_DCMAR_RSP_POST = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPDCMarginRSPPost',
    data: obj
  };
};
export const GET_DCMAR_RSP_ATTR = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPDCMarginRSPAttribute',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_ATTR = () => {
  return {
    method: 'POST',
    url: 'FPCons/FPUnloadingBatchAttribute'
  };
};
export const GET_UNLOAD_BATCH_DETAIL = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPUnloadingBatchDetail',
    data: obj
  };
};
export const GET_BATCH_CREATE = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPCreateBatch',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnLoadingBatchSummary',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_SUMMARY_ATTR = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnloadingBatchSummaryAttributes',
    data: obj
  };
};

export const GET_UNLOAD_BATCH_DETAIL_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnLoadingBatchDetailSummary',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_DETAIL_APPR = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnloadingBatchDetailApprove',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_DETAIL_SAVE = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnLoadingBatchDetailSave',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_DETAIL_DEL = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnloadingBatchDetailDelete',
    data: obj
  };
};
export const GET_UNLOAD_BATCH_DETAIL_GRID = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/UnloadingBatchDetailGrid',
    data: obj
  };
};
export const GET_MISSING_BRANCH = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/FPMissingBranch',
    data: obj
  };
};
export const GET_STK_TRANS_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: 'FreshPurchaseWeb/getTransferItems',
    data: obj
  };
};
export const GET_STK_TRANS_ITEM_SEARCH = (obj) => {
  return {
    method: 'POST',
    url: 'FreshPurchaseWeb/FPStockTRansferItemSearch',
    data: obj
  };
};

export const DELETE_STK_TRANS_ITEM = (obj) => {
  return {
    method: 'POST',
    url: 'RequestTransfer/deleteTransferItems',
    data: obj
  };
};

export const GET_MENUS = (obj) => {
  return {
    method: 'POST',
    url: 'RequestTransfer/menu',
    data: obj
  };
};

export const EDIT_CONFORM_TRANSACTION = (obj) => {
  return {
    method: 'POST',
    url: 'FreshPurchaseWeb/EditConfirmTransaction',
    data: obj
  };
};

export const GET_CONSOLIDATE_LOCITEMS = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/LoadConsLocItems',
    data: obj
  };
};

export const SAVE_CONSOLIDATE_LOCITEMS = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/SaveConsLocItems',
    data: obj
  };
};

// Return Request DCReceipt Summary
export const GET_ReturnRequest_DCReceipt_SUMMARY = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnRequestSummaryDC`,
    data: obj
  };
};
// Return Request DCReceipt Detail
export const GET_ReturnRequest_DCReceipt_Detail = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnRequestItemGetrowDC`,
    data: obj
  };
};

export const Return_Request_ItemUpdate = (obj) => {
  return {
    method: 'POST',
    url: `FRReturn/ReturnRequestItemUpdate`,
    data: obj
  };
};

export const GET_UNLOAD_BATCH_ITEM_PURDETAIL = (obj) => {
  return {
    method: 'POST',
    url: 'FPCons/VanUnloadingBatchItemPurDetail',
    data: obj
  };
};

// seperate page as Notion
export const ITEM_SEARCH = (obj) => {
  return {
    method: 'POST',
    url: 'bakeryPreBooking/searchBKOrderItems',
    data: obj
  };
};

export const UPDATE_FLYER_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: 'bakeryPreBooking/updateFlyerItems',
    data: obj
  };
};

export const GET_FLYER_UNIT = (obj) => {
  return {
    method: 'POST',
    url: 'bakeryPreBooking/NotionUom',
    data: obj
  };
};

export const DELETE_LINEITEMS = (obj) => {
  return {
    method: 'POST',
    url: 'bakeryPreBooking/deleteNotionFlyerItem',
    data: obj
  };
};

export const GET_NOTION_FLYER_ITEMS = (obj) => {
  return {
    method: 'POST',
    url: 'bakeryPreBooking/getNotionFlyerItems',
    data: obj
  };
};

export const NOTION_FLYER_APPROVE = (obj) => {
  return {
    method: 'POST',
    url: 'bakeryPreBooking/NotionFlyer_Approve',
    data: obj
  };
};

export const fExecuteAPI = async (xAPIName, apiObj = {}) => {
  try {
    var resp = {};
    switch (xAPIName) {
      case 'GET_RETURN_REQUEST_SUMMARY': {
        resp = await httpRequest(GET_RETURN_REQUEST_SUMMARY(apiObj));
        break;
      }

      case 'GET_API_LOGIN': {
        resp = await httpRequest(GET_API_LOGIN(apiObj));
        break;
      }
      case 'GET_API_KERN': {
        resp = await httpRequest(GET_API_KERN(apiObj));
        break;
      }

      case 'GET_BUYER_ACCESS': {
        resp = await httpRequest(GET_BUYER_ACCESS(apiObj));
        break;
      }
      case 'FP_USER_RIGHTS': {
        resp = await httpRequest(FP_USER_RIGHTS(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_ITEM_LIST': {
        resp = await httpRequest(GET_RETURN_REQUEST_ITEM_LIST(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_SAVE': {
        resp = await httpRequest(GET_RETURN_REQUEST_SAVE(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_SAVE_ITEM': {
        resp = await httpRequest(GET_RETURN_REQUEST_SAVE_ITEM(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_DELETE': {
        resp = await httpRequest(GET_RETURN_REQUEST_DELETE(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_DELETE_ITEM': {
        resp = await httpRequest(GET_RETURN_REQUEST_DELETE_ITEM(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_POST_ITEM': {
        resp = await httpRequest(GET_RETURN_REQUEST_POST_ITEM(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_REASON': {
        resp = await httpRequest(GET_RETURN_REQUEST_REASON(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_EXACT_SEARCH_ITEM': {
        resp = await httpRequest(GET_RETURN_REQUEST_EXACT_SEARCH_ITEM(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_SEARCH_ITEM': {
        resp = await httpRequest(GET_RETURN_REQUEST_SEARCH_ITEM(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_ITEM_UNIT': {
        resp = await httpRequest(GET_RETURN_REQUEST_ITEM_UNIT(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_REVIEW_LOCATIONS': {
        resp = await httpRequest(GET_RETURN_REQUEST_REVIEW_LOCATIONS(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_REVIEW_SUMMARY': {
        resp = await httpRequest(GET_RETURN_REQUEST_REVIEW_SUMMARY(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_REVIEW_ITEM_LIST': {
        resp = await httpRequest(GET_RETURN_REQUEST_REVIEW_ITEM_LIST(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_REVIEW_DETAIL_SAVE': {
        resp = await httpRequest(GET_RETURN_REQUEST_REVIEW_DETAIL_SAVE(apiObj));
        break;
      }

      case 'GET_RETURN_REQUEST_REVIEW_DETAIL_POST': {
        resp = await httpRequest(GET_RETURN_REQUEST_REVIEW_DETAIL_POST(apiObj));
        break;
      }

      case 'GET_VENDOR_LIST': {
        resp = await httpRequest(GET_VENDOR_LIST(apiObj));
        break;
      }

      case 'GET_TRUCK_LIST': {
        resp = await httpRequest(GET_TRUCK_LIST(apiObj));
        break;
      }

      case 'GET_REVERSE_RETURN_REQUEST': {
        resp = await httpRequest(GET_REVERSE_RETURN_REQUEST(apiObj));
        break;
      }

      case 'GET_REVERSE_RETURN_REQUEST_REVIEW': {
        resp = await httpRequest(GET_REVERSE_RETURN_REQUEST_REVIEW(apiObj));
        break;
      }

      case 'GET_PICK_LOCATION': {
        resp = await httpRequest(GET_PICK_LOCATION(apiObj));
        break;
      }

      case 'GET_PICK_SUMMARY_LIST': {
        resp = await httpRequest(GET_PICK_SUMMARY_LIST(apiObj));
        break;
      }

      case 'GET_PICK_DETAIL_ITEM_LIST': {
        resp = await httpRequest(GET_PICK_DETAIL_ITEM_LIST(apiObj));
        break;
      }

      case 'UPDATE_PICK_DETAIL_ITEM': {
        resp = await httpRequest(UPDATE_PICK_DETAIL_ITEM(apiObj));
        break;
      }

      case 'POST_PICK_DETAIL_ITEM': {
        resp = await httpRequest(POST_PICK_DETAIL_ITEM(apiObj));
        break;
      }

      case 'REVERSE_PICK_DETAIL_ITEM': {
        resp = await httpRequest(REVERSE_PICK_DETAIL_ITEM(apiObj));
        break;
      }

      case 'GET_DISPATCH_SUMMARY_LIST': {
        resp = await httpRequest(GET_DISPATCH_SUMMARY_LIST(apiObj));
        break;
      }

      case 'UPDATE_DISPATCH_DETAIL_ITEM': {
        resp = await httpRequest(UPDATE_DISPATCH_DETAIL_ITEM(apiObj));
        break;
      }

      case 'REVERSE_DISPATCH_DETAIL_ITEM': {
        resp = await httpRequest(REVERSE_DISPATCH_DETAIL_ITEM(apiObj));
        break;
      }

      case 'GET_PURCHASE_RETURN_SUMMARY_LIST': {
        resp = await httpRequest(GET_PURCHASE_RETURN_SUMMARY_LIST(apiObj));
        break;
      }

      case 'GET_PURCHASE_RETURN_ITEM_LIST': {
        resp = await httpRequest(GET_PURCHASE_RETURN_ITEM_LIST(apiObj));
        break;
      }

      case 'UPDATE_PURCHASE_RETURN_ITEM': {
        resp = await httpRequest(UPDATE_PURCHASE_RETURN_ITEM(apiObj));
        break;
      }

      case 'GET_TAX_DEPT_LIST': {
        resp = await httpRequest(GET_TAX_DEPT_LIST(apiObj));
        break;
      }

      case 'GET_DC_CONFIRMATION_SUMMARY_LIST': {
        resp = await httpRequest(GET_DC_CONFIRMATION_SUMMARY_LIST(apiObj));
        break;
      }

      case 'GET_DC_CONFIRMATION_ITEM_LIST': {
        resp = await httpRequest(GET_DC_CONFIRMATION_ITEM_LIST(apiObj));
        break;
      }

      case 'UPDATE_DC_CONFIRMATION_ITEM_LIST': {
        resp = await httpRequest(UPDATE_DC_CONFIRMATION_ITEM_LIST(apiObj));
        break;
      }

      case 'GET_PENDING_FOR_BATCH_CREATION': {
        resp = await httpRequest(GET_PENDING_FOR_BATCH_CREATION(apiObj));
        break;
      }

      case 'GET_PENDING_TRANS_FOR_BATCH_CREATION': {
        resp = await httpRequest(GET_PENDING_TRANS_FOR_BATCH_CREATION(apiObj));
        break;
      }

      case 'CREATE_BATCH': {
        resp = await httpRequest(CREATE_BATCH(apiObj));
        break;
      }

      case 'GET_PENDING_RETURN_REQUEST': {
        resp = await httpRequest(PENDING_RETURN_REQUEST(apiObj));
        break;
      }
      case 'CREATE_REVIEW_BATCH': {
        resp = await httpRequest(CREATE_REVIEW_BATCH(apiObj));
        break;
      }
      case 'RETURN_REVIEW_BATCH_SUMMARY': {
        resp = await httpRequest(RETURN_REVIEW_BATCH_SUMMARY(apiObj));
        break;
      }

      case 'RETURN_REVIEW_BATCH_DETAIL': {
        resp = await httpRequest(RETURN_REVIEW_BATCH_DETAIL(apiObj));
        break;
      }

      case 'RETURN_REVIEW_BATCH_ITEM': {
        resp = await httpRequest(RETURN_REVIEW_BATCH_ITEM(apiObj));
        break;
      }
      case 'UPDATE_RETURN_REVIEW_BATCH_ITEM': {
        resp = await httpRequest(UPDATE_RETURN_REVIEW_BATCH_ITEM(apiObj));
        break;
      }

      case 'POST_RETURN_REVIEW_BATCH': {
        resp = await httpRequest(POST_RETURN_REVIEW_BATCH(apiObj));
        break;
      }
      case 'GET_RETURN_SUMMARY_REPORT': {
        resp = await httpRequest(GET_RETURN_SUMMARY_REPORT(apiObj));
        break;
      }
      case 'FP_ORDER_SUMMARY': {
        resp = await httpRequest(FP_ORDER_SUMMARY(apiObj));
        break;
      }
      case 'FP_ORDER_ITEMS': {
        resp = await httpRequest(FP_ORDER_ITEMS(apiObj));
        break;
      }
      case 'FP_ORDER_ITEMS_REQ_LOC': {
        resp = await httpRequest(FP_ORDER_ITEMS_REQ_LOC(apiObj));
        break;
      }
      case 'FP_VENDOR_PRICE_ENQUIRY': {
        resp = await httpRequest(FP_VENDOR_PRICE_ENQUIRY(apiObj));
        break;
      }
      case 'FP_ORDER_DETAILS': {
        resp = await httpRequest(FP_ORDER_DETAILS(apiObj));
        break;
      }
      case 'FP_POST_ORDER': {
        resp = await httpRequest(FP_POST_ORDER(apiObj));
        break;
      }
      case 'GET_KERN': {
        resp = await httpRequest(GET_KERN(apiObj));
        break;
      }
      case 'FP_TRANS_SUMMARY': {
        resp = await httpRequest(FP_TRANS_SUMMARY(apiObj));
        break;
      }
      case 'FP_TRANS_ITEMS': {
        resp = await httpRequest(FP_TRANS_ITEMS(apiObj));
        break;
      }
      case 'GET_VENDOR_OS_AMT': {
        resp = await httpRequest(GET_VENDOR_OS_AMT(apiObj));
        break;
      }

      case 'GET_VENDOR_OS_DETAIL': {
        resp = await httpRequest(GET_VENDOR_OS_DETAIL(apiObj));
        break;
      }

      case 'SAVE_PURCHASE_TRANS': {
        resp = await httpRequest(SAVE_PURCHASE_TRANS(apiObj));
        break;
      }
      case 'DELETE_PURCHASE_TRANS': {
        resp = await httpRequest(DELETE_PURCHASE_TRANS(apiObj));
        break;
      }
      case 'POST_PURCHASE_TRANS': {
        resp = await httpRequest(POST_PURCHASE_TRANS(apiObj));
        break;
      }
      case 'REVERSE_PURCHASE_TRANS': {
        resp = await httpRequest(REVERSE_PURCHASE_TRANS(apiObj));
        break;
      }
      case 'SRCH_ADDITIONAL_ITEM': {
        resp = await httpRequest(SRCH_ADDITIONAL_ITEM(apiObj));
        break;
      }
      case 'FP_VAN_TRANS_SUMMARY': {
        resp = await httpRequest(FP_VAN_TRANS_SUMMARY(apiObj));
        break;
      }

      case 'FP_VAN_TRANS_ITEMS': {
        resp = await httpRequest(FP_VAN_TRANS_ITEMS(apiObj));
        break;
      }
      case 'FP_VAN_RECEIVED_ITEMS': {
        resp = await httpRequest(FP_VAN_RECEIVED_ITEMS(apiObj));
        break;
      }

      case 'FP_SAVE_RECEIVED_ITEMS': {
        resp = await httpRequest(FP_SAVE_RECEIVED_ITEMS(apiObj));
        break;
      }
      case 'FP_DELETE_RECEIVED_ITEMS': {
        resp = await httpRequest(FP_DELETE_RECEIVED_ITEMS(apiObj));
        break;
      }
      case 'FP_POST_VAN_TRANS': {
        resp = await httpRequest(FP_POST_VAN_TRANS(apiObj));
        break;
      }
      case 'FP_REVERSE_VAN_TRANS': {
        resp = await httpRequest(FP_REVERSE_VAN_TRANS(apiObj));
        break;
      }
      case 'FP_BUFFER_ITEMS': {
        resp = await httpRequest(FP_BUFFER_ITEMS(apiObj));
        break;
      }
      case 'FP_UPDATE_BUFFER_TRANS': {
        resp = await httpRequest(FP_UPDATE_BUFFER_TRANS(apiObj));
        break;
      }
      case 'FP_CONFIRM_BUFFER_TRANS': {
        resp = await httpRequest(FP_CONFIRM_BUFFER_TRANS(apiObj));
        break;
      }
      case 'GET_DEPT_LIST': {
        resp = await httpRequest(GET_DEPT_LIST(apiObj));
        break;
      }
      case 'GET_DEPT_LIST_OUTLET': {
        resp = await httpRequest(GET_DEPT_LIST_OUTLET(apiObj));
        break;
      }
      case 'GET_SUBDEPT_LIST': {
        resp = await httpRequest(GET_SUBDEPT_LIST(apiObj));
        break;
      }
      case 'GET_CATOGORY_LIST': {
        resp = await httpRequest(GET_CATOGORY_LIST(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_ITMWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_ITMWISE(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_DATEWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_DATEWISE(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_DOCWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_DOCWISE(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_DEPTWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_DEPTWISE(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_SUBDEPTWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_SUBDEPTWISE(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_CATWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_CATWISE(apiObj));
        break;
      }
      case 'GET_RETURN_REPORT_LOCWISE': {
        resp = await httpRequest(GET_RETURN_REPORT_LOCWISE(apiObj));
        break;
      }
      case 'GET_OUTLET_RQT_REPORT_ITMWISE': {
        resp = await httpRequest(GET_OUTLET_RQT_REPORT_ITMWISE(apiObj));
        break;
      }
      case 'GET_OUTLET_RQT_REPORT_LOCWISE': {
        resp = await httpRequest(GET_OUTLET_RQT_REPORT_LOCWISE(apiObj));
        break;
      }
      case 'SEARCH_VENDOR': {
        resp = await httpRequest(SEARCH_VENDOR(apiObj));
        break;
      }
      case 'PRICE_ENQUIRY_TRANS_SUMMARY': {
        resp = await httpRequest(PRICE_ENQUIRY_TRANS_SUMMARY(apiObj));
        break;
      }
      case 'PRICE_ENQUIRY_TRANS_DETAIL': {
        resp = await httpRequest(PRICE_ENQUIRY_TRANS_DETAIL(apiObj));
        break;
      }
      case 'PRICE_ENQUIRY_DEFAULT_VALUES': {
        resp = await httpRequest(PRICE_ENQUIRY_DEFAULT_VALUES(apiObj));
        break;
      }
      case 'SAVE_ENQUIRY_TRANS': {
        resp = await httpRequest(SAVE_ENQUIRY_TRANS(apiObj));
        break;
      }
      case 'DELETE_ENQUIRY_TRANS_ITEM': {
        resp = await httpRequest(DELETE_ENQUIRY_TRANS_ITEM(apiObj));
        break;
      }
      case 'GET_FP_RQST_SUMMARY': {
        resp = await httpRequest(GET_FP_RQST_SUMMARY(apiObj));
        break;
      }
      case 'GET_FP_RQST_VOUCHERS': {
        resp = await httpRequest(GET_FP_RQST_VOUCHERS(apiObj));
        break;
      }
      case 'GET_FP_DEPT_LIST': {
        resp = await httpRequest(GET_FP_DEPT_LIST(apiObj));
        break;
      }
      case 'GET_FP_RQST_DAYS_LIST': {
        resp = await httpRequest(GET_FP_RQST_DAYS_LIST(apiObj));
        break;
      }
      case 'GET_FP_RQST_ITM_LIST': {
        resp = await httpRequest(GET_FP_RQST_ITM_LIST(apiObj));
        break;
      }
      case 'UPDATE_FP_RQT_HEAD': {
        resp = await httpRequest(UPDATE_FP_RQT_HEAD(apiObj));
        break;
      }
      case 'GENERT_FP_RQT_ITM': {
        resp = await httpRequest(GENERT_FP_RQT_ITM(apiObj));
        break;
      }
      case 'UPDATE_FP_RQT_ITM': {
        resp = await httpRequest(UPDATE_FP_RQT_ITM(apiObj));
        break;
      }
      case 'RELEASE_FP_RQT_ITM': {
        resp = await httpRequest(RELEASE_FP_RQT_ITM(apiObj));
        break;
      }
      case 'FP_RQT_VALIDT_HEAD': {
        resp = await httpRequest(FP_RQT_VALIDT_HEAD(apiObj));
        break;
      }
      case 'POST_FP_RQT_ITM': {
        resp = await httpRequest(POST_FP_RQT_ITM(apiObj));
        break;
      }
      case 'DELETE_FP_RQT_HEAD': {
        resp = await httpRequest(DELETE_FP_RQT_HEAD(apiObj));
        break;
      }
      case 'DELETE_FP_RQT_ITM': {
        resp = await httpRequest(DELETE_FP_RQT_ITM(apiObj));
        break;
      }
      case 'GET_FP_RQT_DOC_RIGHTS': {
        resp = await httpRequest(GET_FP_RQT_DOC_RIGHTS(apiObj));
        break;
      }
      case 'REVERSE_CONFIRMED_TRANS': {
        resp = await httpRequest(REVERSE_CONFIRMED_TRANS(apiObj));
        break;
      }
      case 'REVERSE_FP_RQT': {
        resp = await httpRequest(REVERSE_FP_RQT(apiObj));
        break;
      }
      case 'FP_RQT_AUDIT_FLD': {
        resp = await httpRequest(FP_RQT_AUDIT_FLD(apiObj));
        break;
      }
      case 'FP_RQT_AUDIT_VALUES': {
        resp = await httpRequest(FP_RQT_AUDIT_VALUES(apiObj));
        break;
      }
      case 'FP_CONS_ATTRIBUTES': {
        resp = await httpRequest(FP_CONS_ATTRIBUTES(apiObj));
        break;
      }
      case 'FP_CONS_SAVE_HEAD': {
        resp = await httpRequest(FP_CONS_SAVE_HEAD(apiObj));
        break;
      }
      case 'FP_CONS_REQUEST_DOCS': {
        resp = await httpRequest(FP_CONS_REQUEST_DOCS(apiObj));
        break;
      }
      case 'FP_CONS_LOAD_DETAIL': {
        resp = await httpRequest(FP_CONS_LOAD_DETAIL(apiObj));
        break;
      }
      case 'GET_RQST_TSR_SUMMARY': {
        resp = await httpRequest(GET_RQST_TSR_SUMMARY(apiObj));
        break;
      }
      case 'GET_RQST_TSR_ITMLIST': {
        resp = await httpRequest(GET_RQST_TSR_ITMLIST(apiObj));
        break;
      }
      case 'SAVE_RQST_TSR_ITMS': {
        resp = await httpRequest(SAVE_RQST_TSR_ITMS(apiObj));
        break;
      }
      case 'POST_RQST_TSR': {
        resp = await httpRequest(POST_RQST_TSR(apiObj));
        break;
      }
      case 'GET_RQST_TSR_UNIT_DETAILS': {
        resp = await httpRequest(GET_RQST_TSR_UNIT_DETAILS(apiObj));
        break;
      }
      case 'FP_CONS_RELEASE': {
        resp = await httpRequest(FP_CONS_RELEASE(apiObj));
        break;
      }
      case 'FP_CONS_DISPLAY': {
        resp = await httpRequest(FP_CONS_DISPLAY(apiObj));
        break;
      }
      case 'FP_CONS_REVERSE': {
        resp = await httpRequest(FP_CONS_REVERSE(apiObj));
        break;
      }
      case 'FP_CONS_DELETE': {
        resp = await httpRequest(FP_CONS_DELETE(apiObj));
        break;
      }
      case 'FP_CONS_APPROVE': {
        resp = await httpRequest(FP_CONS_APPROVE(apiObj));
        break;
      }
      case 'FP_CONS_SEARCH': {
        resp = await httpRequest(FP_CONS_SEARCH(apiObj));
        break;
      }
      case 'GET_FPCONS_POST_ATTR': {
        resp = await httpRequest(GET_FPCONS_POST_ATTR(apiObj));
        break;
      }
      case 'GET_FPCONS_POST_LOAD_DETAIL': {
        resp = await httpRequest(GET_FPCONS_POST_LOAD_DETAIL(apiObj));
        break;
      }
      case 'GET_GITTER_COLUMNS': {
        resp = await httpRequest(GET_GITTER_COLUMNS(apiObj));
        break;
      }
      case 'GET_FPCONS_APP_ATTR': {
        resp = await httpRequest(GET_FPCONS_APP_ATTR(apiObj));
        break;
      }
      case 'GET_FPCONS_APP_DETAIL': {
        resp = await httpRequest(GET_FPCONS_APP_DETAIL(apiObj));
        break;
      }
      case 'GET_FPCONS_POST': {
        resp = await httpRequest(GET_FPCONS_POST(apiObj));
        break;
      }
      case 'GET_FP_DCMAR_ATTR': {
        resp = await httpRequest(GET_FP_DCMAR_ATTR(apiObj));
        break;
      }
      case 'GET_FP_DCMAR_LOAD_DETAIL': {
        resp = await httpRequest(GET_FP_DCMAR_LOAD_DETAIL(apiObj));
        break;
      }
      case 'GET_FP_DCMAR_RSP_POST': {
        resp = await httpRequest(GET_FP_DCMAR_RSP_POST(apiObj));
        break;
      }
      case 'GET_DCMAR_RSP_ATTR': {
        resp = await httpRequest(GET_DCMAR_RSP_ATTR(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_ATTR': {
        resp = await httpRequest(GET_UNLOAD_BATCH_ATTR());
        break;
      }
      case 'GET_UNLOAD_BATCH_DETAIL': {
        resp = await httpRequest(GET_UNLOAD_BATCH_DETAIL(apiObj));
        break;
      }
      case 'GET_BATCH_CREATE': {
        resp = await httpRequest(GET_BATCH_CREATE(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_SUMMARY': {
        resp = await httpRequest(GET_UNLOAD_BATCH_SUMMARY(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_SUMMARY_ATTR': {
        resp = await httpRequest(GET_UNLOAD_BATCH_SUMMARY_ATTR(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_DETAIL_SUMMARY': {
        resp = await httpRequest(GET_UNLOAD_BATCH_DETAIL_SUMMARY(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_DETAIL_APPR': {
        resp = await httpRequest(GET_UNLOAD_BATCH_DETAIL_APPR(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_DETAIL_SAVE': {
        resp = await httpRequest(GET_UNLOAD_BATCH_DETAIL_SAVE(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_DETAIL_DEL': {
        resp = await httpRequest(GET_UNLOAD_BATCH_DETAIL_DEL(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_DETAIL_GRID': {
        resp = await httpRequest(GET_UNLOAD_BATCH_DETAIL_GRID(apiObj));
        break;
      }
      case 'GET_MISSING_BRANCH': {
        resp = await httpRequest(GET_MISSING_BRANCH(apiObj));
        break;
      }
      case 'GET_STK_TRANS_ITEMS': {
        resp = await httpRequest(GET_STK_TRANS_ITEMS(apiObj));
        break;
      }
      case 'GET_STK_TRANS_ITEM_SEARCH': {
        resp = await httpRequest(GET_STK_TRANS_ITEM_SEARCH(apiObj));
        break;
      }
      case 'DELETE_STK_TRANS_ITEM': {
        resp = await httpRequest(DELETE_STK_TRANS_ITEM(apiObj));
        break;
      }
      case 'FP_TSR_PACKQTY_GETROW': {
        resp = await httpRequest(FP_TSR_PACKQTY_GETROW(apiObj));
        break;
      }
      case 'GET_MENUS': {
        resp = await httpRequest(GET_MENUS(apiObj));
        break;
      }
      case 'EDIT_CONFORM_TRANSACTION': {
        resp = await httpRequest(EDIT_CONFORM_TRANSACTION(apiObj));
        break;
      }
      case 'GET_CONSOLIDATE_LOCITEMS': {
        resp = await httpRequest(GET_CONSOLIDATE_LOCITEMS(apiObj));
        break;
      }
      case 'SAVE_CONSOLIDATE_LOCITEMS': {
        resp = await httpRequest(SAVE_CONSOLIDATE_LOCITEMS(apiObj));
        break;
      }
      case 'GET_ReturnRequest_DCReceipt_SUMMARY': {
        resp = await httpRequest(GET_ReturnRequest_DCReceipt_SUMMARY(apiObj));
        break;
      }
      case 'GET_ReturnRequest_DCReceipt_Detail': {
        resp = await httpRequest(GET_ReturnRequest_DCReceipt_Detail(apiObj));
        break;
      }
      case 'Return_Request_ItemUpdate': {
        resp = await httpRequest(Return_Request_ItemUpdate(apiObj));
        break;
      }
      case 'GET_UNLOAD_BATCH_ITEM_PURDETAIL': {
        resp = await httpRequest(GET_UNLOAD_BATCH_ITEM_PURDETAIL(apiObj));
        break;
      }
      case 'ITEM_SEARCH': {
        resp = await httpRequest(ITEM_SEARCH(apiObj));
        break;
      }
      case 'UPDATE_FLYER_ITEMS': {
        resp = await httpRequest(UPDATE_FLYER_ITEMS(apiObj));
        break;
      }
      case 'GET_FLYER_UNIT': {
        resp = await httpRequest(GET_FLYER_UNIT(apiObj));
        break;
      }
      case 'DELETE_LINEITEMS': {
        resp = await httpRequest(DELETE_LINEITEMS(apiObj));
        break;
      }
      case 'GET_NOTION_FLYER_ITEMS': {
        resp = await httpRequest(GET_NOTION_FLYER_ITEMS(apiObj));
        break;
      }
      case 'NOTION_FLYER_APPROVE': {
        resp = await httpRequest(NOTION_FLYER_APPROVE(apiObj));
        break;
      }

      default:
        break;
    }
    if (resp?.status === 200) {
      return resp;
    }
  } catch (err) {
    return err;
  }
};
