import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../layout';
import Home from '../pages/home';
import ReturnRequest from '../pages/ReturnRequest/ReturnRequestSummary';
import ReturnRequestReview from '../pages/ReturnRequestReview/ReturnRequestReviewSummary';
import ReturnRequestDetail from '../pages/ReturnRequest/ReturnRequestDetail';
import ReturnRequestReviewDetail from '../pages/ReturnRequestReview/ReturnRequestReviewDetail';
import PickingSummary from '../pages/PickingSummary/PickingSummary';
import PickingDetail from '../pages/PickingSummary/PickingDetail';
import DispatchSummary from '../pages/Dispatch/DispatchSummary';
import DispatchDetail from '../pages/Dispatch/DispatchDetail';
import PurchaseReturnSummary from '../pages/PurchaseReturn/PurchaseReturnSummary';
import PurchaseReturnDetail from '../pages/PurchaseReturn/PurchaseReturnDetail';
import DCConfirmationSummary from '../pages/DC_Confirmation/DCConfirmationSummary';
import DCConfirmationDetail from '../pages/DC_Confirmation/DCConfirmationDetail';
import BatchCreationSummary from '../pages/BatchCreation/BatchCreationSummary';
import BatchCreationDetail from '../pages/BatchCreation/BatchCreationDetail';
import BatchDetail from '../pages/PurchaseReturn/BatchDetail';
import ReturnReviewBatchCreate from '../pages/ReturnReviewBatchCreation/ReturnReviewBatchCreate';
import ReturnReviewBatchSummary from '../pages/ReturnReviewBatch/ReturnReviewBatchSummary';
import ReturnReviewBatchDetails from '../pages/ReturnReviewBatch/ReturnReviewBatchDetails';
import ReturnReviewBatchItem from '../pages/ReturnReviewBatch/ReturnReviewBatchItem';
import Order from '../pages/Order/Order';
import Return from '../pages/Return/Return';
import Purchase from '../pages/Purchase/Purchase';
import MisInfo from '../pages/MIS/MisInfo';
import OrderDetail from '../pages/Order/OrderDetail';
import Dashboard from '../pages/Dashboard/DashBoard';
import PendingOrder from '../pages/Order/PendingOrder';
import PostedOrder from '../pages/Order/PostedOrder';
import OpenPurchaseSummary from '../pages/Purchase/OpenPurchaseSummary';
// import OpenPurchaseDetail from '../pages/Purchase/OpenPurchaseDetail';
import PostedPurchaseSummary from '../pages/Purchase/PostedPurchaseSummary';
import PurchaseDetail from '../pages/Purchase/PurchaseDetail';
import VanTransSummary from '../pages/VanReceiving/VanTransSummary';
import VanTransDetail from '../pages/VanReceiving/VanTransDetail';
import VanItemReceiving from '../pages/VanReceiving/VanItemReceiving';
import BufferPurchaseSummary from '../pages/Purchase/BufferPurchaseSummary';
import PurchaseConfirmDetail from '../pages/Purchase/PurchaseConfirmDetail';
import ConfirmedPurchaseSummary from '../pages/Purchase/ConfirmedPurchaseSummary';
import ReturnReports from '../pages/ReturnReports/ReturnReports';
import RequestReports from '../pages/ReturnReports/RequestReports';
import OutletRequest from '../pages/Request/OutletRequest';
import OutletRequestReport from '../pages/Request/OutletRequestReport';
import PriceEnquirySummary from '../pages/PriceEnquiry/PriceEnquirySummary';
import PriceEnquiryDetail from '../pages/PriceEnquiry/PriceEnquiryDetail';
import PurchaseRequestSummary from '../pages/Request/PurchaseRequestSummary';
import PurchaseRequestDetail from '../pages/Request/PurchaseRequestDetail';
import PurchaseRequestDetail001 from '../pages/Request/PurchaseRequestDetail001';
import OpenFishPurSummary from '../pages/FishPurchase/OpenFishPurSummary';
import PostedFishPurSummary from '../pages/FishPurchase/PostedFishPurSummary';
import FishPurchaseDetail from '../pages/FishPurchase/FishPurchaseDetail';
import PendingFishPurSummary from '../pages/FishPurchase/PendingFishPurSummary';
import ConfirmFishPurDetail from '../pages/FishPurchase/ConfirmFishPurDetail';
import CompletedFishPurSummary from '../pages/FishPurchase/CompletedFishPurSummary';
import PendingFishOrder from '../pages/FishPurchase/PendingFishOrder';
import CompletedFishOrder from '../pages/FishPurchase/CompletedFishOrder';
import FishOrderDetail from '../pages/FishPurchase/FishOrderDetail';
import ConsolidationRequest from '../pages/Request/ConsolidationRequest';
import RequestTransferDetail from '../pages/Request/RequestTransferDetail';
import RequestTransferSummary from '../pages/Request/RequestTransferSummary';
import ConsolidationPosting from '../pages/Request/ConsolidationPosting';
import DCMarginUpdate from '../pages/Request/DCMarginUpdate';
import UnLoading from '../pages/Request/UnLoading';
import UnLoadingDetail from '../pages/Request/UnloadingDetail';
import UnLoadingDetailItem from '../pages/Request/UnLoadingDetailItem';
import DCReceiptSummary from '../pages/DC_Receipt/DCReceiptSummary';
import DCReceiptDetail from '../pages/DC_Receipt/DCReceiptDetail';
import Notion from '../pages/Notion/Notion';

export const LayoutRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/ReturnRequest" element={<ReturnRequest />} />
      <Route path="/ReturnRequestDetail" element={<ReturnRequestDetail />} />
      <Route path="/ReturnRequestReview" element={<ReturnRequestReview />} />
      <Route path="/ReturnRequestReviewDetail" element={<ReturnRequestReviewDetail />} />
      <Route path="/PickingSummary" element={<PickingSummary />} />
      <Route path="/PickingDetail" element={<PickingDetail />} />
      <Route path="/DispatchSummary" element={<DispatchSummary />} />
      <Route path="/DispatchDetail" element={<DispatchDetail />} />
      <Route path="/PurchaseReturn" element={<PurchaseReturnSummary />} />
      <Route path="/PurchaseReturnDetail" element={<PurchaseReturnDetail />} />
      <Route path="/DCConfirmation" element={<DCConfirmationSummary />} />
      <Route path="/DCConfirmationDetail" element={<DCConfirmationDetail />} />
      <Route path="/BatchCreation" element={<BatchCreationSummary />} />
      <Route path="/BatchCreationDetail" element={<BatchCreationDetail />} />
      <Route path="/BatchDetail" element={<BatchDetail />} />
      <Route path="/ReviewBatchCreation" element={<ReturnReviewBatchCreate />} />
      <Route path="/ReviewBatchSummary" element={<ReturnReviewBatchSummary />} />
      <Route path="/ReviewBatchDetails" element={<ReturnReviewBatchDetails />} />
      <Route path="/ReviewBatchItem" element={<ReturnReviewBatchItem />} />
      <Route path="/RequestReports" element={<RequestReports />} />
      <Route path="/ReturnReports" element={<ReturnReports />} />
      <Route path="/DCReceipt" element={<DCReceiptSummary />} />
      <Route path="/DCReceiptDetail" element={<DCReceiptDetail />} />

      <Route path="/PendingOrder" element={<PendingOrder />} />
      <Route path="/PostedOrder" element={<PostedOrder />} />
      <Route path="/OrderDetail" element={<OrderDetail />} />

      <Route path="/OpenPurchaseSummary" element={<OpenPurchaseSummary />} />
      {/* <Route path="/OpenPurchaseDetail" element={<OpenPurchaseDetail />} /> */}
      <Route path="/PostedPurchaseSummary" element={<PostedPurchaseSummary />} />
      <Route path="/PurchaseDetail" element={<PurchaseDetail />} />

      <Route path="/BufferPurchaseSummary" element={<BufferPurchaseSummary />} />
      <Route path="/ConfirmedPurchaseSummary" element={<ConfirmedPurchaseSummary />} />
      <Route path="/PurchaseConfirmDetail" element={<PurchaseConfirmDetail />} />

      <Route path="/VanTransSummary" element={<VanTransSummary />} />
      <Route path="/VanTransDetail" element={<VanTransDetail />} />
      <Route path="/VanItemReceiving" element={<VanItemReceiving />} />

      <Route path="/OutletRequest" element={<OutletRequest />} />
      <Route path="/OutletRequestReport" element={<OutletRequestReport />} />

      <Route path="/PurchaseRequestSummary" element={<PurchaseRequestSummary />} />
      <Route path="/PurchaseRequestDetail" element={<PurchaseRequestDetail />} />
      <Route path="/PurchaseRequestDetail001" element={<PurchaseRequestDetail001 />} />
      <Route path="/RequestTransferSummary" element={<RequestTransferSummary />} />
      <Route path="/RequestTransferDetail" element={<RequestTransferDetail />} />

      <Route path="/PriceEnquirySummary" element={<PriceEnquirySummary />} />
      <Route path="/PriceEnquiryDetail" element={<PriceEnquiryDetail />} />

      <Route path="/purchase" element={<Purchase />} />
      <Route path="/return" element={<Return />} />
      <Route path="/misInfo" element={<ReturnReports />} />

      <Route path="/OpenFishPurSummary" element={<OpenFishPurSummary />} />
      <Route path="/PostedFishPurSummary" element={<PostedFishPurSummary />} />
      <Route path="/FishPurchaseDetail" element={<FishPurchaseDetail />} />
      <Route path="/PendingFishPurSummary" element={<PendingFishPurSummary />} />
      <Route path="/ConfirmFishPurDetail" element={<ConfirmFishPurDetail />} />
      <Route path="/CompletedFishPurSummary" element={<CompletedFishPurSummary />} />
      <Route path="/PendingFishOrder" element={<PendingFishOrder />} />
      <Route path="/CompletedFishOrder" element={<CompletedFishOrder />} />
      <Route path="/FishOrderDetail" element={<FishOrderDetail />} />
      <Route path="/ConsolidationRequest" element={<ConsolidationRequest />} />
      <Route path="/ConsolidationPosting" element={<ConsolidationPosting />} />
      <Route path="/DCMarginUpdate" element={<DCMarginUpdate />} />
      <Route path="/UnLoading" element={<UnLoading />} />
      <Route path="/UnLoadingDetail" element={<UnLoadingDetail />} />
      <Route path="/UnLoadingDetailItem" element={<UnLoadingDetailItem />} />
    </Routes>
  );
};

const ProjectRoutes = () => {
  return (
    // <BrowserRouter>
    <Routes>
      <Route path="*" element={<Layout />} />
    </Routes>
    // </BrowserRouter>
  );
};

export default ProjectRoutes;
