import { Close, MoreHoriz } from '@mui/icons-material'
import { Box, Grid, IconButton, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CmpButton, CmpInputField, CmpSwitch, CmpTypographyField } from '../../../component/ToolBox/ToolBox'
import PurchaseAdjustmentModal from './PurchaseAdjustmentModal';
import { postPurchaseTransaction } from '../actions';
import { fAlertToast } from '../../../Utility/Utilitys';
import { updateTransHead } from '../PurchaseSlice';


export default function PurchasePostModal({
    sHeadObj,
    setHeadObj,
    setShowPostModal,
    xTotal
}) {

    const rdxTransHead = useSelector((state) => state.PurchaseSlice.transHead);
    const [sShowAdjModal, setShowAdjModal] = useState(false);
    const dispatch = useDispatch();

    const fOnChangeAdjAmt = (newValue) => {

        const reduction = isNaN(newValue) ? 0 : newValue;
        setHeadObj({ ...sHeadObj, TRNS_Vendor_Reduction: reduction, TRNS_Vendor_Os_Adj: Number(reduction) > 0 ? 'Y' : 'N' })
    }
    const fPostPurchaseTrans = async () => {

        let obj = {
            TransId: sHeadObj.TransId,
            TRNS_Vendor_Os: sHeadObj.TRNS_Vendor_Os,
            TRNS_Vendor_Os_Adj: sHeadObj.TRNS_Vendor_Os_Adj,
            TRNS_Vendor_Reduction: sHeadObj.TRNS_Vendor_Reduction,
        }
        const result = await postPurchaseTransaction(obj);
        console.log('result>>>>' + JSON.stringify(result));
        if (result.status === 200) {
            fAlertToast('SUCCESS', 'Posted Successfully');
            dispatch(updateTransHead({ ...sHeadObj, Status: 'POST' }));
        }
    }
    return (
        <Box sx={{ flexGrow: 1 }} className="m-4">

            <Grid container spacing={0}>
                <Grid item xs={12} className=" d-flex justify-content-between">
                    <CmpTypographyField xVariant='h3' xText={'Post Purchase'} />
                    <Close onClick={() => setShowPostModal(false)} />
                </Grid>
            </Grid>
            {/* <Box sx={{ height: '70vh', overflowY: 'auto', marginTop: '20px' }}> */}
            <Grid container spacing={2}
                sx={{ height: '58vh', overflowY: 'auto', marginTop: '1vh', paddingRight: '15px', alignContent: 'start' }}>

                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <CmpTypographyField xcomponent={'span'} xText={sHeadObj.TransId} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Order No.'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <CmpTypographyField xcomponent={'span'} xText={sHeadObj.OrderId} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <CmpTypographyField xcomponent={'span'} xText={sHeadObj.LocationName} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <CmpTypographyField xcomponent={'span'} xText={moment(sHeadObj.Date).format('DD/MM/YYYY')} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Invoice No.'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <CmpTypographyField xcomponent={'span'} xText={sHeadObj.ReferenceNo} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={4} md={3}>
                            <CmpTypographyField xcomponent={'span'} xText={'Invoice Date'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={7} md={8}>
                            <CmpTypographyField xcomponent={'span'} xText={moment(sHeadObj.ReferenceDate).format('DD/MM/YYYY')} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        sx={{ alignItems: 'center' }}>
                        <Grid item xs={4} sm={2} md={1.5}>
                            <CmpTypographyField xcomponent={'span'} xText={'Party'} />
                        </Grid>
                        <Grid item xs={1} sm={.5} md={.5}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={7} sm={9.5} md={10}>
                            <CmpTypographyField xcomponent={'span'} xText={sHeadObj.VendorName} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} md={12} />
                <Grid item xs={12} sm={12} lg={12} md={12} />
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                        <Grid item xs={4} sm={4} md={4}>
                            <CmpTypographyField xcomponent={'span'} xText={'Invoice Amount'} />
                        </Grid>
                        <Grid item xs={1} sm={1} lg={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={4} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={Number(sHeadObj.invoiceAmt).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                        <Grid item xs={4} sm={4} md={4}>
                            <CmpTypographyField xcomponent={'span'} xText={'Line wise Total'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={4} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={Number(xTotal).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                        <Grid item xs={4} sm={4} md={4}>
                            <CmpTypographyField xcomponent={'span'} xText={'Rounding'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={4} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={Number(sHeadObj.Rounding).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                        <Grid item xs={4} sm={4} md={4}>
                            <CmpTypographyField xcomponent={'span'} xText={'Net Amount'} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <CmpTypographyField xcomponent={'span'} xText={':'} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={4} className='text-end'>
                            <CmpTypographyField xcomponent={'span'} xText={(Number(xTotal) + Number(sHeadObj.Rounding)).toFixed(2)} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} md={12} visibility={sHeadObj.TRNS_Confirm_Inv === 'Y' && Number(sHeadObj.TRNS_Vendor_Os) > 0 && sHeadObj.Paymode === 'CASH' ? '' : 'hidden'}>
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        alignItems='center'
                    >
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                alignItems='center'
                            >
                                <Grid item xs={4} sm={4} md={4}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Vendor O/S'} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                {/* <Grid item xs={2} sm={2} lg={1} md={1}>
                            <CmpSwitch />
                        </Grid> */}
                                <Grid item xs={5} sm={5} md={4} className='text-end'>
                                    <CmpTypographyField xcomponent={'span'} xText={Number(sHeadObj.TRNS_Vendor_Os).toFixed(2)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                alignItems='center'
                            >
                                <Grid item xs={4} sm={4} md={4}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Adjustment Amt.'} />
                                </Grid>
                                <Grid item xs={2} sm={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    {/* <IconButton color='primary' onClick={(params) => {
                                        setShowAdjModal(true);
                                    }}>
                                        <MoreHoriz />
                                    </IconButton> */}
                                </Grid>
                                <Grid item xs={4} sm={5} md={4} className='text-end'>
                                    {/* <CmpTypographyField xcomponent={'span'} xText={Number(0).toFixed(2)} /> */}
                                    <CmpInputField
                                        xType={'text'} // text/number/password
                                        xName={'AdjAmt'}
                                        xReadOnly={false}
                                        xValue={sHeadObj.TRNS_Vendor_Reduction}
                                        xOnChange={(event) => { fOnChangeAdjAmt(event.target.value) }}
                                        xError={false}
                                        xErrorMessage={''}
                                        xOnKeyUp={''}
                                        xTextAlign='right'
                                    />


                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid
                                container
                                spacing={1}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            >
                                <Grid item xs={4} sm={4} md={4}>
                                    <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                                </Grid>
                                <Grid item xs={5} sm={5} md={4} className='text-end'>
                                    <CmpTypographyField xcomponent={'span'} xText={Number(0).toFixed(2)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* </Box> */}

            <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} justifyContent='center' >

                <Grid item xs={4} sm={3} lg={1} md={1}>
                    <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={fPostPurchaseTrans}
                        xLabel={'Post'}
                        xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER'}
                    />
                </Grid>

            </Grid>
            <Modal
                open={sShowAdjModal}
                onClose={() => setShowAdjModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="modal-puchase-adjustment-Modal">
                    <PurchaseAdjustmentModal sHeadObj={sHeadObj} setHeadObj={setHeadObj} xTotal={xTotal} setShowAdjModal={setShowAdjModal} />
                </Box>
            </Modal>
        </Box >

    )
}
