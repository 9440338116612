import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import TransSummarycard from './ProcessComponents/TransSummarycard';
import { getTransSummary } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateTransHead } from './PurchaseSlice';
import { useNavigate } from 'react-router-dom';
import OpenPurchaseBreadcrumbs from './ProcessComponents/OpenPurchaseBreadcrumbs';
import './Purchase.css';
export default function PurchaseConfirmSummary({ xState }) {
  const [sTransList, setTransList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rdxUser = useSelector((state) => state.SliceDB.value);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);

  const loadDetail = useCallback(async () => {
    const reqObj = {
      fromDate: moment(rdxFilterObj.dateFrom).format('MM/DD/YYYY'),
      toDate: moment(rdxFilterObj.dateTo).format('MM/DD/YYYY'),
      buyerCd: rdxUser.userInfo.Buyer,
      transId: '',
      marketPurchase: 'Y',
      state: xState
    };
    const result = await getTransSummary(reqObj);
    const obj = result.ResultSet;
    setTransList(obj);
  }, [setTransList, rdxFilterObj, rdxUser, xState]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  const fOnpressTrasnCard = (itm) => {
    dispatch(
      updateTransHead({
        TransId: itm.TransId,
        OrderId: itm.OrderId,
        Location: itm.Location,
        LocationName: itm.LocationName,
        Date: itm.Date,
        Vendor: itm.Vendor,
        VendorName: itm.VendorName,
        ReferenceNo: itm.ReferenceNo,
        ReferenceDate: itm.ReferenceDate,
        Status: itm.Status,
        NetAmt: itm.NetAmt,
        DriverName: itm.DriverName,
        Parking: itm.Parking,
        invoiceAmt: itm.invoiceAmt,
        Paymode: itm.Paymode,
        State: itm.State,
        Rounding: itm.Rounding,
        MarketPurchase: itm.MarketPurchase,
        TRNS_Vendor_Os: itm.TRNS_Vendor_Os,
        TRNS_Vendor_Os_Adj: itm.TRNS_Vendor_Os_Adj,
        TRNS_Vendor_Reduction: itm.TRNS_Vendor_Reduction,
        TRNS_Confirm_Inv: itm.TRNS_Confirm_Inv,
        TRNS_Direct_Deli: itm.TRNS_Direct_Deli,
        TaxPurchase: itm.TaxPurchase,
        TRNS_PARTY_MOBILE: itm.TRNS_PARTY_MOBILE,
        UpdateFlag: itm.UpdateFlag,
        DCPurchase: itm.DCPurchase
      })
    );

    navigate('/PurchaseConfirmDetail');
  };
  return (
    <div className="container openPurchase">
      <div
      // className="my-2"
      >
        <OpenPurchaseBreadcrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '80vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent: sTransList.length === 0 ? 'center' : 'start'
          }}>
          {sTransList.length === 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                // height: '80vh'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : (
            sTransList.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={itm.TransId}
                  //  onClick={() => fOnpressTrasnCard(itm)}
                >
                  <TransSummarycard
                    xTransId={itm.TransId}
                    xInvoiceNumber={itm.ReferenceNo}
                    xVendor={itm.VendorName.toLowerCase()}
                    xAmount={itm.NetAmt}
                    xDate={moment(itm.Date).format('DD/MM/YYYY')}
                    xState={itm.State}
                    xStatus={itm.Status}
                    xOnClick={() => fOnpressTrasnCard(itm)}
                    sTransList={sTransList}
                    setTransList={setTransList}
                    xDCPurchase={itm.DCPurchase}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
