import React, { useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpSelectArrField,
  CmpButton
} from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  ReturnRequest_Review_Head,
  ReturnRequest_Review_RowDetail
} from './ReturnRequestReviewSlice';
import { fExecuteAPI } from '../../Server/Apis';
import { fAlertToast } from '../../Utility/Utilitys';
import { GridcolumnsReview } from './ReturnRequestReviewAction';

import ReturnRequestReviewBreadcrumbs from './ReturnRequestReview_BreadCrumbs';
import { ToastContainer } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
const statusData = ['All', 'Pending', 'Post'];
const typeData = ['All', 'Accepted', 'Rejected', 'Partial Accepted'];

const ReturnRequestReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vMediaQuery = useMediaQuery('(max-width:800px)');
  const getReviewLocations = useSelector((state) => state.SliceDB.value.getReviewLoc);
  const getDCLocations = useSelector((state) => state.SliceDB.value.getDCLocation);
  const scanFocusOnLoad = useRef(null);

  const [sGridrows, setGridrows] = useState([]);
  const [sGridCols, setGridcols] = useState(GridcolumnsReview);
  const [sSummaryObj, setSummaryObj] = useState({
    location: getReviewLocations[0].UId,
    dateFrom: moment().startOf('month'),
    dateTo: new Date(),
    status: statusData[0],
    type: typeData[0],
    DC: getDCLocations[0].UId
  });

  const fOnPageLoad = useCallback(async () => {
    dispatch(
      ReturnRequest_Review_Head({
        location: getReviewLocations[0].UId,
        dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        dateTo: new Date(),
        status: 'All',
        DCStatus: 'All',
        DC: getDCLocations[0].UId
      })
    );

    fOnpressLoad();
  }, [dispatch]);

  useEffect(() => {
    scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  const Return_Request_Summary = async () => {
    let suumaryObj = {
      v_A_Loc: sSummaryObj.location,
      v_A_DCLoc: sSummaryObj.DC,
      v_Date1: moment(sSummaryObj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(sSummaryObj.dateTo).format('MM/DD/YYYY'),
      v_A_Type: sSummaryObj.type,
      v_A_DCStatus: sSummaryObj.status
    };
    const vSummary = await fExecuteAPI('GET_RETURN_REQUEST_REVIEW_SUMMARY', suumaryObj);
    if (vSummary?.status === 200) {
      return vSummary.data.ResultSet;
    } else if (
      vSummary.response.status === 500 ||
      vSummary.response.status === 400 ||
      vSummary.response.status === 404 ||
      vSummary.response.status === 403
    ) {
      fAlertToast('FAILED', vSummary.response.data.message);
      return {};
    } else {
      fAlertToast('FAILED', 'Error in loading');
      return {};
    }
  };

  const fOnpressLoad = async () => {
    scanFocusOnLoad.current.focus();
    const result = await Return_Request_Summary();
    if (result.length > 0) {
      let GridID = result?.map((obj, i) => ({
        ...obj,
        id: i + 1,
        A_PostDt: moment(obj.A_PostDt).format('DD/MM/YYYY')
      }));
      setGridrows(GridID);
    }
    dispatch(ReturnRequest_Review_Head(sSummaryObj));
  };

  const fOnRowClick = (item) => {
    dispatch(
      ReturnRequest_Review_RowDetail({
        A_TransId: item.row.A_TransId,
        A_PostDt: moment(item.row.A_PostDt, 'DD/MM/YYYY')._d,
        A_Loc: item.row.A_Loc,
        LocName: item.row.LocName,
        A_DCLoc: item.row.A_DCLoc,
        DCLocName: item.row.DCLocName,
        A_DocStatus: item.row.A_DocStatus,
        A_Type: item.row.A_Type,
        A_DCStatus: item.row.A_DCStatus
      })
    );
    navigate('/ReturnRequestReviewDetail');
  };

  const onChangeLocations = (event) => {
    setSummaryObj((obj) => ({ ...obj, location: event.target.value }));
  };

  const onChangeDCLocations = (event) => {
    setSummaryObj((obj) => ({ ...obj, DC: event.target.value }));
  };

  const onChangeType = (event) => {
    setSummaryObj((obj) => ({ ...obj, type: event.target.value }));
  };

  const onChangeStatus = (event) => {
    setSummaryObj((obj) => ({ ...obj, status: event.target.value }));
  };

  const onChangeDateFrom = (newValue) => {
    setSummaryObj((obj) => ({ ...obj, dateFrom: newValue.$d }));
  };

  const onChangeDateTo = (newValue) => {
    setSummaryObj((obj) => ({ ...obj, dateTo: newValue.$d }));
  };

  // useEffect(() => {
  //   if (vMediaQuery) {

  //     let updatedArray = GridcolumnsReview.map((obj) => {
  //       if (obj.field === "A_TransId") {
  //         return { ...obj, hide: true };
  //       }
  //       return obj;
  //     });

  //     GridcolumnsReview = updatedArray;
  //   } else {
  //     // GridcolumnsReview[0].hide = false

  //     setGridcols(updatedArray);
  //   } else {

  //     let updatedArray = GridcolumnsReview.map((obj) => {
  //       if (obj.field === "A_TransId") {
  //         return { ...obj, hide: false };
  //       }
  //       return obj;
  //     });

  //     GridcolumnsReview = updatedArray;
  //   }
  // }, [vMediaQuery]);
  // console.log(GridcolumnsReview);

  //     setGridcols(updatedArray);
  //   }
  // }, [vMediaQuery]);

  return (
    <div>
      <div>
        {' '}
        <ToastContainer />
      </div>
      <div>
        <ReturnRequestReviewBreadcrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={4} md={3} className="me-return-request-review">
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.5} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Location'} />
              </Grid>
              <Grid item xs={9.5} sm={9} md={8}>
                <CmpSelectField
                  xTabIndex="1"
                  xLabelText="Location"
                  xValue={sSummaryObj.location}
                  xOnChange={(event) => onChangeLocations(event)}
                  xName={'Location'}
                  xData={getReviewLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xref={scanFocusOnLoad}
                />
              </Grid>
              <Grid item xs={1.5} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'DC'} />
              </Grid>
              <Grid item xs={4} sm={9} md={8} className={vMediaQuery ? 'display-none' : ''}>
                <CmpSelectField
                  xTabIndex="4"
                  xLabelText="DC"
                  xValue={sSummaryObj.DC}
                  xOnChange={(event) => onChangeDCLocations(event)}
                  xName={'DC'}
                  xData={getDCLocations}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={3} className="me-return-request-review">
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.5} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={4} sm={9} md={8}>
                <CmpDatePickerField
                  xTabIndex="2"
                  xValue={sSummaryObj.dateFrom}
                  xOnChange={(newValue) => onChangeDateFrom(newValue)}
                  xName={'dateFrom'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
              <Grid item xs={1.5} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Status'} />
              </Grid>
              <Grid item xs={4} sm={9} md={8}>
                <CmpSelectArrField
                  xTabIndex="5"
                  xLabelText="status"
                  xValue={sSummaryObj.status}
                  xOnChange={(event) => onChangeStatus(event)}
                  xName={'status'}
                  xData={statusData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={3} className="me-return-request-review">
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.5} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={4} sm={9} md={8}>
                <CmpDatePickerField
                  xTabIndex="3"
                  xValue={sSummaryObj.dateTo}
                  xOnChange={(newValue) => onChangeDateTo(newValue)}
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                />
              </Grid>
              <Grid item xs={1.5} sm={3} md={4}>
                <CmpTypographyField xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={4} sm={9} md={8}>
                <CmpSelectArrField
                  xTabIndex="6"
                  xLabelText="Type"
                  xValue={sSummaryObj.type}
                  xOnChange={(event) => onChangeType(event)}
                  xName={'Type'}
                  xData={typeData}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center', textAlign: 'end' }}>
          <Grid item xs={12} sm={12} md={10} className="ms-1 my-2">
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: 'end' }}>
              <Grid item xs={6} sm={6} md={1} className="me-1">
                <CmpButton
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ height: '40vh', width: '100%' }} className="mt-1">
          <DataGrid
            rows={sGridrows}
            columns={sGridCols}
            rowHeight={30}
            headerHeight={40}
            sx={{
              border: 2,
              borderColor: 'primary.light'
            }}
            componentsProps={{
              filterPanel: { sx: { maxWidth: "93vw" } }
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
            }
            pageSize={10}
            rowsPerPageOptions={[10]}
            onRowClick={(item) => fOnRowClick(item)}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ReturnRequestReview;
