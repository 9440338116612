import { fExecuteAPI } from '../../Server/Apis';

const getPendingReturnRequest = async (xObj) => {
  try {
    const pendingList = await fExecuteAPI('GET_PENDING_RETURN_REQUEST', xObj);
    return pendingList.data;
  } catch (error) {}
};

export const getDepartmentList = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_DEPT_LIST', xObj);
    return result.data;
  } catch (error) {}
};
// const getBatchCreationPendingTrans = async (xObj) => {
//   try {
//     const vPurchaseReturnItemList = await fExecuteAPI('GET_PENDING_TRANS_FOR_BATCH_CREATION', xObj);
//     return vPurchaseReturnItemList.data;
//   } catch (error) {}
// };

const createReviewBatch = async (xObj) => {
  try {
    const createBatchResult = await fExecuteAPI('CREATE_REVIEW_BATCH', xObj);
    return createBatchResult;
  } catch (error) {}
};

export { getPendingReturnRequest, createReviewBatch };
