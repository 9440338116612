import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Modal, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  CmpButtonWithIcon,
  CmpDatePickerField,
  CmpStatusDiv,
  CmpTypographyField
} from '../../component/ToolBox/ToolBox';
import { updateRequestItemsDetail, updateTransferHead } from './OutletRequestSlice';
import { Save, Verified } from '@mui/icons-material';
import { fAlertToast } from '../../Utility/Utilitys';
import { confirmAlert } from 'react-confirm-alert';
import { Print, AddCircle } from '@mui/icons-material';
import TransferBreadCrumbs from './ProcessComponents/TransferBreadCrumbs';
import TransferItemGrid from './ProcessComponents/TransferItemGrid';
import {
  getItemsUnitDetails,
  getRequestTransferItemList,
  postRequestTransfer,
  saveRequestTransfer
} from './actions';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'background.paper',
  border: '2px solid #0d73bb',
  boxShadow: 24,
  // p: 4,
  textAlign: 'center',
  // alignItems: "center",
  // borderRadius: 3,
  height: '80%',
  margin: '0 auto',
  color: '#0d73bb'
};

export default function RequestTransferDetail() {
  const dispatch = useDispatch();
  const rdxHeadObj = useSelector((state) => state.OutletRequestSlice.value.transferHead);
  const rdxuser = useSelector((state) => state.SliceDB.value.login);

  const [sHeadObj, setHeadObj] = useState(rdxHeadObj);
  const [sItemList, setItemList] = useState([]);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [sItemSearchModal, setItemSearchModal] = useState(false);

  const [sItemPackQtyModal, setItemPackQtyModal] = useState(false);
  const [selectedID, setSelectedID] = useState(0);
  const [printWebURL, setPrintWebURL] = useState(null);
  const vMediaQuery = useMediaQuery('(max-width: 900px)');
  const vMobileScreen = useMediaQuery('(max-width: 600px)');
  const reportBaseUrl = useSelector((state) => state.SliceDB.value.reportBaseUrl);

  const fLoadItems = async (xHeadObj) => {
    const obj = {
      vocType: xHeadObj.vocTyp,
      vocNo: xHeadObj.vocNo,
      loc: xHeadObj.loc
    };
    const result = await getRequestTransferItemList(obj);
    console.log(result);

    const unitResult = await getItemsUnitDetails(obj);

    if (result?.ResultSet?.length > 0) {
      let changedList = result.ResultSet.map((i, index) => {
        let unitArray = unitResult?.ResultSet?.filter((j) => j.IL_ItmCd === i.IL_ItmCd);
        return {
          ...i,
          id: index + 1,
          B_Tsr_PackQty: i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty,
          B_Tsr_Unit: i.B_Tsr_Unit === '' ? unitArray[0]?.Unit : i.B_Tsr_Unit,
          B_Tsr_UnitRate: Number(
            Number(i?.CostPr) *
              Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty)
          ),
          IL_StockCTN:
            Number(i.IL_Stock) /
            Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty),
          IL_FinalQtyCTN:
            Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty) == 0
              ? 0
              : Number(i.IL_FinalQty) /
                  Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty) >
                  0 &&
                Number(i.IL_FinalQty) /
                  Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty) <
                  0.99
              ? 1
              : Math.round(
                  Number(i.IL_FinalQty) /
                    Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty)
                ),

          // Number(i.IL_FinalQty) /
          // Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty),
          units: unitArray,
          SuggestedTransferCTN:
            Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty) == 0
              ? 0
              : Number(i.SuggestedTransfer) /
                  Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty) >
                  0 &&
                Number(i.SuggestedTransfer) /
                  Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty) <
                  0.99
              ? 1
              : Math.round(
                  Number(i.SuggestedTransfer) /
                    Number(i.B_Tsr_PackQty === 0 ? unitArray[0]?.PackQty : i.B_Tsr_PackQty)
                )
        };
      });
      console.log(changedList);
      setItemList(changedList);
    } else {
      setItemList([]);
    }
    //setItemList(result ? result.ResultSet ? result.ResultSet : [] : []);

    // result ? dispatch(updateRequestItemsDetail(result.ResultSet)) : dispatch(updateRequestItemsDetail([]));
    return [];
  };

  const fonPageLoad = useCallback(async () => {
    await fLoadItems(rdxHeadObj);
  }, [fLoadItems]);

  useEffect(() => {
    fonPageLoad();
  }, []);

  const validate = () => {
    try {
      // let invalidRows = sItemList.filter(i => Number(i.B_Tsr_Qty) === 0);
      // console.log(`invalidRows` + invalidRows);
      // comment logic because to save items with zero Qty value

      // if (invalidRows.length > 0) {
      //     fAlertToast("FAILED", `Please enter transfer quantity for all the items`);
      //     return false;
      // }

      let invalidRows = sItemList.filter(
        (i) => i.B_Tsr_Unit.toString() === '' && Number(i.B_Tsr_Qty) != 0
      );
      if (invalidRows.length > 0) {
        fAlertToast('FAILED', `Please select Unit for ${invalidRows[0].IL_ItmCd} itemcode`);
        return false;
      }
      invalidRows = sItemList.filter(
        (i) => Number(i.B_Tsr_PackQty) === 0 && Number(i.B_Tsr_Qty) != 0
      );
      if (invalidRows.length > 0) {
        fAlertToast('FAILED', `Please enter pack quantity for ${invalidRows[0].IL_ItmCd} itemcode`);
        return false;
      }
    } catch (error) {
      fAlertToast('FAILED', error);
      return false;
    }
    return true;
  };

  const fSave = async () => {
    let itemList = sItemList.map((i) => {
      return {
        itmCd: i.IL_ItmCd,
        tsrQty: Number(i.B_Tsr_Qty),
        tsrUnit: i.B_Tsr_Unit,
        tsrPackQty: Number(i.B_Tsr_PackQty),
        tsrUnitRate: Number(i.B_Tsr_UnitRate)
      };
    });
    let obj = {
      vocType: rdxHeadObj.vocTyp,
      vocNo: rdxHeadObj.vocNo,
      loc: rdxHeadObj.loc,
      postDt: moment(sHeadObj.postDt).format('MM/DD/YYYY'),
      itmList: itemList,
      userCd: rdxuser.userId
    };
    const result = await saveRequestTransfer(obj);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Saved Successfully');
      return true;
    }

    return false;
  };
  const fPost = async () => {
    let obj = {
      vocType: rdxHeadObj.vocTyp,
      vocNo: rdxHeadObj.vocNo,
      loc: rdxHeadObj.loc
    };

    const result = await postRequestTransfer(obj);

    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Posted Successfully');
      dispatch(updateTransferHead({ ...rdxHeadObj, status: 'POST' }));
    }
  };
  const onClickPrint = () => {
    setOpenPrintModal(true);
    let url =
      reportBaseUrl +
      `FreshPurchase/RequestTransfer?trntyp=REQTRNSFER&VocTyp=${rdxHeadObj.vocTyp}&VocNo=${rdxHeadObj.vocNo}&Loc=${rdxHeadObj.loc}`;
    // window.open(url);
    setPrintWebURL(url);
  };
  const onClickNewItem = async () => {
    const data = sItemList?.filter((x) => x.IL_ItmCd == '');
    if (data?.length == 0) {
      let obj = {
        IL_ItmCd: '',
        ItemName: '',
        IL_Stock: 0,
        IL_OrderQty: 0,
        IL_AvgSales: 0,
        IL_FinalQty: 0,
        B_Tsr_Qty: 0,
        B_Tsr_PackQty: 0,
        B_Tsr_BaseQty: 0,
        B_Tsr_Unit: '',
        B_Tsr_UnitRate: 0,
        // PurchaseRatio:0,
        // SuggestedTransfer:0,
        SuggestedTransferCTN: 0,
        IL_StockCTN: 0,
        IL_FinalQtyCTN: 0,
        id: sItemList.length + 1,
        units: []
      };
      let newList = [obj, ...sItemList];
      setItemList(newList);
      setSelectedID(sItemList.length + 1);
    }
    setItemSearchModal(true);
  };

  const onClickSave = async () => {
    if (validate()) {
      confirmAlert({
        title: 'Save',
        message: 'Are you sure to Save?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              await fSave();
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onClickPost = async () => {
    if (validate()) {
      confirmAlert({
        title: 'Post',
        message: 'Are you sure to Post?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              let saveRes = await fSave();
              if (saveRes) {
                await fPost();
              }
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };
  const onClickReverse = async () => {
    // const obj = {
    //     vocTyp: sHeadObj.vocTyp,
    //     vocNo: sHeadObj.vocNo,
    //     userCd: rdxuser.userId
    // }
    // const result = await reverseFPRequest(obj);
    // if (result.status === 200) {
    //     fAlertToast('SUCCESS', 'Reversed Successfully');
    //     setHeadObj({ ...sHeadObj, docStat: 'ENTER' });
    // }
  };

  const fAddItem = async (resultSet, id) => {
    if (resultSet?.Item.length > 0) {
      let changedList = resultSet?.Item?.map((i) => {
        let unitArray = resultSet?.Unit?.filter((j) => j.IL_ItmCd === i.IL_ItmCd);
        return {
          ...i,
          units: unitArray
        };
      });
      // console.log(changedList,id,"changedList")
      if (changedList?.length > 0) {
        const obj = sItemList?.map((i) => {
          if (i?.IL_ItmCd == '' && i?.id == id) {
            return {
              ...i,
              IL_ItmCd: changedList[0]?.IL_ItmCd,
              ItemName: changedList[0]?.ItemName,
              IL_Stock: changedList[0]?.IL_Stock,
              IL_OrderQty: changedList[0]?.IL_OrderQty,
              IL_AvgSales: changedList[0]?.IL_AvgSales,
              IL_FinalQty: changedList[0]?.IL_FinalQty,
              IL_FinalQtyCTN:
                Number(changedList[0].IL_FinalQty) /
                Number(
                  changedList[0].B_Tsr_PackQty === 0
                    ? changedList[0]?.units[0]?.PackQty
                    : changedList[0].B_Tsr_PackQty
                ),
              B_Tsr_Qty: changedList[0]?.B_Tsr_Qty,
              B_Tsr_PackQty: changedList[0]?.units[0]?.PackQty,
              CostPr: changedList[0]?.CostPr,
              IL_StockCTN:
                Number(changedList[0].IL_Stock) /
                Number(
                  changedList[0].B_Tsr_PackQty === 0
                    ? changedList[0]?.units[0]?.PackQty
                    : changedList[0].B_Tsr_PackQty
                ),
              B_Tsr_BaseQty: changedList[0]?.B_Tsr_BaseQty,
              B_Tsr_Unit: changedList[0]?.units[0]?.Unit,
              B_Tsr_UnitRate: Number(
                Number(changedList[0]?.CostPr) *
                  Number(
                    changedList[0].B_Tsr_PackQty === 0
                      ? changedList[0]?.units[0]?.PackQty
                      : changedList[0].B_Tsr_PackQty
                  )
              ),
              id: id,
              units: changedList[0]?.units
            };
          } else {
            return i;
          }
        });

        // console.log(obj, 'obj');
        setItemList(obj);
      }
    }
  };

  return (
    <div className="container">
      <div
      // className="my-2"
      >
        <TransferBreadCrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mb-3">
          <Grid item xs={4} sm={2} md={1}>
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onClickSave}
              xLabel={'Save'}
              xIcon={<Save />}
              xDisable={rdxHeadObj.status.toString().toUpperCase() !== 'ENTER'}
            />
          </Grid>

          <Grid item xs={4} sm={2} md={1}>
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onClickPost}
              xLabel={'Post'}
              xIcon={<Verified />}
              xDisable={rdxHeadObj.status.toString().toUpperCase() !== 'ENTER'}
            />
          </Grid>

          <Grid item xs={4} sm={2} md={1.2}>
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onClickPrint}
              xLabel={'Print'}
              xIcon={<Print />}
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1.5}>
            <CmpButtonWithIcon
              xvariant={'contained'}
              xsize={'small'}
              xonClick={onClickNewItem}
              xLabel={'Add Item'}
              xIcon={<AddCircle />}
              xDisable={rdxHeadObj.status.toString().toUpperCase() !== 'ENTER'}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="mb-2">
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={6} md={4}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={2} sm={3} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Doc.Type'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={9.5} sm={8.5} md={8}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={`${rdxHeadObj.vocTyp} - ${rdxHeadObj.vocName} `}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={3} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Doc.No.'} />
                  </Grid>
                  <Grid item xs={1} sm={0.5} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={8.5} md={8}>
                    <CmpTypographyField xcomponent={'span'} xText={rdxHeadObj.vocNo} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={3} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Date'} />
                  </Grid>
                  <Grid item xs={1} sm={0.5} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={8.5} md={8}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={moment(rdxHeadObj.vocDt).format('DD/MM/YYYY')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={6} md={4}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={3} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Branch'} />
                  </Grid>
                  <Grid item xs={1} sm={0.5} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={8.5} md={8}>
                    <CmpTypographyField xcomponent={'span'} xText={rdxHeadObj.locName} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={3} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Distributing Loc'} />
                  </Grid>
                  <Grid item xs={1} sm={0.5} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={8.5} md={8}>
                    <CmpTypographyField xcomponent={'span'} xText={rdxHeadObj.dcLocName} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={4} sm={3} md={3}>
                    <CmpTypographyField xcomponent={'span'} xText={'Posting Date'} />
                  </Grid>
                  <Grid item xs={1} sm={0.5} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={7} sm={8.5} md={6}>
                    <CmpDatePickerField
                      xValue={sHeadObj.postDt}
                      xOnChange={(newValue) => setHeadObj({ ...sHeadObj, postDt: newValue.$d })}
                      xName={'post Date'}
                      xError={false}
                      xErrorMessage={false}
                      xOnKeyUp={''}
                      xTabIndex={'1'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {rdxHeadObj.tsrVocNo !== '' && (
                <Grid item xs={6} sm={6} md={4}>
                  <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}>
                    <Grid item xs={4} sm={3} md={3}>
                      <CmpTypographyField xcomponent={'span'} xText={'Transfer No.'} />
                    </Grid>
                    <Grid item xs={1} sm={0.5} md={1}>
                      <CmpTypographyField xcomponent={'span'} xText={':'} />
                    </Grid>
                    <Grid item xs={7} sm={8.5} md={8}>
                      <a
                        href={`${reportBaseUrl}FreshPurchase/StockTransfer?trntyp=INV10&voctyp=${rdxHeadObj.tsrVocTyp}&vocno=${rdxHeadObj.tsrVocNo}&prnTyp=INV10INTR`}
                        target="_blank">
                        {rdxHeadObj.tsrVocTyp + ' - ' + rdxHeadObj.tsrVocNo}
                      </a>
                      {/* <CmpTypographyField xcomponent={'span'} xText={rdxHeadObj.tsrVocTyp + ' - ' + rdxHeadObj.tsrVocNo} /> */}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <TransferItemGrid
          xMobileScreen={vMobileScreen}
          xMediaQuery={vMediaQuery}
          xItemList={sItemList}
          xSetItemList={setItemList}
          rdxHeadObj={rdxHeadObj}
          fAddItem={fAddItem}
          sItemSearchModal={sItemSearchModal}
          setItemSearchModal={setItemSearchModal}
          setSelectedID={setSelectedID}
          selectedID={selectedID}
          sItemPackQtyModal={sItemPackQtyModal}
          setItemPackQtyModal={setItemPackQtyModal}
        />
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          className="mt-2"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={5} sm={5} md={1}>
            <CmpStatusDiv xStatus={rdxHeadObj.status} />
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={openPrintModal}
        onClose={() => setOpenPrintModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box className="GCSearchBtn-Tittle">
            <span>Print</span>
            <CloseIcon
              sx={{ zIndex: 999, float: 'right' }}
              onClick={() => setOpenPrintModal(false)}
            />
          </Box>
          <iframe src={printWebURL} width="100%" height={500}></iframe>
        </Box>
      </Modal>
    </div>
  );
}
