import * as React from 'react';
import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';
import grapes from '../../../Images/grapes.jpg';

import { CmpTypographyField } from '../../../component/ToolBox/ToolBox';
import OrderLocation from './OrderLocation';

export default function OrderCardContent(props) {
  const StyledParagraph = styled.p`
    font-size: 12px;
    padding: 2px;
    margin: 0 auto;
    color: #0d73bb;
  `;
  return (
    <React.Fragment>
      {/* <CardContent > */}
      <Box sx={{ flexGrow: 1 }} fontSize={1}>
        <Grid container spacing={1}>
          <Grid item xs={3} align="center">
            <Avatar
              alt=" "
              src={`${props.data.IMAGE_BASE_URL}product/${props.data.Barcode}.jpg?imgVer=${props.data.Version}`}
              sx={{ width: 60, height: 60, border: '2px solid #0d73bb', backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container align="left">
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={4.5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Consolidation'} />
                  </Grid>
                  <Grid item xs={2}>
                    <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                    {/* <StyledParagraph>Qty</StyledParagraph> */}
                  </Grid>
                  <Grid item xs={3.5} className="text-end">
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(props.data.BasseQty).toFixed(2)}
                    />
                    {/* <StyledParagraph>{Number(props.data.BasseQty).toFixed(2)}</StyledParagraph> */}
                  </Grid>
                  <Grid item xs={2}>
                    <CmpTypographyField xcomponent={'span'} xText={props.data.UnitName} />
                    {/* <StyledParagraph>{props.data.UnitName}</StyledParagraph> */}
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={4.5}>
                      <CmpTypographyField xcomponent={'span'} xText={'Purchased'} />
                      {/* <StyledParagraph>Purchased</StyledParagraph> */}
                    </Grid>
                    <Grid item xs={2}>
                      <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                      {/* <StyledParagraph>Qty</StyledParagraph> */}
                    </Grid>
                    <Grid item xs={3.5} className="text-end">
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={Number(props.data.PurchasedQty).toFixed(2)}
                      />
                      {/* <StyledParagraph>
                        {Number(props.data.PurchasedQty).toFixed(2)}
                      </StyledParagraph> */}
                    </Grid>
                    <Grid item xs={2}>
                      <CmpTypographyField xcomponent={'span'} xText={props.data.UnitName} />
                      {/* <StyledParagraph>{props.data.UnitName}</StyledParagraph> */}
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={4.5}>
                      <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                      {/* <StyledParagraph>Balance</StyledParagraph> */}
                    </Grid>
                    <Grid item xs={2}>
                      <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
                      {/* <StyledParagraph>Qty</StyledParagraph> */}
                    </Grid>
                    <Grid item xs={3.5} className="text-end">
                      <CmpTypographyField
                        xcomponent={'span'}
                        xText={Number(props.data.BasseQty - props.data.PurchasedQty).toFixed(2)}
                      />
                      {/* <StyledParagraph>
                        {Number(props.data.BasseQty - props.data.PurchasedQty).toFixed(2)}{' '}
                      </StyledParagraph> */}
                    </Grid>
                    <Grid item xs={2}>
                      <CmpTypographyField xcomponent={'span'} xText={props.data.UnitName} />
                      {/* <StyledParagraph>{props.data.UnitName}</StyledParagraph> */}
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        {/* <StyledParagraph>
          Avg purchased cost AED : {Number(props.data.AvgCost).toFixed(2)}{' '}
        </StyledParagraph>
        <StyledParagraph>Best vendor: {props.data.BestVendorName}</StyledParagraph> */}
        <CmpTypographyField
          xcomponent={'span'}
          xText={'Avg purchased cost AED ' + Number(props.data.AvgCost).toFixed(2)}
        />
        <br />
        <CmpTypographyField
          xcomponent={'span'}
          xText={'Best vendor: ' + props.data.BestVendorName}
        />
        {/* <br /> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <OrderLocation data={props.data} />
        </div>
      </Box>
      {/* </CardContent> */}
    </React.Fragment>
  );
}
