import { Box } from '@mui/material';
import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import Order from './Order';
import OrderBreadCrumbs from './ProcessComponents/OrderBreadCrumbs';

export default function PendingOrder() {
    useEffect(() => {
        console.log("renderrrrr");
    }, []);

    return (
        <div className="container Order">
            <div 
            // className="my-2"
            >
                <OrderBreadCrumbs />
            </div>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1, height: '75vh', overflow: 'auto', paddingRight: '10px' }}>
                <Order xStatus='Enter' />
            </Box>
        </div>
    )
}
