import React, { useEffect, useRef } from 'react';
import { DataGrid, GridFooter, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import CustomFooterTotal from './CustomFooterTotal';
import { Box } from '@mui/material';


export default function ReportGrid({ xRows, xColumns, xIsMIS = false }) {

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={xRows}
                // getRowId={(row) => row.SrNo}
                columns={xColumns}
                rowHeight={30}
                headerHeight={60}
                sx={{
                    border: 0,
                    borderColor: 'primary.light',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    '& .MuiDataGrid-row:hover': {
                        color: 'primary.main',
                        cursor: 'pointer',
                        fontWeight: 500,
                        // backgroundColor: '#ebeef0',
                        //boxShadow: 'rgba(0, 0, 0, 0.35) 10px 15px 15px'
                    },

                }}
                components={{
                    Toolbar: GridToolbar,
                    Footer: xIsMIS ? CustomFooterTotal : GridFooter

                }}
                componentsProps={{
                    footer: { rows: xRows },
                    filterPanel: { sx: { maxWidth: "93vw" } }

                }}
                // getRowClassName={(params) =>
                //     params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-report-grid-even-row' : 'mui-report-grid-odd-row'
                // }
                getRowClassName={(params) =>
                    'mui-report-grid-row'
                }

            />
        </Box>
    )
}
