import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { CmpButton, CmpDatePickerField, CmpSelectField, CmpTypographyField, CmpInputField, CmpInput } from '../../component/ToolBox/ToolBox';
import GC_VendorSearch from '../../component/ReuseableComponent/GC_VendorSearch';
import { deletePriceEnquiryItem, getPriceEnquiryTransDetail, savePriceEnquiryTrans, searchItem, searchVendor } from './actions'
import GCDropdownSearch from '../../component/ReuseableComponent/GCDropdownSearch';
import { fAlertToast } from '../../Utility/Utilitys';
import { updateTransHead } from './PriceEnquirySlice';
import { Delete } from '@mui/icons-material';
import PEBreadcrumbs from './ProcessComponents.jsx/PEBreadcrumbs';

export default function PriceEnquiryDetail() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
    const rdxHeadObj = useSelector((state) => state.PriceEnquirySlice.transHead);
    const rdxuserId = useSelector((state) => state.SliceDB.value.login.userId);
    const [sItemList, setItemList] = useState([]);
    const [sHeadObj, setHeadObj] = useState({
        vocTyp: '',
        VocTypName: '',
        vocNo: '',
        vocDate: new Date(),
        locCd: '',
        locName: '',
        partyCd: '',
        partyName: '',
        deptCd: '',
        deptName: '',
        status: 'ENTER'
    });
    const [sDeatilObj, setDetailObj] = useState({
        B_Srno: 0,
        B_Itmcd: '',
        ItmName: '',
        B_UnitRate: 0,
        B_VendorPackQty: 0,
        B_NetPackQty: 0,
    });

    const [sSearchTextVendor, setSearchTextVendor] = useState(rdxHeadObj.partyName);
    const [sSearchTextItem, setSearchTextItem] = useState('');


    const initialErrormsg = {
        locCd: '',
        partyCd: '',
        B_Itmcd: '',
        B_UnitRate: '',
        B_VendorPackQty: '',
        B_NetPackQty: '',
    }
    const [sErrorMsg, setErrorMsg] = useState(initialErrormsg);


    const fLoadItems = useCallback(async () => {
        if (rdxHeadObj.vocNo !== '') {
            const obj = {
                vocTyp: rdxHeadObj.vocTyp,
                vocNo: rdxHeadObj.vocNo
            };
            const result = await getPriceEnquiryTransDetail(obj);
            console.log(result);
            result ? setItemList(result.ResultSet) : setItemList([]);
        }
    }, [rdxHeadObj]);


    const onPageLoad = useCallback(async () => {
        setHeadObj(rdxHeadObj);
        await fLoadItems();
    }, [rdxHeadObj, fLoadItems]);

    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);



    const fSearchVendor = async (searchText) => {
        const obj = {
            searchText: searchText
        }

        const result = await searchVendor(obj);
        const resultSet = result.data ? result.data.ResultSet : [];
        return resultSet ? resultSet.map(i => { return { ...i, id: i.AcCd } }) : [];
    }

    const fSearchItem = async (searchText) => {
        let searchObj = {
            v_AnyData: searchText,
            v_Cd: '',
            v_Name1: '',
            v_Barcode: '',
            v_Model: sHeadObj.deptCd,
            v_RefNo1: '',
            v_NameFL: '',
            v_Loc: ''
        };

        const res = await searchItem(searchObj);
        const resultSet = res?.ResultSet ? res?.ResultSet : [];
        return resultSet.map((i, index) => { return { ...i, id: index } });

    };


    const Validation = () => {
        setErrorMsg(initialErrormsg);
        let res = true;
        if (sHeadObj.locCd == '') {
            setErrorMsg((obj) => ({ ...obj, locCd: 'Location is Empty' }));
            res = false;
        }
        if (sHeadObj.partyCd == '') {
            setErrorMsg((obj) => ({ ...obj, partyCd: 'Vendor is not selected' }));
            res = false;
        }
        if (sDeatilObj.B_Itmcd == '') {
            setErrorMsg((obj) => ({ ...obj, B_Itmcd: 'Item is not selected' }));
            res = false;
        }

        if (sDeatilObj.B_UnitRate == '') {
            setErrorMsg((obj) => ({ ...obj, B_UnitRate: 'Unit Rate is Empty' }));
            res = false;
        }
        if (sDeatilObj.B_VendorPackQty == '') {
            setErrorMsg((obj) => ({ ...obj, B_VendorPackQty: 'Pack Qty. is Empty' }));
            res = false;
        }
        if (sDeatilObj.B_NetPackQty == '') {
            setErrorMsg((obj) => ({ ...obj, B_NetPackQty: 'Net Pack qty is Empty' }));
            res = false;
        }

        return res;
    };

    const fNewDetail = () => {
        setDetailObj({
            B_Itmcd: '',
            ItmName: '',
            B_UnitRate: 0,
            B_VendorPackQty: 0,
            B_NetPackQty: 0,
            B_Srno: 0
        });
        setSearchTextItem('');
    };
    const fOnRowClick = (params) => {
        console.log(params);
        if (params.field !== 'delete') {
            setDetailObj({
                B_Itmcd: params.row.B_Itmcd,
                ItmName: params.row.ItmName,
                B_UnitRate: params.row.B_UnitRate,
                B_VendorPackQty: params.row.B_VendorPackQty,
                B_NetPackQty: params.row.B_NetPackQty,
                B_Srno: params.row.B_Srno,
            });
            setSearchTextItem(params.row.ItmName);
        }
    };

    const fSave = async () => {
        if (Validation()) {

            let obj = {
                vocTyp: sHeadObj.vocTyp,
                postDt: moment(sHeadObj.postDt).format("MM/DD/YYYY"),
                loc: sHeadObj.locCd,
                party: sHeadObj.partyCd,
                dept: sHeadObj.deptCd,
                itmcd: sDeatilObj.B_Itmcd,
                unitRate: sDeatilObj.B_UnitRate,
                vendorPackQty: sDeatilObj.B_VendorPackQty,
                netPackQty: sDeatilObj.B_NetPackQty,
                userId: rdxuserId,
            };

            const result = await savePriceEnquiryTrans(obj);
            if (result.status === 200) {
                fAlertToast("SUCCESS", "Saved successfully");
                dispatch(updateTransHead({
                    ...rdxHeadObj,
                    vocNo: result.data?.ResultSet[0]?.vocNo,
                    locCd: sHeadObj.locCd,
                    locName: sHeadObj.locName,
                    partyCd: sHeadObj.partyCd,
                    partyName: sHeadObj.partyName,
                }));
                setHeadObj({ ...sHeadObj, vocNo: result.data?.ResultSet[0]?.vocNo });
                fNewDetail();
                // console.log("savedddd");
                // await fLoadItems();
                // console.log("loaded");

            }

        }
    };

    const onDeleteCellClick = async (params) => {

        let obj = {
            vocTyp: sHeadObj.vocTyp,
            vocNo: sHeadObj.vocNo,
            srNo: Number(params.row.B_Srno),
        }

        const result = await deletePriceEnquiryItem(obj);
        if (result.status === 200) {
            fAlertToast("SUCCESS", "Deleted Successfully");
            await fLoadItems();
            // dispatch(updateTransHead({
            //     ...rdxHeadObj,
            //     vocNo: result.data?.ResultSet[0]?.vocNo,
            //     locCd: sHeadObj.locCd,
            //     locName: sHeadObj.locName,
            //     partyCd: sHeadObj.partyCd,
            //     partyName: sHeadObj.partyName,
            // }));
            //setHeadObj({ ...sHeadObj, vocNo: result.data?.ResultSet[0]?.vocNo });
            //fNewDetail();
            // console.log("savedddd");
            // await fLoadItems();
            // console.log("loaded");

        }
    };


    const columns = [
        {
            field: 'B_Srno',
            headerName: 'Sr No.',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'B_Itmcd',
            headerName: 'Item Cd',
            flex: 2,
        },
        {
            field: 'ItmName',
            headerName: 'Item Name',
            flex: 3,
        },
        {
            field: 'B_UnitRate',
            headerName: 'Unit Rate',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'B_VendorPackQty',
            headerName: 'Vendor Pack Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'B_NetPackQty',
            headerName: 'Net Pack Qty',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'B_BaseRate',
            headerName: 'Base Rate',
            flex: 2,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => Number(value).toLocaleString('en')
        },
        {
            field: 'delete',
            headerName: '',
            flex: 2,
            align: 'center',
            headerAlign: 'center',
            hide: false,
            renderCell: (params) => {

                return (
                    <IconButton size='small' color='primary' onClick={() => { onDeleteCellClick(params); }} disabled={sHeadObj.status.toString().toUpperCase() !== 'ENTER'}>
                        <Delete />
                    </IconButton>
                );
            }
        },

    ];

    const xVendorColumns = [
        {
            field: 'AcCd',
            headerName: 'Party Code',
            flex: 1
        },
        {
            field: 'Name2',
            headerName: 'Party Name',
            flex: 3
        },
        {
            field: 'Mobile',
            headerName: 'Mobile',
            flex: 2
        },
    ];
    const xItemColumns = [
        {
            field: 'ItmCd',
            headerName: 'Item Code',
            flex: 1
        },
        {
            field: 'Name2',
            headerName: 'Item Name',
            flex: 3
        },
        {
            field: 'BarCode',
            headerName: 'Barcode',
            flex: 1
        },
    ];

    return (
        <div className='container'>
            <div >
                <PEBreadcrumbs />
            </div>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mb-3'>
                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fNewDetail}
                            xLabel={'New'}
                            xDisable={sHeadObj.status.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            xonClick={fSave}
                            xLabel={'Save'}
                            xDisable={sHeadObj.status.toString().toUpperCase() !== 'ENTER'}
                        />
                    </Grid>

                    {/* <Grid item xs={4} sm={2} lg={1} md={1}>
                        <CmpButton
                            xvariant={'contained'}
                            xsize={'small'}
                            //   xonClick={fPostPurcchaseTrans}
                            xLabel={'Post'}
                        // xDisable={}
                        />
                    </Grid> */}

                </Grid>
                <Grid
                    container
                    spacing={1}
                    columnSpacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}
                    className='mb-3'>


                    <Grid item xs={12} sm={12} md={12}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Voucher Date'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={4} md={7}>
                                        <CmpTypographyField xcomponent={'span'} xText={moment(sHeadObj.vocDate).format("DD/MM/YYYY")} />
                                        {/* <CmpDatePickerField
                                            xTabIndex="2"
                                            xValue={sHeadObj.vocDate}
                                            xOnChange={(newValue) => setHeadObj((obj) => ({ ...sHeadObj, vocDate: newValue.$d }))}
                                            xName={'Date'}
                                            xError={false}
                                            xErrorMessage={''}
                                            xOnKeyUp={''}
                                        /> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <GCDropdownSearch
                                            xValue={sHeadObj.partyCd}
                                            xOnChange={(params) => { setHeadObj({ ...sHeadObj, partyCd: params.row.AcCd, partyName: params.row.Name2 }); setSearchTextVendor(params.row.Name2) }}
                                            xType='text'
                                            xName='vendor'
                                            xTextAlign='left'
                                            xTabIndex='1'
                                            xSearchText={sSearchTextVendor}
                                            xSetSearchText={setSearchTextVendor}
                                            xOnBackSpaceKeyPress={() => setHeadObj({ ...sHeadObj, partyCd: '', partyName: '' })}
                                            xResultColumns={xVendorColumns}
                                            xLoadDetail={async (searchText) => fSearchVendor(searchText)}
                                            xDisable={false}
                                            xError={sErrorMsg.partyCd !== ''}
                                            xErrorMessage={sErrorMsg.partyCd}
                                        // xref = ''
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Location'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <CmpSelectField
                                            xTabIndex="4"
                                            xLabelText="DC"
                                            xValue={sHeadObj.locCd}
                                            xOnChange={(event) => { setHeadObj({ ...sHeadObj, locCd: event.target.value }) }}
                                            xName={'DC'}
                                            xData={dcLoclist}
                                            xValueMember={''}
                                            xDispalyMember={''}
                                            xError={sErrorMsg.locCd !== ''}
                                            xErrorMessage={sErrorMsg.locCd}
                                            xDisable={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Department'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={4} md={7}>
                                        <CmpTypographyField xcomponent={'span'} xText={rdxHeadObj.deptName} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Item'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <GCDropdownSearch
                                            xValue={sDeatilObj.B_Itmcd}
                                            xOnChange={(params) => { setDetailObj({ ...sDeatilObj, B_Itmcd: params.row.ItmCd, ItmName: params.row.Name2 }); setSearchTextItem(params.row.Name2) }}
                                            xType='text'
                                            xName='Item'
                                            xTextAlign='left'
                                            xTabIndex='1'
                                            xSearchText={sSearchTextItem}
                                            xSetSearchText={setSearchTextItem}
                                            xOnBackSpaceKeyPress={() => setDetailObj({ ...sDeatilObj, B_Itmcd: '', ItmName: '' })}
                                            xDisable={false}
                                            xResultColumns={xItemColumns}
                                            xLoadDetail={async (searchText) => fSearchItem(searchText)}
                                            xError={sErrorMsg.B_Itmcd !== ''}
                                            xErrorMessage={sErrorMsg.B_Itmcd}
                                        // xref = ''
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Unit Rate'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={5} sm={4} md={2}>
                                        <CmpInput
                                            xTabIndex="1002"
                                            xValue={Number(sDeatilObj.B_UnitRate)}
                                            xType={'number'} // text/number/password
                                            xName={'B_UnitRate'}
                                            xReadOnly={false}
                                            xOnChange={(event) => { setDetailObj({ ...sDeatilObj, B_UnitRate: Number(event.target.value) }) }}
                                            xError={sErrorMsg.B_UnitRate === '' ? false : true}
                                            xErrorMessage={sErrorMsg.B_UnitRate}
                                            xOnKeyUp={''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Pack Qty'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={5} sm={4} md={2}>
                                        <CmpInput
                                            xTabIndex="1002"
                                            xValue={Number(sDeatilObj.B_VendorPackQty)}
                                            xType={'number'} // text/number/password
                                            xName={'B_VendorPackQty'}
                                            xReadOnly={false}
                                            xOnChange={(event) => { setDetailObj({ ...sDeatilObj, B_VendorPackQty: Number(event.target.value) }) }}
                                            xError={sErrorMsg.B_VendorPackQty === '' ? false : true}
                                            xErrorMessage={sErrorMsg.B_VendorPackQty}
                                            xOnKeyUp={''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Net Pack Qty.'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={5} sm={4} md={2}>
                                        <CmpInput
                                            xTabIndex="1002"
                                            xValue={Number(sDeatilObj.B_NetPackQty)}
                                            xType={'number'} // text/number/password
                                            xName={'B_NetPackQty'}
                                            xReadOnly={false}
                                            xOnChange={(event) => { setDetailObj({ ...sDeatilObj, B_NetPackQty: Number(event.target.value) }) }}
                                            xError={sErrorMsg.B_NetPackQty === '' ? false : true}
                                            xErrorMessage={sErrorMsg.B_NetPackQty}
                                            xOnKeyUp={''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Box sx={{ height: '45vh', width: '100%' }} className="mt-4">
                    <DataGrid
                        rowHeight={50}
                        headerHeight={50}
                        getRowId={(row) => row.B_Srno}
                        sx={{
                            border: 2,
                            borderColor: 'primary.light'
                        }}
                        componentsProps={{
                            filterPanel: { sx: { maxWidth: "93vw" } }
                          }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                        }
                        rows={sItemList}
                        columns={columns}
                        //onRowClick={(params) => fOnRowClick(params)}
                        onCellClick={(params) => { fOnRowClick(params); }}

                    />
                </Box>
            </Box>
        </div>
    )
}
