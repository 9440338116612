import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton
} from '../../../component/ToolBox/ToolBox';
function PurchaseReturnDetailCard({
  xDisptachNo,
  xLoc,
  xItmCode,
  xItmName,
  xPickedQty,
  xDisptached,
  xConfrmQty_Value,
  xOnchangeConfrmQty,
  xUnitRateValue,
  xOnchangeUnitRate,
  xNetAmnt,
  xAdjQty,
  xTaxDeptArr,
  xTaxAmnt,
  xTaxDeptValue,
  xOnChangeTxDept,
  xUnit
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid
        container
        spacing={0.1}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'left' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Dispatch No'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xDisptachNo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Branch'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xLoc} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={2} md={2}>
              <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
            </Grid>
            <Grid item xs={1} sm={0.5} md={0.5}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={9} md={9} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xItmCode} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={2} md={2}>
              <CmpTypographyField xcomponent={'span'} xText={'Item Name'} />
            </Grid>
            <Grid item xs={1} sm={0.5} md={0.5}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid
              item
              xs={7}
              sm={9}
              md={9}
              sx={{ textAlign: 'left' }}
              className="txt-overflow-ellipse">
              <CmpTypographyField xcomponent={'span'} xText={xItmName} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Picked Qty'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={xPickedQty} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Dispatched'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={5} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={xDisptached} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Confirm Qty'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpInputField
                xTextAlign="center"
                xValue={xConfrmQty_Value}
                xType={'number'} // text/number/password
                xName={'ConfirmQty'}
                xReadOnly={false}
                xOnChange={xOnchangeConfrmQty}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Unit Rate'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={5} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpInputField
                xTextAlign="center"
                xValue={xUnitRateValue}
                xType={'number'} // text/number/password
                xName={'ConfirmQty'}
                xReadOnly={false}
                xOnChange={xOnchangeUnitRate}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Unit'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={5} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={xUnit} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>

          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Net Amt'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={5} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xNetAmnt).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Adj.Qty'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={5} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xAdjQty).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={5} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Tax.Dept'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={6} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpSelectField
                xLabelText="TaxDept"
                xValue={xTaxDeptValue}
                xOnChange={xOnChangeTxDept}
                xName={'TaxDept'}
                xData={xTaxDeptArr}
                xValueMember={''}
                xDispalyMember={''}
                xError={false}
                xErrorMessage={false}
                xDisable={false}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={6} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Tax.Amt'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={5} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xTaxAmnt).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
}
export default PurchaseReturnDetailCard;
