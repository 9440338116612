
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function OpenPurchaseBreadcrumbs({ xState = 'OPEN' }) {
    const location = useLocation();
    const vPathName = location.pathname;
    return (
        <div className="container-fluid p-0 mt-0 mb-3 breadcrumb">
            {vPathName === '/OpenPurchaseSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Open Purchase Summary</span>
                </div>

            ) : vPathName === '/PostedPurchaseSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Posted Purchase Summary</span>
                </div>

            ) : vPathName === '/OpenPurchaseDetail' ? (
                <div className="d-flex">
                    <Link
                        to={"/OpenPurchaseSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        Open Purchase Summary
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Open Purchase Detail </span>
                    </div>
                </div>
            ) : vPathName === '/PurchaseDetail' ? (
                <div className="d-flex">
                    <Link
                        to={xState === 'OPEN' ? "/OpenPurchaseSummary" : "/PostedPurchaseSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        {xState === 'OPEN' ? 'Open Purchase Summary' : 'Posted Purchase Summary'}
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">Purchase Detail </span>
                    </div>
                </div>
            ) : vPathName === '/BufferPurchaseSummary' ? (
                <div>
                    <span className="breadcrumb-arrow-inactive ">Buffer Summary</span>
                </div>

            ) : vPathName === '/PurchaseConfirmDetail' ? (
                <div className="d-flex">
                    <Link
                        to={xState === 'RECEIVED' ? "/BufferPurchaseSummary" : "/ConfirmedPurchaseSummary"}
                        state={location}
                        className={'breadcrumb-arrow-active mr-2'}>
                        {xState === 'RECEIVED' ? 'Buffer Purchase Summary' : 'Confirmed Summary'}
                    </Link>
                    <div>
                        <span className="breadcrumb-arrow-inactive">{xState === 'RECEIVED' ? 'Buffer Purchase Detail' : 'Confirmed Detail'} </span>
                    </div>
                </div>
            )

                : (
                    <></>

                )}
        </div>
    );
}
