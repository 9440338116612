import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { CmpButton, CmpSelectArrField, CmpTypographyField } from '../../component/ToolBox/ToolBox'
import GC_VendorSearch from '../../component/ReuseableComponent/GC_VendorSearch'
import { getPriceEnquiryDefaultValues, getPriceEnquiryTransSummary } from './actions'
import { DataGrid } from '@mui/x-data-grid'
import { fAlertToast } from '../../Utility/Utilitys'
import { updateFilterHead, updateTransHead } from './PriceEnquirySlice'
import { useNavigate } from 'react-router-dom'
import PEBreadcrumbs from './ProcessComponents.jsx/PEBreadcrumbs'

export default function PriceEnquirySummary() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
    const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
    const rdxHeadFilterObj = useSelector((state) => state.PriceEnquirySlice.filterHead);
    const [sSearchText, setSearchText] = useState(rdxHeadFilterObj.vendorname);
    const [sDefaultValues, setDefaultValues] = useState({
        vocTyp: '',
        vocName: '',
        dept: '',
        deptName: ''
    });
    const [sFilteredDCNames, setFilteredDCNames] = useState([]);
    const typeList = ["All", "Is"];
    // const [sHeadObj, setHeadObj] = useState({
    //     // DClocation: rdxFilterObj.DCList,
    //     // dateFrom: rdxFilterObj.dateFrom,
    //     // dateTo: rdxFilterObj.dateTo,
    //     vendorTyp: 'All',
    //     vendorCd: '',
    //     vendorname: ''
    // });
    const [sHeadObj, setHeadObj] = useState({
        vendorTyp: rdxHeadFilterObj.vendorTyp,
        vendorCd: rdxHeadFilterObj.vendorCd,
        vendorname: rdxHeadFilterObj.vendorname
    });
    const [sDetail, setDetail] = useState([]);

    const fGetDetailList = useCallback(async (obj) => {

        const reqObj =
        {
            locList: rdxFilterObj.DCList.toString(),
            dateFrom: moment(rdxFilterObj.dateFrom).format("MM/DD/YYYY"),
            dateTo: moment(rdxFilterObj.dateTo).format("MM/DD/YYYY"),
            vendor: obj.vendorTyp === 'All' ? 'All' : obj.vendorCd
        }

        const result = await getPriceEnquiryTransSummary(reqObj);
        setDetail(result?.ResultSet);
    }, [rdxFilterObj]);

    const fOnPageLoad = useCallback(async () => {

        let filteredDC = dcLoclist.filter(i => rdxFilterObj.DCList.includes(i.UId));
        setFilteredDCNames(filteredDC.map(i => i.Name1));

        const res1 = await getPriceEnquiryDefaultValues({});
        const resultSet = res1?.ResultSet;
        if (resultSet.length > 0)
            setDefaultValues(resultSet[0]);

        // let currObj = {
        //     vendorTyp: rdxHeadFilterObj.vendorTyp,
        //     vendorCd: rdxHeadFilterObj.vendorCd,
        //     vendorname: rdxHeadFilterObj.vendorname
        // }
        //setHeadObj(currObj);
        await fGetDetailList(sHeadObj);
    }, [dcLoclist, rdxFilterObj, fGetDetailList]);


    useEffect(() => {
        //scanFocusOnLoad.current.focus();
        fOnPageLoad();
        console.log("calleddd");
    }, [fOnPageLoad]);

    const fOnpressLoad = async () => {
        if (sHeadObj.vendorTyp !== 'All' && sHeadObj.vendorCd === "") {
            fAlertToast("FAILED", "Select Vendor");
            return;
        }
        await fGetDetailList(sHeadObj);
        dispatch(updateFilterHead(sHeadObj));
    }


    const fOnpressNew = async () => {
        dispatch(updateTransHead({
            vocTyp: sDefaultValues.vocTyp,
            VocTypName: sDefaultValues.vocName,
            vocNo: '',
            vocDate: new Date(),
            locCd: '',
            locName: '',
            partyCd: '',
            partyName: '',
            deptCd: sDefaultValues.dept,
            deptName: sDefaultValues.deptName,
            status: 'ENTER'
        }));
        navigate('/PriceEnquiryDetail');
    }

    const onChangeVendorTyp = (event) => {
        if (event.target.value === 'All') {
            setHeadObj((obj) => ({ ...obj, vendorTyp: event.target.value, vendorCd: '', vendorname: '' }));
            setSearchText('');
        }
        else {
            setHeadObj((obj) => ({ ...obj, vendorTyp: event.target.value }));
        }
    }

    const columns = [
        // {
        //     field: 'VocTypName',
        //     headerName: 'Voucher',
        //     flex: 2
        // },
        // {
        //     field: 'VocNo',
        //     headerName: 'Voucher No.',
        //     flex: 1
        // },
        {
            field: 'VocDate',
            headerName: 'Date',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => moment(value).format("DD/MM/YYYY")
        },
        {
            field: 'VendorName',
            headerName: 'Vendor',
            flex: 3
        },
        {
            field: 'LocName',
            headerName: 'Location',
            flex: 2
        },

        {
            field: 'DeptName',
            headerName: 'Department',
            flex: 2
        },
        // {
        //     field: 'Status',
        //     headerName: 'Status',
        //     flex: 1
        // },
    ];

    const fOnRowClick = (row) => {
        dispatch(updateTransHead({
            vocTyp: row.VocTypCode,
            VocTypName: row.VocTypName,
            vocNo: row.VocNo,
            vocDate: row.VocDate,
            locCd: row.LocCode,
            locName: row.LocName,
            partyCd: row.VendorCode,
            partyName: row.VendorName,
            deptCd: row.DeptCode,
            deptName: row.DeptName,
            status: row.Status
        }));
        navigate('/PriceEnquiryDetail');
    }

    return (
        <div className='container'>
            <div 
            // className="my-2"
            >
                <PEBreadcrumbs />
            </div>
            <div>
                <ToastContainer />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    spacing={1}
                    columnSpacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    sx={{ alignItems: 'center' }}
                    className='mb-3'>

                    <Grid item xs={6} sm={6} md={3}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={5.5} sm={4} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={4.5} sm={4} md={4.5}>
                                <CmpTypographyField xcomponent={'span'} xText={moment(rdxFilterObj.dateFrom).format("DD/MM/YYYY")} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={5} sm={4} md={4}>
                                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={4.5} sm={4} md={4.5}>
                                <CmpTypographyField xcomponent={'span'} xText={moment(rdxFilterObj.dateTo).format("DD/MM/YYYY")} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={2.7} sm={2} md={3}>
                                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={.5}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={8.3} sm={9} md={7}>
                                <CmpTypographyField xcomponent={'span'} xText={sFilteredDCNames.toString()} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            className='mt-1'
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={2.7} sm={2} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={0.25}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={1}>
                                <CmpSelectArrField
                                    xTabIndex="2"
                                    xLabelText="VendorType"
                                    xValue={sHeadObj.vendorTyp}
                                    //xOnChange={(event) => setHeadObj((obj) => ({ ...obj, vendorTyp: event.target.value }))}
                                    xOnChange={(event) => onChangeVendorTyp(event)}
                                    xName={'vendorTyp'}
                                    xData={typeList}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={false}
                                />
                            </Grid>

                            <Grid item xs={6.3} sm={7} md={6.75}>
                                <GC_VendorSearch
                                    xValue={sHeadObj.vendorCd}
                                    xOnChange={(params) => { setHeadObj({ ...sHeadObj, vendorCd: params.row.AcCd, vendorname: params.row.Name2 }); setSearchText(params.row.Name2) }}
                                    xType='text'
                                    xName='vendor'
                                    xTextAlign='left'
                                    xTabIndex='1'
                                    xSearchText={sSearchText}
                                    xSetSearchText={setSearchText}
                                    xOnBackSpaceKeyPress={() => setHeadObj({ ...sHeadObj, vendorCd: '', vendorname: '' })}
                                    xDisable={sHeadObj.vendorTyp === 'All'}
                                // xref = ''
                                />
                            </Grid>
                            <Grid item xs={6} sm={8} md={1} >
                            </Grid>
                            <Grid item xs={3} sm={2} md={1} className='text-end' >
                                <CmpButton
                                    xvariant={'contained'}
                                    xsize={'small'}
                                    xonClick={fOnpressLoad}
                                    xLabel={'Load'}
                                />
                            </Grid>
                            <Grid item xs={3} sm={2} md={1} className='text-end'>
                                <CmpButton
                                    xvariant={'contained'}
                                    xsize={'small'}
                                    xonClick={fOnpressNew}
                                    xLabel={'New'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ height: '65vh', width: '100%' }} className="mt-4">
                    <DataGrid
                        rowHeight={50}
                        headerHeight={50}
                        getRowId={(row) => row.VocTypCode + row.VocNo}
                        sx={{
                            border: 2,
                            borderColor: 'primary.light'
                        }}
                        componentsProps={{
                            filterPanel: { sx: { maxWidth: "93vw" } }
                          }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                        }
                        rows={sDetail}
                        columns={columns}
                        onRowClick={(params) => fOnRowClick(params.row)}
                    />
                </Box>
            </Box>
        </div>
    )
}
