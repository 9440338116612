import { fExecuteAPI } from '../../Server/Apis';

export const getPriceEnquiryTransSummary = async (xObj) => {
  try {
    const result = await fExecuteAPI('PRICE_ENQUIRY_TRANS_SUMMARY', xObj);
    return result.data;
  } catch (error) {}
};

export const getPriceEnquiryTransDetail = async (xObj) => {
  try {
    const result = await fExecuteAPI('PRICE_ENQUIRY_TRANS_DETAIL', xObj);
    return result.data;
  } catch (error) {}
};

export const searchVendor = async (xObj) => {
  try {
    const result = await fExecuteAPI('SEARCH_VENDOR', xObj);
    return result;
  } catch (error) {}
};

export const searchItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('GET_RETURN_REQUEST_SEARCH_ITEM', xObj);
    return result.data;
  } catch (error) {}
};

export const getPriceEnquiryDefaultValues = async (xObj) => {
  try {
    const result = await fExecuteAPI('PRICE_ENQUIRY_DEFAULT_VALUES', xObj);
    return result.data;
  } catch (error) {}
};

export const savePriceEnquiryTrans = async (xObj) => {
  try {
    const result = await fExecuteAPI('SAVE_ENQUIRY_TRANS', xObj);
    return result;
  } catch (error) {}
};

export const deletePriceEnquiryItem = async (xObj) => {
  try {
    const result = await fExecuteAPI('DELETE_ENQUIRY_TRANS_ITEM', xObj);
    return result;
  } catch (error) {}
};
