import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgress } from "@mui/material";
// import { fDummyFunction, GridDataColumn } from "./Action";
import "./ReuseableComponent.css";
import Modal from "@mui/material/Modal";


const fDummyFunction = () => { };
const GridDataColumn = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "VocName", headerName: "Doc.Name", width: 250 },
    { field: "A_VocNo", headerName: "Doc.No", width: 150 },
];
const GCAudit = ({
    xOpen = false,
    fCloseAuditModal,
    sAuditSelectArr = [],
    GridDataAuditColumns,
    GridDataAuditRows = [],
    xAuditId = "",
    fonChangeAuditSelect,
    xTitle = "",
    xLabel = "",
}) => {
    const vMediaQuery = useMediaQuery("(max-width:650px)");
    return (
        <Modal
            open={xOpen}
            onClose={fCloseAuditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{ flexGrow: 1, overflow: "auto" }}
                className="gnr-item-search model-gc-search"
            >
                <Grid
                    container
                    spacing={0}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                    className="GCSearchBtn-Tittle"
                    sx={{ alignItems: "center" }}
                >
                    <Grid
                        item
                        xs={11}
                        sm={11}
                        md={11}
                        className="d-flex justify-content-center  "
                    >
                        <div>{xTitle}</div>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        className="d-flex justify-content-end"
                    >
                        <CloseIcon
                            className="grnt-item-search-close-icon"
                            onClick={!!fCloseAuditModal ? fCloseAuditModal : fDummyFunction}
                            style={{ zIndex: 999 }}
                        />
                    </Grid>
                </Grid>

                <div className="p-2">
                    <Grid
                        container
                        spacing={1}
                        columns={{ xs: 4, sm: 4, md: 12 }}
                        className="mt-2 justify-content-between"
                    >
                        <Grid item xs={4} className="gnr-item-search-label ">
                            {xLabel}
                        </Grid>
                        <Grid item xs={3}>
                            <div className="grn-header-checkbox">
                                {/* <select className="form-select form-select-sm" name="vItemNameGroupCode" ref={vItemNameGroupCode} onChange={fonChangeInputModalSelect} value={xItemSearchModalObj.vItemNameGroupCode} onKeyUp={(e) => { xGetItemSearchValue(e) }}>
                            <option className='py-2' value="ALL"  > All  </option>
                            {sAuditSelectArr?.map((option, index) => (
                                <option className='py-2' value={option.id} key={"Group1" + index} >{option.name} </option>
                            ))}
                        </select> */}

                                <select
                                    className="form-select form-select-sm"
                                    name={xAuditId}
                                    onChange={
                                        !!fonChangeAuditSelect
                                            ? fonChangeAuditSelect
                                            : fDummyFunction
                                    }
                                >
                                    {sAuditSelectArr.length > 0 ? (
                                        <>
                                            {sAuditSelectArr?.map((option, index) => (
                                                <option
                                                    className="py-2"
                                                    value={option.id}
                                                    key={"auditfield" + index}
                                                >
                                                    {option.name}{" "}
                                                </option>
                                            ))}
                                        </>
                                    ) : (
                                        <option value="">none</option>
                                    )}
                                </select>
                            </div>
                        </Grid>
                    </Grid>
                    {false ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <Grid container spacing={1} className="search-item ">
                            <Grid
                                item
                                xs={12}
                                className=" height-table-scroll"
                                component="div"
                            >
                                <div
                                    style={{
                                        height: vMediaQuery ? "60vh" : "65vh",
                                        width: "100%",
                                    }}
                                    id="table-main-grid-3"
                                    className="search-grid-table mt-2"
                                >
                                    <Box style={{ height: "100%", width: "100%" }}>
                                        <DataGrid
                                            rowHeight={30}
                                            headerHeight={40}
                                            getRowClassName={(params) =>
                                                params.indexRelativeToCurrentPage % 2 === 0
                                                    ? "mui-even-row"
                                                    : "mui-odd-row"
                                            }
                                            sx={{
                                                border: 1,
                                                borderColor: "primary.light",
                                            }}
                                            componentsProps={{
                                                filterPanel: { sx: { maxWidth: "93vw" } }
                                              }}
                                            rows={GridDataAuditRows}
                                            columns={
                                                !!GridDataAuditColumns
                                                    ? GridDataAuditColumns
                                                    : GridDataColumn
                                            }
                                            getRowId={(row) => row.id}
                                            hideFooter={GridDataAuditRows.length < 100}
                                        />
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default GCAudit;