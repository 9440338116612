import { fExecuteAPI } from '../../Server/Apis';

const getBatchCreationPendingList = async (xObj) => {
  try {
    const vBatchCreationPendingList = await fExecuteAPI('GET_PENDING_FOR_BATCH_CREATION', xObj);
    return vBatchCreationPendingList.data;
  } catch (error) {}
};

const getBatchCreationPendingTrans = async (xObj) => {
  try {
    const vPurchaseReturnItemList = await fExecuteAPI('GET_PENDING_TRANS_FOR_BATCH_CREATION', xObj);
    return vPurchaseReturnItemList.data;
  } catch (error) {}
};

const createBatch = async (xObj) => {
  try {
    const createBatchResult = await fExecuteAPI('CREATE_BATCH', xObj);
    return createBatchResult;
  } catch (error) {}
};

export { getBatchCreationPendingList, getBatchCreationPendingTrans, createBatch };
