import React, { useEffect, useState, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpButton,
  CmpDatePickerField,
  CmpStatusDiv,
  CmpTotalMenu,
  CmpCheckboxField
} from '../../component/ToolBox/ToolBox';
import PurchaseReturnBreadCrumbs from './PurchaseReturnBreadCrumbs';
import { getPurchaseReturnItemList, updatePurchaseReturnItem } from './action';
import BatchDetailGrid from './processComponent/BatchDetailGrid';
import { PurchaseReturn_Detail, PurchaseReturn_Detail_TransDate } from './PurchaseReturnSlice';
import './PurchaseReturn.css';
import { confirmAlert } from 'react-confirm-alert';

export default function BatchDetail() {
  const dispatch = useDispatch();
  const xItemDetail = useSelector((state) => state.PurchaseReturnSlice.value.detail);
  const rdxuserId = useSelector((state) => state.SliceDB.value.login.userId);
  const reportBaseUrl = useSelector((state) => state.SliceDB.value.reportBaseUrl);

  const [sItemList, setItemList] = useState([]);
  const [sTotalNetAmt, setTotalNetAmt] = useState(0);
  const [sGridEditing, setGridEditing] = useState(false);

  const loadItems = useCallback(async () => {
    const itemListObj = {
      v_BatchId: xItemDetail.A_BatchId
    };
    const result = await getPurchaseReturnItemList(itemListObj);
    const obj = result.data.ResultSet;
    const rowObj = obj.map((i) => {
      return { ...i, id: i.A_TransId + '-' + i.B_SrNo };
    });
    setItemList(rowObj);
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  useEffect(() => {
    const total = sItemList.reduce((sum, i) => sum + i.B_Conf_NetAmt, 0);
    setTotalNetAmt(total);
  }, [sItemList]);

  const onChangeTransDate = (newValue) => {
    dispatch(PurchaseReturn_Detail_TransDate(newValue.$d));
  };

  const UpdatePurchaseReturn = async (xStatus) => {
    console.log('inside save' + JSON.stringify(sItemList));
    const updateList = sItemList.map((itm) => ({
      v_B_TrnsId: itm.A_TransId,
      v_B_SrNo: itm.B_SrNo,
      v_B_Conf_Qty: itm.B_Conf_Qty,
      v_B_Conf_UnitRate: itm.B_Conf_UnitRate,
      v_B_Conf_NetAmt: itm.B_Conf_NetAmt,
      v_B_Adj_Qty: itm.B_Adj_Qty,
      v_B_Adj_Amt: itm.B_Adj_Amt
      // v_B_TaxDept: itm.B_TaxDept,
      // v_B_TaxPerc: itm.B_TaxPerc,
      // v_B_TaxAmt: itm.B_TaxAmt
    }));

    if (Validation()) {
      const updateObj = {
        batchNo: xItemDetail.A_BatchId,
        status: xStatus === 'save' ? 'Enter' : 'Post',
        TransDt: moment(xItemDetail.A_TransDt).format('MM/DD/YYYY'),
        userId: rdxuserId,
        ItemList: updateList,
        taxable: xItemDetail.A_Taxable
      };
      //////////////////////

      const zeroQty = sItemList.filter((data) => data.B_Conf_Qty == 0);
      if (zeroQty.length > 0) {
        confirmAlert({
          title: xStatus === 'save' ? 'Save' : 'Post',
          message: 'Confimed quantity is 0 for some items, Are you sure?',
          buttons: [
            {
              className: 'alertBtn',
              label: 'Yes',
              onClick: async () => {
                let result = await updatePurchaseReturnItem(updateObj);
                if (result.status === 200) {
                  fAlertToast(
                    'SUCCESS',
                    xStatus === 'save' ? 'Saved Successfully' : 'Posted Successfully'
                  );
                  dispatch(
                    PurchaseReturn_Detail({
                      ...xItemDetail,
                      A_Status: xStatus === 'save' ? 'Enter' : 'Post'
                    })
                  );
                }
              }
            },
            {
              className: 'alertBtn',
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      } else {
        confirmAlert({
          title: xStatus === 'save' ? 'Save' : 'Post',
          message: 'Are you sure?',
          buttons: [
            {
              className: 'alertBtn',
              label: 'Yes',
              onClick: async () => {
                let result = await updatePurchaseReturnItem(updateObj);
                if (result.status === 200) {
                  fAlertToast(
                    'SUCCESS',
                    xStatus === 'save' ? 'Saved Successfully' : 'Posted Successfully'
                  );
                  dispatch(
                    PurchaseReturn_Detail({
                      ...xItemDetail,
                      A_Status: xStatus === 'save' ? 'Enter' : 'Post'
                    })
                  );
                }
              }
            },
            {
              className: 'alertBtn',
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  const Validation = () => {
    const confrQty = sItemList.filter((data) => data.B_Conf_Qty === '');
    if (confrQty.length > 0) {
      fAlertToast('FAILED', 'Conf Qty can not be empty');
      return false;
    }

    const unitRate = sItemList.filter((data) => data.B_Conf_UnitRate === '');
    if (unitRate.length > 0) {
      fAlertToast('FAILED', 'Unit Rate can not be empty');
      return false;
    }

    const adjQtyRange = sItemList.filter((data) => data.B_Conf_Qty > data.B_Disp_Qty);
    if (adjQtyRange.length > 0) {
      fAlertToast('FAILED', 'Confrm Qty cannot be greater than Dispatch Qty');
      return false;
    }

    return true;
  };

  const onPressPrint = () => {
    //let baseUrl = '' + process.env.REACT_APP_REPORT_BASE_URL;
    let url =
      reportBaseUrl + 'FreshPurchase/PurchaseReturn?trntyp=PR&batchId=' + xItemDetail.A_BatchId;
    window.open(url);
  };

  return (
    <div className="container PurchaseReturn">
      <div>
        <ToastContainer />
      </div>
      <div
      // className="my-2"
      >
        <PurchaseReturnBreadCrumbs />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={4} sm={2} lg={1} md={2}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => UpdatePurchaseReturn('save')}
              xLabel={'Save'}
              xDisable={xItemDetail.A_Status !== 'Enter' || sGridEditing}
            />
          </Grid>
          <Grid item xs={4} sm={2} lg={1} md={2}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => UpdatePurchaseReturn('post')}
              xLabel={'Post'}
              xDisable={xItemDetail.A_Status !== 'Enter' || sGridEditing}
            />
          </Grid>
          <Grid item xs={4} sm={2} lg={1} md={2}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => onPressPrint()}
              xLabel={'Print'}
              xDisable={false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.5}
          columns={{ xs: 12, sm: 12, md: 12 }}
          className="my-2"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Batch Id'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_BatchId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Batch Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={moment(xItemDetail.A_PostDt).format('DD/MM/YYYY')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.Vendorname} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.DCLocName} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'PR Trans.Date'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={5} sm={3} md={5} lg={4}>
                <CmpDatePickerField
                  xValue={xItemDetail.A_TransDt}
                  xOnChange={(newValue) => onChangeTransDate(newValue)}
                  xName={'Trans Date'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'Taxable'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpCheckboxField
                  xChecked={xItemDetail.A_Taxable === 'Y'}
                  xDisabled={xItemDetail.A_Status !== 'Enter'}
                  xOnChange={(event) => {
                    dispatch(
                      PurchaseReturn_Detail({
                        ...xItemDetail,
                        A_Taxable: event.target.checked ? 'Y' : 'N'
                      })
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'PR Doc No.'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.A_PR_DocNo} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'VANTransIDs'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.VANTransIDs} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4.5} sm={4} lg={3} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'REQTransIDs'} />
              </Grid>
              <Grid item xs={1} sm={1} lg={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={6.5} sm={7} lg={8} md={8}>
                <CmpTypographyField xcomponent={'span'} xText={xItemDetail.REQTransIDs} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="scrol-overflow1">
          {sItemList.length > 0 && (
            <BatchDetailGrid
              rowsData={sItemList}
              setUpdateRows={setItemList}
              editable={xItemDetail.A_Status === 'Enter'}
              setGridEditing={setGridEditing}
            />
          )}
        </div>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 5 }}
          sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <Grid item xs={5} sm={5} md={1}>
            <CmpStatusDiv xStatus={xItemDetail.A_Status} />
          </Grid>
          <Grid item xs={5} sm={3} md={0.8} className="text-end">
            {/* <button className="btn-status btn-primary">
                            <span> TOTAL = </span>
                            <span> {Number(sTotalNetAmt).toFixed(2)}</span>
                        </button> */}
            <CmpTotalMenu xTotal={sTotalNetAmt} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
