import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpInputField,
  CmpSelectArrField,
  CmpButton
} from '../../../component/ToolBox/ToolBox';
function PickingDetailCard({
  xSrNo,
  xQty,
  xConfQty,
  xItem,
  xNetAmount,
  xOnChangeConfNetAmount,
  xOnChangeConfQty,
  xUnitRate,
  xUnit,
  xReadQty,
  xPacking,
  xItemName,
  xTabIndex
}) {
  return (
    <Box sx={{ flexGrow: 1 }} className="border-card">
      <Grid
        container
        spacing={0.1}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ alignItems: 'center' }}>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Item Code'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xItem} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Sr.No'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={xSrNo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={2} sm={2} md={2}>
              <CmpTypographyField xcomponent={'span'} xText={'Item Name'} />
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={0.5}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={9} sm={9} md={9} sx={{ textAlign: 'left' }}>
              <CmpTypographyField xcomponent={'span'} xText={xItemName} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Unit'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={xUnit} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Packing'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={xPacking} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Qty'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xQty).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Unit Rate'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xUnitRate).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Conf.Qty'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
              <CmpInputField
                xTextAlign="center"
                xValue={xConfQty}
                xType={'number'} // text/number/password
                xName={'ConfirmQty'}
                xReadOnly={xReadQty}
                xOnChange={xOnChangeConfQty}
                xError={false}
                xErrorMessage={''}
                xOnKeyUp={''}
                xTabIndex={xTabIndex}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid
            container
            spacing={0}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ alignItems: 'center' }}>
            <Grid item xs={4} sm={4} md={4}>
              <CmpTypographyField xcomponent={'span'} xText={'Net.Amt'} />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <CmpTypographyField xcomponent={'span'} xText={':'} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ textAlign: 'center' }}>
              <CmpTypographyField xcomponent={'span'} xText={Number(xNetAmount).toFixed(2)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default PickingDetailCard;
