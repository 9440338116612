import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  head: {
    location: '',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date()
  },
  detail: {
    A_TransId: '',
    TruckName: '',
    LocName: '',
    A_DispatchDt: '',
    Status: '',
    ItemCount: '',
    A_TransDt: '',
    A_Truck: '',
    Loc: ''
  }
};

const DCConfirmationSlice = createSlice({
  name: 'DCConfirmationSlice',
  initialState: { value: initialState },

  reducers: {
    DCConfirmation_Head: (state, action) => {
      state.value.head = action.payload;
    },
    DCConfirmation_Detail: (state, action) => {
      state.value.detail = action.payload;
    },

    DCConfirmation_Detail_TransDate: (state, action) => {
      state.value.detail.A_TransDt = action.payload;
    }
  }
});

export const { DCConfirmation_Head, DCConfirmation_Detail, DCConfirmation_Detail_TransDate } =
  DCConfirmationSlice.actions;
export default DCConfirmationSlice.reducer;
