import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import layoutSlice from '../layout/slice';
import SliceDB from '../DBSlice/SliceDB';
import loginSlice from '../pages/Login/sliceUsers';
import ReturnRequestSlice from '../pages/ReturnRequest/returnRequestSlice';
import ReturnRequestReviewSlice from '../pages/ReturnRequestReview/ReturnRequestReviewSlice';
import PickingSlice from '../pages/PickingSummary/PickingSlice';
import DispatchSlice from '../pages/Dispatch/DispatchSlice';
import PurchaseReturnSlice from '../pages/PurchaseReturn/PurchaseReturnSlice';
import DCConfirmationSlice from '../pages/DC_Confirmation/DCConfirmationSlice';
import BatchCreationSlice from '../pages/BatchCreation/BatchCreationSlice';
import ReturnReviewBatchCreationSlice from '../pages/ReturnReviewBatchCreation/ReturnReviewBatchCreationSlice';
import ReturnReviewBatchSlice from '../pages/ReturnReviewBatch/ReturnReviewBatchSlice';
import ReturnReportSlice from '../pages/ReturnReports/ReturnReportSlice';

import OrderSlice from '../pages/Order/OrderSlice';
import PurchaseSlice from '../pages/Purchase/PurchaseSlice';
import VanReceivingSlice from '../pages/VanReceiving/VanReceivingSlice';
import OutletRequestSlice from '../pages/Request/OutletRequestSlice';
import PriceEnquirySlice from '../pages/PriceEnquiry/PriceEnquirySlice';
import FishPurchaseSlice from '../pages/FishPurchase/FishPurchaseSlice';
import DCReceiptSlice from '../pages/DC_Receipt/DCReceiptSlice';

// add the reducers that need to be persisted
const reducersToPersist = ['SliceDB', 'loginSlice', 'ReturnRequestSlice', 'OutletRequestSlice','layoutSlice'];

const persistConfig = {
  key: 'freshpurchase',
  storage,
  whitelist: reducersToPersist
};

const reducers = combineReducers({
  layoutSlice: layoutSlice,
  SliceDB: SliceDB,
  loginSlice: loginSlice,
  ReturnRequestSlice: ReturnRequestSlice,
  ReturnRequestReviewSlice: ReturnRequestReviewSlice,
  PickingSlice: PickingSlice,
  DispatchSlice: DispatchSlice,
  PurchaseReturnSlice: PurchaseReturnSlice,
  DCConfirmationSlice: DCConfirmationSlice,
  BatchCreationSlice: BatchCreationSlice,
  ReturnReviewBatchCreationSlice: ReturnReviewBatchCreationSlice,
  ReturnReviewBatchSlice: ReturnReviewBatchSlice,
  OrderSlice: OrderSlice,
  PurchaseSlice: PurchaseSlice,
  VanReceivingSlice: VanReceivingSlice,
  ReturnReportSlice: ReturnReportSlice,
  OutletRequestSlice: OutletRequestSlice,
  PriceEnquirySlice: PriceEnquirySlice,
  FishPurchaseSlice: FishPurchaseSlice,
  DCReceiptSlice:DCReceiptSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = [
  /*thunk*/
];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware // Note: logger should be the last item in the middleware
});

const persistor = persistStore(store);

export { store, persistor, storage };
