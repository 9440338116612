import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { fAlertToast } from '../../Utility/Utilitys';
import {
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectField,
  CmpButton,
  CmpInputField,
  CmpButtonWithIcon
} from '../../component/ToolBox/ToolBox';
import { BatchCreationHead, setBatchCreationDetail } from './BatchCreationSlice';
import { getBatchCreationPendingList } from './actions';
import { Sd } from '@mui/icons-material';
import BatchCreationSummaryCard from './ProcessComponents/BatchCreationSummaryCard';
import BatchCreationBreadcrumbs from './BatchCreationBreadcrumbs';
import './BatchCreation.css';
import { Refresh } from '@mui/icons-material';

export default function BatchCreationSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dcLoclist = useSelector((state) => state.SliceDB.value.getDCLocation);
  const scanFocusOnLoad = useRef(null);
  const rdxHeadobj = useSelector((state) => state.BatchCreationSlice.value.head);

  // const [sDetailObj, setDetailObj] = useState([
  //     {
  //         A_Vendor: '',
  //         Vendorname: '',
  //         DCLoc: '',
  //         DCLocName: '',
  //         A_DispatchDt: '',
  //         DispatchCount: 0,
  //         DispatchValue: 0,
  //         A_TransDt: new Date()
  //     }
  // ]);

  const [sDetailObj, setDetailObj] = useState([]);

  const [sSearch, setSearch] = useState('');
  const [sSearchList, setSearchList] = useState([]);

  const fOnPageLoad = useCallback(async () => {
    let currObj = {
      location: rdxHeadobj.location == '' ? dcLoclist[0]?.UId : rdxHeadobj?.location,
      dateFrom: rdxHeadobj.dateFrom,
      dateTo: rdxHeadobj.dateTo
    };
    dispatch(BatchCreationHead(currObj));
    await fGetDetailList(currObj);
  }, [dcLoclist]);

  useEffect(() => {
    scanFocusOnLoad.current.focus();
    fOnPageLoad();
  }, [fOnPageLoad]);

  const fOnpressLoad = async () => {
    scanFocusOnLoad.current.focus();
    if (rdxHeadobj.dateFrom < rdxHeadobj.dateTo) {
      await fGetDetailList(rdxHeadobj);
      dispatch(BatchCreationHead(rdxHeadobj));
    } else {
      fAlertToast('To Date cannot be less then From date');
    }
  };

  const fGetDetailList = async () => {
    const obj = {
      v_Loc: rdxHeadobj.location,
      v_Date1: moment(rdxHeadobj.dateFrom).format('MM/DD/YYYY'),
      v_Date2: moment(rdxHeadobj.dateTo).format('MM/DD/YYYY')
    };

    const result = await getBatchCreationPendingList(obj);
    if (result.ResultSet?.length > 0) {
      setDetailObj(result.ResultSet);
    } else {
      setDetailObj([]);
    }
  };

  const onChangeLocation = (event) => {
    dispatch(
      BatchCreationHead({
        ...rdxHeadobj,
        location: event.target.value
      })
    );
  };

  const onChangeDateFrom = (newValue) => {
    dispatch(
      BatchCreationHead({
        ...rdxHeadobj,
        dateFrom: newValue.$d
      })
    );
  };

  const onChangeDateTo = (newValue) => {
    dispatch(
      BatchCreationHead({
        ...rdxHeadobj,
        dateTo: newValue.$d
      })
    );
  };

  const onSearchKey = () => {
    if (sSearch.length > 1) {
      const searchArr = sDetailObj.filter((data) =>
        data.Vendorname.toString().toLowerCase().includes(sSearch.toLowerCase())
      );
      setSearchList(searchArr);
    } else {
      setSearchList(sDetailObj);
    }
  };

  const fOnpressItem = (item) => {
    dispatch(
      setBatchCreationDetail({
        A_Vendor: item.A_Vendor,
        Vendorname: item.Vendorname,
        DCLoc: item.DCLoc,
        DCLocName: item.DCLocName,
        A_DispatchDt: item.A_DispatchDt,
        DispatchCount: item.DispatchCount,
        DispatchValue: item.DispatchValue,
        A_TransDt: item.A_TransDt
      })
    );

    navigate('/BatchCreationDetail');
  };

  return (
    <div className="container BatchCreation">
      <div
      // className="my-2"
      >
        <BatchCreationBreadcrumbs />
      </div>
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Location'} />
              </Grid>
              <Grid item xs={0.5} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.5} sm={7} md={8}>
                <CmpSelectField
                  xLabelText="Location"
                  xValue={rdxHeadobj.location}
                  xOnChange={(event) => onChangeLocation(event)}
                  xName={'Location'}
                  xData={dcLoclist}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'1'}
                  xref={scanFocusOnLoad}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'From Date'} />
              </Grid>
              <Grid item xs={0.5} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.5} sm={7} md={8}>
                <CmpDatePickerField
                  xValue={rdxHeadobj.dateFrom}
                  xOnChange={(event) => onChangeDateFrom(event)}
                  xName={'dateFrom'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={3} sm={4} md={3}>
                <CmpTypographyField xcomponent={'span'} xText={'To Date'} />
              </Grid>
              <Grid item xs={0.5} sm={1} md={1}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.5} sm={7} md={8}>
                <CmpDatePickerField
                  xValue={rdxHeadobj.dateTo}
                  xOnChange={(event) => onChangeDateTo(event)}
                  xName={'dateTo'}
                  xError={false}
                  xErrorMessage={false}
                  xOnKeyUp={''}
                  xTabIndex={'3'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={6} md={11}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 12, sm: 12, md: 12 }}
              className="d-flex justify-content-between">
              <Grid item xs={12} sm={12} md={8}>
                <CmpInputField
                  xTextAlign="left"
                  xValue={sSearch}
                  xType={'text'} // text/number/password
                  xName={'vendorSearch'}
                  xReadOnly={false}
                  xOnChange={(event) => {
                    setSearch(event.target.value);
                    onSearchKey();
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTabIndex={'4'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={12} md={1}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ justifyContent: 'end' }}>
              <Grid item xs={12} sm={1.6} md={12} className="text-end">
                <CmpButtonWithIcon
                  xvariant={'contained'}
                  xsize={'small'}
                  xonClick={fOnpressLoad}
                  xLabel={'Load'}
                  xIcon={<Refresh />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{ alignItems: 'center' }}
          className="my-2"></Grid>
        <Grid
          container
          spacing={1}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            height: '65vh',
            overflowY: 'auto',
            marginTop: '1vh',
            paddingRight: '16px',
            alignContent:
              sDetailObj.length == 0
                ? 'center'
                : sSearch.length == 0
                ? 'start'
                : sSearchList.length == 0
                ? 'center'
                : 'start'
          }}>
          {sDetailObj.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <p style={{ fontSize: '2rem' }}> List is Empty </p>
            </div>
          ) : sSearch.length == 0 ? (
            sDetailObj.map((itm, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={'BatchCreation' + i}
                  onClick={() => fOnpressItem(itm)}>
                  <BatchCreationSummaryCard
                    xVendor={itm.Vendorname}
                    xNo_Dispatch={itm.DispatchCount}
                    xValue_Dispatched={itm.DispatchValue}
                    xDCLocation={itm.DCLocName}
                    xDispatched_Date={moment(itm.A_DispatchDt).format('DD/MM/YYYY')}
                  />
                </Grid>
              );
            })
          ) : sSearchList.length == 0 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <p style={{ fontSize: '2rem' }}> Nothing found </p>
            </div>
          ) : (
            sSearchList.map((itm) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={itm.A_Vendor}
                  onClick={() => fOnpressItem(itm)}>
                  <BatchCreationSummaryCard
                    xVendor={itm.Vendorname}
                    xNo_Dispatch={itm.DispatchCount}
                    xValue_Dispatched={itm.DispatchValue}
                    xDCLocation={itm.DCLocName}
                    xDispatched_Date={moment(itm.A_DispatchDt).format('DD/MM/YYYY')}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </div>
  );
}
