import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
function DispatchSummaryBreadCrumbs() {
  const location = useLocation();
  const vPathName = location.pathname;
  return (
    <div className="container-fluid p-0 breadcrumb">
      {vPathName === '/DispatchSummary' ? (
        <div>
          <span
            className="breadcrumb-arrow-inactive"
          >
            Dispatch Summary{' '}
          </span>
        </div>
      ) : vPathName === '/DispatchDetail' ? (
        <div className="d-flex">
          <Link
            to="/DispatchSummary"
            state={location}
            className={'breadcrumb-arrow-active mr-2'}>
            Dispatch Summary
          </Link>
          {/* <ArrowForwardIos color="info" style={{ marginRight: '1px' }} /> */}
          <div>
            <span
              className="breadcrumb-arrow-inactive"
            >
              Dispatch Detail{' '}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default DispatchSummaryBreadCrumbs;
