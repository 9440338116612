import { Close } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CmpButton, CmpSwitch, CmpTypographyField } from '../../../component/ToolBox/ToolBox'
import { updateRequestGridColumns } from '../OutletRequestSlice';

export default function RequestItmColumnPicker({
    setShowColumnPicker
}) {
    const rdxColumns = useSelector((state) => state.OutletRequestSlice.value.requestGridColumns);
    const [sColumnList, setColumnList] = useState(rdxColumns);
    const dispatch = useDispatch();

    const onChangeSelection = (event, id) => {
        let columns = sColumnList.map(i => { return i.field === id ? { ...i, hide: !event.target.checked } : i });
        setColumnList(columns);
    }

    const onPressSave = () => {
        dispatch(updateRequestGridColumns(sColumnList));
        setShowColumnPicker(false);
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
                <Grid item xs={12} className="post-Modal-title d-flex justify-content-between">
                    <span>Choose columns</span>
                    <Close onClick={() => setShowColumnPicker(false)} />
                </Grid>
            </Grid>
            <Box sx={{ height: "45vh", overflowY: 'auto' }}>
                <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className='mt-3'>
                    {
                        sColumnList.map(i => {
                            return (
                                <Grid key={i.field} item xs={6} className='d-flex align-items-center p-1'>
                                    <CmpSwitch xChecked={!i.hide} xOnChange={(event) => { onChangeSelection(event, i.field) }} />
                                    <CmpTypographyField xText={i.headerName} />
                                </Grid>
                            )
                        })
                    }


                </Grid>
            </Box>

            <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12 }}
                className='mt-1'
            >
                <Grid item xs={12} className=" d-flex justify-content-center mt-2">
                    <CmpButton
                        xvariant={'contained'}
                        xsize={'small'}
                        xonClick={onPressSave}
                        xLabel={'Save'}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}
