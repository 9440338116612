import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  head: {
    location: '',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(),
    status: 'All',
    Dept: ''
  },

  detail: {
    A_BatchId: '',
    A_PostDt: new Date(),
    A_DCLoc: '',
    DCLocName: '',
    A_Status: '',
    A_Endt: new Date(),
    Dept: '',
    LocNames: '',
    RequestIDs: ''
  },

  itemHead: {
    batchId: '',
    itemCd: '',
    itemName: ''
  }
};

const ReturnReviewBatchSlice = createSlice({
  name: 'ReturnReviewBatchSlice',
  initialState: { value: initialState },

  reducers: {
    RetunReviewBatchHead: (state, action) => {
      state.value.head = action.payload;
    },
    UpdateRetunReviewBatchDetail: (state, action) => {
      state.value.detail = action.payload;
    },
    RetunReviewBatchItem: (state, action) => {
      state.value.itemHead = action.payload;
    }
  }
});

export const { RetunReviewBatchHead, UpdateRetunReviewBatchDetail, RetunReviewBatchItem } =
  ReturnReviewBatchSlice.actions;
export default ReturnReviewBatchSlice.reducer;
