import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { flexbox } from '@mui/system';

export default function SideNav(props) {
  const { sideNav, subMenu } = props;
  const [state, setState] = useState(sideNav);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const vMediaQuery = useMediaQuery('(max-width: 767px)');
  const location = useLocation();
  return (
    <React.Fragment>
      <div
        style={{
          height: '100%',
          // border: '1px ',
          borderRight: '1px solid #e5e7eb'
        }}>
        {!state && props.handleSideNavClose('enable')}
        {state && (
          <div>
            {!vMediaQuery && <CloseIcon sx={{ float: 'right' }} onClick={() => setState(false)} />}
            <Grid container spacing={1}>
              {subMenu.map((value, i) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  key={'order' + i + 1}
                  className="breadcrumb d-flex justify-content-center"
                  onClick={() => {
                    setSelected(subMenu[i].name);
                    navigate(subMenu[i].path);
                  }}>
                  {selected === subMenu[i].name ? (
                    <div className="sidenav-active">{subMenu[i].name}</div>
                  ) : (
                    <div className="sidenav-inactive">{subMenu[i].name}</div>
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
